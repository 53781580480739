import { JOB_APPLICANTS_OPTIONS, JOB_STATUS_OPTIONS } from "components/FilterButtons/options";
import useBadgeValues from "hooks/useBadgeValues";
import { useMemo, useState } from "react";

const initialCardsTopRow = [
  {
    title: "All",
    filterField: "",
    filterValue: "",
  },
  {
    title: "New",
    filterField: "jobs.applicantStatus",
    filterValue: "New",
  },
  {
    title: "ATC",
    filterField: "jobs.applicantStatus",
    filterValue: "ATC",
  },
  {
    title: "Screened",
    filterField: "jobs.applicantStatus",
    filterValue: "Screened",
  },
  {
    title: "Pre-Hire",
    filterField: "jobs.applicantStatus",
    filterValue: "Pre-Hire",
  },
  {
    title: "Declined",
    filterField: "jobs.applicantStatus",
    filterValue: "Declined",
  },
];

const initialCardsBottomRow = [
  {
    title: "All",
    filterField: "",
    filterValue: "",
  },
  {
    title: "Submitted",
    filterField: "jobs.employmentStage",
    filterValue: "Submitted",
  },
  {
    title: "Interview",
    filterField: "jobs.employmentStage",
    filterValue: "Interview",
  },
  {
    title: "Waitlist",
    filterField: "jobs.employmentStage",
    filterValue: "Waitlist",
  },
  {
    title: "Declined",
    filterField: "jobs.employmentStage",
    filterValue: "Declined",
  },
  {
    title: "Filled",
    filterField: "jobs.employmentStage",
    filterValue: "Filled",
  },
  {
    title: "Terminated",
    filterField: "jobs.employmentStage",
    filterValue: "Terminated",
  },
  {
    title: "Completed",
    filterField: "jobs.employmentStage",
    filterValue: "Completed",
  },
];

const useJobStatusFilterCards = ({ currentJob, applicantStatus }) => {
  const [refreshBadges, setRefreshBadges] = useState(false);

  const { cards: cardsTopRow } = useBadgeValues({
    initialCards: initialCardsTopRow,
    filters: { "jobs.jobSlug": currentJob?.jobSlug },
    entity: `applicants`,
    refreshBadges,
    useElemMatch: true,
    extraParams: `&availableForJobSlug=${currentJob?.jobSlug}`,
  });

  const { cards: cardsBottomRow } = useBadgeValues({
    initialCards: initialCardsBottomRow,
    filters: {
      "jobs.jobSlug": currentJob?.jobSlug,
      ...(!!applicantStatus && applicantStatus !== "all"
        ? { "jobs.applicantStatus": applicantStatus }
        : {}),
    },
    entity: `applicants`,
    refreshBadges,
    useElemMatch: true,
    extraParams: `&availableForJobSlug=${currentJob?.jobSlug}`,
  });

  const jobApplicantOptions = useMemo(() => {
    const result = [...JOB_APPLICANTS_OPTIONS];
    result[0].count = cardsTopRow[0].number ?? 0;
    result[1].count = cardsTopRow[1].number ?? 0;
    result[2].count = cardsTopRow[2].number ?? 0;
    result[3].count = cardsTopRow[3].number ?? 0;
    result[4].count = cardsTopRow[4].number ?? 0;
    result[5].count = cardsTopRow[5].number ?? 0;
    return result;
  }, [cardsTopRow]);

  const jobStatusOptions = useMemo(() => {
    const result = [...JOB_STATUS_OPTIONS];
    result[0].count = cardsBottomRow[0].number ?? 0;
    result[1].count = cardsBottomRow[1].number ?? 0;
    result[2].count = cardsBottomRow[2].number ?? 0;
    result[3].count = cardsBottomRow[3].number ?? 0;
    result[4].count = cardsBottomRow[4].number ?? 0;
    result[5].count = cardsBottomRow[5].number ?? 0;
    result[6].count = cardsBottomRow[6].number ?? 0;
    result[7].count = cardsBottomRow[7].number ?? 0;
    return result;
  }, [cardsBottomRow]);

  return { jobApplicantOptions, jobStatusOptions };
};

export default useJobStatusFilterCards;
