// import React from "react";
import PropTypes from "prop-types";
import TableAction from "components/TableAction";
import { useAppContextController } from "context/AppContext";
import ApplicantMoreButton from "components/ApplicantMoreButton";
import { useMemo } from "react";
import moment from "moment";

const ApplicantsTableAssessmentActions = ({
  id,
  row,
  onOpenResumeModal,
  onOpenLastAssessmentResultModal,
  onOpenLastAIScreeningResultModal,
}) => {
  const { company } = useAppContextController();

  const { jobs, assessments } = row;

  const lastAssessment = useMemo(() => {
    if (!assessments?.length) return null;
    let lastAs = null;
    for (let index = 0; index < assessments.length; index += 1) {
      const as = assessments[index];
      if (as.endDate && (lastAs == null || moment(as.endDate).isAfter(moment(lastAs.endDate)))) {
        lastAs = as;
      }
    }
    return lastAs;
  }, [assessments]);

  const lastAIScreeningResult = useMemo(() => {
    if (!jobs?.length) return null;
    let lastAI = null;
    for (let index = 0; index < jobs.length; index += 1) {
      const applJob = jobs[index];
      if (applJob?.aiInterview?.interviewEndDate) {
        if (
          !lastAI ||
          moment(applJob?.aiInterview?.interviewEndDate).isAfter(lastAI.interviewEndDate)
        ) {
          lastAI = applJob?.aiInterview;
        }
      }
    }
    return lastAI;
  }, [jobs]);

  const onClickHandler = (action, e) => {
    switch (action) {
      case "applicantResume":
        e.stopPropagation();
        onOpenResumeModal(`${row?.firstName} ${row?.lastName}`, row.finalResumeText);
        break;
      case "applicantAIResult":
        e.stopPropagation();
        onOpenLastAIScreeningResultModal(row);
        break;
      case "applicantAssessmentResult":
        e.stopPropagation();
        onOpenLastAssessmentResultModal(row, lastAssessment);
        break;

      default:
        break;
    }
  };

  return (
    <span>
      <TableAction
        color="warning"
        icon="description"
        clickAction={(e) => onClickHandler("applicantResume", e)}
        tooltip={
          row?.finalResumeText
            ? `Resume:\n${(row.finalResumeText ?? "").slice(0, 200)}${
                row.finalResumeText.length > 200 ? "..." : ""
              }`
            : "No resume available"
        }
        disabled={!row?.finalResumeText}
      />
      <TableAction
        color="info"
        icon="content_paste_search"
        clickAction={(e) => onClickHandler("applicantAssessmentResult", e)}
        tooltip={lastAssessment ? "Latest Assessment Result" : "No Assessment Taken"}
        disabled={!lastAssessment}
      />
      {company?.aiSettings?.aiInterviewsEnabled && (
        <TableAction
          iconStyle={{ color: lastAIScreeningResult ? "#5f32a2" : null }}
          icon="try_outlined"
          clickAction={(e) => onClickHandler("applicantAIResult", e)}
          tooltip={
            lastAIScreeningResult ? "Latest AI Screening Result" : "No AI Screening Finished"
          }
          disabled={!lastAIScreeningResult}
        />
      )}
    </span>
  );
};

export default ApplicantsTableAssessmentActions;

ApplicantsTableAssessmentActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
ApplicantsTableAssessmentActions.propTypes = {
  id: PropTypes.string,
};
