import { useState } from "react";
import { LocationOn } from "@mui/icons-material";
import { Card, Button, Box } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import VenueIcon from "components/VenueIcon";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import ApplyButton from "../ApplyButton";

const ResultCard = ({ result, customers }) => {
  const { venues, allVenues, company, isMobile, companyType } = useAppContextController();
  const [showMore, setShowMore] = useState(false);
  const expandText = () => setShowMore(!showMore);
  let shortDescription = "";
  if (result?.description) {
    shortDescription = result.description;
  }
  let customer;
  if (companyType === "Company") customer = customers?.[result.companySlug];

  const activeCompany = result?.showCustomer === "Yes" ? customer : company;

  const getLocation = () => {
    const currentCustomer = customers?.[result?.companySlug];
    const currentLoc = currentCustomer?.locations?.find(
      (item) => item?.locationName === result?.jobLocation
    );

    let locCity = "";
    let locState = "";

    if (currentLoc) {
      locCity = currentLoc?.city;
      locState = currentLoc?.state;
    } else {
      locCity = result?.companyCity;
      locState = result?.companyState;
    }

    return (
      <MDTypography variant="body2">
        {locCity && locState && <LocationOn color="error" />}
        {locCity && locCity}
        {locCity && locState && ", "}
        {locState && locState}
      </MDTypography>
    );
  };

  return (
    <Card id="table-results" shadow="md">
      <MDBox display="flex" justifyContent="space-between" p={2}>
        <MDBox display="flex" width="100%">
          <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            variant="gradient"
            bgColor="white"
            color="white"
            borderRadius="xl"
            p={3}
            mr={2}
          >
            {companyType === "Venue" ? (
              <VenueIcon
                logoUrl={allVenues?.[result?.venueSlug]?.logoUrl}
                slug={result?.venueSlug}
                name={allVenues?.[result?.venueSlug]?.name}
              />
            ) : (
              <CompanyLogoSquare company={activeCompany} defaultCompany={company} />
            )}
          </MDBox>
          <MDBox width="100%">
            <MDTypography variant="h4">{result?.title || ""}</MDTypography>
            {companyType === "Venue" ? (
              <>
                <MDTypography variant="h5">{result?.venueName || ""}</MDTypography>
                <MDTypography variant="body2">
                  {result?.venueCity && result?.venueState && <LocationOn color="error" />}
                  {result?.venueCity && result?.venueCity}
                  {result?.venueCity && result?.venueState && ","}
                  {result?.venueState && result?.venueState}
                </MDTypography>
              </>
            ) : (
              <>
                {/* <MDTypography variant="h5">{company?.companyName}</MDTypography> */}
                {getLocation()}
              </>
            )}
            {!isMobile && (
              <MDBox mt={2} width="100%">
                <Box sx={{ typography: "body2" }} pr={1}>
                  {!showMore && shortDescription?.length
                    ? parse(shortDescription?.substring(0, 250))
                    : parse(shortDescription)}
                </Box>
                {shortDescription?.length > 250 && (
                  <MDBox textAlign="end">
                    <Button color="error" sx={{ justifySelf: "end" }} onClick={expandText}>
                      {showMore ? "Show Less" : "Show More..."}
                    </Button>
                  </MDBox>
                )}
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <MDBox>{!isMobile && <ApplyButton row={result} />}</MDBox>
      </MDBox>
      {isMobile && (
        <>
          <MDBox mt={2} width="100%">
            <Box sx={{ typography: "body2" }} ml={1} p={2}>
              {!showMore && shortDescription?.length
                ? parse(shortDescription?.substring(0, 250))
                : parse(shortDescription)}
            </Box>
            {shortDescription?.length > 250 && (
              <MDBox textAlign="end">
                <Button color="error" sx={{ justifySelf: "end" }} onClick={expandText}>
                  {showMore ? "Show Less" : "Show More..."}
                </Button>
              </MDBox>
            )}
          </MDBox>
          <ApplyButton row={result} />
        </>
      )}
    </Card>
  );
};

export default ResultCard;
