import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import PreviewIcon from "@mui/icons-material/Preview";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { isEmpty, isEqual } from "lodash";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import fetchCustomers from "api/companies/fetchCustomers";
import { useEffect, useState } from "react";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import JobStatusButton from "components/JobStatusButton";
import BadgeIcon from "@mui/icons-material/Badge";
import GenericMoreButton from "components/GenericMoreButton";

const CompanyJobsTableActions = ({
  jobSlug,
  row,
  setFilters,
  setOpen,
  setJobPreview,
  parent = "jobs",
  setPage,
  currentApplicant,
  setNavigateUrl,
}) => {
  const navigate = useNavigate();

  const navigateLocal = setNavigateUrl ?? navigate;

  const [localJobStatus, setLocalJobStatus] = useState(
    getApplicantJobStatus(currentApplicant, jobSlug)
  );

  useEffect(() => {
    setLocalJobStatus(getApplicantJobStatus(currentApplicant, jobSlug));
  }, [currentApplicant, jobSlug]);

  const refresh = (slug, status) => {
    if (status) {
      setLocalJobStatus(status);
    }
  };

  const { setCurrentJob } = useAppContextController();

  const { data: customers, isLoading } = useQuery(["customerdropdown"], () =>
    fetchCustomers({
      filters: { status: "Active" },
      fetchAll: true,
      order: "asc",
      orderBy: "name",
    })
  );

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    if (isEqual(action, "jobinfo")) setCurrentJob(row);
    setPage(1);
    navigateLocal(`/companyjobs/${jobSlug}/action/${action}`);
  };

  const setPreviewOpen = (job, e) => {
    e.stopPropagation();

    if (!isEmpty(job.companyName)) {
      const customerInfo = customers?.data.find(
        (customer) =>
          isEqual(customer.name, job.companyName) || job.companySlug.includes(customer.slug)
      );

      setJobPreview(customerInfo);
    }

    setOpen(true);
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("jobinfo", e)}
        tooltip="Job Info"
      />
      {!!currentApplicant && (
        <JobStatusButton status={localJobStatus} job={row} refresh={refresh} />
      )}
      <TableAction
        icon="badge"
        color="success"
        tooltip="Job Status"
        clickAction={(e) => onClickHandler("jobstatus", e)}
      />
      <GenericMoreButton
        buttons={[
          {
            color: "success",
            icon: "event-seat",
            showBadge: true,
            badgeNumber: row?.interviewCount || 0,
            tooltip: "Job Interviews",
            clickAction: (e) => onClickHandler("jobinterviews", e),
          },
          // {
          //   icon: "person-add",
          //   showBadge: true,
          //   badgeNumber: row?.applicantCount || 0,
          //   color: "warning",
          //   tooltip: "Job Applicants",
          //   clickAction: (e) => onClickHandler("jobapplicants", e),
          // },
          // {
          //   icon: "badge",
          //   color: "success",
          //   tooltip: "Job Status",
          //   clickAction: (e) => onClickHandler("jobstatus", e),
          // },
          {
            color: "info",
            icon: "preview",
            clickAction: (e) => {
              setCurrentJob(row);
              return parent === "jobs"
                ? setPreviewOpen(row, e)
                : navigateLocal(`/companyjobs/${jobSlug}/action/jobpreview`);
            },
            tooltip: "Job Preview",
          },
        ]}
      />
    </span>
  );
};

CompanyJobsTableActions.defaultProps = {
  jobSlug: "",
};

// Typechecking props for the TableAction
CompanyJobsTableActions.propTypes = {
  jobSlug: PropTypes.string,
};

export default CompanyJobsTableActions;
