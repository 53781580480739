import { useAuth0 } from "@auth0/auth0-react";
import { AttachFile } from "@mui/icons-material";
import LandscapeIcon from "@mui/icons-material/Landscape";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Box,
  Grid,
  Icon,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundImage from "assets/images/bg-mascots.png";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { baseAxios } from "config";
import { useAppContextController } from "context/AppContext";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { getCommonBaseImageUrl, getCompanyImageUrl } from "utils/helpers/upload";
import PanelSelectorBox from "../PanelSelectorBox";
import useStyles from "./styles";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  addButton: {
    fontSize: 14,
    marginRight: 15,
  },
  section_image_delete: {
    position: "absolute!important",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",
    border: "1px dashed #C9C9C9",
    padding: "20px 20px",
    cursor: "pointer",
    borderRadius: "4px",
  },
});
const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};
const ProfileAttachments = ({
  setCurrentApplicant,
  currentApplicant,
  setCurrentUser,
  currentUser: currentUserData,
  company,
}) => {
  const { applicant, ...currentUser } = currentUserData;
  const [attachmentOpen, setAttachmentOpen] = useState(false);
  const classes = useStyle();
  const styles = useStyles();
  const { userType } = useAppContextController();
  const [banner, setBanner] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const { user } = useAuth0();
  const isEdit = currentUser?._id || currentUser?.createAgent;
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [confirmModalApplicant, setConfirmModalApplicant] = useState(initialValueModal);
  const closeModalHandler = () => setConfirmModalApplicant(initialValueModal);

  const IMAGE_SERVER = getCompanyImageUrl(company);

  const uploadSignatureImgMutation = useMutation(
    async (imgForm) => {
      await baseAxios.post(
        `/upload/applicants/${currentApplicant._id}/${imgForm.type}`,
        imgForm.form
      );
      await updateProfile({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, data) => {
        setCurrentUser({ ...currentUser, ...data.data });
        enqueueSnackbar("Picture has been updated!", { variant: "success" });
      },
    }
  );

  const uploadImgMutation = useMutation(
    async (imgForm) => {
      await baseAxios.post(`/upload/users/${currentUser._id}/${imgForm.type}`, imgForm.form);
      await updateProfile({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, data) => {
        setCurrentUser({ ...currentUser, ...data.data });
        enqueueSnackbar("Picture has been updated!", { variant: "success" });
      },
    }
  );
  const onDropSignatureImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);
    uploadSignatureImgMutation.mutate({
      form,
      data: { ...currentUser, signature: file.name },
      type: "signature",
    });
  };

  const onDropBannerHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);
    setBanner(file.name);
    uploadImgMutation.mutate({
      form,
      data: { ...currentUser, bannerImg: file.name },
      type: "banner",
    });
  };

  const onDropImageHandler = (img) => {
    const file = img[0];
    const form = new FormData();
    form.append("file", file);

    uploadImgMutation.mutate({
      form,
      data: { ...currentUser, profileImg: file.name },
      type: "photo",
    });
  };

  const signatureAvatar =
    currentUser?.signature && currentApplicant?._id ? (
      <Box>
        <img
          src={`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/signature/${currentUser?.signature}`}
          alt="signature"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    ) : (
      <LandscapeIcon sx={{ width: "5em", height: "5em" }} />
    );

  useEffect(() => {
    if (IMAGE_SERVER && currentUser?.bannerImg !== null) {
      if (currentUser?.bannerImg?.startsWith("http")) {
        setBanner(currentUser?.bannerImg);
      } else {
        setBanner(`${IMAGE_SERVER}/users/${currentUser?._id}/banner/${currentUser?.bannerImg}`);
      }
    } else {
      setBanner(backgroundImage);
    }
  }, [currentUser?.bannerImg, currentUser?._id, IMAGE_SERVER, currentUser?.bannerImg, currentUser]);

  useEffect(() => {
    if (IMAGE_SERVER && currentUser?.profileImg !== (null || undefined)) {
      if (currentUser?.profileImg?.startsWith("http")) {
        setProfileImg(currentUser?.profileImg);
      } else {
        setProfileImg(`${IMAGE_SERVER}/users/${currentUser?._id}/photo/${currentUser?.profileImg}`);
      }
    } else {
      const image =
        user?.picture && user.email.indexOf("@gmail.com") > -1 ? user.picture : backgroundImage;
      setProfileImg(image);
    }
  }, [
    currentUser?.profileImg,
    currentUser?._id,
    IMAGE_SERVER,
    user.picture,
    user.email,
    currentUser,
  ]);

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("applicants");
      setCurrentApplicant({ ...currentApplicant, ...data });
      setFiles(data?.attachments);
      enqueueSnackbar("Attachments updated!", { variant: "success" });
    },
  });

  const addNew = (e) => {
    setAttachmentOpen(true);
  };

  const deleteFileHandler = async (idx) => {
    if (currentApplicant) {
      // delete from DB
      const tempAttachments = [...files];
      const updatedAttachments = tempAttachments.filter((_, i) => i !== idx);

      await updateApplicantMutation.mutateAsync({
        applicantId: currentApplicant?._id,
        data: {
          attachments: updatedAttachments,
        },
      });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const size = isMobile ? 70 : 100;

  useEffect(() => {
    if (currentApplicant?.attachments?.length) {
      setFiles(
        currentApplicant?.attachments.map((file) => {
          const { docType, filename, name, title, type, uploadDate } = file;
          return { docType, name: filename || name, title, type };
        })
      );
    }
  }, [currentApplicant?.attachments]);

  const deleteMedia = [
    { path: "", name: "", lastModified: "", size: "", type: "", webkitRelativePath: "" },
  ];

  const headerMediaDelete = () => {
    setConfirmModalApplicant({
      isOpen: true,
      title: "Delete Header Media",
      bodyText: "Are you sure you want to delete header media",
      response: false,
      description: "Are you sure you want to delete header media",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          onDropBannerHandler(deleteMedia);
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };
  const profilePictureDelete = () => {
    setConfirmModalApplicant({
      isOpen: true,
      title: "Delete Profile Picture",
      bodyText: "Are you sure you want to delete profile picture",
      response: false,
      description: "Are you sure you want to delete  profile picture",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          onDropImageHandler(deleteMedia);
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };
  const signatureDelete = () => {
    setConfirmModalApplicant({
      isOpen: true,
      title: "Delete Signature",
      bodyText: "Are you sure you want to delete signature",
      response: false,
      description: "Are you sure you want to delete signature",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          onDropSignatureImageHandler(deleteMedia);
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };
  const attachmentsDelete = (idx) => {
    setConfirmModalApplicant({
      isOpen: true,
      title: "Delete Attachments",
      bodyText: "Are you sure you want to delete attachment",
      response: false,
      description: "Are you sure you want to delete attachment",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          deleteFileHandler(idx);
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };

  return (
    <PanelSelectorBox
      title="User Attachments"
      icon={<AttachFile />}
      iconColor="warning"
      cardVariant="outlined"
    >
      <Grid
        container
        sx={{ padding: "20px 30px", width: "100%", height: "620px", overflow: "scroll" }}
      >
        <Grid item sm={12} mt={2}>
          {isEdit && (
            <MDBox style={{ marginBottom: "10px" }}>
              {/* <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
                <MDTypography variant="h6" className={styles.titleBar_title}>
                  Header Media
                </MDTypography>
              </MDBox> */}
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={12}>
                  <FileDropzone
                    onDrop={onDropBannerHandler}
                    message="Drag & Drop or Choose file to upload"
                    maxSize={1048576}
                    size="8rem"
                    icon={<UploadFileIcon />}
                    customStyles={classes}
                  />
                </Grid>
                {currentUser?.bannerImg && (
                  <Grid item sm={12}>
                    <MDBox className={styles.section_image_container}>
                      <IconButton
                        color="info"
                        className={styles.section_image_delete}
                        onClick={headerMediaDelete}
                      >
                        <Icon fontSize="small">cancel</Icon>
                      </IconButton>
                      <MDBox style={{ height: "100px", width: "207px" }}>
                        {IMAGE_SERVER && (
                          <img
                            src={banner}
                            alt="previews"
                            style={{ height: "100px", width: "207px" }}
                          />
                        )}
                      </MDBox>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          )}
          {isEdit && (
            <MDBox style={{ marginBottom: "10px" }}>
              <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
                <MDTypography variant="h6" className={styles.titleBar_title}>
                  Profile Picture
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={12}>
                  <FileDropzone
                    message="Drag & Drop or Choose file to upload"
                    onDrop={onDropImageHandler}
                    maxSize={1048576}
                    size="8rem"
                    icon={<UploadFileIcon />}
                    customStyles={classes}
                  />
                </Grid>
                {currentUser?.profileImg && (
                  <Grid item sm={12}>
                    <MDBox className={styles.section_image_container}>
                      <IconButton
                        color="info"
                        className={styles.section_image_delete}
                        onClick={profilePictureDelete}
                      >
                        <Icon fontSize="small">cancel</Icon>
                      </IconButton>
                      <Stack>
                        <MDBox style={{ height: "100px", width: "100px" }} mb={1}>
                          {IMAGE_SERVER && (
                            <img
                              src={profileImg}
                              alt="previews"
                              style={{ height: "100px", width: "100px" }}
                            />
                          )}
                        </MDBox>
                      </Stack>
                    </MDBox>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          )}
          {currentApplicant?._id && (userType === "Admin" || userType === "Master") ? (
            <MDBox>
              <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
                <MDTypography variant="h6" className={styles.titleBar_title}>
                  Signature
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={12}>
                  <FileDropzone
                    message="Drag & Drop or Choose file to upload"
                    onDrop={onDropSignatureImageHandler}
                    maxSize={1048576}
                    size="8rem"
                    icon={<UploadFileIcon />}
                    customStyles={classes}
                  />
                </Grid>

                <Grid item sm={12}>
                  <MDBox className={styles.section_image_container}>
                    <IconButton
                      color="info"
                      className={styles.section_image_delete}
                      onClick={signatureDelete}
                    >
                      <Icon fontSize="small">cancel</Icon>
                    </IconButton>
                    <Stack>
                      <Box sx={{ maxHeight: "100px", maxWidth: "260px" }}>{signatureAvatar}</Box>
                    </Stack>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          ) : null}
          {isEdit && (
            <MDBox style={{ marginTop: "20px" }}>
              <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
                <MDTypography variant="h6" className={styles.titleBar_title}>
                  Additional Attachments
                </MDTypography>
              </MDBox>
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={12}>
                  <Box
                    className={classes.container}
                    sx={{ background: "white", height: "8rem", cursor: "pointer" }}
                    onClick={addNew}
                  >
                    <UploadFileIcon />
                    <MDTypography variant="p" className={classes.text}>
                      Drag & Drop or Choose file to upload
                    </MDTypography>
                  </Box>
                </Grid>
              </Grid>
              {files?.length > 0 && (
                <MDBox className={styles.section_images_wapper}>
                  <Box
                    sx={{
                      maxWidth: "100%",
                      minHeight: "130px",
                      overflowX: "auto",
                      width: "100",
                      height: "150px",
                      display: "flex",
                    }}
                  >
                    {files.map((file, idx) => {
                      return (
                        <Box className={styles.section_image_container2} key={file?.name}>
                          <Stack
                            container
                            justifyContent="space-evenly"
                            alignItems="center"
                            mb={3}
                            pt={1}
                          >
                            <MDBox
                              style={{
                                position: "absolute",
                                right: "-10px",
                                top: "-10px",
                                padding: 1,
                                borderRadius: "50%",
                                zIndex: "1000",
                              }}
                            >
                              <IconButton
                                color="error"
                                sx={{ padding: "0px", background: "#fff", zIndex: "1000" }}
                                onClick={(e) => attachmentsDelete(idx)}
                              >
                                <Icon fontSize="small">cancel</Icon>
                              </IconButton>
                            </MDBox>

                            <MDBox
                              onClick={() => {
                                if (IMAGE_SERVER)
                                  window.open(
                                    `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file?.type}/${file?.name}`
                                  );
                              }}
                            >
                              {IMAGE_SERVER &&
                                [
                                  "jpeg",
                                  "jpg",
                                  "png",
                                  "gif",
                                  "heic",
                                  "jfif",
                                  "svg",
                                  "webp",
                                ].includes(file.docType.toLowerCase()) && (
                                  <img
                                    src={`${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file?.type}/${file?.name}`}
                                    alt="previews"
                                    className={styles.section_image}
                                    height={size}
                                  />
                                )}
                              {!!getCompanyImageUrl(company) &&
                                !!company?.uploadUrl &&
                                file?.docType.toLowerCase() === "pdf" && (
                                  <img
                                    src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                                    alt="PDF Preview"
                                    width={size}
                                    height={size}
                                  />
                                )}
                              {!!getCompanyImageUrl(company) &&
                                !!company?.uploadUrl &&
                                file?.docType.toLowerCase() === "docx" && (
                                  <img
                                    src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                                    alt="preview"
                                    width={size}
                                    height={size}
                                  />
                                )}
                            </MDBox>
                            <Box>
                              <Typography sx={{ fontSize: "12px" }}>{file?.name}</Typography>
                            </Box>
                          </Stack>
                        </Box>
                      );
                    })}
                  </Box>
                </MDBox>
              )}
            </MDBox>
          )}
        </Grid>
      </Grid>

      <ConfirmModal
        title={confirmModalApplicant.title}
        description={confirmModalApplicant.description}
        positiveBtn={confirmModalApplicant.positiveBtn}
        negativeBtn={confirmModalApplicant.negativeBtn}
        isOpen={confirmModalApplicant.isOpen}
        closeFn={closeModalHandler}
      />
      <UploadFileModal
        currentApplicant={currentApplicant}
        setCurrentApplicant={setCurrentApplicant}
        open={attachmentOpen}
        setOpen={setAttachmentOpen}
      />
    </PanelSelectorBox>
  );
};

export default ProfileAttachments;
