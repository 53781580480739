import SaveIcon from "@mui/icons-material/Save";
import { Button, Grid, Typography } from "@mui/material";
import fetchCustomers from "api/companies/fetchCustomers";
import colors from "assets/theme/base/colors";
import DataListContainer from "components/DataListContainer";
import useDropdowns from "hooks/useDropdowns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useSort from "utils/useSort";
import { useSnackbar } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmDialog from "components/ConfirmDialog";
import { transformYupErrorsIntoObject } from "utils/helpers/formHelpers";
import OrganizationsModal from "../OrganizationsModal";
import PanelSelectorBox from "../PanelSelectorBox";
import OrganizationsRow from "./OrganizationsRow";
import OrganizationForm from "./OrganizationForm";
import organizationsSchema from "../OrganizationsModal/organizationsSchema";
import updateProfile from "../../actions/updateProfile";

const emptyValues = {
  slug: "",
  userType: "",
  status: "",
  primary: "",
};

const ProfileOrganizations = ({
  currentUser,
  venues,
  isCompany,
  fetchAll = true,
  page,
  limit,
  filters,
  company,
  userApplicant,
  setCurrentUser,
}) => {
  const {
    setValue,
    watch,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { isDirty },
  } = useForm({ mode: "onBlur", defaultValues: currentUser });

  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const isVenue = company?.companyType === "Venue";

  const [organizationDetailOpen, setOrganizationDetailOpen] = useState(false);
  const [isOrgModalOpen, setOrgModalOpen] = useState(false);
  const [orgIndex, setOrgIndex] = useState(null);
  const { dropdowns: clientUserTypes } = useDropdowns({ entity: "clientUserTypes" });
  const { dropdowns: clientUserStatuses } = useDropdowns({ entity: "clientUserStatuses" });
  const { dropdowns: userAdminStatuses } = useDropdowns({
    entity: "userAdminStatuses",
    enabled: isVenue,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [isModified, setIsModified] = useState(false);
  const queryClient = useQueryClient();
  const [currentOrganization, setCurrentOrganization] = useState(emptyValues);

  const orgSlug = watch("slug");
  const orgUserType = watch("userType");
  const orgStatus = watch("status");
  const orgPrimary = watch("primary");

  const [slug, setSlug] = useState(orgSlug);
  const [userType, setUserType] = useState(orgUserType);
  const [status, setStatus] = useState(orgStatus);
  const [primary, setPrimary] = useState(orgPrimary);

  useEffect(() => {
    setSlug(orgSlug);
    setUserType(orgUserType);
    setStatus(orgStatus);
    setPrimary(orgPrimary);
  }, [orgPrimary, orgSlug, orgStatus, orgUserType]);

  const organizations = watch("clientOrgs");

  const editOrganization = useCallback(async () => {
    const newOrg = { slug, userType, status, primary, modifiedDate: new Date().toISOString() };
    await organizationsSchema
      .validate(newOrg, { abortEarly: false })
      .then(() => {
        let tempOrgs;
        if (orgIndex !== null) {
          tempOrgs = organizations.map((item, index) => {
            if (index === orgIndex) return newOrg;
            if (primary === "Yes") return { ...item, primary: "No" };
            return item;
          });
        }
        if (orgIndex !== null) setValue("clientOrgs", tempOrgs);
      })
      .catch((error) => {
        const validationErrors = transformYupErrorsIntoObject(error);
      });
  }, [orgIndex, primary, slug, status, userType]);

  useEffect(() => {
    editOrganization();
  }, [editOrganization]);

  const handleOrganizationsSelect = (data, idx) => {
    setOrganizationDetailOpen(true);
    setOrgIndex(idx);
    if (idx === null) {
      setCurrentOrganization(emptyValues);
    } else {
      setCurrentOrganization(data);
    }
  };

  const handleOrganizationDelete = (data, idx) => {
    setOrganizationDetailOpen(false);
    setOrgIndex(null);
    setCurrentOrganization(emptyValues);

    const orgs = watch("clientOrgs") || [];

    const tempOrgs = orgs.filter((dt, index) => index !== idx);

    setValue("clientOrgs", tempOrgs, { shouldDirty: true });
  };

  const { order, orderBy } = useSort();
  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };

  const allOptions = {
    ...options,
    filters: {
      ...filters,
      status: "Active",
    },
    useOr: true,
  };
  const { data: companies } = useQuery(
    ["customers", allOptions],
    () => fetchCustomers(allOptions),
    { refetchInterval: 300000 }
  );

  const allVenues = Object?.values(venues || []);
  const allCompanies = Object.values(companies?.data || []);

  const renderRow = (row, idx) => (
    <OrganizationsRow
      row={row}
      idx={idx}
      allVenues={allVenues}
      isCompany={isCompany}
      allCompanies={allCompanies}
      handleOrganizationsSelect={handleOrganizationsSelect}
      handleOrganizationDelete={handleOrganizationDelete}
      company={company}
    />
  );

  useEffect(() => {
    if (currentOrganization) {
      setValue("slug", currentOrganization?.slug);
      setValue("userType", currentOrganization?.userType);
      setValue("status", currentOrganization?.status);
      setValue("primary", currentOrganization?.primary);
    }
  }, [currentOrganization]);

  const userTypeOptions = clientUserTypes || [];
  const statusOptions =
    currentUser?.userType === "Client" ? clientUserStatuses : userAdminStatuses || [];

  useEffect(() => {
    if (
      currentUser?.userType === "Client" ||
      (currentUser?.userType === "User" && currentUser?.employeeType === "Event Admin")
    )
      setValue("clientOrgs", currentUser?.clientOrgs);
    setOrgIndex(null);
  }, [currentUser?._id]);

  const handleAdd = () => {
    setOrgIndex(null);
    setOrganizationDetailOpen(false);
    setOrgModalOpen(true);
  };

  const updateUserMutation = useMutation(updateProfile, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      enqueueSnackbar("User has been updated!", { variant: "success" });
      setIsModified(false);
      setOrganizationDetailOpen(false);
    },
  });

  const getUserValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentUser?.[key] || "";
    });
    return values;
  };

  function diffObj(obj1, obj2) {
    const result = {};
    return result;
  }

  const saveChanges = async () => {
    if (!currentUser?.userId) {
      enqueueSnackbar("Something went wrong!, no Id", { variant: "error" });
      return;
    }
    const values = diffObj(getUserValues(), watch());

    if (
      (currentUser?.userType === "Client" ||
        (currentUser?.userType === "User" && currentUser?.employeeType === "Event Admin")) &&
      watch("clientOrgs") !== currentUser?.clientOrgs
    )
      values.clientOrgs = watch("clientOrgs");

    await updateUserMutation.mutateAsync({
      userId: currentUser?.userId,
      data: values,
    });
  };

  useEffect(() => {
    const subscription = watch((value) => {
      if (currentUser) {
        setIsModified(JSON.stringify(getUserValues()) !== JSON.stringify(value));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, getUserValues, currentUser]);

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const cancelChanges = () => {
    reset(getUserValues());
    setIsModified(false);
    enqueueSnackbar("Changes has been abandoned", { variant: "success" });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  useEffect(() => {
    if (confirmModal.response) {
      resetConfirmModal();
      cancelChanges();
    }
    return "";
  }, [getValues, userApplicant, confirmModal.response]);

  const Header = () => {
    return (
      <>
        {isDirty && isModified ? (
          <>
            <Button startIcon={<CancelIcon />} onClick={onCancel}>
              Cancel
            </Button>
            <Button sx={{ padding: "0", marginRight: "20px" }} type="submit">
              <SaveIcon
                sx={{
                  color: colors?.success?.main,
                  marginRight: "3px",
                  width: "15px",
                  height: "15px",
                }}
              />
              <Typography sx={{ fontSize: "15px", color: colors?.success?.main }}> Save</Typography>
            </Button>
          </>
        ) : null}
      </>
    );
  };
  return (
    <form onSubmit={handleSubmit(saveChanges)}>
      <PanelSelectorBox
        title="Organizations"
        icon="business"
        iconColor="info"
        cardVariant="outlined"
        dateSelected={currentUser?.modifiedDate}
        header={<Header />}
      >
        <Grid item sm={12} sx={{ padding: "20px" }}>
          <DataListContainer
            onAdd={handleAdd}
            title="Organizations"
            data={watch("clientOrgs") || []}
            renderRow={(row, idx) => renderRow(row, idx)}
            height={302}
            tableHeight={300}
            selected={orgIndex}
          />
        </Grid>
        {organizationDetailOpen && (
          <OrganizationForm
            userTypeOptions={userTypeOptions}
            statusOptions={statusOptions}
            control={control}
            setValue={setValue}
          />
        )}
        <ConfirmDialog
          state={confirmModal}
          setState={setConfirmModal}
          setOrganizationDetailOpen={setOrganizationDetailOpen}
        />
        <OrganizationsModal
          isOpen={isOrgModalOpen}
          setOpen={setOrgModalOpen}
          setValue={setValue}
          organizations={watch("clientOrgs") || []}
          orgIndex={orgIndex}
          userRecordUserType={currentUser?.userType}
        />
      </PanelSelectorBox>
    </form>
  );
};

export default ProfileOrganizations;
