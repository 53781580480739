import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import ApplicantMoreButton from "components/ApplicantMoreButton";
import { useMemo } from "react";

const ApplicantsTableActions = ({
  id,
  type,
  row,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setModalInfo,
  parentTable,
  onRefresh,
}) => {
  const { company, userType } = useAppContextController();
  const navigate = useNavigate();

  const navigateLocal = setNavigateUrl ?? navigate;

  const isNotClient = userType !== "Client";

  const route = type === "Staffing" ? "employees" : "applicants";
  const actionroute = type === "Staffing" ? "employee" : "applicant";
  const namePrefix = type === "Staffing" ? "Employee" : "Applicant";
  const onClickHandler = (action, e) => {
    e.stopPropagation();
    console.log(action);
    if (action === "applicantmail" || action === "employeemail") {
      setCurrentApplicant(row);
      setModalInfo({ type: action, data: row });
      setGenericModalOpen(true);
    } else {
      navigateLocal(`/${route}/${id}/action/${action}`);
    }
  };

  return (
    <span>
      {isNotClient && (
        <>
          <TableAction
            color="info"
            icon="info"
            clickAction={(e) => onClickHandler(`${actionroute}info`, e)}
            tooltip={`${namePrefix} Info`}
          />
        </>
      )}
      {isNotClient && (
        <ApplicantMoreButton
          applicant={row}
          onSuccess={onRefresh}
          extraButtons={[
            ...(company?.companyType === "Venue"
              ? [
                  {
                    color: "info",
                    icon: "stadium",
                    clickAction: (e) => onClickHandler("applicantvenues", e),
                    tooltip: `${namePrefix} Venues`,
                  },
                ]
              : [
                  {
                    color: "info",
                    icon: "business",
                    clickAction: (e) => onClickHandler("applicantcustomers", e),
                    tooltip: `${namePrefix} Customers`,
                  },
                ]),
            {
              color: "warning",
              icon: "work",
              clickAction: (e) => onClickHandler("applicantjobs", e),
              tooltip: `${namePrefix} Jobs`,
            },
            ...(company?.companyType === "Venue"
              ? [
                  {
                    color: "success",
                    icon: "event-seat",
                    clickAction: (e) => onClickHandler("applicantevents", e),
                    tooltip: `${namePrefix} Event`,
                  },
                ]
              : []),
            {
              color: "error",
              icon: "mail",
              clickAction: (e) => onClickHandler("applicantmail", e),
              tooltip: "Send Message",
            },
          ]}
        />
      )}
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler(`${actionroute}all`, e)}
        tooltip="All Applicant Information"
      /> */}
    </span>
  );
};

export default ApplicantsTableActions;

ApplicantsTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
ApplicantsTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
