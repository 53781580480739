/* eslint-disable no-else-return */
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { AddCircleRounded, Assessment, Download, PlayCircle, Save } from "@mui/icons-material";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import MDTypography from "components/MDTypography";
import CustomCard from "components/CustomCard";
import PanelActions from "components/PanelActions";
import fetchJobActivityReport from "api/jobs/fetchJobActivityReport";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomTextField from "components/Form/CustomTextField";
import moment from "moment";
import saveReportPanel from "api/reports/createReportPanel";
import exportJobActivity from "api/jobs/exportJobActivity";
import ReportDetails from "./ReportDetails";
import StatModal from "./StatModal";

const JobsReportPanel = ({ name, displayPosition = false }) => {
  const { isMobile, currentJob, company } = useAppContextController();
  const [statModalOpen, setStatModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState([]);
  const [activityData, setActivityData] = useState(null);
  const [applicantSource, setApplicantSource] = useState(null);
  const [applicantStage, setApplicantStage] = useState(null);
  const [interviewActivity, setInterviewActivity] = useState(null);
  const [staffingPoolActivity, setStaffingPoolActivity] = useState(null);
  const [eventActivity, setEventActivity] = useState(null);
  const [period, setPeriod] = useState({ value: "This Week", label: "This Week" });
  const [isLoading, setIsLoading] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [details, setDetails] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateErrors, setDateErrors] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Show error toast
  const showError = (message) => {
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const validateDates = (start, end) => {
    const errors = {
      startDate: null,
      endDate: null,
    };

    if (start) {
      const startMoment = moment(start);
      if (!startMoment.isValid()) {
        errors.startDate = "Invalid date format";
        showError("Invalid start date format");
        return false;
      } else if (startMoment.isAfter(moment())) {
        errors.startDate = "Start date cannot be in the future";
        showError("Start date cannot be in the future");
        return false;
      }
    }

    if (end) {
      const endMoment = moment(end);
      if (!endMoment.isValid()) {
        errors.endDate = "Invalid date format";
        showError("Invalid end date format");
        return false;
      } else if (endMoment.isAfter(moment())) {
        errors.endDate = "End date cannot be in the future";
        showError("End date cannot be in the future");
        return false;
      }
    }

    if (start && end) {
      const startMoment = moment(start);
      const endMoment = moment(end);
      if (startMoment.isValid() && endMoment.isValid() && startMoment.isAfter(endMoment)) {
        errors.startDate = "Start date must be before end date";
        errors.endDate = "End date must be after start date";
        showError("Start date must be before end date");
        return false;
      }
    }

    setDateErrors(errors);
    return !errors.startDate && !errors.endDate;
  };

  const companyType = company?.companyType?.toLowerCase() || "venue";

  const periodOptions = [
    { value: "This Week", label: "This Week" },
    { value: "This Month", label: "This Month" },
    { value: "This Year", label: "This Year" },
    { value: "Custom Range", label: "Custom Range" },
  ];

  const getPeriodTitle = () => {
    if (period?.value === "Custom Range" && dateRange.startDate && dateRange.endDate) {
      return `Activity (${moment(dateRange.startDate).format("MM/DD/YYYY")} - ${moment(
        dateRange.endDate
      ).format("MM/DD/YYYY")})`;
    }

    switch (period?.value) {
      case "This Month":
        return "This Month's Activity";
      case "This Year":
        return "This Year's Activity";
      default:
        return "This Week's Activity";
    }
  };

  const getCompanyTypeStats = () => {
    if (!activityData) return [];

    if (companyType === "company") {
      return [
        {
          name: "New Applies",
          number: activityData?.new?.total || 0,
          breakdown: activityData?.new?.breakdown || activityData?.new?.daily || {},
        },
        {
          name: "Submittals",
          number: activityData?.submittals?.total || 0,
          breakdown: activityData?.submittals?.breakdown || activityData?.submittals?.daily || {},
        },
        {
          name: "Interviews",
          number: activityData?.interviews?.total || 0,
          breakdown: activityData?.interviews?.breakdown || activityData?.interviews?.daily || {},
        },
        {
          name: "Fills",
          number: activityData?.fills?.total || 0,
          breakdown: activityData?.fills?.breakdown || activityData?.fills?.daily || {},
        },
      ];
    }

    return [
      {
        name: "New",
        number: activityData?.new?.total || 0,
        breakdown: activityData?.new?.breakdown || activityData?.new?.daily || {},
      },
      {
        name: "On Assignment",
        number: activityData?.onAssignment?.total || 0,
        breakdown: activityData?.onAssignment?.breakdown || activityData?.onAssignment?.daily || {},
      },
      {
        name: "Pending",
        number: activityData?.pending?.total || 0,
        breakdown: activityData?.pending?.breakdown || activityData?.pending?.daily || {},
      },
      {
        name: "Declined",
        number: activityData?.declined?.total || 0,
        breakdown: activityData?.declined?.breakdown || activityData?.declined?.daily || {},
      },
    ];
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm();

  const fetchJobActivity = useCallback(async () => {
    if (period?.value === "Custom Range") {
      if (!dateRange.startDate || !dateRange.endDate) {
        showError("Both start and end dates are required for custom range");
        return;
      }
      const isValid = validateDates(dateRange.startDate, dateRange.endDate);
      if (!isValid) {
        return;
      }
    }

    try {
      setIsLoading(true);
      setIsSaved(false);

      const periodMap = {
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Custom Range": "custom",
      };

      const params = {
        jobSlug: currentJob?.jobSlug,
        period: periodMap[period?.value],
      };

      if (period?.value === "Custom Range") {
        params.startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
        params.endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
      }

      const response = await fetchJobActivityReport(params);

      setDetails(response.data);
      setActivityData(response.data.activitySummary);
      setApplicantSource(response.data.applicantSource);
      setApplicantStage(response.data.applicantStageActivity);
      setInterviewActivity(response.data.interviewActivity);
      setStaffingPoolActivity(response.data.staffingPoolActivity);
      setEventActivity(response.data.eventActivity);
    } catch (error) {
      console.error("error: ", error);
      showError("Failed to fetch activity report");
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJob?.jobSlug, dateRange, period?.value]);

  const handleSaveReport = async () => {
    try {
      setIsLoading(true);

      const response = await saveReportPanel({
        feature: "jobs",
        details,
        recordId: currentJob?._id,
      });

      if (response.data.success) {
        setIsSaved(true);
        setSnackbar({
          open: true,
          message: "Report panel saved successfully",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error saving report panel:", error);
      setSnackbar({
        open: true,
        message: "Failed to save report panel",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportReport = async (fileType) => {
    try {
      setIsLoading(true);

      const periodMap = {
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Custom Range": "custom",
      };

      const params = {
        jobSlug: currentJob?.jobSlug,
        period: periodMap[period?.value],
        fileType,
      };

      if (period?.value === "Custom Range") {
        params.startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
        params.endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
      }

      handleClose();

      await exportJobActivity(params);

      setSnackbar({
        open: true,
        message: "Report exported successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error export report panel:", error);
      setSnackbar({
        open: true,
        message: "Failed to export report panel",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePeriodChange = (value) => {
    // If value is null (cleared), default to "This Week"
    const newPeriod = value || { value: "This Week", label: "This Week" };
    setPeriod(newPeriod);
    setShowDateRange(newPeriod.value === "Custom Range");
    if (newPeriod.value !== "Custom Range") {
      setDateRange({ startDate: null, endDate: null });
    }
  };

  useEffect(() => {
    if (period?.value !== "Custom Range" || (dateRange.startDate && dateRange.endDate)) {
      fetchJobActivity();
    }
  }, [dateRange.endDate, dateRange.startDate, fetchJobActivity, period?.value]);

  const onSubmit = () => console.log("submit");

  const handleCancelChanges = () => {
    reset();
  };

  const handleStatClick = (stat) => {
    if (period.value === "Custom Range") {
      return;
    }

    setModalTitle(stat.name);

    // For custom range, don't show the modal since we only have totals

    // Handle predefined periods
    let labels = [];
    switch (period.value) {
      case "This Week":
        labels = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        break;
      case "This Month":
        labels = ["WEEK1", "WEEK2", "WEEK3", "WEEK4", "WEEK5"];
        break;
      case "This Year":
        labels = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        break;
      default:
        break;
    }

    const breakdown = stat.breakdown || stat.daily || {};
    const chartData = labels.map((label) => ({
      name: label,
      value:
        period.value === "This Month"
          ? breakdown[label] || 0
          : breakdown[label.toLowerCase().slice(0, 4)] || 0,
    }));

    setModalData(chartData);
    setStatModalOpen(true);
  };

  const stats = getCompanyTypeStats();

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomCard
          icon={<Assessment color="white" />}
          cardTitle={`Job Report Data: ${name}`}
          cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
          {...customPanelCard(isMobile, "info")}
        >
          {/* First row: Period selector and Days Open */}
          <Stack spacing={2} sx={styles.container}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                mb: 2,
                pt: 2,
                backgroundColor: "white",
                borderRadius: "8px",
                "& .MuiAutocomplete-root": {
                  width: "280px",
                },
              }}
            >
              <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Autocomplete
                    options={periodOptions}
                    value={period}
                    onChange={(event, value) => handlePeriodChange(value)}
                    renderInput={(params) => <CustomTextField {...params} label="Select Period" />}
                  />

                  <Box gap={1} display="flex">
                    <IconButton
                      color="success"
                      onClick={handleSaveReport}
                      sx={{ padding: 0 }}
                      disabled={isLoading || isSaved || !details}
                    >
                      <Save />
                    </IconButton>

                    <IconButton
                      color="info"
                      onClick={handleClick}
                      sx={{ padding: 0 }}
                      disabled={isLoading}
                    >
                      <Download />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem onClick={() => handleExportReport("csv")}>CSV</MenuItem>
                      <MenuItem onClick={() => handleExportReport("xlsx")}>Excel</MenuItem>
                    </Menu>
                  </Box>
                </Stack>

                <Collapse in={showDateRange}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Start Date"
                        renderInput={(params) => <CustomTextField {...params} />}
                        value={dateRange.startDate}
                        maxDate={moment()}
                        onChange={(date) =>
                          setDateRange((prev) => ({
                            ...prev,
                            startDate: date ? date.toISOString() : null,
                          }))
                        }
                      />
                      <DatePicker
                        label="End Date"
                        renderInput={(params) => <CustomTextField {...params} />}
                        value={dateRange.endDate}
                        maxDate={moment()} // Prevent future dates
                        minDate={dateRange.startDate ? moment(dateRange.startDate) : undefined}
                        onChange={(date) =>
                          setDateRange((prev) => ({
                            ...prev,
                            endDate: date ? date.toISOString() : null,
                          }))
                        }
                      />
                    </LocalizationProvider>
                  </Stack>
                </Collapse>
              </Stack>
            </Stack>

            {/* Action buttons */}

            <Divider sx={{ bgcolor: "gray" }} />

            {/* Title with navigation arrows */}
            <Stack direction="row" justifyContent="center">
              <MDTypography textAlign="center" fontWeight="medium">
                {getPeriodTitle()}
              </MDTypography>
            </Stack>

            {isLoading ? (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                  <CircularProgress
                    size={60}
                    thickness={4}
                    sx={{
                      color: "#5757FF",
                      "& .MuiCircularProgress-circle": {
                        strokeLinecap: "round",
                      },
                    }}
                  />
                </Box>
              </>
            ) : (
              <>
                <Divider sx={{ bgcolor: "gray" }} />

                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                  {stats.map((stat) => (
                    <Box
                      key={stat.name}
                      width={200}
                      px={2}
                      py={1}
                      borderRadius={2}
                      sx={{
                        backgroundColor: "#5757FF",
                        cursor: "pointer",
                      }}
                      onClick={() => handleStatClick(stat)}
                    >
                      <MDTypography color="white" fontWeight="medium">
                        {stat.number}
                      </MDTypography>
                      <MDTypography color="white" fontSize="1rem">
                        {stat.name}
                      </MDTypography>
                    </Box>
                  ))}
                </Stack>

                {/* Report Details */}
                <ReportDetails
                  applicantSource={applicantSource}
                  applicantStage={applicantStage}
                  interviewActivity={interviewActivity}
                  staffingPoolActivity={staffingPoolActivity}
                  eventActivity={eventActivity}
                />
              </>
            )}

            {/* Stats boxes */}
          </Stack>
        </CustomCard>
        <StatModal
          open={statModalOpen}
          setOpen={setStatModalOpen}
          title={modalTitle}
          data={modalData}
        />
      </form>
    </>
  );
};

export default JobsReportPanel;
