// import { useAppContextController } from "context/AppContext";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Icon,
  Modal,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const GenericModal = ({
  open,
  setOpen,
  header,
  body,
  icon = null,
  iconColor,
  img,
  iconTitle = null,
  buttons,
  media = null,
  width = "50%",
  backgroundColor = "white",
  buttonLocation = "end",
  sx,
  closableByClickingAway = true,
  borderRadius = "20px",
  overflow = "auto",
  contentPadding,
  maxWidth,
  maxHeight,
  pdfButton,
}) => {
  const useStyle = makeStyles({
    container: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius,
      backgroundColor,
      width,
      maxWidth,
      overflow: icon || img ? "visible" : overflow,
      maxHeight: maxHeight || "calc(100vh - 100px)",
    },
    buttons: {
      display: "flex",
      justifyContent: buttonLocation,
    },
  });

  const classes = useStyle();
  const handleClose = (e) => {
    e?.stopPropagation();
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      onClose={
        closableByClickingAway
          ? (event) => handleClose(event)
          : (event, reason) => {
              if (reason === "escapeKeyDown") {
                handleClose(event);
              }
            }
      }
      disableEnforceFocus
      style={{ overflow: "scroll" }}
    >
      {/* //     <Fade > */}
      <Card className={classes.container} sx={(theme) => sx?.(theme)}>
        {(icon || img) && (
          <Stack display="flex" direction="row">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor={img ? "white" : iconColor}
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-2}
            >
              <Icon fontSize="large">{icon}</Icon>
            </MDBox>
            {iconTitle && (
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {iconTitle}
              </MDTypography>
            )}
          </Stack>
        )}
        <CardContent sx={{ p: contentPadding }}>
          {media && <CardMedia>{media}</CardMedia>}
          {header && <CardHeader title={header} subheader="" />}
          {body && <CardContent sx={{ p: contentPadding }}>{body}</CardContent>}
          {buttons && (
            <CardActions className={classes.buttons}>
              {pdfButton && pdfButton} {buttons}
            </CardActions>
          )}
        </CardContent>
      </Card>
      {/* // </Fade> */}
    </Modal>
  );
};

export default GenericModal;
