import { Grid } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import colors from "assets/theme/base/colors";

const OrganizationsRow = ({
  row,
  idx,
  allVenues,
  isCompany,
  allCompanies,
  handleOrganizationsSelect,
  handleOrganizationDelete,
  company,
}) => {
  let venue = {};
  if (allVenues && !isCompany) {
    venue = allVenues?.find((obj) => obj?.slug === row.slug);
  }

  let customersValue = {};
  if (allCompanies && isCompany) {
    customersValue = allCompanies?.find((obj) => obj?.slug === row?.slug);
  }
  return (
    <>
      <MDBox
        key={`${row?.name}_${row?.slug}`}
        display="flex"
        justifyContent="space-between"
        onClick={() => handleOrganizationsSelect(row, idx)}
      >
        <Grid item xs={12} sm={2} lg={1.5}>
          {!isCompany ? (
            <VenueIcon logoUrl={venue?.logoUrl} slug={venue?.slug} name={venue?.name} />
          ) : (
            <CompanyLogoSquare company={customersValue || {}} defaultCompany={company} />
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={8.5}>
          <Grid container sx={{ width: "100%", height: "100%", paddingLeft: "30px" }}>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: " flex",
                justifyContent: "flex-start",
                alignItems: " center",
                width: "100%",
                height: "50%",
              }}
            >
              <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                {isCompany === false ? venue?.name : customersValue?.name}
              </MDTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: " flex",
                justifyContent: "flex-start",
                alignItems: " center",
                width: "100%",
                height: "50%",
              }}
            >
              <MDTypography variant="body2">
                {isCompany === false ? venue?.city : customersValue?.city}
                {isCompany === false ? venue?.state : customersValue?.state}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container sx={{ flexDirection: "row", alignItems: "center", gap: 2 }}>
            <Grid item sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <DeleteIcon
                onClick={() => handleOrganizationDelete(row, idx)}
                sx={{ color: colors?.error?.main, width: "35px", height: "35px" }}
              />
            </Grid>

            <Grid item sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <SettingsIcon sx={{ color: colors?.info?.main, width: "35px", height: "35px" }} />
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
};

export default OrganizationsRow;
