import { Box, Stack, Typography } from "@mui/material";
import GenericModal from "components/GenericModal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import MDButton from "components/MDButton";
import { useMutation, useQueryClient } from "react-query";
import { updateYardstikStatusToProceed } from "api/yardstik/updateYardstikStatusToProceed";
import { useSnackbar } from "notistack";
import createYardstikAdverseAction from "api/yardstik/createYardstikAdverseAction";
import cancelYardstikAdverseAction from "api/yardstik/cancelYardstikAdverseAction";
import { useAppContextController } from "context/AppContext";
import { useState } from "react";
import MDInput from "components/MDInput";
import DeleteIcon from "@mui/icons-material/Delete";

const YardstikAttachmentModal = ({ getFilePreview, open, setOpen }) => {
  const { currentApplicant, setCurrentApplicant, currentLoggedUser, userType, company } =
    useAppContextController();
  const queryClient = useQueryClient();

  const [isOpenAddViolations, setIsOpenAddViolations] = useState(false);
  const [violations, setViolations] = useState([{ description: "" }]);

  const isEmployee = currentApplicant?.status === "Employee";

  const applicantName = `${currentApplicant.firstName} ${currentApplicant.lastName}`;
  const yardstikData = currentApplicant.yardstik;

  const { mutateAsync: updateYardstikStatus, isLoading: isUpdatingYardstikStatus } = useMutation(
    updateYardstikStatusToProceed
  );
  const { mutateAsync: sendPreAdverseViolationNotice, isLoading: isSendingNotice } = useMutation(
    createYardstikAdverseAction
  );
  const { mutateAsync: cancelPreAdverseViolationNotice, isLoading: isCancellingNotice } =
    useMutation(cancelYardstikAdverseAction);

  const { enqueueSnackbar } = useSnackbar();

  const proceed = () => {
    const body = {
      candidate_id: yardstikData?.yardstikId,
      report_id: yardstikData?.report?.reportId,
    };
    updateYardstikStatus(
      { body },
      {
        onSuccess: async (res) => {
          setCurrentApplicant({ ...currentApplicant, ...res?.data });
          queryClient.invalidateQueries(isEmployee ? "employee_table" : "applicants");
          queryClient.invalidateQueries("useBadgeValues");
          enqueueSnackbar("Proceeded successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
        onError: (error) => {
          enqueueSnackbar("Error in proceeding", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
      }
    );
  };

  const sendNotice = () => {
    const body = {
      report_id: yardstikData?.report?.reportId,
      violations: violations.filter((violation) => violation.description?.trim() !== ""),
    };
    sendPreAdverseViolationNotice(
      { body },
      {
        onSuccess: async (res) => {
          setCurrentApplicant({ ...currentApplicant, ...res?.data });
          queryClient.invalidateQueries("useBadgeValues");
          queryClient.invalidateQueries(isEmployee ? "employee_table" : "applicants");
          enqueueSnackbar("Notice sent successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
        onError: (error) => {
          enqueueSnackbar("Error in proceeding", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
      }
    );
  };

  const cancelNotice = () => {
    const adverseActionId = yardstikData?.report?.reportDetails?.adverse_action_id;
    if (!adverseActionId) {
      enqueueSnackbar("No adverse action found", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    const body = {
      report_id: yardstikData?.report?.reportId,
      adverse_action_id: adverseActionId,
    };
    cancelPreAdverseViolationNotice(
      { body },
      {
        onSuccess: (res) => {
          setCurrentApplicant({ ...currentApplicant, ...res?.data });
          queryClient.invalidateQueries(isEmployee ? "employee_table" : "applicants");
          queryClient.invalidateQueries("useBadgeValues");
          enqueueSnackbar("Adverse action cancelled successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
        onError: (error) => {
          enqueueSnackbar("Error in proceeding", {
            variant: "error",
            autoHideDuration: 3000,
          });
          setOpen(false);
        },
      }
    );
  };

  const handleViolationChange = (index, value) => {
    const updatedViolations = [...violations];
    updatedViolations[index].description = value;
    setViolations(updatedViolations);
  };

  const addViolation = () => setViolations([...violations, { description: "" }]);

  const removeViolation = (index) => {
    const updatedViolations = violations.filter((_, i) => i !== index);
    setViolations(updatedViolations);
  };

  const header = (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box sx={{ mt: 2, mb: 1, ml: 2 }}>
        <Typography variant="h5" color="dark">
          {`Background Check for ${applicantName} is ${yardstikData?.report?.status}`}
        </Typography>
      </Box>
      <IconButton onClick={() => setOpen(false)}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
  const modalBody = (
    <Stack display="flex" direction="row" alignItems="center">
      {yardstikData?.report?.status === "consider" && (
        <>
          {!isOpenAddViolations ? (
            <Box display="flex" flexDirection="column" alignItems="center" gap={2} flex={1}>
              <MDButton
                type="button"
                color="info"
                size="large"
                sx={{ maxWidth: "400px" }}
                disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
                onClick={() => proceed()}
              >
                Proceed this applicant
              </MDButton>
              <MDButton
                type="button"
                color="error"
                size="large"
                sx={{ maxWidth: "400px" }}
                disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
                onClick={() => setIsOpenAddViolations(true)}
              >
                Pre-Adverse Action
              </MDButton>
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" gap={2} flex={1}>
              {violations.map((violation, index) => (
                <Box
                  key={`${index.toString()}_violation`}
                  display="flex"
                  alignItems="center"
                  gap={1}
                >
                  <MDInput
                    sx={{ width: "400px" }}
                    type="text"
                    variant="outlined"
                    value={violation.description}
                    onChange={(e) => handleViolationChange(index, e.target.value)}
                    placeholder={`Violation ${index + 1}`}
                  />
                  <IconButton
                    aria-label="delete"
                    disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
                    onClick={() => removeViolation(index)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              ))}
              <MDButton
                type="button"
                color="info"
                size="large"
                disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
                onClick={addViolation}
              >
                Add More
              </MDButton>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap={2}
                flex={1}
                marginTop={5}
              >
                <MDButton
                  type="button"
                  color="dark"
                  size="large"
                  disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
                  onClick={() => setIsOpenAddViolations(false)}
                >
                  Cancel
                </MDButton>
                <MDButton
                  type="button"
                  color="success"
                  size="large"
                  disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
                  onClick={() => sendNotice()}
                >
                  Send Notice
                </MDButton>
              </Box>
            </Box>
          )}
        </>
      )}
      {yardstikData?.report?.status === "pre_adverse" && (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2} flex={1}>
          <MDButton
            type="button"
            color="violet"
            size="large"
            sx={{ maxWidth: "400px" }}
            disabled={isUpdatingYardstikStatus || isSendingNotice || isCancellingNotice}
            onClick={() => cancelNotice()}
          >
            Cancel Adverse Action
          </MDButton>
        </Box>
      )}
      <Box display="flex" flex={1} alignItems="center" justifyContent="center">
        {getFilePreview(450, true)}
      </Box>
    </Stack>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        header={header}
        body={modalBody}
        // buttons={modalButtons}
        width="60%"
        overflow="auto"
      />
    </>
  );
};

export default YardstikAttachmentModal;
