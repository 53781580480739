import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import useBadgeValues from "hooks/useBadgeValues";
import FilterCardBar from "components/FilterCardBar";
import PublicIcon from "@mui/icons-material/Public";
import {
  AccountBalanceWalletRounded,
  AssignmentTurnedIn,
  AttachMoney,
  PendingActions,
  PostAdd,
  Work,
  WorkHistory,
} from "@mui/icons-material";

const ActivitiesIntegrationFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentActivity, currentLoggedUser } = useAppContextController();

  const initialCards = useMemo(
    () => [
      {
        color: "dark",
        icon: <PublicIcon fontSize="large" color="black" />,
        title: "All",
        tooltip: "All Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "",
      },
      {
        color: "success",
        icon: <AttachMoney fontSize="large" color="success" />,
        title: "Helm",
        tooltip: "Helm Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "Helm",
      },
      {
        color: "dark",
        icon: <AssignmentTurnedIn fontSize="large" color="dark" />,
        title: "Yardstik",
        tooltip: "Yardstik Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "Yardstik",
      },
      {
        color: "success",
        icon: <AccountBalanceWalletRounded fontSize="large" color="succcess" />,
        title: "Branch",
        tooltip: "Branch Virtual Wallet Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "Branch Wallet",
      },
      {
        color: "info",
        icon: <Work fontSize="large" color="info" />,
        title: "Indeed",
        tooltip: "Indeed Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "Indeed",
      },
      {
        color: "success",
        icon: <PendingActions fontSize="large" color="success" />,
        title: "Batch",
        tooltip: "Batch Tasks",
        number: 0,
        filterField: "integration",
        filterValue: "Batch",
      },
      {
        color: "info",
        icon: <WorkHistory fontSize="large" sx={{ color: "#00a7ec" }} />,
        title: "Employee",
        tooltip: "Employee App Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "Employee",
      },
      {
        color: "warning",
        icon: <PostAdd fontSize="large" color="warning" />,
        title: "Resume Import",
        tooltip: "Resume Import Integrations",
        number: 0,
        filterField: "integration",
        filterValue: "Resume Import",
      },
    ],
    []
  );

  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({ initialCards, filters, entity: "activities", refreshBadges });
  const navigate = useNavigate();

  const setActivityFilters = (filter) => {
    navigate("/activities");
    setCurrentActivity(null);
    setFilters((existingFilters) => {
      if (filter.integration) return { ...existingFilters, ...filter };
      const newFilters = { ...existingFilters };
      delete newFilters.integration;
      return newFilters;
    });
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Venues"
        bgColor="primary"
        setFilters={setActivityFilters}
        setPage={setPage}
        spacing={1}
      />
    </span>
  );
};

export default ActivitiesIntegrationFilterCards;
