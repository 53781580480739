import React, { useMemo, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import moment from "moment";
import {
  AvatarGroup,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccessTime, Add, Close, Delete, Person, Save } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CircularLoader from "components/CircularLoader";
import { useTheme } from "@mui/material/styles";

// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
import { useMaterialUIController } from "context";
import PictureAvatar from "components/PictureAvatar";

const ShiftCalendarView = ({
  open,
  onClose,
  shifts,
  staffingPool,
  startDate,
  endDate,
  onShiftUpdate,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const theme = useTheme();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedStaffToAdd, setSelectedStaffToAdd] = useState("");
  const [modifiedShifts, setModifiedShifts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newShiftDialogOpen, setNewShiftDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [newShift, setNewShift] = useState({
    shiftName: "",
    shiftStartDate: "",
    shiftEndDate: "",
    defaultSchedule: {
      monday: { start: "", end: "" },
      tuesday: { start: "", end: "" },
      wednesday: { start: "", end: "" },
      thursday: { start: "", end: "" },
      friday: { start: "", end: "" },
      saturday: { start: "", end: "" },
      sunday: { start: "", end: "" },
    },
    shiftRoster: [],
  });

  // Calculate valid date range for the calendar
  const validDateRange = useMemo(() => {
    const startMoment = startDate ? moment(startDate) : null;
    const endMoment = endDate ? moment(endDate) : null;

    // If both dates are provided, use them
    if (startMoment && endMoment) {
      return {
        start: startMoment.toDate(),
        end: endMoment.toDate(),
        initialDate: startMoment.toDate(),
      };
    }

    // If only one date is provided, use it as both start and end
    if (startMoment) {
      return {
        start: startMoment.toDate(),
        initialDate: startMoment.toDate(),
      };
    }

    if (endMoment) {
      return {
        end: endMoment.toDate(),
        initialDate: endMoment.toDate(),
      };
    }

    // If no dates are provided, use the first shift's date range
    if (shifts && shifts.length > 0) {
      const firstShift = shifts[0];
      return {
        start: moment(firstShift.shiftStartDate).toDate(),
        end: moment(firstShift.shiftEndDate).toDate(),
        initialDate: moment(firstShift.shiftStartDate).toDate(),
      };
    }

    // Default to current date if no other options
    return {
      initialDate: new Date(),
    };
  }, [startDate, endDate, shifts]);

  // Create a working copy of shifts for modifications
  useMemo(() => {
    if (shifts) {
      setModifiedShifts([...shifts]);
    }
  }, [shifts]);

  // Format shifts data for calendar
  const calendarEvents = useMemo(() => {
    if (!modifiedShifts || !modifiedShifts.length) return [];

    const events = [];

    modifiedShifts.forEach((shift, shiftIndex) => {
      // Get all days between start and end date
      const shiftStartDate = moment(shift.shiftStartDate);
      const shiftEndDate = moment(shift.shiftEndDate);

      // Calculate the number of days between start and end dates
      const dayCount = shiftEndDate.diff(shiftStartDate, "days") + 1;

      // Generate an array of days
      const days = Array.from({ length: dayCount }, (_, i) => {
        return moment(shiftStartDate).add(i, "days");
      });

      // For each day, check if it's in the default schedule
      days.forEach((day) => {
        const dayName = day.format("dddd").toLowerCase();
        const daySchedule = shift.defaultSchedule[dayName];

        if (daySchedule && daySchedule.start && daySchedule.end) {
          // Extract hours and minutes from the ISO strings
          const startMoment = moment(daySchedule.start);
          const endMoment = moment(daySchedule.end);

          // Create event for this day by combining the day date with schedule times
          const startDateTime = day.clone().set({
            hour: startMoment.hour(),
            minute: startMoment.minute(),
            second: 0,
          });

          const endDateTime = day.clone().set({
            hour: endMoment.hour(),
            minute: endMoment.minute(),
            second: 0,
          });

          // If end time is before start time, assume it's the next day
          if (endDateTime.isBefore(startDateTime)) {
            endDateTime.add(1, "day");
          }

          // Get staff assigned to this shift for this specific day
          let assignedStaff = [];

          // If the day has a specific roster, use it
          if (daySchedule.roster && daySchedule.roster.length > 0) {
            // Find the staff details for each ID in the roster
            assignedStaff = daySchedule.roster
              .map((staffId) => {
                const staffMember = shift.shiftRoster.find((s) => s._id === staffId);
                return staffMember || { _id: staffId, fullName: "Unknown Staff" };
              })
              .filter(Boolean);
          } else {
            // For days without a specific roster, show no staff assigned
            assignedStaff = [];
          }

          const staffCount = assignedStaff.length;

          events.push({
            title: shift.shiftName,
            start: startDateTime.toDate(),
            end: endDateTime.toDate(),
            extendedProps: {
              shift,
              daySchedule,
              assignedStaff,
              date: day.format("YYYY-MM-DD"),
              staffCount,
              dayName,
              shiftIndex, // Use the actual index from the forEach loop
            },
            className: staffCount > 0 ? "success" : "warning",
          });
        }
      });
    });

    return events;
  }, [modifiedShifts]);

  const handleEventClick = (info) => {
    setSelectedEvent(info.event);
    setIsEditing(false);
    setSelectedStaffToAdd("");
  };

  const handleCloseEventDetails = () => {
    setSelectedEvent(null);
    setIsEditing(false);
    setSelectedStaffToAdd("");
  };

  // Get initials from a name
  const getInitials = (name) => {
    if (!name) return "?";
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase()
      .substring(0, 2);
  };

  // Generate a consistent color based on a string
  const stringToColor = (string) => {
    if (!string) return theme.palette.primary.main;

    // Simple hash function that avoids bitwise operations
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
      hash = (hash * 31 + string.charCodeAt(i)) % 16777215;
    }

    // Convert to hex color
    const color = `#${Math.floor(hash).toString(16).padStart(6, "0")}`;
    return color;
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const { extendedProps } = event;
    const { assignedStaff, staffCount, dayName } = extendedProps;

    return (
      <Tooltip
        title={
          <Box>
            <Typography variant="subtitle2">{event.title}</Typography>
            <Typography variant="body2">
              {moment(event.start).format("h:mm A")} - {moment(event.end).format("h:mm A")}
            </Typography>
            <Typography variant="caption" sx={{ display: "block", mt: 0.5 }}>
              {dayName.charAt(0).toUpperCase() + dayName.slice(1)}
            </Typography>
            {staffCount > 0 ? (
              <Box mt={1}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                  Staff ({staffCount}):
                </Typography>
                {assignedStaff.slice(0, 3).map((staff) => (
                  <Typography key={staff._id} variant="caption" display="block">
                    • {staff.fullName}
                  </Typography>
                ))}
                {staffCount > 3 && (
                  <Typography variant="caption">• and {staffCount - 3} more...</Typography>
                )}
              </Box>
            ) : (
              <Typography variant="caption" sx={{ color: theme.palette.warning.main }}>
                No staff assigned
              </Typography>
            )}
          </Box>
        }
      >
        <Box sx={{ overflow: "hidden", width: "100%", p: 0.5 }}>
          <Typography variant="caption" sx={{ fontWeight: "bold", display: "block" }}>
            {moment(event.start).format("h:mm A")} - {moment(event.end).format("h:mm A")}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontWeight: "medium",
            }}
          >
            {event.title}
          </Typography>

          {staffCount > 0 ? (
            <AvatarGroup
              max={3}
              sx={{
                mt: 0.5,
                "& .MuiAvatar-root": {
                  width: 20,
                  height: 20,
                  fontSize: "0.625rem",
                },
              }}
            >
              {assignedStaff.map((staff) => (
                <Tooltip key={staff._id} title={staff.fullName}>
                  <PictureAvatar
                    image={staff?.profileImg}
                    firstName={staff.fullName?.split(" ")[0]}
                    lastName={staff.fullName?.split(" ")[1]}
                    userId={staff?._id}
                    size="md"
                  />
                </Tooltip>
              ))}
            </AvatarGroup>
          ) : (
            <Chip
              icon={<Person sx={{ fontSize: 14 }} />}
              label="Unassigned"
              size="small"
              color="warning"
              variant="outlined"
              sx={{ height: 20, "& .MuiChip-label": { fontSize: "0.625rem", px: 0.5 } }}
            />
          )}
        </Box>
      </Tooltip>
    );
  };

  // Get available staff that are not already assigned to this shift on this specific day
  const getAvailableStaff = () => {
    if (!selectedEvent || !staffingPool) return [];

    const { dayName, shift } = selectedEvent.extendedProps;

    // Get IDs of staff already assigned to this shift on this specific day
    const dayRoster = shift.defaultSchedule[dayName]?.roster || [];

    // Filter the staffing pool to only include staff not already assigned to this day
    return staffingPool.filter((staff) => !dayRoster.includes(staff._id));
  };

  // Handle adding a staff member to the shift
  const handleAddStaff = () => {
    if (!selectedStaffToAdd || !selectedEvent) return;

    const { shiftIndex, dayName } = selectedEvent.extendedProps;
    const staffMember = staffingPool.find((staff) => staff._id === selectedStaffToAdd);

    if (!staffMember) return;

    // Create a deep copy of the shifts array
    const updatedShifts = [...modifiedShifts];

    // Ensure we're using the correct shift
    if (shiftIndex < 0 || shiftIndex >= updatedShifts.length) {
      console.error("Invalid shift index:", shiftIndex);
      return;
    }

    const shift = updatedShifts[shiftIndex];

    // Ensure the day's schedule exists
    if (!shift.defaultSchedule[dayName]) {
      shift.defaultSchedule[dayName] = { start: "", end: "" };
    }

    // Ensure the roster array exists for this day
    if (!shift.defaultSchedule[dayName].roster) {
      shift.defaultSchedule[dayName].roster = [];
    }

    // Add the staff ID to the day's roster if not already there
    if (!shift.defaultSchedule[dayName].roster.includes(staffMember._id)) {
      shift.defaultSchedule[dayName].roster.push(staffMember._id);
    }

    // If the staff member is not in the general shift roster, add them
    if (!shift.shiftRoster.some((staff) => staff._id === staffMember._id)) {
      shift.shiftRoster.push(staffMember);
    }

    // Update the state with the correct shift
    setModifiedShifts(updatedShifts);
    setSelectedStaffToAdd("");

    // Preserve the original event data while updating staff information
    const updatedEvent = {
      ...selectedEvent,
      start: selectedEvent.start,
      end: selectedEvent.end,
      extendedProps: {
        ...selectedEvent.extendedProps,
        assignedStaff: [...selectedEvent.extendedProps.assignedStaff, staffMember],
        staffCount: selectedEvent.extendedProps.staffCount + 1,
        shiftIndex, // Preserve the correct shift index
      },
    };

    // Close and reopen the event to refresh the data
    setSelectedEvent(null);
    setTimeout(() => {
      setSelectedEvent(updatedEvent);
    }, 0);
  };

  // Handle removing a staff member from the shift
  const handleRemoveStaff = (staffId) => {
    if (!selectedEvent) return;

    const { shiftIndex, dayName } = selectedEvent.extendedProps;

    // Create a deep copy of the shifts array
    const updatedShifts = [...modifiedShifts];

    // Ensure we're using the correct shift
    if (shiftIndex < 0 || shiftIndex >= updatedShifts.length) {
      console.error("Invalid shift index:", shiftIndex);
      return;
    }

    const shift = updatedShifts[shiftIndex];

    // Remove the staff ID from the day's roster
    if (shift.defaultSchedule[dayName] && shift.defaultSchedule[dayName].roster) {
      shift.defaultSchedule[dayName].roster = shift.defaultSchedule[dayName].roster.filter(
        (id) => id !== staffId
      );
    }

    // Update the state with the correct shift
    setModifiedShifts(updatedShifts);

    // Preserve the original event data while updating staff information
    const updatedEvent = {
      ...selectedEvent,
      start: selectedEvent.start,
      end: selectedEvent.end,
      extendedProps: {
        ...selectedEvent.extendedProps,
        assignedStaff: selectedEvent.extendedProps.assignedStaff.filter(
          (staff) => staff._id !== staffId
        ),
        staffCount: selectedEvent.extendedProps.staffCount - 1,
        shiftIndex, // Preserve the correct shift index
      },
    };

    // Close and reopen the event to refresh the data
    setSelectedEvent(null);
    setTimeout(() => {
      setSelectedEvent(updatedEvent);
    }, 0);
  };

  // Save all changes to shifts
  const handleSaveChanges = () => {
    setIsLoading(true);

    // Call the parent component's update function with the modified shifts
    if (onShiftUpdate) {
      onShiftUpdate(modifiedShifts);
    }

    // Simulate API call delay
    setTimeout(() => {
      setIsLoading(false);
      handleCloseEventDetails();
    }, 500);
  };

  // Handle date click to create a new shift
  const handleDateClick = (info) => {
    const clickedDate = moment(info.date);
    setSelectedDate(clickedDate);

    // Initialize new shift with the clicked date
    setNewShift({
      shiftName: "",
      shiftStartDate: clickedDate.format("YYYY-MM-DD"),
      shiftEndDate: clickedDate.add(7, "days").format("YYYY-MM-DD"), // Default to 1 week duration
      defaultSchedule: {
        monday: { start: "", end: "" },
        tuesday: { start: "", end: "" },
        wednesday: { start: "", end: "" },
        thursday: { start: "", end: "" },
        friday: { start: "", end: "" },
        saturday: { start: "", end: "" },
        sunday: { start: "", end: "" },
      },
      shiftRoster: [],
    });

    // Set the default schedule for the day that was clicked
    const dayName = clickedDate.format("dddd").toLowerCase();
    const startTime = moment(info.date).set({ hour: 9, minute: 0 }).format();
    const endTime = moment(info.date).set({ hour: 17, minute: 0 }).format();

    setNewShift((prev) => ({
      ...prev,
      defaultSchedule: {
        ...prev.defaultSchedule,
        [dayName]: { start: startTime, end: endTime },
      },
    }));

    setNewShiftDialogOpen(true);
  };

  // Handle time slot selection to create a new shift
  const handleSelectTimeSlot = (info) => {
    const selectedStartDate = moment(info.start);
    const selectedEndDate = moment(info.end);
    setSelectedDate(selectedStartDate);

    // Initialize new shift with the selected date range
    setNewShift({
      shiftName: "",
      shiftStartDate: selectedStartDate.format("YYYY-MM-DD"),
      shiftEndDate: selectedStartDate.clone().add(7, "days").format("YYYY-MM-DD"), // Default to 1 week duration
      defaultSchedule: {
        monday: { start: "", end: "" },
        tuesday: { start: "", end: "" },
        wednesday: { start: "", end: "" },
        thursday: { start: "", end: "" },
        friday: { start: "", end: "" },
        saturday: { start: "", end: "" },
        sunday: { start: "", end: "" },
      },
      shiftRoster: [],
    });

    // Set the default schedule for the day that was selected with the exact time range
    const dayName = selectedStartDate.format("dddd").toLowerCase();

    setNewShift((prev) => ({
      ...prev,
      defaultSchedule: {
        ...prev.defaultSchedule,
        [dayName]: {
          start: selectedStartDate.format(),
          end: selectedEndDate.format(),
        },
      },
    }));

    setNewShiftDialogOpen(true);
  };

  // Handle creating a new shift
  const handleCreateNewShift = () => {
    if (!newShift.shiftName) return;

    setIsLoading(true);

    // Create a new shift object
    const createdShift = {
      ...newShift,
      slug: `temp-${Date.now()}`, // Temporary slug
    };

    // Add the new shift to the modified shifts
    const updatedShifts = [...modifiedShifts, createdShift];
    setModifiedShifts(updatedShifts);

    // Call the parent component's update function with the modified shifts
    if (onShiftUpdate) {
      onShiftUpdate(updatedShifts);
    }

    // Close the dialog and reset state
    setNewShiftDialogOpen(false);
    setNewShift({
      shiftName: "",
      shiftStartDate: "",
      shiftEndDate: "",
      defaultSchedule: {
        monday: { start: "", end: "" },
        tuesday: { start: "", end: "" },
        wednesday: { start: "", end: "" },
        thursday: { start: "", end: "" },
        friday: { start: "", end: "" },
        saturday: { start: "", end: "" },
        sunday: { start: "", end: "" },
      },
      shiftRoster: [],
    });

    // Simulate API call delay
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { height: "90vh" } }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            <MDTypography variant="h5">Shift Calendar with Staff</MDTypography>
            <MDTypography variant="caption" color="text.secondary">
              Click on a date or select a time range to create a new shift
            </MDTypography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <MDBox sx={{ position: "relative", height: "100%" }}>
          <CalendarRoot p={2} ownerState={{ darkMode }} sx={{ height: "100%" }}>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              events={calendarEvents}
              eventClick={handleEventClick}
              dateClick={handleDateClick}
              selectable
              select={handleSelectTimeSlot}
              height="100%"
              eventContent={renderEventContent}
              slotMinTime="06:00:00"
              slotMaxTime="23:00:00"
              allDaySlot={false}
              initialDate={validDateRange.initialDate}
              validRange={{
                start: validDateRange.start,
                end: validDateRange.end,
              }}
              // Disable navigation outside the valid range
              navLinks
              fixedWeekCount={false}
              selectMirror
              selectOverlap={false}
              selectAllow={(selectInfo) => {
                // Only allow selections in the future
                return moment(selectInfo.start).isSameOrAfter(moment(), "day");
              }}
              // Custom styling for the selection
              selectMinDistance={10} // Minimum distance in pixels to trigger a selection
              // Add custom CSS classes
              selectOverlapFunc={(event) => {
                // Don't allow selection over existing events
                return false;
              }}
              // Add tooltips to explain the selection feature
              eventDidMount={(info) => {
                if (info.event.display === "background") {
                  const tooltip = document.createElement("div");
                  tooltip.className = "fc-tooltip";
                  tooltip.innerHTML = "Click and drag to create a new shift";
                  info.el.appendChild(tooltip);
                }
              }}
            />
          </CalendarRoot>
          <CircularLoader hasBackdrop={false} isLoading={isLoading} color="info" />
        </MDBox>

        {selectedEvent && (
          <Dialog
            open={Boolean(selectedEvent)}
            onClose={handleCloseEventDetails}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <MDTypography variant="h6">{selectedEvent.title}</MDTypography>
                <Stack direction="row" spacing={1}>
                  {!isEditing && (
                    <Button
                      color="primary"
                      size="small"
                      onClick={() => setIsEditing(!isEditing)}
                      startIcon={<Person />}
                    >
                      Manage {moment(selectedEvent.start).format("dddd")} Staff
                    </Button>
                  )}
                  <IconButton onClick={handleCloseEventDetails} size="small">
                    <Close />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <AccessTime sx={{ mr: 1, color: theme.palette.text.secondary }} />
                    <MDTypography variant="subtitle1">
                      {moment(selectedEvent.start).format("dddd, MMMM D, YYYY")}
                    </MDTypography>
                  </Box>
                  <MDTypography variant="body2" sx={{ ml: 4 }}>
                    {moment(selectedEvent.start).format("h:mm A")} -{" "}
                    {moment(selectedEvent.end).format("h:mm A")}
                  </MDTypography>
                  <MDTypography
                    variant="caption"
                    sx={{ ml: 4, display: "block", mt: 0.5, color: theme.palette.text.secondary }}
                  >
                    Recurring on{" "}
                    {selectedEvent.extendedProps.dayName.charAt(0).toUpperCase() +
                      selectedEvent.extendedProps.dayName.slice(1)}
                    s
                  </MDTypography>
                </Grid>

                <Grid item xs={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <MDTypography variant="h6" color="text" mt={2} mb={1}>
                      Staff Assigned for {moment(selectedEvent.start).format("dddd")} (
                      {selectedEvent.extendedProps.staffCount})
                    </MDTypography>
                  </Stack>
                  <MDTypography
                    variant="caption"
                    color="text.secondary"
                    sx={{ mb: 2, display: "block" }}
                  >
                    Staff assignments are specific to each day of the week
                  </MDTypography>

                  {isEditing && (
                    <Box sx={{ mb: 2 }}>
                      <Stack direction="row" spacing={1} alignItems="flex-end">
                        <FormControl fullWidth size="small">
                          <InputLabel
                            id="add-staff-label"
                            shrink={false}
                            sx={{ position: "static", transform: "none", marginBottom: 1 }}
                          >
                            Add Staff for {moment(selectedEvent.start).format("dddd")}
                          </InputLabel>
                          <Select
                            labelId="add-staff-label"
                            value={selectedStaffToAdd}
                            label="Add Staff Member"
                            sx={{
                              minWidth: 200,
                              "& .MuiSelect-outlined.MuiSelect-select": {
                                padding: "0.75rem !important",
                              },
                              "& .MuiSelect-icon": {
                                display: "initial",
                              },
                            }}
                            onChange={(e) => setSelectedStaffToAdd(e.target.value)}
                          >
                            <MenuItem value="">
                              <em>Select a staff member</em>
                            </MenuItem>
                            {getAvailableStaff().map((staff) => (
                              <MenuItem key={staff._id} value={staff._id}>
                                {staff.fullName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddStaff}
                          disabled={!selectedStaffToAdd}
                          startIcon={<Add />}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Box>
                  )}

                  {selectedEvent.extendedProps.assignedStaff.length > 0 ? (
                    <Grid container spacing={1}>
                      {selectedEvent.extendedProps.assignedStaff.map((staff) => (
                        <Grid item xs={12} sm={6} key={staff._id}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              p: 1.5,
                              borderRadius: 1,
                              bgcolor: theme.palette.grey[100],
                              border: `1px solid ${theme.palette.grey[200]}`,
                              transition: "all 0.2s",
                              "&:hover": {
                                boxShadow: theme.shadows[2],
                                bgcolor: theme.palette.grey[50],
                              },
                              position: "relative",
                            }}
                          >
                            <PictureAvatar
                              image={staff?.profileImg}
                              firstName={staff.fullName?.split(" ")[0]}
                              lastName={staff.fullName?.split(" ")[1]}
                              userId={staff?._id}
                              size="md"
                            />
                            <Box sx={{ flexGrow: 1 }}>
                              <MDTypography variant="body2" fontWeight="medium" mx={1}>
                                {staff.fullName}
                              </MDTypography>
                              {staff.position && (
                                <MDTypography variant="caption" color="text.secondary" mx={1}>
                                  {staff.position}
                                </MDTypography>
                              )}
                            </Box>
                            {isEditing && (
                              <IconButton
                                size="small"
                                color="error"
                                onClick={() => handleRemoveStaff(staff._id)}
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  bgcolor: "rgba(255,255,255,0.8)",
                                  "&:hover": {
                                    bgcolor: "rgba(255,255,255,0.9)",
                                  },
                                }}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Box
                      sx={{
                        p: 3,
                        borderRadius: 1,
                        bgcolor: theme.palette.warning.light,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 1,
                      }}
                    >
                      <Person sx={{ fontSize: 40, color: theme.palette.warning.main }} />
                      <MDTypography variant="body2" color="warning.dark" textAlign="center">
                        No staff assigned for {moment(selectedEvent.start).format("dddd")}
                      </MDTypography>
                      {isEditing && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          startIcon={<Add />}
                          onClick={() => document.getElementById("add-staff-label").focus()}
                        >
                          Add Staff for {moment(selectedEvent.start).format("dddd")}
                        </Button>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            {isEditing && (
              <DialogActions sx={{ px: 3, pb: 3 }}>
                <Button onClick={() => setIsEditing(false)} color="inherit">
                  Cancel
                </Button>
                <Button
                  onClick={handleSaveChanges}
                  variant="contained"
                  color="success"
                  startIcon={<Save />}
                >
                  Save Changes
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}

        {/* New Shift Dialog */}
        <Dialog
          open={newShiftDialogOpen}
          onClose={() => setNewShiftDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <MDTypography variant="h6">Create New Shift</MDTypography>
              <IconButton onClick={() => setNewShiftDialogOpen(false)} size="small">
                <Close />
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextField
                  label="Shift Name"
                  fullWidth
                  value={newShift.shiftName}
                  onChange={(e) => setNewShift({ ...newShift, shiftName: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Start Date"
                  type="date"
                  fullWidth
                  value={newShift.shiftStartDate}
                  onChange={(e) => setNewShift({ ...newShift, shiftStartDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="End Date"
                  type="date"
                  fullWidth
                  value={newShift.shiftEndDate}
                  onChange={(e) => setNewShift({ ...newShift, shiftEndDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    mb: 2,
                    borderRadius: 1,
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  <MDTypography variant="subtitle1" fontWeight="medium">
                    Default Schedule for {selectedDate && moment(selectedDate).format("dddd")}
                  </MDTypography>
                  <MDTypography variant="caption" color="text.secondary">
                    This shift will initially be scheduled for{" "}
                    {selectedDate && moment(selectedDate).format("dddd")}s. You can configure other
                    days after creating the shift.
                  </MDTypography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Start Time"
                      type="time"
                      fullWidth
                      value={
                        selectedDate
                          ? moment(
                              newShift.defaultSchedule[
                                moment(selectedDate).format("dddd").toLowerCase()
                              ].start
                            ).format("HH:mm")
                          : ""
                      }
                      onChange={(e) => {
                        const dayName = moment(selectedDate).format("dddd").toLowerCase();
                        const [hours, minutes] = e.target.value.split(":");
                        const newTime = moment(selectedDate).set({ hours, minutes }).format();

                        setNewShift((prev) => ({
                          ...prev,
                          defaultSchedule: {
                            ...prev.defaultSchedule,
                            [dayName]: {
                              ...prev.defaultSchedule[dayName],
                              start: newTime,
                            },
                          },
                        }));
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="End Time"
                      type="time"
                      fullWidth
                      value={
                        selectedDate
                          ? moment(
                              newShift.defaultSchedule[
                                moment(selectedDate).format("dddd").toLowerCase()
                              ].end
                            ).format("HH:mm")
                          : ""
                      }
                      onChange={(e) => {
                        const dayName = moment(selectedDate).format("dddd").toLowerCase();
                        const [hours, minutes] = e.target.value.split(":");
                        const newTime = moment(selectedDate).set({ hours, minutes }).format();

                        setNewShift((prev) => ({
                          ...prev,
                          defaultSchedule: {
                            ...prev.defaultSchedule,
                            [dayName]: {
                              ...prev.defaultSchedule[dayName],
                              end: newTime,
                            },
                          },
                        }));
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="caption" color="text.secondary">
                  Note: You can configure the full weekly schedule after creating the shift.
                </MDTypography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={() => setNewShiftDialogOpen(false)} color="inherit">
              Cancel
            </Button>
            <Button
              onClick={handleCreateNewShift}
              variant="contained"
              color="success"
              startIcon={<Add />}
              disabled={!newShift.shiftName}
            >
              Create Shift
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default ShiftCalendarView;
