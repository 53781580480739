import { baseAxios } from "config";

const syncDataWithHelm = async ({ id, slug, isManual, overwriteEmployee }) => {
  // Initialize the body object with common properties
  const body = { overwriteEmployee };

  // Conditionally add properties if they have values
  if (slug && isManual) {
    body.slug = slug;
    body.isManual = isManual;
  }
  const res = await baseAxios.post(`/helm/employee/id/${id}/datasync`, body);
  return res.data;
};

export default syncDataWithHelm;
