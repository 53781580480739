import { baseAxios } from "config";

const createBranchWallet = async (applicantId) => {
  try {
    const res = await baseAxios.post(`/branch/wallet/${applicantId}/create`);
    return res.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message ?? err.toString());
  }
};

export default createBranchWallet;
