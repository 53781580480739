import { Box, Paper, styled, Typography } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import CustomStepper from "components/CustomizedMui/CustomStepper";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { ONBOARDING_OBJECTS_ENUM } from "utils/constants/applicant";
import { getCompanyImageUrl } from "utils/helpers/upload";
import UnsavedChangesModal from "../NewApplicantForms/UnsavedChangesModal";

const StyledImg = styled("img")(() => ({
  borderRadius: "15px",
}));

const NewApplicantHeader = ({ isAvailable, setIsAvailable }) => {
  const { applicantCode } = useParams();
  const {
    activeStep,
    activeStepId,
    initializeApplicant,
    applicant,
    setActiveStep,
    currentFormState,
    registrationSteps,
  } = useNewApplicantContext();
  const [clickDirection, setClickDirection] = useState(null);
  const [openUnsavedChangesModal, setOpenUnsavedChangesModal] = useState(false);
  const [stepToGoId, setStepToGoId] = useState(null);

  // useEffect(() => {
  //   const initialize = async () => {
  //     const res = await initializeApplicant(applicantCode);
  //     if (res) setIsAvailable(true);
  //     else setIsAvailable(false);
  //   };
  //   initialize();
  // }, [applicantCode]);

  const fullName = useMemo(
    () => `${applicant.firstName || ""} ${applicant.lastName || ""}`,
    [applicant]
  );

  const handleStepClick = (stepData) => {
    const { id, isActive } = stepData;
    if (activeStep === ONBOARDING_OBJECTS_ENUM.COMPLETE) {
      return;
    }
    if (currentFormState?.isDirty) {
      setClickDirection("setStep");
      setOpenUnsavedChangesModal(true);
      setStepToGoId(id);
    } else if (isActive) {
      setActiveStep(id);
    }
  };

  const { company: primaryCompany, setCompany: setPrimaryCompany } = useAppContextController();

  return (
    <Box position="relative">
      <a href="/">
        <StyledImg
          src={`${getCompanyImageUrl(primaryCompany)}/company/${primaryCompany?.slug}/banner/${
            primaryCompany?.bannerUrl
          }`}
          alt=""
          width="100%"
        />
      </a>
      {isAvailable && activeStep !== "verification" ? (
        <Paper elevation={3} sx={{ px: "40px", py: "20px" }}>
          <FlexWrapper
            alignItems={{ xs: "normal", lg: "center" }}
            justifyContent={{ xs: "center", lg: "space-between" }}
            flexDirection={{ xs: "column", lg: "row" }}
          >
            <FlexWrapper
              columnGap="20px"
              justifyContent={{ xs: "center", md: "flex-start" }}
              sx={(theme) => ({
                flex: 0.2,
                [theme.breakpoints.down("lg")]: {
                  flex: 1,
                },
              })}
            >
              <Box>
                <Typography variant="h4">{fullName}</Typography>
                <Typography>Applicant User</Typography>
              </Box>
            </FlexWrapper>
            <Box
              maxWidth="100%"
              sx={(theme) => ({
                overflowY: "auto",
                flex: 0.8,
                display: "flex",
                [theme.breakpoints.down("lg")]: {
                  flex: 1,
                },
              })}
            >
              <CustomStepper activeStepId={activeStepId} steps={registrationSteps} />
            </Box>
          </FlexWrapper>
        </Paper>
      ) : null}
      <UnsavedChangesModal
        clickDirection={clickDirection}
        openUnsavedChangesModal={openUnsavedChangesModal}
        setOpenUnsavedChangesModal={setOpenUnsavedChangesModal}
        stepToGoId={stepToGoId}
      />
    </Box>
  );
};

export default NewApplicantHeader;
