import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import PreviewIcon from "@mui/icons-material/Preview";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BadgeIcon from "@mui/icons-material/Badge";
import JobStatusButton from "components/JobStatusButton";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import { useEffect, useState } from "react";
import GenericMoreButton from "components/GenericMoreButton";

const JobsTableActions = ({
  jobSlug,
  setNavigateUrl,
  row,
  setFilters,
  setOpen,
  setJobPreview,
  parent = "jobs",
  currentApplicant,
  isCompany,
}) => {
  const navigate = useNavigate();

  const navigateLocal = setNavigateUrl ?? navigate;

  const [localJobStatus, setLocalJobStatus] = useState(
    getApplicantJobStatus(currentApplicant, jobSlug)
  );

  useEffect(() => {
    setLocalJobStatus(getApplicantJobStatus(currentApplicant, jobSlug));
  }, [currentApplicant, jobSlug]);

  const refresh = (slug, status) => {
    if (status) {
      setLocalJobStatus(status);
    }
  };

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    navigateLocal(`/jobs/${jobSlug}/action/${action}`);
  };

  const setPreviewOpen = (job, e) => {
    e.stopPropagation();
    setJobPreview(job);
    setOpen(true);
  };
  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("jobinfo", e)}
        tooltip="Job Info"
      />
      {!!currentApplicant && (
        <JobStatusButton status={localJobStatus} job={row} refresh={refresh} />
      )}
      <TableAction
        icon="badge"
        color="success"
        tooltip="Job Status"
        clickAction={(e) => onClickHandler("jobstatus", e)}
      />
      <GenericMoreButton
        buttons={[
          // {
          //   icon: "person-add",
          //   showBadge: true,
          //   color: "warning",
          //   tooltip: "Job Applicants",
          //   clickAction: (e) => onClickHandler("jobapplicants", e),
          // },
          {
            icon: "people",
            showBadge: true,
            color: "error",
            tooltip: isCompany ? "Job Employees" : "Job Staffing Pool",
            clickAction: (e) => onClickHandler("jobstaffingpool", e),
          },
          // {
          //   icon: "badge",
          //   color: "success",
          //   tooltip: "Job Status",
          //   clickAction: (e) => onClickHandler("jobstatus", e),
          // },
          {
            color: "info",
            icon: <PreviewIcon />,
            clickAction: (e) =>
              parent === "jobs"
                ? setPreviewOpen(row, e)
                : navigateLocal(`/jobs/${jobSlug}/action/jobpreview`),
            tooltip: "Job Preview",
          },
        ]}
      />
    </span>
  );
};

JobsTableActions.defaultProps = {
  jobSlug: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
JobsTableActions.propTypes = {
  jobSlug: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};

export default JobsTableActions;
