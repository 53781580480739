/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import useDropdowns from "hooks/useDropdowns";
// @mui material components
import { Autocomplete, Grid, Switch, Button, Box } from "@mui/material";
import { Cancel as CancelIcon, Save as SaveIcon, Settings } from "@mui/icons-material";

import { PatternFormat } from "react-number-format";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import CustomCard from "components/CustomCard";
import ConfirmDialog from "components/ConfirmDialog";

import { VENUE_DEFAULT_SETTINGS } from "utils/constants/venues";
import { cardStyles } from "layouts/pages/venues/styles";

import AdditionalInfo from "./AdditionalInfo";

const STATES_ENTITY_NAME = "stateCodes";

const VenueSettingsPanel = () => {
  const { currentVenue, setCurrentVenue, isMobile } = useAppContextController();

  const [payrollDeptContact, setPayrollDeptContact] = useState();
  const [venueContact1, setVenueContact1] = useState();
  const [venueContact2, setVenueContact2] = useState();
  const [isSettingsDirty, setSettingsDirty] = useState(false);
  const { dropdowns: states } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const { dropdowns: payFrequencyValues } = useDropdowns({ entity: "payFrequency" });
  const { dropdowns: wcCodes } = useDropdowns({ entity: "wcCodes" });
  const { dropdowns: groupIds } = useDropdowns({ entity: "groupIds" });
  const { enqueueSnackbar } = useSnackbar();

  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    control,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  // Input Refs
  const venuePrimaryContact = register("venuePrimaryContact");
  const fein = {
    ...register("fein", {
      validate: {
        length: (value) => {
          if (value) {
            const trimValueLength = value.replace(/\D/g, "").length;

            if (trimValueLength === 9 || trimValueLength === 0) {
              return true;
            }
            return "FEIN must be 9 digits";
          }
          return true;
        },
      },
    }),
  };

  const payFrequency = register("payFrequency");
  const wcCode = register("wcCode");
  const wcState = register("wcState");
  const groupId = register("groupId");
  const allowAdminChanges = register("allowAdminChanges");
  const makeVenueInactive = register("makeVenueInactive");
  const ShowVenueOnWebsite = register("ShowVenueOnWebsite");
  const notifyWhenAdminUsersMakeChanges = register("notifyWhenAdminUsersMakeChanges");
  const notifyWhenNewJobsAreAddes = register("notifyWhenNewJobsAreAddes");
  const notifyWhenEventsAreAdded = register("notifyWhenEventsAreAdded");
  const notifyWhenApplicantsAreLocked = register("notifyWhenApplicantsAreLocked");
  const clockInRemindersEnabledByDefault = register("clockInRemindersEnabledByDefault");
  const allowPartnerLeaderClockIn = register("allowPartnerLeaderClockIn");
  const dashboardLastName = register("dashboardLastName");
  const dashboardFirstName = register("dashboardFirstName");
  const dashboardPhone1 = register("dashboardPhone1");
  const dashboardPhone2 = register("dashboardPhone2");
  const dashboardEmailAddress = register("dashboardEmailAddress");
  const dashboardHelmEID = register("dashboardHelmEID");
  const dashboardHireDate = register("dashboardHireDate");
  const dashboardBirthDate = register("dashboardBirthDate");
  const dashboardLastDateWorked = register("dashboardLastDateWorked");
  const dashboardRank = register("dashboardRank");
  const dashboardLastUpdate = register("dashboardLastUpdate");
  const dashboardRepName = register("dashboardRepName");
  const dashboardNotes = register("dashboardNotes");
  const dashboardResults = register("dashboardResults");
  const dashboardOtherVenues = register("dashboardOtherVenues");

  const queryClient = useQueryClient();
  const updateVenueMutation = useMutation(updateVenue, {
    onError: () =>
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("myvenues");
      setCurrentVenue({ ...currentVenue, ...data });
      setSettingsDirty(false);
      enqueueSnackbar("Venue has been updated!", { variant: "success" });
    },
  });

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const cancelChanges = () => {
    reset(currentVenue?.settings);
    setSettingsDirty(false);
    setVenueContact1(currentVenue?.venueContact1);
    setVenueContact2(currentVenue?.venueContact2);
    setPayrollDeptContact(currentVenue?.payrollDeptContact);
    enqueueSnackbar("Changes has been abandoned", { variant: "warning" });
  };

  const saveChanges = async (values) => {
    if (!currentVenue._id) {
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
      return;
    }

    let addInfo;

    if (venueContact1 !== currentVenue?.venueContact1) addInfo = { ...addInfo, venueContact1 };
    if (venueContact2 !== currentVenue?.venueContact2) addInfo = { ...addInfo, venueContact2 };
    if (payrollDeptContact !== currentVenue?.payrollDeptContact)
      addInfo = { ...addInfo, payrollDeptContact };

    await updateVenueMutation.mutateAsync({
      venueId: currentVenue._id,
      data: { settings: values, ...(addInfo && { ...addInfo }) },
    });
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confrim to abandon Changes",
      response: false,
    });
  };

  useEffect(() => {
    if (currentVenue) {
      reset(currentVenue?.settings ? currentVenue?.settings : VENUE_DEFAULT_SETTINGS);
    }
  }, [currentVenue]);

  // Executes on modal response!
  useEffect(() => {
    if (confirmModal.response) {
      resetConfirmModal();
      cancelChanges();
    }
  }, [confirmModal.response]);

  const panelActions = (
    <Box mt={2}>
      <Button
        variant="text"
        startIcon={<CancelIcon />}
        onClick={onCancel}
        disabled={isSubmitting}
        color="error"
        sx={{ color: "#F44335", fontSize: 14 }}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="text"
        endIcon={<SaveIcon />}
        sx={{ color: "#4CAF50", fontSize: 14, lineHeight: 1.5 }}
        disabled={isSubmitting}
      >
        Save
      </Button>
    </Box>
  );

  return (
    currentVenue && (
      <form onSubmit={handleSubmit(saveChanges)}>
        <CustomCard
          icon={<Settings color="white" />}
          cardTitle={`Venue Settings: ${currentVenue?.name}`}
          cardActions={(isDirty || isSettingsDirty) && panelActions}
          cardSx={cardStyles.card}
          titleSx={cardStyles.title}
          actionsSx={cardStyles.actions}
          iconContainerSx={{ backgroundColor: "info", mt: -3, ml: "1.5rem" }}
        >
          <Box sx={cardStyles.container}>
            <MDBox mb={3}>
              <Grid container direction="row-reverse">
                <Grid item sm={4}>
                  <Controller
                    {...venuePrimaryContact}
                    control={control}
                    defaultValue={
                      currentVenue
                        ? currentVenue.venuePrimaryContact
                        : "Assign Venue PrimaryInactive"
                    }
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_venuePrimaryContact_${venuePrimaryContact?.slug}`}
                        options={["Assign Venue Primary"]}
                        name="venuePrimaryContact"
                        value={field.value}
                        defaultValue={
                          currentVenue ? currentVenue.venuePrimaryContact : "Assign Venue Primary"
                        }
                        onChange={(e) => field.onChange(e.target.innerText)}
                        renderInput={(params) => (
                          <FormField
                            key={`venuePrimaryContact_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="Assign Venue Primary"
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <MDTypography variant="h6" color="info">
                    GENERAL SETTINGS
                  </MDTypography>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Controller
                        {...allowAdminChanges}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Allow Admin User to Make Changes
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Controller
                        {...makeVenueInactive}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Make Venue Inactive
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={3}>
                      <Controller
                        {...ShowVenueOnWebsite}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Show Venue on Website
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={12} sm={3}>
                        <Controller
                          {...allowPartnerLeaderClockIn}
                          control={control}
                          render={({ field }) => (
                            <Switch
                              checked={field.value === "Yes"}
                              onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <MDTypography variant="body2" textTransform="capitalize">
                          Allow partner leaders to clock members
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <MDTypography variant="h6" color="info">
                    NOTIFICATIONS
                  </MDTypography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <Controller
                            {...notifyWhenAdminUsersMakeChanges}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            When Admin Users make changes
                          </MDTypography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={3}>
                            <Controller
                              {...notifyWhenNewJobsAreAddes}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === "Yes"}
                                  onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <MDTypography variant="body2" textTransform="capitalize">
                              When new Jobs are added
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={3}>
                            <Controller
                              {...clockInRemindersEnabledByDefault}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === "Yes"}
                                  onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <MDTypography variant="body2" textTransform="capitalize">
                              Clock-in reminders enabled by default
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={3}>
                          <Controller
                            {...notifyWhenEventsAreAdded}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={9}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            When events are added
                          </MDTypography>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} sm={3}>
                            <Controller
                              {...notifyWhenApplicantsAreLocked}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  checked={field.value === "Yes"}
                                  onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <MDTypography variant="body2" textTransform="capitalize">
                              When Applicants are locked
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
            <hr />
            <MDBox my={3}>
              <MDTypography variant="h6" color="info">
                STAFFING POOL SETTINGS
              </MDTypography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardLastName}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Last Name
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardFirstName}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        First Name
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={4} lg={6} xl={6} xxl={5}>
                          <Controller
                            {...dashboardPhone1}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={6} xl={6} xxl={7}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            Phone 1
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} lg={6} xl={6} xxl={6}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} sm={4} lg={6} xl={6} xxl={5}>
                          <Controller
                            {...dashboardPhone2}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                checked={field.value === "Yes"}
                                onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={6} xl={6} xxl={7}>
                          <MDTypography variant="body2" textTransform="capitalize">
                            Phone 2
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardEmailAddress}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Email Address
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardHelmEID}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Helm EID
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardHireDate}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Hire Date
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardBirthDate}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Birth Date
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <Controller
                        {...dashboardLastDateWorked}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Last Worked Date
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={2.5}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <Controller
                        {...dashboardRank}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Rank
                      </MDTypography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <Controller
                        {...dashboardLastUpdate}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Last Update
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <Controller
                        {...dashboardNotes}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Notes
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={2.5}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <Controller
                        {...dashboardRepName}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Rep Name
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <Controller
                        {...dashboardResults}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Results
                      </MDTypography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} sm={5}>
                      <Controller
                        {...dashboardOtherVenues}
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <MDTypography variant="body2" textTransform="capitalize">
                        Other Venues
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
            <hr />
            <MDBox my={3}>
              <MDTypography variant="h6" color="info">
                EMPLOYMENT SETTINGS
              </MDTypography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    {...fein}
                    name="fein"
                    control={control}
                    render={({ field }) => (
                      <PatternFormat
                        format="##-###-####"
                        allowEmptyFormatting
                        mask="_"
                        label="FEIN"
                        value={field.value ?? ""}
                        onChange={field.onChange}
                        onValueChange={({ value }) =>
                          setValue("fein", value, { shouldDirty: true })
                        }
                        onBlur={() => trigger("fein")}
                        customInput={FormField}
                      />
                    )}
                  />
                  {errors?.fein && (
                    <MDTypography sx={{ fontSize: "0.75rem" }} color="error">
                      {errors?.fein?.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    {...groupId}
                    name="groupId"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_groupId_${currentVenue?.slug}`}
                        options={groupIds || []}
                        {...field}
                        value={field.value || ""}
                        autoSelect
                        name="groupId"
                        onChange={(e, v) => {
                          setValue("groupId", v || "", { shouldDirty: true });
                        }}
                        onBlur={() => trigger("groupId")}
                        renderInput={(params) => (
                          <FormField
                            key={`state_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="Group Id"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.groupId && (
                    <MDTypography sx={{ fontSize: "0.75rem" }} color="error">
                      {errors?.groupId?.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    {...wcState}
                    name="wcState"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_wcState_${currentVenue?.slug}`}
                        options={states || []}
                        {...field}
                        value={field.value || ""}
                        autoSelect
                        name="wcState"
                        defaultValue={currentVenue ? currentVenue.state : null}
                        onChange={(e, v) => {
                          setValue("wcState", v?.toUpperCase() || "", { shouldDirty: true });
                        }}
                        onBlur={() => trigger("wcState")}
                        renderInput={(params) => (
                          <FormField
                            key={`state_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="WC State"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.wcState && (
                    <MDTypography sx={{ fontSize: "0.75rem" }} color="error">
                      {errors?.wcState?.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    {...wcCode}
                    name="wcCode"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_wcCode_${currentVenue?.slug}`}
                        options={wcCodes || []}
                        {...field}
                        value={field.value || ""}
                        autoSelect
                        name="wcCode"
                        onChange={(e, v) => {
                          setValue("wcCode", v || "", { shouldDirty: true });
                        }}
                        onBlur={() => trigger("wcCode")}
                        renderInput={(params) => (
                          <FormField
                            key={`state_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="WC Code"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.wcCode && (
                    <MDTypography sx={{ fontSize: "0.75rem" }} color="error">
                      {errors?.wcCode?.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    {...payFrequency}
                    name="payFrequency"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        key={`autocomplete_payFrequency_${currentVenue?.slug}`}
                        options={payFrequencyValues || []}
                        {...field}
                        autoSelect
                        name="payFrequency"
                        getOptionLabel={(option) => option.label}
                        value={
                          field.value
                            ? payFrequencyValues?.find((option) => option.value === field.value)
                            : null
                        }
                        onChange={(e, v) => {
                          setValue("payFrequency", v.value, {
                            shouldDirty: true,
                          });
                        }}
                        onBlur={() => trigger("payFrequency")}
                        renderInput={(params) => (
                          <FormField
                            key={`state_${currentVenue?.slug}`}
                            {...params}
                            type="text"
                            label="Pay Frequency"
                          />
                        )}
                      />
                    )}
                  />
                  {errors?.payFrequency && (
                    <MDTypography sx={{ fontSize: "0.75rem" }} color="error">
                      {errors?.payFrequency?.message}
                    </MDTypography>
                  )}
                </Grid>
              </Grid>
            </MDBox>
            <AdditionalInfo
              payrollDeptContact={payrollDeptContact}
              setPayrollDeptContact={setPayrollDeptContact}
              venueContact1={venueContact1}
              setVenueContact1={setVenueContact1}
              venueContact2={venueContact2}
              setVenueContact2={setVenueContact2}
              setSettingsDirty={setSettingsDirty}
            />
          </Box>
          <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
        </CustomCard>{" "}
      </form>
    )
  );
};

export default VenueSettingsPanel;
