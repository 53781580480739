import SmartphoneIcon from "@mui/icons-material/Smartphone";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Box, Button, Icon, styled, Tooltip, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import { useMemo, useState } from "react";
import MDTypography from "components/MDTypography";
import boxShadow from "assets/theme/functions/boxShadow";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { ExpandMore } from "@mui/icons-material";

const StyledBadge = styled(Typography)(({ theme }) => ({
  "&": {
    letterSpacing: "0.02857em",
    textAlign: "center",
    textTransform: "uppercase",
    margin: 0,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 600,
    minWidth: "20px",
    lineHeight: 1,
    borderRadius: "10px",
    zIndex: 1,
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundColor: "#1A73E8",
    color: "#fff",
    transform: "scale(1)",
    transformOrigin: "100% 0%",
    paddingRight: "6px",
    paddingLeft: "6px",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "0.7rem",
    opacity: 0.9,
    height: "unset",
  },
}));

const StyledBadgeWithMargin = styled(StyledBadge)(({ theme }) => ({
  "&": { marginLeft: "16px" },
}));

const SplitPanelSelector = ({ panelOptions, selectedPanel, isEdit, minWidth }) => {
  const selectedPanelOption = useMemo(() => {
    const options = panelOptions ?? [];
    return options.find((op) => op.name === selectedPanel) ?? options[0] ?? null;
  }, [panelOptions, selectedPanel]);
  const [anchorEl, setAnchorEl] = useState(null);

  const options = useMemo(
    () =>
      (panelOptions ?? [])
        .filter((item) => !item.hidden)
        .map((item) => ({
          text: item.title,
          icon: (
            <Icon
              fontSize="medium"
              sx={{
                color: (theme) => theme.palette?.[item?.color]?.main,
              }}
            >
              {item.icon}
            </Icon>
          ),
          handleClick: (e) => item.fn(e),
          disabled: item.disabled,
          rightItem: item?.badgeCount ? (
            <StyledBadgeWithMargin>{item?.badgeCount}</StyledBadgeWithMargin>
          ) : null,
        })),
    [panelOptions]
  );

  const handlePress = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  if (!isEdit) return null;

  return (
    <>
      <Button
        variant="gradient"
        m={1}
        onClick={handlePress}
        disabled={!isEdit}
        sx={{
          borderRadius: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingTop: 0.5,
          paddingBottom: 0.4,
          paddingLeft: 1.5,
          paddingRight: 1.5,
          gap: 1.5,
          boxShadow: `${boxShadow([0, 4], [10, -1], "#000", 0.6)}, ${boxShadow(
            [0, 2],
            [4, -1],
            "#000",
            0.5
          )}`,
          minWidth,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1.5 }}>
          <Icon
            sx={{
              color: (theme) => theme.palette?.[selectedPanelOption?.color]?.main,
              marginTop: "-4px",
            }}
            aria-label={selectedPanelOption?.ariaLabel}
            fontSize="2rem"
          >
            {selectedPanelOption?.selectedIcon ?? selectedPanelOption?.icon}
          </Icon>

          <MDTypography
            variant="h5"
            sx={{
              fontSize: "1rem",
              fontWeight: 500,
              textTransform: "capitalize",
              color: "info",
            }}
          >
            {selectedPanelOption?.selectedTitle ?? selectedPanelOption?.title}
          </MDTypography>

          {!!selectedPanelOption?.badgeCount && (
            <StyledBadge>{selectedPanelOption?.badgeCount}</StyledBadge>
          )}
        </Box>

        <ExpandMore color="dark" fontSize="medium" />
      </Button>

      <CustomMenu
        anchorEl={anchorEl}
        handleClose={(e) => {
          e?.stopPropagation();
          setAnchorEl(null);
        }}
        options={options}
        iconMargin="5px"
      />
    </>
  );
};

export default SplitPanelSelector;
