import { AddCircleRounded, ContentPasteSearch } from "@mui/icons-material";
import { IconButton, Stack, TableHead } from "@mui/material";
import DataList from "components/DataList";
import FilterButtons from "components/FilterButtons";
import { JOB_ASSESSMENTS_OPTIONS } from "components/FilterButtons/options";
import { useAppContextController } from "context/AppContext";
import fetchAssessments from "layouts/pages/assessments/actions/fetchAssessments";
import { useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import useSort from "utils/useSort";
import JobAssessmentRow from "./JobAssessmentRow";

const CompanyJobAssessments = ({ jobSlug }) => {
  const { venues, currentApplicant, userType, company, currentLoggedUser } =
    useAppContextController();
  const [dataFilter, setDataFilter] = useState({ type: "all" });
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const { order, orderBy, toggleSort } = useSort("name", "asc");
  const options = useMemo(() => {
    return {
      fetchAll: false,
      orderBy,
      order,
      limit: 10,
      useElemMatch: true,
    };
  }, [order, orderBy]);

  const fetchOptions = useMemo(() => {
    return {
      ...options,
      page,
      filters: {
        jobSlug,
        ...(dataFilter?.type && dataFilter?.type !== "all"
          ? {
              type: dataFilter?.type,
            }
          : {}),
      },
    };
  }, [options, page, jobSlug, dataFilter]);

  const {
    data: assessments,
    isLoading,
    refetch: refetchApplicants,
    fetchNextPage,
  } = useInfiniteQuery(
    ["jobAssessments", fetchOptions],
    ({ queryKey, pageParam }) => fetchAssessments({ ...fetchOptions, page: pageParam ?? 1 }),
    {
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.data?.length || !lastPage?.pagination?.next?.page) {
          return undefined;
        }

        const nextPage = lastPage?.pagination?.next?.page;
        return nextPage ?? false;
      },
    }
  );

  const fullData = useMemo(
    () =>
      assessments?.pages?.flatMap((p) => {
        const currentItems = p?.data || [];
        return currentItems;
      }),
    [assessments?.pages]
  );

  const handleCreate = () => {
    navigate(`/assessments/create?jobSlug=${jobSlug}`);
  };

  const header = (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="right" pr={3}>
        <IconButton
          // sx={{ p: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            handleCreate();
          }}
        >
          <AddCircleRounded color="info" fontSize="medium" />
        </IconButton>
      </Stack>
    </>
  );

  const renderRow = (row) => <JobAssessmentRow row={row} />;

  const topButtonBar = (
    <TableHead
      sx={{
        height: "8rem",
        zIndex: 3,
        position: "sticky",
        top: 0,
        background: "white",
        width: "100%",
      }}
      display="flex"
      width="100%"
    >
      <FilterButtons
        filter={dataFilter}
        setFilter={setDataFilter}
        name="type"
        options={JOB_ASSESSMENTS_OPTIONS.slice(0, 3)}
        // direction="row-reverse"
      />
      <FilterButtons
        filter={dataFilter}
        setFilter={setDataFilter}
        name="type"
        options={JOB_ASSESSMENTS_OPTIONS.slice(3)}
        // direction="row-reverse"
      />
    </TableHead>
  );

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={fullData}
        icon={<ContentPasteSearch />}
        iconColor="info"
        title="Assessments"
        isLoading={isLoading}
        topButtonBar={topButtonBar}
        greybar
        divider
        header={header}
        tableHeight={450}
        height={850}
        scrollY
        computedHeight
        loadMore={fetchNextPage}
        infiniteData={assessments?.pages?.[assessments?.pages?.length - 1]}
      />
    </>
  );
};

export default CompanyJobAssessments;
