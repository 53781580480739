import { useState } from "react";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import GenericModal from "components/GenericModal";
import FileDropzone from "components/Dropzone";
import MDTypography from "components/MDTypography";
import { Box, Button } from "@mui/material";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import CancelIcon from "@mui/icons-material/Cancel";
import uploadResume from "api/applicants/uploadResume";
import { useAppContextController } from "context/AppContext";
import { getFileRejectionErrors } from "utils/helpers/formatters";

const BulkResumeUploadModal = ({ open, setOpen, onSuccess }) => {
  const { currentLoggedUser } = useAppContextController();
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Define constants for file upload
  const allowedFileTypes = ".pdf, .docx, .doc, .txt, .zip";
  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB max file size

  const uploadResumesMutation = useMutation(uploadResume, {
    onSuccess: () => {
      enqueueSnackbar("Resumes uploaded successfully!", { variant: "success" });
      if (onSuccess) onSuccess();
      setOpen(false);
    },
    onError: (error) => {
      enqueueSnackbar(error?.response?.data?.message || "Error uploading resumes", {
        variant: "error",
      });
    },
    onSettled: () => {
      setIsUploading(false);
    },
  });

  const handleFileUpload = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      // Use the utility function to get formatted error messages
      const errorMessage = getFileRejectionErrors(
        rejectedFiles,
        allowedFileTypes,
        MAX_FILE_SIZE,
        "Resume"
      );

      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }

    if (acceptedFiles.length > 0) {
      setIsUploading(true);
      const formData = new FormData();
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("createAgent", currentLoggedUser?._id);

      await uploadResumesMutation.mutateAsync(formData);
    }
  };

  const modalBody = (
    <Box>
      <MDTypography variant="h6" mb={2}>
        Upload one or more resumes or zip files containing resumes
      </MDTypography>
      <FileDropzone
        accept={{
          "application/pdf": [".pdf"],
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
          "text/plain": [".txt"],
          "application/zip": [".zip"],
        }}
        multiple
        message="Drop files here to upload"
        message2={`Allowed types: ${allowedFileTypes}`}
        onDrop={handleFileUpload}
        disabled={isUploading}
        maxSize={MAX_FILE_SIZE}
      />
    </Box>
  );

  const modalHeader = (
    <FlexWrapper justifyContent="space-between" px={1} sx={{ flexWrap: "nowrap" }}>
      <MDTypography variant="h5">Bulk Resume Upload</MDTypography>
      <Button
        variant="text"
        disabled={isUploading}
        startIcon={<CancelIcon />}
        onClick={() => setOpen(false)}
      >
        Cancel
      </Button>
    </FlexWrapper>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      body={modalBody}
      header={modalHeader}
      sx={(theme) => ({
        width: "30% !important",
        maxWidth: "50% !important",
        [theme.breakpoints.down("md")]: {
          "& .MuiCardContent-root": {
            padding: "0px",
          },
        },
      })}
    />
  );
};

export default BulkResumeUploadModal;
