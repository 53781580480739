import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { Box, Button, Grid, Stack, useMediaQuery } from "@mui/material";

import DataList from "components/DataList";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import moment from "moment";
import DateRangeSelector from "components/DateRangeSelector";
import MyJobsActions from "../MyJobsActions";

const MyJobs = ({ fetchAll, setActionSelected = "info", title = "Title", filters, setFilters }) => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const columns = useMemo(() => [
    { title: "", field: "logoUrl", type: "image", imageWidth: "50", imageHeight: "50" },
    {
      title: "Venue",
      field: "venueSlug",
      customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      width: "15%",
    },
    { title: "Job Url", field: "jobSlug", width: "15%" },
    { title: "Title", field: "title", width: "25%" },
    {
      title: "Pay Rate",
      field: "payRate",
      customCell: (rate) => `$${rate?.toFixed(2)}`,
      width: "10%",
    },
    {
      title: "Start Date",
      field: "startDate",
      customCell: (date) => moment(date).format("MM/DD/YYYY"),
      width: "10%",
    },
    { title: "Status", field: "status", width: "15%" },
  ]);

  const [dateRangeFilter, setDateRangeFilter] = useState(null);

  const [page, setPage] = useState(1);
  const { venues, allVenues, setCurrentJob } = useAppContextController();
  const options = { fetchAll: false };
  const allOptions = {
    ...options,
    limit: 30,
    filters: { ...filters, status: "Active" },
    order: "venueSlug",
    orderBy: "venueSlug",
  };

  if (dateRangeFilter) {
    allOptions.startDate = dateRangeFilter.startDate.toISOString();
    allOptions.endDate = dateRangeFilter.endDate.toISOString();
  }

  const { data: fetchedJobs, isLoading } = useQuery(["myjobs", allOptions], () => {
    if (allOptions.filters?.venueSlug) {
      return fetchJobs(allOptions);
    }
    return {};
  });

  const navigate = useNavigate();
  const addNew = () => navigate("/jobs/create");
  const viewAll = () => navigate("/jobs");

  const renderRow = (row) => (
    <>
      <Grid container alignItems="center" key={row._id}>
        <Grid item xs={12} sm={1.5}>
          <VenueIcon
            logoUrl={allVenues?.[row?.venueSlug]?.logoUrl}
            slug={row.venueSlug}
            name={allVenues?.[row?.venueSlug]?.name}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={12} sm={12}>
              <MDTypography
                variant="subtitle2"
                sx={{ fontWeight: "bold" }}
                key={`${row._id}_${row.jobSlug}`}
              >
                {row.title}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <MDTypography variant="body2" key={`${row._id}_${row.venueName}`}>
                {venues?.[row?.venueSlug]?.name} - {venues?.[row?.venueSlug]?.city},{" "}
                {venues?.[row?.venueSlug]?.state}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Box sx={{ textAlign: "right" }} pr={2}>
            <MyJobsActions slug={row.jobSlug} />
          </Box>
        </Grid>
      </Grid>
    </>
  );

  const searchBarRowClick = (item) => {
    setCurrentJob(item);
    navigate(`/jobs/${item.jobSlug}/action/jobinfo`);
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      <Grid container mt={2} ml={12}>
        <Grid item xs={12}>
          <Searchbar
            fetch={fetchJobs}
            fetchAll={fetchAll}
            placeholder="Search Job/Venue"
            columns={columns.slice(1, columns.length - 1)}
            queryCharacterLimit={1}
            resultsLimit={10}
            setFilters={setFilters}
            setPage={setPage}
            searchBy="title,venueSlug,venueName"
            filterBy="venueSlug"
            onRowClick={(item) => searchBarRowClick(item)}
            dropRight
          />
        </Grid>
        <Grid item container sm={6}>
          <DateRangeSelector
            setDateRangeFilter={setDateRangeFilter}
            dateRangeFilter={dateRangeFilter}
            text
          />
        </Grid>
        <Grid item sm={6}>
          <Button variant="text" color="primary" onClick={viewAll}>
            View All
          </Button>
          |
          <Button variant="text" color="primary" onClick={addNew}>
            Add New
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );

  return (
    <DataList
      renderRow={renderRow}
      data={fetchedJobs?.data}
      filters={{ ...filters, status: "Active" }}
      limit={30}
      icon="work"
      iconColor="warning"
      title="My Jobs"
      isLoading={isLoading}
      greybar
      divider
      header={header}
      tableHeight={420}
      height={isLaptop ? 500 : null}
      scrollY
    />
  );
};

export default MyJobs;
