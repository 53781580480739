import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { removeHtmlTags, removeStylingTags } from "utils/string";
import useCompanyStore from "stores/useCompanyStore";
import FormField from "layouts/applications/wizard/components/FormField";
import {
  messageEmail,
  messageEmailBulk,
  messageSystem,
  messageSystemBulk,
  messageText,
  messageTextBulk,
  messageTopicPushNotification,
  templateSubstitution,
} from "layouts/pages/applicants/actions/sendMessageAction";
import { Close } from "@mui/icons-material";
import fetchSingleEvent from "layouts/pages/events/actions/fetchSingleEvent";
import { useSnackbar } from "notistack";
import { sanitizeSlug } from "utils/helpers/sanitizeSlug";
// import templateTypes from "assets/templateTypes.json";
import MDButton from "components/MDButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import PropTypes from "prop-types";
import useSessionAuth from "hooks/useSessionAuth";
import ConfirmationModal from "components/ConfirmationModal";
import Searchbar from "components/Searchbar";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import fetchCompanyJob from "api/companies/fetchCompanyJob";
import generateFinalResume from "api/applicants/generateFinalResume";
import useMinStageToOnboarding from "hooks/useMinStageToOnboarding";
import EditorPreviewModal from "./EditorPreviewModal";
import AddMessageAttachmentModal from "./AddMessageAttachmentModal";

const useStyle = makeStyles({
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
    height: 550,
  },
});

const SendMessage = ({
  recipient,
  setOpen,
  setText = (value) => {},
  text,
  email,
  setEmail,
  system,
  setSystem,
  applicant,
  eventId = null,
  jobId = null,
  venueId = null,
  eventVenue,
  companyId = null,
  partnerId = null,
  isButtonDisabled = false,
  bulk = false,
  selectedRecipients = [],
  hideMessageSelection = false,
  hideMessageType = false,
  templateIn = null,
  templateSelectionDisabled = false,
  subjectIn = "",
  messageContext = "Applicant",
  attachmentContext = "Applicant",
  recipientsTable,
  isPanel = false,
  companyType: companyTypeProp,
  venueSlug,
  pushNotificationSelection,
  setPushNotificationSelection,
  dataStatus,
  noText = false,
  showStatusChangeButton = false,
  onComplete = () => {},
  forceCustomSubject = false,
  substitutionRecipient,
  applicants,
  // onUpdateApplicant,
}) => {
  const sb = useSnackbar();
  const enqueueSnackbar = sb?.enqueueSnackbar || null;
  const classes = useStyle();
  const {
    company,
    currentLoggedUser,
    currentVenue,
    currentJob,
    currentEvent,
    currentPartner,
    emailTemplates,
    getEmailTemplates,
    venues,
    companyType: companyTypeLocal,
  } = useAppContextController();
  const companyType = companyTypeProp ?? companyTypeLocal;
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));

  const [selectedTemplate, setSelectedTemplate] = useState(templateIn);
  const [previousTemplate, setPreviousTemplate] = useState("");
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [subjectValue, setSubjectValue] = useState("");
  const [previewMessageText, setPreviewMessageText] = useState(null);
  const [previewSubjectText, setPreviewSubjectText] = useState(null);
  const [error, setError] = useState(false);
  const [noFormatSelected, setNoFormatSelected] = useState(false);
  const [noTemplateSelected, setNoTemplateSelected] = useState(false);
  const [noSubject, setNoSubject] = useState(false);
  const [noMessage, setNoMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customSubject, setCustomSubject] = useState(subjectIn);
  const [templateTypes, setTemplateTypes] = useState([]);
  const { _id: userId, emailAddress } = currentLoggedUser;
  // const { _id: applicantId, email: applicantEmail, phone, firstName, lastName } = recipient;
  const applicantId = recipient?._id || recipient?.id || recipient?.applicantId;
  const applicantEmail = recipient?.email;
  const phone = recipient?.phone;
  const firstName = recipient?.firstName;
  const lastName = recipient?.lastName;
  const [messageModal, setMessageModal] = useState(false);
  const [pushNotificationsOptions, setPushNotificationsOptions] = useState([]);
  const { logoutAuth0User } = useSessionAuth();
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const [includeAttachments, setIncludeAttachments] = useState(false);
  const [sendCopyToMyEmail, setSendCopyToMyEmail] = useState(false);
  const [sendCarbonicCopy, setSendCarbonicCopy] = useState(false);
  const [sendBlindCarbonicCopy, setSendBlindCarbonicCopy] = useState(false);
  const [attachmentSelection, setAttachmentSelection] = useState([]);
  const [attachmentOptions, setAttachmentOptions] = useState([]);
  const [attachmentInputValue, setAttachmentInputValue] = useState("");
  const [addMessageAttachmentModalOpen, setAddMessageAttachmentModalOpen] = useState(false);
  const [cCEmails, setCCEmails] = useState([]);
  const [bCCEmails, setBCCEmails] = useState([]);
  const [cCEmailInputValue, setCCEmailInputValue] = useState("");
  const [bCCEmailInputValue, setBCCEmailInputValue] = useState("");
  const [cCError, setCCError] = useState("");
  const [bCCError, setBCCError] = useState("");
  const [missingContext, setMissingContext] = useState([]);
  const [hasTemplateError, setHasTemplateError] = useState(false);

  const [templateJob, setTemplateJob] = useState(null);
  const [templateEvent, setTemplateEvent] = useState(null);
  const [templateVenue, setTemplateVenue] = useState(null);

  const [showOnboardingInvitationWarning, setShowOnboardingInvitationWarning] = useState(false);
  const closeModalHandler = () => setShowOnboardingInvitationWarning(false);
  const { minStage, allowedStages, disallowedStages } = useMinStageToOnboarding();

  // Unused. Updating applicant involves refreshing lists and forms in other components
  // const changeApplicantStatus = (applId, status, jobSlug) => {
  //   updateApplicant({
  //     applicantId: applId,
  //     data: {
  //       applicantStatus: status,
  //     },
  //   })
  //     .then((_) => {
  //       enqueueSnackbar(`Applicant has been updated!`, { variant: "success" });
  //       onUpdateApplicant?.();
  //     })
  //     .catch((e) => {
  //       enqueueSnackbar(e.message, { variant: "error" });
  //     });
  // };

  const areAllRecipientsReadyForOnboarding = () => {
    if (!applicants?.length || !selectedRecipients?.length) {
      return true;
    }

    const applicantsIndexed = {};
    // Index the applicants for easy access
    applicants.forEach((appl) => {
      if (appl._id || appl.id)
        applicantsIndexed[appl._id?.toString() ?? appl.id?.toString()] = appl;
    });

    for (let index = 0; index < selectedRecipients.length; index += 1) {
      const rec = selectedRecipients[index];
      if (
        applicantsIndexed[rec] &&
        applicantsIndexed[rec].status === "Applicant" &&
        disallowedStages.includes(applicantsIndexed[rec].applicantStatus)
      )
        return false;
    }

    return true;
  };

  // useEffect(() => {
  //   // This is where we need to call the API endpoint to do the string substitution
  //   // setIsLoading(true);
  // }, [
  //   selectedRecipients?.length,
  //   applicantId,
  //   selectedRecipients,
  //   bulk,
  //   venueId,
  //   eventId,
  //   venues,
  //   currentLoggedUser,
  //   jobId,
  //   emailTemplates,
  // ]);

  const [hasPendingTemplateErrors, pendingMissingContext] = useMemo(() => {
    if (!hasTemplateError) return [false, []];

    // The template got an error other than mising context
    if (!missingContext?.length) return [true, []];

    const missingContextCopy = [...missingContext];
    if (templateJob) {
      const index = missingContextCopy.indexOf("job");
      if (index !== -1) {
        missingContextCopy.splice(index, 1);
      }
    }
    return [!!missingContextCopy.length, missingContextCopy];
  }, [hasTemplateError, missingContext, templateJob]);

  useEffect(() => {
    const getMessageTemplateSubstitution = async () => {
      if (!applicantId && !selectedRecipients?.length) {
        const thisTemplate = emailTemplates.find((item) => item.name === selectedTemplate);
        if (thisTemplate?.Message) setEditorValue(thisTemplate.Message);
        if (thisTemplate?.subject) setPreviewSubjectText(thisTemplate.subject);
        setSubjectValue("");

        return false;
      }

      let appId = bulk && selectedRecipients?.length ? selectedRecipients[0] : applicantId;
      if (substitutionRecipient) appId = substitutionRecipient._id;
      // if venueId is null, then we need to get the venueId from the event record
      let venueIdFromEvent = venueId;
      if (eventId && !venueIdFromEvent) {
        // look up the venueId in the venue cache
        try {
          const event = await fetchSingleEvent({ eventId });
          if (event?.venueSlug) {
            const venue = venues[event.venueSlug];
            venueIdFromEvent = venue._id;
          }
        } catch (err) {
          if (String(err).includes("401") || err?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      }

      if (appId) {
        const data = {
          selectedTemplate,
          applicantId: appId,
          user: currentLoggedUser,
          venueId: (venueIdFromEvent || venueId) ?? templateVenue?._id?.toString(),
          eventId: eventId ?? templateEvent?._id?.toString(),
          jobId: jobId ?? templateJob?._id?.toString(),
          companyId,
          partnerId,
          createAgent: currentLoggedUser?._id,
        };

        try {
          const result = await templateSubstitution(data);

          if (!result?.data?.success) {
            setHasTemplateError(true);
            setMissingContext(result?.data?.missingContext ?? []);
          }

          if (result?.data?.messageText) {
            if (bulk) {
              setPreviewMessageText(result.data.messageText);
              const thisTemplate = emailTemplates.find((item) => item.name === selectedTemplate);
              if (thisTemplate?.Message) setEditorValue(thisTemplate.Message);
              setSubjectValue("");
              setPreviewSubjectText(result?.data?.subjectText ?? thisTemplate?.subject);
            } else {
              setEditorValue(result.data.messageText);
              setSubjectValue(result.data.subjectText);
              setPreviewSubjectText(result.data.subjectText);
            }
          }
        } catch (err) {
          if (String(err).includes("401") || err?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      } else {
        const thisTemplate = emailTemplates.find((item) => item.name === selectedTemplate);
        if (thisTemplate?.Message) setEditorValue(thisTemplate.Message);
        if (thisTemplate?.subject) setPreviewSubjectText(thisTemplate.subject);
        setSubjectValue("");
      }
      return true;
      //      else setEditorValue('<p></p>')
    };

    if (selectedTemplate && selectedTemplate !== "Custom Message") {
      getMessageTemplateSubstitution();
      setPreviousTemplate(selectedTemplate);
    }
    if (selectedTemplate === "Custom Message" && selectedTemplate !== previousTemplate) {
      setEditorValue("<p></p>");
      setSubjectValue("");
      setPreviewSubjectText(null);
      setPreviousTemplate(selectedTemplate);
    }
  }, [selectedTemplate, templateVenue, templateJob, templateEvent, selectedRecipients?.length]);

  useEffect(() => {
    setHasTemplateError(false);
    setMissingContext([]);
    setTemplateJob(null);
    setTemplateEvent(null);
    setTemplateVenue(null);
    setPreviewMessageText(null);
  }, [selectedTemplate]);

  useEffect(() => {
    if (!emailTemplates?.length) getEmailTemplates();
  }, [emailTemplates?.length]);

  useEffect(() => {
    if (emailTemplates?.length) {
      let filteredEmailTemplates;
      if (messageContext === "Applicant") {
        filteredEmailTemplates = emailTemplates.filter((item) => {
          return (
            item.context.includes(messageContext) &&
            !item.context.includes("Venue") &&
            !item.context.includes("Event") &&
            !item.context.includes("Issue")
          );
        });
      } else if (messageContext === "Venue" && dataStatus !== "Prospect") {
        filteredEmailTemplates = emailTemplates.filter((item) => {
          return (
            (item.context.includes(messageContext) || item.context.includes("Applicant")) &&
            !item.context.includes("Event") &&
            !item.context.includes("Issue")
          );
        });
      } else if (messageContext === "Venue" && dataStatus === "Prospect") {
        filteredEmailTemplates = emailTemplates.filter((item) => {
          return (
            (item.context.includes(messageContext) || item.context.includes("Prospect")) &&
            !item.context.includes("Event") &&
            !item.context.includes("Issue")
          );
        });
      } else if (messageContext === "Event") {
        filteredEmailTemplates = emailTemplates.filter((item) => {
          return (
            item.context.includes(messageContext) ||
            item.context.includes("Applicant") ||
            item.context.includes("Venue")
          );
        });
      } else if (messageContext === "Partner") {
        filteredEmailTemplates = emailTemplates.filter((item) => {
          return item.context.includes(messageContext) || item.context.includes("Applicant");
        });
      } else {
        filteredEmailTemplates = emailTemplates.filter((item) => {
          return item.context.includes(messageContext);
        });
      }

      setTemplateTypes(["Custom Message", ...filteredEmailTemplates.map((item) => item.name)]);
    }
  }, [emailTemplates?.length]);

  useEffect(() => {
    const resultArray = [
      {
        entity: attachmentContext,
        group: "New File",
        filename: "[ Upload Attachment ]",
      },
    ];
    if (attachmentContext === "Applicant") {
      if (company?.primaryCompany && company.attachments?.length) {
        resultArray.push(
          ...company.attachments.map((option) => ({
            entity: "Company",
            group: company.name,
            filename: option.filename,
          }))
        );
      }
      if (messageContext === "Venue" && currentVenue?.otherUrls?.length) {
        resultArray.push(
          ...currentVenue.otherUrls.map((option) => ({
            entity: "Venue",
            group: "Venue",
            filename: option,
            venueSlug: currentVenue?.slug,
          }))
        );
      }
      if (messageContext === "Event" && currentEvent?.attachments?.length) {
        resultArray.push(
          ...currentEvent.attachments.map((option) => ({
            entity: "Event",
            group: "Event",
            filename: option.filename,
            venueSlug: currentEvent.venueSlug,
            eventUrl: currentEvent.eventUrl,
          }))
        );
      }
      if (messageContext === "Customer" && currentCompany?.attachments?.length) {
        resultArray.push(
          ...currentCompany.attachments.map((option) => ({
            entity: "Customer",
            group: "Customer",
            filename: option.filename,
            companySlug: currentCompany.companySlug,
          }))
        );
      }
      if (messageContext === "Job" && currentJob?.attachments?.length) {
        resultArray.push(
          ...currentJob.attachments.map((option) => ({
            entity: "Job",
            group: "Job",
            filename: option.filename,
            jobSlug: currentJob.jobSlug,
            ...(!!currentJob.venueSlug && { venueSlug: currentJob.venueSlug }),
          }))
        );
      }

      if (recipient?.attachments?.length) {
        resultArray.push(
          ...recipient?.attachments?.map((option) => ({
            entity: "Applicant",
            group: option.type,
            filename: option.filename ?? option.name,
            applicantId,
            type: option.type,
          }))
        );
      }
      setAttachmentOptions(resultArray);
    }
    if (attachmentContext === "Applicant Job Submittal") {
      resultArray.push({
        entity: "Applicant",
        group: "Resume",
        filename: "[ Create Final Resume ]",
        applicantId: applicant._id,
        type: "resumes",
      });
      if (applicant?.attachments?.length) {
        resultArray.push(
          ...applicant?.attachments
            ?.filter(
              (item) =>
                item.type === "Resume" || item.type === "AIScreening" || item.type === "Assessments"
            )
            ?.map((option) => ({
              entity: "Applicant",
              group: option.type,
              filename: option.filename ?? option.name,
              applicantId: applicant._id,
              type: option.type,
            }))
        );
      }
      setAttachmentOptions(resultArray);
    }
    if (attachmentContext === "Venue") {
      if (company?.primaryCompany && company.attachments?.length) {
        resultArray.push(
          ...company.attachments.map((option) => ({
            entity: "Company",
            group: company.name,
            filename: option.filename,
          }))
        );
      }
      if (currentVenue?.otherUrls?.length) {
        resultArray.push(
          ...currentVenue.otherUrls.map((option) => ({
            entity: "Venue",
            group: "Venue",
            filename: option,
            venueSlug: currentVenue?.slug,
          }))
        );
      }
      setAttachmentOptions(resultArray);
    }
    if (attachmentContext === "Customer") {
      if (company?.primaryCompany && company.attachments?.length) {
        resultArray.push(
          ...company.attachments.map((option) => ({
            entity: "Company",
            group: company.name,
            filename: option.filename,
          }))
        );
      }
      if (currentCompany?.attachments?.length) {
        resultArray.push(
          ...currentCompany.attachments.map((option) => ({
            entity: "Customer",
            group: "Customer",
            filename: option.filename,
            companySlug: currentCompany.companySlug,
          }))
        );
      }
      setAttachmentOptions(resultArray);
    }
    if (attachmentContext === "Partner") {
      if (company?.primaryCompany && company.attachments?.length) {
        resultArray.push(
          ...company.attachments.map((option) => ({
            entity: "Company",
            group: company.name,
            filename: option.filename,
          }))
        );
      }
      if (currentPartner?.attachments?.length) {
        resultArray.push(
          ...currentPartner.attachments.map((option) => ({
            entity: "Partner",
            group: "Partner",
            filename: option?.filename,
            partnerSlug: currentPartner.slug,
          }))
        );
      }
      if (recipient?.attachments?.length) {
        resultArray.push(
          ...recipient?.attachments?.map((option) => ({
            entity: "Applicant",
            group: option.type,
            filename: option.filename ?? option.name,
            applicantId,
            type: option.type,
          }))
        );
      }
      setAttachmentOptions(resultArray);
    }
    if (attachmentContext === "Event") {
      if (company?.primaryCompany && company.attachments?.length) {
        resultArray.push(
          ...company.attachments.map((option) => ({
            entity: "Company",
            group: company.name,
            filename: option.filename,
          }))
        );
      }

      if (eventVenue?.otherUrls?.length) {
        resultArray.push(
          ...eventVenue.otherUrls.map((option) => ({
            entity: "Venue",
            group: "Venue",
            filename: option,
            venueSlug: currentEvent.venueSlug,
          }))
        );
      }

      if (currentEvent?.attachments?.length) {
        resultArray.push(
          ...currentEvent.attachments.map((option) => ({
            entity: "Event",
            group: "Event",
            filename: option.filename,
            venueSlug: currentEvent.venueSlug,
            eventUrl: currentEvent.eventUrl,
          }))
        );
      }
      setAttachmentOptions(resultArray);
    }
    if (attachmentContext === "Job") {
      // to be tested
      if (company?.primaryCompany && company.attachments?.length) {
        resultArray.push(
          ...company.attachments.map((option) => ({
            entity: "Company",
            group: company.name,
            filename: option.filename,
          }))
        );
      }
      if (venues?.length) {
        const jobVenue = venues?.filter((item) => item.slug === currentJob.venueSlug);
        if (jobVenue?.otherUrls?.length) {
          resultArray.push(
            ...jobVenue.otherUrls.map((option) => ({
              entity: "Venue",
              group: "Venue",
              filename: option,
              venueSlug: currentJob.venueSlug,
            }))
          );
        }
      }

      if (currentJob?.attachments?.length) {
        resultArray.push(
          ...currentJob.attachments.map((option) => ({
            entity: "Job",
            group: "Job",
            filename: option.filename,
            jobSlug: currentJob.jobSlug,
            ...(!!currentJob.venueSlug && { venueSlug: currentJob.venueSlug }),
          }))
        );
      }
    }

    const filteredAttachmentArray = resultArray?.filter((item) => {
      return !attachmentSelection?.some((selectionItem) => {
        return selectionItem.entity === item.entity && selectionItem.filename === item.filename;
      });
    });
    setAttachmentOptions(filteredAttachmentArray);
  }, [attachmentContext, attachmentSelection]);

  useEffect(() => {
    const assignPushNotifications = async () => {
      if (messageContext === "Event") {
        try {
          const event = await fetchSingleEvent({ eventId });

          const eventNotificationsOptions = [
            { value: `${sanitizeSlug(event.venueSlug)}`, label: `This Venue` },
            {
              value: `${sanitizeSlug(event.venueSlug)}-staffingpool`,
              label: `This Venue - Staffing Pool`,
            },
            { value: `${sanitizeSlug(event.venueSlug)}-pending`, label: `This Venue - Pending` },
            { value: `${sanitizeSlug(event.eventUrl)}`, label: `This Event` },
            { value: `${sanitizeSlug(event.eventUrl)}-roster`, label: `This Event - Roster` },
            { value: `${sanitizeSlug(event.eventUrl)}-waitlist`, label: `This Event - Waitlist` },
            { value: `${sanitizeSlug(event.eventUrl)}-request`, label: `This Event - Request` },
            {
              value: `${sanitizeSlug(event.eventUrl)}-not-roster`,
              label: `This Event - Not Roster`,
            },
            {
              value: `${sanitizeSlug(event.eventUrl)}-event-staff`,
              label: `This Event - Event Staff`,
            },
            ...(event?.positions?.length > 0
              ? event.positions?.map((position) => {
                  return {
                    value: `${sanitizeSlug(
                      `${event.eventUrl}-${position?.positionName?.toLowerCase()}`
                    )}`,
                    label: `This Event - ${position.positionName}`,
                  };
                })
              : []),
          ];
          setPushNotificationsOptions(eventNotificationsOptions);
        } catch (err) {
          if (String(err).includes("401") || err?.response?.status === 401) {
            logoutAuth0User();
          }
        }
      }
      if (messageContext === "Venue") {
        const venueNotificationsOptions = [
          { value: `${sanitizeSlug(venueSlug)}`, label: `This Venue` },
          { value: `${sanitizeSlug(venueSlug)}-staffingpool`, label: `This Venue - staffingPool` },
          { value: `${sanitizeSlug(venueSlug)}-pending`, label: `This Venue - pending` },
        ];
        setPushNotificationsOptions(venueNotificationsOptions);
      }
    };
    if (companyType === "Venue") {
      assignPushNotifications();
    }
  }, [messageContext, eventId, venueSlug, companyType]);

  const handleTemplateChange = (v) => {
    setSelectedTemplate(v);
    if (v !== "Custom Message" && bulk) {
      setPushNotificationSelection(null);
    }
    if (selectedTemplate) {
      setNoTemplateSelected(false);
    }
  };
  const handlePushNotificationChange = (v) => {
    setPushNotificationSelection(v);
  };
  const handleAttachmentChange = (value) => {
    const {
      entity,
      filename,
      eventUrl,
      companySlug,
      type,
      venueSlug: venueSlugAttachment,
      applicantId: applicantIdAttachment,
      jobSlug,
      partnerSlug,
    } = value;
    if (filename === "[ Upload Attachment ]") {
      setAttachmentInputValue("");
      setAddMessageAttachmentModalOpen(true);
    } else {
      setAttachmentSelection([
        ...attachmentSelection,
        {
          entity,
          filename,
          ...(eventUrl !== undefined && { eventUrl }),
          ...(companySlug !== undefined && { companySlug }),
          ...(jobSlug !== undefined && { jobSlug }),
          ...(type !== undefined && { type }),
          ...(venueSlugAttachment !== undefined && { venueSlug: venueSlugAttachment }),
          ...(applicantIdAttachment !== undefined && { applicantId: applicantIdAttachment }),
          ...(partnerSlug !== undefined && { partnerSlug }),
        },
      ]);
      setAttachmentInputValue("");
    }
  };

  const handleSubjectChange = (value) => {
    setCustomSubject(value);
  };

  const confirmationModalBody =
    pushNotificationSelection && pushNotificationSelection !== "selected-individuals" ? (
      <>
        <Typography variant="body2">
          {" "}
          Are you sure you want to send this push notification to all {
            pushNotificationSelection
          }{" "}
          subscribers?
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="body2">
          {" "}
          Are you sure you want to send this message to {selectedRecipients?.length}{" "}
          {`recipient${selectedRecipients?.length > 1 ? "s" : ""}`}?
        </Typography>
      </>
    );

  const getSubjectFromTemplate = (template) => {
    if (subjectValue) return subjectValue;
    if (!template) return "";
    const ndx = emailTemplates.findIndex((item) => item.name === template);
    if (ndx > -1) return emailTemplates[ndx].subject;
    return "";
  };

  const onSendMessage = () => {
    setIsLoading(false);
    setSystem(false);
    setText(false);
    setEmail(true);
    setError(false);
    setEditorValue(`<p></p>`);
    setSubjectValue("");
    setPreviewSubjectText(null);
    setSelectedTemplate(null);
    setCCEmailInputValue("");
    setCustomSubject("");
    setCCEmails([]);
    setCCError("");
    setBCCEmailInputValue("");
    setBCCEmails([]);
    setBCCError("");
    setAttachmentSelection([]);
    setIncludeAttachments(false);
    setSendBlindCarbonicCopy(false);
    setSendCarbonicCopy(false);
    setSendCopyToMyEmail(false);
    onComplete();
    if (setOpen) {
      setOpen(false);
    }
  };

  const sendSingleMessage = async (updateApplicantModalSelection) => {
    setError(true);
    if (!(noFormatSelected || noTemplateSelected || noSubject || noMessage)) {
      // Check if we're trying to send an Onboarding invitation to a non-screened applicant (by default)
      // let updateAfterSend = false;
      if (
        recipient &&
        selectedTemplate === "Onboarding Invitation" &&
        recipient.status === "Applicant" &&
        disallowedStages.includes(recipient.applicantStatus)
      ) {
        switch (updateApplicantModalSelection) {
          case "send":
            // User selected "Send Only"
            // Do nothing here
            break;
          // case "update_send":
          //   // User selected "Update and Send"
          //   // Update applicant
          //   updateAfterSend = true;
          //   break;

          default:
            // User clicked on Send
            setShowOnboardingInvitationWarning(true);
            return;
        }
      }

      setIsLoading(true);
      if (enqueueSnackbar)
        enqueueSnackbar("Sending Your Message... Please Wait", { variant: "warning" });

      // if (updateAfterSend) changeApplicantStatus(applicantId, minStage);

      if (system === true) {
        const data = {
          sender: {
            userId,
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
          },
          recipient: {
            applicantId,
            firstName: recipient?.firstName,
            lastName: recipient?.lastName,
          },
          sendTime: new Date(),
          templateName: selectedTemplate,
          status: "unread",
          subject:
            selectedTemplate === "Custom Message" || forceCustomSubject
              ? customSubject
              : getSubjectFromTemplate(selectedTemplate),
          messageBody: editorValue,
        };

        messageSystem(data)
          .then(() => {
            onSendMessage();
            if (enqueueSnackbar) enqueueSnackbar("Message Sent to System", { variant: "success" });
          })
          .catch((e) => {
            setIsLoading(false);
            if (enqueueSnackbar)
              enqueueSnackbar(`Something went wrong on system error:${e}`, { variant: "error" });
            if (setOpen) {
              setOpen(true);
            }
          });
      }
      if (text === true) {
        const data = {
          sender: {
            userId,
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
          },
          recipient: {
            applicantId,
            firstName,
            lastName,
            toSms: phone,
          },
          templateName: selectedTemplate,
          subject:
            selectedTemplate === "Custom Message" || forceCustomSubject
              ? customSubject
              : getSubjectFromTemplate(selectedTemplate),
          messageBody: editorValue,
        };

        messageText(data)
          .then(() => {
            onSendMessage();
            if (enqueueSnackbar) enqueueSnackbar("Message Sent to SMS", { variant: "success" });
          })
          .catch((e) => {
            setIsLoading(false);
            if (enqueueSnackbar)
              enqueueSnackbar(`Something went wrong on SMS error:${e}`, { variant: "error" });
            if (setOpen) {
              setOpen(true);
            }
          });
      }
      // else {
      //   setNoFormatSelected(true);
      // }
      if (email === true) {
        const attchmentsCopy = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < (attachmentSelection ?? []).length; index++) {
          const att = (attachmentSelection ?? [])[index];
          if (att.filename === "[ Create Final Resume ]") {
            // eslint-disable-next-line no-await-in-loop
            const result = await generateFinalResume({ applicantId: att.applicantId });
            const url = result.data?.url;

            if (url) {
              const split = url.split("/");
              const croppedUrl = split[split.length - 1];
              attchmentsCopy.push({
                entity: att.entity,
                filename: croppedUrl,
                type: att.type,
                applicantId: att.applicantId,
              });
            }
          } else {
            attchmentsCopy.push(att);
          }
        }

        const data = {
          sender: {
            userId,
            fromEmail: currentLoggedUser.emailAddress,
            firstName: currentLoggedUser.firstName,
            lastName: currentLoggedUser.lastName,
          },
          recipient: {
            firstName,
            lastName,
            applicantId,
            toEmail: applicantEmail,
          },
          companyId,
          venueId: venueId ?? templateVenue?._id?.toString(),
          partnerId,
          jobId: jobId ?? templateJob?._id?.toString(),
          templateName: selectedTemplate,
          subject:
            selectedTemplate === "Custom Message" || forceCustomSubject
              ? customSubject
              : getSubjectFromTemplate(selectedTemplate),
          messageBody: editorValue,
          attachments: attchmentsCopy,
          copySender: sendCopyToMyEmail,
          ...(sendCarbonicCopy && { ccList: cCEmails }),
          ...(sendBlindCarbonicCopy && { bccList: bCCEmails }),
        };
        messageEmail(data)
          .then(() => {
            onSendMessage();
            if (enqueueSnackbar) enqueueSnackbar("Message Sent to Email", { variant: "success" });
          })
          .catch((e) => {
            setIsLoading(false);
            if (enqueueSnackbar)
              enqueueSnackbar(`Something went wrong on email error:${e}`, { variant: "error" });
            if (setOpen) {
              setOpen(true);
            }
          });
      }
    }
  };

  const sendBulkMessage = async (updateApplicantModalSelection) => {
    const promiseList = [];
    setError(true);
    if (!text && !email && !system && !pushNotificationSelection) {
      setNoFormatSelected(true);
    }
    if (
      !(
        (!text && !email && !system && !pushNotificationSelection) ||
        noTemplateSelected ||
        noSubject ||
        noMessage
      )
    ) {
      // Check if we're trying to send an Onboarding invitation to a non-screened applicant
      if (selectedTemplate === "Onboarding Invitation" && !areAllRecipientsReadyForOnboarding()) {
        switch (updateApplicantModalSelection) {
          case "send":
            // User selected "Send Only"
            // Do nothing here
            break;

          default:
            // User clicked on Send
            setShowOnboardingInvitationWarning(true);
            return;
        }
      }

      const attchmentsCopy = [];
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < (attachmentSelection ?? []).length; index++) {
        const att = (attachmentSelection ?? [])[index];
        if (att.filename === "[ Create Final Resume ]") {
          // eslint-disable-next-line no-await-in-loop
          const result = await generateFinalResume({ applicantId: att.applicantId });
          const url = result.data?.url;

          if (url) {
            const split = url.split("/");
            const croppedUrl = split[split.length - 1];
            attchmentsCopy.push({
              entity: att.entity,
              filename: croppedUrl,
              type: att.type,
              applicantId: att.applicantId,
            });
          }
        } else {
          attchmentsCopy.push(att);
        }
      }

      setNoFormatSelected(false);
      setIsLoading(true);
      if (enqueueSnackbar)
        enqueueSnackbar("Sending Bulk Messages... Please Wait", { variant: "warning" });
      const data = {
        sender: {
          userId,
          fromEmail: `${company?.companyEmail}`,
          firstName: currentLoggedUser.firstName,
          lastName: currentLoggedUser.lastName,
        },
        user: currentLoggedUser,
        venueId: venueId ?? templateVenue?._id?.toString(),
        eventId: eventId ?? templateEvent?._id?.toString(),
        jobId: jobId ?? templateJob?._id?.toString(),
        partnerId,
        companyId,
        applicantIdList: selectedRecipients,
        selectedTemplate,
        subject:
          selectedTemplate === "Custom Message" || forceCustomSubject
            ? customSubject
            : getSubjectFromTemplate(selectedTemplate),
        messageBody: removeStylingTags(editorValue),
        attachments: attchmentsCopy,
        copySender: sendCopyToMyEmail,
        ...(sendCarbonicCopy && { ccList: cCEmails }),
        ...(sendBlindCarbonicCopy && { bccList: bCCEmails }),
      };

      if (email === true && selectedRecipients?.length) {
        promiseList.push(
          messageEmailBulk(data)
            .then(() => {
              if (enqueueSnackbar)
                enqueueSnackbar(`${selectedRecipients?.length} messages have been submitted.`, {
                  variant: "success",
                });
            })
            .catch((e) => {
              setIsLoading(false);
              if (enqueueSnackbar) {
                enqueueSnackbar(`No messages have been submitted...`, {
                  variant: "warning",
                });
                enqueueSnackbar(`Something went wrong on email. Error:${e}`, { variant: "error" });
              }
              if (setOpen) {
                setOpen(true);
              }
            })
        );
      }

      if (system === true && selectedRecipients?.length) {
        promiseList.push(
          messageSystemBulk(data)
            .then(() => {
              if (enqueueSnackbar)
                enqueueSnackbar(`${selectedRecipients?.length} messages have been submitted.`, {
                  variant: "success",
                });
            })
            .catch((e) => {
              setIsLoading(false);
              if (enqueueSnackbar) {
                enqueueSnackbar(`No messages have been submitted...`, {
                  variant: "warning",
                });
                enqueueSnackbar(`Something went wrong on system message. Error:${e}`, {
                  variant: "error",
                });
              }
              if (setOpen) {
                setOpen(true);
              }
            })
        );
      }

      if (text === true && selectedRecipients?.length) {
        promiseList.push(
          messageTextBulk(data)
            .then(() => {
              if (enqueueSnackbar) enqueueSnackbar("Message Queued to SMS", { variant: "success" });
            })
            .catch((e) => {
              setIsLoading(false);
              if (enqueueSnackbar)
                enqueueSnackbar(`Something went wrong on SMS. Error:${e}`, { variant: "error" });
              if (setOpen) {
                setOpen(true);
              }
            })
        );
      }

      const dataTopic = {
        sender: {
          userId,
          fromEmail: `${company?.companyEmail}`,
          firstName: currentLoggedUser.firstName,
          lastName: currentLoggedUser.lastName,
        },
        user: currentLoggedUser,
        applicantIdList: selectedRecipients,
        selectedTemplate,
        subject:
          selectedTemplate === "Custom Message" || forceCustomSubject
            ? customSubject
            : getSubjectFromTemplate(selectedTemplate),
        messageBody: removeStylingTags(editorValue),
        ...(selectedTemplate === "Custom Message" &&
          pushNotificationSelection && {
            pushNotification: {
              context: messageContext,
              topic: pushNotificationSelection,
            },
          }),
      };

      if (selectedTemplate === "Custom Message" && pushNotificationSelection) {
        promiseList.push(
          messageTopicPushNotification(dataTopic)
            .then(() => {
              if (enqueueSnackbar)
                enqueueSnackbar("Push notifications sent to selected topic.", {
                  variant: "success",
                });
            })
            .catch((e) => {
              if (enqueueSnackbar)
                enqueueSnackbar(
                  `Something went wrong sending push notifications to the selected topic. Error: ${e}`,
                  { variant: "error" }
                );
              if (setOpen) {
                setOpen(true);
              }
            })
        );
      }

      Promise.all(promiseList).then(() => {
        onSendMessage();
      });
    }
  };
  const messagePreviewHandler = () => setMessageModal(!messageModal);

  const isSendInvalid = useMemo(() => {
    if (
      !selectedTemplate ||
      (!customSubject && (selectedTemplate === "Custom Message" || forceCustomSubject)) ||
      removeHtmlTags(editorValue) === "" ||
      hasPendingTemplateErrors
    )
      return true;
    return false;
  }, [selectedTemplate, customSubject, forceCustomSubject, editorValue, hasPendingTemplateErrors]);

  const deleteAttachment = (attachment) => {
    const attachmentsTemp = attachmentSelection?.filter(
      (item) => !(item.filename === attachment.filename && item.entity === attachment.entity)
    );
    setAttachmentSelection(attachmentsTemp);
  };

  const validateEmail = (emailToVerify) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(emailToVerify);
  };

  const handleCCInputChange = (event) => {
    if (!(validateEmail(event.target.value) && !cCEmails.includes(event.target.value))) {
      setCCError("Invalid email format");
    }
    setCCEmailInputValue(event.target.value);
    if (cCError && validateEmail(event.target.value)) {
      setCCError("");
    }
  };
  const handleBCCInputChange = (event) => {
    if (!(validateEmail(event.target.value) && !bCCEmails.includes(event.target.value))) {
      setBCCError("Invalid email format");
    }
    setBCCEmailInputValue(event.target.value);
    if (bCCError && validateEmail(event.target.value)) {
      setBCCError("");
    }
  };

  const addCCEmail = (ccEmail) => {
    if (!cCError) {
      setCCEmails((prevEmails) => [...prevEmails, ccEmail]);
      setCCEmailInputValue("");
    }
  };
  const addBCCEmail = (bccEmail) => {
    if (!bCCError) {
      setBCCEmails((prevEmails) => [...prevEmails, bccEmail]);
      setBCCEmailInputValue("");
    }
  };

  const handleCCInputKeyDown = (event) => {
    if ([";", ",", "Enter", "Tab"].includes(event.key)) {
      event.preventDefault();
      addCCEmail(cCEmailInputValue.trim());
    }
  };
  const handleBCCInputKeyDown = (event) => {
    if ([";", ",", "Enter", "Tab"].includes(event.key)) {
      event.preventDefault();
      addBCCEmail(bCCEmailInputValue.trim());
    }
  };
  const handleCCOnBLur = () => {
    if (!cCError && !cCEmails.includes(cCEmailInputValue) && cCEmailInputValue !== "") {
      setCCEmails((prevEmails) => [...prevEmails, cCEmailInputValue]);
      setCCEmailInputValue("");
    }
  };
  const handleBCConBlur = () => {
    if (!bCCError && !bCCEmails.includes(bCCEmailInputValue) && bCCEmailInputValue !== "") {
      setBCCEmails((prevEmails) => [...prevEmails, bCCEmailInputValue]);
      setBCCEmailInputValue("");
    }
  };

  const handleCCChipDelete = (index) => {
    setCCEmails((prevEmails) => prevEmails.filter((ccEmail, i) => i !== index));
  };
  const handleBCCChipDelete = (index) => {
    setBCCEmails((prevEmails) => prevEmails.filter((bccEmail, i) => i !== index));
  };

  const handleCCPaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("Text");
    const pastedEmails = clipboardData.split(/[;,]+/);
    pastedEmails.forEach((ccEmail) => addCCEmail(ccEmail.trim()));
  };
  const handleBCCPaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("Text");
    const pastedEmails = clipboardData.split(/[;,]+/);
    pastedEmails.forEach((ccEmail) => addBCCEmail(ccEmail.trim()));
  };

  const displayChip = (chipValue) => {
    return (
      <Chip
        key={`${chipValue.entity}${chipValue.filename}`}
        color="info"
        sx={{ textTransform: "uppercase", mx: 0.5, mb: 0.5 }}
        label={chipValue.filename}
        onDelete={() => deleteAttachment(chipValue)}
        size="small"
      />
    );
  };

  return (
    <MDBox sx={{ overflowY: "scroll" }}>
      <MDBox px={3}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={12} display="flex" alignItems="center">
            <Checkbox
              id="copyToEmail"
              checked={sendCopyToMyEmail}
              onChange={(e) => setSendCopyToMyEmail(!sendCopyToMyEmail)}
              sx={{
                disabled: {
                  cursor: "not-allowed",
                  color: "grey",
                  backgroundColor: "grey",
                },
              }}
            />
            <MDTypography variant="body2">
              Send a Copy to My Email: ({currentLoggedUser.emailAddress})
            </MDTypography>
          </Grid>
          <Grid item sm={12} display="flex" alignItems="center">
            <Checkbox
              id="copyCC"
              checked={sendCarbonicCopy}
              onChange={(e) => {
                setSendCarbonicCopy(!sendCarbonicCopy);
                if (sendCarbonicCopy) {
                  setCCEmails([]);
                  setCCEmailInputValue("");
                  setCCError("");
                }
              }}
              sx={{
                disabled: {
                  cursor: "not-allowed",
                  color: "grey",
                  backgroundColor: "grey",
                },
              }}
            />
            <MDTypography variant="body2">CC:</MDTypography>
            <Grid container spacing={1} alignItems="center" ml={2}>
              {cCEmails.map((ccEmail, index) => (
                <Grid item key={ccEmail}>
                  <Chip key={ccEmail} label={ccEmail} onDelete={() => handleCCChipDelete(index)} />
                </Grid>
              ))}
              <Grid item sm={6}>
                <FormField
                  disabled={!sendCarbonicCopy}
                  label="Emails"
                  type="text"
                  value={cCEmailInputValue}
                  onChange={handleCCInputChange}
                  onKeyDown={handleCCInputKeyDown}
                  onBlur={handleCCOnBLur}
                  onPaste={handleCCPaste}
                  error={!!cCError}
                  helperText={cCError}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} display="flex" alignItems="center">
            <Checkbox
              id="copyBCC"
              checked={sendBlindCarbonicCopy}
              onChange={(e) => {
                setSendBlindCarbonicCopy(!sendBlindCarbonicCopy);
                if (sendBlindCarbonicCopy) {
                  setBCCEmails([]);
                  setBCCEmailInputValue("");
                  setBCCError("");
                }
              }}
              sx={{
                disabled: {
                  cursor: "not-allowed",
                  color: "grey",
                  backgroundColor: "grey",
                },
              }}
            />
            <MDTypography variant="body2">BCC:</MDTypography>
            <Grid container spacing={1} alignItems="center" ml={1}>
              {bCCEmails.map((bccEmail, index) => (
                <Grid item key={bccEmail}>
                  <Chip
                    key={bccEmail}
                    label={bccEmail}
                    onDelete={() => handleBCCChipDelete(index)}
                  />
                </Grid>
              ))}
              <Grid item sm={6}>
                <FormField
                  disabled={!sendBlindCarbonicCopy}
                  label="Emails"
                  type="text"
                  value={bCCEmailInputValue}
                  onChange={handleBCCInputChange}
                  onKeyDown={handleBCCInputKeyDown}
                  onPaste={handleBCCPaste}
                  onBlur={() => handleBCConBlur()}
                  error={!!bCCError}
                  helperText={bCCError}
                />
              </Grid>
            </Grid>
          </Grid>
          {!hideMessageSelection && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={selectedTemplate}
                options={templateTypes}
                disabled={templateSelectionDisabled}
                onChange={(e, v) => handleTemplateChange(v)}
                renderInput={(params) => (
                  <FormField {...params} type="text" label="Select a Message" />
                )}
              />
            </Grid>
          )}
          {companyType === "Venue" && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                value={pushNotificationSelection}
                disabled={selectedTemplate !== "Custom Message"}
                options={pushNotificationsOptions || []}
                onChange={(e, v) => handlePushNotificationChange(v?.value)}
                renderInput={(params) => (
                  <FormField {...params} type="text" label="Mobile Push Notifications" />
                )}
              />
            </Grid>
          )}
          {(selectedTemplate === "Custom Message" || forceCustomSubject) && (
            <Grid item xs={12} lg={9} xl={9} xxl={8}>
              <FormField
                value={customSubject}
                onChange={(e, v) => {
                  handleSubjectChange(e.target.value);
                }}
                label="Enter a Subject"
              />
            </Grid>
          )}
          {!hideMessageType && (
            <>
              <Grid item xs={12} lg={9} xl={9} xxl={8}>
                <Grid container spacing={1} alignItems="center">
                  {!isPanel && (
                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        flexDirection={{ xs: "column-reverse", md: "row" }}
                      >
                        <Grid item xs={12} sm={4} lg={5} xl={5} xxl={5}>
                          <Switch
                            // disabled={recipient?.status !== "Employee"}
                            checked={system}
                            onChange={(e, v) => setSystem(v)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={7} xl={7} xxl={7}>
                          <MDTypography
                            variant="body2"
                            fontWeight="medium"
                            textTransform="capitalize"
                            color={recipient?.status !== "Employee" ? "secondary" : "info"}
                          >
                            System
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {!noText && (
                    <Grid item xs={4} sm={4} lg={4} xl={4}>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        flexDirection={{ xs: "column-reverse", md: "row" }}
                      >
                        <Grid item xs={12} sm={4} lg={5} xl={5} xxl={5}>
                          <Switch
                            disabled={
                              applicantId || isPanel
                                ? recipient?.phone === "" || !recipient?.phone
                                : false
                            }
                            // checked={recipient?.phone === "" ? false : text}
                            checked={text}
                            onChange={(e, v) => setText(v)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} lg={7} xl={7} xxl={7}>
                          <MDTypography
                            variant="body2"
                            fontWeight="medium"
                            textTransform="capitalize"
                            color={recipient?.phone === "" ? "secondary" : "info"}
                          >
                            Text
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={4} sm={4} lg={4} xl={4}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      flexDirection={{ xs: "column-reverse", md: "row" }}
                    >
                      <Grid item xs={12} sm={4} lg={5} xl={5} xxl={5}>
                        <Switch
                          disabled={
                            applicantId || isPanel
                              ? recipient?.email === "" || !recipient?.email
                              : false
                          }
                          checked={email}
                          onChange={(e, v) => setEmail(v)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8} lg={7} xl={7} xxl={7}>
                        <MDTypography
                          variant="body2"
                          fontWeight="medium"
                          textTransform="capitalize"
                          color={recipient?.email === "" ? "secondary" : "info"}
                        >
                          Email
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {error && (
                    <MDBox px={2}>
                      {noTemplateSelected && (
                        <MDTypography style={{ fontSize: "1rem" }} color="error">
                          You must select a template
                        </MDTypography>
                      )}
                      {noFormatSelected && (
                        <MDTypography style={{ fontSize: "1rem" }} color="error">
                          You must select a medium
                        </MDTypography>
                      )}
                      {noSubject && (
                        <MDTypography style={{ fontSize: "1rem" }} color="error">
                          You must input a subject
                        </MDTypography>
                      )}
                      {noMessage && (
                        <MDTypography style={{ fontSize: "1rem" }} color="error">
                          You must input a message
                        </MDTypography>
                      )}
                    </MDBox>
                  )}
                  {hasTemplateError && !!missingContext?.length && (
                    <MDBox
                      sx={{
                        width: "100%",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                      }}
                    >
                      {companyType === "Company" && missingContext.includes("job") ? (
                        <>
                          {templateJob == null ? (
                            <Searchbar
                              fetch={fetchCompanyJob}
                              fetchAll={false}
                              placeholder="Search Customer Jobs"
                              columns={[
                                { title: "Status", field: "status", width: 50 },
                                {
                                  width: 150,
                                  title: "Customer",
                                  field: "companySlug",
                                  customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
                                },
                                { title: "Title", field: "title", width: 150 },
                              ]}
                              queryCharacterLimit={2}
                              resultsLimit={10}
                              onRowClick={(job) => {
                                setTemplateJob(job);
                              }}
                              setFilters={() => {}}
                              setPage={() => {}}
                              searchBy={["title", "name", "companySlug", "jobSlug"]}
                              filterBy="_id"
                            />
                          ) : (
                            <Chip
                              key="job"
                              color="info"
                              sx={{ textTransform: "uppercase", mx: 0.5, mb: 0.5 }}
                              label={`Job: ${templateJob.companyName} - ${templateJob.title}`}
                              onDelete={() => setTemplateJob(null)}
                              size="small"
                            />
                          )}
                        </>
                      ) : null}
                    </MDBox>
                  )}
                  {hasPendingTemplateErrors && (
                    <>
                      <MDBox px={2}>
                        {pendingMissingContext?.length ? (
                          <>
                            <MDTypography style={{ fontSize: "1rem" }} color="error">
                              {`Missing data for this template: ${pendingMissingContext.join(
                                ", "
                              )}`}
                            </MDTypography>
                          </>
                        ) : (
                          <MDTypography style={{ fontSize: "1rem" }} color="error">
                            An error occurred loading the template
                          </MDTypography>
                        )}
                      </MDBox>
                    </>
                  )}
                </Grid>
              </Grid>
              {system || pushNotificationSelection ? (
                <MDBox px={2}>
                  <MDTypography style={{ fontSize: "1rem" }} color="secondary">
                    {`This will send a mobile push notification to the selected ${
                      system && applicant ? "user" : ""
                    }${system && !applicant ? "users" : ""}${
                      system && pushNotificationSelection ? " and " : ""
                    }${pushNotificationSelection ? "group" : ""}`}
                  </MDTypography>
                </MDBox>
              ) : null}
            </>
          )}
        </Grid>
      </MDBox>
      {isLoading && (
        <MDBox textAlign="center">
          <CircularProgress />
        </MDBox>
      )}
      <MDBox
        p={3}
        sx={{
          "& .ql-editor": {
            minHeight: "100px !important",
            maxHeight: "300px",
            overflow: "hidden",
            overflowY: "auto",
            overflowX: "auto",
          },
        }}
      >
        <MDBox display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <MDTypography variant="body2" color="dark">
            MESSAGE
          </MDTypography>
          <Button onClick={messagePreviewHandler}>Preview</Button>
        </MDBox>

        <MDEditor
          className={classes.editor}
          value={editorValue}
          onChange={(e) => setEditorValue(e)}
          style={{ height: "100%" }}
        />

        {email && (
          <Grid container spacing={2} mt={2}>
            <Grid
              item
              xs={12}
              sm={3}
              sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
              <Checkbox
                id="AttachmentsForMessage"
                checked={includeAttachments}
                onChange={(e) => setIncludeAttachments(!includeAttachments)}
                sx={{
                  disabled: {
                    cursor: "not-allowed",
                    color: "grey",
                    backgroundColor: "grey",
                  },
                }}
              />
              <MDTypography fontWeight="bold" variant="body2">
                {attachmentContext === "Applicant Job Submittal"
                  ? "Include Resume"
                  : "Include Attachments"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={!includeAttachments}
                disableClearable
                forcePopupIcon={false}
                onChange={(e, v) => {
                  handleAttachmentChange(v);
                }}
                groupBy={(option) => option.group}
                options={
                  attachmentOptions.sort((a, b) => {
                    if (a.group === "New File" && b.group !== "New File") return -1;
                    if (a.group !== "New File" && b.group === "New File") return 1;

                    if (a.group < b.group) return -1;
                    if (a.group > b.group) return 1;
                    if (a.filename?.startsWith("[ ") && !b.filename?.startsWith("[ ")) return -1;
                    if (!a.filename?.startsWith("[ ") && b.filename?.startsWith("[ ")) return 1;

                    if (a.filename < b.filename) return -1;
                    if (a.filename > b.filename) return 1;

                    return 0;
                  }) || []
                }
                getOptionLabel={(option) => option?.filename}
                fullWidth
                renderInput={(params) => (
                  <FormField
                    {...params}
                    type="text"
                    label=""
                    placeholder="Add Attachments"
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      value: attachmentInputValue,
                    }}
                    // eslint-disable-next-line
                    InputProps={{
                      ...params.InputProps,
                      value: attachmentInputValue,
                      // endAdornment: (
                      //   <IconButton position="end">
                      //     <SearchIcon />
                      //   </IconButton>
                      // ),
                    }}
                    onChange={(e) => setAttachmentInputValue(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              {attachmentSelection?.length
                ? attachmentSelection.map((attachment) => {
                    return displayChip(attachment);
                  })
                : null}
            </Grid>
          </Grid>
        )}

        {recipientsTable && (
          <MDBox mt={2} opacity={system || text || email ? 1 : 0.5}>
            {recipientsTable}
          </MDBox>
        )}
      </MDBox>

      <FlexWrapper
        gap="15px"
        sx={{ px: 3 }}
        flexWrap="nowrap"
        justifyContent={{ xs: "center", md: "flex-end" }}
      >
        {!isPanel && (
          <MDButton
            disabled={isLoading}
            color="error"
            variant="contained"
            fullWidth
            onClick={() => {
              setEditorValue("<p></p>");
              setSubjectValue("");
              setPreviewSubjectText(null);
              setSelectedTemplate("Custom Message");
              setPreviousTemplate("");
              setCustomSubject("");
              setOpen(false);
            }}
            sx={{ maxWidth: "100px" }}
          >
            <MDTypography variant="body2" color="white">
              Cancel
            </MDTypography>
          </MDButton>
        )}
        <MDButton
          disabled={isLoading || isButtonDisabled || isSendInvalid}
          color="info"
          variant="contained"
          fullWidth
          onClick={!bulk ? () => sendSingleMessage() : () => sendBulkMessage()}
          sx={{ maxWidth: "120px" }}
        >
          <MDTypography variant="body2" color="white" sx={{ whiteSpace: "nowrap" }}>
            Send Message
          </MDTypography>
        </MDButton>
        {showStatusChangeButton && (
          <MDButton
            color="info"
            variant="contained"
            fullWidth
            onClick={() => onComplete()}
            sx={{ maxWidth: "160px" }}
          >
            <MDTypography variant="body2" color="white" sx={{ whiteSpace: "nowrap" }}>
              Change Status Only
            </MDTypography>
          </MDButton>
        )}
      </FlexWrapper>
      {messageModal && (
        <EditorPreviewModal
          toggleOpen={messagePreviewHandler}
          isOpen={messageModal}
          message={
            selectedTemplate === "Custom Message" ? editorValue : previewMessageText ?? editorValue
          }
          subject={
            selectedTemplate === "Custom Message"
              ? customSubject
              : previewSubjectText ?? customSubject
          }
        />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          isOpen={isConfirmationOpen}
          onClose={() => toggleConfirmationModal(false)}
          title="Recipients Confirmation"
          body={confirmationModalBody}
          onConfirm={(e) => {
            e.stopPropagation();
            sendBulkMessage();
            toggleConfirmationModal(false);
          }}
        />
      )}
      {showOnboardingInvitationWarning ? (
        <ConfirmModal
          title={`Applicant not ${minStage}`}
          description={
            bulk
              ? `One or more of the selected recipients don't have an applicant status of "${minStage}". They won't be able to start the onboarding. Do you want to send the message anyway?`
              : `This applicant has a status of ${recipient.applicantStatus}. They won't be able to start the onboarding unless their status is "${minStage}". Do you want to send the message anyway?`
          }
          positiveBtn={
            // !bulk
            //   ? {
            //       label: "Update and Send",
            //       fn: async (e) => {
            //         e?.stopPropagation();
            //         sendSingleMessage("update_send");
            //         closeModalHandler();
            //       },
            //       isShown: true,
            //     }
            //   : {
            //       label: "Send",
            //       fn: async (e) => {
            //         e?.stopPropagation();
            //         await sendBulkMessage("send");
            //         closeModalHandler();
            //       },
            //       isShown: true,
            //     }
            {
              label: "Send",
              fn: async (e) => {
                e?.stopPropagation();
                if (bulk) sendBulkMessage("send");
                else sendSingleMessage("send");
                closeModalHandler();
              },
              isShown: true,
            }
          }
          negativeBtn={{
            label: "Cancel",
            fn: closeModalHandler,
            isShown: true,
          }}
          // negativeAltBtn={{
          //   label: "Send Only",
          //   fn: async () => {
          //     sendSingleMessage("send");
          //     closeModalHandler();
          //   },
          //   isShown: !bulk,
          // }}
          isOpen={showOnboardingInvitationWarning}
          closeFn={closeModalHandler}
        />
      ) : null}
      <AddMessageAttachmentModal
        open={addMessageAttachmentModalOpen}
        setOpen={setAddMessageAttachmentModalOpen}
        messageContext={messageContext}
        attachmentSelection={attachmentSelection}
        setAttachmentSelection={setAttachmentSelection}
        applicantId={applicantId}
        attachmentContext={attachmentContext}
      />
    </MDBox>
  );
};

SendMessage.defaultProps = {
  messageTypes: [],
  system: false,
  text: false,
  email: false,
};

SendMessage.propTypes = {
  messageTypes: PropTypes.array,
  system: PropTypes.bool,
  text: PropTypes.bool,
  email: PropTypes.bool,
};

export default SendMessage;
