import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, styled } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import CustomCard from "components/CustomCard";

import { useAppContextController } from "context/AppContext";
import useCompanyStore from "stores/useCompanyStore";

import CompanyForm from "./CompanyForm";

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "4rem",
  height: "4rem",
  background: theme.palette.warning.main,
  color: "white",
  borderRadius: "0.8rem",
}));

const CompanyInfoPanel = ({
  setRefreshCompanies = () => null,
  handleSubmit = () => null,
  panelOptions,
  selectedPanel,
}) => {
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const { company: primaryCompany } = useAppContextController();

  const isEdit = !!currentCompany?._id;

  return (
    <CustomCard
      icon={
        isEdit && currentCompany?.squareLogoUrl ? (
          <CompanyLogoSquare company={currentCompany} defaultCompany={primaryCompany} />
        ) : (
          <StyledImageContainer>
            <AccountCircleIcon fontSize="large" />
          </StyledImageContainer>
        )
      }
      iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "-1.5rem" }}
      cardSx={{ width: "100%", overflow: "visible !important", p: 0, height: 850 }}
    >
      <CompanyForm
        onSubmit={handleSubmit}
        setRefreshCompanies={setRefreshCompanies}
        panelOptions={panelOptions}
        selectedPanel={selectedPanel}
      />
    </CustomCard>
  );
};

export default CompanyInfoPanel;
