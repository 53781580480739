import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const JobDescriptionModal = ({ open, onClose, job }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 3,
          maxHeight: "90vh",
        },
      }}
    >
      <DialogTitle
        sx={{
          pb: 1,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <WorkOutlineIcon color="primary" />
        <Typography variant="h5" component="span" fontWeight={600}>
          Job Details
        </Typography>
      </DialogTitle>

      <Divider />

      <DialogContent sx={{ pt: 3 }}>
        <Stack spacing={3}>
          {/* Header Section */}
          <Box>
            <Typography variant="h4" gutterBottom fontWeight={700}>
              {job?.title}
            </Typography>

            {(job?.companyCity || job?.companyState) && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Chip
                  icon={<LocationOnIcon />}
                  label={[job?.companyCity, job?.companyState].join(", ")}
                  variant="outlined"
                />
              </Stack>
            )}
          </Box>

          {/* Tags Section */}
          <Box>
            <Typography variant="h6" gutterBottom>
              Required Skills
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
              {job?.tags?.map((tag, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  label={tag}
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              ))}
            </Stack>
          </Box>

          {/* Markdown Content */}
          <Box
            sx={{
              "& h1": {
                fontSize: "1.5rem",
                fontWeight: 700,
                mb: 2,
              },
              "& h2": {
                fontSize: "1.25rem",
                fontWeight: 600,
                mb: 2,
                mt: 3,
              },
              "& p": {
                mb: 2,
              },
              "& ul": {
                pl: 2,
              },
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: job?.description || "" }} />
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 3, pt: 0 }}>
        <Button onClick={onClose} variant="contained" sx={{ borderRadius: 2 }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JobDescriptionModal;
