import { useState } from "react";
import VerifyCard from "layouts/pages/web/ChangeEmailVerification/VerifyCard";
import VerifySuccess from "layouts/pages/web/ChangeEmailVerification/VerifySuccess";
import MDBox from "components/MDBox";
import { Fade, Modal } from "@mui/material";

const ChangeEmailModal = ({
  user,
  changeEmailModalOpen,
  setChangeEmailModalOpen,
  changeEmailSuccessModalOpen,
  setChangeEmailSuccessModalOpen,
  oldEmail,
  setOpenOTPModal,
  newEmailEdit,
  setNewEmailEdit,
}) => {
  const handleClose = () => {
    setChangeEmailModalOpen(false);
    setChangeEmailSuccessModalOpen(false);
  };
  return (
    <Modal
      open={changeEmailModalOpen || changeEmailSuccessModalOpen}
      onClose={() => handleClose()}
      disableEnforceFocus
      style={{ overflow: "scroll" }}
    >
      <Fade in={changeEmailModalOpen || changeEmailSuccessModalOpen}>
        <MDBox>
          {changeEmailModalOpen && (
            <VerifyCard
              user={user}
              parent="profile"
              oldEmail={oldEmail}
              setChangeEmailModalOpen={setChangeEmailModalOpen}
              setOpenOTPModal={setOpenOTPModal}
              newEmailEdit={newEmailEdit}
              setNewEmailEdit={setNewEmailEdit}
            />
          )}
          {changeEmailSuccessModalOpen && (
            <VerifySuccess
              newEmail={newEmailEdit}
              parent="profile"
              setChangeEmailSuccessModalOpen={setChangeEmailSuccessModalOpen}
            />
          )}
        </MDBox>
      </Fade>
    </Modal>
  );
};

export default ChangeEmailModal;
