import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { AttachFile, Cancel as CancelIcon } from "@mui/icons-material";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";

const FormUploadModal = ({ open, onClose, onUpload, loading, formName, setFormName }) => {
  const [uploadedFiles, setUploadedFiles] = useState(null);

  const handleFileUpload = (acceptedFiles, rejectedFiles) => {
    setUploadedFiles(acceptedFiles);
    onUpload(acceptedFiles, rejectedFiles);
  };

  const handleProcessForm = () => {
    if (!uploadedFiles || !formName.trim()) return;
    onClose(true); // Pass true to indicate form should be processed
  };

  return (
    <Dialog 
      open={open} 
      onClose={() => onClose(false)}
      maxWidth="md"
    >
      <DialogContent sx={{ padding: "24px", maxWidth: "580px", width: "500px" }}>
        <FlexWrapper justifyContent="space-between" sx={{ flexWrap: "nowrap" }}>
          <MDTypography sx={{ flex: 1 }}>
            <AttachFile color="primary" /> Upload Form Document
          </MDTypography>
          <MDBox sx={{ flex: 0 }}>
            <Button
              variant="text"
              disabled={loading}
              startIcon={<CancelIcon />}
              onClick={() => onClose(false)}
            >
              Cancel
            </Button>
          </MDBox>
        </FlexWrapper>
        
        <MDBox mt={3} mb={2}>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Form Name"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            disabled={loading}
            placeholder="Enter a name for this form"
            helperText="Please provide a name to help identify this form later"
          />
        </MDBox>
        
        <MDBox mt={2}>
          <MDTypography variant="h5" color="info">
            Upload a form document to be processed
          </MDTypography>
          <FileDropzone
            disabled={loading}
            multiple={false}
            message="Drop your form document here"
            message2="Supported formats: PDF, JPG, PNG"
            onDrop={handleFileUpload}
            accept={{
              "image/*": [".jpeg", ".jpg", ".png"],
              "application/pdf": [".pdf"],
            }}
            sx={{ minHeight: "200px" }}
          />
        </MDBox>
      </DialogContent>
      
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button 
          onClick={handleProcessForm} 
          variant="contained" 
          color="primary"
          disabled={loading || !uploadedFiles || !formName.trim()}
        >
          Process Form
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  setFormName: PropTypes.func.isRequired,
};

FormUploadModal.defaultProps = {
  loading: false,
};

export default FormUploadModal; 