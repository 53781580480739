import { baseAxios } from "config";

const uploadAssessmentExcelFile = async (assessmentId, templateFile, answerFile) => {
  try {
    const formData = new FormData();
    // Only append files that exist
    if (templateFile) formData.append("template", templateFile);
    if (answerFile) formData.append("answer", answerFile);

    // Don't make API call if no files to upload
    if (!templateFile && !answerFile) {
      return { success: true };
    }

    const response = await baseAxios.post(
      `/assessments/${assessmentId}/upload-excel-files`,
      formData
    );

    return response.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default uploadAssessmentExcelFile;
