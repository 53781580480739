import ConfirmationModal from "components/ConfirmationModal";
import updateApplicantAIScreenedFlag from "layouts/pages/applicants/actions/updateApplicantAIScreenedFlag";
import updateApplicantCallMeFlag from "layouts/pages/applicants/actions/updateApplicantCallMeFlag";
import { cloneDeep } from "lodash";
import { useSnackbar } from "notistack";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

const useRemoveApplicantFlag = (props) => {
  const { currentJobSlug, onRefetch } = props ?? {};
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [removeFlagModalSelectedApplicant, setRemoveFlagModalSelectedApplicant] = useState(null);
  const [removeFlagModalCurrentFlag, setRemoveFlagModalCurrentFlag] = useState(null);

  // const changeLocalFlag = (applicantId, flag) => {
  //   const allEvents = cloneDeep(queryClient.getQueryData([queryKey, queryParams]));
  //   const pages = allEvents?.pages ?? [];

  //   for (let indexPage = 0; indexPage < pages.length; indexPage += 1) {
  //     const applPage = pages[indexPage];
  //     const pageData = applPage.data ?? [];
  //     for (let indexData = 0; indexData < pageData.length; indexData += 1) {
  //       const appl = pageData[indexData];
  //       if (applicantId && applicantId === appl._id) {
  //         // Found applicant to update

  //         const applJobs = appl.jobs.filter((apJb) =>
  //           currentJobSlug ? apJb.jobSlug === currentJobSlug : true
  //         );
  //         applJobs.forEach((apJb) => {
  //           allEvents.pages[indexPage].data[indexData][flag] = null;
  //         })
  //       }
  //     }
  //   }

  //   queryClient.setQueryData([queryKey, queryParams], allEvents);
  // };

  const handleOpenRemoveFlagModal = (row, flag) => {
    setRemoveFlagModalSelectedApplicant(row);
    setRemoveFlagModalCurrentFlag(flag);
  };

  const handleCloseRemoveFlagModal = () => {
    setRemoveFlagModalSelectedApplicant(null);
    setRemoveFlagModalCurrentFlag(null);
  };

  const updateApplicantAIScreenedFlagMutation = useMutation(updateApplicantAIScreenedFlag, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { applicantId, jobSlug }) => {
      // changeLocalFlag(applicantId, "aiScreened");
    },
  });

  const updateApplicantCallMeFlagMutation = useMutation(updateApplicantCallMeFlag, {
    onError: (err) => {
      console.log("error", err);
      enqueueSnackbar("Something went wrong!", { variant: "error" });
    },
    onSuccess: async (_, { applicantId, jobSlug }) => {
      // changeLocalFlag(applicantId, "waitingForCall");
    },
  });

  const handleRemoveAIScreenedFlagFromApplicant = useCallback(
    async (applicant) => {
      const validJobs = applicant.jobs?.filter((jb) => jb.aiInterview?.interviewEndDate != null);
      const promises = [];
      for (let index = 0; index < validJobs.length; index += 1) {
        const apJob = validJobs[index];
        promises.push(
          updateApplicantAIScreenedFlagMutation.mutateAsync({
            applicantId: applicant._id,
            jobSlug: apJob.jobSlug,
          })
        );
      }
      await Promise.all(promises).then(() => {
        onRefetch?.();
        enqueueSnackbar("Applicant has been updated!", { variant: "success" });
      });
    },
    [enqueueSnackbar, onRefetch, updateApplicantAIScreenedFlagMutation]
  );

  const handleRemoveCallMeFlagFromApplicant = useCallback(
    async (applicant) => {
      const validJobs = applicant.jobs?.filter((jb) => jb.waitingForCall != null);
      const promises = [];
      for (let index = 0; index < validJobs.length; index += 1) {
        const apJob = validJobs[index];
        promises.push(
          updateApplicantCallMeFlagMutation.mutateAsync({
            applicantId: applicant._id,
            jobSlug: apJob.jobSlug,
          })
        );
      }
      if (!promises?.length) return;
      await Promise.all(promises).then(() => {
        onRefetch?.();
        enqueueSnackbar("Applicant has been updated!", { variant: "success" });
      });
    },
    [enqueueSnackbar, onRefetch, updateApplicantCallMeFlagMutation]
  );

  const removeFlagConfirmationModal = useMemo(
    () => (
      <>
        {!!removeFlagModalSelectedApplicant && (
          <ConfirmationModal
            isOpen={!!removeFlagModalSelectedApplicant}
            onClose={handleCloseRemoveFlagModal}
            title={`Are you sure you want to remove this flag from ${
              removeFlagModalSelectedApplicant?.firstName
            } ${removeFlagModalSelectedApplicant?.lastName}${
              currentJobSlug ? "" : " for all jobs"
            }?`}
            onConfirm={(e) => {
              e.stopPropagation();
              if (removeFlagModalCurrentFlag === "aiScreened")
                handleRemoveAIScreenedFlagFromApplicant(removeFlagModalSelectedApplicant);
              else handleRemoveCallMeFlagFromApplicant(removeFlagModalSelectedApplicant);
              handleCloseRemoveFlagModal();
            }}
          />
        )}
      </>
    ),
    [
      currentJobSlug,
      handleRemoveAIScreenedFlagFromApplicant,
      handleRemoveCallMeFlagFromApplicant,
      removeFlagModalCurrentFlag,
      removeFlagModalSelectedApplicant,
    ]
  );

  return {
    handleOpenRemoveFlagModal,
    removeFlagConfirmationModal,
  };
};

export default useRemoveApplicantFlag;
