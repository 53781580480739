import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Card, CircularProgress, Grid, Tab, Tabs, Typography } from "@mui/material";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";

import SortableFormField from "./SortableFormField";
import FormFieldModal from "./FormFieldModal";
import ConfirmationModal from "./ConfirmationModal";

const FormEditor = ({ formData, onFormDataChange, loading }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [isFieldModalOpen, setIsFieldModalOpen] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [fieldToDelete, setFieldToDelete] = useState(null);
  const [localFormData, setLocalFormData] = useState(formData);

  // Constants
  const deleteFieldMessage =
    "Are you sure you want to delete this field? This action cannot be undone.";

  // Update local form data when prop changes
  useEffect(() => {
    setLocalFormData(formData);
  }, [formData]);

  const handleTabChange = (event, newValue) => {
    setActiveSection(newValue);
  };

  const handleEditField = (field) => {
    setFieldToEdit(field);
    setIsFieldModalOpen(true);
  };

  const handleDeleteField = (fieldId) => {
    // Find the field to delete
    let sectionIndex = -1;
    let rowIndex = -1;
    let columnIndex = -1;

    localFormData.sections.forEach((section, secIdx) => {
      section.rows.forEach((row, rowIdx) => {
        row.columns.forEach((column, colIdx) => {
          if (column.id === fieldId) {
            sectionIndex = secIdx;
            rowIndex = rowIdx;
            columnIndex = colIdx;
          }
        });
      });
    });

    if (sectionIndex !== -1) {
      setFieldToDelete({ sectionIndex, rowIndex, columnIndex });
      setIsConfirmationOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    if (!fieldToDelete) return;

    const { sectionIndex, rowIndex, columnIndex } = fieldToDelete;
    const newFormData = { ...localFormData };

    // Remove the field
    newFormData.sections[sectionIndex].rows[rowIndex].columns.splice(columnIndex, 1);

    // If the row is now empty, remove it
    if (newFormData.sections[sectionIndex].rows[rowIndex].columns.length === 0) {
      newFormData.sections[sectionIndex].rows.splice(rowIndex, 1);

      // If the section is now empty, add an empty row
      if (newFormData.sections[sectionIndex].rows.length === 0) {
        newFormData.sections[sectionIndex].rows.push({
          columns: [],
        });
      }
    }

    setLocalFormData(newFormData);
    setIsConfirmationOpen(false);
    setFieldToDelete(null);
  };

  const handleToggleVisibility = (field) => {
    const newFormData = { ...localFormData };

    // Find and update the field
    localFormData.sections.forEach((section, secIdx) => {
      section.rows.forEach((row, rowIdx) => {
        row.columns.forEach((column, colIdx) => {
          if (column.id === field.id) {
            newFormData.sections[secIdx].rows[rowIdx].columns[colIdx].hidden =
              !newFormData.sections[secIdx].rows[rowIdx].columns[colIdx].hidden;
          }
        });
      });
    });

    setLocalFormData(newFormData);
  };

  const handleSaveField = (fieldData) => {
    const newFormData = { ...localFormData };

    // Update existing field
    localFormData.sections.forEach((section, secIdx) => {
      section.rows.forEach((row, rowIdx) => {
        row.columns.forEach((column, colIdx) => {
          if (column.id === fieldToEdit.id) {
            newFormData.sections[secIdx].rows[rowIdx].columns[colIdx] = {
              ...fieldData,
              id: fieldToEdit.id,
            };
          }
        });
      });
    });

    setLocalFormData(newFormData);
    setIsFieldModalOpen(false);
    setFieldToEdit(null);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    const newFormData = { ...localFormData };

    // Find the source and destination fields
    let sourceSection;
    let sourceRow;
    let sourceCol;
    let destSection;
    let destRow;
    let destCol;

    localFormData.sections.forEach((section, secIdx) => {
      section.rows.forEach((row, rowIdx) => {
        row.columns.forEach((column, colIdx) => {
          if (column.id === active.id) {
            sourceSection = secIdx;
            sourceRow = rowIdx;
            sourceCol = colIdx;
          }
          if (column.id === over.id) {
            destSection = secIdx;
            destRow = rowIdx;
            destCol = colIdx;
          }
        });
      });
    });

    // If source and destination are in the same row, just reorder within the row
    if (sourceSection === destSection && sourceRow === destRow) {
      const columns = [...newFormData.sections[sourceSection].rows[sourceRow].columns];
      const reorderedColumns = arrayMove(columns, sourceCol, destCol);
      newFormData.sections[sourceSection].rows[sourceRow].columns = reorderedColumns;
    } else {
      // Move between different rows or sections
      const sourceField = {
        ...newFormData.sections[sourceSection].rows[sourceRow].columns[sourceCol],
      };

      // Remove from source
      newFormData.sections[sourceSection].rows[sourceRow].columns.splice(sourceCol, 1);

      // Add to destination
      newFormData.sections[destSection].rows[destRow].columns.splice(destCol, 0, sourceField);

      // Clean up empty rows
      if (newFormData.sections[sourceSection].rows[sourceRow].columns.length === 0) {
        newFormData.sections[sourceSection].rows.splice(sourceRow, 1);

        // If section is empty, add an empty row
        if (newFormData.sections[sourceSection].rows.length === 0) {
          newFormData.sections[sourceSection].rows.push({
            columns: [],
          });
        }
      }
    }

    setLocalFormData(newFormData);
  };

  const handleSaveChanges = () => {
    onFormDataChange(localFormData);
  };

  return (
    <Card sx={{ p: 3, mb: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">Form Editor</Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} color="inherit" />}
          >
            {loading ? "Saving..." : "Save Changes"}
          </Button>
        </Box>
      </Box>

      {/* Section Tabs */}
      <Tabs
        value={activeSection}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ mb: 3 }}
      >
        {localFormData.sections.map((section) => (
          <Tab
            key={
              section.title
                ? `section-tab-${section.title}`
                : `section-tab-${section.id || uuidv4()}`
            }
            label={section.title || `Section ${localFormData.sections.indexOf(section) + 1}`}
          />
        ))}
      </Tabs>

      {/* Active Section */}
      {localFormData.sections[activeSection] && (
        <Box>
          <Typography variant="h6" gutterBottom>
            {localFormData.sections[activeSection].title || `Section ${activeSection + 1}`}
          </Typography>

          {localFormData.sections[activeSection].description && (
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {localFormData.sections[activeSection].description}
            </Typography>
          )}

          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext
              items={localFormData.sections[activeSection].rows.flatMap((row) =>
                row.columns.map((column) => column.id)
              )}
              strategy={rectSortingStrategy}
            >
              <Grid container spacing={2} sx={{ mt: 1 }}>
                {localFormData.sections[activeSection].rows.map((row, rowIndex) => {
                  // Create a stable key that doesn't rely on index
                  const rowKey = `row-${activeSection}-${row.columns
                    .map((col) => col.id)
                    .join("-")}`;

                  return row.columns.map((field) => (
                    <SortableFormField
                      key={field.id}
                      field={field}
                      handleDeleteField={handleDeleteField}
                      handleEditField={handleEditField}
                      handleToggleVisibility={handleToggleVisibility}
                    />
                  ));
                })}
              </Grid>
            </SortableContext>
          </DndContext>

          {localFormData.sections[activeSection].rows.length === 0 && (
            <Box
              sx={{
                p: 4,
                textAlign: "center",
                border: "1px dashed #ccc",
                borderRadius: 1,
                bgcolor: "#f9f9f9",
              }}
            >
              <Typography variant="body1" color="text.secondary">
                No fields in this section.
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {/* Modals */}
      <FormFieldModal
        open={isFieldModalOpen}
        onClose={() => {
          setIsFieldModalOpen(false);
          setFieldToEdit(null);
        }}
        field={fieldToEdit}
        onSave={handleSaveField}
      />

      <ConfirmationModal
        open={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmDelete}
        title="Delete Field"
        message={deleteFieldMessage}
      />
    </Card>
  );
};

FormEditor.propTypes = {
  formData: PropTypes.object.isRequired,
  onFormDataChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

FormEditor.defaultProps = {
  loading: false,
};

export default FormEditor;
