import { UploadFile } from "@mui/icons-material";
import { Grid, Icon, IconButton, Stack } from "@mui/material";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { getCommonBaseImageUrl, getCompanyImageUrl } from "utils/helpers/upload";
import uploadAssessmentExcelFile from "../../actions/uploadAssessmentExcelFile";
import useStyles from "./styles";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const AssessmentsQuestionsExcel = ({ updateAssessmentMutation }) => {
  const { company, currentAssessment, setCurrentAssessment, isMobile } = useAppContextController();
  const styles = useStyles();

  const IMAGE_SERVER = getCompanyImageUrl(company);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [attachmentsStartingData, setAttachmentsStartingData] = useState([]);
  const [attachmentsExpectedData, setAttachmentsExpectedData] = useState([]);
  const [confirmModal, setConfirmModal] = useState(initialValueModal);
  const closeModalHandler = () => setConfirmModal(initialValueModal);

  useEffect(() => {
    if (currentAssessment?.excelAssessment?.startingWorkbook) {
      setAttachmentsStartingData([currentAssessment?.excelAssessment?.startingWorkbook]);
    } else {
      setAttachmentsStartingData([]);
    }
  }, [currentAssessment]);

  useEffect(() => {
    if (currentAssessment?.excelAssessment?.expectedAnswer) {
      setAttachmentsExpectedData([currentAssessment?.excelAssessment?.expectedAnswer]);
    } else {
      setAttachmentsExpectedData([]);
    }
  }, [currentAssessment]);

  const uploadSignatureImgMutation = useMutation(
    async ({ assessmentId, templateFile, answerFile }) => {
      await uploadAssessmentExcelFile(assessmentId, templateFile, answerFile);
      // await updateProfile({ data: imgForm.data, userId: imgForm.data.userId });
    },
    {
      onSuccess: (_, vars) => {
        const newExcelData = {
          ...(currentAssessment?.excelAssessment ?? {}),
        };
        if (vars.templateFile) {
          if (newExcelData.startingWorkbook == null) {
            newExcelData.startingWorkbook = {
              filename: vars.templateFile.name,
              type: "starting-template",
              docType: "xlsx",
              uploadDate: new Date().toISOString(),
            };
          } else {
            newExcelData.startingWorkbook.filename = vars.templateFile.name;
            newExcelData.startingWorkbook.uploadDate = new Date().toISOString();
          }
        }
        if (vars.answerFile) {
          if (newExcelData.expectedAnswer == null) {
            newExcelData.expectedAnswer = {
              filename: vars.answerFile.name,
              type: "starting-template",
              docType: "xlsx",
              uploadDate: new Date().toISOString(),
            };
          } else {
            newExcelData.expectedAnswer.filename = vars.answerFile.name;
            newExcelData.expectedAnswer.uploadDate = new Date().toISOString();
          }
        }
        queryClient.invalidateQueries("assessments_table");
        queryClient.invalidateQueries("assessments");
        queryClient.invalidateQueries("useBadgeValues");
        setCurrentAssessment({ ...currentAssessment, excelAssessment: newExcelData });
        enqueueSnackbar("Assessment has been updated!", { variant: "success" });
      },
    }
  );

  const onDropExcelStartHandler = (img) => {
    const file = img[0];
    uploadSignatureImgMutation.mutate({
      assessmentId: currentAssessment?._id,
      templateFile: file,
      // answerFile,
    });
  };

  const onDropExcelExpectHandler = (img) => {
    const file = img[0];
    uploadSignatureImgMutation.mutate({
      assessmentId: currentAssessment?._id,
      // templateFile,
      answerFile: file,
    });
  };

  const deleteTemplateFileHandler = async () => {
    if (currentAssessment) {
      await updateAssessmentMutation({
        _id: currentAssessment?._id,
        data: {
          excelAssessment: {
            ...currentAssessment?.excelAssessment,
            startingWorkbook: null,
          },
        },
      });
    }
  };

  const deleteExpectedFileHandler = async () => {
    if (currentAssessment) {
      await updateAssessmentMutation({
        _id: currentAssessment?._id,
        data: {
          excelAssessment: {
            ...currentAssessment?.excelAssessment,
            expectedAnswer: null,
          },
        },
      });
    }
  };

  const templateDelete = () => {
    setConfirmModal({
      isOpen: true,
      title: "Delete Template File",
      bodyText: "Are you sure you want to delete the template file?",
      response: false,
      description: "Are you sure you want to delete the template file?",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          deleteTemplateFileHandler();
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };

  const expectedDelete = () => {
    setConfirmModal({
      isOpen: true,
      title: "Delete Expected File",
      bodyText: "Are you sure you want to delete the expected file?",
      response: false,
      description: "Are you sure you want to delete the expected file?",
      negativeBtn: {
        label: "Cancel",
        fn: closeModalHandler,
        isShown: true,
      },
      positiveBtn: {
        label: "Confirm",
        fn: async () => {
          deleteExpectedFileHandler();
          closeModalHandler();
        },
        isShown: true,
      },
    });
  };

  const size = isMobile ? 70 : 100;

  return (
    <>
      <MDBox>
        <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
          <MDTypography variant="h6" className={styles.titleBar_title}>
            Excel Template
          </MDTypography>
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={12}>
            <FileDropzone
              message="Drag & Drop or Choose file to upload"
              onDrop={onDropExcelStartHandler}
              maxSize={1048576}
              size="8rem"
              icon={<UploadFile />}
              customStyles={styles}
              accept={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                "application/vnd.ms-excel": [".xls"],
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <MDBox className={styles.section_image_container}>
              <Stack>
                {attachmentsStartingData.map((file, idx) => {
                  return (
                    <MDBox className={styles.section_image_container2} key={file?.name}>
                      <Stack
                        container
                        justifyContent="space-evenly"
                        alignItems="center"
                        mb={3}
                        pt={1}
                      >
                        <MDBox
                          style={{
                            position: "absolute",
                            right: "-10px",
                            top: "-10px",
                            padding: 1,
                            borderRadius: "50%",
                            zIndex: "1000",
                          }}
                        >
                          <IconButton
                            color="error"
                            sx={{ padding: "0px", background: "#fff", zIndex: "1000" }}
                            onClick={templateDelete}
                          >
                            <Icon fontSize="small">cancel</Icon>
                          </IconButton>
                        </MDBox>

                        <MDBox
                          onClick={() => {
                            if (IMAGE_SERVER)
                              window.open(
                                `${IMAGE_SERVER}/assessments/${currentAssessment?._id}/starting-template/${file?.filename}`
                              );
                          }}
                        >
                          <img
                            src={`${getCommonBaseImageUrl(company)}/static/excel_icon.png`}
                            alt="preview"
                            width={size}
                            height={size}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography sx={{ fontSize: "12px" }}>{file?.filename}</MDTypography>
                        </MDBox>
                      </Stack>
                    </MDBox>
                  );
                })}
              </Stack>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <MDBox display="flex" justifyContent="space-between" className={styles.titleBar}>
          <MDTypography variant="h6" className={styles.titleBar_title}>
            Excel Expected Answer File
          </MDTypography>
        </MDBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={12}>
            <FileDropzone
              message="Drag & Drop or Choose file to upload"
              onDrop={onDropExcelExpectHandler}
              maxSize={1048576}
              size="8rem"
              icon={<UploadFile />}
              customStyles={styles}
              accept={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                "application/vnd.ms-excel": [".xls"],
              }}
            />
          </Grid>

          <Grid item sm={12}>
            <MDBox className={styles.section_image_container}>
              <Stack>
                {attachmentsExpectedData.map((file, idx) => {
                  return (
                    <MDBox className={styles.section_image_container2} key={file?.name}>
                      <Stack
                        container
                        justifyContent="space-evenly"
                        alignItems="center"
                        mb={3}
                        pt={1}
                      >
                        <MDBox
                          style={{
                            position: "absolute",
                            right: "-10px",
                            top: "-10px",
                            padding: 1,
                            borderRadius: "50%",
                            zIndex: "1000",
                          }}
                        >
                          <IconButton
                            color="error"
                            sx={{ padding: "0px", background: "#fff", zIndex: "1000" }}
                            onClick={expectedDelete}
                          >
                            <Icon fontSize="small">cancel</Icon>
                          </IconButton>
                        </MDBox>

                        <MDBox
                          onClick={() => {
                            if (IMAGE_SERVER)
                              window.open(
                                `${IMAGE_SERVER}/assessments/${currentAssessment?._id}/expected-answer/${file?.filename}`
                              );
                          }}
                        >
                          <img
                            src={`${getCommonBaseImageUrl(company)}/static/excel_icon.png`}
                            alt="preview"
                            width={size}
                            height={size}
                          />
                        </MDBox>
                        <MDBox>
                          <MDTypography sx={{ fontSize: "12px" }}>{file?.filename}</MDTypography>
                        </MDBox>
                      </Stack>
                    </MDBox>
                  );
                })}
              </Stack>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <ConfirmModal
        title={confirmModal.title}
        description={confirmModal.description}
        positiveBtn={confirmModal.positiveBtn}
        negativeBtn={confirmModal.negativeBtn}
        isOpen={confirmModal.isOpen}
        closeFn={closeModalHandler}
      />
    </>
  );
};

export default AssessmentsQuestionsExcel;
