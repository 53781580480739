import { baseAxios } from "../../config";

/**
 * Generates a filled PDF by overlaying form values on the original PDF
 * @param {string} formId - The ID of the form
 * @param {object} formValues - The values to fill in the form
 * @returns {Promise} - The API response with PDF data
 */
const generateFilledPdf = async ({ formId, formValues }) => {
  try {
    const response = await baseAxios.post(`/llm/dynamicForms/${formId}/generate-pdf`, 
      { formValues },
      { 
        responseType: 'blob',
        headers: {
          'Accept': 'application/pdf'
        }
      }
    );
    
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export default generateFilledPdf; 