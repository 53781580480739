import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const CustomLabel = (props) => {
  const { x, y, width, height, value, name } = props;

  return (
    <g>
      <text
        x={x + width + 10}
        y={y + height / 2}
        fill="#344767"
        textAnchor="start"
        alignmentBaseline="middle"
        fontSize={14}
        fontWeight="bold"
      >
        {name}
      </text>
    </g>
  );
};

const CustomValue = (props) => {
  const { x, y, width, height, value, name } = props;

  return (
    <g>
      <text
        x={x}
        dx={height * 0.35}
        y={y}
        dy={height * 0.7}
        fill="white"
        textAnchor="start"
        fontSize={Math.min(20, height * 0.7)}
        fontWeight="lighter"
      >
        {value}
      </text>
    </g>
  );
};

const CustomBarChart = ({ data, icons }) => {
  // Determine the appropriate aspect ratio based on data length
  const aspectRatio = data.length <= 3 ? 4 : 2;

  // Calculate dynamic height
  const dynamicHeight = Math.max(200, data.length * 60);

  return (
    <ResponsiveContainer width="100%" height={dynamicHeight}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{
          top: 10,
          right: 150,
          left: 20,
          bottom: 10,
        }}
      >
        <XAxis type="number" axisLine={false} tick={null} />
        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={({ x, y, payload }) => (
            <foreignObject x={x - 40} y={y - 20} width={40} height={40}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                {icons?.[payload.value]}
              </div>
            </foreignObject>
          )}
          interval={0}
          width={50}
        />
        <Tooltip />
        <Bar dataKey="value" radius={[18, 18, 18, 18]} minPointSize={50}>
          {data.map((entry) => (
            <>
              <Cell key={entry.name} fill={entry.color} />
              <LabelList dataKey="name" content={<CustomLabel />} />
              <LabelList dataKey="value" content={<CustomValue />} />
            </>
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default CustomBarChart;
