import { baseAxios } from "config";

export const getAssessmentResultsUrl = async (applicantId, fileName) => {
  //   try {
  const res = await baseAxios.get(`/assessments/applicants/${applicantId}/link/${fileName}`);
  return res.data;
  //   } catch (err) {
  //     console.error(`Error fetching assessment:`, err);
  //     throw new Error(err.toString());
  //   }
};
