import { useEffect, useState, useCallback } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { FolderCopy } from "@mui/icons-material";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomCard from "components/CustomCard";
import FreeTagList from "components/FreeTagList";
import MDBox from "components/MDBox";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import PanelActions from "components/PanelActions";
import { useAppContextController } from "context/AppContext";
import { customPanelCard, cardStyles as styles } from "layouts/pages/customers/styles";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { getDirtyFields } from "utils/helpers/formHelpers";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import generateAssessmentSkills from "../../actions/generateAssessmentSkills";
import updateAssessment from "../../actions/updateAssessment";

const useStyle = makeStyles({
  editor: {
    height: 450,
  },
});

const AssessmentsContent = () => {
  const {
    currentAssessment,
    setCurrentAssessment,
    isMobile,
    company,
    setCurrentFormDirty,
    changePanel,
  } = useAppContextController();

  const classes = useStyle();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty: isDirtyForm, dirtyFields },
  } = useForm();

  const isDirty = !isEmpty(dirtyFields);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
  }, []);

  const { mutateAsync: updateAssessmentMutation } = useMutation(updateAssessment, {
    onSuccess: async (res, vars) => {
      if (!vars.silent) {
        enqueueSnackbar("Assessment has been updated.", { variant: "success" });
      }
      setCurrentAssessment({ ...currentAssessment, ...vars.data });
      await queryClient.invalidateQueries("assessments");
    },
    onError: (err, vars) => {
      if (!vars.silent) {
        enqueueSnackbar(`An error occured: ${err}`, { variant: "error" });
      }
    },
  });

  const { mutateAsync: generateAssessmentSkillsMutation, isLoading } = useMutation(
    generateAssessmentSkills,
    {
      onSuccess: async (res, vars) => {
        enqueueSnackbar("Skills generated successfully.", { variant: "success" });
        setValue("skills", res.skills, { shouldDirty: true });
      },
      onError: (err) => enqueueSnackbar(`An error occured: ${err}`, { variant: "error" }),
    }
  );

  const handleUpdateAssessment = (values, method = "update" /* onSuccess */) => {
    const modifiedFields = getDirtyFields(values, dirtyFields);
    updateAssessmentMutation({ _id: currentAssessment?._id, data: { ...modifiedFields } });
  };

  const handleGenerateQuestions = () => {
    const div = document.createElement("div");
    div.innerHTML = watch("description") ?? "";
    const plainDescription = div.innerText;
    if (!plainDescription.length) {
      enqueueSnackbar("A description is required.", { variant: "error" });
      return;
    }
    generateAssessmentSkillsMutation({ description: watch("description"), hasName: true });
  };

  const handleCancelChanges = () => {
    reset(currentAssessment);
    enqueueSnackbar("Changes have been abandoned!", { variant: "success" });
  };

  useEffect(() => {
    reset(currentAssessment);
  }, [currentAssessment]);

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(handleUpdateAssessment)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  // Autosave function
  const handleAutoSave = useCallback(async () => {
    if (!isDirty || !currentAssessment?._id) return;

    try {
      const data = getValues();
      const modifiedFields = getDirtyFields(data, dirtyFields);
      await updateAssessmentMutation(
        { _id: currentAssessment._id, data: { ...modifiedFields }, silent: true },
        {
          onSuccess: async (res) => {
            setCurrentAssessment({ ...currentAssessment, ...modifiedFields });
            await queryClient.invalidateQueries("assessments");
          },
          onError: (err) => {
            console.error("Autosave failed:", err);
          },
        }
      );
    } catch (error) {
      console.error("Autosave failed:", error);
    }
  }, [
    isDirty,
    currentAssessment?._id,
    dirtyFields,
    getValues,
    updateAssessmentMutation,
    queryClient,
  ]);

  // Set up autosave interval
  useEffect(() => {
    const autoSaveInterval = setInterval(handleAutoSave, 30000); // Autosave every 30 seconds
    return () => clearInterval(autoSaveInterval);
  }, [handleAutoSave]);

  // Autosave when content changes
  useEffect(() => {
    if (!isDirty) return undefined;

    const debounceTimer = setTimeout(handleAutoSave, 2000); // Wait 2 seconds after changes before saving
    return () => clearTimeout(debounceTimer);
  }, [watch("description"), watch("skills"), handleAutoSave, isDirty]);

  return (
    <form onSubmit={handleSubmit(handleUpdateAssessment)}>
      <CustomCard
        icon={<FolderCopy color="white" />}
        cardTitle={
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <span>Assessment Content: {currentAssessment?.name}</span>
          </Box>
        }
        cardActions={
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {isDirty && <PanelActions onCancel={handleCancelChanges} />}
          </Box>
        }
        {...customPanelCard(isMobile, "primary")}
      >
        <Box sx={styles.container}>
          <MDBox mb={10} mt={2}>
            <Controller
              name="description"
              control={control}
              render={({ field: { value, onChange, ...props } }) => (
                <MDEditor
                  className={classes.editor}
                  theme="snow"
                  onChange={(text, delta, source, editor) => {
                    if (source === "user") onChange(text);
                  }}
                  value={value || ""}
                  placeholder="Write Content"
                  {...props}
                />
              )}
            />
          </MDBox>
          <Box>
            <Controller
              name="skills"
              control={control}
              defaultValue={watch("skills")}
              render={({ field }) => (
                <FreeTagList
                  {...field}
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  currentComponent={currentAssessment}
                  label="Skills Measured"
                  placeholder="Type a skill and press Enter"
                  onGenerateSkills={handleGenerateQuestions}
                  isLoadingGenerateSkills={isLoading}
                />
              )}
            />
            {errors?.invalidTag && (
              <MDTypography color="error">{errors?.invalidTag?.message}</MDTypography>
            )}
          </Box>
        </Box>
      </CustomCard>
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default AssessmentsContent;
