import { useMemo } from "react";
import { Grid, Tooltip } from "@mui/material";
import MDTypography from "components/MDTypography";
import moment from "moment";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import { Block, Flag, Phone, SpeakerNotesOff, VerifiedUser } from "@mui/icons-material";
import OnboardingStatus from "components/OnboardingStatus";
import CustomWidthTooltip from "layouts/pages/applicants/components/CustomWidthTooltip";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import JobsStatusRowActions from "./JobsStatusRowActions";

const JobsStatusRow = ({
  row,
  refresh,
  onAddToJob,
  onChangeStatus,
  onOpenResumeModal,
  onOpenRemoveFlagModal,
  onTouchJob,
  isJobFull,
  isSearchingAll,
  isShowingAiScore,
  isShowingAssessmentScore,
  onOpenAIQuestionsModal,
  onOpenAssessmentModal,
  onOpenAssessmentAssignModal,
  currentVenue,
  fullscreen,
}) => {
  const shouldShowAIScore = isShowingAiScore && row.aiRank != null;
  const shouldShowAssessmentScore = isShowingAssessmentScore && row.assessmentRank != null;
  const scoreFinal = useMemo(() => {
    if (shouldShowAIScore) return row.aiRank;
    if (shouldShowAssessmentScore) return row.assessmentRank;
    return row.rank;
  }, [row.aiRank, row.assessmentRank, row.rank, shouldShowAIScore, shouldShowAssessmentScore]);

  const flagsLabel = useMemo(() => {
    let label = "";
    if (!fullscreen) label += `${row?.email} \n ${row?.phone}`;
    if (row?.jobAgent) label += `${label ? "\n" : ""}Added by: ${row.jobAgent}`;
    if (row?.isDnu) label += `${label ? "\n" : ""}Do not use`;
    if (row.recordFlagged === "Yes") label += `${label ? "\n" : ""}Check Employee Notes`;
    if (row.aiScreened === "Yes" && row.status === "Applicant")
      label += `${label ? "\n" : ""}Screened by AI. (Click to remove flag)`;
    if (
      row.waitingForCall === "Yes" &&
      row.status === "Applicant" &&
      (row.applicantStatus === "New" || row.applicantStatus === "ATC")
    )
      label += `${label ? "\n" : ""}Waiting for call. (Click to remove flag)`;
    return label;
  }, [
    fullscreen,
    row.aiScreened,
    row.applicantStatus,
    row?.email,
    row?.isDnu,
    row.jobAgent,
    row?.phone,
    row.recordFlagged,
    row.status,
    row.waitingForCall,
  ]);

  const starsColor = useMemo(() => {
    if (shouldShowAIScore) return "#8935f4";
    if (shouldShowAssessmentScore) return "#1A73E8";
    return "#f7c501";
  }, [shouldShowAIScore, shouldShowAssessmentScore]);

  return (
    <>
      <Grid container spacing={2} alignItems="center" flexWrap="nowrap">
        <Grid item xs={12} sm={1}>
          <OnboardingStatus applicant={row} size="md" useAltIcons />
        </Grid>
        <Grid item flexGrow={2} flexBasis={0} flexShrink={1} overflow="hidden">
          <CustomWidthTooltip title={flagsLabel}>
            <MDTypography variant="body2">
              {[row.firstName, row.lastName].join(" ")}
              {!!row?.jobAgent && (
                <VerifiedUser
                  fontSize="small"
                  color="info"
                  sx={{
                    verticalAlign: "middle",
                    marginLeft: "3px",
                  }}
                />
              )}
              {row?.isDnu === "Yes" && (
                <Block
                  fontSize="small"
                  color="error"
                  sx={{
                    verticalAlign: "middle",
                    marginLeft: "3px",
                  }}
                />
              )}
              {row.recordFlagged === "Yes" && (
                <Flag
                  fontSize="small"
                  color="error"
                  sx={{
                    verticalAlign: "middle",
                    marginLeft: "3px",
                  }}
                />
              )}
              {row.aiScreened === "Yes" && row.status === "Applicant" && (
                <SpeakerNotesOff
                  fontSize="small"
                  color="violet"
                  onClick={() => onOpenRemoveFlagModal(row, "aiScreened")}
                  sx={{
                    verticalAlign: "middle",
                    marginLeft: "3px",
                  }}
                />
              )}
              {row.waitingForCall === "Yes" &&
                row.status === "Applicant" &&
                (row.applicantStatus === "New" || row.applicantStatus === "ATC") && (
                  <Phone
                    fontSize="small"
                    color="info"
                    onClick={() => onOpenRemoveFlagModal(row, "waitingForCall")}
                    sx={{
                      verticalAlign: "middle",
                      marginLeft: "3px",
                    }}
                  />
                )}
            </MDTypography>
          </CustomWidthTooltip>
        </Grid>
        {fullscreen ? (
          <>
            <Grid item flexGrow={2} flexBasis={0} flexShrink={1} overflow="hidden">
              <Tooltip title={row.phone}>
                <MDTypography variant="body2">{row.phone}</MDTypography>
              </Tooltip>
            </Grid>
            <Grid item flexGrow={2.5} flexBasis={0} flexShrink={1} overflow="hidden">
              <Tooltip title={row.email}>
                <MDTypography variant="body2">{row.email}</MDTypography>
              </Tooltip>
            </Grid>
            <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
              <MDTypography variant="body2">{row.systemSource}</MDTypography>
            </Grid>
          </>
        ) : null}
        <Grid
          container
          item
          xs={12}
          sm={fullscreen ? 2 : 2.5}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Tooltip
            title={`${shouldShowAIScore ? "AI Screening Score" : "Score"}: ${
              scoreFinal ? (scoreFinal * 100).toFixed(2) : 0
            }`}
          >
            <Grid sx={{ width: "100%", maxWidth: "175px" }}>
              <JobsStatusRankStars
                rank={scoreFinal ?? 0}
                color={starsColor}
                linear={shouldShowAIScore || shouldShowAssessmentScore}
              />
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={fullscreen ? 1 : 2}>
          <Tooltip
            slotProps={{ tooltip: { style: { whiteSpace: "pre-line" } } }}
            title={`Last Activity${
              row.jobModifiedAgent && row.jobModifiedAgentName
                ? ` by ${row.jobModifiedAgentName}`
                : " Date"
            }${
              row.jobApplyDate
                ? `\nApply date: ${moment(row.jobApplyDate).format("MM/DD/YYYY")}`
                : ""
            }`}
          >
            <MDTypography variant="body2">
              {row.jobModifiedDate || !!row.interview?.eventDate
                ? moment(row.jobModifiedDate).format("MM/DD/YYYY")
                : ""}
            </MDTypography>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={fullscreen ? 3 : 4}>
          <JobsStatusRowActions
            applicant={row}
            refresh={refresh}
            onAddToJob={onAddToJob}
            onChangeStatus={onChangeStatus}
            onOpenResumeModal={onOpenResumeModal}
            isJobFull={isJobFull}
            isSearchingAll={isSearchingAll}
            onTouchJob={onTouchJob}
            onOpenAIQuestionsModal={onOpenAIQuestionsModal}
            onOpenAssessmentModal={onOpenAssessmentModal}
            currentVenue={currentVenue}
            onOpenAssessmentAssignModal={onOpenAssessmentAssignModal}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default JobsStatusRow;
