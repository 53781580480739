export const QUESTION_TYPES = ["Multiple Choice", "Fill-In", "True/False"];

export const INITIAL_QUESTION_STATE = {
  text: "",
  required: true,
  type: "Multiple Choice",
  answers: [],
};

export const INITIAL_QUESTION_MULTI_CHOICE_ANSWER = {
  answerText: "",
  skillMeasured: "",
};
