import {
  AccessTime,
  Check,
  Checklist,
  ConnectWithoutContact,
  DateRange,
  DirectionsRun,
  EditNote,
  Event,
  FolderShared,
  GridOn,
  Groups,
  LibraryAddCheck,
  PersonAdd,
  PersonSearch,
  Public,
  QuestionAnswer,
  Quiz,
  Stadium,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/Work";
import HistoryIcon from "@mui/icons-material/History";
import BoltIcon from "@mui/icons-material/Bolt";
import BusinessIcon from "@mui/icons-material/Business";

export const MY_EVENTS_OPTIONS = [
  {
    color: "dark",
    icon: <Public fontSize="medium" color="black" />,
    title: "All",
    filterField: "rosterStatus",
    value: "all",
  },
  {
    color: "success",
    icon: <Check fontSize="medium" color="success" />,
    title: "Selected",
    filterField: "rosterStatus",
    value: "inRoster",
  },
];

export const MY_CUSTOMERS_OPTIONS = [
  {
    color: "dark",
    icon: <Public fontSize="medium" color="black" />,
    title: "All",
    filterField: "status",
    value: "all",
  },
  {
    color: "success",
    icon: <DirectionsRun fontSize="medium" color="success" />,
    title: "Active",
    filterField: "status",
    value: "Active",
  },
  {
    color: "warning",
    icon: <PersonSearch fontSize="medium" color="warning" />,
    title: "Prospect",
    filterField: "status",
    value: "Prospect",
  },
];
export const MY_TIMECLOCK_OPTIONS = [
  {
    color: "success",
    icon: <BoltIcon fontSize="medium" color="success" />,
    title: "Current",
    filterField: "eventDate",
    value: "current",
  },
  {
    color: "error",
    icon: <HistoryIcon fontSize="medium" color="error" />,
    title: "Past",
    filterField: "eventDate",
    value: "past",
  },
];

export const APPLICANT_VENUES_OPTIONS = [
  {
    color: "dark",
    title: "All",
    value: "all",
  },
  {
    color: "dark",
    icon: <Stadium fontSize="medium" color="success" />,
    title: "Staffing Pool",
    value: "StaffingPool",
  },
  {
    icon: <Stadium fontSize="medium" color="warning" />,
    title: "Pending",
    value: "Pending",
  },
  {
    icon: <Stadium fontSize="medium" color="error" />,
    title: "Locked",
    value: "Locked",
  },
];

export const APPLICANT_VENUES_JOBS_OPTIONS = [
  {
    color: "dark",
    title: "All",
    value: "all",
  },
  {
    icon: <WorkIcon fontSize="medium" color="success" />,
    title: "On Assignment",
    value: "On Assignment",
  },
  {
    icon: <WorkIcon fontSize="medium" color="warning" />,
    title: "Pending",
    value: "Pending",
  },
  {
    icon: <WorkIcon fontSize="medium" color="error" />,
    title: "Declined",
    value: "Declined",
  },
];

export const APPLICANT_COMPANIES_JOBS_OPTIONS = [
  {
    color: "dark",
    icon: <WorkIcon fontSize="small" color="secondary" />,
    iconFullscreen: <WorkIcon fontSize="large" color="secondary" sx={{ color: "#7b809a" }} />,
    title: "All",
    value: "all",
  },
  {
    icon: <WorkIcon fontSize="medium" color="success" />,
    iconFullscreen: <WorkIcon fontSize="large" color="success" />,
    title: "On Assignment",
    value: "On Assignment",
  },
  {
    icon: <WorkIcon fontSize="medium" color="warning" />,
    iconFullscreen: <WorkIcon fontSize="large" color="warning" />,
    title: "Pending",
    value: "Pending",
  },
  {
    icon: <WorkIcon fontSize="medium" color="error" />,
    iconFullscreen: <WorkIcon fontSize="large" color="error" />,
    title: "Declined",
    value: "Declined",
  },
  {
    icon: <WorkIcon fontSize="medium" color="primary" />,
    iconFullscreen: <WorkIcon fontSize="large" color="primary" sx={{ color: "#e91e63" }} />,
    title: "Completed",
    value: "Completed",
  },
  {
    icon: <WorkIcon fontSize="medium" color="error" />,
    iconFullscreen: <WorkIcon fontSize="large" color="error" />,
    title: "Terminated",
    value: "Terminated",
  },
];

export const APPLICANT_COMPANIES_OPTIONS = [
  {
    color: "dark",
    title: "All",
    value: "all",
  },
  {
    color: "dark",
    icon: <BusinessIcon fontSize="small" color="success" />,
    title: "On Assignment",
    value: "On Assignment",
  },
  {
    icon: <BusinessIcon fontSize="small" color="warning" />,
    title: "Pending",
    value: "Pending",
  },
  {
    icon: <BusinessIcon fontSize="small" color="secondary" />,
    title: "Inactive",
    value: "Inactive",
  },
  // {
  //   icon: <BusinessIcon fontSize="small" color="error" />,
  //   title: "Dnu",
  //   value: "Dnu",
  // },
  // {
  //   icon: <BusinessIcon fontSize="small" color="error" />,
  //   title: "Terminated",
  //   value: "Terminated",
  // },
];
export const APPLICANT_EVENTS_OPTIONS = [
  {
    color: "dark",
    title: "All",
    value: "All",
  },
  {
    color: "dark",
    icon: <DateRange fontSize="medium" color="success" />,
    title: "Roster",
    value: "Roster",
  },
  {
    color: "dark",
    icon: <DateRange fontSize="medium" color="info" />,
    title: "Waitlist",
    value: "Waitlist",
  },
  {
    icon: <DateRange fontSize="medium" color="warning" />,
    title: "Request",
    value: "Request",
  },
];

export const PAYROLL_EXPORT_OPTIONS = [
  {
    icon: <Groups fontSize="medium" color="error" />,
    title: "Event Roster",
    value: "eventRoster",
  },
  {
    icon: <AccessTime fontSize="medium" color="success" />,
    title: "Time Sheet",
    value: "timeSheet",
  },
  {
    icon: <Event fontSize="medium" color="warning" />,
    title: "Events",
    value: "events",
  },
];

export const SHIFT_JOBS_EXPORT_OPTIONS = [
  {
    icon: <Groups fontSize="medium" color="error" />,
    title: "Job Roster",
    value: "jobsRoster",
  },
  {
    icon: <AccessTime fontSize="medium" color="success" />,
    title: "Time Sheet",
    value: "jobsTimeSheet",
  },
  {
    icon: <WorkIcon fontSize="medium" color="warning" />,
    title: "Jobs",
    value: "jobs",
  },
];

export const JOB_APPLICANTS_OPTIONS = [
  {
    icon: <WorkIcon fontSize="small" color="secondary" />,
    iconFullscreen: <WorkIcon fontSize="large" color="secondary" sx={{ color: "#7b809a" }} />,
    iconName: "work",
    iconColor: "secondary",
    title: "All",
    value: "all",
  },
  {
    icon: <PersonAdd fontSize="small" color="violet" />,
    iconFullscreen: <PersonAdd fontSize="large" color="violet" sx={{ color: "#9c27b0" }} />,
    iconName: "person_add",
    iconColor: "violet",
    title: "New",
    value: "New",
  },
  {
    icon: <ConnectWithoutContact fontSize="small" color="warning" />,
    iconFullscreen: <ConnectWithoutContact fontSize="large" color="warning" />,
    iconName: "connect_without_contact",
    iconColor: "warning",
    title: "ATC",
    value: "ATC",
  },
  {
    icon: <QuestionAnswer fontSize="small" color="info" />,
    iconFullscreen: <QuestionAnswer fontSize="large" color="info" />,
    iconName: "question_answer",
    iconColor: "info",
    title: "Screened",
    value: "Screened",
  },
  {
    icon: <FolderShared fontSize="small" color="success" />,
    iconFullscreen: <FolderShared fontSize="large" color="success" />,
    iconName: "folder_shared",
    iconColor: "success",
    title: "Pre-Hire",
    value: "Pre-Hire",
  },
  {
    icon: <ThumbDown fontSize="small" color="error" />,
    iconFullscreen: <ThumbDown fontSize="large" color="error" />,
    iconName: "thumb_down",
    iconColor: "error",
    title: "Declined",
    value: "Declined",
  },
];

export const JOB_INTERVIEWS_OPTIONS = [
  {
    color: "dark",
    icon: <ThumbUp fontSize="small" color="secondary" />,
    title: "All",
    value: "all",
  },
  {
    color: "dark",
    icon: <ThumbUp fontSize="small" color="success" />,
    title: "Hired",
    value: "Hired",
  },
  {
    icon: <ThumbUp fontSize="small" color="warning" />,
    title: "Mixed",
    value: "Pending",
  },
  {
    icon: <ThumbDown fontSize="small" color="error" />,
    title: "Declined",
    value: "Declined",
  },
];

export const JOB_ASSESSMENTS_OPTIONS = [
  {
    icon: <Public fontSize="small" color="info" />,
    title: "All",
    value: "all",
  },
  {
    icon: <Checklist fontSize="small" color="info" />,
    title: "Multiple Choice",
    value: "Multiple Choice",
  },
  {
    icon: <EditNote fontSize="small" color="error" />,
    title: "Fill-In",
    value: "Fill-In",
  },
  {
    icon: <LibraryAddCheck fontSize="small" color="success" />,
    title: "True/False",
    value: "True/False",
  },
  {
    icon: <GridOn fontSize="small" color="success" />,
    title: "Excel",
    value: "Excel",
  },
  {
    icon: <Quiz fontSize="small" color="violet" />,
    title: "Hybrid",
    value: "Hybrid",
  },
];

export const JOB_STATUS_OPTIONS = [
  {
    icon: <WorkIcon fontSize="small" color="secondary" />,
    iconFullscreen: <WorkIcon fontSize="large" color="secondary" sx={{ color: "#7b809a" }} />,
    title: "All",
    value: "Applies",
  },
  {
    icon: <WorkIcon fontSize="small" color="warning" />,
    iconFullscreen: <WorkIcon fontSize="large" color="warning" />,
    title: "Submitted",
    value: "Submitted",
  },
  {
    icon: <WorkIcon fontSize="small" color="success" />,
    iconFullscreen: <WorkIcon fontSize="large" color="success" />,
    title: "Interview",
    value: "Interview",
  },
  {
    icon: <WorkIcon fontSize="small" color="violet" />,
    iconFullscreen: <WorkIcon fontSize="large" color="violet" sx={{ color: "#5f32a2" }} />,
    title: "Waitlist",
    value: "Waitlist",
  },
  {
    icon: <WorkIcon fontSize="small" color="error" />,
    iconFullscreen: <WorkIcon fontSize="large" color="error" />,
    title: "Declined",
    value: "Declined",
  },
  {
    icon: <WorkIcon fontSize="small" color="info" />,
    iconFullscreen: <WorkIcon fontSize="large" color="info" />,
    title: "Filled",
    value: "Filled",
  },
  {
    icon: <WorkIcon fontSize="small" color="error" />,
    iconFullscreen: <WorkIcon fontSize="large" color="error" />,
    title: "Terminated",
    value: "Terminated",
  },
  {
    icon: <WorkIcon fontSize="small" color="primary" />,
    iconFullscreen: <WorkIcon fontSize="large" color="primary" sx={{ color: "#e91e63" }} />,
    title: "Completed",
    value: "Completed",
  },
];
