import {
  Cancel as CancelIcon,
  QuestionAnswer as QuestionAnswerIcon,
  ThumbDown as ThumbDownIcon,
} from "@mui/icons-material";
import { Avatar, Button, Card, Fade, Grid, Modal } from "@mui/material";
import AIInterviewSummary from "components/AIInterviewSummary";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import { useAppContextController } from "context/AppContext";
import updateApplicantJobAIInterviews from "layouts/pages/applicants/actions/updateApplicantJobAIInterviews";
import updateApplicantStage from "layouts/pages/applicants/actions/updateApplicantStatus";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useMinStageToOnboarding from "hooks/useMinStageToOnboarding";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import AIInterviewChatWindow from "../AIInterviewChatWindow";

const initialValueModal = {
  title: null,
  description: null,
  negativeBtn: null,
  positiveBnt: null,
  isOpen: false,
};

const styles = {
  container: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    height: "100%",
    maxWidth: 1170,
    padding: "2rem",
    // overflow: "auto",
  },
};

const JobsStatusAIInterviewModal = ({ open, onClose, applicant, onChangeStatus, useLatest }) => {
  const [questionIndex, setQuestionIndex] = useState(null);
  const { currentLoggedUser, userType, company, currentJob } = useAppContextController();
  const { minStage, allowedStages, disallowedStages } = useMinStageToOnboarding();
  const isVenue = company?.companyType === "Venue";
  const [confirmModalApplicant, setConfirmModalApplicant] = useState(initialValueModal);
  const [notesModalOpen, setNoteModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);

  const shouldAskForOnboardingInvitation = minStage === "Screened" && !applicant?.acknowledged;

  const applicantJob = useMemo(() => {
    if (useLatest) {
      let lastAI = null;
      for (let index = 0; index < applicant?.jobs?.length; index += 1) {
        const applJob = applicant?.jobs[index];
        if (applJob?.aiInterview?.interviewEndDate) {
          if (
            !lastAI ||
            moment(applJob?.aiInterview?.interviewEndDate).isAfter(
              lastAI.aiInterview?.interviewEndDate
            )
          ) {
            lastAI = applJob;
          }
        }
      }
      return lastAI;
    }
    return applicant?.jobs?.find((jb) => jb.jobSlug === currentJob?.jobSlug);
  }, [applicant?.jobs, currentJob?.jobSlug, useLatest]);

  const applicantJobAiInterview = applicantJob?.aiInterview;

  const dateString = useMemo(() => {
    if (applicantJobAiInterview?.interviewStartDate || applicantJobAiInterview?.interviewEndDate) {
      const date =
        applicantJobAiInterview?.interviewEndDate ?? applicantJobAiInterview?.interviewStartDate;

      const now = moment();
      const dateMoment = moment(date);

      const days = Math.abs(dateMoment.diff(now, "days"));
      const hours = Math.abs(dateMoment.diff(now, "hours"));
      const minutes = Math.abs(dateMoment.diff(now, "minutes"));

      let unit = "day";
      let amount = 0;
      if (days < 1) {
        if (hours < 1) {
          unit = `minute${minutes > 1 ? "s" : ""}`;
          amount = minutes;
        } else {
          unit = `hour${hours > 1 ? "s" : ""}`;
          amount = hours;
        }
      } else {
        unit = `day${days > 1 ? "s" : ""}`;
        amount = days;
      }

      return `Active ${amount} ${unit} ago`;
    }
    return "";
  }, [applicantJobAiInterview?.interviewEndDate, applicantJobAiInterview?.interviewStartDate]);

  const applicantStatus = useMemo(() => {
    if (isVenue) {
      return applicant?.applicantStatus ?? "New";
    }
    return applicantJob?.applicantStatus ?? "New";
  }, [applicant?.applicantStatus, applicantJob?.applicantStatus, isVenue]);

  const { score = 0 } = applicantJobAiInterview ?? {};
  const scoreRounded = Math.round(score * 100);
  const aiResult = applicantJobAiInterview?.overall_scores?.final_recommendation;

  const [displayMode, setDisplayMode] = useState("transcript");

  const toggleDisplayMode = () => {
    setDisplayMode((dm) => {
      if (dm === "transcript") return "summary";
      return "transcript";
    });
  };

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const updateAiInterviewMutation = useMutation(updateApplicantJobAIInterviews, {
    onSuccess: async (_, { applicantId, status }) => {},
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "applicantUpdateAiInterviewMutationMutation",
  });

  const changeStageMutation = useMutation(updateApplicantStage, {
    onSuccess: async (_, { applicantId, status, jobSlug }) => {
      enqueueSnackbar(
        `${applicant.firstName} ${applicant.lastName} has been set to: ${status}${
          !isVenue ? ` for ${applicantJob?.jobSlug}` : ""
        }`,
        { variant: "success" }
      );
      if (onChangeStatus) {
        onChangeStatus({
          applicantId,
          applicantStatus: status,
        });
      }
      await updateAiInterviewMutation.mutateAsync({
        applicantId,
        jobSlug,
        data: {
          ...(applicantJobAiInterview ?? {}),
          screeningDecision: status,
          screeningDecisionUserId: currentLoggedUser._id,
        },
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "applicantEmploymentStageChangeMutation",
  });

  const closeModalHandler = () => setConfirmModalApplicant(initialValueModal);

  const executeChangeStatus = (status, sendOnboardingMessage, jobNote) => {
    return changeStageMutation.mutateAsync({
      status,
      applicantId: applicant?._id,
      jobSlug: applicantJob?.jobSlug,
      sendOnboardingMessage,
      jobNote,
    });
  };

  const saveNote = (noteRec) => {
    executeChangeStatus("Declined", null, noteRec.text);
  };

  const handleChangeStatus = (status) => {
    if (status === "Screened") {
      if (!shouldAskForOnboardingInvitation) return executeChangeStatus(status, false);

      return setConfirmModalApplicant({
        isOpen: true,
        title: "Applicant stage changed",
        bodyText: `The applicant stage has been changed to ${minStage}. Do you want to send an onboarding invitation to the applicant?`,
        response: false,
        description: `The applicant stage has been changed to ${minStage}. Do you want to send an onboarding invitation to the applicant?`,
        negativeBtn: {
          label: "Cancel",
          fn: closeModalHandler,
          isShown: true,
        },
        negativeAltBtn: {
          label: "Don't Send",
          fn: async () => {
            executeChangeStatus(status, false);
            closeModalHandler();
          },
          isShown: true,
        },
        positiveBtn: {
          label: "Send",
          fn: async () => {
            executeChangeStatus(status, true);
            closeModalHandler();
          },
          isShown: true,
        },
      });
    }

    if (status === "Declined") {
      return setNoteModalOpen(true);
    }

    return null;
  };

  const [aiResultText, aiResultColor] = useMemo(() => {
    switch (aiResult) {
      case "RECOMMEND_HIRE":
        return ["Recommended", "success"];
      case "DO_NOT_RECOMMEND":
        return ["Not Recommended", "error"];
      case "MAYBE":
        return ["Maybe", "warning"];

      default:
        return [null, null];
    }
  }, [aiResult]);

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card style={styles.container}>
            <Button
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                m: 2,
                p: 0,
              }}
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <CancelIcon color="secondary" />
            </Button>
            <Grid container item flexDirection="column" height="100%">
              <Grid
                item
                container
                sx={{
                  borderBottomWidth: 2,
                  borderBottomColor: "#8f9095",
                  borderBottomStyle: "solid",
                  paddingBottom: 1,
                  justifyContent: "space-between",
                  paddingRight: "3rem",
                }}
              >
                <MDBox display="flex" flexDirection="row">
                  <Avatar
                    src={null}
                    alt={`${applicant?.firstName} ${applicant?.lastName} picture`}
                    sx={{ height: "50px", width: "50px" }}
                  >
                    {`${applicant?.firstName?.[0] ?? ""}${
                      applicant?.lastName?.[0] ?? ""
                    }`.toUpperCase()}
                  </Avatar>
                  <MDBox display="flex" flexDirection="column" pl={1}>
                    <MDTypography component="label" variant="h5" fontWeight="bold" color="dark">
                      {`${applicant?.firstName ?? ""} ${applicant.lastName ?? ""}`}
                    </MDTypography>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="secondary"
                      sx={{ fontSize: "0.74rem" }}
                    >
                      {dateString}
                    </MDTypography>
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="secondary"
                      sx={{ fontSize: "0.74rem" }}
                    >
                      Job: {applicantJob?.jobSlug}
                    </MDTypography>
                  </MDBox>
                </MDBox>
                {aiResultText != null ? (
                  <MDBox>
                    <MDBox display="flex" flexDirection="row" alignItems="center" pl={1}>
                      <MDTypography
                        component="label"
                        variant="h6"
                        fontWeight="bold"
                        color="dark"
                        textAlign="right"
                      >
                        Total Score:
                      </MDTypography>
                      <MDTypography
                        component="label"
                        variant="h6"
                        fontWeight="bold"
                        color="info"
                        textAlign="right"
                        sx={{ paddingLeft: "0.5rem" }}
                      >
                        {scoreRounded}
                      </MDTypography>
                      <MDTypography
                        component="label"
                        variant="h6"
                        fontWeight="bold"
                        textAlign="right"
                      >
                        /100
                      </MDTypography>
                    </MDBox>
                    <MDBox display="flex" flexDirection="row" alignItems="center" pl={1}>
                      <MDTypography
                        component="label"
                        variant="h6"
                        fontWeight="bold"
                        color="dark"
                        textAlign="right"
                      >
                        AI Result:
                      </MDTypography>
                      <MDTypography
                        component="label"
                        variant="h6"
                        fontWeight="bold"
                        color={aiResultColor}
                        textAlign="right"
                        sx={{ paddingLeft: "0.5rem" }}
                      >
                        {aiResultText}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                ) : null}
              </Grid>
              <Grid item container columnSpacing={2} pt={2} pb={2}>
                <Grid xs={3}>
                  <JobsStatusRankStars rank={score} color="#8935f4" linear />
                </Grid>
                <Grid xs={1.5} item />
                <Grid xs={2.5} item>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    color="secondary"
                    onClick={toggleDisplayMode}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                  >
                    {displayMode === "transcript" ? "Summary" : "Transcript"}
                  </Button>
                </Grid>
                <Grid xs={2.5} item>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    onClick={() => handleChangeStatus("Screened")}
                    startIcon={<QuestionAnswerIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                    disabled={applicantStatus === "Screened" || changeStageMutation.isLoading}
                  >
                    Screened
                  </Button>
                </Grid>
                <Grid xs={2.5} item>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => handleChangeStatus("Declined")}
                    startIcon={<ThumbDownIcon />}
                    style={{ color: "white" }}
                    sx={{ padding: "0.5rem 1rem" }}
                    disabled={applicantStatus === "Declined" || changeStageMutation.isLoading}
                  >
                    Declined
                  </Button>
                </Grid>
              </Grid>
              <Grid item container sx={{ flexBasis: 0, flexGrow: 1, overflow: "hidden" }}>
                {displayMode === "transcript" ? (
                  <AIInterviewChatWindow
                    firstName={applicant?.firstName}
                    lastName={applicant?.lastName}
                    items={applicantJobAiInterview?.questionsAndAnswers}
                    questionScores={applicantJobAiInterview?.question_scores}
                  />
                ) : (
                  <AIInterviewSummary
                    areas={applicantJobAiInterview?.overall_scores?.areas_summary}
                  />
                )}
              </Grid>
            </Grid>
          </Card>
        </Fade>
      </Modal>
      {confirmModalApplicant?.isOpen ? (
        <ConfirmModal
          title={confirmModalApplicant.title}
          description={confirmModalApplicant.description}
          positiveBtn={confirmModalApplicant.positiveBtn}
          negativeBtn={confirmModalApplicant.negativeBtn}
          negativeAltBtn={confirmModalApplicant.negativeAltBtn}
          isOpen={confirmModalApplicant.isOpen}
          closeFn={closeModalHandler}
        />
      ) : null}
      {notesModalOpen && (
        <AddANoteModal
          currentApplicant={applicant}
          open={notesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNoteModalOpen}
          saveNote={saveNote}
          showType={false}
          defaultValue={`${applicant?.firstName} ${applicant?.lastName} status changed to Declined for job ${currentJob?.jobSlug}`}
          onExtraAction={() => executeChangeStatus("Declined")}
          extraActionLabel="Change Status Only"
        />
      )}
    </div>
  );
};

// Setting default values for the props of CalendarDayModal
JobsStatusAIInterviewModal.defaultProps = {};

// Typechecking props of the CalendarDayModal
JobsStatusAIInterviewModal.propTypes = {};

export default JobsStatusAIInterviewModal;
