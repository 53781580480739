import { baseAxios } from "config";

const cancelYardstikAdverseAction = async ({ body }) => {
  try {
    const res = await baseAxios.post(`/yardstik/cancelAdverseAction`, body);
    return res.data;
  } catch (err) {
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};

export default cancelYardstikAdverseAction;
