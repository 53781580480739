import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import useCompanyCache from "hooks/useCompanyCache";
import EmailIcon from "@mui/icons-material/Email";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
// @mui material components
import Grid from "@mui/material/Grid";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import deleteIssue from "layouts/pages/issues/actions/deleteIssue";
import PropTypes from "prop-types";

import fetchIssueById from "layouts/pages/issues/actions/fetchIssueById";

import { Alert, Card, CardContent, Snackbar, Stack, Tab, Tabs, Typography } from "@mui/material";
import MessageTemplatesTable from "layouts/pages/setup/components/MessageTemplatesTable";
import DropdownEntitiesTable from "layouts/pages/setup/components/DropdownEntitiesTable";
import { SnackbarProvider } from "notistack";
import useSessionAuth from "hooks/useSessionAuth";
import { AutoAwesome, DocumentScanner } from "@mui/icons-material";
import DynamicForms from "layouts/pages/setup/components/DynamicForm/DynamicForms";
import CustomFieldsTab from "./components/CustomFieldsTab";

function Setup({ mode = "edit" }) {
  const navigate = useNavigate();

  const { company, setCompany, setCompanyType, currentIssue, setCurrentIssue, venues, userType } =
    useAppContextController();

  console.log("userType", userType);

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const { issueId, action } = useParams();
  const [actionSelected, setActionSelected] = useState(action || "");
  const [filters, setFilters] = useState({});
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [tabValue, setTabValue] = useState(0);

  const queryClient = useQueryClient();
  const { logoutAuth0User } = useSessionAuth();

  const deleteIssueHandler = async (deleteIssueId, issueTitle) => {
    try {
      const res = await deleteIssue(deleteIssueId);

      if (res?.status === 200) {
        await queryClient.invalidateQueries("issues");

        setToastAlert({
          isShow: true,
          message: `Issue ${issueTitle} has been deleted`,
          status: "success",
        });
        return { success: true };
      }

      setToastAlert({
        isShow: true,
        message: `Something went wrong -- ${res?.data?.message}`,
        status: "error",
      });
      return { success: false, message: res?.data?.message };
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }

      return { success: false, message: String(error) };
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  useEffect(async () => {
    if (issueId && (actionSelected || action)) {
      setFilters({ _id: issueId });
    }
    if (action) {
      // && action !== actionSelected) {
      setActionSelected(action);
    } else {
      setActionSelected("");
      // setFilters({ status: "Open" });
    }

    if (issueId && (!currentIssue || currentIssue._id !== issueId)) {
      try {
        const issues = await fetchIssueById(issueId);
        if (issues && issues?.data?.length) setCurrentIssue(issues?.data[0]);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
  }, [issueId, action, currentIssue]);

  const setNavigateUrl = (url) => navigate(url);

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} lg={12} xl={12} xxl={12}>
          {/* <IssuesFilterCards
            setFilters={setFilters}
            // setPage={setPage}
          /> */}
          <SnackbarProvider
            maxSnack={3}
            open={toastAlert.isShow}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
            key="top-center"
          >
            <Card>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                sx={{
                  "& .MuiTabs-flexContainer": {
                    width: "70%", // Adjust this value as needed
                  },
                  "& .MuiTab-root": {
                    width: "70%", // Adjust this value as needed
                    maxWidth: "none",
                  },
                }}
              >
                <Tab
                  label={
                    <Stack direction="row" alignItems="center">
                      <EmailIcon fontSize="medium" color="error" />
                      <Typography fontWeight="bold">Message Templates</Typography>
                    </Stack>
                  }
                />
                <Tab
                  label={
                    <Stack direction="row" alignItems="center">
                      <ArrowDropDownCircleIcon fontSize="medium" color="info" />
                      <Typography fontWeight="bold">Dropdown Entities</Typography>
                    </Stack>
                  }
                />
                <Tab
                  label={
                    <Stack direction="row" alignItems="center">
                      <AutoAwesome fontSize="medium" color="primary" />
                      <Typography fontWeight="bold">Custom Fields</Typography>
                    </Stack>
                  }
                />
                {userType === "Master" && company.dynamicFormsAvailable === "Yes" && (
                  <Tab
                    label={
                      <Stack direction="row" alignItems="center">
                        <DocumentScanner fontSize="medium" />
                        <Typography fontWeight="bold">Dynamic forms</Typography>
                      </Stack>
                    }
                  />
                )}
              </Tabs>
              <CardContent>
                {tabValue === 0 && (
                  <MessageTemplatesTable
                    id={issueId}
                    filters={filters}
                    setFilters={setFilters}
                    actionSelected={actionSelected}
                    setNavigateUrl={setNavigateUrl}
                    showFiltersList
                    title="Message Templates"
                  />
                )}
                {tabValue === 1 && (
                  <DropdownEntitiesTable
                    // id={issueId}
                    // filters={filters}
                    // setFilters={setFilters}
                    actionSelected={actionSelected}
                    setNavigateUrl={setNavigateUrl}
                    showFiltersList
                    title="DropDown Entities"
                  />
                )}
                {tabValue === 2 && <CustomFieldsTab />}
                {tabValue === 3 && <DynamicForms />}
              </CardContent>
            </Card>
          </SnackbarProvider>
        </Grid>
        {/* {["issuesinfo", "issuesall", ""].includes(actionSelected) &&
          (issueId || mode === "create") && (
            <Grid item xs={12} sm={12}>
              <IssuesInformation mode={mode} deleteIssue={deleteIssueHandler} />
            </Grid>
          )} */}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Setup.defaultProps = {
  mode: "edit",
};

// Typechecking props
Setup.propTypes = {
  mode: PropTypes.string,
};
export default Setup;
