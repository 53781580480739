import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";
import BlockIcon from "@mui/icons-material/Block";
import TaskFlag from "components/TaskFlag";
import { AutoAwesome, Phone, SpeakerNotesOff } from "@mui/icons-material";
import {
  getApplicantJobAiScreenedFlag,
  getApplicantJobCallMeFlag,
  getApplicantJobHasFinishedAIScreening,
} from "utils/helpers/applicant";

const ApplicantFlagsForJob = ({ flagged, row, jobSlug, onRemoveFlag }) => (
  <Stack direction="row" container>
    {row.isDnu === "Yes" && (
      <Tooltip title="Do not use">
        <BlockIcon fontSize="large" color="error" />
      </Tooltip>
    )}
    {(row.aiScreened === "Yes" || getApplicantJobAiScreenedFlag(row, jobSlug)) && (
      <Tooltip title="Screened by AI">
        <SpeakerNotesOff fontSize="large" color="violet" />
      </Tooltip>
    )}
    {getApplicantJobHasFinishedAIScreening(row, jobSlug) && (
      <Tooltip title="Finished AI Screening">
        <AutoAwesome fontSize="large" color="violet" />
      </Tooltip>
    )}
    {getApplicantJobCallMeFlag(row, jobSlug) && (
      <Tooltip title="Waiting for call. (Click to remove flag)">
        <Phone
          fontSize="large"
          color="info"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemoveFlag(row, "waitingForCall");
          }}
        />
      </Tooltip>
    )}
    {flagged === "Yes" && (
      <Tooltip title="Check Employee Notes">
        <FlagIcon fontSize="large" color="error" />
      </Tooltip>
    )}
    <TaskFlag _id={row._id} />
  </Stack>
);

export default ApplicantFlagsForJob;
