import { baseAxios } from "config";

export const evaluateExcelSubmission = async (assessmentId, applicantId, file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    // Don't make API call if no files to upload
    if (!file) {
      return { success: true };
    }

    const response = await baseAxios.post(
      `/outside-public/assessments/${assessmentId}/applicants/${applicantId}/submit-excel`,
      formData
    );

    return response.data;
  } catch (err) {
    // Extract and preserve the actual error message from the API
    console.log("API Error Response:", err.response?.data);
    if (err.response?.data?.error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        apiError: true,
        message: err.response.data.error,
        originalError: err,
      };
    }
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};
