import { Checklist, EditNote, GridOn, LibraryAddCheck, Public, Quiz } from "@mui/icons-material";
import FilterCardBar from "components/FilterCardBar";
import { useAppContextController } from "context/AppContext";
import useBadgeValues from "hooks/useBadgeValues";
import PropTypes from "prop-types";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CommonIconProps = {
  width: "50px",
  height: "50px",
  color: "white",
};

export const initialCards = [
  {
    icon: <Checklist {...CommonIconProps} color="info" fontSize="large" />,
    color: "info",
    title: "Multiple Choice",
    tooltip: "Multiple Choice Assessments",
    number: 0,
    filterField: "type",
    filterValue: "Multiple Choice",
  },
  {
    icon: <EditNote {...CommonIconProps} color="error" fontSize="large" />,
    title: "Fill-In",
    color: "error",
    tooltip: "Fill-In Assessments",
    number: 0,
    filterField: "type",
    filterValue: "Fill-In",
  },
  {
    icon: <LibraryAddCheck {...CommonIconProps} color="success" fontSize="large" />,
    color: "success",
    title: "True/False",
    tooltip: "True/False Assessments",
    number: 0,
    filterField: "type",
    filterValue: "True/False",
  },
  {
    icon: <GridOn {...CommonIconProps} color="success" fontSize="large" />,
    color: "success",
    title: "Excel",
    tooltip: "Excel Assessments",
    number: 0,
    filterField: "type",
    filterValue: "Excel",
  },
  {
    icon: <Quiz {...CommonIconProps} color="secondary" fontSize="large" />,
    color: "secondary",
    title: "Hybrid",
    tooltip: "Hybrid Assessments",
    number: 0,
    filterField: "type",
    filterValue: "Hybrid",
  },
  {
    icon: <Public {...CommonIconProps} color="info" fontSize="large" />,
    title: "All",
    tooltip: "All Jobs",
    number: 0,
    filterField: "type",
    filterValue: "Multiple Choice;Fill-In;True/False;Excel;Hybrid",
    default: true,
  },
];

const AssessmentsFilterCards = ({ setFilters, setPage, filters, initialIndex }) => {
  const { setCurrentAssessment } = useAppContextController();
  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({ initialCards, filters, entity: "assessments", refreshBadges });

  const navigate = useNavigate();
  const { assessmentId } = useParams();

  const setAssessmentsFilters = (filter) => {
    if (assessmentId) navigate(`/assessments`);
    setCurrentAssessment(null);
    setFilters(filter);
    setPage(1);
    setRefreshBadges((prev) => !prev);
  };

  return (
    <span>
      <FilterCardBar
        color="error"
        cards={cards}
        parent="Applicants"
        bgColor="primary"
        setFilters={setAssessmentsFilters}
        setPage={setPage}
        initialIndex={initialIndex}
      />
    </span>
  );
};

export default AssessmentsFilterCards;

AssessmentsFilterCards.defaultProps = {
  setFilters: () => {},
  setPage: () => {},
  initialIndex: 0,
};

// Typechecking props for the TableAction
AssessmentsFilterCards.propTypes = {
  setFilters: PropTypes.func,
  setPage: PropTypes.func,
  initialIndex: PropTypes.number,
  // children: PropTypes.node.isRequired,
};
