import { Card, CircularProgress, Fade, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import useCompanyCache from "hooks/useCompanyCache";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import GenericModal from "components/GenericModal";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  logoImage: {
    objectFit: "contain",
  },
});

const WaitForResumeModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const { isMobile } = useAppContextController();

  const mailAlreadyExistHeader = (
    <>{/* <MDTypography variant="h3">Almost there</MDTypography> */}</>
  );

  const mailAlreadyExistBody = (
    <Box textAlign="center">
      <MDTypography textAlign="center">Please wait while we process your resume...</MDTypography>
      <Box mt={3} width="60%" sx={{ marginLeft: "20%" }}>
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress color="info" />
        </Box>
      </Box>
    </Box>
  );

  return (
    <GenericModal
      open={open}
      setOpen={setOpen}
      header={mailAlreadyExistHeader}
      body={mailAlreadyExistBody}
      buttons={<></>}
      width={isMobile ? "90%" : "50%"}
    />
  );
};

export default WaitForResumeModal;
