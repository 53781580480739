import { useAuth0 } from "@auth0/auth0-react";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import backgroundImage from "assets/images/bg-mascots.png";
import breakpoints from "assets/theme/base/breakpoints";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import { useAppContextController } from "context/AppContext";
import useLocalStorage from "hooks/useLocalStorage";
import { getCompanyImageUrl } from "utils/helpers/upload";
import TrainingVideoModal from "../TrainingVideoModal";

function Header({ children }) {
  const { user } = useAuth0();
  const { userType, currentLoggedUser, company } = useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [banner, setBanner] = useState(null);
  const [openTrainingVideoModal, setOpenTrainingVideoModal] = useState(false);
  const [instructionalVideo, setInstructionalVideo] = useLocalStorage("instructionalVideo", true);

  useEffect(() => {
    if (instructionalVideo && !JSON.parse(sessionStorage.getItem("instructionalVideo"))) {
      setOpenTrainingVideoModal(true);
    } else {
      setOpenTrainingVideoModal(false);
    }
  }, [instructionalVideo]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    if (IMAGE_SERVER && currentLoggedUser?.bannerImg) {
      if (currentLoggedUser.bannerImg?.startsWith("http")) {
        setBanner(currentLoggedUser.bannerImg);
      } else if (currentLoggedUser?._id && currentLoggedUser?.bannerImg) {
        setBanner(
          `${IMAGE_SERVER}/users/${currentLoggedUser._id}/banner/${currentLoggedUser.bannerImg}`
        );
      }
    } else {
      setBanner(backgroundImage);
    }
  }, [currentLoggedUser?.bannerImg, IMAGE_SERVER, currentLoggedUser?._id]);

  return (
    <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.1),
              rgba(gradients.info.state, 0.1)
            )}, url( ${banner} )`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          })}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <PictureAvatar
                image={
                  user?.picture && user.email.indexOf("@gmail.com") > -1
                    ? user.picture
                    : currentLoggedUser?.profileImg
                }
                firstName={currentLoggedUser?.firstName}
                lastName={currentLoggedUser?.lastName}
                userId={currentLoggedUser?._id}
                size="md"
              />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                  {`${currentLoggedUser?.firstName || ""} ${
                    currentLoggedUser?.lastName || ""
                  }'s Dashboard`}
                </MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {currentLoggedUser?.userType || ""}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          {userType === "User" && (
            <Button
              variant="text"
              sx={{ textTransform: "none", fontSize: "1rem", padding: "12px 16px", flexShrink: 0 }}
              size="large"
              endIcon={<MovieFilterIcon />}
              onClick={() => {
                setOpenTrainingVideoModal(true);
              }}
            >
              Watch Instructional Video
            </Button>
          )}
        </Box>
        {children}
      </Card>
      {userType === "User" && (
        <TrainingVideoModal
          openTrainingVideoModal={openTrainingVideoModal}
          setOpenTrainingVideoModal={setOpenTrainingVideoModal}
          instructionalVideo={instructionalVideo}
          setInstructionalVideo={setInstructionalVideo}
        />
      )}
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
