import { useAppContextController } from "context/AppContext";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import { useEffect } from "react";
import { getCompanyImageUrl } from "utils/helpers/upload";
import useSessionAuth from "./useSessionAuth";

function useVenueCache({ venues, setVenues, company, outsideMode = "" }) {
  const { logoutAuth0User } = useSessionAuth();
  const { allVenues, setAllVenues } = useAppContextController();
  useEffect(() => {
    const options = { fetchAll: true };
    const filters = {};
    let allOptions = {
      ...options,
      filters,
      order: "asc",
      orderBy: "slug",
      imageUrlBase: getCompanyImageUrl(company),
    };

    if (outsideMode) {
      allOptions = { ...allOptions, outsideMode };
    }

    if (!venues && !!company && company?.companyType === "Venue") {
      const fetchData = async () => {
        try {
          const data = await fetchVenues(allOptions);
          const objs = {};
          const activeObjs = {};
          data?.data?.forEach((item) => {
            objs[item.slug] = item;
            if (item.status === "Active") activeObjs[item.slug] = item;
          });
          setAllVenues(objs);
          setVenues(activeObjs);
        } catch (error) {
          console.error("Error fetching venues:", error);
          if (!outsideMode) {
            if (String(error).includes("401") || error?.response?.status === 401) {
              logoutAuth0User();
            }
          }
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venues, setVenues, company]);

  return { isLoadingVenues: false, refetchVenues: () => {} };
}

export default useVenueCache;
