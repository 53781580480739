import { Button, Grid, Stack, useMediaQuery } from "@mui/material";
import DataList from "components/DataList";
import MDTypography from "components/MDTypography";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import { baseAxios } from "config";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useSessionAuth from "hooks/useSessionAuth";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";
import fetchUserVenues from "layouts/pages/venues/actions/fetchUserVenues";
import fetchVenues from "layouts/pages/venues/actions/fetchVenues";
import fetchVenuesByZipCode from "layouts/pages/venues/actions/fetchVenuesByZipcode";
import VenuePreviewPanel from "layouts/pages/venues/components/VenuePreviewPanel";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import { getCompanyImageUrl } from "utils/helpers/upload";
import MyVenuesActions from "../MyVenuesActions";

const MyVenues = () => {
  const isLaptop = useMediaQuery("(min-width: 1281px)");
  const {
    setCurrentVenue,
    currentLoggedUser,
    setCurrentLoggedUser,
    userType,
    setVenues,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [myVenues, setMyVenues] = useState([]);
  const [lockedVenues, setLockedVenues] = useState([]);
  const [page, setPage] = useState(1);
  const [isVenuePreviewOpen, toggleVenuePreview] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState({});
  const [userApplicant, setUserApplicant] = useState(null);
  const { logoutAuth0User } = useSessionAuth();

  // const options = { fetchAll: false };
  // const allOptions = { ...options, filters, order: "slug", orderBy: "slug" };

  const options = { fetchAll: true };
  const filters = { status: "Active;Inactive" };

  const allOptions = {
    ...options,
    filters,
    order: "asc",
    orderBy: "slug",
    imageUrlBase: getCompanyImageUrl(company),
  };

  const {
    data: cachedVenues,
    isLoading: isLoadingVenues,
    refetch: refetchVenues,
  } = useQuery(["cachedVenues", allOptions], () => fetchVenues(allOptions));

  const myVenuesMutation = useMutation(async ({ data, id }) => {
    // eslint-disable-next-line
    if (data._id) delete data._id;
    try {
      await baseAxios.put(`/users/${id}`, data);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
    setCurrentLoggedUser({ _id: id, ...currentLoggedUser, ...data });
  });

  const setAndRequery = (venueSlug) => {
    const newVenues = myVenues
      .filter((item) => item.slug !== venueSlug)
      .map((venue) => venue?.slug);
    myVenuesMutation.mutate({ data: { venues: [...newVenues] }, id: currentLoggedUser.userId });
  };

  const sortVenues = (a, b) => {
    if (a.name > b.name) return 1;
    return -1;
  };

  const [refetchApplicant, setRefetchApplicant] = useState(false);
  const refetch = () => {
    setRefetchApplicant((prev) => !prev);
  };

  useEffect(() => {
    let isComponentMounted = true; // Flag to track the component's mounted status

    const setUserVenues = async (appId, employeeType, clientOrgs) => {
      const app = await fetchSingleApplicant({ applicantId: appId });
      setUserApplicant(app.data);
      let venList =
        userType === "User"
          ? app?.data?.venues
              ?.filter((item) => item.venueSlug && item.status !== "Locked")
              ?.map((item) => item.venueSlug)
          : app?.data?.venues?.filter((item) => item.venueSlug)?.map((item) => item.venueSlug);
      const locked = app?.data?.venues
        ?.filter((item) => item.venueSlug && item.status === "Locked")
        .map((item) => item.venueSlug);
      if (locked.length) setLockedVenues(locked);

      if (employeeType === "Event Admin" && clientOrgs?.length) {
        const adminVenList = clientOrgs.map((item) => item.slug);
        venList = Array.from(new Set([...venList, ...adminVenList]));
      }

      if (venList?.length && cachedVenues?.data) {
        const tempList = cachedVenues?.data.filter((venue) => venList.includes(venue.slug)) || [];
        if (userType === "User") {
          setMyVenues(tempList.filter((item) => !item?.slug.toLowerCase().startsWith("sp")));
        } else setMyVenues(tempList);
      }
    };

    if (currentLoggedUser && isComponentMounted) {
      try {
        if (userType === "User") {
          setUserVenues(
            currentLoggedUser.applicantId,
            currentLoggedUser.employeeType,
            currentLoggedUser.clientOrgs
          );
          setIsLoading(false);
        } else if (userType === "Client") {
          const clientVenues = currentLoggedUser?.clientOrgs?.map(({ slug }) => slug) || [];
          if (clientVenues && cachedVenues?.data) {
            const tempList = cachedVenues?.data.filter((venue) =>
              clientVenues.includes(venue.slug)
            );

            setMyVenues(tempList);
          }
          setIsLoading(false);
        } else if (
          userType &&
          userType !== "User" &&
          cachedVenues?.data &&
          Object.keys(cachedVenues?.data)?.length
        ) {
          if (currentLoggedUser.venues && typeof currentLoggedUser.venues !== "string") {
            setMyVenues(
              cachedVenues?.data?.filter((venue) =>
                currentLoggedUser.venues.includes(venue.slug)
              ) || []
            );
          } else setMyVenues([]);
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        setMyVenues([]);
        throw e;
      }
    }
    // Cleanup function to update the mounted flag when the component unmounts
    return () => {
      isComponentMounted = false;
    };
  }, [currentLoggedUser, cachedVenues, refetchApplicant]);

  const navigate = useNavigate();
  const addNew = () => navigate("/venues/create");
  const viewAll = () => navigate(`/venues${userType !== "User" ? "?myVenues=true" : ""}`);

  const columns = [
    {
      title: "Logo",
      field: "logoUrl",
      type: "image",
      imageWidth: "50",
      imageHeight: "50",
      width: "10%",
    },
    {
      title: "Venue ID",
      field: "slug",
      customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      width: "25%",
    },

    { title: "Venue Name", field: "name", width: "30%" },
    { title: "City", field: "city", width: "15%" },
    { title: "State", field: "state", width: "15%" },
  ];

  const userColumns = [
    {
      title: "Status",
      field: "slug",
      customCell: (vslug) => {
        return (
          <ApplicantVenueStatus
            id={userApplicant?._id}
            venue={vslug}
            slugField="venueSlug"
            row={userApplicant}
            // invalidQuery={["venues", allOptions]}
            blockUpdate
            mode="Check"
            setRequery={refetch}
          />
        );
      },
      width: "10%",
    },
    {
      title: "Logo",
      field: "logoUrl",
      imageWidth: "50",
      imageHeight: "50",
      customCell: (logoUrl, row) =>
        row ? (
          <VenueIcon
            logoUrl={logoUrl}
            slug={row?.slug}
            name={row?.name}
            imageWidth="50"
            imageHeight="50"
            setOpenVenueModal={() => {
              setSelectedVenue(row);
              toggleVenuePreview(true);
            }}
          />
        ) : null,
      width: "15%",
    },
    {
      title: "Venue Name",
      field: "name",
      width: "50%",
      customCell: (name) => `${name.length > 20 ? name.substring(0, 20) : name}...`,
    },
    {
      title: "City/State",
      field: "city",
      customCell: (city, row) => `${city}, ${row?.state}`,
      width: "25%",
    },
  ];

  const renderRow = (row) => {
    const venueStatus = getApplicantVenueStatus(userApplicant, row?.slug);

    return (
      <>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={12} md={6} sx={{ display: "flex", gap: "10px" }}>
            <VenueIcon
              logoUrl={row?.logoUrl}
              slug={row?.slug}
              name={row?.name}
              goToEnabled
              setOpenVenueModal={() => {
                setSelectedVenue(row);
                toggleVenuePreview(true);
              }}
            />
            <Grid container>
              <Grid item xs={12}>
                <Stack display="flex" direction="row" alignItems="center">
                  <MDTypography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                    {row?.name}
                  </MDTypography>
                  {row?.status === "Inactive" ? (
                    <MDTypography
                      component="span"
                      variant="subtitle2"
                      color="white"
                      ml={2}
                      sx={{ backgroundColor: "red", fontWeight: "bold", paddingX: "5px" }}
                    >
                      Inactive
                    </MDTypography>
                  ) : null}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="body2">
                  {row?.city}, {row?.state}
                </MDTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <MyVenuesActions
              status={venueStatus}
              isFavorite={() => true}
              setFavorite={setAndRequery}
              slug={row?.slug}
              openVenuePreview={() => {
                setSelectedVenue(row);
                toggleVenuePreview(true);
              }}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const searchBarRowClick = (item) => {
    setCurrentVenue(item);
    navigate(`/venues/${item?.slug}/action/venueinfo`);
  };

  const header = (
    <Stack display="flex" flexDirection="row">
      {userType !== "Client" && (
        <Grid container mt={2} ml={12}>
          <Grid item xs={12}>
            {userType !== "User" ? (
              <Searchbar
                fetch={fetchVenues}
                fetchAll={false}
                placeholder="Search Venue Name/City"
                columns={columns.slice(1, columns.length - 1)}
                queryCharacterLimit={2}
                resultsLimit={10}
                setPage={setPage}
                searchBy={["name", "city"]}
                filterBy="slug"
                onRowClick={(item) => searchBarRowClick(item)}
              />
            ) : (
              <Grid container flexDirection="row" rowSpacing="10px">
                <Grid item xs={12} md={6} mr={0} pr={0}>
                  <Searchbar
                    fetch={fetchUserVenues}
                    fetchAll={false}
                    placeholder="Name/City"
                    columns={userColumns}
                    queryCharacterLimit={2}
                    resultsLimit={12}
                    scrollLimit={50}
                    setPage={setPage}
                    searchBy={["name", "city", "state", "slug"]}
                    filterBy="slug"
                    maxWidth="120px"
                    dropRight
                    filterData={(item) => {
                      return item.status === "Active" && !lockedVenues.includes(item.slug);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Searchbar
                    fetch={fetchVenuesByZipCode}
                    fetchAll={false}
                    placeholder="Zip"
                    columns={userColumns}
                    queryCharacterLimit={5}
                    resultsLimit={12}
                    scrollLimit={50}
                    setPage={setPage}
                    searchBy={["zip"]}
                    useRestParams
                    filterBy="slug"
                    dropRight
                    filterData={(item) => {
                      return item.status === "Active" && !lockedVenues.includes(item.slug);
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} alignSelf="center">
            <Button variant="text" color="primary" onClick={viewAll}>
              View All
            </Button>
            {userType !== "User" && (
              <Button variant="text" color="primary" onClick={addNew}>
                Add New
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Stack>
  );

  return (
    <>
      <DataList
        renderRow={renderRow}
        data={myVenues.sort(sortVenues)}
        icon="stadium"
        iconColor="info"
        title="My Venues"
        keyField="slug"
        isLoading={isLoadingVenues}
        greybar
        divider
        header={header}
        tableHeight="60%"
        height={isLaptop ? 500 : null}
        cardVariant="outlined"
        scrollY
        computedHeight
      />
      <VenuePreviewPanel
        open={isVenuePreviewOpen}
        setOpen={toggleVenuePreview}
        venueId={selectedVenue._id}
      />
    </>
  );
};

export default MyVenues;
