import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Button, Card, Fade, Grid, IconButton, Modal } from "@mui/material";
import checkUserEvent from "api/events/checkUserEvent";
import AddToCalendarButton from "components/AddToCalendarButton";
import CircularLoader from "components/CircularLoader";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import DataTable from "components/DataTable";
import EventDate from "components/EventDate";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import parse from "html-react-parser";
import EventSignupButton from "layouts/pages/events/components/EventsPreviewModal/EventSignupButton";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { APPLICANT_EVENT_STATUS_ENUM } from "utils/constants/applicant";
import { getPositionEndTime, getPositionName, getReportDateTime } from "utils/helpers/events";
import { searchGoogleMaps } from "utils/helpers/googleMaps";
import { getCompanyImageUrl } from "utils/helpers/upload";
import fetchSingleEvent from "../../actions/fetchSingleEvent";
import EventConflict from "./EventConflict";

const EventsPreviewModal = ({
  open,
  setOpen,
  eventPreview,
  setEventPreview,
  refetchEvents,
  content = "all",
}) => {
  const { venues, setVenues, userType, currentLoggedUser, company, setCompany, setCompanyType } =
    useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [eventAdded, setEventAdded] = useState(false);

  useEffect(() => {
    let isMounted = true; // Flag to track mount status

    const asyncOperation = async () => {
      // Perform async operations
      if (isMounted) {
        // Update state or perform actions only if component is still mounted
      }
    };

    asyncOperation();

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, []); // Dependencies array for effect

  const { data: eventConflict, refetch: refetchEventConflict } = useQuery(
    ["checkEnrolledEvent", eventPreview?.eventUrl],
    () =>
      checkUserEvent({
        eventUrl: eventPreview?.eventUrl,
        applicantId: currentLoggedUser.applicantId,
      }),
    {
      enabled: userType === "User" && open,
      onSuccess: () => {
        refetchEvents?.();
      },
    }
  );

  const isAllowed = !(
    eventConflict?.data?.allowed === APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER &&
    eventConflict?.data?.type === APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER
  );

  const message = eventConflict?.data?.message;

  const isAllowedAndNotRoster =
    eventConflict?.data?.allowed === APPLICANT_EVENT_STATUS_ENUM.ROSTER &&
    eventConflict?.data?.type === APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER;

  const isOvertimeWarning =
    eventConflict?.data?.allowed === APPLICANT_EVENT_STATUS_ENUM.NOT_ROSTER &&
    !!eventConflict?.data?.overTimeEventList &&
    !!eventConflict?.data?.overTimeEventList.length;

  const {
    data: event = {},
    isLoading,
    refetch: refetchEvent,
  } = useQuery([eventPreview], () => fetchSingleEvent({ eventId: eventPreview?._id }), {
    enabled: !!eventPreview?._id,
  });

  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (!venues || !Object.keys(venues)?.length) {
      refetchVenues();
    }
  }, [venues]);

  useEffect(() => {
    if (open) {
      refetchEventConflict();
      setEventAdded(false);
      setSelectedRow(null);
    }
  }, [open]);

  useEffect(() => {
    if (event?.positions) {
      const filteredPositionsArray = event?.positions?.filter((position) => {
        const isPublic = position.makePublic === true;
        const applicantsAssignedToPosition = (event?.applicants ?? []).filter(
          (evAppl) => evAppl.primaryPosition === position.positionName
        );
        const maxAvailable = position.numberPositions;
        const slotAvailable =
          maxAvailable == null || maxAvailable > applicantsAssignedToPosition.length;
        return isPublic && slotAvailable;
      });
      if (filteredPositionsArray.some((e) => e?.positionName === "Event Staff")) {
        setFilteredPositions(
          filteredPositionsArray.map((position) => {
            if (position?.positionName === "Event Staff") {
              return { ...position, selectedRow: true };
            }
            return { ...position, selectedRow: false };
          })
        );
      } else if (filteredPositionsArray.length > 0) {
        filteredPositionsArray.unshift({
          endTime: event?.endTime,
          makePublic: true,
          positionName: "Event Staff",
          reportTime: event?.eventDate,
          selectedRow: true,
        });
        setFilteredPositions(filteredPositionsArray);
      } else {
        setFilteredPositions([]);
      }
    }
  }, [open, event]);

  useEffect(() => {
    if (filteredPositions.length > 0) {
      setFilteredPositions(
        filteredPositions.map((position) => {
          if (position?.positionName === selectedRow?.positionName) {
            return { ...position, selectedRow: true };
          }
          return { ...position, selectedRow: false };
        })
      );
    }
  }, [selectedRow]);

  const handleClose = () => setOpen(false);
  const textColor = "#7c97a9";

  const venue = venues?.[eventPreview?.venueSlug];
  const navigate = useNavigate();

  const navigateToEdit = () => {
    navigate(`/clientevents/${event?.eventUrl}/action/eventsinfo`);
    setOpen(false);
  };

  const navigateToRoster = () => {
    navigate(`/clientevents/${event?.eventUrl}/action/eventsroster`);
    setOpen(false);
  };
  const positionColumns = [
    {
      title: "Available Positions",
      field: "positionName",
      customCell: (text) => <MDTypography color="info">{text}</MDTypography>,
    },
    {
      title: "Report Time",
      field: "reportTime",
      customCell: (date) => (date ? <span>{moment(date).format(" hh:mm A")}</span> : ""),
    },
  ];

  const handleRowClick = (row, e, index) => {
    setSelectedRow(row);
  };

  const uniform = event?.attachments?.find((attachment) => {
    return attachment.type === "Uniform Guidelines";
  });
  const parking = event?.attachments?.find((attachment) => {
    return attachment.type === "Employee Parking Map";
  });
  const bannerSource = () => {
    if (company?.companyType === "Company") {
      return `${IMAGE_SERVER}/company/${company?.slug}/backgroundImage/${company?.backgroundImageUrl}`;
    }
    if (event?.venueSlug) {
      return `${venues?.[event?.venueSlug]?.bannerUrl}`;
    }

    return "";
  };

  return (
    <div>
      {(content === "all" || content === "tableAll") && (
        <>
          <Modal
            open={open}
            disableAutoFocus
            onClose={(e, reason) => {
              if (reason === "escapeKeyDown") {
                handleClose();
              }
            }}
          >
            <Fade in={open}>
              <Card
                sx={{
                  position: "absolute",
                  width: "90%",
                  top: "50%",
                  right: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "auto",
                  maxWidth: 1080,
                  background: "none !important",
                  borderColor: "transparent",
                  boxShadow: "none !important",
                }}
              >
                <CircularLoader hasBackdrop={false} isLoading={isLoading} />
                <Box
                  sx={(theme) => ({
                    overflow: "hidden",
                    borderRadius: "20px",
                    padding: "48px 0px",
                    [theme.breakpoints.down("md")]: {
                      padding: "48px 0px",
                    },
                    display: "flex",
                    justifyContent: "center",
                  })}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "200px",
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      zIndex: -1,
                      overflow: "hidden",
                      borderRadius: "5px",
                      "& img": {
                        width: "100%",
                        overflowY: "auto",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      },
                    }}
                  >
                    <img src={bannerSource()} alt={`${event?.eventName} Event Preview`} />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      background: "#ffffff",
                      height: "min-content",
                      borderBottomLeftRadius: 5,
                      borderBottomRightRadius: 5,
                      boxShadow: 24,
                      mt: 18,
                      p: 6,
                      position: "relative",
                      // maxWidth: "980px",
                      width: "100%",
                      [theme.breakpoints.down("md")]: {
                        p: 2.5,
                      },
                    })}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      <IconButton onClick={() => handleClose()}>
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </Box>
                    <Box
                      sx={(theme) => ({
                        maxHeight: eventConflict?.data?.overlappingEvent ? "400px" : "540px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        [theme.breakpoints.down("md")]: {
                          mt: 4,
                        },
                      })}
                    >
                      <Box
                        sx={{
                          borderBottom: `1px solid ${textColor}`,
                          pb: 2,
                        }}
                      >
                        <Grid container spacing={3}>
                          <Grid item sm={12}>
                            <FlexWrapper
                              alignItems={{ xs: "flex-start" }}
                              justifyContent="space-between"
                              sx={(theme) => ({
                                [theme.breakpoints.down("md")]: {
                                  flexDirection: "column",
                                },
                              })}
                            >
                              <Box mr={2} display="flex">
                                <Box mr={2}>
                                  {company?.companyType === "Company" ? (
                                    <CompanyLogoSquare company={event} defaultCompany={company} />
                                  ) : (
                                    <Box>
                                      {IMAGE_SERVER && (
                                        <img
                                          alt="logo"
                                          height="86px"
                                          src={
                                            event?.logoUrl?.startsWith("http")
                                              ? event?.logoUrl
                                              : venues?.[event.venueSlug]?.logoUrl
                                          }
                                        />
                                      )}
                                    </Box>
                                  )}
                                </Box>
                                <MDBox display="flex" sx={{ flexDirection: "column", flexGrow: 1 }}>
                                  <h3 style={{ lineHeight: 1, marginBottom: 5 }}>
                                    {event?.eventName}
                                  </h3>
                                  <h4>{event?.venueName}</h4>
                                </MDBox>
                              </Box>
                              {userType === "Client" ? (
                                <Button
                                  sx={{
                                    marginRight: "10px",
                                  }}
                                  variant="contained"
                                  onClick={navigateToEdit}
                                >
                                  Edit
                                </Button>
                              ) : null}
                            </FlexWrapper>
                            <Grid container>
                              <Grid item sm={6}>
                                <Box
                                  sx={(theme) => ({
                                    color: textColor,
                                    [theme.breakpoints.down("md")]: {
                                      fontSize: "1rem",
                                    },
                                  })}
                                >
                                  <Box display="flex" alignItems="center">
                                    <PersonIcon color="info" sx={{ marginRight: 1 }} />
                                    <MDTypography sx={{ fontSize: "inherit" }}>
                                      {selectedRow
                                        ? `Position: ${selectedRow?.positionName}`
                                        : `Position: ${getPositionName(
                                            event?.applicants,
                                            event?.eventDate,
                                            currentLoggedUser?.applicantId,
                                            userType
                                          )}`}
                                    </MDTypography>
                                  </Box>
                                  <Box display="flex" alignItems="center">
                                    <AccessTimeFilledIcon color="info" sx={{ marginRight: 1 }} />
                                    <MDTypography sx={{ fontSize: "inherit" }}>
                                      {selectedRow ? (
                                        <MDTypography sx={{ fontSize: "inherit" }}>
                                          {event?.reportTimeTBD
                                            ? event.reportTimeTBD
                                            : moment(selectedRow.reportTime).format(
                                                "ddd MMM Do, yyyy hh:mm A"
                                              )}
                                        </MDTypography>
                                      ) : (
                                        <EventDate
                                          date={
                                            event?.eventDate
                                              ? getReportDateTime(
                                                  event?.applicants,
                                                  event?.eventDate,
                                                  currentLoggedUser?.applicantId,
                                                  userType
                                                )
                                              : ""
                                          }
                                          reportTimeTBD={event?.reportTimeTBD}
                                        />
                                      )}
                                    </MDTypography>
                                  </Box>

                                  <Box display="flex" alignItems="center">
                                    <AccessTimeFilledIcon color="info" sx={{ marginRight: 1 }} />

                                    <MDTypography sx={{ fontSize: "inherit" }}>
                                      {selectedRow ? (
                                        <MDTypography sx={{ fontSize: "inherit" }}>
                                          {event?.reportTimeTBD
                                            ? event.reportTimeTBD
                                            : moment(selectedRow.endTime).format(
                                                "ddd MMM Do, yyyy hh:mm A"
                                              )}
                                        </MDTypography>
                                      ) : (
                                        <EventDate
                                          date={
                                            event?.eventEndTime
                                              ? getPositionEndTime(
                                                  event?.applicants,
                                                  event?.eventEndTime,
                                                  currentLoggedUser?.applicantId,
                                                  userType
                                                )
                                              : ""
                                          }
                                          reportTimeTBD={event?.reportTimeTBD}
                                        />
                                      )}
                                    </MDTypography>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={6} alignContent="end">
                                <Box display="flex" alignItems="center">
                                  <AddToCalendarButton event={event} />
                                </Box>
                                <Box>
                                  <Button
                                    startIcon={
                                      <Avatar
                                        src="/images/png/logos/google-maps-icon.jpg"
                                        sx={{ height: "15px", width: "15px", padding: "0" }}
                                      />
                                    }
                                    sx={{
                                      color: "green",
                                      textTransform: "none",
                                      padding: "0",
                                      fontSize: "1.15rem",
                                    }}
                                    onClick={() =>
                                      searchGoogleMaps({
                                        address: event?.address,
                                        city: event?.venueCity,
                                        state: event?.venueState,
                                        zip: event?.zip,
                                      })
                                    }
                                  >
                                    {event?.address}
                                    <br />
                                    {event?.venueCity} {event?.venueState} {event?.zip}
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {filteredPositions.length > 0 &&
                          userType === "User" &&
                          isAllowedAndNotRoster &&
                          !eventAdded && (
                            <DataTable
                              columns={positionColumns}
                              data={{ data: filteredPositions, count: filteredPositions.length }}
                              onRowClick={(row, e) => handleRowClick(row, e)}
                            />
                          )}
                      </Box>
                      <Grid
                        container
                        spacing={1}
                        sx={(theme) => ({
                          justifyContent: "initial",
                          alignItems: "initial",
                          [theme.breakpoints.down("md")]: {
                            justifyContent: "center",
                            alignItems: "center",
                          },
                        })}
                      >
                        <Grid item xs={12}>
                          <Box
                            sx={(theme) => ({
                              mt: 2,
                              width: "100%",
                              [theme.breakpoints.down("md")]: { textAlign: "center" },
                            })}
                          >
                            <Box
                              id="modal-modal-description"
                              sx={(theme) => ({
                                mt: 2,
                                color: textColor,
                                [theme.breakpoints.down("md")]: {
                                  fontSize: "1rem",
                                },
                              })}
                            >
                              {event?.description ? parse(event?.description) : ""}
                            </Box>
                          </Box>
                        </Grid>

                        {event?.eventImage && (
                          <Grid item xs={12} md={6}>
                            <MDBox
                              p={3}
                              sx={(theme) => ({
                                textAlign: "end",
                                [theme.breakpoints.down("md")]: { textAlign: "center" },
                              })}
                            >
                              <img
                                src={`${IMAGE_SERVER}/${event?.venueSlug}/events/${event?.eventUrl}/${event?.eventImage}`}
                                alt={`${event?.eventName}-event`}
                                style={{ width: "100%" }}
                              />
                            </MDBox>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {userType === "User" && (
                      <>
                        {eventConflict?.data?.allowed === APPLICANT_EVENT_STATUS_ENUM.WAITLIST && (
                          <Box>
                            <MDTypography sx={{ fontSize: "16px" }} color="error">
                              Let us know you are available to work this event by adding yourself to
                              our waitlist.
                            </MDTypography>
                          </Box>
                        )}
                        {isOvertimeWarning && (
                          <Box>
                            <MDTypography sx={{ fontSize: "16px" }} color="error">
                              You are not approved for overtime.
                            </MDTypography>
                          </Box>
                        )}
                        {!isAllowed &&
                          !eventConflict?.data?.overlappingEvent &&
                          !isOvertimeWarning && (
                            <Box>
                              <MDTypography sx={{ fontSize: "16px" }} color="error">
                                {message === "Event has already ended."
                                  ? message
                                  : "Event roster and waitlist are full."}
                              </MDTypography>
                            </Box>
                          )}
                        <Grid
                          item
                          xs={12}
                          sx={(theme) => ({
                            [theme.breakpoints.down("md")]: {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            },
                          })}
                        >
                          {eventConflict?.data?.overlappingEvent ? (
                            <EventConflict
                              event={eventConflict?.data?.overlappingEvent || {}}
                              refetch={refetchEventConflict}
                            />
                          ) : (
                            isAllowed && (
                              <EventSignupButton
                                toggleEventModal={setOpen}
                                event={event}
                                setEventPreview={setEventPreview}
                                refetchEvents={refetchEvents}
                                isWaitlist={
                                  eventConflict?.data?.allowed ===
                                  APPLICANT_EVENT_STATUS_ENUM.WAITLIST
                                }
                                filteredPositions={filteredPositions}
                                isAllowed={isAllowed}
                                selectedRow={selectedRow}
                                eventCheckData={eventConflict?.data}
                                setEventAdded={setEventAdded}
                                refetchEvent={refetchEvent}
                              />
                            )
                          )}
                        </Grid>
                      </>
                    )}
                  </Box>
                </Box>
              </Card>
            </Fade>
          </Modal>
        </>
      )}
      {content !== "all" && content !== "tableAll" && (
        <Modal
          open={open}
          disableAutoFocus
          onClose={(e, reason) => {
            if (reason === "escapeKeyDown") {
              handleClose();
            }
          }}
        >
          <Fade in={open}>
            <Card
              sx={{
                position: "absolute",
                width: "90%",
                top: "50%",
                right: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                height: "auto",
                maxWidth: 1080,
                background: "none !important",
                borderColor: "transparent",
                boxShadow: "none !important",
              }}
            >
              <CircularLoader hasBackdrop={false} isLoading={isLoading} />
              <Box
                sx={(theme) => ({
                  overflow: "hidden",
                  borderRadius: "20px",
                  padding: "48px 32px",
                  [theme.breakpoints.down("md")]: {
                    padding: "48px 0px",
                  },
                  display: "flex",
                  justifyContent: "center",
                })}
              >
                <Box
                  sx={(theme) => ({
                    background: "#ffffff",
                    height: "min-content",
                    borderRadius: 5,
                    boxShadow: 24,
                    mt: 6,
                    p: 6,
                    position: "relative",
                    maxWidth: "980px",
                    [theme.breakpoints.down("md")]: {
                      p: 2.5,
                    },
                  })}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                  >
                    <IconButton onClick={() => handleClose()}>
                      <CloseIcon fontSize="large" />
                    </IconButton>
                  </Box>
                  <Box
                    sx={(theme) => ({
                      maxHeight: eventConflict?.data?.overlappingEvent ? "400px" : "540px",
                      overflowY: "auto",
                      overflowX: "hidden",
                      [theme.breakpoints.down("md")]: {
                        mt: 4,
                      },
                    })}
                  >
                    <Box
                      sx={{
                        borderBottom: `1px solid ${textColor}`,
                        pb: 2,
                      }}
                    >
                      <Box sx={{ backgroundColor: "white" }}>
                        <Box
                          id="modal-modal-description"
                          sx={(theme) => ({
                            mt: 2,
                            color: textColor,
                            [theme.breakpoints.down("md")]: {
                              fontSize: "1rem",
                            },
                          })}
                        >
                          {eventPreview?.[content] ? parse(eventPreview?.[content]) : ""}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Fade>
        </Modal>
      )}
    </div>
  );
};

// Setting default values for the props of EventsPreviewModal
EventsPreviewModal.defaultProps = {
  open: false,
  setOpen: () => {},
  eventPreview: {
    eventName: "",
    venueName: null,
    secondaryLocation: "",
    address: "",
    venueCity: "",
    venueState: "",
    zip: "",
    eventId: "",
    eventUrl: "",
    maknePublicAndSendNotification: "No",
    sendConfirmationToSignUps: "No",
    eventDate: null,
    reportDate: null,
    positionsRequested: "",
    eventLogo: "No",
    jobLogo: "No",
    venueBanner: "No",
    googleMap: "No",
    allowPartners: "No",
    allowEarlyClockin: "No",
    enableClockInReminders: "No",
    allowWaitList: "No",
    allowNonHired: "No",
    reminder48Hour: "No",
    reminder24Hour: "No",
    description: "",
    logoUrl: "",
    venueSlug: "",
  },
};

// Typechecking props of the EventsPreviewModal
EventsPreviewModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  eventPreview: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    venueState: PropTypes.string,
    venueCity: PropTypes.string,
    googleMap: PropTypes.string,
    venueName: PropTypes.string,
    venueHelmID: PropTypes.string,
    venueLogoUrl: PropTypes.string,
  }),
};

export default EventsPreviewModal;
