import { useAppContextController } from "context/AppContext";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui material components
import {
  Autocomplete,
  Button,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import moment from "moment";

// NewProduct page components
import { FolderCopy, Quiz, Settings } from "@mui/icons-material";
import { makeStyles, styled } from "@mui/styles";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import SplitPanelSelector from "components/SplitPanelSelector";
import useSessionAuth from "hooks/useSessionAuth";
import FormField from "layouts/applications/wizard/components/FormField";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import { isEmpty, isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { getDirtyFields } from "utils/helpers/formHelpers";
import useVenueCache from "hooks/useVenueCache";
import createAssessment from "../../actions/createAssessment";
import deleteAssessment from "../../actions/deleteAssessment";
import fetchSingleAssessment from "../../actions/fetchSingleAssessment";
import updateAssessment from "../../actions/updateAssessment";

const useStyle = makeStyles({
  logoImage: {
    objectFit: "contain",
  },
  previewButton: {
    padding: 0,
  },
  editor: {
    height: 115,
  },
  error: {
    fontSize: "0.75rem",
  },
});

function AssessmentsInformation({
  mode = "edit",
  open,
  setOpen,
  setAssessmentPreview,
  setRefetchAssessments,
  selectedPanel = "",
  onPanelSelect = () => null,
}) {
  const {
    currentAssessment,
    setCurrentAssessment,
    currentLoggedUser,
    venues,
    setVenues,
    userType,
    company,
    isCurrentFormDirty,
    setPanelChangesOpen,
    setChangePanel,
    isMobile,
  } = useAppContextController();
  const navigate = useNavigate();
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const [disableSlug, setDisableSlug] = useState(true);
  const [editorValue, setEditorValue] = useState(`<p></p>`);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [notificationRecipientsModalOpen, setNotificationRecipientsModalOpen] = useState(false);
  const [copyAssessmentName, setCopyAssessmentName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const copyAssessmentId = searchParams.get("copyAssessmentId");
  const jobSlug = searchParams.get("jobSlug");
  const { logoutAuth0User } = useSessionAuth();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isEdit = !!currentAssessment?._id;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDiscardModal, setOpenDiscardModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // Confirm Modal
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });

  const [deleteConfirmModal, setDeleteConfirmModal] = useState({
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const resetDeleteConfirmModal = () => {
    setDeleteConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const defaultValues = {
    name: "",
    type: "",
    jobSlug: "",
  };

  const handlePanelChange = (panel) => {
    if (isCurrentFormDirty) {
      setPanelChangesOpen(true);
      setChangePanel(() => onPanelSelect(panel));
    } else {
      onPanelSelect(panel);
    }
  };

  const panelOptions = [
    {
      title: "Assessment Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "content" ? "emd" : "",
      color: "primary",
      ariaLabel: "assessment content",
      fn: () => handlePanelChange("content"),
      name: "content",
    },
    {
      title: "Assessment Questions",
      icon: <Quiz />,
      hasShadow: selectedPanel === "questions" ? "emd" : "",
      color: "violet",
      ariaLabel: "questions",
      fn: () => handlePanelChange("questions"),
      name: "questions",
    },
    {
      title: "Assessment Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "assessment settings",
      fn: () => handlePanelChange("settings"),
      name: "settings",
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    control,
    watch,
    getValues,
    trigger,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({
    mode: "onBlur",
    defaultValues: mode === "edit" ? currentAssessment : defaultValues,
  });

  const allJobsOptions = {
    fetchAll: true,
    filters: { status: "Active" },
    projectedFields: [
      "jobSlug",
      "title",
      "companySlug",
      "companyName",
      "description",
      "venueSlug",
      "venueName",
    ],
    venues,
    companyType: company?.companyType,
  };

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery(["jobs", allJobsOptions], () => fetchJobs(allJobsOptions));

  const jobsOptions = useMemo(() => {
    if (!jobs == null || jobs?.data == null) return null;
    if (!jobs?.data?.length) return [];

    return jobs.data.map((jb) => ({
      label: `${
        company?.companyType === "Company"
          ? jb.companyName ?? jb.companySlug
          : jb.venueName ?? jb.venueSlug
      } - ${jb.title}`,
      value: jb.jobSlug,
    }));
  }, [company?.companyType, jobs]);

  const type = { ...register("type", { required: "Type is required" }) };
  const job = { ...register("jobSlug", {}) };

  const name = {
    ...register("name", {
      required: "Name is required",
      validate: {
        repeated: (value) => /^[a-zA-Z\s]+$/.test(value) || "Invalid Assessment Name",
      },
    }),
  };

  const queryClient = useQueryClient();

  const deleteAssessmentMutation = useMutation(deleteAssessment, {
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("assessments");
      setCurrentAssessment(null);
      enqueueSnackbar("Assessment was archived", { variant: "success" });
      navigate(`/assessments`);
    },
  });

  const updateAssessmentMutation = useMutation(updateAssessment, {
    onError: (err) =>
      enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("assessments");
      setCurrentAssessment({ ...currentAssessment, ...data });
      enqueueSnackbar("Assessment has been updated!", { variant: "success" });
    },
  });

  const createAssessmentMutation = useMutation(createAssessment, {
    onError: (err) =>
      enqueueSnackbar(`Something went wrong!  ${err.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("assessments");
      enqueueSnackbar("Assessment has been created!", { variant: "success" });
      setCurrentAssessment({
        ...currentAssessment,
        ...data,
        _id: _?.data?._id,
      });
      navigate(`/assessments/${_?.data?._id}`);
    },
  });

  const classes = useStyle();

  const openModal = () => {
    setAssessmentPreview(currentAssessment);
    setOpen(!open);
  };

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const getAssessmentValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = currentAssessment?.[key] || "";
    });
    return values;
  };

  const cancelChanges = () => {
    if (mode === "edit") {
      reset(getAssessmentValues());
      setEditorValue(currentAssessment.description);
    } else {
      const data = {};
      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentAssessment(data);
      reset({ ...defaultValues });
      setEditorValue(defaultValues.description);
      navigate("/assessments");
    }

    setIsModified(false);
    enqueueSnackbar("Changes have been abandoned", { variant: "success" });
  };

  const saveChanges = async (values) => {
    if (!currentAssessment._id) {
      enqueueSnackbar("Cannot Update Assessment -- No ID Present!", { variant: "success" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);

    if (modifiedFields.jobSlug) {
      const div = document.createElement("div");
      div.innerHTML = currentAssessment.description ?? "";
      const plainDescription = div.innerText;
      if (!plainDescription.length) {
        // A job has been selected, copy the job description to the assessment
        // if no description is available
        const selectedJob = jobs?.data?.find((jb) => jb.jobSlug === modifiedFields.jobSlug);
        if (selectedJob) {
          modifiedFields.description = selectedJob.description;
        }
      }
    }

    if (modifiedFields.jobSlug !== undefined && !modifiedFields.jobSlug) {
      modifiedFields.notifyJobRecipients = "No";
    }

    await updateAssessmentMutation.mutateAsync({
      _id: currentAssessment._id,
      data: { ...modifiedFields },
    });
    setRefetchAssessments((prev) => !prev);
  };

  const createAssessmentHandler = async (values) => {
    const newAssessmentValues = { ...values };
    if (copyAssessmentId) newAssessmentValues.copyAssessmentId = copyAssessmentId;

    if (newAssessmentValues.jobSlug) {
      const div = document.createElement("div");
      div.innerHTML = newAssessmentValues.description ?? "";
      const plainDescription = div.innerText;
      if (!plainDescription.length) {
        // A job has been selected, copy the job description to the assessment
        // if no description is available
        const selectedJob = jobs?.data?.find((jb) => jb.jobSlug === newAssessmentValues.jobSlug);
        if (selectedJob) {
          newAssessmentValues.description = selectedJob.description;
        }
      }
    }

    try {
      await createAssessmentMutation.mutateAsync({
        data: {
          ...newAssessmentValues,
          payRate: parseFloat(values.payRate),
          createAgent: currentLoggedUser._id,
        },
      });
    } catch (e) {
      //
    }
    setRefetchAssessments((prev) => !prev);
  };

  const onCancel = () => {
    setIsModified(false);
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confrim to abandon Changes",
      response: false,
    });
  };

  const addNotificationRecipients = () => {
    setNotificationRecipientsModalOpen(true);
  };

  const onDelete = () => {
    setDeleteConfirmModal({
      isOpen: true,
      title: "Delete Assessment",
      bodyText: "Please confirm you want to DELETE this Assessment!",
      response: false,
    });
  };

  const copyAssessment = () => {
    navigate(`/assessments/create?copyAssessmentId=${currentAssessment?._id}`);
  };

  const fetchCopyAssessment = async () => {
    try {
      const hasAssessmentId = await fetchSingleAssessment(copyAssessmentId);
      const oldAssessment = hasAssessmentId?.data?.data;
      if (oldAssessment) {
        setCopyAssessmentName(oldAssessment?.name);
        oldAssessment.name = "";
        oldAssessment.assessmentId = "";

        delete oldAssessment?._id;
        delete oldAssessment?.assessmentId;

        reset(oldAssessment);
        setCurrentAssessment(oldAssessment);
        setEditorValue(oldAssessment.description);
        setIsModified(false);
      }
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };

  const deleteAssessmentHandler = async () => {
    await deleteAssessmentMutation.mutateAsync({
      _id: currentAssessment._id,
    });
  };

  const deleteModalHandler = () => setOpenDeleteModal(!openDeleteModal);
  const discardModalHandler = () => setOpenDiscardModal(!openDiscardModal);
  const cancelModalHandler = () => setOpenCancelModal(!openCancelModal);

  useEffect(() => {
    if (!copyAssessmentId) return;
    fetchCopyAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyAssessmentId]);

  const removeRecipient = (idx) => {
    const temporaryRecipients = getValues()?.notificationRecipients;
    temporaryRecipients.splice(idx, 1);
    setValue("notificationRecipients", temporaryRecipients, { shouldDirty: true });
    if (mode !== "create") setReminderToSaveChangesModalOpen(true);
  };
  const notificationRecipientsRenderRow = (row, idx) => {
    return (
      <>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          onClick={() => {
            setNotificationRecipientsModalOpen(true);
          }}
        >
          <Grid container>
            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>{row.fullName}</MDTypography>
            </Grid>

            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>E-Mail: {row.email}</MDTypography>
            </Grid>

            <Grid item sm={1}>
              <MDBox textAlign="right">
                <Icon
                  color="error"
                  fontSize="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeRecipient(idx);
                  }}
                >
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    if (currentAssessment) {
      reset(currentAssessment, { keepDirty: false, keepDirtyValues: false });
      Object.keys(watch()).forEach((key) => {
        setValue(key, currentAssessment?.[key] || "");
      });
      setEditorValue(currentAssessment.description);
      setIsModified(false);
    }
  }, [currentAssessment]);

  useEffect(() => {
    if (mode === "edit") {
      if (currentAssessment) {
        Object.keys(watch())?.forEach((key) => {
          setValue(key, currentAssessment[key] || "");
        });
        setEditorValue(currentAssessment.description);
        setIsModified(false);
      }

      const subscription = watch((value) => {
        if (currentAssessment) {
          setIsModified(JSON.stringify(getAssessmentValues()) !== JSON.stringify(value));
        }
      });

      // Executes on modal response!
      if (confirmModal.response) {
        resetConfirmModal();
        cancelChanges();
      }
      return () => subscription.unsubscribe();
    }
    return null;
  }, [watch, currentAssessment, confirmModal.response]);
  // Executes on modal response!
  useEffect(() => {
    if (confirmModal.response) {
      resetConfirmModal();
      cancelChanges();
    }
  }, [confirmModal.response]);

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};
      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });

      setCurrentAssessment(data);
      reset({ ...defaultValues });
      setEditorValue(defaultValues.description);
    } else {
      //      setSlugDisable(true)
    }
  }, [mode]);

  useEffect(() => {
    const del = async () => {
      await updateAssessmentMutation.mutateAsync({
        _id: currentAssessment._id,
        data: { status: "Deleted", deleteAgent: currentLoggedUser._id },
      });
    };
    if (deleteConfirmModal.response) {
      resetDeleteConfirmModal();
      del();
    }
  }, [deleteConfirmModal.response]);

  useEffect(() => {
    if (mode === "create" && jobSlug) {
      setTimeout(() => {
        setValue("jobSlug", jobSlug);
        navigate("/assessments/create", { replace: true });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobSlug, mode]);

  const icon = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="4rem"
      height="4rem"
      variant="gradient"
      bgColor={mode === "create" ? "warning" : "white"}
      color="white"
      borderRadius="xl"
    >
      {mode === "create" ? (
        <Icon fontSize="large">work</Icon>
      ) : (
        <CompanyLogoSquare defaultCompany={company} />
      )}
    </MDBox>
  );

  return (
    <CustomCard
      icon={<CompanyLogoSquare company={company} />}
      iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "-1.5rem" }}
      cardSx={{ width: "100%", height: 850, overflow: "visible !important", p: 0 }}
    >
      <MDBox mt={!isSmallScreen && -9}>
        <form onSubmit={handleSubmit(mode === "edit" ? saveChanges : createAssessmentHandler)}>
          <FlexWrapper
            maxWidth="100%"
            justifyContent="flex-end"
            alignItems="center"
            minHeight={38}
            mt={4}
            mb={1}
          >
            <MDBox px={isEdit ? 0 : 3} pt={isEdit ? 0 : 1}>
              {!isEdit && (
                <>
                  <Button
                    variant="text"
                    startIcon={<CancelIcon />}
                    onClick={cancelModalHandler}
                    disabled={isSubmitting}
                    style={{ color: "#F44335", fontSize: 14 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white", fontSize: 14 }}
                    disabled={isSubmitting}
                  >
                    Create
                  </Button>
                </>
              )}
              {isEmpty(dirtyFields) && (
                <>
                  {isEdit && isEqual(userType, "Master") && (
                    <Button
                      variant="text"
                      startIcon={<DeleteIcon />}
                      onClick={deleteModalHandler}
                      style={{ color: "#F44335", fontSize: 14 }}
                      disabled={isSubmitting}
                    >
                      Delete Assessment
                    </Button>
                  )}
                  {isEdit && (
                    <Button
                      variant="text"
                      startIcon={<ContentCopyIcon />}
                      onClick={() =>
                        navigate(`/assessments/create?copyAssessmentId=${currentAssessment?._id}`)
                      }
                      disabled={isSubmitting}
                      style={{ fontSize: 14 }}
                    >
                      Copy Assessment
                    </Button>
                  )}
                </>
              )}
              {!isEmpty(dirtyFields) && (
                <>
                  {isEdit && isEqual(userType, "Master") && (
                    <Button
                      variant="text"
                      startIcon={<DeleteIcon />}
                      onClick={discardModalHandler}
                      style={{ color: "#F44335", fontSize: 14 }}
                      disabled={isSubmitting}
                    >
                      Discard
                    </Button>
                  )}
                  {isEdit && (
                    <Button
                      variant="text"
                      type="submit"
                      style={{ color: "#4CAF50", fontSize: 14 }}
                      startIcon={<SaveIcon />}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  )}
                </>
              )}
            </MDBox>
          </FlexWrapper>

          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ px: "1.5rem", mb: 2 }}
          >
            <Grid item xs={12} sm={6}>
              <Stack alignItems="flex-start">
                <MDTypography sx={{ mr: 2 }} variant="h5" color="dark">
                  {mode === "create"
                    ? `Create Assessment ${
                        copyAssessmentId ? `from Duplicate ${copyAssessmentName}` : ""
                      }`
                    : `Edit Assessment: ${currentAssessment?.name}`}
                </MDTypography>
                {isEdit && (
                  <MDTypography variant="body2">
                    Last Update:{" "}
                    {moment(currentAssessment?.updatedAt).format("ddd MM/DD/YYYY hh:mm a")}
                  </MDTypography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <Stack direction="row" alignItems="center" flexWrap="wrap">
                  <SplitPanelSelector
                    panelOptions={panelOptions}
                    selectedPanel={selectedPanel}
                    isEdit={isEdit}
                  />
                </Stack>
              </MDBox>
            </Grid>
          </Grid>

          {/* </FlexWrapper> */}
          <StyledContainer px={5} pb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                  Assessment Information
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <FormField
                      type="text"
                      disabled
                      label="Assessment Name"
                      {...field}
                      value={field.value || ""}
                    />
                  )}
                />
                {errors?.name && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.name.message}
                  </MDTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  {...type}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={["Multiple Choice", "Fill-In", "True/False", "Excel", "Hybrid"]}
                      disabled={mode === "edit" && currentAssessment?.questions?.length > 0}
                      inputProps={mode === "edit" ? { tabIndex: -1 } : {}}
                      tabIndex="-1"
                      name="type"
                      value={field.value || ""}
                      onChange={(e, v) => {
                        field.onChange(e.target.textContent);
                      }}
                      renderInput={(params) => (
                        <FormField
                          key={`type_${currentAssessment?._id}`}
                          {...params}
                          type="text"
                          label="Type"
                        />
                      )}
                    />
                  )}
                />
                {errors?.type && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.type.message}
                  </MDTypography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  {...job}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={jobsOptions ?? []}
                      inputProps={mode === "edit" ? { tabIndex: -1 } : {}}
                      tabIndex="-1"
                      getOptionLabel={(option) => option?.label || ""}
                      isOptionEqualToValue={(option, value) => option.value === value?.value}
                      name="jobSlug"
                      value={
                        jobsOptions?.find((opt) => opt.value === field.value) ||
                        (field.value
                          ? {
                              label: field.value,
                              value: field.value,
                            }
                          : null)
                      }
                      onChange={(_, value) => {
                        field.onChange(value ? value.value : null);
                      }}
                      renderInput={(params) => (
                        <FormField
                          key={`jobSlug_${currentAssessment?._id}`}
                          {...params}
                          type="text"
                          label="Job"
                        />
                      )}
                    />
                  )}
                />
                {errors?.jobSlug && (
                  <MDTypography className={classes.error} color="error">
                    {errors?.jobSlug.message}
                  </MDTypography>
                )}
              </Grid>
            </Grid>
          </StyledContainer>
        </form>
      </MDBox>
      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}

      {openCancelModal && (
        <ConfirmModal
          title="You are leaving create mode"
          description="Please confirm you want to abandon new assessment."
          isOpen={openCancelModal}
          negativeBtn={{
            label: "Cancel",
            fn: cancelModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              setCurrentAssessment({});
              navigate("/assessments");
              cancelModalHandler();
            },
            isShown: true,
          }}
        />
      )}
      {openDiscardModal && (
        <ConfirmModal
          title="Data has Changed"
          description="Please confirm you want to abandon changes."
          isOpen={openDiscardModal}
          negativeBtn={{
            label: "Cancel",
            fn: discardModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: () => {
              reset(currentAssessment);
              navigate(`/assessments/${currentAssessment?._id}`);
              discardModalHandler();
            },
            isShown: true,
          }}
        />
      )}
      {openDeleteModal && (
        <ConfirmModal
          title="Delete Assessment"
          description="Please confirm you want to DELETE this assessment."
          isOpen={openDeleteModal}
          negativeBtn={{
            label: "Cancel",
            fn: deleteModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deleteAssessmentHandler,
            isShown: true,
          }}
        />
      )}
    </CustomCard>
  );
}

export default AssessmentsInformation;

// Setting default values for the props of BaseLayout
AssessmentsInformation.defaultProps = {};

// Typechecking props for BaseLayout
AssessmentsInformation.propTypes = {};

const StyledContainer = styled(MDBox)(() => ({
  height: 660,
  overflowY: "auto",
}));
