import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import fetchUsers from "layouts/pages/profile/actions/fetchUsers";

import { Autocomplete, Box, FormHelperText, Grid, Stack, Switch } from "@mui/material";
import { Controller } from "react-hook-form";
import CustomTextField from "components/Form/CustomTextField";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import FormField from "layouts/applications/wizard/components/FormField";
import useCompanyStore from "stores/useCompanyStore";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";
import useDropdowns from "hooks/useDropdowns";
import { PatternFormat } from "react-number-format";
import { useAppContextController } from "context/AppContext";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableCheckbox from "./SortableCheckbox";

const {
  WORKWEEK_ENTITY_NAME,
  OVERTIME_WARNINGS_ENTITY_NAME,
  CLOCKOUT_REMINDERS_ENTITY_NAME,
  APPROVAL_REMINDERS_ENTITY_NAME,
  GEO_PREFERENCE_ENTITY_NAME,
  STATES_ENTITY_NAME,
  PAY_FREQUENCY,
  GROUP_IDS,
  WC_CODES,
  BACKGROUND_CHECK_VENDOR,
} = COMPANY_ENTITIES;

const minStageToOnboardingOptions = ["New", "ATC", "Screened", "Pre-Hire"];

const dashboardPanelIdToLabel = (company) => [
  company?.companyType === "Company" ? "My Customers" : "My Venues",
  company?.companyType === "Company" ? "My Interviews" : "My Events",
  "My Jobs",
  "My Tasks",
  "My Activities",
  "My Reports",
  "My Notifications",
];

const defaultDashboardPanelOrder = (company) => [
  {
    id: 0,
    label: dashboardPanelIdToLabel(company)[0],
    enabled: true,
  },
  {
    id: 1,
    label: dashboardPanelIdToLabel(company)[1],
    enabled: true,
  },
  {
    id: 2,
    label: dashboardPanelIdToLabel(company)[2],
    enabled: true,
  },
  {
    id: 3,
    label: dashboardPanelIdToLabel(company)[3],
    enabled: true,
  },
  {
    id: 4,
    label: dashboardPanelIdToLabel(company)[4],
    enabled: false,
  },
  {
    id: 5,
    label: dashboardPanelIdToLabel(company)[5],
    enabled: false,
  },
  {
    id: 6,
    label: dashboardPanelIdToLabel(company)[6],
    enabled: false,
  },
];

const InputFlexWrapper = ({ children, ...props }) => (
  <Grid item xs={12} sm={6}>
    {children}
  </Grid>
);

const TitleFlexWrapper = ({ children, ...props }) => (
  <FlexWrapper maxWidth={{ lg: "47%", sm: "100%" }} marginBottom={2.5} {...props}>
    {children}
  </FlexWrapper>
);

const CompanyConfiguration = ({
  control,
  sourceComponent,
  setValue,
  trigger,
  errors,
  watch,
  onBlur = () => {}, // trigger("accountManager")
}) => {
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const { company } = useAppContextController();

  const { dropdowns: workweek } = useDropdowns({ entity: WORKWEEK_ENTITY_NAME });
  const { dropdowns: overtimeWarnings } = useDropdowns({ entity: OVERTIME_WARNINGS_ENTITY_NAME });
  const { dropdowns: clockoutReminders } = useDropdowns({ entity: CLOCKOUT_REMINDERS_ENTITY_NAME });
  const { dropdowns: geoPreferences } = useDropdowns({ entity: GEO_PREFERENCE_ENTITY_NAME });
  const { dropdowns: approvalReminders } = useDropdowns({ entity: APPROVAL_REMINDERS_ENTITY_NAME });
  const { dropdowns: states } = useDropdowns({ entity: STATES_ENTITY_NAME });
  const { dropdowns: payFrequencyValues } = useDropdowns({ entity: PAY_FREQUENCY });
  const { dropdowns: groupIds } = useDropdowns({ entity: GROUP_IDS });
  const { dropdowns: wcCodes } = useDropdowns({ entity: WC_CODES });
  const { dropdowns: backgroundCheckVendors } = useDropdowns({ entity: BACKGROUND_CHECK_VENDOR });

  const dashboardCardsFromCompany = watch("dashboardPanelOrder");

  const dashboardCards = useMemo(() => {
    if (dashboardCardsFromCompany == null) return defaultDashboardPanelOrder(company);

    const addedItems = {};
    let currentItems = [];

    for (let index = 0; index < dashboardCardsFromCompany.length; index += 1) {
      const dashboardCard = dashboardCardsFromCompany[index];

      if (
        dashboardCard.id != null &&
        dashboardCard.enabled != null &&
        dashboardCard.order != null &&
        !!dashboardPanelIdToLabel(company)[dashboardCard.id] &&
        !addedItems[dashboardCard.id.toString()]
      ) {
        addedItems[dashboardCard.id.toString()] = true;
        currentItems.push({
          ...dashboardCard,
          label: dashboardPanelIdToLabel(company)[dashboardCard.id],
        });
      }
    }

    if (currentItems.length !== 7) {
      // Something is wrong with the data. Return defaults
      return defaultDashboardPanelOrder(company);
    }

    currentItems = currentItems.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order === b.order) {
        return 0;
      }
      return 1;
    });

    return currentItems;
  }, [company, dashboardCardsFromCompany]);

  // const [dashboardCards, setDashboardCards] = useState(normalizedDashboardCards);

  const enabledDashboardCards = useMemo(
    () => dashboardCards.filter((c) => c.enabled),
    [dashboardCards]
  );
  const disabledDashboardCards = useMemo(
    () => dashboardCards.filter((c) => !c.enabled),
    [dashboardCards]
  );

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = dashboardCards.findIndex((field) => field.id === active.id);
      const newIndex = dashboardCards.findIndex((field) => field.id === over.id);

      const reorderedFields = arrayMove(dashboardCards, oldIndex, newIndex);
      setValue(
        "dashboardPanelOrder",
        reorderedFields.map((field, index) => ({
          id: field.id,
          enabled: field.enabled,
          order: index,
          label: dashboardPanelIdToLabel(company)[field.id],
        })),
        { shouldDirty: true }
      );
    }
  };

  const handleToggleEnabled = (e, id) => {
    const newItems = dashboardCards.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          enabled: e.target.checked,
        };
      }
      return item;
    });
    setValue(
      "dashboardPanelOrder",
      newItems.map((field, index) => ({
        id: field.id,
        enabled: field.enabled,
        order: index,
        label: dashboardPanelIdToLabel(company)[field.id],
      })),
      { shouldDirty: true }
    );
  };

  const [usersNames, setUsersNames] = useState();

  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((user) => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
          }))
      );
    }
  }, [users]);

  return (
    <>
      <TitleFlexWrapper
        justifyContent="flex-start"
        alignSelf="flex-start"
        maxWidth="100%"
        minHeight={38}
      >
        <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
          {sourceComponent} Configuration
        </MDTypography>
      </TitleFlexWrapper>
      <Grid container spacing={2}>
        {sourceComponent === "Company" && (
          <>
            <InputFlexWrapper>
              <Controller
                name="companyUrl"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomTextField
                    label="URL"
                    placeholder="URL"
                    showError
                    errorMessage={error?.message}
                    {...field}
                  />
                )}
              />
            </InputFlexWrapper>
            <InputFlexWrapper>
              <Controller
                name="accountManager"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    key={`autocomplete_recruiter_${currentCompany?.id}`}
                    options={usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []}
                    autoSelect
                    name="accountManager"
                    defaultValue={null}
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("accountManager", v?.label ?? null, { shouldDirty: true });
                    }}
                    sx={{ width: "100%" }}
                    onBlur={onBlur}
                    renderInput={(params) => (
                      <>
                        <FormField
                          key={`accountManager_${currentCompany?.id}`}
                          {...params}
                          type="text"
                          label="Account Manager"
                          // InputLabelProps={{ shrink: !isEmpty(getValues("recruiter")) }}
                        />
                        <FormHelperText error>{error?.message}</FormHelperText>
                      </>
                    )}
                  />
                )}
              />
            </InputFlexWrapper>
          </>
        )}

        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.workWeek"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={workweek || []}
                name="timeClockSettings.workWeek"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.workWeek", v, { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Work Week"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.overtimeWarning"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={overtimeWarnings || []}
                name="timeClockSettings.overtimeWarning"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.overtimeWarning", v, { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Overtime Warning"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.clockOutReminders"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={clockoutReminders || []}
                name="timeClockSettings.clockOutReminders"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.clockOutReminders", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Clock Out Reminders"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="timeClockSettings.approvalReminders"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={approvalReminders || []}
                name="timeClockSettings.approvalReminders"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("timeClockSettings.approvalReminders", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Approval Reminders"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="backgroundCheckInfo.vendor"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={backgroundCheckVendors || []}
                name="backgroundCheckInfo.vendor"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("backgroundCheckInfo.vendor", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Background Check Vendor"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={2}
          >
            <MDTypography variant="body2" textTransform="capitalize">
              Background check enabled
            </MDTypography>
            <Controller
              name="backgroundCheckInfo.enabled"
              control={control}
              render={({ field }) => (
                <Switch
                  disabled={
                    !watch("backgroundCheckInfo.vendor") ||
                    watch("backgroundCheckInfo.vendor") === "None"
                  }
                  checked={field.value === "Yes"}
                  onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                />
              )}
            />
          </Stack>
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="minStageToOnboarding"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={minStageToOnboardingOptions}
                name="minStageToOnboarding"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("minStageToOnboarding", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Minimum Stage To Start Onboarding"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        {company?.companyType === "Company" ? (
          <>
            <InputFlexWrapper>
              <Controller
                name="geoPreference"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    options={geoPreferences || []}
                    name="geoPreference"
                    autoSelect
                    defaultValue={null}
                    value={field.value || ""}
                    onChange={(e, v) => {
                      setValue("geoPreference", v, {
                        shouldDirty: true,
                      });
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        type="text"
                        label="Default Job Search Geo Preference"
                        showError
                        errorMessage={error?.message}
                      />
                    )}
                  />
                )}
              />
            </InputFlexWrapper>
          </>
        ) : null}
        <Grid item xs={12}>
          <MDTypography mt={1} mb={2} variant="p" fontSize="18px" color="info" fontWeight="bold">
            Deposit Options
          </MDTypography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="depositOptions.directDeposit"
                control={control}
                defaultValue="No"
                render={({ field }) => (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Switch
                      checked={field.value === "Yes"}
                      onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                    />
                    <MDTypography variant="body2">Direct Deposit</MDTypography>
                  </Stack>
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="depositOptions.moneyNetworkService"
                control={control}
                defaultValue="No"
                render={({ field }) => (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Switch
                      checked={field.value === "Yes"}
                      onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                    />
                    <MDTypography variant="body2">Money Network Service</MDTypography>
                  </Stack>
                )}
              />
            </Grid>
            {company?.companyType === "Venue" && (
              <Grid item xs={6}>
                <Controller
                  name="depositOptions.branchVirtualWallet"
                  control={control}
                  defaultValue="No"
                  render={({ field }) => (
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Switch
                        checked={field.value === "Yes"}
                        onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                      />
                      <MDTypography variant="body2">Branch Virtual Wallet</MDTypography>
                    </Stack>
                  )}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <Controller
                name="depositOptions.employerIssuedPaperCheck"
                control={control}
                defaultValue="No"
                render={({ field }) => (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Switch
                      checked={field.value === "Yes"}
                      onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                    />
                    <MDTypography variant="body2">Paper Check Option</MDTypography>
                  </Stack>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mb={2}>
            <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
              Employment Configuration
            </MDTypography>
          </Box>
        </Grid>
        <InputFlexWrapper>
          <Controller
            name="fein"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <PatternFormat
                format="##-###-####"
                allowEmptyFormatting
                mask="_"
                label="FEIN"
                value={field.value || ""}
                defaultValue=""
                onValueChange={({ value }) => setValue("fein", value, { shouldDirty: true })}
                onBlur={() => trigger("fein")}
                showError
                errorMessage={error?.message}
                customInput={CustomTextField}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="wcState"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={states || []}
                name="wcState"
                autoSelect
                defaultValue={currentCompany ? currentCompany.state : null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("wcState", v?.toUpperCase() || "", { shouldDirty: true });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="WC State"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="groupId"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={groupIds || []}
                name="groupId"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("groupId", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Marketing Group ID"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="wcCode"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={wcCodes || []}
                name="wcCode"
                autoSelect
                defaultValue={null}
                value={field.value || ""}
                onChange={(e, v) => {
                  setValue("wcCode", v, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="WC Code"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <Controller
            name="payFrequency"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                options={payFrequencyValues || []}
                name="payFrequency"
                autoSelect
                defaultValue={null}
                getOptionLabel={(option) => option.label || option}
                value={
                  field.value
                    ? payFrequencyValues?.find((option) => option.value === field.value)
                    : null
                }
                onChange={(e, v) => {
                  setValue("payFrequency", v?.value ?? null, {
                    shouldDirty: true,
                  });
                }}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    type="text"
                    label="Pay Frequency"
                    showError
                    errorMessage={error?.message}
                  />
                )}
              />
            )}
          />
        </InputFlexWrapper>
        {sourceComponent === "Company" && (
          <>
            <Grid item xs={12}>
              <Box mb={2}>
                <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
                  Dashboard Configuration
                </MDTypography>
              </Box>
            </Grid>
            <Grid item xs={12} mt={-2}>
              <Stack container direction="row" alignItems="center">
                <Controller
                  name="dashboardJobStatusFullscreen"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      checked={field.value === "Yes"}
                      onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                      inputProps={{ "aria-label": "" }}
                    />
                  )}
                />
                <MDTypography variant="body2">
                  Open Job Status in Fullscreen by Default
                </MDTypography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext
                  items={dashboardCards.map((field) => field.id)}
                  strategy={rectSortingStrategy}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      paddingBottom: 2,
                      zIndex: 10,
                    }}
                  >
                    {enabledDashboardCards.map((field) => (
                      <SortableCheckbox
                        key={field.id}
                        field={field}
                        handleToggleEnabled={handleToggleEnabled}
                      />
                    ))}
                  </Grid>
                </SortableContext>
              </DndContext>
              <Box
                sx={{
                  marginBottom: 2,
                  borderBottomStyle: "solid",
                  borderBottomWidth: 1,
                  borderBottomColor: "lightgray",
                }}
              />
              <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext
                  items={dashboardCards.map((field) => field.id)}
                  strategy={rectSortingStrategy}
                >
                  <Grid container spacing={2}>
                    {disabledDashboardCards.map((field) => (
                      <SortableCheckbox
                        key={field.id}
                        field={field}
                        disableSort
                        handleToggleEnabled={handleToggleEnabled}
                        disabled={field.id === 5}
                      />
                    ))}
                  </Grid>
                </SortableContext>
              </DndContext>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default CompanyConfiguration;
