import {
  AddCircleRounded,
  ContentPasteSearch,
  Description,
  Try,
  TryOutlined,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import ApplicantPhoneButton from "components/ApplicantPhoneButton";
import ApplicantStageButton from "components/ApplicantStageButton";
import JobStatusButton from "components/JobStatusButton";
import { useAppContextController } from "context/AppContext";
import UploadFileModal from "layouts/pages/applicants/components/UploadFileModal";
import { SnackbarProvider } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApplicantJobStatus } from "utils/helpers/applicant";
import JobStatusMoreButton from "../JobStatusMoreButton";

const JobsStatusRowActions = ({
  applicant,
  refresh,
  onAddToJob,
  onChangeStatus,
  onOpenResumeModal,
  isJobFull,
  isSearchingAll,
  onTouchJob,
  onOpenAIQuestionsModal,
  onOpenAssessmentModal,
  onOpenAssessmentAssignModal,
  currentVenue,
}) => {
  const { company } = useAppContextController();
  const { currentJob } = useAppContextController();
  const [toastAlert, setToastAlert] = useState(false);
  const navigate = useNavigate();
  const [isOpenAttachmentModal, setIsOpenAttachmentModal] = useState(false);
  const shouldShowAIInterviewHistory = applicant.aiRank;
  const shouldShowAssessment = applicant.assessmentRank != null;

  const aiInterviewsEnabled =
    company?.aiInterviewsAvailable === "Yes" &&
    currentJob?.aiSettings?.aiInterviewsEnabled === "Yes" &&
    company?.aiSettings?.aiInterviewsEnabled === "Yes" &&
    currentVenue?.aiSettings?.aiInterviewsEnabled === "Yes";

  const aiInterviewsDisabledForStatus =
    !shouldShowAIInterviewHistory &&
    ((applicant.applicantStatus !== "New" && applicant.applicantStatus !== "ATC") ||
      applicant.status !== "Applicant");

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={1} justifyContent="center">
          {isSearchingAll && applicant.hasNotApplied ? (
            <>
              {/* Filler */}
              {aiInterviewsEnabled && (
                <Grid item>
                  <IconButton
                    sx={{ paddingLeft: 0, paddingRight: 0, opacity: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    disabled
                  >
                    <AddCircleRounded fontSize="large" color="success" />
                  </IconButton>
                </Grid>
              )}
              <Grid item>
                <IconButton
                  sx={{ paddingLeft: 0, paddingRight: 0, opacity: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  disabled
                >
                  <AddCircleRounded fontSize="large" color="success" />
                </IconButton>
              </Grid>
            </>
          ) : null}
          <Grid item>
            <Tooltip
              title={
                applicant?.finalResumeText
                  ? `Resume:\n${(applicant.finalResumeText ?? "").slice(0, 200)}${
                      applicant.finalResumeText.length > 200 ? "..." : ""
                    }`
                  : "No resume available"
              }
            >
              <IconButton
                sx={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={
                  applicant?.finalResumeText
                    ? (e) => {
                        e.stopPropagation();
                        onOpenResumeModal(
                          `${applicant?.firstName} ${applicant?.lastName}`,
                          applicant.finalResumeText
                        );
                      }
                    : () => {}
                }
              >
                <Description
                  fontSize="large"
                  color={applicant?.finalResumeText ? "warning" : "secondary"}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <ApplicantPhoneButton applicant={applicant} />
          </Grid>
          <Grid item>
            <Tooltip
              title={shouldShowAssessment ? "Latest Assessment Result" : "No Assessment Taken"}
            >
              <span>
                <IconButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={() => onOpenAssessmentModal(applicant, applicant.lastAssessment)}
                  disabled={!shouldShowAssessment}
                >
                  <ContentPasteSearch
                    fontSize="large"
                    color={shouldShowAssessment ? "info" : "secondary"}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
          {applicant?.hasNotApplied || !aiInterviewsEnabled ? null : (
            <Grid item>
              <Tooltip
                title={
                  shouldShowAIInterviewHistory
                    ? "AI Screening Result"
                    : "AI Screening Resume Questions"
                }
              >
                <span>
                  <IconButton
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={() => onOpenAIQuestionsModal(applicant, shouldShowAIInterviewHistory)}
                    disabled={aiInterviewsDisabledForStatus}
                  >
                    {shouldShowAIInterviewHistory ? (
                      <TryOutlined fontSize="large" color="violet" />
                    ) : (
                      <Try
                        fontSize="large"
                        color={aiInterviewsDisabledForStatus ? "secondary" : "violet"}
                      />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
          {applicant?.hasNotApplied ? null : (
            <Grid item>
              <ApplicantStageButton
                sx={{ paddingLeft: 0, paddingRight: 0 }}
                applicant={applicant}
                job={currentJob}
                refresh={refresh}
                onChangeStatus={onChangeStatus}
                parent="jobs"
              />
            </Grid>
          )}
          {applicant?.hasNotApplied ? null : (
            <Grid item>
              <JobStatusButton
                applicant={applicant}
                status={getApplicantJobStatus(applicant, currentJob?.jobSlug)}
                job={currentJob}
                refresh={refresh}
                parent="jobStatus"
              />
            </Grid>
          )}
          {applicant?.hasNotApplied ? (
            <>
              <Grid item>
                <Tooltip title={`Add ${applicant?.firstName} ${applicant?.lastName} to job`}>
                  <IconButton
                    sx={{ paddingLeft: 0, paddingRight: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddToJob({
                        applicantId: applicant._id,
                        jobSlug: currentJob?.jobSlug,
                        score: applicant.rank,
                      });
                    }}
                  >
                    <AddCircleRounded fontSize="large" color="success" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </>
          ) : null}
          <Grid item>
            <JobStatusMoreButton
              applicant={applicant}
              toastAlert={toastAlert}
              setToastAlert={setToastAlert}
              onTouchJob={onTouchJob}
              onOpenAssessmentAssignModal={onOpenAssessmentAssignModal}
            />
          </Grid>
        </Grid>
      </SnackbarProvider>
      <UploadFileModal
        currentApplicant={applicant}
        setCurrentApplicant={() => {}}
        open={isOpenAttachmentModal}
        setOpen={setIsOpenAttachmentModal}
      />
    </>
  );
};

export default JobsStatusRowActions;
