import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";

const SnackbarActionsHelmOverwrite = ({ snackbarId, handleSyncNow }) => {
  const { closeSnackbar } = useSnackbar();

  const syncNow = () => {
    handleSyncNow();
    closeSnackbar(snackbarId);
  };

  return (
    <>
      <Typography
        fontSize="small"
        fontWeight="bold"
        color="success.main"
        sx={{ mx: 1, "&:hover": { cursor: "pointer" } }}
        onClick={() => {
          syncNow();
        }}
      >
        Overwrite
      </Typography>
      <Typography
        fontSize="small"
        fontWeight="bold"
        color="error"
        sx={{ mx: 1, "&:hover": { cursor: "pointer" } }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        Cancel
      </Typography>
    </>
  );
};

export default SnackbarActionsHelmOverwrite;
