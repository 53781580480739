import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { CallMerge, DoDisturbAlt, FileDownload, Lock, LockOpen, Mail } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Box, Button, Card, Grid, Icon, IconButton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import TagSearchAndToggle from "components/TagSearchAndToggle";
import VenueIcon from "components/VenueIcon";
import VenueStatusButton from "components/VenueStatusButton";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useAppContextController } from "context/AppContext";
import fetchCustomers from "api/companies/fetchCustomers";
import useCompanyCache from "hooks/useCompanyCache";
import useVenueCache from "hooks/useVenueCache";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import fetchEmployeesAdvanced from "layouts/pages/employees/actions/fetchEmployeesAdvanced";
import fetchEmployeesOnly from "layouts/pages/employees/actions/fetchEmployeesOnly";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import NoTagSelectedModal from "layouts/pages/applicants/components/NoTagSelectedModal";
import NoResultsModal from "layouts/pages/applicants/components/NoResultsModal";
import { BulkMessagingModal } from "layouts/pages/events/components/BulkMessagingModal";
import { isEmpty } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { getApplicantVenueStatus } from "utils/helpers/applicant";
import useSort from "utils/useSort";
import useSearchbarStore from "stores/useSearchbarStore";
import AdvancedSearchModal from "layouts/pages/applicants/components/AdvancedSearchModal";
// maybe change to employee?
import DuplicateApplicantModal from "layouts/pages/applicants/components/DuplicateApplicantModal";
import applicantEnableMerge from "api/applicants/applicantEnableMerge";
import useSessionAuth from "hooks/useSessionAuth";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";
import {
  generateEmployeeChartReport,
  generateEmployeeTableReport,
} from "api/reports/employeeReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import ReportModal from "components/ReportModal";
import ExamDetailsModal from "components/AssessmentResultModal";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import EmployeeFlags from "layouts/pages/employees/components/EmployeesTable/EmployeeFlags";
import ApplicantsTableAssessmentActions from "layouts/pages/applicants/components/ApplicantsTableAssessmentActions";
import JobsStatusAIInterviewModal from "components/JobsStatusAIInterviewModal";
import ApplicantResumeTextModal from "layouts/pages/applicants/components/ApplicantResumeTextModal";
import EmployeesTableActions from "../EmployeesTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const EmployeesTable = ({
  fetchAll = false,
  title = "Title",
  filters,
  pageSize = 50,
  page,
  setPage,
  setFilters,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  venueSlug = null,
  showEventStatus = false,
  setSelectedId,
  queryName = "employees",
  venueId,
  parent = "employees",
  useOrTags,
  setUseOrTags,
  status = "Employee",
  staffingPoolCards = [],
  setToastAlert,
  parentPage,
  parentNavigateWithBack,
}) => {
  const queryClient = useQueryClient();
  const { searchResults, setSearchResults } = useSearchbarStore((state) => ({
    setSearchResults: state.setSearchResults,
    searchResults: state.searchResults,
  }));
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const classes = useStyle();
  const {
    currentApplicant,
    setCurrentApplicant,
    currentVenue,
    venues,
    setVenues,
    allVenues,
    userType,
    company,
    setCompany,
    setCompanyType,
    currentLoggedUser,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoadingVenues, refetchVenues } = useVenueCache({ venues, setVenues, company });
  const [selectedCardNumber, setSelectedCardNumber] = useState(0);
  const [modalInfo, setModalInfo] = useState({ data: currentApplicant });
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [openNoTagModal, setOpenNoTagModal] = useState(false);
  const [messageContext, setMessageContext] = useState("Applicant");
  const [openNoResultsModal, setOpenNoResultsModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
  const [searchTagList, setSearchTagList] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);
  // Pagination state
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(pageSize);
  const { order, orderBy, toggleSort } = useSort("lastName", "asc");
  const [selectedTags, setSelectedTags] = useState("");
  const [mergeApplicantData, setMergeApplicantData] = useState([]);
  const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);

  // Handle back button from parent screens
  const { navigateWithBack, navigateWithoutBack } = useHandleBackButtonSubGrid({
    parentPage,
    setPage,
    page,
    filters,
    navigateFunc: setNavigateUrl,
    navigateWithBackFunc: parentNavigateWithBack,
  });

  const { mutateAsync: createEmployeeTableReport } = useMutation(generateEmployeeTableReport);
  const { mutateAsync: createEmployeeChartReport } = useMutation(generateEmployeeChartReport);
  const { mutateAsync: exportEmployeeReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveEmployeeReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const isCompany = company?.companyType === "Company";
  const options = useMemo(
    () => (fetchAll ? { fetchAll } : { page, limit, order, orderBy }),
    [fetchAll, limit, order, orderBy, page]
  );
  const optionsForBulk = fetchAll ? { fetchAll } : { page, limit: null, order, orderBy };

  const enableMergeMutation = useMutation(applicantEnableMerge);
  const { enqueueSnackbar } = useSnackbar();
  const [searchRefetchKey, setSearchRefetchKey] = useState(0);

  const refetchSearch = () => setSearchRefetchKey(Math.random());

  // test this

  // let allOptions;
  // if (filters.filt === "All") {
  //   allOptions = {
  //     ...options,
  //     filters: { status: "Employee;Inactive", ...filters },
  //     useOrTags,
  //   };
  // } else if (filters.loginVerified) {
  //   allOptions = {
  //     ...options,
  //     filters: {
  //       status: "Employee;Inactive",
  //       ...filters,
  //       loginVerified: filters.loginVerified,
  //     },
  //     useOrTags,
  //   };
  // } else if (filters?.status) {
  //   allOptions = {
  //     ...options,
  //     filters: {
  //       ...filters,
  //       status: filters?.status,
  //     },
  //     useOrTags,
  //   };
  // } else if ("isAvailable" in filters) {
  //   allOptions = {
  //     ...options,
  //     filters: {
  //       ...filters,
  //       isAvailable: filters?.isAvailable,
  //     },
  //     useOrTags,
  //   };
  // }

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);
  const [selectedSubtables, setSelectedSubtables] = useState([]);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const { logoutAuth0User } = useSessionAuth();

  const handleLoadInitialEmployeeReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const response = await fetchModuleReports("employees");

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData({ ...response.tableReportData, feature: "employees" });

          if (response.tableReportData.criteria?.selectedSubtables) {
            setSelectedSubtables(response.tableReportData.criteria?.selectedSubtables);
          }
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);

          if (response.chartReportData.criteria?.selectedSubtables) {
            setSelectedSubtables(response.chartReportData.criteria?.selectedSubtables);
          }
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialEmployeeReport();
  }, [handleLoadInitialEmployeeReport]);

  const allOptions = useMemo(
    () => ({
      ...options,
      filters: { status, ...filters, ...selectedTags },
      useOrTags,
    }),
    [filters, options, selectedTags, status, useOrTags]
  );

  const allOptionsForBulk = {
    ...optionsForBulk,
    filters: { status, ...filters, ...selectedTags },
    useOrTags,
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchEmployees,
  } = useQuery(["employee_table", queryName, allOptions, searchQuery], () => {
    const allOptionsCopy = { ...allOptions, filters: { ...allOptions.filters } };
    // If we're filtering by ID, overwrite status filter so it can find the user regardless of status
    if (allOptionsCopy?.filters?._id != null && allOptionsCopy?.filters?.status != null) {
      allOptionsCopy.filters.status = "Employee;Partner;Leader";
    }

    if (searchQuery) {
      return fetchEmployeesAdvanced({ ...allOptionsCopy, searchQuery });
    }
    if (filters) {
      if (queryName === "staffingpool") return fetchStaffingPool(allOptionsCopy);
    }
    return fetchEmployees(allOptionsCopy);
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    refetch: refetchCompanies,
  } = useQuery(
    ["employee_table_companies", { filters: { primaryCompany: false }, page: 1, limit: 0 }],
    () => fetchCustomers({ filters: { primaryCompany: false }, page: 1, limit: 0 }),
    {
      enabled: company?.companyType !== "Venue",
      select: (data) => data?.data ?? [],
    }
  );

  const {
    data: applicantsForBulk,
    isLoading: isLoadingForBulk,
    refetch: refetchEmployeeForBulk,
  } = useQuery(
    ["employeesForBulkMessaging", queryName, allOptionsForBulk, searchQuery],
    () => {
      if (searchQuery) {
        return fetchEmployeesAdvanced({ ...allOptionsForBulk, searchQuery });
      }
      if (filters) {
        if (queryName === "staffingpool") return fetchStaffingPool(allOptionsForBulk);
      }
      return fetchEmployees(allOptionsForBulk);
    },
    {
      enabled: isBulkMessageModalOpen,
    }
  );

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportEmployeeReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Employee ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportEmployeeReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      if (values?.selectedSubtables?.length > 0) {
        reportPayload = { ...reportPayload, selectedSubtables: values?.selectedSubtables };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      if (values?.subFilterCards) {
        reportPayload = {
          ...reportPayload,
          subFilterCards: values?.subFilterCards,
        };
      }

      try {
        await createEmployeeTableReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartTableSrc(response?.iframe_url);
              setTableQueryDetails(response?.queryDetails);
              setTableReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Employee Table Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
        selectedSubtables: values?.selectedSubtables || [],
      };

      if (values?.chartFilters) {
        reportPayload = {
          ...reportPayload,
          chartFilters: values?.chartFilters,
        };
      }

      if (values?.chartDateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.chartStartDate, // ISO formatted date already handled
            end: values?.chartEdDate, // ISO formatted date already handled
            field: values?.chartDateField,
            range: values?.chartDateRange,
          },
        };
      }

      try {
        await createEmployeeChartReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Employee Chart Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const formatSearchResults = (searchResult) => {
    const formatted = searchResult?.map((v, index) => {
      if (v.status === "Deleted") return v;
      if (v.doNotMerge === "Yes") return { ...v, mergeStatus: "ignore" };
      const result = searchResult.find((val, idx) => {
        return (
          val.employeeID === v.employeeID &&
          v._id !== val._id &&
          val.doNotMerge !== "Yes" &&
          val.status !== "Deleted"
        );
      });
      if (result) {
        return { ...v, mergeStatus: "merge" };
      }
      return v;
    });
    return formatted;
  };

  const handleSaveEmployeeReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        const tableData = {
          tableReportData: { ...tableReportData, feature: "employees" },
          feature: "employees",
        };
        const chartData = {
          chartReportData: { ...chartReportData, feature: "employees" },
          feature: "employees",
        };

        if (values?.formType === "table") {
          payload = { ...payload, ...tableData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, ...chartData, formType: "chart" };
        }

        await saveEmployeeReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Employee ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving employee report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveEmployeeReport, tableReportData]
  );

  const openMergeModal = useCallback(
    (row) => {
      const updatedRow = { ...row };
      const duplicateApplicants = searchResults.filter(
        (v) =>
          row.employeeID === v.employeeID &&
          v._id !== row._id &&
          v.mergeStatus !== "ignore" &&
          v.status !== "Deleted"
      );
      updatedRow.mergeStatus = "primary";
      setMergeApplicantData([updatedRow, ...duplicateApplicants]);
      setDuplicateModalOpen(true);
    },
    [searchResults]
  );

  const removeIgnoredFlag = (e, row) => {
    e.stopPropagation();
    const params = {
      id: row._id,
      agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
      createAgent: currentLoggedUser._id,
    };
    enableMergeMutation.mutate(params, {
      onSuccess: (result) => {
        if (result && result.data.success) {
          enqueueSnackbar(`Ignore flag removed successfully`, { variant: "success" });
          refetchSearch();
        } else {
          enqueueSnackbar(`Something went wrong`, { variant: "error" });
        }
      },
      onError: () => {
        enqueueSnackbar(`Something went wrong`, { variant: "error" });
      },
    });
  };

  const [applicantResumeText, setApplicantResumeText] = useState(null);
  const [aiScreeningResultModalSelected, setAIScreeningResultModalSelected] = useState(null);
  const [assessmentResultModalSelected, setAssessmentResultModalSelected] = useState(null);

  const handleCloseResumeModal = () => {
    setApplicantResumeText(null);
  };

  const handleOpenResumeModal = (applicantName, text) => {
    setApplicantResumeText({ applicantName, text });
  };

  const handleCloseAIScreeningResultModal = () => {
    setAIScreeningResultModalSelected(null);
  };

  const handleOpenAIScreeningResultModal = (applicant) => {
    setAIScreeningResultModalSelected(applicant);
  };

  const handleCloseAssessmentResultModal = () => {
    setAssessmentResultModalSelected(null);
  };

  const handleOpenAssessmentResultModal = (applicant, assessment) => {
    setAssessmentResultModalSelected({ applicantId: applicant?._id, assessment, applicant });
  };

  useEffect(() => {
    if (company?.companyType === "Venue" && (!venues || Object.keys(venues).length))
      refetchVenues();
  }, [venues, company?.companyType]);

  useEffect(() => {
    if (applicants?.data?.length && applicants.data.length === 1) {
      //      setSelectedId(applicants.data[0]._id);
      setCurrentApplicant(applicants.data[0]);
    }
  }, [applicants?.data]);

  useEffect(() => {
    if (action && applicants?.data?.length) {
      const [setApplicantAction] = applicants?.data?.filter((item) => item?._id === id);
      setCurrentApplicant(setApplicantAction);
    }
  }, [action, applicants?.data?.length]);

  const columns = useMemo(
    () => [
      ...(company?.companyType === "Company"
        ? [
            {
              title: "Flag",
              field: "recordFlagged",
              width: 15,
              customCell: (flagged, field, row) => <EmployeeFlags flagged={flagged} row={row} />,
            },
          ]
        : [
            {
              title: "Avatar",
              field: "profileImg",
              width: 15,
              customCell: (image, field, row) => (
                <PictureAvatar
                  image={row?.profileImg}
                  firstName={row?.firstName}
                  lastName={row?.lastName}
                  userId={row?.userRecordId ? row.userRecordId : null}
                  applicantId={row?.userRecordId ? null : row?._id}
                  size="md"
                />
              ),
            },
            {
              title: "Flag",
              field: "recordFlagged",
              width: 15,
              customCell: (flagged, field, row) => <EmployeeFlags flagged={flagged} row={row} />,
            },
            {
              title: "Locked",
              field: "recordLocked",
              width: 15,
              customCell: (recordLocked) => (
                <Box pl={0.75}>
                  {recordLocked === "Yes" ? (
                    <Lock fontSize="large" color="error" />
                  ) : (
                    <LockOpen fontSize="large" color="success" />
                  )}
                </Box>
              ),
            },
          ]),
      // {
      //   title: "Merge",
      //   field: "mergeStatus",
      //   width: "10%",
      //   customCell: (value, row) => {
      //     if (value === "merge") {
      //       return (
      //         <IconButton
      //           onClick={(e) => {
      //             e.stopPropagation();
      //             openMergeModal(row);
      //           }}
      //         >
      //           <CallMerge fontSize="medium" color="info" />
      //         </IconButton>
      //       );
      //     }
      //     return "";
      //   },
      // },
      { title: "Type", field: "status" },
      { title: "Employment Status", field: "employmentStatus" },
      ...(venueSlug && currentVenue
        ? [
            {
              title: "Venue Status",
              field: "slug",
              customCell: (_id, field, row) => {
                return (
                  <VenueStatusButton
                    status={getApplicantVenueStatus(row, venueSlug)}
                    venue={venues?.[venueSlug] || {}}
                    onUpdate={() => queryClient.invalidateQueries(["applicants", allOptions])}
                    applicant={row}
                  />
                );
              },
            },
          ]
        : []),

      {
        title: "Last Name",
        field: "lastName",
        customCell: (name, field, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (name, field, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      ...(filters?.status !== "Partner"
        ? [
            // {
            //   title: "Birthdate",
            //   field: "birthDate",
            //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
            // },
            // {
            //   title: "Hire Date",
            //   field: "hireDate",
            //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
            // },
            ...(company?.companyType === "Venue"
              ? [
                  { title: "Login", field: "loginVerified" },
                  {
                    title: "Venue",
                    field: "venues",
                    customCell: (vens, field, row) => {
                      if (!vens || !vens.length) return "";
                      const { venueSlug: vslug } = vens?.[0];
                      if (!allVenues) return "";
                      const venue = allVenues[vslug];
                      return (
                        <>
                          <VenueIcon
                            logoUrl={venue?.logoUrl}
                            slug={vslug}
                            name={venue?.name}
                            imageWidth="50"
                            imageHeight="50"
                          />
                        </>
                      );
                    },
                  },
                  { title: "Rank", field: "rank" },
                ]
              : [
                  {
                    title: "Customer",
                    field: "companies",
                    customCell: (comps, field, row) => {
                      if (!comps || !comps.length) return "";
                      let foundComp = comps?.find((c) => c.status === "On Assignment");
                      if (!foundComp) foundComp = comps?.[comps.length - 1];
                      const cslug = foundComp?.companySlug;
                      if (!cslug) return "";

                      if (company?.companyType === "Company") {
                        const existingCompany = (companies ?? []).find(
                          (comp) => comp.slug === cslug
                        );
                        return (
                          <CompanyLogoSquare company={existingCompany} defaultCompany={company} />
                        );
                      }
                      return (
                        <span>
                          {cslug}
                          {/* <VenueIcon
                    logoUrl={venue?.logoUrl}
                    slug={vslug}
                    name={venue?.name}
                    imageWidth="50"
                    imageHeight="50"
                  /> */}
                        </span>
                      );
                    },
                  },
                ]),
            { title: "Phone", field: "phone" },
            {
              title: "Source",
              field: "referralSource",
              customCell: (source, field, row) => source || row?.systemSource || "",
            },
            {
              title: "Apply Date",
              field: "applicationDate",
              customCell: (appDate, field, row) => {
                let date = null;
                const { applicationDate } = row;
                if (applicationDate && moment(applicationDate).isValid()) {
                  if (applicationDate.length <= 16) {
                    // Bad format, only includes date
                    date = moment(applicationDate).add(12, "hours");
                  } else {
                    date = moment(applicationDate);
                  }
                } else if (row.createdDate) {
                  date = moment(row.createdDate);
                }
                return date ? (
                  <Tooltip title={date.format("YYYY-MM-DD hh:mm a")}>
                    <span>{date.format("YYYY-MM-DD")}</span>
                  </Tooltip>
                ) : (
                  ""
                );
              },
            },
          ]
        : [
            { title: "Partner", field: "partnerSlug" },
            { title: "Helm ID", field: "employeeID" },
          ]),
      {
        title: "Extra Info",
        field: "_id",
        customCell: (rowId, field, row) => (
          <ApplicantsTableAssessmentActions
            id={rowId}
            row={row}
            onOpenResumeModal={handleOpenResumeModal}
            onOpenLastAssessmentResultModal={handleOpenAssessmentResultModal}
            onOpenLastAIScreeningResultModal={handleOpenAIScreeningResultModal}
          />
        ),
      },
      {
        title: "Applicant Actions",
        field: "_id",
        customCell: (rowId, field, row) => (
          <EmployeesTableActions
            id={row?._id}
            row={row}
            setCurrentApplicant={setCurrentApplicant}
            setModalInfo={setModalInfo}
            setPage={setPage}
            setNavigateUrl={navigateWithBack}
            setGenericModalOpen={setGenericModalOpen}
            company={company}
            onRefresh={refetchEmployees}
          />
        ),
      },
    ],
    [
      company,
      venueSlug,
      currentVenue,
      filters?.status,
      venues,
      queryClient,
      allOptions,
      allVenues,
      companies,
      setCurrentApplicant,
      setPage,
      navigateWithBack,
      refetchEmployees,
    ]
  );

  const searchColumns = [
    ...(company?.companyType === "Venue"
      ? [
          {
            width: 50,
            title: "Avatar",
            field: "profileImg",
            customCell: (image, row) => {
              return (
                <PictureAvatar
                  image={row?.profileImg}
                  firstName={row?.firstName}
                  lastName={row?.lastName}
                  userId={row?.userRecordId ? row.userRecordId : null}
                  applicantId={row?.userRecordId ? null : row?._id}
                  size="md"
                />
              );
            },
          },
        ]
      : []),
    {
      title: "Flag",
      field: "recordFlagged",
      width: 15,
      customCell: (flagged, row) => <EmployeeFlags flagged={flagged} row={row} />,
    },
    {
      title: "Merge",
      field: "mergeStatus",
      width: 50,
      customCell: (value, row) => {
        if (value === "merge") {
          return (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                openMergeModal(row);
              }}
            >
              <CallMerge fontSize="medium" color="info" />
            </IconButton>
          );
        }
        if (value === "ignore") {
          return (
            <IconButton
              onClick={(e) => {
                removeIgnoredFlag(e, row);
              }}
            >
              <DoDisturbAlt fontSize="medium" color="error" />
            </IconButton>
          );
        }
        return "";
      },
    },
    ...(company?.companyType === "Venue"
      ? [
          {
            title: "Locked",
            field: "recordLocked",
            width: 50,
            customCell: (recordLocked) => (
              <Box pl={1.25}>
                {recordLocked === "Yes" ? (
                  <Lock fontSize="medium" color="error" />
                ) : (
                  <LockOpen fontSize="medium" color="success" />
                )}
              </Box>
            ),
          },
        ]
      : []),
    { width: 120, title: "Type", field: "status" },
    ...(venueSlug && currentVenue
      ? [
          {
            title: "Venue Status",
            field: "slug",
            customCell: (_id, row) => {
              return (
                <VenueStatusButton
                  status={getApplicantVenueStatus(row, venueSlug)}
                  venue={venues?.[venueSlug] || {}}
                  onUpdate={() => queryClient.invalidateQueries(["applicants", allOptions])}
                  applicant={row}
                />
              );
            },
          },
        ]
      : []),

    {
      width: 140,
      title: "Last Name",
      field: "lastName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    {
      width: 140,
      title: "First Name",
      field: "firstName",
      customCell: (name, row) =>
        row ? (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            <div>{name}</div>
          </CustomWidthTooltip>
        ) : null,
    },
    ...(filters?.status !== "Partner" && company?.companyType === "Venue"
      ? [
          // {
          //   title: "Birthdate",
          //   field: "birthDate",
          //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
          // },
          // {
          //   title: "Hire Date",
          //   field: "hireDate",
          //   customCell: (date) => date ? moment(date).format("YYYY-MM-DD") : '',
          // },
          { width: 40, title: "Login", field: "loginVerified" },
          {
            title: "Venue",
            width: 50,
            field: "venues",
            customCell: (vens, row) => {
              if (!vens || !vens.length) return "";
              const { venueSlug: vslug } = vens?.[0];
              if (!allVenues) return "";
              const venue = allVenues[vslug];
              return (
                <>
                  <VenueIcon
                    logoUrl={venue?.logoUrl}
                    slug={vslug}
                    name={venue?.name}
                    imageWidth="50"
                    imageHeight="50"
                  />
                </>
              );
            },
          },
          { width: 20, title: "Rank", field: "rank" },
        ]
      : []),
    {
      title: "SSN-4",
      field: "socialSecurity",
      width: 50,
      customCell: (ssn) => {
        if (!ssn) return "";
        const numString = ssn.toString();
        const last4Digits = numString.slice(-4);
        return last4Digits;
      },
    },
    { title: "Helm ID", field: "employeeID", width: 50 },
    { width: 160, title: "Phone", field: "phone" },
    // {
    //   width: 80,
    //   title: "Source",
    //   field: "referralSource",
    //   customCell: (source, row) => source || row?.systemSource || "",
    // },
    {
      width: 120,
      title: "Apply Date",
      field: "applicationDate",
      customCell: (appDate, row) => {
        let date = null;
        const { applicationDate } = row;
        if (applicationDate && moment(applicationDate).isValid()) {
          if (applicationDate.length <= 16) {
            // Bad format, only includes date
            date = moment(applicationDate).add(12, "hours");
          } else {
            date = moment(applicationDate);
          }
        } else if (row.createdDate) {
          date = moment(row.createdDate);
        }
        return date ? date.format("YYYY-MM-DD") : "";
      },
    },
  ];

  const handleRowClick = (applicant, e) => {
    e.stopPropagation();
    setCurrentApplicant({ ...applicant });
    setNavigateUrl(
      `/employees/${applicant._id}${navigateToAction ? `/action/${navigateToAction}` : ""}`
    );
  };

  const addNew = (e) => {
    if (!isEmpty(currentApplicant)) setCurrentApplicant({});
    setNavigateUrl("/employees/create");
    e.stopPropagation();
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  const clearTagsHandler = () => {
    const tempFilters = filters;
    delete tempFilters.tags;
    setFilters(tempFilters);
    setSearchTagList([]);
    setUseOrTags(false);
  };

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  // useEffect(() => {
  //   if (JSON.stringify(filters) !== "{}" && searchTagList.length > 0) {
  //     setSearchTagList([]);
  //   }
  // }, [filters]);
  // useEffect(() => {
  //   if (searchTagList.length > 0 && JSON.stringify(filters) !== "{}") {
  //     setFilters({});
  //   }
  // }, [searchTagList]);

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
      setSelectedSubtables([]);
    }
  }, [openReportMessageModal]);

  const subtables = [
    {
      label: "Attachments",
      value: "attachments",
      payload: "applicant_attachments",
      identifier: "applicantId",
    },
    {
      label: "Jobs",
      value: "jobs",
      payload: "applicants_jobs",
      identifier: "applicantId",
    },
    // TODO: analyze how to make this faster
    {
      label: "Events",
      value: "events",
      payload: "events_applicants",
      identifier: "applicantId",
    },
    {
      label: "Venues",
      value: "venues",
      payload: "applicants_venues",
      identifier: "applicantId",
    },
  ];

  const handleSelectSubtable = (subtable) => {
    setSelectedSubtables((prev) => {
      // If the subtable is already selected, deselect it
      if (prev.length > 0 && prev[0].label === subtable.label) {
        return []; // Deselect the subtable by clearing the selection
      }

      // Otherwise, select the new subtable and replace any existing selection
      return [subtable];
    });
  };

  return (
    <>
      <Card className={classes.box}>
        <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
          <Grid item>
            <MDBox display="flex">
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="error"
                color="white"
                borderRadius="xl"
                ml={3}
                mt={-4}
              >
                <Icon fontSize="large">person-add</Icon>
              </MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                {title}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <TagSearchAndToggle
              filters={filters}
              setFilters={setFilters}
              searchTagList={searchTagList}
              setSearchTagList={setSearchTagList}
              useOrTags={useOrTags}
              setUseOrTags={setUseOrTags}
            />
            {showSearchBar && (
              <Searchbar
                fetch={fetchEmployeesOnly}
                fetchAll={false}
                placeholder="Search Employees"
                columns={searchColumns}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setFilters}
                filters={filters}
                setPage={setPage}
                searchBy={["firstName", "lastName", "email", "altEmail", "socialSecurity"]}
                changedSearchByList={["firstName", "lastName", "email", "altEmail", "employeeID"]}
                changeSearchByAtInputChar={5}
                filterBy="_id"
                scrollLimit={200}
                sortFunction={sortLastFirst}
                onRowClick={(item) => {
                  if (item?._id) setSelectedId(item._id);
                  setFilters({
                    // ...filters,
                    status: "Employee",
                    _id: item._id,
                  });
                  navigateWithBack(`/employees/${item._id}/action/employeeinfo`);
                }}
                returnAfterClick={false}
                formatData={formatSearchResults}
                refetchDependency={searchRefetchKey}
              />
            )}
            {isCompany && (
              <MDBox sx={{ width: "25rem" }}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{ color: "white" }}
                  onClick={() => setAdvancedSearchModalOpen(true)}
                >
                  Advanced Search
                </Button>
              </MDBox>
            )}
            <Tooltip title="Bulk Message">
              <IconButton
                color="error"
                onClick={() => {
                  if (searchTagList?.length > 0 && applicants?.data?.length > 0) {
                    setMessageContext("Applicant");
                    toggleBulkMessageModal(true);
                  } else if (searchTagList?.length === 0) {
                    setOpenNoTagModal(true);
                  } else if (
                    applicants?.data?.length === 0 ||
                    applicants?.data?.length === undefined
                  ) {
                    setOpenNoResultsModal(true);
                  }
                }}
              >
                <Mail fontSize="large" />
              </IconButton>
            </Tooltip>
            <MDBox>
              {["Master", "Admin"].includes(userType) && (
                <>
                  <FlexWrapper flexWrap="nowrap">
                    {parent !== "employees" && (
                      <Tooltip title="Bulk Message">
                        <IconButton
                          color="error"
                          onClick={() => {
                            setMessageContext("Venue");
                            toggleBulkMessageModal(true);
                          }}
                        >
                          <Mail fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {filters?.["venues.venueSlug"] && (
                      <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                        <FileDownload />
                      </IconButton>
                    )}
                    <IconButton className={classes.addButton} color="info" onClick={addNew}>
                      <AddCircleRoundedIcon />
                    </IconButton>

                    {userType !== "User" && userType !== "Client" && (
                      <Tooltip title="Create Report">
                        <IconButton color="info" onClick={() => setOpenReportMessageModal(true)}>
                          <ViewComfyIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </FlexWrapper>
                </>
              )}
            </MDBox>
          </Grid>
        </Grid>
        {/* <MDBox ml={3}>
            <StaffingPoolFilterCards
              // setTableFilter={setFilters}
              filters={filters}
              setFilters={setFilters}
              selectedCardNumber={selectedCardNumber}
              initialCards={staffingPoolCards}
            />
          </MDBox> */}
        <MDBox ml={3} flexWrap="wrap">
          {showFiltersList && (
            <Grid item xs={6}>
              <FiltersList
                filters={filters}
                setFilters={setFilters}
                searchTagList={searchTagList}
                setSearchTagList={setSearchTagList}
                clearTagsHandler={clearTagsHandler}
              />
            </Grid>
          )}
        </MDBox>
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={applicants || { data: null, count: 0 }}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            isLoading={isLoading}
            onRowClick={(row, e) => handleRowClick(row, e)}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll={fetchAll}
            defaultSort="lastName"
            idField="_id"
          />
        </MDBox>
        <ApplicantActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setToastAlert={setToastAlert}
          currentApplicant={currentApplicant}
          messageContext={messageContext}
          venueId={venueId}
        />

        {duplicateModalOpen && (
          <DuplicateApplicantModal
            open={duplicateModalOpen}
            setOpen={setDuplicateModalOpen}
            mergeData={mergeApplicantData}
            parentComponent="Employee"
            onSubmit={refetchSearch}
          />
        )}
        <AdvancedSearchModal
          advancedSearchModalOpen={advancedSearchModalOpen}
          setAdvancedSearchModalOpen={setAdvancedSearchModalOpen}
          searchQuery={searchQuery}
          setFilters={setFilters}
          setSearchQuery={setSearchQuery}
          useOrTags={useOrTags}
          setUseOrTags={setUseOrTags}
          filters={filters}
          setPage={setPage}
          showFiltersList
          parent="Employees"
        />
      </Card>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          recipientText={filters?.status?.search(";") !== -1 ? "All" : filters?.status}
          applicants={applicantsForBulk?.data?.map((item) => ({ ...item, id: item?._id })) || []}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          messageContext={messageContext}
          isLoading={isLoadingForBulk}
        />
      )}
      {isStaffingPoolModalOpen && (
        <StaffingPoolExportModal
          venueSlug={filters?.["venues.venueSlug"]}
          isOpen={isStaffingPoolModalOpen}
          toggleOpen={toggleStaffingPoolModalOpen}
          filters={allOptions.filters}
          useOrTags
        />
      )}
      {applicantResumeText && (
        <ApplicantResumeTextModal
          open={!!applicantResumeText}
          setOpen={handleCloseResumeModal}
          text={applicantResumeText.text}
          applicantName={applicantResumeText.applicantName}
        />
      )}
      {!!aiScreeningResultModalSelected && (
        <JobsStatusAIInterviewModal
          open={!!aiScreeningResultModalSelected}
          onClose={handleCloseAIScreeningResultModal}
          applicant={aiScreeningResultModalSelected}
          // currentVenue={currentVenue}
          onChangeStatus={refetchEmployees}
          useLatest
        />
      )}
      {openNoTagModal && <NoTagSelectedModal open={openNoTagModal} setOpen={setOpenNoTagModal} />}
      {openNoResultsModal && (
        <NoResultsModal open={openNoResultsModal} setOpen={setOpenNoResultsModal} />
      )}
      {assessmentResultModalSelected && (
        <ExamDetailsModal
          open={!!assessmentResultModalSelected}
          onClose={handleCloseAssessmentResultModal}
          applicantAssessment={assessmentResultModalSelected.assessment}
          applicantId={assessmentResultModalSelected.applicantId}
          applicant={assessmentResultModalSelected?.applicant}
        />
      )}
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="employees"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          title="Employee Report"
          filterCardList={
            isCompany
              ? [
                  "Assignment",
                  "Inactive",
                  "Terminated",
                  "Do Not Use",
                  "Food Handling License",
                  "TABC License",
                  "All",
                ]
              : [...currentLoggedUser.venues, "All"]
          }
          subFilterCardList={
            isCompany
              ? []
              : [
                  "Active",
                  "Partner",
                  "LoggedIn",
                  "No Login",
                  "Inactive",
                  "Terminated",
                  "Do Not Use",
                  "Gaming License",
                  "Security License",
                  "TABC License",
                  "Other",
                ]
          }
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveEmployeeReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
          defaultShowSubFilters
          subTables={subtables}
          selectedSubtables={selectedSubtables}
          handleSelectSubtables={handleSelectSubtable}
        />
      )}
    </>
  );
};

// Setting default values for the props
EmployeesTable.defaultProps = {
  // currentVenue: {},
  fetchAll: false,
  title: "Employees",
  filters: null,
  showFiltersList: true,
  staffingPoolCards: [],
};

// Typechecking props
EmployeesTable.propTypes = {
  // currentVenue: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
  staffingPoolCards: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element || PropTypes.string,
      title: PropTypes.string.isRequired,
      number: PropTypes.number,
    })
  ),
};

export default EmployeesTable;
