import { useState } from "react";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  CalendarMonth,
  Description,
  Email,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import { useAppContextController } from "context/AppContext";
import MDTypography from "components/MDTypography";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import { useMutation, useQueryClient } from "react-query";
import ApplicantJobStatusButton from "components/ApplicantJobStatusButton";
import { getApplicantJobEmploymentStage } from "utils/helpers/applicant";
import ApplicantStageButton from "components/ApplicantStageButton";
import updateInterviewFeedback from "layouts/pages/events/actions/updateInterviewFeedback";
import ApplicantPhoneButton from "components/ApplicantPhoneButton";
import JobStatusMoreButton from "../../../CompanyJobsStatus/JobStatusRow/JobStatusMoreButton";

const thumbUpOptions = [
  {
    label: "None",
    color: "action",
  },
  {
    label: "Hired",
    color: "success",
  },
  {
    label: "Pending",
    color: "warning",
  },
  {
    label: "Declined",
    color: "error",
  },
];

const InterviewRowActions = ({
  currentApplicant,
  refresh,
  isJobStatus,
  currentCustomer,
  isJobFull,
}) => {
  const queryClient = useQueryClient();

  const { currentJob, currentLoggedUser } = useAppContextController();
  const [toastAlert, setToastAlert] = useState(false);
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const thumbOption = thumbUpOptions?.find(
    (option) => option.label === currentApplicant?.interview?.status
  );
  const tooltip = currentApplicant?.interview?.tooltip;
  const agentRating = currentApplicant?.interview?.agentRating;
  const isThumbsUpOpen = Boolean(anchorEl);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const changeStatusMutation = useMutation(updateInterviewFeedback, {
    onSuccess: async (_, { applicantId, eventUrl, data }) => {
      if (refresh) {
        refresh({
          applicantId,
          eventUrl,
          interviewFeedback: data.feedback.rating,
        });
      }
      enqueueSnackbar("Status updated successfully", { variant: "success" });
    },
    onError: (error) => {
      enqueueSnackbar(error?.message ?? "Something went wrong!", { variant: "error" });
    },
    mutationKey: "InterviewStatusChangeMutation",
  });

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFeedback = (label, color) => {
    changeStatusMutation.mutate({
      applicantId: currentApplicant._id,
      eventUrl: currentApplicant?.interview?.eventUrl,
      data: {
        feedback: {
          createAgent: currentLoggedUser?._id,
          agent: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
          firstName: currentLoggedUser?.firstName,
          lastName: currentLoggedUser?.lastName,
          eventUrl: currentApplicant?.interview?.eventUrl,
          modifiedDate: new Date().toISOString(),
          rating: label,
        },
      },
    });
    handleClose();
  };

  const handleSaveNote = (noteRec) => {
    // const newNote = {
    //   type: "Interview Feedback",
    //   eventUrl: currentEvent?.eventUrl,
    //   text: noteRec.text,
    //   firstName: currentLoggedUser?.firstName,
    //   lastName: currentLoggedUser?.lastName,
    //   createAgent: currentLoggedUser?._id,
    //   date: new Date().toISOString(),
    // };
    //
    // return handleUpdateFeedback({ note: newNote });
  };

  const gridSize = isJobStatus ? 2 : 2.4;

  return (
    <>
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <Grid container spacing={1}>
          {thumbOption ? (
            <Grid item>
              <Tooltip title="View Interview">
                <IconButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/events/${currentApplicant.interview?.eventUrl}`);
                  }}
                >
                  <CalendarMonth fontSize="large" color="success" />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="No interview assigned">
                <IconButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  disableRipple
                >
                  <CalendarMonth fontSize="large" color="success" sx={{ opacity: 0.4 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {thumbOption ? (
            <Grid item>
              <Tooltip title={tooltip}>
                <IconButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(e);
                  }}
                >
                  {thumbOption.label !== "Declined" ? (
                    <ThumbUpIcon fontSize="large" color={thumbOption.color} />
                  ) : (
                    <ThumbDownIcon fontSize="large" color={thumbOption.color} />
                  )}
                </IconButton>
              </Tooltip>
              <Menu anchorEl={anchorEl} open={isThumbsUpOpen} onClose={handleClose}>
                {thumbUpOptions.map((option) =>
                  option.label === "Pending" ? null : (
                    <MenuItem
                      key={option.label}
                      onClick={() => handleFeedback(option.label, option.color)}
                      disableRipple
                      disabled={option.label === agentRating}
                    >
                      {option.label !== "Declined" ? (
                        <ThumbUpIcon fontSize="medium" color={option.color} />
                      ) : (
                        <ThumbDownIcon fontSize="medium" color={option.color} />
                      )}
                      <MDTypography variant="h6" sx={{ pl: 2 }}>
                        {option.label}
                      </MDTypography>
                    </MenuItem>
                  )
                )}
              </Menu>
            </Grid>
          ) : (
            <Grid item>
              <Tooltip title="No interview assigned">
                <IconButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  disableRipple
                >
                  <ThumbUpIcon fontSize="large" color="success" sx={{ opacity: 0.4 }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Grid item>
              <ApplicantPhoneButton applicant={currentApplicant} />
            </Grid>
          </Grid>
          {isJobStatus && (
            <>
              <Grid item>
                <ApplicantStageButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  applicant={currentApplicant}
                  job={currentJob}
                  refresh={refresh}
                />
              </Grid>
              <Grid item>
                <ApplicantJobStatusButton
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  status={getApplicantJobEmploymentStage(currentApplicant, currentJob.jobSlug)}
                  job={currentJob}
                  applicant={currentApplicant}
                  refresh={refresh}
                  currentCustomer={currentCustomer}
                  isJobFull={isJobFull}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <JobStatusMoreButton
              applicant={currentApplicant}
              toastAlert={toastAlert}
              setToastAlert={setToastAlert}
            />
          </Grid>
        </Grid>
        <ApplicantActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={{ type: "applicantmail", data: currentApplicant }}
          setToastAlert={setToastAlert}
          currentApplicant={currentApplicant}
          messageContext="Applicant"
          attachmentContext="Applicant"
          eventId={currentApplicant?.interview?._id}
        />
        <AddANoteModal
          currentApplicant={currentApplicant}
          open={isNotesModalOpen}
          toastAlert={toastAlert}
          setToastAlert={setToastAlert}
          setOpen={setNotesModalOpen}
          saveNote={handleSaveNote}
          showType={false}
        />
      </SnackbarProvider>
    </>
  );
};

export default InterviewRowActions;
