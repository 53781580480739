import { baseAxios } from "config";

export const answerTimeUpdate = async (assessmentId, applicantId, payload) => {
  try {
    const res = await baseAxios.put(
      `/outside-public/assessments/${assessmentId}/applicants/${applicantId}/answer-time`,
      { ...payload }
    );
    return res.data;
  } catch (err) {
    // Extract and preserve the actual error message from the API
    console.log("API Error Response:", err.response?.data);
    if (err.response?.data?.error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        apiError: true,
        message: err.response.data.error,
        originalError: err,
      };
    }
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};
