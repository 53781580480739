import { Card, Fade, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import useCompanyCache from "hooks/useCompanyCache";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContextController } from "context/AppContext";
import CompanyLogoSquare from "components/CompanyLogoSquare";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "50%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  logoImage: {
    objectFit: "contain",
  },
});

const ThanksForApplyingModal = ({
  applicantCode,
  open,
  setOpen,
  canStartAIScreening,
  onPressCallMe,
}) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const { company, setCompany, setCompanyType } = useAppContextController();
  const [isShowingThankYouMessage, setIsShowingThankYouMessage] = useState(false);

  useEffect(() => {
    setIsShowingThankYouMessage(false);
  }, [open]);

  const handleOnboarding = useCallback(() => {
    setOpen(false);
    navigate(`/applicants/code/${applicantCode}`);
  }, [applicantCode]);

  const handleNoOnboarding = () => {
    setOpen(false);
    navigate(`/`);
  };

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "protected",
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open}>
          <Card className={classes.container}>
            <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
              <Box display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="white"
                  color="white"
                  borderRadius="xl"
                  mt={-2}
                >
                  <CompanyLogoSquare
                    company={company}
                    defaultCompany={company}
                    width="50"
                    height="50"
                  />
                </MDBox>
                <MDBox p={3}>
                  <MDTypography variant="h5" color="dark">
                    Application Successful
                  </MDTypography>
                </MDBox>
              </Box>
            </MDBox>
            <Box display="flex">
              {isShowingThankYouMessage ? (
                <MDBox p={3}>
                  <MDTypography variant="h5" color="dark">
                    Thank you, a recruiter will be reaching out to you.
                  </MDTypography>
                </MDBox>
              ) : (
                <MDBox p={3}>
                  <MDTypography variant={canStartAIScreening ? "h6" : "h5"} color="dark">
                    Thank you for applying with {company?.name}. One of our Recruiters will be in
                    contact with you very soon. In the meantime, you will receive an email message
                    with a link to verify your email address.
                  </MDTypography>
                  {canStartAIScreening && (
                    <MDTypography variant="h6" color="dark" mt={2}>
                      You can also do a quick AI Screening Interview, which will speed up the
                      recruiting process and increase the chances of getting hired. It
                      shouldn&apos;t take more than 10 minutes. Do you want to start the interview
                      now?
                    </MDTypography>
                  )}
                  {/* In the meantime, you may begin completing our Job
                  Application at any time to expedite the hiring process. */}
                </MDBox>
              )}
            </Box>
            {canStartAIScreening && !isShowingThankYouMessage ? (
              <MDBox
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <MDBox p={3}>
                  <MDButton
                    color="info"
                    variant="contained"
                    onClick={() => {
                      onPressCallMe();
                      setIsShowingThankYouMessage(true);
                    }}
                  >
                    <MDTypography variant="body2" color="white">
                      CALL ME INSTEAD
                    </MDTypography>
                  </MDButton>
                </MDBox>
                <MDBox p={3}>
                  <MDButton
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      // handleOnboarding();
                      setOpen(false);
                      navigate(`https://stadium-people.com`);
                    }}
                  >
                    <MDTypography variant="body2" color="white">
                      LATER
                    </MDTypography>
                  </MDButton>
                  <MDButton
                    color="info"
                    variant="contained"
                    sx={{
                      marginLeft: 2,
                    }}
                    onClick={() => {
                      // handleOnboarding();
                      setOpen(false);
                      navigate(`/applicants/jobs`);
                    }}
                  >
                    <MDTypography variant="body2" color="white">
                      START NOW
                    </MDTypography>
                  </MDButton>
                </MDBox>
              </MDBox>
            ) : (
              <MDBox p={3} alignSelf="end">
                <MDButton
                  color="info"
                  variant="contained"
                  onClick={() => {
                    // handleOnboarding();
                    setOpen(false);
                    navigate(`https://stadium-people.com`);
                  }}
                >
                  <MDTypography variant="body2" color="white">
                    DONE
                  </MDTypography>
                </MDButton>
              </MDBox>
            )}
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default ThanksForApplyingModal;
