import { AttachFile, Description, MoreHoriz, Timeline } from "@mui/icons-material";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useNavigate } from "react-router-dom";
import ApplicantNotesList from "components/ApplicantNotesList";
import ApplicantActivitiesList from "components/ApplicantActivitiesList";
import MDBadge from "components/MDBadge";
import ApplicantAttachmentsList from "components/ApplicantAttachmentsList";
import TaskDetailsModal from "layouts/pages/tasks/components/TaskDetailsModal";

const ApplicantMoreButton = ({
  applicant,
  onSuccess,
  isCreateTaskAllowed = false,
  extraButtons,
}) => {
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);

  const queryClient = useQueryClient();
  const { company, currentLoggedUser, currentJob, currentApplicant, setCurrentApplicant } =
    useAppContextController();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = useState(false);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const applicantNotesCount = (applicant?.notes ?? []).length;
  const applicantAttachmentsCount = (applicant?.attachments ?? []).length;

  const extraOptions =
    extraButtons?.map((item) => ({
      text: item.tooltip,
      icon: (
        <MDBadge
          badgeContent={item.badgeNumber}
          color="error"
          size="xs"
          invisible={!item.showBadge}
          // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          slotProps={{
            badge: {
              sx: { marginTop: "5px" },
            },
          }}
          circular
        >
          <Icon
            sx={{
              opacity: item.disabled ? "0.5" : "1",
            }}
            fontSize={item.size ?? "medium"}
            color={item.disabled ? "secondary" : item.color}
            key={`action_icon_${item.id}`}
            style={item.iconStyle}
          >
            {item.icon}
          </Icon>
        </MDBadge>
      ),
      handleClick: item.clickAction,
      disabled: item.disabled,
    })) ?? [];

  const options = [
    {
      text: `Notes`,
      icon: (
        <MDBadge
          badgeContent={applicantNotesCount}
          color="error"
          size="xs"
          invisible={!applicantNotesCount}
          // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          slotProps={{
            badge: {
              sx: { marginTop: "5px" },
            },
          }}
          circular
        >
          <Description fontSize="medium" color="info" />
        </MDBadge>
      ),
      handleClick: (e) => {
        setNotesModalOpen(true);
      },
      disabled: false,
    },
    {
      text: `Activities`,
      icon: <Timeline fontSize="medium" color="error" />,
      handleClick: (e) => {
        setIsActivitiesModalOpen(true);
      },
      disabled: false,
    },

    {
      text: `Attachments`,
      icon: (
        <MDBadge
          badgeContent={applicantAttachmentsCount}
          color="error"
          size="xs"
          invisible={!applicantAttachmentsCount}
          slotProps={{
            badge: {
              sx: { marginTop: "5px" },
            },
          }}
          circular
        >
          <AttachFile fontSize="medium" color="dark" />
        </MDBadge>
      ),
      handleClick: (e) => {
        setIsAttachmentsModalOpen(true);
      },
      disabled: false,
    },
  ];

  // If the current applicant is the same we're applying this quick action, then update it in memory
  const setApplicant = (applData) => {
    if (applicant && currentApplicant && applicant._id === currentApplicant._id) {
      setCurrentApplicant(applData);
    }
  };

  return (
    <>
      <Tooltip title="More actions">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          sx={{ paddingLeft: 0, paddingRight: 0, marginLeft: "10px", marginRight: "10px" }}
        >
          <MoreHoriz fontSize="large" color="dark" />
        </IconButton>
      </Tooltip>
      <CustomMenu
        anchorEl={anchorEl}
        handleClose={(e) => {
          e?.stopPropagation();
          setAnchorEl(null);
        }}
        options={[...extraOptions, ...options]}
        iconMargin="5px"
      />

      {/* This button prevents onClick events in modals from propagating to parents, triggering the row's onClick */}
      <button
        type="button"
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
        }}
      >
        {isNotesModalOpen && (
          <ApplicantNotesList
            currentApplicant={applicant}
            setCurrentApplicant={setApplicant}
            open={isNotesModalOpen}
            setOpen={setNotesModalOpen}
            onSuccess={onSuccess}
            isCreateTaskAllowed={isCreateTaskAllowed}
            setTaskModalOpen={setTaskModalOpen}
          />
        )}
        {isActivitiesModalOpen && (
          <ApplicantActivitiesList
            currentApplicant={applicant}
            open={isActivitiesModalOpen}
            setOpen={setIsActivitiesModalOpen}
          />
        )}
        {isAttachmentsModalOpen && (
          <ApplicantAttachmentsList
            currentApplicant={applicant}
            setCurrentApplicant={setApplicant}
            open={isAttachmentsModalOpen}
            setOpen={setIsAttachmentsModalOpen}
            onSuccess={onSuccess}
          />
        )}
        {isCreateTaskAllowed && taskModalOpen && (
          <TaskDetailsModal
            mode="create"
            setTaskModalOpen={setTaskModalOpen}
            taskModalOpen={taskModalOpen}
            currentTask={null}
            setToastAlert={setToastAlert}
            currentApplicant={applicant}
            parent="Notes"
          />
        )}
      </button>
    </>
  );
};

export default ApplicantMoreButton;
