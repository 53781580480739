const timeSheetFields = [
  {
    name: "venueId",
    label: "Venue ID",
  },
  {
    name: "employeeId",
    label: "Employee ID",
  },
  {
    name: "earningCode",
    label: "Earning Code",
  },
  {
    name: "hours",
    label: "Hours",
  },
  {
    name: "payRate",
    label: "Pay Rate",
  },
  {
    name: "billRate",
    label: "Bill Rate",
  },
  {
    name: "eventName",
    label: "Event Name",
  },
  {
    name: "date",
    label: "Date",
  },
  {
    name: "wcCode",
    label: "WC Code",
  },
  {
    name: "wcState",
    label: "WC State",
  },
  {
    name: "lastName",
    label: "Last name",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "position",
    label: "Position",
  },
  {
    name: "totalBill",
    label: "Total Bill",
  },
  {
    name: "timeIn",
    label: "Time In",
  },
  {
    name: "timeOut",
    label: "Time Out",
  },
  {
    name: "uniformDeduction",
    label: "Uniform Deduction",
  },
  {
    name: "reportTime",
    label: "Report Time",
  },
  {
    name: "endTime",
    label: "End Time",
  },
  {
    name: "partnerName",
    label: "Partner Name",
  },
  {
    name: "partnerSlug",
    label: "Partner Slug",
  },
];

const commonFields = [
  {
    name: "venueName",
    label: "Venue Name",
  },
  {
    name: "eventName",
    label: "Event Name",
  },
  {
    name: "eventId",
    label: "Event Id",
  },
  {
    name: "eventDate",
    label: "Event Date",
  },
];

const commonJobsFields = [
  {
    name: "venueName",
    label: "Venue Name",
  },
  {
    name: "venueId",
    label: "Venue ID",
  },
  {
    name: "title",
    label: "Title",
  },
  {
    name: "jobId",
    label: "Job Id",
  },
  {
    name: "createdDate",
    label: "Created Date",
  },
  {
    name: "jobStartDate",
    label: "Job Start Date",
  },
  {
    name: "jobEndDate",
    label: "Job End Date",
  },
];

const eventRosterFields = [
  {
    name: "venueId",
    label: "Venue ID",
  },
  {
    name: "eventId",
    label: "Event ID",
  },
  {
    name: "eventName",
    label: "Event Name",
  },
  {
    name: "eventDate",
    label: "Event Date",
  },
  {
    name: "employeeId",
    label: "Employee ID",
  },
  {
    name: "firstName",
    label: "Employee First Name",
  },
  {
    name: "lastName",
    label: "Employee Last name",
  },
  {
    name: "payRate",
    label: "Pay Rate",
  },
  {
    name: "billRate",
    label: "Bill Rate",
  },
  {
    name: "position",
    label: "Position",
  },
  {
    name: "timeIn",
    label: "Time In",
  },
  {
    name: "timeOut",
    label: "Time Out",
  },
  {
    name: "partnerSlug",
    label: "Partner Slug",
  },
  {
    name: "partnerName",
    label: "Partner Name",
  },
  {
    name: "reportTime",
    label: "Report Time",
  },
  {
    name: "endTime",
    label: "End Time",
  },
];

const eventFields = [
  ...commonFields,
  {
    name: "positionsRequested",
    label: "# Positions Requested",
  },
  {
    name: "numberOnRoster",
    label: "# On Roster",
  },
  {
    name: "numberOnWaitlist",
    label: "# Waitlisted",
  },
  {
    name: "numberOnPremise",
    label: "# On Premise",
  },
  {
    name: "reportTime",
    label: "Report Time",
  },
  {
    name: "endTime",
    label: "End Time",
  },
];

const jobsRosterFields = [
  ...commonJobsFields,
  {
    name: "locationZip",
    label: "Location Zip",
  },
  {
    name: "locationState",
    label: "Location State",
  },
  {
    name: "employeeId",
    label: "Employee ID",
  },
  {
    name: "firstName",
    label: "Employee First Name",
  },
  {
    name: "lastName",
    label: "Employee Last name",
  },
  {
    name: "payRate",
    label: "Shift Pay Rate",
  },
  {
    name: "billRate",
    label: "Shift Bill Rate",
  },
  {
    name: "shift",
    label: "Shift Name",
  },
  // {
  //   name: "timeIn",
  //   label: "Time In",
  // },
  // {
  //   name: "timeOut",
  //   label: "Time Out",
  // },
  {
    name: "date",
    label: "Date",
  },
  {
    name: "startTime",
    label: "Start Time",
  },
  {
    name: "endTime",
    label: "End Time",
  },
  {
    name: "hours",
    label: "Hours",
  },
];

const jobsTimeSheetFields = [
  {
    name: "venueId",
    label: "Venue ID",
  },
  {
    name: "employeeId",
    label: "Employee ID",
  },
  {
    name: "earningCode",
    label: "Earning Code",
  },
  {
    name: "hours",
    label: "Hours",
  },
  {
    name: "payRate",
    label: "Pay Rate",
  },
  {
    name: "billRate",
    label: "Bill Rate",
  },
  {
    name: "title",
    label: "Title",
  },
  {
    name: "date",
    label: "Date",
  },
  {
    name: "wcCode",
    label: "WC Code",
  },
  {
    name: "wcState",
    label: "WC State",
  },
  {
    name: "lastName",
    label: "Last name",
  },
  {
    name: "firstName",
    label: "First Name",
  },
  {
    name: "shift",
    label: "Shift Name",
  },
  {
    name: "totalBill",
    label: "Total Bill",
  },
  {
    name: "timeIn",
    label: "Time In",
  },
  {
    name: "timeOut",
    label: "Time Out",
  },
  {
    name: "uniformDeduction",
    label: "Uniform Deduction",
  },
];

const jobsFields = [
  ...commonJobsFields,
  // {
  //   name: "positionsRequested",
  //   label: "# Positions Requested",
  // },
  // {
  //   name: "numberOnRoster",
  //   label: "# On Roster",
  // },
  // {
  //   name: "numberOnWaitlist",
  //   label: "# Waitlisted",
  // },
  // {
  //   name: "numberOnPremise",
  //   label: "# On Premise",
  // },
  {
    name: "payRate",
    label: "Job Pay Rate",
  },
  {
    name: "billRate",
    label: "Job Bill Rate",
  },
  {
    name: "status",
    label: "Status",
  },
];

export {
  timeSheetFields,
  eventRosterFields,
  eventFields,
  jobsRosterFields,
  jobsTimeSheetFields,
  jobsFields,
};
