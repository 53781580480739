import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

const FormRenderer = ({ formData, formValues, onInputChange, onSubmit }) => {
  const renderTextBlock = (textBlock) => {
    const fontSize = "1rem";
    return (
      <Box
        sx={{
          mb: 2,
          textAlign: "left",
          fontWeight: "normal",
          fontSize,
          bgcolor: "transparent",
          p: 0,
        }}
      >
        {textBlock.title && (
          <Typography variant="h6" sx={{ mb: 1 }}>
            {textBlock.title}
          </Typography>
        )}
        <Typography variant="body1">{textBlock.content}</Typography>
      </Box>
    );
  };

  const renderField = (field) => {
    const { id, type, name, placeholder, options, required, defaultValue, readOnly } = field;

    switch (type) {
      case "input":
      case "email":
      case "phone":
      case "number":
      case "currency":
        return (
          <TextField
            fullWidth
            id={id}
            label={name}
            placeholder={placeholder}
            type={type === "currency" ? "number" : type}
            required={required}
            value={formValues[id] || defaultValue || ""}
            onChange={(e) => onInputChange(id, e.target.value)}
            InputProps={{
              readOnly,
            }}
          />
        );

      case "textarea":
        return (
          <TextField
            fullWidth
            id={id}
            label={name}
            multiline
            rows={4}
            placeholder={placeholder}
            required={required}
            value={formValues[id] || defaultValue || ""}
            onChange={(e) => onInputChange(id, e.target.value)}
            InputProps={{
              readOnly,
            }}
          />
        );

      case "select":
      case "dropdown":
        return (
          <TextField
            select
            fullWidth
            id={id}
            label={name}
            required={required}
            value={formValues[id] || defaultValue || ""}
            onChange={(e) => onInputChange(id, e.target.value)}
            InputProps={{
              readOnly,
            }}
          >
            {options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );

      case "checkbox":
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={formValues[id] || defaultValue || false}
                onChange={(e) => onInputChange(id, e.target.checked)}
                disabled={readOnly}
              />
            }
            label={name}
          />
        );

      case "radio":
        return (
          <FormControl component="fieldset">
            <FormLabel component="legend">{name}</FormLabel>
            <RadioGroup
              value={formValues[id] || defaultValue || ""}
              onChange={(e) => onInputChange(id, e.target.value)}
            >
              {options?.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio disabled={readOnly} />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case "paragraph":
        return (
          <Typography variant="body1" sx={{ mb: 2 }}>
            {field.content}
          </Typography>
        );

      case "heading":
        return (
          <Typography variant={`h${field.level || 6}`} sx={{ mb: 2, fontWeight: "bold" }}>
            {field.content}
          </Typography>
        );

      case "divider":
        return <Divider sx={{ my: 2 }} />;

      default:
        return null;
    }
  };

  if (!formData) return null;

  return (
    <form onSubmit={onSubmit}>
      {formData.textBlocks?.map(
        (textBlock) =>
          textBlock.position === "top" && (
            <Box key={`text-block-${textBlock.title || textBlock.content.substring(0, 20)}`}>
              {renderTextBlock(textBlock)}
            </Box>
          )
      )}

      {formData.title && (
        <Typography variant="h4" sx={{ mb: 2 }}>
          {formData.title}
        </Typography>
      )}

      {formData.subtitle && (
        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          {formData.subtitle}
        </Typography>
      )}

      {formData.sections?.map((section) => (
        <Box
          key={`section-${section.title || "untitled"}-${
            section.description?.substring(0, 20) || ""
          }`}
          sx={{ mb: 4 }}
        >
          {section.title && (
            <Typography variant="h5" sx={{ mb: 2 }}>
              {section.title}
            </Typography>
          )}

          {section.description && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              {section.description}
            </Typography>
          )}

          {section.textBlocks?.map(
            (textBlock) =>
              textBlock.position === "before" && (
                <Box key={`text-block-${textBlock.title || textBlock.content.substring(0, 20)}`}>
                  {renderTextBlock(textBlock)}
                </Box>
              )
          )}

          <Grid container spacing={2}>
            {section.rows?.map((row) => {
              const rowKey =
                row.columns?.map((field) => field.id || field.name).join("-") || "empty-row";
              const columnsInRow = row.columns?.length || 0;

              // Calculate grid sizes based on number of columns
              let mdSize;
              let smSize;

              switch (columnsInRow) {
                case 1:
                  mdSize = 12;
                  smSize = 12;
                  break;
                case 2:
                  mdSize = 6;
                  smSize = 6;
                  break;
                case 3:
                  mdSize = 4;
                  smSize = 6;
                  break;
                case 4:
                  mdSize = 3;
                  smSize = 6;
                  break;
                default:
                  // For 5 or more columns, or empty rows
                  mdSize = Math.floor(12 / columnsInRow);
                  smSize = 6;
              }

              return (
                <Grid
                  container
                  item
                  spacing={2}
                  key={`row-${section.title || "untitled"}-${rowKey}`}
                >
                  {row.columns
                    ?.filter((field) => !field.hidden)
                    .map((field) => (
                      <Grid
                        item
                        xs={12}
                        sm={smSize}
                        md={mdSize}
                        key={`field-${field.id || field.name}`}
                      >
                        <Box
                          sx={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent:
                              field.type === "checkbox" || field.type === "radio"
                                ? "center"
                                : "flex-start",
                            alignItems:
                              field.type === "checkbox" || field.type === "radio"
                                ? "flex-start"
                                : "stretch",
                            p: 1,
                          }}
                        >
                          {renderField(field)}
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              );
            })}
          </Grid>

          {section.textBlocks?.map(
            (textBlock) =>
              textBlock.position === "after" && (
                <Box key={`text-block-${textBlock.title || textBlock.content.substring(0, 20)}`}>
                  {renderTextBlock(textBlock)}
                </Box>
              )
          )}
        </Box>
      ))}

      {formData.textBlocks?.map(
        (textBlock) =>
          textBlock.position === "bottom" && (
            <Box key={`text-block-${textBlock.title || textBlock.content.substring(0, 20)}`}>
              {renderTextBlock(textBlock)}
            </Box>
          )
      )}

      <Box sx={{ mt: 3, display: "flex", gap: 2, justifyContent: "flex-end" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

FormRenderer.propTypes = {
  formData: PropTypes.object,
  formValues: PropTypes.object.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

FormRenderer.defaultProps = {
  formData: null,
};

export default FormRenderer;
