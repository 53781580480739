import CancelIcon from "@mui/icons-material/Cancel";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Card, Fade, Grid, IconButton, Modal, Stack, styled, Typography } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import useCompanyStore from "stores/useCompanyStore";
import { getCompanyImageUrl } from "utils/helpers/upload";
import fetchSingleCompany from "../../actions/fetchSingleCompany";

const CompanyPreview = ({ open, onClose, content }) => {
  const { company } = useAppContextController();
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const IMAGE_SERVER = getCompanyImageUrl(company);
  const [previewCompany, setPreviewCompany] = useState(null);

  useEffect(() => {
    const fetchPreview = async () => {
      const tempCompany = await fetchSingleCompany(currentCompany?._id);
      if (tempCompany) setPreviewCompany(tempCompany);
    };
    if (currentCompany?._id) fetchPreview();
  }, [currentCompany]);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card>
            {content === "all" && (
              <>
                <StyledContainer mt={-20}>
                  <CloseEditorButton onClick={onClose}>
                    <CancelIcon color="secondary" />
                  </CloseEditorButton>
                  <Box mt={-15} sx={{ height: "30rem", width: "100%", zIndex: -1 }}>
                    <img
                      style={{ objectFit: "cover", width: "100%" }}
                      src={`${IMAGE_SERVER}/company/${previewCompany?.slug}/backgroundImage/${previewCompany?.backgroundImageUrl}`}
                      alt={`${previewCompany?.title} Job Preview`}
                    />
                  </Box>
                </StyledContainer>
              </>
            )}
            <StyledContainer>
              <StyledModalContainer>
                <Grid container>
                  {content === "all" && (
                    <>
                      <Grid item style={{ alignSelf: "center" }}>
                        <CompanyLogoSquare
                          company={previewCompany}
                          defaultCompany={company}
                          height={120}
                          width={120}
                        />
                      </Grid>
                      <Grid item>
                        <StyledBox>
                          <StyledCompanyName variant="h5">{previewCompany?.name}</StyledCompanyName>
                          <StyledHeaderBox>
                            <Stack direction="row" alignItems="center">
                              <MDBox pr={1}>
                                <EmailIcon color="dark" pr={2} />
                              </MDBox>
                              <Typography variant="body1">
                                <a
                                  href={`mailto:${previewCompany?.contact?.email}`}
                                  alt="Email Customer"
                                >
                                  {previewCompany?.contact?.email}
                                </a>
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <MDBox pr={1}>
                                <PhoneIcon color="success" />
                              </MDBox>
                              <Typography variant="body1">
                                {previewCompany?.contact?.phone}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <MDBox pr={1}>
                                <LocationOnIcon color="error" />
                              </MDBox>
                              <Typography variant="body1">
                                {`${previewCompany?.address}, ${previewCompany?.city} ${previewCompany?.state} ${previewCompany?.zip}`}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <MDBox pr={1}>
                                <LanguageIcon color="info" />
                              </MDBox>
                              <Typography variant="body1">
                                <a
                                  href={previewCompany?.companyUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {previewCompany?.companyUrl}
                                </a>
                              </Typography>
                            </Stack>
                          </StyledHeaderBox>
                        </StyledBox>
                      </Grid>
                      <Grid item sm={12}>
                        <StyledBoxDescription>
                          {previewCompany?.description && (
                            <div>{parse(previewCompany.description)}</div>
                          )}
                        </StyledBoxDescription>
                      </Grid>
                    </>
                  )}
                  {content !== "all" && (
                    <Grid item sm={12}>
                      <StyledBoxDescription>
                        {previewCompany?.[content] && <div>{parse(previewCompany[content])}</div>}
                      </StyledBoxDescription>
                    </Grid>
                  )}
                </Grid>
              </StyledModalContainer>
            </StyledContainer>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

export default CompanyPreview;

const CloseEditorButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "0%",
  right: "5%",
  m: 1,
  p: 0,
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  maxWidth: 1170,
  backgroundColor: "none",
  overflow: "hidden",
  borderRadius: 5,
  padding: "6rem 4rem",
}));

const StyledModalContainer = styled(Card)(({ theme }) => ({
  backgroundColor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  padding: "1rem",
  display: "flex",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "1rem",
}));

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  color: "#7c97a9",
}));

const StyledBoxDescription = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  maxHeight: "40vh",
  marginTop: 2,
  padding: "1rem",
  fontSize: "1rem",
}));

const StyledCompanyName = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  marginBottom: 5,
}));
