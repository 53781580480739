import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Icon,
  Stack,
} from "@mui/material";
import {
  ArrowDropDown,
  Hub,
  ContentPasteSearch,
  Timeline,
  PeopleAlt,
  Event,
} from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import CustomDataGrid from "components/CustomDataGrid";
import CustomBarChart from "components/CustomBarChart";
import { useAppContextController } from "context/AppContext";
import {
  APPLICANT_DETAIL,
  JOB_APPLIES,
  APPLICANT_STAGE_ACTIVITY_ICONS,
  STAFFING_POOL_ICONS,
  INTERVIEW_ACTIVITY_ICONS,
  EVENT_ACTIVITY_ICONS,
} from "./detailColumns";
import {
  APPLICANT_DETAIL_DATA,
  JOB_APPLIES_DATA,
  APPLICANT_ACTIVITY_STAGE_DATA,
  STAFFING_POOL_DATA,
} from "./detailRows"; // to be deleted

const accordionStyle = {
  borderBottom: "1px solid #DBDCDE",
  boxShadow: "none",
  borderRadius: "0 !important",
};

const ReportDetails = ({
  applicantSource,
  applicantStage,
  interviewActivity,
  staffingPoolActivity,
  eventActivity,
}) => {
  const { company } = useAppContextController();

  const companyType = company?.companyType?.toLowerCase() || "venue";

  const colorMap = {
    blue: "#0070C0",
    red: "#CC3300",
    purple: "#9C27B0",
    orange: "#E28700",
    green: "#008000",
  };

  const transformedStageData =
    applicantStage?.stages?.map((stage) => ({
      name: stage.stage,
      value: stage.count,
      color: colorMap[stage.color] || "#000000",
    })) || [];

  const transformedInterviewData =
    interviewActivity?.stages?.map((stage) => ({
      name: stage.stage,
      value: stage.count,
      color: colorMap[stage.color] || "#000000",
    })) || [];

  const transformedStaffingPoolData =
    staffingPoolActivity?.stages?.map((stage) => ({
      name: stage.stage,
      value: stage.count,
      color: colorMap[stage.color] || "#000000",
    })) || [];

  const transformedEventData =
    eventActivity?.stages?.map((stage) => ({
      name: stage.stage,
      value: stage.count,
      color: colorMap[stage.color] || "#000000",
    })) || [];

  const reportSections = [
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <Hub />
        </Icon>
      ),
      title: "Applicant Source",
      content: (
        <CustomDataGrid
          rows={
            applicantSource?.sources.map((source, index) => {
              return {
                id: index + 1,
                source: source.source,
                quantity: source.quantity,
                new: source.new,
                atc: source.atc,
                screened: source.screened,
                prehire: source.preHire,
                decline: source.declined,
              };
            }) || []
          }
          columns={JOB_APPLIES}
        />
      ),
      count: applicantSource?.total || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <Timeline />
        </Icon>
      ),
      title: "Applicant Stage Activity",
      content: (
        <CustomBarChart data={transformedStageData} icons={APPLICANT_STAGE_ACTIVITY_ICONS} />
      ),
      count: applicantStage?.total || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <PeopleAlt />
        </Icon>
      ),
      title: "Interview Activity",
      content: <CustomBarChart data={transformedInterviewData} icons={INTERVIEW_ACTIVITY_ICONS} />,
      count: interviewActivity?.total || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <PeopleAlt />
        </Icon>
      ),
      title: "Staffing Pool Activity",
      content: <CustomBarChart data={transformedStaffingPoolData} icons={STAFFING_POOL_ICONS} />,
      count: staffingPoolActivity?.total || 0,
    },
  ];

  // Add Event Activity section only for venue company type
  if (companyType === "venue" && eventActivity) {
    reportSections.splice(4, 0, {
      icon: (
        <Icon color="warning" fontSize="large">
          <Event />
        </Icon>
      ),
      title: "Event Activity",
      content: <CustomBarChart data={transformedEventData} icons={EVENT_ACTIVITY_ICONS} />,
      count: eventActivity.total || 0,
    });
  }

  return (
    <Box>
      {reportSections?.map((section) => (
        <Accordion key={section?.title} disableGutters sx={accordionStyle}>
          <AccordionSummary
            expandIcon={
              <Icon fontSize="large">
                <ArrowDropDown />
              </Icon>
            }
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Stack direction="row" spacing={4} alignItems="center">
              {section?.icon}
              <MDTypography fontWeight="medium">{section?.title}</MDTypography>
            </Stack>
            <Box sx={{ ml: "auto" }}>
              <MDTypography fontWeight="medium" sx={{ mr: 1 }}>
                {section?.count}
              </MDTypography>
            </Box>
          </AccordionSummary>
          <Divider sx={{ bgcolor: "gray", my: 0 }} />
          <AccordionDetails sx={{ py: 0, m: 0 }}>{section?.content}</AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default ReportDetails;
