import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import { useEffect, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { getDirtyFields } from "utils/helpers/formHelpers";
import { v4 as uuidv4 } from "uuid";

import { AutoAwesome, Quiz } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import AssessmentPreviewModal from "components/AssessmentPreviewModal";
import AssessmentQuestionsPanel from "components/AssessmentQuestionsPanel";
import CircularLoader from "components/CircularLoader";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import CustomCard from "components/CustomCard";
import CustomTextField from "components/Form/CustomTextField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import { cardStyles, customPanelCard } from "layouts/pages/customers/styles";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import { isEmpty } from "lodash";
import { getCompanyImageUrl } from "utils/helpers/upload";
import generateAssessmentQuestions from "../../actions/generateAssessmentQuestions";
import generateAssessmentRubric from "../../actions/generateAssessmentRubric";
import updateAssessment from "../../actions/updateAssessment";
import AssessmentsQuestionsExcel from "./AssessmentsQuestionsExcel";
import AssessmentsQuestionsPanelActions from "./AssessmentsQuestionsPanelActions";
import useStyles from "./styles";

const AssessmentsQuestions = () => {
  const {
    company,
    currentAssessment,
    setCurrentAssessment,
    isMobile,
    setCurrentFormDirty,
    changePanel,
  } = useAppContextController();
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);

  const IMAGE_SERVER = getCompanyImageUrl(company);
  const styles = useStyles();
  const [questionIndex, setQuestionIndex] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [numQuestionsToGenerate, setNumQuestionsToGenerate] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [questionsUpdated, setQuestionsUpdated] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    setError,
    formState: { dirtyFields, errors },
  } = useForm();

  const isDirty = !isEmpty(dirtyFields);

  const recalculateRecommendedNumberOfQuestions = (questions) => {
    if (!(questions ?? []).length) return 30;
    return Math.ceil((28 * (questions ?? []).length) / 60);
  };

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
  }, []);

  const queryClient = useQueryClient();
  const { mutateAsync: updateAssessmentMutation, isLoading } = useMutation(updateAssessment, {
    onSuccess: async (res, val) => {
      queryClient.invalidateQueries("assessments_table");
      queryClient.invalidateQueries("assessments");
      queryClient.invalidateQueries("useBadgeValues");
      setCurrentAssessment({ ...currentAssessment, ...val.data });
      if (!val.silent) {
        enqueueSnackbar("Assessment has been updated.", { variant: "success" });
      }
      setQuestionsUpdated(false);
    },
    onError: (err) => {
      if (!err.silent) {
        console.error(err);
        enqueueSnackbar("Failed to save changes", { variant: "error" });
      }
    },
  });

  const { mutateAsync: generateAssessmentQuestionMutation, isLoading: isLoadingQuestion } =
    useMutation(generateAssessmentQuestions, {
      onSuccess: async (res, vars) => {
        const questionsWithIds = res.questions?.map((q) => ({
          ...q,
          _id: q._id || uuidv4(),
        }));
        enqueueSnackbar("Questions generated successfully.", { variant: "success" });
        const newQuestions = vars.existingQuestions?.length
          ? [...vars.existingQuestions, ...questionsWithIds]
          : questionsWithIds;
        setValue("questions", newQuestions, { shouldDirty: true });
        setValue("scoringRubric", res.rubric, { shouldDirty: true });
        setValue("timeLimit", recalculateRecommendedNumberOfQuestions(newQuestions) * 60, {
          shouldDirty: true,
        });
        setQuestionsUpdated(false);
      },
      onError: (err) =>
        enqueueSnackbar(`An error occured: ${err?.response?.data?.message ?? err}`, {
          variant: "error",
        }),
    });

  const { mutateAsync: generateAssessmentRubricMutation, isLoading: isLoadingRubric } = useMutation(
    generateAssessmentRubric,
    {
      onSuccess: async (res, vars) => {
        enqueueSnackbar("Scoring rubric generated successfully.", { variant: "success" });
        setValue("scoringRubric", res.rubric, { shouldDirty: true });
        setQuestionsUpdated(false);
      },
      onError: (err) =>
        enqueueSnackbar(`An error occured: ${err?.response?.data?.message ?? err}`, {
          variant: "error",
        }),
    }
  );

  const handleUpdateAssessment = (updatedValues, silent = false) => {
    updateAssessmentMutation({
      _id: currentAssessment?._id,
      data: updatedValues,
      silent,
    });
  };

  const onSubmit = (data) => {
    const temp = getDirtyFields(data, dirtyFields);
    handleUpdateAssessment({ ...temp }, false);
  };

  const handleCancelChanges = () => {
    reset(currentAssessment);
    setQuestionsUpdated(false);
  };

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  useEffect(() => {
    reset(currentAssessment);
    setQuestionsUpdated(false);
  }, [currentAssessment]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
      setQuestionsUpdated(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addQuestion = (question) => {
    const newQuestions = [...(watch("questions") ?? []), question];
    setValue("questions", newQuestions, { shouldDirty: true });
    setValue("timeLimit", recalculateRecommendedNumberOfQuestions(newQuestions) * 60, {
      shouldDirty: true,
    });
    setQuestionsUpdated(true);
  };

  const updateQuestion = (id, question) => {
    setValue(
      "questions",
      watch("questions").map((oldQuestion) => {
        if (oldQuestion._id === id) {
          return question;
        }
        return oldQuestion;
      }),
      { shouldDirty: true }
    );
    setQuestionsUpdated(true);
  };

  const removeQuestion = (index) => {
    const newQuestions = watch("questions").filter((oldQuestion, oldIndex) => {
      return oldIndex !== index;
    });
    setValue("questions", newQuestions, { shouldDirty: true });
    setValue("timeLimit", recalculateRecommendedNumberOfQuestions(newQuestions) * 60, {
      shouldDirty: true,
    });
    setQuestionsUpdated(true);
  };

  const generateNewQuestions = async (numQuestions, shouldAppend = false) => {
    try {
      enqueueSnackbar(
        `Generating questions... This takes approximately ${numQuestions * 6} seconds.`,
        {
          variant: "warning",
          autoHideDuration: 8000,
        }
      );
      const { questions: generatedQuestions, rubric: scoringRubric } =
        await generateAssessmentQuestionMutation({
          description: watch("description"),
          skills: watch("skills"),
          type: watch("type"),
          numQuestions,
          existingQuestions: shouldAppend ? watch("questions") : [],
        });
    } catch (error) {
      console.error("Failed to generate questions:", error);
    }
  };

  const generateNewRubric = async () => {
    try {
      const { questions: generatedQuestions, rubric: scoringRubric } =
        await generateAssessmentRubricMutation({
          description: watch("description"),
          skills: watch("skills"),
          type: watch("type"),
          existingQuestions: watch("questions"),
        });
    } catch (error) {
      console.error("Failed to generate questions:", error);
    }
  };

  const handleGenerateQuestions = async (numQuestions) => {
    const div = document.createElement("div");
    div.innerHTML = watch("description") ?? "";
    const plainDescription = div.innerText;
    if (!plainDescription.length) {
      enqueueSnackbar(`A description is required`, { variant: "error" });
      return;
    }

    const hasExistingQuestions = watch("questions")?.length > 0;
    if (!hasExistingQuestions) {
      generateNewQuestions(numQuestions);
      return;
    }

    setNumQuestionsToGenerate(numQuestions);
    setShowRegenerate(true);
  };

  const handleConfirmRegenerate = (shouldAppend) => {
    setShowRegenerate(false);
    generateNewQuestions(numQuestionsToGenerate, shouldAppend);
  };

  const handleOpenPreviewModal = () => {
    setOpenPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setOpenPreviewModal(false);
  };

  // Debounced autosave for content changes only
  useEffect(() => {
    if (!isDirty || !currentAssessment?._id || isLoading || isLoadingRubric) return;

    const timer = setTimeout(() => {
      const data = getValues();
      const temp = getDirtyFields(data, dirtyFields);
      handleUpdateAssessment({ ...temp }, true); // Silent - no feedback
    }, 3000);

    // eslint-disable-next-line arrow-body-style, consistent-return
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("questions"), watch("timeLimit"), watch("scoringRubric"), isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Quiz color="white" />}
        cardTitle={
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <span>Questions: {currentAssessment?.name}</span>
          </Box>
        }
        cardActions={
          <AssessmentsQuestionsPanelActions
            onCancel={handleCancelChanges}
            onPreview={handleOpenPreviewModal}
            isDirty={isDirty}
          />
        }
        {...customPanelCard(isMobile, "violet")}
      >
        <Box sx={cardStyles.container}>
          {watch("type") === "Excel" ? (
            <AssessmentsQuestionsExcel updateAssessmentMutation={updateAssessmentMutation} />
          ) : (
            <MDBox>
              <AssessmentQuestionsPanel
                questions={watch("questions") ?? []}
                addQuestion={addQuestion}
                removeQuestion={removeQuestion}
                updateQuestion={updateQuestion}
                questionIndex={questionIndex}
                setQuestionIndex={setQuestionIndex}
                control={control}
                watch={watch}
                errors={errors}
                setValue={setValue}
                title="Assessment Questions"
                onPressGenerate={handleGenerateQuestions}
                isLoadingGenerateQuestion={isLoadingQuestion}
                skills={watch("skills")}
                assessmentType={watch("type")}
              />
            </MDBox>
          )}
          <MDBox sx={{ width: 180, mt: 3 }}>
            <Controller
              name="timeLimit"
              control={control}
              render={({ field }) => (
                <FormField
                  type="number"
                  label="Time limit (minutes)"
                  name="timeLimit"
                  {...field}
                  value={field.value == null ? "" : Math.round((field.value ?? 0) / 60)}
                  onChange={(test) => {
                    if (test?.target?.value == null || test?.target?.value === "")
                      setValue("timeLimit", "", { shouldDirty: true });
                    setValue("timeLimit", test.target.value * 60, { shouldDirty: true });
                  }}
                />
              )}
            />
            <MDTypography
              className={styles.error}
            >{`Recommended: ${recalculateRecommendedNumberOfQuestions(watch("questions"))} minutes${
              (watch("questions") ?? []).length
                ? ` (${(watch("questions") ?? []).length} questions)`
                : ""
            }`}</MDTypography>
            {errors?.timeLimit && (
              <MDTypography color="error" className={styles.error}>
                {errors?.timeLimit.message}
              </MDTypography>
            )}
          </MDBox>
          {watch("type") !== "Excel" && (
            <>
              <MDBox sx={{ mt: 4 }}>
                <MDBox
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {questionsUpdated ? (
                    <MDTypography color="error" className={styles.error}>
                      Questions updated. Please regenerate rubric.
                    </MDTypography>
                  ) : (
                    <Grid />
                  )}
                  <Button
                    disableRipple
                    disabled={isLoadingRubric}
                    onClick={(e) => {
                      e.stopPropagation();
                      generateNewRubric();
                    }}
                  >
                    {isLoadingRubric && (
                      <CircularLoader size="2rem" color="info" hasBackdrop={false} isLoading />
                    )}
                    <Grid container columnGap={1} alignItems="center">
                      <AutoAwesome sx={{ color: "#8935f4" }} />
                      <MDTypography
                        component="label"
                        variant="button"
                        fontWeight="medium"
                        color="violet"
                        sx={{ fontSize: "0.8rem", color: "#8935f4", cursor: "inherit" }}
                      >
                        Generate Scoring Rubric
                      </MDTypography>
                    </Grid>
                  </Button>
                </MDBox>
                <Controller
                  name="scoringRubric"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      multiline
                      rows={8}
                      variant="outlined"
                      label="Scoring Rubric"
                      showError={!isEmpty(errors.scoringRubric)}
                      sx={{
                        "& div": {
                          paddingTop: "8px",
                        },
                      }}
                      {...field}
                    />
                  )}
                />
                {errors?.scoringRubric && (
                  <MDTypography color="error" className={styles.error}>
                    {errors?.scoringRubric.message}
                  </MDTypography>
                )}
              </MDBox>
            </>
          )}
        </Box>
      </CustomCard>
      <AssessmentPreviewModal
        open={openPreviewModal}
        onClose={handleClosePreviewModal}
        questions={watch("questions")}
        name={watch("name")}
        description={watch("description")}
        assessment={currentAssessment}
      />
      <ReminderToSaveChangesModal
        open={reminderToSaveChangesModalOpen}
        setOpen={setReminderToSaveChangesModalOpen}
      />
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
      {showRegenerate && (
        <ConfirmModal
          title="Generate Questions"
          description="Do you want to append to or replace existing questions?"
          isOpen={showRegenerate}
          negativeBtn={{
            label: "Cancel",
            fn: () => {
              setShowRegenerate(false);
            },
            isShown: true,
          }}
          negativeAltBtn={{
            label: "Replace",
            fn: () => handleConfirmRegenerate(false),
            isShown: true,
          }}
          positiveBtn={{
            label: "Append",
            fn: () => handleConfirmRegenerate(true),
            isShown: true,
          }}
        />
      )}
    </form>
  );
};

export default AssessmentsQuestions;
