import { Button, Tooltip } from "@mui/material";
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import createBranchWallet from "api/branchWallet/createBranchWallet";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const BranchWalletCreationButton = ({ isEmployee }) => {
  const { currentApplicant, setCurrentApplicant } = useAppContextController();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const createWalletMutation = useMutation(createBranchWallet, {
    onError: (error) => {
      enqueueSnackbar(error.message || "Failed to create Branch wallet", { variant: "error" });
    },
    onSuccess: (res) => {
      enqueueSnackbar("Branch wallet created successfully", { variant: "success" });
      setCurrentApplicant({ ...currentApplicant, ...res?.data });
      queryClient.invalidateQueries(isEmployee ? "employee_table" : "applicants");
    },
  });

  const handleClick = () => {
    const status = currentApplicant?.branchWallet?.status;

    switch (status) {
      case "ACTIVE":
        enqueueSnackbar("Branch wallet is already active and ready to use", { variant: "info" });
        break;
      case "PENDING":
        enqueueSnackbar("Branch wallet creation is in progress", { variant: "warning" });
        break;
      case "CREATED":
        enqueueSnackbar("Branch wallet has been created and is being processed", {
          variant: "info",
        });
        break;
      default:
        if (!currentApplicant?.employeeID) {
          enqueueSnackbar("Employee ID is required", { variant: "error" });
          return;
        }
        createWalletMutation.mutate(currentApplicant._id);
    }
  };

  const getWalletStatusConfig = () => {
    const status = currentApplicant?.branchWallet?.status;

    switch (status) {
      case "ACTIVE":
        return {
          color: "success",
          icon: <CheckCircleIcon fontSize="small" />,
          tooltip: "Branch wallet is active",
        };
      case "PENDING":
        return {
          color: "warning",
          icon: <PendingIcon fontSize="small" />,
          tooltip: "Branch wallet creation in progress",
        };
      case "CREATED":
        return {
          color: "secondary",
          icon: <CheckCircleIcon fontSize="small" />,
          tooltip: "Branch wallet has been created",
        };
      default:
        return {
          color: "primary",
          icon: <AddCircleOutlineIcon fontSize="small" />,
          tooltip: "Create Branch wallet",
        };
    }
  };

  const config = getWalletStatusConfig();

  return (
    <Tooltip title={config.tooltip}>
      <Button
        variant="contained"
        size="small"
        color={config.color}
        onClick={handleClick}
        disabled={createWalletMutation.isLoading}
        startIcon={config.icon}
      >
        Branch
      </Button>
    </Tooltip>
  );
};

export default BranchWalletCreationButton;
