import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AvTimer } from "@mui/icons-material";
import PreviewIcon from "@mui/icons-material/Preview";
import { useAppContextController } from "context/AppContext";
import { useMemo, useState } from "react";
import GenericMoreButton from "components/GenericMoreButton";

const ClientEventsTableActions = ({
  eventUrl,
  setNavigateUrl,
  row,
  setActionSelected,
  setFilters,
  setOpen,
  setEventPreview,
  setInterviewPreview,
  setCurrentEvent,
  getAndSetFullEvent,
  openTimeModal,
}) => {
  const { userType, company, currentLoggedUser } = useAppContextController();

  const isCompany = company?.companyType === "Company";
  const onClickHandler = (action, e) => {
    e.stopPropagation();

    const nodes = new URL(window.location).pathname.split("/");
    const baseRoute =
      nodes?.length >= 4 && nodes[2] === "venue"
        ? `/clientevents/venue/${row?.venueSlug}/${row?.eventUrl}`
        : `/clientevents/${row?.eventUrl}`;

    // we need to get rid of timeFrame in the filters, because user has selected
    //    and event which could be in the past
    if (row?.eventUrl)
      setFilters((prev) => {
        const newFilters = { ...prev };
        // if (newFilters.timeFrame) delete newFilters.timeFrame;
        return { ...newFilters, eventUrl: row.eventUrl };
      });
    // setCurrentEvent(row);
    getAndSetFullEvent(row._id);
    setNavigateUrl(`${baseRoute}/action/${action}`);
  };

  // const setPreviewOpen = (event, e) => {
  //   e.stopPropagation();

  //   setEventPreview(event);
  //   setOpen(true);
  // };

  const openPreviewModal = () => {
    if (isCompany) {
      setInterviewPreview(row);
    } else {
      setEventPreview(row);
    }
    setOpen((prev) => !prev);
  };
  const [actions] = useState([
    {
      color: "info",
      icon: "info",
      clickAction: (e) => onClickHandler("eventsinfo", e),
      tooltip: company?.companyType === "Venue" ? "Event Info" : "Interview Info",
    },
    ...(company?.companyType === "Venue"
      ? [
          {
            name: "eventRoster",
            color: "error",
            icon: "people",
            clickAction: (e) => onClickHandler("eventsroster", e),
            tooltip: "Event Roster",
          },
        ]
      : [
          {
            name: "eventRoster",
            color: "warning",
            icon: <PersonAddIcon fontSize="large" color="warning" />,
            clickAction: (e) => onClickHandler("eventsroster", e),
            tooltip: "Interview Applicants",
          },
        ]),
    {
      name: "venuePreview",
      color: "info",
      icon: <PreviewIcon />,
      clickAction: (e) => {
        e.stopPropagation();
        openPreviewModal();
      },
      tooltip: company?.companyType === "Venue" ? "Event Preview" : "Interview Preview",
    },
    {
      name: "eventTime",
      color: "success",
      icon: <AvTimer />,
      clickAction: (e) => {
        e.stopPropagation();
        openTimeModal();
      },
      tooltip: "Event time",
    },
    // {
    //   color: "dark",
    //   icon: "public",
    //   clickAction: (e) => onClickHandler("eventsall", e),
    //   tooltip:
    //     company?.companyType === "Venue" ? "All Event Information" : "All Interview Information",
    // },
  ]);

  const filteredActions = useMemo(() => {
    const updatedActions = [...actions];
    if (userType === "User") {
      const userActions = ["venuePreview", "eventTime"];
      return updatedActions.filter((action) => userActions.includes(action.name));
    }

    const hideAdminActions = ["eventTime"];
    return updatedActions.filter((action) => !hideAdminActions.includes(action.name));
  }, [actions]);

  if (filteredActions.length <= 2) {
    return (
      <span>
        {filteredActions.map((prop) => (
          <TableAction {...prop} key={prop.tooltip} />
        ))}
      </span>
    );
  }

  return (
    <span>
      {filteredActions.slice(0, 1).map((prop) => (
        <TableAction {...prop} key={prop.tooltip} />
      ))}
      <GenericMoreButton buttons={filteredActions.slice(1)} />
    </span>
  );
};

export default ClientEventsTableActions;

ClientEventsTableActions.defaultProps = {
  eventUrl: "",
};

// Typechecking props for the TableAction
ClientEventsTableActions.propTypes = {
  eventUrl: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
