import axios from "axios";

import { baseAxios } from "config";

const deleteAssessment = async ({ _id }) => {
  try {
    const res = await baseAxios.delete(`/assessments/${_id}`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default deleteAssessment;
