import { AttachFile, Description, MoreHoriz, Timeline } from "@mui/icons-material";
import { Icon, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useNavigate } from "react-router-dom";
import ApplicantNotesList from "components/ApplicantNotesList";
import ApplicantActivitiesList from "components/ApplicantActivitiesList";
import MDBadge from "components/MDBadge";
import ApplicantAttachmentsList from "components/ApplicantAttachmentsList";
import TaskDetailsModal from "layouts/pages/tasks/components/TaskDetailsModal";

const GenericMoreButton = ({ buttons }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const options = buttons.map((item) => ({
    text: item.tooltip,
    icon: (
      <MDBadge
        badgeContent={item.badgeNumber}
        color="error"
        size="xs"
        invisible={!item.showBadge}
        // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        slotProps={{
          badge: {
            sx: { marginTop: "5px" },
          },
        }}
        circular
      >
        <Icon
          sx={{
            marginLeft: "5px",
            opacity: item.disabled ? "0.5" : "1",
          }}
          fontSize={item.size ?? "medium"}
          color={item.disabled ? "secondary" : item.color}
          key={`action_icon_${item.id}`}
          style={item.iconStyle}
        >
          {item.icon}
        </Icon>
      </MDBadge>
    ),
    handleClick: item.clickAction,
    disabled: item.disabled,
  }));

  return (
    <>
      <Tooltip title="More actions">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          sx={{ paddingLeft: 0, paddingRight: 0, marginLeft: "10px", marginRight: "10px" }}
        >
          <MoreHoriz fontSize="large" color="dark" />
        </IconButton>
      </Tooltip>
      <CustomMenu
        anchorEl={anchorEl}
        handleClose={(e) => {
          e?.stopPropagation();
          setAnchorEl(null);
        }}
        options={options}
        iconMargin="5px"
      />
    </>
  );
};

export default GenericMoreButton;
