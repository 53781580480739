import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useAppContextController } from "context/AppContext";
import useSort from "utils/useSort";

import CompanyLogoSquare from "components/CompanyLogoSquare";
import FiltersList from "components/FiltersList";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import Searchbar from "components/Searchbar";
import useHandleBackButtonSubGrid from "hooks/useHandleBackButtonSubGrid";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import useSessionAuth from "hooks/useSessionAuth";
import moment from "moment";
import fetchAssessments from "../../actions/fetchAssessments";
import AssessmentsTableActions from "../AssessmentsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const AssessmentsTable = ({
  assessmentId,
  fetchAll = false,
  setNavigateUrl,
  filters,
  setFilters,
  setOpen,
  setAssessmentPreview,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  refetchAssessments,
  title = "Assessments",
  parent = "Assessments",
  currentApplicant,
  name,
  parentPage,
  parentNavigateWithBack,
}) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const { company, currentAssessment, setCurrentAssessment, venues, reportSchemas, userType } =
    useAppContextController();
  const isCompany = company?.companyType === "Company";

  // const [dateRangeFilter, setDateRangeFilter] = useState(null);
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { logoutAuth0User } = useSessionAuth();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const { order, orderBy, toggleSort } = useSort();

  // Handle back button from parent screens
  const { navigateWithBack, navigateWithoutBack } = useHandleBackButtonSubGrid({
    parentPage,
    setPage,
    page,
    filters,
    navigateFunc: navigate,
    navigateWithBackFunc: parentNavigateWithBack,
  });

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters: { ...filters } };

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);
  const [selectedSubtables, setSelectedSubtables] = useState([]);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const {
    data: assessments,
    isLoading,
    refetch,
  } = useQuery(["assessments", allOptions], () => fetchAssessments(allOptions), {});

  useEffect(() => {
    if (!isLoading && assessments?.data?.length && assessmentId) {
      const ndx = assessments.data.findIndex((item) => item._id === assessmentId);
      if (ndx > -1) {
        setCurrentAssessment(assessments.data[ndx]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, assessmentId, assessments?.data]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => {
          return <CompanyLogoSquare defaultCompany={company} />;
        },
      },
      { title: "Name", field: "name" },
      {
        title: "Job",
        field: "jobSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      {
        title: "Date Created",
        field: "createdAt",
        customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
      },
      { title: "Type", field: "type" },
      {
        title: "Assessment Actions",
        field: "_id",
        onClick: (e) => e.stopPropagation(),
        customCell: (_id, field, row) => {
          // if (parent === "jobs")
          return (
            <AssessmentsTableActions
              assessmentId={_id}
              setNavigateUrl={navigateWithBack}
              row={row}
              setActionSelected={setActionSelected}
              setOpen={setOpen}
              setAssessmentPreview={setAssessmentPreview}
              parent={parent}
              currentApplicant={currentApplicant}
              isCompany={isCompany}
            />
          );
        },
      },
    ],
    [
      company,
      navigateWithBack,
      setActionSelected,
      setOpen,
      setAssessmentPreview,
      parent,
      currentApplicant,
      isCompany,
    ]
  );

  const handleRowClick = (assessment, e) => {
    e.stopPropagation();
    setCurrentAssessment(assessment);
    if (parent?.toLowerCase() === "assessments") setNavigateUrl(`/assessments/${assessment._id}`);
    else navigateWithBack(`/assessments/${assessment._id}`);
  };

  const addNew = () => {
    if (!currentAssessment) {
      setCurrentAssessment({});
    }
    if (parent?.toLowerCase() === "assessments") setNavigateUrl("/assessments/create");
    else navigateWithBack("/assessments/create");
  };

  useEffect(() => {
    if (!openReportMessageModal) {
      setChartTableSrc("");
      setChartSrc("");
      setSelectedSubtables([]);
    }
  }, [openReportMessageModal]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} pr={2}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="warning"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-6}
            >
              <Icon fontSize="large">work</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          {showSearchBar && (
            <Searchbar
              fetch={fetchAssessments}
              fetchAll={false}
              placeholder="Search Assessment"
              columns={columns.slice(1, columns.length - 1)}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["name", "jobSlug"]}
              filterBy="_id"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={assessments}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading}
          defaultSort="_id"
          idField="_id"
        />
      </MDBox>
      {pageLimitConfirmationModal}
    </Card>
  );
};

// Setting default values for the props
AssessmentsTable.defaultProps = {
  fetchAll: false,
  setNavigateUrl: () => {},
  title: "Assessments Table",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
AssessmentsTable.propTypes = {
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default AssessmentsTable;
