import { useEffect, useRef, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { Checkbox, Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller, useForm } from "react-hook-form";
import useDropdowns from "hooks/useDropdowns";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { directDepositSchema } from "data/directDeposit";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { useAppContextController } from "context/AppContext";
import ConfirmationModal from "components/ConfirmationModal";
import VoidedCheckUploadModal from "components/VoidedCheckUploadModal";
import DirectDepositCommonFields from "./DirectDepositCommonFields";

const defaultValues = {
  account1: "",
  account2: "",
  accountType1: "",
  accountType2: "",
  amountPercentage1: "",
  amountPercentage2: "",
  bankName1: "",
  bankName2: "",
  date: new Date().toISOString(),
  paymentType: "",
  routing1: "",
  routing2: "",
};

const DirectDepositFormFields = ({ onSubmit }) => {
  const resolver = useYupValidationResolver(directDepositSchema);
  const { applicant, updateButtons, buttonState, currentFormState, updateCurrentFormState } =
    useNewApplicantContext();

  const { currentApplicant, user, userType, company } = useAppContextController();
  const isAdmin = userType === "Master" || userType === "Admin";

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    trigger,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitSuccessful, submitCount },
  } = useForm({ defaultValues, resolver, mode: "onBlur" });

  const paymentType = watch("paymentType");
  const isDisabled =
    !paymentType || ["EmployerIssuedPaperCheck", "BranchVirtualWallet"].includes(paymentType);

  const handleCheckStubOptionsChange = (e) => {
    setValue("checkStubOptions", e.target.id, { shouldDirty: true });
  };

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isBranchWalletConfirmOpen, setBranchWalletConfirmOpen] = useState(false);
  const [pendingPaymentType, setPendingPaymentType] = useState(null);
  const [isVoidedCheckModalOpen, setVoidedCheckModalOpen] = useState(false);

  const handlePaymentTypeChange = (e) => {
    if (
      e.target.id === "DirectDeposit" &&
      !isAdmin &&
      company?.depositOptions?.branchVirtualWallet === "Yes"
    ) {
      setPendingPaymentType(e.target.id);
      setVoidedCheckModalOpen(true);
    } else if (e.target.id === "EmployerIssuedPaperCheck") {
      setPendingPaymentType(e.target.id);
      setIsConfirmationOpen(true);
    } else if (e.target.id === "BranchVirtualWallet") {
      setPendingPaymentType(e.target.id);
      setBranchWalletConfirmOpen(true);
    } else {
      setValue("paymentType", e.target.id, { shouldDirty: true });
    }
  };

  const clearDirectDepositFields = () => {
    setValue("bankName1", "", { shouldDirty: true });
    setValue("bankName2", "", { shouldDirty: true });
    setValue("routing1", "", { shouldDirty: true });
    setValue("routing2", "", { shouldDirty: true });
    setValue("account1", "", { shouldDirty: true });
    setValue("account2", "", { shouldDirty: true });
    setValue("accountType1", "", { shouldDirty: true });
    setValue("accountType2", "", { shouldDirty: true });
    setValue("amountPercentage1", "", { shouldDirty: true });
    setValue("amountPercentage2", "", { shouldDirty: true });
  };

  const { dropdowns: accountTypes } = useDropdowns({
    entity: "accountTypes",
    outsideMode: user ? "" : "protected",
  });

  useEffect(() => {
    reset(
      {
        ...defaultValues,
        ...(applicant.directDeposit ?? {}),
      },
      { keepErrors: false }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant]);

  const [canContinue, setCanContinue] = useState(false);
  const renderKey = useRef(0); // Used to prevent race condition when running useEffect with promises

  useEffect(() => {
    const currentRenderKey = Math.round(Math.random() * 10000);
    renderKey.current = currentRenderKey;
    if (applicant && applicant.directDeposit) {
      directDepositSchema
        .validate(applicant.directDeposit)
        .then(() => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(true);
          }
        })
        .catch((error) => {
          if (renderKey.current === currentRenderKey) {
            setCanContinue(false);
          }
        });
    } else if (renderKey.current === currentRenderKey) {
      setCanContinue(false);
    }
  }, [applicant]);

  useEffect(() => {
    updateButtons({
      ...buttonState,
      next: {
        show: true,
        disabled: !canContinue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canContinue]);

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: !canContinue,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (!isValid && submitCount < 1) {
      const bankInfoArr = [
        "account1",
        "bankName1",
        "accountType1",
        "routing1",
        "amountPercentage1",
        "account2",
        "bankName2",
        "accountType2",
        "routing2",
        "amountPercentage2",
      ];
      const subscription = watch((formData, { name, type }) => {
        if (bankInfoArr.includes(name)) {
          if (formData[name]) {
            setValue("paymentType", "DirectDeposit", { shouldDirty: true });
            if (name !== "amountPercentage1" || name !== "amountPercentage2") {
              if (!getValues(`amountPercentage${name.at(-1)}`)) {
                setValue(`amountPercentage${name.at(-1)}`, 100, { shouldDirty: true });
              }
            }
          }
        }
      });
      return () => subscription.unsubscribe();
    }
    return "";
  }, [watch, submitCount, isValid]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} id="current-form">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <MDTypography variant="h5"> Payment Type Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="paymentType"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  {(isAdmin || company?.depositOptions?.directDeposit === "Yes") && (
                    <Grid item xs={12} lg={3}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Checkbox
                          id="DirectDeposit"
                          checked={field.value === "DirectDeposit"}
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CircleIcon />}
                          onChange={(e) => handlePaymentTypeChange(e)}
                        />
                        <MDTypography fontWeight="bold" variant="body2">
                          Direct deposit
                        </MDTypography>
                      </Stack>
                    </Grid>
                  )}
                  {(isAdmin || company?.depositOptions?.moneyNetworkService === "Yes") && (
                    <Grid item xs={12} lg={3}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Checkbox
                          id="MoneyNetworkService"
                          checked={field.value === "MoneyNetworkService"}
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CircleIcon />}
                          onChange={(e) => handlePaymentTypeChange(e)}
                        />
                        <MDTypography fontWeight="bold" variant="body2">
                          Money Network Service
                        </MDTypography>
                      </Stack>
                    </Grid>
                  )}
                  {company?.companyType === "Venue" &&
                    company?.depositOptions?.branchVirtualWallet === "Yes" && (
                      <Grid item xs={12} lg={3}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Checkbox
                            id="BranchVirtualWallet"
                            checked={field.value === "BranchVirtualWallet"}
                            icon={<CircleOutlinedIcon />}
                            checkedIcon={<CircleIcon />}
                            onChange={(e) => handlePaymentTypeChange(e)}
                          />
                          <MDTypography fontWeight="bold" variant="body2">
                            Branch Virtual Wallet
                          </MDTypography>
                          {currentApplicant?.directDeposit?.paymentType === "BranchVirtualWallet" &&
                            currentApplicant.branchWallet && (
                              <MDTypography color="info">
                                ({currentApplicant.branchWallet?.status})
                              </MDTypography>
                            )}
                        </Stack>
                      </Grid>
                    )}
                  {(isAdmin || company?.depositOptions?.employerIssuedPaperCheck === "Yes") && (
                    <Grid item xs={12} lg={3}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Checkbox
                          id="EmployerIssuedPaperCheck"
                          checked={field.value === "EmployerIssuedPaperCheck"}
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CircleIcon />}
                          onChange={(e) => handlePaymentTypeChange(e)}
                        />
                        <MDTypography fontWeight="bold" variant="body2">
                          Employer-Issued Paper Check
                        </MDTypography>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          />
        </Grid>
        <DirectDepositCommonFields
          control={control}
          isDisabled={isDisabled}
          accountTypes={accountTypes}
          setValue={setValue}
          title="Bank One Information"
          number="1"
          trigger={trigger}
          watch={watch}
        />

        <DirectDepositCommonFields
          control={control}
          isDisabled={isDisabled}
          accountTypes={accountTypes}
          setValue={setValue}
          title="Bank Two Information"
          number="2"
          watch={watch}
        />
        {/* <Grid item sm={12}>
          <MDTypography variant="h5"> Check Stub Options:</MDTypography>
        </Grid>
        <Grid item sm={12}>
          <Controller
            name="checkStubOptions"
            control={control}
            render={({ field }) => (
              <>
                <Grid container>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="PrintStubOnly"
                        checked={field.value === "PrintStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        {" "}
                        Print Stub Only
                      </MDTypography>
                    </Stack>
                  </Grid>
                  <Grid item sm={6}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Checkbox
                        id="EmailStubOnly"
                        checked={field.value === "EmailStubOnly"}
                        icon={<CircleOutlinedIcon />}
                        checkedIcon={<CircleIcon />}
                        onChange={(e) => handleCheckStubOptionsChange(e)}
                      />
                      <MDTypography fontWeight="bold" variant="body2">
                        Email Stub Only
                      </MDTypography>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </Grid> */}
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Email Address"
            value={applicant?.email || ""}
          />
        </Grid>
        <Grid item sm={8} />
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Employee Name"
            value={`${applicant?.firstName} ${applicant?.lastName}`}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CustomTextField
            variant="outlined"
            disabled
            label="Social Security"
            value={applicant?.socialSecurity || ""}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="date"
            control={control}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date"
                  renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                  {...field}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
      </Grid>
      <FormErrors errors={errors} />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => {
          setIsConfirmationOpen(false);
          setPendingPaymentType(null);
        }}
        title="Warning: Paper Check Selection"
        body="Are you certain you want to receive paper checks via USPS? The company will not be held responsible for any lost checks or incorrect addresses. Please note that it may take up to 60 days to receive your payment."
        onConfirm={(e) => {
          e.stopPropagation();
          setValue("paymentType", pendingPaymentType, { shouldDirty: true });
          setIsConfirmationOpen(false);
          setPendingPaymentType(null);
          clearDirectDepositFields();
        }}
      />
      <ConfirmationModal
        isOpen={isBranchWalletConfirmOpen}
        onClose={() => {
          setBranchWalletConfirmOpen(false);
          setPendingPaymentType(null);
        }}
        title="Branch Virtual Wallet"
        body="The Branch App is a free digital account and debit card that provides easier access to your pay, with no minimum balance, no credit check, and zero monthly fees. While we are partnering with Branch, all matters related to this account will be handled directly between you and Branch."
        onConfirm={(e) => {
          e.stopPropagation();
          setValue("paymentType", pendingPaymentType, { shouldDirty: true });
          setBranchWalletConfirmOpen(false);
          setPendingPaymentType(null);
          clearDirectDepositFields();
        }}
      />
      <VoidedCheckUploadModal
        open={isVoidedCheckModalOpen}
        setOpen={setVoidedCheckModalOpen}
        currentApplicant={applicant}
        onUploadSuccess={(checkData) => {
          setValue("paymentType", pendingPaymentType, { shouldDirty: true });
          setValue("routing1", checkData.routing1);
          setValue("account1", checkData.account1);
          setValue("bankName1", checkData.bankName1);
          setPendingPaymentType(null);
          const matchedType = accountTypes?.find(
            (type) => type.toLowerCase() === checkData.accountType1.toLowerCase()
          );
          if (matchedType) {
            setValue("accountType1", matchedType);
          }
        }}
        onSwitchToBranch={() => {
          setPendingPaymentType("BranchVirtualWallet");
          setBranchWalletConfirmOpen(true);
        }}
        outsideMode={user ? "" : "protected"}
      />
    </form>
  );
};

export default DirectDepositFormFields;
