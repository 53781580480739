import {
  ConnectWithoutContact,
  PersonAdd,
  FolderShared,
  QuestionAnswer,
  ThumbDown,
  Info,
  CheckCircle,
  PersonOff,
  Cancel,
  Login,
  SmartToy,
  Groups,
  HowToReg,
  Event,
  HourglassEmpty,
  Work,
  AccessTime,
  EmojiPeople,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { commonColumnAttributes } from "./styles";

const iconStatus = {
  ATC: <ConnectWithoutContact color="warning" fontSize="medium" />,
  Declined: <ThumbDown color="error" fontSize="medium" />,
  New: <PersonAdd fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Pre-Hire": <FolderShared color="success" fontSize="medium" />,
  Screened: <QuestionAnswer color="info" fontSize="medium" />,
};

const APPLICANT_DETAIL = [
  { field: "applicant", headerName: "Applicant", flex: 1 },
  {
    field: "applyDate",
    headerName: "Apply Date",
    ...commonColumnAttributes,
    valueGetter: ({ value }) =>
      new Date(value)?.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }),
  },
  {
    field: "source",
    headerName: "Source",
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "applicantStatus",
    headerName: "Status",
    sortable: false,
    ...commonColumnAttributes,
    renderCell: ({ value }) => {
      return iconStatus?.[value];
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    ...commonColumnAttributes,
    renderCell: () => (
      <IconButton>
        <Info color="info" fontSize="medium" />
      </IconButton>
    ),
    // only one, not dynamic. separate to actions later on
  },
];

const JOB_APPLIES = [
  { field: "source", headerName: "Source", flex: 1 },
  {
    field: "quantity",
    headerName: "Quantity",
    ...commonColumnAttributes,
  },
  {
    field: "new",
    headerName: <PersonAdd fontSize="medium" sx={{ color: "#9C27B0" }} />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "atc",
    headerName: <ConnectWithoutContact color="warning" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "screened",
    headerName: <QuestionAnswer color="info" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "prehire",
    headerName: <FolderShared color="success" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
  {
    field: "decline",
    headerName: <ThumbDown color="error" fontSize="medium" />,
    sortable: false,
    ...commonColumnAttributes,
  },
];

const APPLICANT_STAGE_ACTIVITY_ICONS = {
  Atc: <ConnectWithoutContact color="warning" fontSize="medium" />,
  Declined: <ThumbDown color="error" fontSize="medium" />,
  New: <PersonAdd fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Pre-Hire": <FolderShared color="success" fontSize="medium" />,
  Screened: <QuestionAnswer color="info" fontSize="medium" />,
};

const INTERVIEW_ACTIVITY_ICONS = {
  "AI Interviews Completed": <SmartToy fontSize="medium" sx={{ color: "#0070C0" }} />,
  "AI Declines": <ThumbDown fontSize="medium" sx={{ color: "#CC3300" }} />,
  "Group Interviews Scheduled": <Groups fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Staff Signed up for Group Interviews": <PersonAdd fontSize="medium" sx={{ color: "#E28700" }} />,
  "Staff Clocked in at Group Interviews": <HowToReg fontSize="medium" sx={{ color: "#008000" }} />,
};

const EVENT_ACTIVITY_ICONS = {
  "Total Events": <Event fontSize="medium" sx={{ color: "#0070C0" }} />,
  "Staff Signed Up": <PersonAdd fontSize="medium" sx={{ color: "#008000" }} />,
  "Staff on Waitlist": <HourglassEmpty fontSize="medium" sx={{ color: "#E28700" }} />,
  "Staff Currently Working": <Work fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Staff Clocked In This Week": <AccessTime fontSize="medium" sx={{ color: "#0070C0" }} />,
  "First-Time Staff": <EmojiPeople fontSize="medium" sx={{ color: "#008000" }} />,
};

const STAFFING_POOL_ICONS = {
  All: <Info fontSize="medium" sx={{ color: "#0070C0" }} />,
  Active: <CheckCircle fontSize="medium" sx={{ color: "#008000" }} />,
  Inactive: <PersonOff fontSize="medium" sx={{ color: "#E28700" }} />,
  "Do Not Use": <Cancel fontSize="medium" sx={{ color: "#CC3300" }} />,
  Terminated: <Cancel fontSize="medium" sx={{ color: "#CC3300" }} />,
  Partners: <Groups fontSize="medium" sx={{ color: "#9C27B0" }} />,
  "Logged In": <Login fontSize="medium" sx={{ color: "#0070C0" }} />,
  "No Login": <Login fontSize="medium" sx={{ color: "#0070C0" }} />,
};

export {
  APPLICANT_DETAIL,
  JOB_APPLIES,
  APPLICANT_STAGE_ACTIVITY_ICONS,
  STAFFING_POOL_ICONS,
  INTERVIEW_ACTIVITY_ICONS,
  EVENT_ACTIVITY_ICONS,
};
