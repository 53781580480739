import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const AssessmentsTableActions = ({
  assessmentId,
  setNavigateUrl,
  row,
  setFilters,
  setOpen,
  setAssessmentPreview,
  parent = "jobs",
  currentApplicant,
  isCompany,
}) => {
  const navigate = useNavigate();

  const navigateLocal = setNavigateUrl ?? navigate;

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    navigateLocal(`/assessments/${assessmentId}/action/${action}`);
  };

  const onClickViewJobHandler = (e) => {
    e.stopPropagation();
    navigate(`/${isCompany ? "companyjobs" : "jobs"}/${row?.jobSlug}/action/jobinfo`);
  };

  const setPreviewOpen = (job, e) => {
    e.stopPropagation();
    setAssessmentPreview(job);
    setOpen(true);
  };
  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("assessmentinfo", e)}
        tooltip="Assessment Info"
      />
      <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickViewJobHandler(e)}
        disabled={!row?.jobSlug}
        tooltip={row?.jobSlug ? "View Job" : "No job available"}
      />
    </span>
  );
};

AssessmentsTableActions.defaultProps = {
  assessmentId: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
AssessmentsTableActions.propTypes = {
  assessmentId: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};

export default AssessmentsTableActions;
