import { useEffect, useMemo, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import {
  Cancel as CancelIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Send as SendIcon,
  SendOutlined as SendOutlinedIcon,
  ThumbDown as ThumbDownIcon,
} from "@mui/icons-material";
import JobsStatusRankStars from "components/JobsStatusRankStars";
import MDBox from "components/MDBox";
import PictureAvatar from "components/PictureAvatar";
import CustomTextField from "components/Form/CustomTextField";
import updateApplicantStage from "layouts/pages/applicants/actions/updateApplicantStatus";
import updateApplicantJobAIInterviews from "layouts/pages/applicants/actions/updateApplicantJobAIInterviews";
import pushApplicantJobAIInterviewMessage from "layouts/pages/applicants/actions/pushApplicantJobAIInterviewMessage";
import jobAIInterviewProcessConversation from "layouts/pages/applicants/actions/jobAIInterviewProcessConversation";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import {
  AI_INTERVIEWS_FIRST_MESSAGE,
  AI_INTERVIEWS_LAST_MESSAGE,
} from "utils/constants/aiInterviews";
import AIInterviewChatWindow from "../AIInterviewChatWindow";

const styles = {
  container: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "80vh",
    height: "100%",
    maxWidth: 1170,
    padding: "2rem",
    // overflow: "auto",
  },
};

const ApplicantAIInterviewModal = ({
  open,
  onClose,
  applicant,
  questions,
  history,
  jobSlug,
  onAddMessage,
  interviewEndDate,
  onPressUpdateInfo,
  onPressBackToHome,
  listRef,
  onAutoScreened,
}) => {
  const [questionIndex, setQuestionIndex] = useState(null);
  const { currentLoggedUser, userType, company, currentJob } = useAppContextController();
  const [textValue, setTextValue] = useState("");

  const [localHistory, setLocalHistory] = useState([]);
  const localHistoryRef = useRef([]);
  const [interviewFinished, setInterviewFinished] = useState(false);
  const isWaitingForAIResponseRef = useRef(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const updateAiInterviewMutation = useMutation(pushApplicantJobAIInterviewMessage, {
    onSuccess: async (_, { id, data, isLast }) => {
      onAddMessage?.(data, isLast);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "pushApplicantJobAIInterviewMessageMutation",
  });

  const processConversationMutation = useMutation(jobAIInterviewProcessConversation, {
    onSuccess: async (_, { id, data, isLast }) => {
      isWaitingForAIResponseRef.current = false;
    },
    onError: (error) => {
      isWaitingForAIResponseRef.current = false;
      enqueueSnackbar(error.message, { variant: "error" });
    },
    mutationKey: "jobAIInterviewProcessConversationMutation",
  });

  const onPushNextQuestion = (messageIndex) => {
    processConversationMutation
      .mutateAsync({
        id: applicant._id,
        jobSlug,
        messageArray: localHistoryRef.current.map((msg) => ({
          role: msg.isAnswer ? "user" : "assistant",
          content: msg.message,
        })),
        outsideMode: "protected",
      })
      .then((data) => {
        isWaitingForAIResponseRef.current = false;
        if (data?.data) {
          const newQuestionHistory = data?.data?.messages ?? [];

          if (
            data.data.explanation === "Screening Complete" ||
            (newQuestionHistory.length &&
              newQuestionHistory[newQuestionHistory.length - 1]?.content === "Screening Complete")
          ) {
            // No more questions, send last message and close the interview
            const messageData = {
              type: "other",
              index: messageIndex,
              message: company?.aiSettings?.aiInterviewsLastMessage ?? AI_INTERVIEWS_LAST_MESSAGE,
              timestamp: new Date().toISOString(),
              isAnswer: false,
            };
            updateAiInterviewMutation.mutate({
              id: applicant._id,
              jobSlug,
              data: messageData,
              outsideMode: "protected",
              isLast: true,
            });
            setLocalHistory((lh) => [...lh, messageData]);
            localHistoryRef.current = [...localHistoryRef.current, messageData];
            setInterviewFinished(true);

            if (data?.data?.screeningFinalDecision === "Screened") {
              // Applicant got auto-screened. Change steps to onboarding
              onAutoScreened();
            }
          } else {
            // Look for the last assistant question in the new history array
            for (let index = newQuestionHistory.length - 1; index >= 0; index -= 1) {
              const question = newQuestionHistory[index];
              if (question.role === "assistant") {
                const messageData = {
                  type: "unknown",
                  index: messageIndex,
                  message: question.content,
                  timestamp: new Date().toISOString(),
                  isAnswer: false,
                };
                updateAiInterviewMutation.mutate({
                  id: applicant._id,
                  jobSlug,
                  data: messageData,
                  outsideMode: "protected",
                });
                setLocalHistory((lh) => [...lh, messageData]);
                localHistoryRef.current = [...localHistoryRef.current, messageData];
                return;
              }
            }
            enqueueSnackbar(
              "An error has ocurred loading the next question. Please try again later.",
              { variant: "error" }
            );
          }
        } else {
          enqueueSnackbar(
            "An error has ocurred loading the next question. Please try again later.",
            { variant: "error" }
          );
        }
      });
  };

  useEffect(() => {
    if (!(history ?? []).length) {
      // If interview hasn't started yet, push the first message from the bot
      const messageData = {
        type: "other",
        index: 0,
        message: company?.aiSettings?.aiInterviewsFirstMessage ?? AI_INTERVIEWS_FIRST_MESSAGE,
        timestamp: new Date().toISOString(),
        isAnswer: false,
      };
      updateAiInterviewMutation.mutate({
        id: applicant._id,
        jobSlug,
        data: messageData,
        outsideMode: "protected",
      });
      setLocalHistory([messageData]);
      localHistoryRef.current = [messageData];
    } else {
      setLocalHistory([...history]);
      localHistoryRef.current = [...history];
      if (interviewEndDate) {
        setInterviewFinished(true);
      } else if (history[history.length - 1]?.isAnswer) {
        // If the last message in the history is a response from the user, then load the next question
        isWaitingForAIResponseRef.current = true;
        onPushNextQuestion(localHistoryRef.current.length);
      }
    }
  }, []);

  const onPressSend = () => {
    if (isWaitingForAIResponseRef.current) return;

    const messageData = {
      type: "answer",
      index: localHistoryRef.current.length,
      message: textValue,
      timestamp: new Date().toISOString(),
      isAnswer: true,
    };
    setLocalHistory((lh) => [...lh, messageData]);
    localHistoryRef.current = [...localHistoryRef.current, messageData];
    setTextValue("");
    isWaitingForAIResponseRef.current = true;
    updateAiInterviewMutation
      .mutateAsync({
        id: applicant._id,
        jobSlug,
        data: messageData,
        outsideMode: "protected",
      })
      .then(() => onPushNextQuestion(localHistoryRef.current.length));
  };

  const onPressKey = (e) => {
    if (e.keyCode === 13 && !interviewFinished) {
      onPressSend();
    }
  };

  useEffect(() => {
    listRef.current?.lastElementChild?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localHistory.length, processConversationMutation.isLoading]);

  const shouldDisableCopyPaste = process.env.REACT_APP_ENV === "production";
  const handleCopyPaste = (e) => e.preventDefault();

  return (
    <div style={{ position: "relative" }}>
      <Modal
        open={open}
        onClose={(event, reason) => {
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card style={styles.container}>
            <Button
              sx={{
                position: "absolute",
                top: "0%",
                right: "0%",
                m: 2,
                p: 0,
              }}
              onClick={() => {
                onClose();
              }}
              size="large"
            >
              <CancelIcon color="secondary" />
            </Button>
            <Grid container item flexDirection="column" height="100%">
              <Grid
                item
                container
                sx={{
                  borderBottomWidth: 2,
                  borderBottomColor: "#8f9095",
                  borderBottomStyle: "solid",
                  paddingBottom: 1,
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={null}
                  alt={`${applicant?.firstName} ${applicant?.lastName} picture`}
                  sx={{ height: "50px", width: "50px" }}
                >
                  {`${applicant?.firstName?.[0] ?? ""}${
                    applicant?.lastName?.[0] ?? ""
                  }`.toUpperCase()}
                </Avatar>
                <MDBox display="flex" flexDirection="column" pl={1}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="dark">
                    {`${applicant?.firstName ?? ""} ${applicant.lastName ?? ""}`}
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item container sx={{ flexBasis: 0, flexGrow: 1, overflow: "hidden" }}>
                <AIInterviewChatWindow
                  firstName={applicant?.firstName}
                  lastName={applicant?.lastName}
                  items={localHistory}
                  inverted
                  interviewFinished={interviewFinished}
                  onPressUpdateInfo={onPressUpdateInfo}
                  onPressBackToHome={onPressBackToHome}
                  listRef={listRef}
                  isLoadingResponse={processConversationMutation.isLoading}
                  shouldDisableCopy={shouldDisableCopyPaste}
                />
              </Grid>
              <TextField
                onChange={(e) => setTextValue(e.target.value)}
                value={textValue}
                placeholder="Type a new message here"
                onKeyDown={onPressKey}
                onCut={shouldDisableCopyPaste ? handleCopyPaste : undefined}
                onCopy={shouldDisableCopyPaste ? handleCopyPaste : undefined}
                onPaste={shouldDisableCopyPaste ? handleCopyPaste : undefined}
                inputProps={{ maxLength: 600 }}
                disabled={interviewFinished}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="secondary"
                        onClick={onPressSend}
                        edge="end"
                        disabled={interviewFinished}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

// Setting default values for the props of CalendarDayModal
ApplicantAIInterviewModal.defaultProps = {};

// Typechecking props of the CalendarDayModal
ApplicantAIInterviewModal.propTypes = {};

export default ApplicantAIInterviewModal;
