import { baseAxios } from "config";

export const sendAssessmentOTP = async (assessmentId, applicantId) => {
  try {
    const res = await baseAxios.post(
      `/outside-public/assessments/${assessmentId}/applicants/${applicantId}/send-otp`
    );
    return res.data;
  } catch (err) {
    // Extract and preserve the actual error message from the API
    console.log("API Error Response:", err.response?.data);
    if (err.response?.data?.error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        apiError: true,
        message: err.response.data.error,
        originalError: err,
      };
    }
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};

export const verifyAssessmentOTP = async (assessmentId, applicantId, otp) => {
  try {
    const res = await baseAxios.post(
      `/outside-public/assessments/${assessmentId}/applicants/${applicantId}/verify-otp`,
      { otp }
    );
    return res.data;
  } catch (err) {
    // Extract and preserve the actual error message from the API
    console.log("API Error Response:", err.response?.data);
    if (err.response?.data?.error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        apiError: true,
        message: err.response.data.error,
        originalError: err,
      };
    }
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};
