import { baseAxios } from "config";

const fetchShiftJobPunches = async ({ jobId, shiftSlug }) => {
  try {
    const res = await baseAxios.get(`/jobs/${jobId}/shift/${shiftSlug}`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchShiftJobPunches;
