import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { AttachFileRounded, Preview, Visibility } from "@mui/icons-material";
import { useSnackbar } from "notistack";

const FormsList = ({ forms, loading, onViewForm }) => {
  const { enqueueSnackbar } = useSnackbar();
  let content;

  const handlePreviewForm = (formId) => {
    // onViewForm(formId);
    enqueueSnackbar("Displaying form preview", { variant: "info" });
  };

  const handleViewAttachment = (formId) => {
    // onViewForm(formId);
    enqueueSnackbar("Loading original document", { variant: "info" });
  };

  if (loading) {
    content = (
      <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
        <CircularProgress />
      </Box>
    );
  } else if (forms.length === 0) {
    content = (
      <Typography variant="body1" sx={{ textAlign: "center", my: 4 }}>
        No forms found. Click the + button to add a new form.
      </Typography>
    );
  } else {
    content = (
      <List>
        {forms.map((form) => (
          <ListItem key={form.id} divider>
            <ListItemText
              primary={form.name}
              secondary={`Original file: ${form.originalFilename} | Created: ${new Date(
                form.createdAt
              ).toLocaleString()}`}
            />
            <ListItemSecondaryAction sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="Preview form">
                <IconButton edge="end" onClick={() => handlePreviewForm(form.id)}>
                  <Preview />
                </IconButton>
              </Tooltip>
              <Tooltip title="View original document">
                <IconButton edge="end" onClick={() => handleViewAttachment(form.id)}>
                  <AttachFileRounded />
                </IconButton>
              </Tooltip>
              <Tooltip title="View all form fields">
                <IconButton edge="end" onClick={() => onViewForm(form.id)}>
                  <Visibility />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }

  return <Box>{content}</Box>;
};

FormsList.propTypes = {
  forms: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onViewForm: PropTypes.func.isRequired,
};

export default FormsList;
