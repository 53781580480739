import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteModal from "components/ConfirmDialog/ConfirmModal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
// @mui material components
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import FormField from "layouts/applications/wizard/components/FormField";
// NewProduct page components
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";
import { PatternFormat } from "react-number-format";

// npm packages
import { Button, IconButton, Stack, styled, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import ConfirmDialog from "components/ConfirmDialog";
import updateUser from "api/users/updateUser";
import ApplicantStageChangeModal from "layouts/pages/applicants/components/ApplicantStageChangeModal";
import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import SplitPanelSelector from "components/SplitPanelSelector";
import useDropdowns from "hooks/useDropdowns";
import createUser from "layouts/pages/users/actions/createUser";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchUsers from "layouts/pages/users/actions/fetchUsers";
import fetchUserIdentity from "../../actions/fetchUserIdentity";

const ProfileMainInfoPanelForm = ({
  parent = "Applicant",
  setFilters,
  currentUser,
  mode = "edit",
  setCurrentUser,
  applicantTabs,
  isEdit,
  currentUserType,
  loggedUserType,
  company,
  currentLoggedUser,
  setPanelSelect,
  panelSelect,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmModal, setConfirmModal] = useState({
    modalFor: "",
    isOpen: false,
    title: "",
    bodyText: "",
    response: false,
  });
  const isVenue = company?.companyType === "Venue";
  const { dropdowns: userTypes } = useDropdowns({ entity: "userTypes" });
  const { dropdowns: states } = useDropdowns({ entity: "stateCodes" });
  const { dropdowns: userStatusField } = useDropdowns({ entity: "userStatusField" });
  const { dropdowns: userSPStatusField } = useDropdowns({ entity: "userSPStatusField" });
  const { dropdowns: userSPStatusFieldUsers } = useDropdowns({ entity: "userSPStatusFieldUsers" });
  const { dropdowns: employeeTypes } = useDropdowns({ entity: "employeeType", enabled: isVenue });
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [applicantStageModalOpen, setApplicantStageModalOpen] = useState(false);
  const [userApplicant, setUserApplicant] = useState(null);
  const [userIdentityVerification, setUserIdentityVerification] = useState("Unsubmitted");
  const [identityVerificationColor, setIdentityVerificationColor] = useState("dark");
  const [isModified, setIsModified] = useState(false);
  const deleteUserModalHandler = () => setOpenDeleteUserModal(!openDeleteUserModal);
  const messageRef = useRef("");

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    reset,
    setValue,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onBlur" });

  const defaultValues = {
    firstName: "",
    lastName: "",
    userType: null,
    userId: "",
    applicant: {
      firstName: "",
      lastName: "",
      address1: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      altPhone: "",
      emailVerified: "No",
    },
    emailAddress: "",
    organization: company?.name || "",
    employeeType: "",
    master: "No",
    status: null,
    spStatus: null,
    logoVerified: "No",
    endDate: null,
    startDate: null,
    firstDate: null,
    ...(userApplicant?.userType === "Client" && { clientOrgs: [] }),
  };

  const emailAddress = {
    ...register("emailAddress", {
      required: "Email is Required",
      pattern: {
        // eslint-disable-next-line
        value: /^[a-z0-9_.-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/,
        message: "Your email address is invalid",
      },
      validate: {
        repeated: async (value) => {
          if (mode === "create") {
            const filteredApplicants = await fetchApplicants({
              filters: { email: value },
              fetchAll: true,
            });
            const filteredUsers = await fetchUsers({
              filters: { email: value },
              fetchAll: true,
            });
            const anyUser = !filteredApplicants?.data && !filteredUsers?.data;
            return anyUser || "this email is already in use ";
          }
          return true;
        },
      },
    }),
  };

  const firstName = {
    ...register("firstName", {
      required: "First Name is Required",
      pattern: {
        value: /^[A-Za-z\s]*$/,
        message: "First Name contains invalid characters.",
      },
    }),
  };
  const lastName = {
    ...register("lastName", {
      required: "Last Name is Required",
      pattern: {
        value: /^[A-Za-z\s]*$/,
        message: "Last Name contains invalid characters.",
      },
    }),
  };

  const organization = {
    ...register("organization"),
  };
  const address1 = { ...register("applicant.address1") };

  const startDate = {
    ...register("startDate", {
      validate: {
        exist: (value) => {
          if (getValues("endDate") && getValues("startDate")) {
            return (
              moment(value).isBefore(getValues("endDate")) ||
              "The Start Date can’t be later than the End Date"
            );
          }
          return true;
        },
      },
    }),
  };
  const employeeType = {
    ...register("employeeType", {
      validate: {
        exist: (value) =>
          employeeTypes?.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Employee Type",
      },
    }),
  };
  const status = {
    ...register("status", {
      validate: {
        exist: (value) =>
          userStatusField?.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Status",
      },
    }),
  };
  const userType = {
    ...register("userType", {
      validate: { exist: (value) => userTypes?.includes(value) || "Insert a valid user type" },
    }),
  };
  const phone = {
    ...register("applicant.phone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };
  const altPhone = {
    ...register("applicant.altPhone", {
      pattern: {
        // eslint-disable-next-line
        value: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/,
        message: "This phone is invalid",
      },
    }),
  };
  const title = {
    ...register("title"),
  };

  const city = { ...register("applicant.city") };

  const state = {
    ...register("applicant.state", {
      validate: { exist: (value) => states?.includes(value) || "Insert a valid state" },
    }),
  };
  const zip = {
    ...register("applicant.zip", {
      // eslint-disable-next-line
      pattern: { value: /^[0-9]{5}(?:-[0-9]{4})?$/, message: "Zip code is invalid" },
    }),
  };
  const endDate = {
    ...register("endDate", {
      validate: {
        exist: (value) => {
          if (getValues("startDate") && getValues("endDate")) {
            return (
              moment(value)?.isAfter(getValues("startDate")) ||
              "The End Date can’t be earlier than the Start Date"
            );
          }
          return true;
        },
      },
    }),
  };

  const spStatusOptions =
    watch("userType") === "User" ? userSPStatusFieldUsers || [] : userSPStatusField || [];

  const spStatus = {
    ...register("spStatus", {
      validate: {
        exist: (value) =>
          spStatusOptions?.includes(value) ||
          value === "" ||
          value === null ||
          "Insert a valid Status",
      },
    }),
  };

  const getApplicantIdentity = async (email) => {
    const identityVerification = await fetchUserIdentity(email);
    if (identityVerification?.status) {
      setUserIdentityVerification(identityVerification.status);
      if (identityVerification?.status === "verified") {
        setIdentityVerificationColor("success");
      } else if (identityVerification?.status === "submitted") {
        setIdentityVerificationColor("warning");
      } else {
        setIdentityVerificationColor("dark");
      }
    }
  };

  const { mutateAsync, isLoading } = useMutation(createUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, data) => {
      await queryClient.invalidateQueries("users");
      setCurrentUser({
        ...currentUser,
        ...data,
        userId: data?.emailAddress,
        _id: _.insertedId,
      });
      enqueueSnackbar("User has been created!", { variant: "success" });
      navigate(`/users/${_.insertedId}`);
    },
  });

  const updateUserMutation = useMutation(updateProfile, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),

    onSuccess: async (_, { data }) => {
      await queryClient?.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      enqueueSnackbar(messageRef?.current, { variant: "success" });
      setIsModified(false);
      setPanelSelect("activities");
    },
  });
  const isCompany = company?.companyType === "Company";

  const updateUserInfoMutation = useMutation(updateUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      await queryClient?.invalidateQueries("users");
      setCurrentUser({ ...currentUser, ...data });
      enqueueSnackbar(messageRef?.current, { variant: "success" });
    },
  });

  const resetConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      title: "",
      bodyText: "",
      response: false,
    });
  };

  const createUserHandler = async (values) => {
    const data = {};
    Object.keys(values).forEach((key) => {
      data[key] = values[key];
    });
    data.createAgent = currentLoggedUser?._id;
    await mutateAsync(data);
    setIsModified(false);
  };

  const getUserValues = () => {
    const values = {};
    Object.keys(watch()).forEach((key) => {
      values[key] = userApplicant?.[key] || "";
    });
    return values;
  };

  function diffObj(obj1, obj2) {
    const result = {};
    if (Object.is(obj1, obj2)) return undefined;
    if (!obj2 || typeof obj2 !== "object") return obj2;
    Object.keys(obj1 || {})
      .concat(Object.keys(obj2 || {}))
      .forEach((key) => {
        let val;
        if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) val = obj2[key];

        if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
          const value = diffObj(obj1[key], obj2[key]);
          if (value !== undefined) val = value;
        } else if (val !== undefined) {
          result[key] = val;
        }
      });
    return result;
  }

  const cancelChanges = () => {
    reset(getUserValues());
    setIsModified(false);
    enqueueSnackbar("Changes has been abandoned", { variant: "success" });
  };

  const saveChanges = async () => {
    if (!currentUser?.userId) {
      enqueueSnackbar("Something went wrong!, no Id", { variant: "error" });
      return;
    }
    const values = diffObj(getUserValues(), watch());

    if (JSON.stringify(getUserValues().applicant) !== JSON.stringify(watch().applicant)) {
      values.applicant = diffObj(getUserValues().applicant, watch().applicant);
    }
    // eslint-disable-next-line
    if (values.userId !== values.emailAddress) values.userId = values.emailAddress;
    messageRef.current = "User has been updated!";

    if (
      userApplicant?.userType === "User" &&
      userApplicant?.employeeType === "Event Admin" &&
      watch("clientOrgs") !== userApplicant?.clientOrgs
    )
      values.clientOrgs = watch("clientOrgs");

    await updateUserMutation.mutateAsync({
      userId: currentUser?.userId,
      data: values,
    });
  };

  const deleteUser = async () => {
    messageRef.current = "User has been deleted!";
    await updateUserInfoMutation.mutateAsync({
      userId: currentUser?._id,
      data: { status: "Deleted" },
    });
    navigate("/users");
    setFilters({});
    setCurrentUser({});
    setOpenDeleteUserModal(false);
  };

  const onCancel = () => {
    setConfirmModal({
      isOpen: true,
      title: "Data has changed!",
      bodyText: "Please Confirm to abandon Changes",
      response: false,
    });
  };

  const componentTitle = useMemo(() => {
    if (mode === "create") {
      return "Create User";
    }
    return `User: ${currentUser?.firstName} ${currentUser?.lastName}`;
  }, [mode, userApplicant]);

  useEffect(() => {
    clearErrors();
    if (mode === "create") {
      const data = {};
      Object.keys(defaultValues).forEach((key) => {
        data[key] = defaultValues[key];
      });
      setCurrentUser(data);
      reset(defaultValues);
    }
  }, [watch, mode]);

  useEffect(() => {
    if (currentUser?.emailAddress && currentLoggedUser?.userType !== "User") {
      getApplicantIdentity(currentUser?.emailAddress);
    }
  }, [currentUser?.emailAddress, currentLoggedUser?.userType]);

  useEffect(() => {
    if (mode === "edit") {
      if (userApplicant) {
        clearErrors();
        Object.keys(watch())?.forEach((key) => {
          if (typeof userApplicant[key] === "object") {
            Object.keys(watch())?.forEach((applicantKey) => {
              setValue(key?.applicantKey, userApplicant[key?.applicantKey] || "");
            });
          }
          setValue(key, userApplicant[key] || "");
        });
        setIsModified(false);
      }
      if (confirmModal?.response) {
        resetConfirmModal();
        cancelChanges();
      }
    }
    return "";
  }, [getValues, userApplicant, confirmModal?.response]);

  useEffect(() => {
    const subscription = watch((value) => {
      if (userApplicant) {
        setIsModified(JSON.stringify(getUserValues()) !== JSON.stringify(value));
      }
    });
    return () => subscription?.unsubscribe();
  }, [watch, userApplicant, getUserValues]);

  useEffect(() => {
    if (mode === "create") {
      if (watch("emailAddress").split("@").pop() === `${company?.companyUrl}`) {
        setValue("spStatus", "Employee");
      } else {
        setValue("spStatus", null);
      }
    }
  }, [watch("emailAddress"), company?.companyUrl, mode]);

  useEffect(() => {
    if (userApplicant?.userType === "User" && userApplicant?.employeeType === "Event Admin")
      setValue("clientOrgs", userApplicant?.clientOrgs);
  }, [userApplicant?._id]);

  const getUser = useCallback(async () => {
    try {
      const user = await fetchUserById(currentUser?._id);
      if (user) {
        setUserApplicant(user);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentUser]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <>
      <form
        onSubmit={mode === "edit" ? handleSubmit(saveChanges) : handleSubmit(createUserHandler)}
      >
        <Grid
          container
          key={`card_${currentUser?._id}`}
          style={{
            height: 700,
            position: "relative",
            width: "100%",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "#fff",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Grid
            item
            sx={{ width: "100%", display: "flex", justifyContent: "space-between", height: "60px" }}
          >
            <MDBox
              ml={3}
              mt={-3}
              bgColor="#fff"
              borderRadius="10px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              sx={{ boxShadow: "1px 2px 9px rgba(0, 0, 0, 0.1)" }}
            >
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="white"
                color="white"
                borderRadius="xl"
                zIndex="100"
              >
                <PictureAvatar
                  image={currentUser?.profileImg}
                  firstName={currentUser?.firstName}
                  lastName={currentUser?.lastName}
                  userId={currentUser?.userRecordId ? currentUser?.userRecordId : currentUser?._id}
                  size="md"
                />
              </MDBox>
            </MDBox>
            <Grid container>
              <Grid
                item
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <MDBox display="flex" alignItems="center">
                  {mode === "edit" && isModified && (
                    <Button
                      variant="text"
                      startIcon={<CancelIcon />}
                      onClick={onCancel}
                      disabled={isSubmitting}
                      sx={{ padding: "0.5rem 1rem" }}
                    >
                      Cancel
                    </Button>
                  )}

                  {mode === "edit" && isModified && (
                    <Button
                      type="submit"
                      variant="text"
                      startIcon={<SaveIcon />}
                      disabled={isSubmitting}
                      style={{ color: "#4CAF50" }}
                      sx={{ padding: "0.5rem 1rem" }}
                    >
                      Save
                    </Button>
                  )}
                </MDBox>
                {currentUserType === "Master" &&
                  currentUser &&
                  isEdit &&
                  currentUser?._id !== currentLoggedUser?._id && (
                    <Button
                      variant="text"
                      startIcon={<DeleteIcon />}
                      onClick={deleteUserModalHandler}
                      style={{ color: "red" }}
                      disabled={isSubmitting}
                      sx={{ padding: "0.5rem 1.5rem" }}
                    >
                      {`Delete ${parent}`}
                    </Button>
                  )}
                {mode === "create" && (
                  <Button
                    type="submit"
                    variant="contained"
                    endIcon={<SaveIcon />}
                    style={{ color: "white" }}
                    disabled={isLoading}
                    sx={{ margin: "0.5rem 1.5rem" }}
                  >
                    Create
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ minHeight: "60px", padding: "0 15px" }}>
            <Grid item sm={12} xl={5.5}>
              <MDBox display="flex" mx={2} alignItems="center">
                <MDBox>
                  <MDBox>
                    <MDTypography variant="h5" color="dark" sx={{ mr: 1 }}>
                      <MDTypography variant="p" fontSize={16}>
                        {componentTitle}
                      </MDTypography>
                    </MDTypography>
                    {isEdit && (
                      <MDTypography variant="p" fontSize={14}>
                        Last Update:
                        {moment(currentUser?.modifiedDate).format("ddd MM/DD/YYYY hh:mm a")}
                      </MDTypography>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            {isEdit && (
              <Grid item sm={12} xl={6.5}>
                <StyledStack>
                  <SplitPanelSelector
                    panelOptions={applicantTabs}
                    selectedPanel={panelSelect}
                    isEdit={isEdit}
                  />
                </StyledStack>
              </Grid>
            )}
          </Grid>
          <StyledContainer>
            <Grid container style={{ paddingBottom: "15px", width: "100%" }}>
              {mode !== "create" ? (
                <Grid item>
                  <MDTypography
                    color="blue"
                    fontWeight="bold"
                    variant="body2"
                  >{`${parent} Information`}</MDTypography>
                </Grid>
              ) : null}
            </Grid>
            <Grid container spacing={3} style={{ width: "100%" }}>
              <Grid item sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          {...firstName}
                          key={`ProfileInfo_${firstName}`}
                          type="text"
                          label="First Name"
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )}
                    />
                    {errors?.firstName && (
                      <StyledTypographyError> {errors?.firstName?.message}</StyledTypographyError>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          {...lastName}
                          key={`ProfileInfo_${lastName}`}
                          type="text"
                          label="Last Name"
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )}
                    />
                    {errors?.lastName && (
                      <StyledTypographyError>{errors?.lastName?.message}</StyledTypographyError>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      {...status}
                      control={control}
                      defaultValue={userApplicant?.status}
                      render={({ field }) => (
                        <Autocomplete
                          key={`autocomplete_status_${userApplicant?._id}`}
                          options={userStatusField || []}
                          autoSelect
                          name="status"
                          defaultValue={userApplicant?.status}
                          value={field?.value}
                          onChange={(e, v) => {
                            if (v === "Partner") setValue("partnerSlug", "");
                            else setValue("systemSource", userApplicant?.systemSource);
                            return field.onChange(v);
                          }}
                          onBlur={() => trigger(["rank", "primaryPosition", "status"])}
                          renderInput={(params) => (
                            <FormField
                              label="Status"
                              key={`status_${userApplicant?._id}`}
                              {...params}
                              type="text"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.status && (
                      <StyledTypographyError>{errors?.status?.message}</StyledTypographyError>
                    )}
                  </Grid>

                  {currentLoggedUser?.userType !== "User" && (
                    <Grid item xs={6}>
                      <Controller
                        {...userType}
                        control={control}
                        disabled={mode === "edit"}
                        defaultValue={userApplicant && userApplicant?.userType}
                        render={({ field }) => (
                          <Autocomplete
                            disabled={mode === "edit"}
                            key={`userType_autocomplete_${currentUser?._id}`}
                            options={
                              mode === "edit" ? userTypes || [] : ["Master", "Admin", "Client"]
                            }
                            name="userType"
                            autoSelect
                            defaultValue={userApplicant && userApplicant?.userType}
                            value={field?.value}
                            onChange={(e, v) => field.onChange(v)}
                            renderInput={(params) => (
                              <FormField
                                {...params}
                                key={`userType_autocomplete_${currentUser?._id}`}
                                InputLabelProps={{ shrink: true }}
                                type="text"
                                label="User Type"
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.userType && (
                        <StyledTypographyError>{errors?.userType?.message}</StyledTypographyError>
                      )}
                    </Grid>
                  )}

                  <Grid item sm={6}>
                    <Controller
                      {...spStatus}
                      name="spStatus"
                      control={control}
                      defaultValue={userApplicant?.spStatus}
                      render={({ field }) => (
                        <Autocomplete
                          key={`autocomplete_spStatus_${userApplicant?._id}`}
                          options={
                            watch("userType") === "User"
                              ? userSPStatusFieldUsers || []
                              : userSPStatusField || []
                          }
                          autoSelect
                          disabled={currentUserType === "User"}
                          name="spStatus"
                          onChange={(e, v) => {
                            if (v === "Partner") setValue("partnerSlug", "");
                            else setValue("systemSource", userApplicant?.systemSource);
                            return field.onChange(v);
                          }}
                          defaultValue={userApplicant?.spStatus}
                          value={field?.value}
                          onBlur={() => trigger(["rank", "primaryPosition", "spStatus"])}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              key={`spStatus_${userApplicant?._id}`}
                              type="text"
                              label={`${company?.name} Status`}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.status && (
                      <StyledTypographyError>{errors?.spStatus?.message}</StyledTypographyError>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="emailAddress"
                      control={control}
                      disabled={mode === "edit"}
                      render={({ field }) => (
                        <FormField
                          {...emailAddress}
                          disabled={mode === "edit"}
                          key={`ProfileInfo_${emailAddress}`}
                          type="text"
                          label="Email"
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )}
                    />

                    {errors?.emailAddress && (
                      <StyledTypographyError>{errors?.emailAddress?.message}</StyledTypographyError>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      {...address1}
                      type="text"
                      label="Address"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormField
                      {...city}
                      type="text"
                      label="City"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Controller
                          {...state}
                          control={control}
                          defaultValue={(userApplicant && userApplicant?.applicant?.state) || " "}
                          render={({ field }) => (
                            <Autocomplete
                              key={`autocomplete_state_${userApplicant?.applicant?.id}`}
                              options={states || []}
                              autoSelect
                              freeSolo
                              name="state"
                              defaultValue={
                                (userApplicant && userApplicant?.applicant?.state) || " "
                              }
                              value={field?.value}
                              onChange={(e, v) => {
                                field.onChange(v.toUpperCase());
                              }}
                              onBlur={() => trigger("state")}
                              renderInput={(params) => (
                                <FormField
                                  key={`state_${userApplicant?._id}`}
                                  {...params}
                                  type="text"
                                  label="State"
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            />
                          )}
                        />
                        {errors?.applicant?.state && (
                          <StyledTypographyError>
                            {errors?.applicant?.state?.message}
                          </StyledTypographyError>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          {...zip}
                          control={control}
                          defaultValue={(userApplicant && userApplicant?.applicant?.state) || " "}
                          render={({ field }) => (
                            <FormField
                              {...zip}
                              type="text"
                              label="Zip Code"
                              InputLabelProps={{ shrink: true }}
                              {...field}
                            />
                          )}
                        />

                        {errors?.applicant?.zip && (
                          <StyledTypographyError>
                            {errors?.applicant?.zip?.message}
                          </StyledTypographyError>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Controller
                      {...phone}
                      control={control}
                      defaultValue={userApplicant?.applicant?.phone}
                      render={({ field }) => (
                        <PatternFormat
                          format="(###) ### ####"
                          defaultValue={userApplicant?.applicant?.phone}
                          allowEmptyFormatting
                          mask="_"
                          label="Primary Phone"
                          {...field}
                          customInput={FormField}
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />

                    {errors?.applicant?.phone && (
                      <StyledTypographyError>
                        {errors?.applicant?.phone?.message}
                      </StyledTypographyError>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      {...altPhone}
                      control={control}
                      defaultValue={userApplicant?.applicant?.altPhone}
                      render={({ field }) => (
                        <PatternFormat
                          format="(###) ### ####"
                          allowEmptyFormatting
                          defaultValue={userApplicant?.applicant?.altPhone}
                          mask="_"
                          label="Secondary Phone"
                          customInput={FormField}
                          InputLabelProps={{ shrink: true }}
                          {...field}
                        />
                      )}
                    />

                    {errors?.applicant?.altPhone && (
                      <StyledTypographyError>
                        {errors?.applicant?.altPhone?.message}
                      </StyledTypographyError>
                    )}
                  </Grid>

                  {loggedUserType === "Master" && (
                    <Grid item sm={6}>
                      <Controller
                        {...startDate}
                        name="startDate"
                        control={control}
                        defaultValue={
                          userApplicant?.startDate
                            ? moment(userApplicant?.startDate).format("MM/DD/YYYY")
                            : null
                        }
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              label="Start Date"
                              value={!field.value ? null : field.value}
                              onChange={(value) => {
                                if (moment(value).isValid()) {
                                  field.onChange(new Date(value));
                                } else {
                                  field.onChange(value);
                                }
                              }}
                              renderInput={(params) => (
                                <FormField {...params} InputLabelProps={{ shrink: true }} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {errors?.startDate && (
                        <StyledTypographyError>{errors?.startDate?.message}</StyledTypographyError>
                      )}
                    </Grid>
                  )}
                  {loggedUserType === "Master" && (
                    <Grid item sm={6}>
                      <Controller
                        {...endDate}
                        name="endDate"
                        control={control}
                        defaultValue={
                          userApplicant?.endDate
                            ? moment(userApplicant?.endDate).format("MM/DD/YYYY")
                            : null
                        }
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              disablePast
                              label="End Date"
                              value={!field.value ? null : field.value}
                              onChange={(value) => {
                                if (moment(value).isValid()) {
                                  field?.onChange(new Date(value));
                                } else {
                                  field?.onChange(value);
                                }
                              }}
                              renderInput={(params) => (
                                <FormField {...params} InputLabelProps={{ shrink: true }} />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                      {errors?.endDate && (
                        <StyledTypographyError>{errors?.endDate?.message}</StyledTypographyError>
                      )}
                    </Grid>
                  )}

                  {!isCompany && watch("userType") === "User" && (
                    <Grid item sm={12}>
                      <Controller
                        {...employeeType}
                        control={control}
                        defaultValue={(currentUser && currentUser?.employeeType) || " "}
                        render={({ field }) => (
                          <Autocomplete
                            key={`autocomplete_employeeType_${currentUser?.id}`}
                            options={employeeTypes || []}
                            autoSelect
                            freeSolo
                            name="employeeType"
                            defaultValue={(currentUser && currentUser?.employeeType) || " "}
                            value={field?.value}
                            onChange={(e, v) => {
                              field.onChange(v);
                            }}
                            onBlur={() => trigger("title")}
                            renderInput={(params) => (
                              <FormField
                                key={`employeeType_${currentUser?._id}`}
                                {...params}
                                type="text"
                                label="Employee Type"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        )}
                      />
                      {errors?.employeeType && (
                        <StyledTypographyError>
                          {errors?.employeeType?.message}
                        </StyledTypographyError>
                      )}
                    </Grid>
                  )}

                  {watch("userType") === "Master" || watch("userType") === "Admin" ? (
                    <Grid item sm={6}>
                      <Controller
                        name="organization"
                        control={control}
                        render={({ field }) => (
                          <FormField
                            {...organization}
                            type="text"
                            key={`ProfileInfo_${organization}`}
                            label="Employer's Business or Organization Name"
                            InputLabelProps={{ shrink: true }}
                            {...field}
                          />
                        )}
                      />
                      {errors?.organization && (
                        <StyledTypographyError>
                          {errors?.organization?.message}
                        </StyledTypographyError>
                      )}
                    </Grid>
                  ) : null}
                  {watch("userType") === "Master" || watch("userType") === "Admin" ? (
                    <Grid item sm={6}>
                      <FormField
                        {...title}
                        type="text"
                        label="Title of Employer or Authorized Representative"
                        InputLabelProps={{ shrink: true }}
                      />
                      {errors?.title && (
                        <StyledTypographyError>{errors?.title?.message}</StyledTypographyError>
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </StyledContainer>
        </Grid>
      </form>
      {company?.companyType === "Venue" ? (
        <ApplicantStageChangeModal
          open={applicantStageModalOpen}
          setOpen={setApplicantStageModalOpen}
          applicantId={currentUser?._id}
        />
      ) : null}

      <ConfirmDialog state={confirmModal} setState={setConfirmModal} />
      {openDeleteUserModal && (
        <DeleteModal
          title={confirmModal?.title}
          description={confirmModal?.description}
          isOpen={openDeleteUserModal}
          negativeBtn={{
            label: "Cancel",
            fn: deleteUserModalHandler,
            isShown: true,
          }}
          positiveBtn={{
            label: "Confirm",
            fn: deleteUser,
            isShown: true,
          }}
        />
      )}
    </>
  );
};

export default ProfileMainInfoPanelForm;
const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  flexDirection: "row",
  gap: 5,
  justifyContent: "flex-end",
  marginRight: 5,
}));

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

const StyledContainer = styled(MDBox)(() => ({
  height: 500,
  overflowY: "auto",
  padding: "0 30px 0 30px",
  marginTop: "10px",
  width: "100%",
}));
