import axios from "axios";

import { baseAxios } from "config";

const generateAssessmentQuestions = async ({
  description,
  skills,
  type,
  numQuestions = 15,
  existingQuestions = [],
}) => {
  // try {
  let questions = numQuestions;
  if (numQuestions && typeof questions === "string") questions = parseInt(numQuestions, 10);
  const res = await baseAxios.post(`/assessments/generate-questions`, {
    description,
    skills,
    numQuestions: questions,
    type,
    existingQuestions,
  });
  return res.data;
  // } catch (err) {
  //   if (err.message === "Request failed with status code 404") return [];
  //   throw new Error(err.toString());
  // }
};

export default generateAssessmentQuestions;
