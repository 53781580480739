import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import MergeIcon from "@mui/icons-material/Merge";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { Box, Button, Grid, Stack } from "@mui/material";
import _, { isEmpty, cloneDeep } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Info as InfoIcon } from "@mui/icons-material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import mergeApplicants from "api/applicants/mergeApplicants";
import ApplicantMergeStatus from "components/ApplicantMergeStatus";
import DataTable from "components/DataTable";
import FormErrors from "components/Form/FormErrors";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import VenueIcon from "components/VenueIcon";
import { useAppContextController } from "context/AppContext";
import { APPLICANT_MERGE_STATUS_ENUM } from "utils/constants/applicant";
import fetchSingleCustomer from "api/companies/fetchSingleCustomer";
import useSessionAuth from "hooks/useSessionAuth";
import OnboardingStatus from "components/OnboardingStatus";
import ApplicantStatus from "components/ApplicantStatus";
import fetchAssessments from "layouts/pages/assessments/actions/fetchAssessments";
import AssessmentAssignApplicantActions from "./AssessmentAssignApplicantActions";

const AssessmentAssignApplicantModal = ({
  open,
  onClose,
  applicant,
  job,
  handleOpenAssessmentResultsModal,
  handleSendInvitation,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { venues, allVenues, currentLoggedUser, company, companyType } = useAppContextController();
  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [applicants, setApplicants] = useState([]);
  const [errors, setErrors] = useState({});
  const [moreInfo, setMoreInfo] = useState(false);

  const audience = useMemo(() => {
    if (applicant?.status === "Employee") return "Employee";

    if (company?.companyType === "Company") {
      const applJob = applicant?.jobs?.find((apJb) => apJb.jobSlug === job?.jobSlug);
      if (applJob) return applJob.applicantStatus;
    }
    return applicant?.applicantStatus;
  }, [
    applicant?.applicantStatus,
    applicant?.jobs,
    applicant?.status,
    company?.companyType,
    job?.jobSlug,
  ]);

  const options = { fetchAll: true };
  const allOptions = {
    ...options,
    filters: {
      jobSlug: job?.jobSlug,
      audience,
    },
    includeGenericAssessments: true,
  };

  const {
    data: assessments,
    isLoading,
    refetch,
  } = useQuery(["assessments", allOptions], () => fetchAssessments(allOptions), {});

  const handleCancel = () => {
    onClose(false);
  };

  const handleUpdate = (row, newStatus) => {
    const updatedApplicants = cloneDeep(applicants);
    if (newStatus === APPLICANT_MERGE_STATUS_ENUM.PRIMARY) {
      // Looks for the primary applicant, making sure the we would only have 1 primary applicant.
      const index = updatedApplicants.findIndex(
        (v) => v.mergeStatus === APPLICANT_MERGE_STATUS_ENUM.PRIMARY
      );
      if (index !== -1) {
        updatedApplicants[index].mergeStatus = APPLICANT_MERGE_STATUS_ENUM.MERGE;
      }
    }
    const index = updatedApplicants.findIndex((v) => v._id === row._id);
    if (index !== -1) {
      updatedApplicants[index].mergeStatus = newStatus;
    }
    setApplicants(updatedApplicants);
  };

  const { logoutAuth0User } = useSessionAuth();
  const columns = [
    { title: "Name", field: "name" },
    {
      title: "Job",
      field: "jobSlug",
      customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
    },
    { title: "Type", field: "type" },
    {
      title: "Actions",
      field: "_id",
      width: 150,
      onClick: (e) => e.stopPropagation(),
      customCell: (_id, field, row) => {
        return (
          <AssessmentAssignApplicantActions
            row={row}
            applicant={applicant}
            onOpenAssessmentResultsModal={handleOpenAssessmentResultsModal}
            onSendInvitation={handleSendInvitation}
          />
        );
      },
    },
  ];

  const header = (
    <>
      <Stack display="flex" flexDirection="row" justifyContent="space-between">
        <MDTypography variant="h4">Assessments</MDTypography>
        <CancelIcon
          onClick={() => {
            handleCancel();
          }}
          sx={{ color: "#AEAEAE", cursor: "pointer" }}
        />
      </Stack>
    </>
  );

  const modalBody = (
    <>
      <Box
        sx={{
          maxHeight: "40vh",
          overflowY: "auto",
        }}
      >
        <DataTable
          columns={columns}
          data={{
            data: assessments?.data,
          }}
          isLoading={isLoading}
          limit={4}
        />
      </Box>
    </>
  );

  return (
    <>
      <GenericModal
        open={open}
        setOpen={onClose}
        body={modalBody}
        header={header}
        sx={(theme) => ({
          width: "90% !important",
          maxWidth: "940px !important",
          [theme.breakpoints.down("md")]: {
            "& .MuiCardContent-root": {
              padding: "0px",
            },
          },
        })}
      />
    </>
  );
};

export default AssessmentAssignApplicantModal;
