import Applicants from "layouts/pages/applicants";
import Employees from "layouts/pages/employees";
import Events from "layouts/pages/events";
import ClientEvents from "layouts/pages/clientevents";
import Jobs from "layouts/pages/jobs";
import CompanyJobs from "layouts/pages/companyjobs";
import Venues from "layouts/pages/venues";
import Activities from "layouts/pages/activities";
import Issues from "layouts/pages/issues";
import Partners from "layouts/pages/partners";
import Users from "layouts/pages/users";
import CompanyPage from "layouts/pages/companies";
import Customers from "layouts/pages/customers";
import Notifications from "layouts/pages/notifications";
import Assessments from "layouts/pages/assessments";

const subRoutes = [
  {
    key: "companies-id",
    route: "/companies/:id",
    component: <CompanyPage />,
    userType: ["Master", "Admin"],
  },
  {
    key: "customers-create",
    route: "/customers/create",
    component: <Customers mode="create" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "customers-slug",
    route: "/customers/:slug",
    component: <Customers />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "customers-slug-action-action",
    route: "/customers/:slug/action/:action",
    component: <Customers mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "venues-create",
    route: "/venues/create",
    component: <Venues mode="create" />,
    userType: ["Master", "Admin"],
    companyType: "Venue",
  },
  {
    key: "venues-slug",
    route: "/venues/:slug",
    component: <Venues mode="edit" />,
    userType: ["Master", "Admin", "Client", "Event Admin"],
    companyType: "Venue",
  },
  {
    key: "venues-slug-action-action",
    route: "/venues/:slug/action/:action",
    component: <Venues mode="edit" />,
    userType: ["Master", "Admin", "Client", "Event Admin"],
    companyType: "Venue",
  },
  {
    key: "jobs-create",
    route: "/jobs/create",
    component: <Jobs mode="create" />,
    userType: ["Master", "Admin"],
    companyType: "Venue",
  },
  {
    key: "jobs-jobSlug",
    route: "/jobs/:jobSlug",
    component: <Jobs mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Venue",
  },
  {
    key: "jobs-jobSlug-action-action",
    route: "/jobs/:jobSlug/action/:action",
    component: <Jobs mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Venue",
  },
  {
    key: "companyjobs-create",
    route: "/companyjobs/create",
    component: <CompanyJobs mode="create" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "companyjobs-jobSlug",
    route: "/companyjobs/:jobSlug",
    component: <CompanyJobs mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "companyjobs-jobSlug-action-action",
    route: "/companyjobs/:jobSlug/action/:action",
    component: <CompanyJobs mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "issues-create",
    route: "/issues/create",
    component: <Issues mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "issues-issueId",
    route: "/issues/:issueId",
    component: <Issues mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "issues-issueId-action-action",
    route: "/issues/:issueId/action/:action",
    component: <Issues mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "events-create",
    route: "/events/create",
    component: <Events mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "events-create-createVenueSlug",
    route: "/events/create/:createVenueSlug",
    component: <Events mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "events-eventUrl",
    route: "/events/:eventUrl",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin", "Event Admin"],
  },
  {
    key: "events-venue-venueSlug",
    route: "/events/venue/:venueSlug",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin", "User", "Event Admin"],
    companyType: "Venue",
  },
  {
    key: "events-venue-venueSlug-eventUrl",
    route: "/events/venue/:venueSlug/:eventUrl",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin", "Event Admin"],
    companyType: "Venue",
  },
  {
    key: "events-venue-venueSlug-eventUrl-action-action",
    route: "/events/venue/:venueSlug/:eventUrl/action/:action",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin", "Event Admin"],
    companyType: "Venue",
  },
  {
    key: "events-customers-companySlug",
    route: "/events/customers/:companySlug",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "events-customers-companySlug-eventUrl",
    route: "/events/customers/:companySlug/:eventUrl",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "events-customers-companySlug-eventUrl-action-action",
    route: "/events/customers/:companySlug/:eventUrl/action/:action",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin"],
    companyType: "Company",
  },
  {
    key: "events-eventUrl-action-action",
    route: "/events/:eventUrl/action/:action",
    component: <Events mode="edit" />,
    userType: ["Master", "Admin", "Event Admin"],
  },
  {
    key: "clientevents-eventUrl",
    route: "/clientevents/:eventUrl",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
  },
  {
    key: "clientevents-venue-venueSlug",
    route: "/clientevents/venue/:venueSlug",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
    companyType: "Venue",
  },
  {
    key: "clientevents-venue-venueSlug-eventUrl",
    route: "/clientevents/venue/:venueSlug/:eventUrl",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
    companyType: "Venue",
  },
  {
    key: "clientevents-venue-venueSlug-eventUrl-action-action",
    route: "/clientevents/venue/:venueSlug/:eventUrl/action/:action",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
    companyType: "Venue",
  },
  {
    key: "clientevents-customers-companySlug",
    route: "/clientevents/customers/:companySlug",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
    companyType: "Company",
  },
  {
    key: "clientevents-customers-companySlug-eventUrl",
    route: "/clientevents/customers/:companySlug/:eventUrl",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
    companyType: "Company",
  },
  {
    key: "clientevents-customers-companySlug-eventUrl-action-action",
    route: "/clientevents/customers/:companySlug/:eventUrl/action/:action",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
    companyType: "Company",
  },
  {
    key: "clientevents-eventUrl-action-action",
    route: "/clientevents/:eventUrl/action/:action",
    component: <ClientEvents mode="edit" />,
    userType: ["Master", "Admin", "Client"],
  },
  {
    key: "applicants-create",
    route: "/applicants/create",
    component: <Applicants mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "applicants-id",
    route: "/applicants/:id",
    component: <Applicants mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "applicants-id-action-action",
    route: "/applicants/:id/action/:action",
    component: <Applicants mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "employees-create",
    route: "/employees/create",
    component: <Employees mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "employees-id",
    route: "/employees/:id",
    component: <Employees mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "employees-id-action-action",
    route: "/employees/:id/action/:action",
    component: <Employees mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "partners-create",
    route: "/partners/create",
    component: <Partners mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "partners-slug",
    route: "/partners/:slug",
    component: <Partners mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "partners-slug-action-action",
    route: "/partners/:slug/action/:action",
    component: <Partners mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "users-create",
    route: "/users/create",
    component: <Users mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "users-id",
    route: "/users/:id",
    component: <Users mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "users-id-action-action",
    route: "/users/:id/action/:action",
    component: <Users mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "assessments-create",
    route: "/assessments/create",
    component: <Assessments mode="create" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "assessments-assessmentId",
    route: "/assessments/:assessmentId",
    component: <Assessments mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "assessments-assessmentsId-action-action",
    route: "/assessments/:assessmentId/action/:action",
    component: <Assessments mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "companies-id",
    route: "/companies/:id",
    component: <CompanyPage mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "companies-id-action-action",
    route: "/companies/:id/action/:action",
    component: <CompanyPage mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "activities-id",
    route: "/activities/:id",
    component: <Activities mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "activities-id-action-action",
    route: "/activities/:id/action/:action",
    component: <Activities mode="edit" />,
    userType: ["Master", "Admin"],
  },
  {
    key: "notifications-notificationId",
    route: "/notifications/:notificationId",
    component: <Notifications />,
    userType: ["Master", "Admin", "Client", "Event Admin", "User"],
  },
];

export default subRoutes;
