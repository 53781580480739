import { Alert, Snackbar, Stack, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import updateProfile from "layouts/pages/profile/actions/updateProfile";
import useStyles from "layouts/pages/venues/components/VenueMainInfoPanel/styles";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { getCompanyImageUrl } from "utils/helpers/upload";
import fetchBanners from "../../actions/fetchBanners";

const BannerDropzone = ({
  handleClose,
  anchorElem,
  urlBase,
  urlPath = "",
  attribute = "",
  title = "",
  widthAttribute,
  currentUser,
  setCurrentUser,
}) => {
  const styles = useStyles();
  const [bannerList, setBannerList] = useState(null);
  const queryClient = useQueryClient();

  const { company } = useAppContextController();
  const imageUrlBase = getCompanyImageUrl(company);

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const open = Boolean(anchorElem);

  const updateUserMutation = useMutation(updateProfile, {
    onError: (error) =>
      setToastAlert({
        isShow: true,
        message: `Something went wrong! ${error.toString()}`,
        status: "error",
      }),
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("users");
      const newData = {
        ...data,
        bannerImg: data.bannerImg ? data.bannerImg : currentUser?.bannerImg,
      };
      setCurrentUser({ ...currentUser, ...newData });
      setToastAlert({ isShow: true, message: "User has been updated!", status: "success" });
    },
  });

  const saveChanges = async (values) => {
    // evaluate();
    if (!currentUser._id) {
      setToastAlert({ isShow: true, message: "Something went wrong!", status: "error" });
      return;
    }
    handleClose();
    await updateUserMutation.mutateAsync({
      userId: currentUser?.userId,
      data: values,
    });
  };

  const handleLogoOnClick = (img) => {
    const obj = {};
    obj[attribute] = img;
    saveChanges(obj);
  };

  const LogoList = ({ imageList, height = "50px", width = widthAttribute }) => {
    return (
      <Stack
        spacing={2}
        direction="column"
        sx={{ maxWidth: "315px", maxHeight: "230px", msOverflowY: "scroll" }}
      >
        {imageList.map((img, idx) => (
          <MDBox
            key={img}
            className={styles.section_image_container}
            sx={{ width, height: "100%" }}
          >
            <MDBox
              sx={{
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                "padding-top": "10px",
                "&": { transitionDelay: "0.2s" },
              }}
            >
              <Card
                sx={{ width, height: "100px" }}
                onClick={(e) =>
                  handleLogoOnClick(`${urlPath}${`${imageUrlBase}/applicants/banners/${img}`}`)
                }
              >
                <Tooltip title={img} placement="top">
                  <img
                    src={
                      !img?.startsWith("http")
                        ? `${urlPath}${`${imageUrlBase}/applicants/banners/${img}`}`
                        : `${imageUrlBase}/applicants/banners/${img}`
                    }
                    key={img}
                    alt="Not Found"
                    style={{ height: "100px", width }}
                  />
                </Tooltip>
              </Card>
            </MDBox>
          </MDBox>
        ))}
      </Stack>
    );
  };

  useEffect(() => {
    const setbannerFunction = async () => {
      setBannerList(await fetchBanners());
    };
    setbannerFunction();
  }, []);

  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>

      <Menu
        anchorEl={anchorElem}
        anchorReference={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
        sx={{ mt: 2 }}
      >
        <MDTypography variant="h5">{title}</MDTypography>

        <Stack spacing={1} direction="column">
          {bannerList && bannerList?.length > 0 && (
            <Item>
              <LogoList urlBase={urlBase} path={urlPath} imageList={bannerList} />
            </Item>
          )}
        </Stack>
      </Menu>
    </>
  );
};

export default BannerDropzone;
