import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";

const fetchAssessments = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  useOr = false,
  searchProjection = false,
  outsideMode = "",
  projectedFields = "",
  useElemMatch = false,
  includeGenericAssessments,
}) => {
  const { paginationQuery, sortQuery, filterQuery, queryString } = createApiParams({
    page,
    limit,
    order,
    orderBy,
    fetchAll,
    filters,
  });

  try {
    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;

    const res = await baseAxios.get(
      `/${outsideModeQuery}assessments${queryString}${useOr ? "&useOr=true" : ""}${
        searchProjection ? "&searchProjection=true" : ""
      }${outsideModeQuery || projectedFields ? `&projectedFields=${projectedFields}` : ""}${
        useElemMatch ? "&useElemMatch=true" : ""
      }${includeGenericAssessments ? "&includeGenericAssessments=true" : ""}`
    );
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchAssessments;
