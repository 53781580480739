/* eslint-disable react-hooks/exhaustive-deps */
// hooks
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

// store/api/helper
import ContactsPanel from "components/ContactsPanel";
import { useAppContextController } from "context/AppContext";
import updateVenue from "layouts/pages/venues/actions/updateVenue";
import { isEqual } from "lodash";
import { getCompanyImageUrl } from "utils/helpers/upload";
import { imageUrlFixer } from "utils/helpers/venues";
import fetchSingleVenue from "../../actions/fetchSingleVenue";

const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const VenueContacts = ({ setSelectedPanel, setSelectedContact }) => {
  const { currentVenue, setCurrentVenue, currentLoggedUser, company } = useAppContextController();

  const [contacts, setContacts] = useState([]);
  const [tempContacts, setTempContacts] = useState(contacts);
  const [primaryInfo, setPrimaryInfo] = useState();
  const [currentContact, setCurrentContact] = useState(emptyValues);

  const { mutateAsync: updateVenueMutation } = useMutation(updateVenue);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateVenue = async (values, method = "update", onSuccess) => {
    const createAgent = currentLoggedUser?._id;
    const data = values?.contact
      ? { ...values, ...values?.contact, createAgent }
      : { ...values, createAgent };
    if (!currentVenue?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateVenueMutation(
        { venueId: currentVenue?._id, data },
        {
          onSuccess: async (res) => {
            setCurrentVenue({ ...currentVenue, ...data });
            onSuccess(method, data?.contacts);
            enqueueSnackbar("Venue contacts has been updated.", { variant: "success" });
            await queryClient.invalidateQueries("venues");
          },
        }
      );
    }
  };

  // on company change or current company update
  useEffect(() => {
    setPrimaryInfo();
    if (currentVenue?.contacts) {
      setContacts(currentVenue?.contacts);
      setTempContacts(currentVenue?.contacts);
      if (currentContact && currentContact?.primaryContact === "Yes") {
        const temp = currentVenue?.contacts?.find((item) => item?.primaryContact === "Yes");
        setCurrentContact(temp);
      }
    } else {
      setContacts([]);
      setTempContacts([]);
    }
  }, [currentVenue]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchSingleVenue({
        venueId: currentVenue?._id,
      });

      if (!isEqual(currentVenue?.contacts, res?.contacts)) {
        const newData = imageUrlFixer(res, currentVenue, getCompanyImageUrl(company));
        setCurrentVenue(newData);
      }
    };

    fetchData();
  }, [currentVenue?.contacts]);

  return (
    <ContactsPanel
      name={currentVenue?.name}
      sourceComponent="Venue"
      onUpdate={handleUpdateVenue}
      setSelectedPanel={setSelectedPanel}
      setSelectedContact={setSelectedContact}
      contacts={contacts}
      tempContacts={tempContacts}
      setTempContacts={setTempContacts}
      primaryInfo={primaryInfo}
      setPrimaryInfo={setPrimaryInfo}
      currentContact={currentContact}
      setCurrentContact={setCurrentContact}
      currentData={currentVenue}
    />
  );
};

export default VenueContacts;
