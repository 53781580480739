/* eslint-disable no-else-return */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
import { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  TextField,
  TablePagination,
  Icon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Alert,
  Collapse,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ArrowDropDown,
  Assessment,
  AssignmentTurnedIn,
  Download,
  Hub,
  PeopleAlt,
  PlayCircle,
  Poll as PollIcon,
  Save,
  Timer,
} from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import CustomCard from "components/CustomCard";
import { cardStyles as styles, customPanelCard } from "layouts/pages/customers/styles";
import { useAppContextController } from "context/AppContext";
import fetchEventActivityReport from "api/events/fetchEventActivityReport";
import StatModal from "components/JobsReportPanel/StatModal";
import CustomBarChart from "components/CustomBarChart";
import CustomDataGrid from "components/CustomDataGrid";
import PanelActions from "components/PanelActions";
import { useForm } from "react-hook-form";
import moment from "moment";
import CustomTextField from "components/Form/CustomTextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import saveReportPanel from "api/reports/createReportPanel";
import exportEventActivity from "api/events/exportEventActivity";
import { EVENT_STAFF_DATA_ICONS, LIST_COLUMNS, POSITION_COLUMNS } from "./detailColumn";

const EventReportPanel = () => {
  const { currentEvent, isMobile } = useAppContextController();
  const [isLoading, setIsLoading] = useState(false);
  const [period, setPeriod] = useState({ value: "This Week", label: "This Week" });
  const [activityData, setActivityData] = useState(null);
  const [statModalOpen, setStatModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateErrors, setDateErrors] = useState({
    startDate: null,
    endDate: null,
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Show error toast
  const showError = (message) => {
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const validateDates = (start, end) => {
    const errors = {
      startDate: null,
      endDate: null,
    };

    if (start) {
      const startMoment = moment(start);
      if (!startMoment.isValid()) {
        errors.startDate = "Invalid date format";
        showError("Invalid start date format");
        return false;
      } else if (startMoment.isAfter(moment())) {
        errors.startDate = "Start date cannot be in the future";
        showError("Start date cannot be in the future");
        return false;
      }
    }

    if (end) {
      const endMoment = moment(end);
      if (!endMoment.isValid()) {
        errors.endDate = "Invalid date format";
        showError("Invalid end date format");
        return false;
      } else if (endMoment.isAfter(moment())) {
        errors.endDate = "End date cannot be in the future";
        showError("End date cannot be in the future");
        return false;
      }
    }

    if (start && end) {
      const startMoment = moment(start);
      const endMoment = moment(end);
      if (startMoment.isValid() && endMoment.isValid() && startMoment.isAfter(endMoment)) {
        errors.startDate = "Start date must be before end date";
        errors.endDate = "End date must be after start date";
        showError("Start date must be before end date");
        return false;
      }
    }

    setDateErrors(errors);
    return !errors.startDate && !errors.endDate;
  };

  const periodOptions = [
    { value: "This Week", label: "This Week" },
    { value: "This Month", label: "This Month" },
    { value: "This Year", label: "This Year" },
    { value: "Custom Range", label: "Custom Range" },
  ];

  const getPeriodTitle = () => {
    if (period?.value === "Custom Range" && dateRange.startDate && dateRange.endDate) {
      return `Activity (${moment(dateRange.startDate).format("MM/DD/YYYY")} - ${moment(
        dateRange.endDate
      ).format("MM/DD/YYYY")})`;
    }

    switch (period?.value) {
      case "This Month":
        return "This Month's Activity";
      case "This Year":
        return "This Year's Activity";
      default:
        return "This Week's Activity";
    }
  };

  const getEventStats = () => {
    if (!activityData) return [];

    return [
      {
        name: "Waitlist",
        number: activityData?.activitySummary?.waitlist?.total || 0,
        breakdown:
          activityData?.activitySummary?.waitlist?.breakdown ||
          activityData?.activitySummary?.waitlist?.daily ||
          {},
      },
      {
        name: "Request",
        number: activityData?.activitySummary?.pendingRequest?.total || 0,
        breakdown:
          activityData?.activitySummary?.pendingRequest?.breakdown ||
          activityData?.activitySummary?.pendingRequest?.daily ||
          {},
      },
      {
        name: "Late",
        number: activityData?.activitySummary?.late?.total || 0,
        breakdown:
          activityData?.activitySummary?.late?.breakdown ||
          activityData?.activitySummary?.late?.daily ||
          {},
      },
      {
        name: "Positions",
        number: activityData?.activitySummary?.positions?.total || 0,
        breakdown:
          activityData?.activitySummary?.positions?.breakdown ||
          activityData?.activitySummary?.positions?.daily ||
          {},
      },
    ];
  };

  const fetchEventActivity = useCallback(async () => {
    if (period?.value === "Custom Range") {
      if (!dateRange.startDate || !dateRange.endDate) {
        showError("Both start and end dates are required for custom range");
        return;
      }
      const isValid = validateDates(dateRange.startDate, dateRange.endDate);
      if (!isValid) {
        return;
      }
    }

    try {
      setIsLoading(true);
      setIsSaved(false);

      const periodMap = {
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Custom Range": "custom",
      };

      const params = {
        eventUrl: currentEvent?.eventUrl,
        period: periodMap[period.value],
      };

      if (period?.value === "Custom Range") {
        params.startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
        params.endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
      }

      const response = await fetchEventActivityReport(params);

      setActivityData(response.data);
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEvent?.eventUrl, dateRange, period?.value]);

  const handleSaveReport = async () => {
    try {
      setIsLoading(true);

      const response = await saveReportPanel({
        feature: "events",
        details: activityData,
        recordId: currentEvent?._id,
      });

      if (response.data.success) {
        setIsSaved(true);
        setSnackbar({
          open: true,
          message: "Report panel saved successfully",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error saving report panel:", error);
      setSnackbar({
        open: true,
        message: "Failed to save report panel",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportReport = async (fileType) => {
    try {
      setIsLoading(true);

      const periodMap = {
        "This Year": "year",
        "This Month": "month",
        "This Week": "week",
        "Custom Range": "custom",
      };

      const params = {
        eventUrl: currentEvent?.eventUrl,
        period: periodMap[period?.value],
        fileType,
      };

      if (period?.value === "Custom Range") {
        params.startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
        params.endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
      }

      handleClose();

      await exportEventActivity(params);

      setSnackbar({
        open: true,
        message: "Report exported successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error export report panel:", error);
      setSnackbar({
        open: true,
        message: "Failed to export report panel",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePeriodChange = (value) => {
    // If value is null (cleared), default to "This Week"
    const newPeriod = value || { value: "This Week", label: "This Week" };
    setPeriod(newPeriod);
    setShowDateRange(newPeriod.value === "Custom Range");
    if (newPeriod.value !== "Custom Range") {
      setDateRange({ startDate: null, endDate: null });
    }
  };

  useEffect(() => {
    if (period?.value !== "Custom Range" || (dateRange.startDate && dateRange.endDate)) {
      fetchEventActivity();
    }
  }, [dateRange.endDate, dateRange.startDate, fetchEventActivity, period?.value]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatClick = (stat) => {
    if (period.value === "Custom Range") {
      return;
    }

    setModalTitle(stat.name);

    // For custom range, don't show the modal since we only have totals

    // Handle predefined periods
    let labels = [];
    switch (period.value) {
      case "This Week":
        labels = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        break;
      case "This Month":
        labels = ["WEEK1", "WEEK2", "WEEK3", "WEEK4", "WEEK5"];
        break;
      case "This Year":
        labels = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        break;
      default:
        break;
    }

    const breakdown = stat.breakdown || stat.daily || {};
    const chartData = labels.map((label) => ({
      name: label,
      value:
        period.value === "This Month"
          ? breakdown[label] || 0
          : breakdown[label.toLowerCase().slice(0, 4)] || 0,
    }));

    setModalData(chartData);
    setStatModalOpen(true);
  };

  const stats = getEventStats();

  // Paginated lists
  const getPaginatedList = (list = []) => {
    const startIndex = page * rowsPerPage;
    return list.slice(startIndex, startIndex + rowsPerPage);
  };

  const formatGridData = (data = []) => {
    return data.map((item, index) => ({
      id: index, // Adding unique id
      ...item,
    }));
  };

  const transformEventStaffData = useCallback(() => {
    if (!activityData?.eventStaffActivity?.stages) return [];
    return activityData.eventStaffActivity.stages.map((stage) => ({
      name: stage.stage,
      value: stage.count,
      color: stage.color,
    }));
  }, [activityData]);

  const reportSections = [
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <Hub />
        </Icon>
      ),
      title: "Event Staff Activity",
      content: <CustomBarChart data={transformEventStaffData()} icons={EVENT_STAFF_DATA_ICONS} />,
      count: activityData?.eventStaffActivity?.total || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <PeopleAlt />
        </Icon>
      ),
      title: "Waitlist",
      content: (
        <CustomDataGrid rows={formatGridData(activityData?.waitlist)} columns={LIST_COLUMNS} />
      ),
      count: activityData?.waitlist?.length || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <AssignmentTurnedIn />
        </Icon>
      ),
      title: "Pending Requests",
      content: (
        <CustomDataGrid
          rows={formatGridData(activityData?.pendingRequest)}
          columns={LIST_COLUMNS}
        />
      ),
      count: activityData?.pendingRequest?.length || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <Timer />
        </Icon>
      ),
      title: "Late Clock-ins",
      content: (
        <CustomDataGrid rows={formatGridData(activityData?.lateClockIn)} columns={LIST_COLUMNS} />
      ),
      count: activityData?.lateClockIn?.length || 0,
    },
    {
      icon: (
        <Icon color="warning" fontSize="large">
          <Assessment />
        </Icon>
      ),
      title: "Positions",
      content: (
        <CustomDataGrid rows={formatGridData(activityData?.positions)} columns={POSITION_COLUMNS} />
      ),
      count: activityData?.positions?.length || 0,
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm();

  const handleCancelChanges = () => {
    reset();
  };

  const onSubmit = () => console.log("submit");

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomCard
          icon={<Assessment color="white" />}
          cardTitle="Event Report Data"
          cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
          {...customPanelCard(isMobile, "info")}
        >
          <Stack spacing={2} sx={styles.container}>
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  mb: 2,
                  pt: 2,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  "& .MuiAutocomplete-root": {
                    width: "280px",
                  },
                }}
              >
                <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Autocomplete
                      options={periodOptions}
                      value={period}
                      onChange={(event, value) => handlePeriodChange(value)}
                      renderInput={(params) => (
                        <CustomTextField {...params} label="Select Period" />
                      )}
                    />

                    <Box gap={1} display="flex">
                      <IconButton
                        color="success"
                        onClick={handleSaveReport}
                        sx={{ padding: 0 }}
                        disabled={isLoading || isSaved || !activityData}
                      >
                        <Save />
                      </IconButton>

                      <IconButton
                        color="info"
                        onClick={handleClick}
                        sx={{ padding: 0 }}
                        disabled={isLoading}
                      >
                        <Download />
                      </IconButton>

                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={() => handleExportReport("csv")}>CSV</MenuItem>
                        <MenuItem onClick={() => handleExportReport("xlsx")}>Excel</MenuItem>
                      </Menu>
                    </Box>
                  </Stack>

                  <Collapse in={showDateRange}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Start Date"
                          renderInput={(params) => <CustomTextField {...params} />}
                          value={dateRange.startDate}
                          maxDate={moment()}
                          onChange={(date) =>
                            setDateRange((prev) => ({
                              ...prev,
                              startDate: date ? date.toISOString() : null,
                            }))
                          }
                        />
                        <DatePicker
                          label="End Date"
                          renderInput={(params) => <CustomTextField {...params} />}
                          value={dateRange.endDate}
                          maxDate={moment()} // Prevent future dates
                          minDate={dateRange.startDate ? moment(dateRange.startDate) : undefined}
                          onChange={(date) =>
                            setDateRange((prev) => ({
                              ...prev,
                              endDate: date ? date.toISOString() : null,
                            }))
                          }
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Collapse>
                </Stack>
              </Stack>

              <Divider sx={{ bgcolor: "gray" }} />

              <Stack direction="row" justifyContent="center">
                <MDTypography textAlign="center" fontWeight="medium">
                  {getPeriodTitle()}
                </MDTypography>
              </Stack>

              {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                  <CircularProgress
                    size={60}
                    thickness={4}
                    sx={{
                      color: "#5757FF",
                      "& .MuiCircularProgress-circle": {
                        strokeLinecap: "round",
                      },
                    }}
                  />
                </Box>
              ) : (
                <>
                  <Divider sx={{ bgcolor: "gray" }} />

                  {/* Stats boxes */}
                  <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                    {stats.map((stat) => (
                      <Box
                        key={stat.name}
                        width={200}
                        px={2}
                        py={1}
                        borderRadius={2}
                        sx={{
                          backgroundColor: "#5757FF",
                          cursor: "pointer",
                        }}
                        onClick={() => handleStatClick(stat)}
                      >
                        <MDTypography color="white" fontWeight="medium">
                          {stat.number}
                        </MDTypography>
                        <MDTypography color="white" fontSize="1rem">
                          {stat.name}
                        </MDTypography>
                      </Box>
                    ))}
                  </Stack>

                  {/* Report sections with accordions */}
                  <Box>
                    {reportSections.map((section) => (
                      <Accordion key={section.title} disableGutters>
                        <AccordionSummary
                          expandIcon={<ArrowDropDown />}
                          sx={{ width: "100%", alignItems: "center" }}
                        >
                          <Stack direction="row" spacing={4} alignItems="center">
                            {section.icon}
                            <MDTypography fontWeight="medium">{section.title}</MDTypography>
                          </Stack>
                          <Box sx={{ ml: "auto" }}>
                            <MDTypography fontWeight="medium" sx={{ mr: 1 }}>
                              {section.count}
                            </MDTypography>
                          </Box>
                        </AccordionSummary>
                        <Divider />
                        <AccordionDetails sx={{ py: 0, m: 0 }}>{section.content}</AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </>
              )}
            </Stack>
          </Stack>

          <StatModal
            open={statModalOpen}
            setOpen={setStatModalOpen}
            title={modalTitle}
            data={modalData}
          />
        </CustomCard>
      </form>
    </>
  );
};

export default EventReportPanel;
