import { Grid, IconButton, Stack } from "@mui/material";
import { Assignment, Cancel, Preview } from "@mui/icons-material";

import { dataStyles as styles } from "layouts/pages/customers/styles";
import MDTypography from "components/MDTypography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import moment from "moment";
import { useState } from "react";
import ShiftPreviewAndEditPunchesModal from "layouts/pages/jobs/components/ShiftJobs/ShiftPreviewAndEditPunches/ShiftPreviewAndEditPunchesModal";

const ShiftJobsItem = ({
  row,
  idx,
  selectShift,
  onRemoveShift,
  handleCopyShift,
  assignSchedule,
}) => {
  const [isShiftEditPunchesModalOpen, setShiftEditPunchesModalOpen] = useState(false);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getDaysWithTimes(data) {
    const dayOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return Object.keys(data)
      .filter((day) => data[day].start && data[day].end)
      .sort(
        (a, b) =>
          dayOrder.indexOf(capitalizeFirstLetter(a)) - dayOrder.indexOf(capitalizeFirstLetter(b))
      )
      .map((day) => capitalizeFirstLetter(day).slice(0, 3));
  }

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          selectShift(idx, row);
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={3}>
            <MDTypography sx={styles.font}>{row.shiftName}</MDTypography>
          </Grid>
          <Grid item sm={3}>
            <MDTypography sx={styles.font}>
              {getDaysWithTimes(row.defaultSchedule).join(", ")}
            </MDTypography>
          </Grid>
          <Grid item sm={3}>
            <MDTypography sx={styles.font}>
              {moment(row.shiftStartDate).format("MM/DD/YYYY")}-
              {moment(row.shiftEndDate).format("MM/DD/YYYY")}
            </MDTypography>
          </Grid>
          <Grid item sm={3}>
            <Stack direction="row" spacing={2} justifyContent="end">
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShiftEditPunchesModalOpen(true);
                }}
              >
                <Preview color="info" fontSize="medium" />
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  assignSchedule(idx);
                }}
              >
                <Assignment color="warning" fontSize="medium" />
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyShift(idx);
                }}
              >
                <ContentCopyIcon color="info" fontSize="medium" />
              </IconButton>
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveShift(idx);
                }}
              >
                <Cancel color="error" fontSize="medium" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {isShiftEditPunchesModalOpen && (
        <ShiftPreviewAndEditPunchesModal
          shift={row}
          isShiftEditPunchesModalOpen={isShiftEditPunchesModalOpen}
          setShiftEditPunchesModalOpen={setShiftEditPunchesModalOpen}
        />
      )}
    </>
  );
};

export default ShiftJobsItem;
