import { Close } from "@mui/icons-material";
import { Box, Card, Fade, Grid, IconButton, Modal, styled, Typography } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import parse from "html-react-parser";
import { getCompanyImageUrl } from "utils/helpers/upload";
import styles from "./styles";

const PartnerPreview = ({ open, closeHandler, currentPreview, customer }) => {
  const { company, currentPartner } = useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);

  return (
    <div>
      <Modal
        open={open}
        onClose={closeHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card>
            <Box sx={(theme) => styles.modalContainer(theme)}>
              <StyledContainer>
                <IconButton
                  onClick={closeHandler}
                  sx={{ position: "absolute", top: "5px", right: "5px", color: "inherit" }}
                  size="large"
                >
                  <Close />
                </IconButton>
                <Box
                  sx={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box style={styles.longBgImageContainer}>
                    <img style={styles.longBgImage} src={customer?.bannerUrl} alt="Event Staff" />
                  </Box>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    justifyContent={{ xs: "center", md: "initial" }}
                  >
                    <Grid item sm={2}>
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        variant="gradient"
                        bgColor="white"
                        color="white"
                        borderRadius="xl"
                        mt={-2}
                      >
                        <img alt="logo" width="100%" src={customer?.logoUrl} />
                      </MDBox>
                    </Grid>
                    <Grid item sm={6}>
                      <Box>
                        <h3 style={{ lineHeight: 1, marginBottom: 5 }}>{customer?.name}</h3>
                        <Box>
                          <Typography>{customer?.address}</Typography>
                          <Typography>
                            {customer?.city}, {customer?.state} {customer?.zip}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <StyledBackgroundImgContainer>
                    <StyledBackgroundImg
                      component="img"
                      src={`${IMAGE_SERVER}/company/${company.slug}/backgroundImage/${company?.backgroundImageUrl}`}
                      key={company?.backgroundImageUrl}
                      alt={company?.backgroundImageUrl}
                    />
                  </StyledBackgroundImgContainer>
                </Box>

                <StyledModalContainer>
                  <StyledBox>
                    <Grid container spacing={3}>
                      <Grid item sm={9}>
                        <StyledCompanyName variant="h5">
                          {currentPartner?.companyName}
                        </StyledCompanyName>
                        <StyledHeaderBox>
                          <Typography>{currentPartner?.title}</Typography>
                          <Typography>{currentPartner?.address}</Typography>
                          <Typography>{`${currentPartner?.companyCity} ${currentPartner?.companyState} ${currentPartner?.zip}`}</Typography>
                          <Typography>
                            <a href={currentPartner?.slug} target="_blank" rel="noreferrer">
                              {currentPartner?.slug}
                            </a>
                          </Typography>
                        </StyledHeaderBox>
                      </Grid>
                      <Grid item sm={3}>
                        <Box textAlign="right">
                          <CompanyLogoSquare company={company} />
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledBox>
                  <StyledBoxDescription>
                    {currentPartner?.description && <div>{parse(currentPartner.description)}</div>}
                    {company?.logoUrl && IMAGE_SERVER && (
                      <StyledCompanyLogo
                        component="img"
                        alt="logo"
                        src={`${IMAGE_SERVER}/company/${company.slug}/logo/${company?.logoUrl}`}
                      />
                    )}
                  </StyledBoxDescription>
                </StyledModalContainer>
              </StyledContainer>
            </Box>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

export default PartnerPreview;

const StyledBackgroundImg = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowY: "auto",
  height: "100%",
}));

const StyledBackgroundImgContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "200px",
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  zIndex: -1,
  overflow: "hidden",
  borderRadius: 5,
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 1170,
  backgroundColor: "none",
  overflow: "hidden",
  borderRadius: 5,
  padding: "6rem 4rem",
}));

const StyledModalContainer = styled(Card)(({ theme }) => ({
  backgroundColor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  padding: "1rem",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid #7c97a9`,
  paddingBottom: "2rem",
}));

const StyledHeaderBox = styled(Box)(({ theme }) => ({
  color: "#7c97a9",
}));

const StyledBoxDescription = styled(Box)(({ theme }) => ({
  overflowY: "scroll",
  maxHeight: "40vh",
  marginTop: 2,
}));

const StyledCompanyName = styled(Typography)(({ theme }) => ({
  lineHeight: 1,
  marginBottom: 5,
}));

const StyledCompanyLogo = styled(Box)(({ theme }) => ({
  height: 86,
}));
