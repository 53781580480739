import PictureAvatar from "components/PictureAvatar";
import { useAppContextController } from "context/AppContext";
import CustomCard from "components/CustomCard";
import ApplicantForm from "./ApplicantForm";
import ApplicantHeader from "./ApplicantHeader";

const userPanelAllowedStatus = ["Employee"];

const ApplicantMainInfoPanel = ({
  setPanelSelect,
  panelSelect,
  boarding,
  setBoarding,
  filters,
  parent = "Applicant",
  setFilters,
  useOrTags,
  setUseOrTags,
  defaultPanel,
}) => {
  const { currentApplicant } = useAppContextController();

  return (
    <CustomCard
      icon={
        <PictureAvatar
          image={currentApplicant?.profileImg}
          firstName={currentApplicant?.firstName}
          lastName={currentApplicant?.lastName}
          userId={currentApplicant?.userRecordId}
          size="md"
        />
      }
      key={`card_${currentApplicant?._id}`}
      iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "-1.5rem" }}
      cardSx={{ width: "100%", overflow: "visible !important", p: 0, height: 850 }}
    >
      <ApplicantHeader
        parent={parent}
        panelSelect={panelSelect}
        setPanelSelect={setPanelSelect}
        boarding={boarding}
        setBoarding={setBoarding}
        defaultPanel={defaultPanel}
      />
      <ApplicantForm
        parent={parent}
        setFilters={setFilters}
        filters={filters}
        useOrTags={useOrTags}
        setUseOrTags={setUseOrTags}
        panelSelect={panelSelect}
      />
    </CustomCard>
  );
};

export default ApplicantMainInfoPanel;
