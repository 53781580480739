import { useState } from "react";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  CalendarMonth,
  Description,
  Email,
  Info,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from "notistack";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import { useAppContextController } from "context/AppContext";
import MDTypography from "components/MDTypography";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import { useMutation, useQueryClient } from "react-query";
import ApplicantJobStatusButton from "components/ApplicantJobStatusButton";
import { getApplicantJobEmploymentStage } from "utils/helpers/applicant";
import ApplicantStageButton from "components/ApplicantStageButton";
import updateInterviewFeedback from "layouts/pages/events/actions/updateInterviewFeedback";
import ApplicantPhoneButton from "components/ApplicantPhoneButton";
import JobStatusMoreButton from "../../../CompanyJobsStatus/JobStatusRow/JobStatusMoreButton";

const JobAssessmentRowActions = ({ row }) => {
  const queryClient = useQueryClient();

  const { currentJob, currentLoggedUser } = useAppContextController();
  const navigate = useNavigate();

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Tooltip title="View Assessment">
          <IconButton
            sx={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/assessments/${row?._id}`);
            }}
          >
            <Info fontSize="large" color="info" />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default JobAssessmentRowActions;
