import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Box, Card, Grid, Icon, IconButton, Stack, TextField, styled } from "@mui/material";
import FileDropzone from "components/Dropzone";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useSnackbar } from "notistack";

import updateCompany from "api/companies/updateCompany";
import { useAppContextController } from "context/AppContext";
import useStyles from "layouts/pages/venues/components/VenueMainInfoPanel/styles";

import axios from "axios";
import useCompanyCache from "hooks/useCompanyCache";
import { baseAxios } from "config";
import { getCompanyImageUrl } from "utils/helpers/upload";

const ImageListHandler = ({
  imageList,
  width,
  selectedImageHandler,
  removeImageHandler,
  company,
  componentType,
  typeOfUpload,
  selectedImg,
}) => {
  const styles = useStyles();

  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{ maxWidth: "100%", minHeight: "130px", overflowX: "auto" }}
    >
      {imageList?.map((img, idx) => (
        <MDBox key={img} className={styles.section_image_container} sx={{ width }}>
          <StyledCancelBtn color="info" onClick={(e) => removeImageHandler(img, idx)}>
            <Icon fontSize="small">cancel</Icon>
          </StyledCancelBtn>
          <StyledImageBox style={{ border: selectedImg === img ? "3px solid #5A96E3" : "none" }}>
            <Card sx={{ height: "100px" }} onClick={(e) => selectedImageHandler(img)}>
              <StyledImage
                component="img"
                src={`${getCompanyImageUrl(company)}/${componentType}/${
                  company.slug
                }/${typeOfUpload}/${img}`}
                key={img}
                alt={img}
              />
            </Card>
          </StyledImageBox>
          <TextField
            disabled
            id="outlined-basic"
            variant="outlined"
            value={img}
            size="small"
            style={{ margin: "0.5rem 0" }}
            inputProps={{ style: { textAlign: "center" } }}
          />
        </MDBox>
      ))}
    </Stack>
  );
};

const ImageUploadDropzone = ({
  isOpen,
  onClose,
  anchorElem,
  listName,
  apiUrl,
  urlBase,
  urlPath = "",
  attribute = "",
  title = "",
  componentType,
  typeOfUpload,
  widthAttribute,
  imageBaseUrl,
  selectedImg = "",
  company,
  setCompany,
  sourceComponent,
  refetchCompany,
  isPanel = false,
  isNested = false,
}) => {
  const styles = useStyles();
  // const {
  //   company: primaryCompany,
  //   setCompany,
  //   currentCompany,
  //   setCompanyType,
  // } = useAppContextController();
  // const { isLoadingCompany, refetchCompany } = useCompanyCache({
  //   company: primaryCompany,
  //   setCompany,
  //   setCompanyType,
  // });

  // const [company] = useState(sourceComponent === "Customer" ? currentCompany : primaryCompany);

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const companyList = isNested ? company?.images?.[listName] : company?.[listName];
  const companyAttr = isNested ? company?.images?.[attribute] : company?.[attribute];

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany);
  const updateCompanyHandler = async (values) => {
    if (!company?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updateCompanyMutation(
        { companyId: company._id, body: values },
        {
          onSuccess: async (res) => {
            await queryClient.invalidateQueries("companies");
            setCompany(values);
            enqueueSnackbar(`Company ${typeOfUpload} has been updated.`, { variant: "success" });
            refetchCompany();
          },
        }
      );
    }
  };

  const saveChanges = async (values) => {
    if (!company._id) {
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
      return;
    }
    if (isNested) updateCompanyHandler({ ...company, images: { ...company?.images, ...values } });
    else updateCompanyHandler({ ...company, ...values });
  };

  const handleUploadedImage = (img) => {
    if (companyList?.includes(img)) {
      enqueueSnackbar(`Uploaded ${img} already exists!`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    let imageTemp = [];
    const imageStore = {};

    imageTemp = companyList ? [...companyList, img] : [img];
    imageStore[attribute] = `${img}`;
    imageStore[listName] = imageTemp;

    saveChanges(imageStore);
  };

  const selectedImageHandler = (img) => {
    const setImage = `${img}`;
    saveChanges({ [attribute]: setImage });
  };

  const removeImageHandler = (imageName, indexId) => {
    if (companyList.length <= 1) {
      enqueueSnackbar(`Must have atleast 1 ${typeOfUpload}!`, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    const imageStore = {};
    const bannerUrlsTemp = companyList?.filter((details, index) => index !== indexId);
    const isFoundImage = companyAttr?.search(imageName) !== -1;

    imageStore[attribute] = isFoundImage ? `${bannerUrlsTemp[0]}` : company[attribute];
    imageStore[listName] = bannerUrlsTemp;

    saveChanges(imageStore);
  };

  const handleOnDrop = async (acceptedFiles, rejectedFiles) => {
    const [file] = acceptedFiles;

    if (rejectedFiles.length !== 0)
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    if (acceptedFiles.length !== 0) {
      const form = new FormData();
      form.append("file", file);
      await baseAxios.post(apiUrl, form);
      handleUploadedImage(file.name);
    }
  };

  return (
    <div id={`${attribute}_Menu`}>
      <MDTypography
        variant="h6"
        color="dark"
        style={{ padding: "0.5rem 0", borderBottom: isPanel ? "" : "2px solid #7b809a66" }}
      >
        {title}
      </MDTypography>
      <Stack spacing={1} direction="column">
        <Grid
          container
          spacing={2}
          style={{ marginTop: isPanel ? "" : "0.5rem" }}
          alignItems="center"
        >
          <Grid item xs={isPanel ? 12 : 3}>
            <FileDropzone
              message="Click here to Upload"
              onDrop={handleOnDrop}
              key={`${attribute}_Dropzone`}
              maxSize={5000000}
              restrict={{ "image/*": [] }}
            />
          </Grid>
          {company && (
            <Grid item xs={isPanel ? 12 : 9}>
              {companyList && companyList?.length > 0 && (
                <Item isPanel={isPanel}>
                  <ImageListHandler
                    {...{
                      urlBase,
                      path: urlPath,
                      imageList: companyList,
                      width: widthAttribute,
                      selectedImageHandler,
                      removeImageHandler,
                      company,
                      componentType,
                      typeOfUpload,
                      selectedImg,
                    }}
                  />
                </Item>
              )}
            </Grid>
          )}
        </Grid>
      </Stack>
    </div>
  );
};

const Item = styled(Box)(({ theme, isPanel }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: isPanel ? "" : theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledCancelBtn = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: "-10px",
  top: "0px",
  zIndex: 1,
  padding: 0.5,
  backgroundColor: "#FFF",
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  "align-items": "center",
  "justify-content": "center",
  "padding-top": "10px",
  borderRadius: 5,
  "&": { transitionDelay: "0.2s" },
}));

const StyledImage = styled(Box)(({ theme }) => ({
  height: "100px",
}));

export default ImageUploadDropzone;
