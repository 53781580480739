import axios from "axios";

import { baseAxios } from "config";

const fetchUserActivityReport = async ({ userId, period = "week", startDate, endDate }) => {
  try {
    let url = `/users/activity-report/${userId}/${period}`;

    // If custom date range is provided, add as query parameters
    if (period === "custom" && startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`;
    }

    const res = await baseAxios.get(url);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchUserActivityReport;
