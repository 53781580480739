import { baseAxios } from "config";

const saveReportPanel = async ({ feature, details, recordId }) => {
  try {
    const res = await baseAxios.post(`/reports/panels`, {
      feature,
      details,
      recordId,
    });
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default saveReportPanel;
