import { useMutation, useQueryClient } from "react-query";
import checkHelmSyncAllowed from "api/helm/checkHelmSyncAllowed";
import SnackbarActionsHelmSync from "layouts/pages/applicants/components/ApplicantMainInfoPanel/components/SnackbarActionsHelmSync";
import syncDataWithHelm from "api/helm/syncDataWithHelm";
import { useSnackbar } from "notistack";
import { AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR } from "layouts/pages/applicants/components/ApplicantMainInfoPanel/ApplicantForm";
import { useAppContextController } from "context/AppContext";
import SnackbarActionsHelmOverwrite from "layouts/pages/applicants/components/ApplicantMainInfoPanel/components/SnackbarActionsHelmOverwrite";

export const useInvalidateCheckForHelmAllowed = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { currentApplicant, setCurrentApplicant } = useAppContextController();

  const syncWithHelm = useMutation(syncDataWithHelm, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries("checkForHelmAllowed");
      setCurrentApplicant({ ...currentApplicant, ...res?.data });
      enqueueSnackbar("Synced With Helm successfully", { variant: "success" });
    },
    onError: async (err, { id }) => {
      if (err.response?.data?.code === "helmErrorSSNTaken") {
        enqueueSnackbar(err.response?.data?.message, {
          autoHideDuration: (AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR + 1) * 1000,
          variant: "warning",
          anchorOrigin: {
            horizontal: "right",
            vertical: "bottom",
          },
          action: (snackbarId) => (
            <SnackbarActionsHelmOverwrite
              snackbarId={snackbarId}
              handleSyncNow={() =>
                syncWithHelm.mutateAsync({
                  id,
                  overwriteEmployee: true,
                })
              }
            />
          ),
        });
      } else {
        enqueueSnackbar(err.response?.data?.message ?? `Something went wrong`, {
          variant: "error",
        });
      }
    },
  });

  const invalidateCheckForHelmAllowed = async (checkForAutoSync, applicantId) => {
    try {
      queryClient.invalidateQueries("checkForHelmAllowed");

      if (applicantId && checkForAutoSync) {
        const response = await checkHelmSyncAllowed(applicantId);

        // helm will be inactive only when helmActive is false if key does not exist it will be active
        if (response?.data?.helmActive === false) return;

        if (!response.data?.isOnHelm && response.data?.isValid) {
          enqueueSnackbar("Helm Syncing will start in", {
            autoHideDuration: (AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR + 1) * 1000,
            variant: "info",
            anchorOrigin: {
              horizontal: "right",
              vertical: "bottom",
            },
            action: (snackbarId) => (
              <SnackbarActionsHelmSync
                snackbarId={snackbarId}
                handleSyncNow={() => syncWithHelm.mutateAsync({ id: applicantId })}
                timeOutSec={AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR}
              />
            ),
          });
        }
        if (response.data?.isOnHelm && !currentApplicant?.employeeID && response.data?.isValid) {
          enqueueSnackbar("Already synced, Do you want to sync again", {
            autoHideDuration: (AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR + 1) * 1000,
            variant: "warning",
            anchorOrigin: {
              horizontal: "right",
              vertical: "bottom",
            },
            action: (snackbarId) => (
              <SnackbarActionsHelmSync
                autoSync={false}
                snackbarId={snackbarId}
                handleSyncNow={() => syncWithHelm.mutateAsync({ id: applicantId })}
                timeOutSec={AUTO_HIDE_DURATION_HELM_SYNC_SNACKBAR}
              />
            ),
          });
        }
      }
    } catch (error) {
      console.error("Error during query invalidation or fetching data:", error);
      if (checkForAutoSync) {
        enqueueSnackbar(`Something went wrong while checking status of helm sync`, {
          variant: "error",
        });
      }
    }
  };

  return { invalidateCheckForHelmAllowed };
};
