import { baseAxios } from "config";

const getDynamicFormData = async ({ form, formName }) => {
  try {
    const formData = new FormData();
    formData.append("file", form.get("file"));

    if (formName) {
      formData.append("formName", formName);
    }

    const res = await baseAxios.post(`/llm/dynamicForms`, formData);
    return res;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default getDynamicFormData;
