// hooks
import { useMemo, useState } from "react";

// store/api/helper
import { useAppContextController } from "context/AppContext";

// style
import { arrayMove } from "@dnd-kit/sortable";
import { AutoAwesome, Edit } from "@mui/icons-material";
import { Box, Button, Grid, Stack } from "@mui/material";

// components
import CircularLoader from "components/CircularLoader";
import DataListContainer from "components/DataListContainer";
import MDTypography from "components/MDTypography";

import AssessmentQuestionsModal from "components/AssessmentQuestionsModal";
import MDBox from "components/MDBox";
import FormField from "layouts/applications/wizard/components/FormField";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";
import { v4 as uuidv4 } from "uuid";

import { useSnackbar } from "notistack";
import AIQuestionsItem from "./AssessmentQuestionsPanelItem";
import AIQuestionsListWrapper from "./AssessmentQuestionsPanelWrapper";

const { AI_EDUCATION_LEVEL } = COMPANY_ENTITIES;

const AssessmentQuestionsPanel = ({
  questions,
  questionKey = "questions",
  addQuestion,
  removeQuestion,
  updateQuestion,
  questionIndex,
  setQuestionIndex,
  errors,
  setValue,
  isReadOnly,
  title,
  noHeader,
  noTopPadding,
  onPressGenerate,
  isLoadingGenerateQuestion,
  skills,
  assessmentType,
}) => {
  const { companyType, company } = useAppContextController();
  const [isOpenTemplateModal, setIsOpenTemplateModal] = useState(false);
  const [numOfQuestions, setNumOfQuestions] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddQuestion = () => {
    setQuestionIndex(questions.length);
  };

  const handleQuestion = (idx, row) => {
    setQuestionIndex(idx);
  };

  const handleEmptyQuestionReset = () => {
    setQuestionIndex(null);
  };

  const handleRemoveQuestion = (idx) => {
    removeQuestion(idx);
    if (idx === questionIndex) {
      setQuestionIndex(null);
    } else if (questionIndex != null && idx < questionIndex) {
      setQuestionIndex(questionIndex - 1);
    }
  };

  const renderQuestions = (row, idx) => (
    <AIQuestionsItem
      row={row}
      idx={idx}
      onEdit={handleQuestion}
      onRemove={handleRemoveQuestion}
      readOnly={isReadOnly}
    />
  );

  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = questions.findIndex((field) => field.text === active.id);
      const newIndex = questions.findIndex((field) => field.text === over.id);

      const reorderedFields = arrayMove(questions, oldIndex, newIndex);
      setValue(questionKey, reorderedFields, { shouldDirty: true });
    }
  };

  const header = useMemo(() => {
    if (noHeader || isReadOnly) return undefined;
    return (
      <>
        <MDBox sx={{ width: "6rem" }}>
          <FormField
            type="number"
            label="# of questions"
            name="timeLimit"
            value={numOfQuestions}
            onChange={(test) => {
              setNumOfQuestions(test.target.value);
            }}
            InputProps={{ inputProps: { min: 1, max: 50 } }}
          />
        </MDBox>
        <Button
          disableRipple
          disabled={isLoadingGenerateQuestion}
          onClick={(e) => {
            e.stopPropagation();
            onPressGenerate(numOfQuestions ?? 1);
          }}
        >
          {isLoadingGenerateQuestion && (
            <CircularLoader size="2rem" color="info" hasBackdrop={false} isLoading />
          )}
          <Grid container columnGap={1} alignItems="center">
            <AutoAwesome sx={{ color: "#8935f4" }} />
            <MDTypography
              component="label"
              variant="button"
              fontWeight="medium"
              color="violet"
              sx={{ fontSize: "0.8rem", color: "#8935f4", cursor: "inherit" }}
            >
              Generate Questions
            </MDTypography>
          </Grid>
        </Button>
      </>
    );
  }, [noHeader, isReadOnly, numOfQuestions, isLoadingGenerateQuestion, onPressGenerate]);

  const addIcon = useMemo(() => {
    if (noHeader) return undefined;
    if (isReadOnly) return <Edit />;
    return undefined;
  }, [noHeader, isReadOnly]);

  const handleQuestionSave = (questionData) => {
    const newQuestion = {
      ...questionData,
      _id: questionData._id || uuidv4(),
    };

    if (questions.find((q) => q._id === questionData._id))
      updateQuestion(questionData._id, questionData);
    else addQuestion(newQuestion);

    enqueueSnackbar("Question saved successfully", { variant: "success" });

    handleEmptyQuestionReset();
  };

  return (
    <>
      <Stack spacing={1} sx={{ p: 0 }}>
        <Box pt={noHeader || noTopPadding ? 0 : 2}>
          <DataListContainer
            onAdd={handleAddQuestion}
            data={questions}
            renderRow={renderQuestions}
            title={noHeader ? null : title}
            tableHeight={265}
            height={isReadOnly ? undefined : 267}
            selected={questionIndex}
            header={header}
            AddIcon={addIcon}
            noCreate={noHeader}
            ListWrapper={AIQuestionsListWrapper}
            listWrapperProps={{
              items: questions,
              onDragEnd: handleDragEnd,
            }}
            autoHeight={isReadOnly}
          />
        </Box>
        <MDTypography color="error">{(Object.values(errors ?? {})[0] ?? {}).message}</MDTypography>
      </Stack>
      <AssessmentQuestionsModal
        open={questionIndex != null}
        onClose={handleEmptyQuestionReset}
        onSave={handleQuestionSave}
        question={questions?.[questionIndex]}
        skills={skills}
        assessmentType={assessmentType || ""}
      />
    </>
  );
};

export default AssessmentQuestionsPanel;
