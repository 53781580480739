import TableAction from "components/TableAction";
import PropTypes from "prop-types";
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PreviewIcon from "@mui/icons-material/Preview";
import { useAppContextController } from "context/AppContext";
import GenericMoreButton from "components/GenericMoreButton";

const VenueEventsTableActions = ({
  eventUrl,
  setNavigateUrl,
  row,
  setActionSelected,
  setFilters,
  setOpen,
  setEventPreview,
  setCurrentEvent,
  getAndSetFullEvent,
}) => {
  const { userType, currentLoggedUser } = useAppContextController();

  const onClickHandler = (action, e) => {
    e.stopPropagation();

    const nodes = new URL(window.location).pathname.split("/");
    const baseRoute = nodes?.includes("venues")
      ? `/events/venue/${row?.venueSlug}/${row?.eventUrl}`
      : `/events/${row?.eventUrl}`;

    // setCurrentEvent(row);
    getAndSetFullEvent(row._id);
    setNavigateUrl(`${baseRoute}/action/${action}`);
  };

  const setPreviewOpen = (event, e) => {
    e.stopPropagation();
    setEventPreview(event);
    setOpen(true);
  };

  return (
    <span>
      {["Master", "Admin"].includes(userType) && (
        <>
          <TableAction
            color="info"
            icon="info"
            clickAction={(e) => onClickHandler("eventsinfo", e)}
            tooltip="Event Info"
          />
          &nbsp;&nbsp;
        </>
      )}
      <GenericMoreButton
        buttons={[
          {
            color: "error",
            icon: "people",
            clickAction: (e) => onClickHandler("eventsroster", e),
            tooltip: "Event Applicant Roster",
          },
          // ...(["Master", "Admin"].includes(userType)
          //   ? [
          //       {
          //         color: "secondary",
          //         icon: "handshake",
          //         clickAction: (e) => onClickHandler("eventsrosterpartners", e),
          //         tooltip: "Event Partner Roster",
          //       },
          //     ]
          //   : []),
          {
            color: "info",
            icon: "preview",
            clickAction: (e) => setPreviewOpen(row, e),
            tooltip: "Event Preview",
          },
        ]}
      />
    </span>
  );
};

export default VenueEventsTableActions;

VenueEventsTableActions.defaultProps = {
  eventUrl: "",
};

// Typechecking props for the TableAction
VenueEventsTableActions.propTypes = {
  eventUrl: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
