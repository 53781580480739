import { baseAxios } from "../../config";

/**
 * Updates a dynamic form with new form data
 * @param {string} formId - The ID of the form to update
 * @param {object} formData - The updated form data
 * @returns {Promise} - The API response
 */
const updateDynamicForm = async ({ formId, formData }) => {
  try {
    const response = await baseAxios.put(`/llm/dynamicForms/${formId}`, {
      formData,
    });
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export default updateDynamicForm;
