import { useState } from "react";
import { Card, Button, Box, Icon } from "@mui/material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import VenueIcon from "components/VenueIcon";
import { renderToString } from "react-dom/server";
import MDTypography from "components/MDTypography";
import parse from "html-react-parser";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import { LocationOn } from "@mui/icons-material";

const JobDescription = ({ job, activeCompany, jobNotFound }) => {
  const { venues, allVenues, company, isMobile } = useAppContextController();

  const [showMore, setShowMore] = useState(false);
  const expandText = () => setShowMore(!showMore);
  let shortDescription = "";
  if (job?.description) {
    shortDescription = job.description;
  }
  return (
    <Card id="table-jobs" shadow="md">
      <MDBox display="flex" justifyContent="space-between" p={2}>
        <MDBox display="flex" width="100%">
          {jobNotFound ? (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              alignSelf="stretch"
              variant="gradient"
              bgColor="white"
              color="white"
              borderRadius="xl"
              pl={10}
              pr={10}
              pb={5}
              pt={5}
            >
              <Icon color="error" fontSize="large">
                cancel
              </Icon>
              <MDTypography variant="h4" pl={1}>
                Job not found
              </MDTypography>
            </MDBox>
          ) : (
            <>
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor="white"
                color="white"
                borderRadius="xl"
                p={3}
                mr={2}
              >
                {company?.companyType === "Venue" ? (
                  <VenueIcon
                    logoUrl={allVenues?.[job?.venueSlug]?.logoUrl}
                    slug={job?.venueSlug}
                    name={allVenues?.[job?.venueSlug]?.name}
                  />
                ) : (
                  <CompanyLogoSquare company={activeCompany} />
                )}
              </MDBox>
              <MDBox width="100%">
                {job?.title && <MDTypography variant="h4">{job?.title}</MDTypography>}
                {job?.venueName && <MDTypography variant="h5">{job?.venueName}</MDTypography>}
                {/* <MDTypography variant="body2">{job?.venueCity && job?.venueCity}{job?.companyState && job?.venueState}</MDTypography> */}
                {company?.companyType === "Venue" ? (
                  <MDTypography variant="body2">
                    {job?.venueCity && job?.venueState && <LocationOn color="error" />}
                    {job?.venueCity && job?.venueCity}
                    {job?.venueCity && job?.venueState && ","}
                    {job?.venueState && job?.venueState}
                  </MDTypography>
                ) : (
                  <MDTypography variant="body2">
                    {job?.companyCity && job?.companyState && <LocationOn color="error" />}
                    {job?.companyCity && job?.companyCity}
                    {job?.venueCity && job?.companyState && ","}
                    {job?.companyState && job?.companyState}
                  </MDTypography>
                )}
                {!isMobile && (
                  <MDBox mt={2} width="100%">
                    <Box sx={{ typography: "body2" }} pr={1}>
                      {!showMore && shortDescription?.length
                        ? parse(shortDescription?.substring(0, 250))
                        : parse(shortDescription)}
                    </Box>
                    {shortDescription?.length > 250 && (
                      <MDBox textAlign="end">
                        <Button color="error" sx={{ justifySelf: "end" }} onClick={expandText}>
                          {showMore ? "Show Less" : "Show More..."}
                        </Button>
                      </MDBox>
                    )}
                  </MDBox>
                )}
              </MDBox>
            </>
          )}
        </MDBox>
      </MDBox>
      {isMobile && (
        <>
          <MDBox mt={2} width="100%">
            <Box sx={{ typography: "body2" }} p={2}>
              {!showMore && shortDescription?.length
                ? parse(shortDescription?.substring(0, 250))
                : parse(shortDescription)}
            </Box>
            {shortDescription?.length > 250 && (
              <MDBox textAlign="end">
                <Button color="error" sx={{ justifySelf: "end" }} onClick={expandText}>
                  {showMore ? "Show Less" : "Show More..."}
                </Button>
              </MDBox>
            )}
          </MDBox>
        </>
      )}
    </Card>
  );
};

export default JobDescription;
