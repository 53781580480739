import axios from "axios";

import { baseAxios } from "config";

const updateAssessment = async ({ _id, data }) => {
  try {
    // eslint-disable-next-line
    if (data?._id) {
      // eslint-disable-next-line no-param-reassign
      delete data._id;
    }
    const res = await baseAxios.put(`/assessments/${_id}`, data);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default updateAssessment;
