import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AttachFile from "@mui/icons-material/AttachFile";
import { Button, Grid, Icon, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { getCommonBaseImageUrl, getCompanyImageUrl } from "utils/helpers/upload";
import updateApplicant from "../../actions/updateApplicant";
import FileViewer from "./FileViewer";

const useStyle = makeStyles({
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  addButton: {
    fontSize: 40,
    marginRight: 15,
  },
  section_image_delete: {
    position: "absolute!important",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },
});

const ApplicantAttachments = ({
  setOpen,
  refresh,
  applicant,
  setApplicant,
  noHeader,
  tableHeight = 600,
  height = 850,
}) => {
  const classes = useStyle();
  const {
    currentApplicant: currentApplicantContext,
    setCurrent,
    company,
  } = useAppContextController();

  const currentApplicant = applicant ?? currentApplicantContext;

  const IMAGE_SERVER = getCompanyImageUrl(company);
  const [files, setFiles] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar(`Something went wrong!`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      enqueueSnackbar(`Attachment has been deleted!`, { variant: "success" });
      if (refresh) await refresh();
      if (applicant && setApplicant) setApplicant({ ...applicant, ...data });
    },
  });

  useEffect(() => {
    if (currentApplicant?.attachments?.length) {
      setFiles(
        currentApplicant?.attachments.map((file) => {
          const { docType, filename, name, title, type, uploadDate, presignedUrl } = file;
          return { docType, filename, name, title, type, uploadDate, presignedUrl };
        })
      );
    } else {
      setFiles([]);
    }
  }, [currentApplicant]);

  const addNew = (e) => {
    setOpen(true);
  };

  const removeAttachmentHandler = async (attachmentID) => {
    const newAttachmentsArr = files.filter((file, index) => index !== attachmentID);
    setFiles(newAttachmentsArr);

    await updateApplicantMutation.mutateAsync({
      applicantId: currentApplicant?._id,
      data: { attachments: newAttachmentsArr },
    });
  };

  const renderRow = (row, index) => {
    return (
      <FileViewer
        file={{
          docType: row.docType,
          type: row.type,
          filename: row.filename || row.name,
          name: row.name,
          title: row.title,
          uploadDate: row.uploadDate,
          presignedUrl: row.presignedUrl, // Assuming this is available in row
        }}
        currentApplicant={currentApplicant}
        IMAGE_SERVER={IMAGE_SERVER}
        company={company}
        onDelete={() => removeAttachmentHandler(index)}
      />
    );
  };

  const header = (
    <>
      <IconButton className={classes.addButton} color="info" onClick={addNew}>
        <AddCircleRoundedIcon />
      </IconButton>
    </>
  );
  return (
    <>
      <DataList
        renderRow={renderRow}
        data={files}
        onRowClick={() => {}}
        icon={<AttachFile />}
        iconColor="error"
        title={noHeader ? undefined : "Attachments"}
        isLoading={false}
        greybar
        divider
        header={header}
        tableHeight={tableHeight}
        height={height}
        scrollY
        computedHeight
        noIcon={noHeader}
      />
    </>
  );
};

export default ApplicantAttachments;
