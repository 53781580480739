import { useState } from "react";
import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import { AttachFile, Cancel as CancelIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FileDropzone from "components/Dropzone";
import FormField from "layouts/applications/wizard/components/FormField";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { baseAxios } from "config";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import { useAppContextController } from "context/AppContext";
import { getFileRejectionErrors } from "utils/helpers/formatters";

const useStyle = makeStyles({
  dropzone: {
    height: 170,
  },
});
const allowedFileTypes = "PDF, TXT, PNG, BMP, JPEG/JPG, DOC, DOCX";

const VoidedCheckUploadModal = ({
  open,
  setOpen,
  currentApplicant,
  onUploadSuccess,
  onSwitchToBranch,
  outsideMode,
}) => {
  const classes = useStyle();
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentApplicant } = useAppContextController();

  // Define max file size (3MB)
  const MAX_FILE_SIZE = 3 * 1024 * 1024;

  const updateApplicantMutation = useMutation(updateApplicant, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Attachment has been uploaded!", { variant: "success" });
      if (currentApplicant && setCurrentApplicant)
        setCurrentApplicant({ ...currentApplicant, ...data });
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onDropHandler = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length !== 0) {
      // Use the utility function to get formatted error messages
      const errorMessage = getFileRejectionErrors(
        rejectedFiles,
        allowedFileTypes,
        MAX_FILE_SIZE,
        "Voided Check"
      );

      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
      return;
    }

    if (acceptedFiles.length !== 0) {
      const file = acceptedFiles[0];
      const form = new FormData();
      form.append("file", file);

      if (currentApplicant?._id) {
        setIsUploading(true);
        try {
          const response = await baseAxios.post(
            `/${outsideMode ? `outside-${outsideMode}/` : ""}upload/applicants/${
              currentApplicant?._id
            }/Voided_Check`,
            form
          );

          const checkData = response?.data?.recognition;

          if (!checkData.isCheck) {
            throw new Error("Invalid voided check document");
          }

          // Create new attachment object
          const newAttachment = {
            title: "Voided Check",
            type: "Voided_Check",
            docType: file.path.split(".").pop(),
            filename: file.path,
            uploadDate: new Date(),
            ...response?.data,
          };

          // Update applicant's attachments
          const newAttachmentsArr = currentApplicant.attachments
            ? [...currentApplicant.attachments, newAttachment]
            : [newAttachment];

          await updateApplicantMutation.mutateAsync({
            applicantId: currentApplicant?._id,
            data: {
              attachments: [...newAttachmentsArr],
            },
            outsideMode,
          });
          // Prepare form data for direct deposit fields
          const formData = {
            routing1: checkData.routingNumber,
            account1: checkData.accountNumber,
            bankName1: checkData.bankName,
            accountType1: checkData.accountType,
          };

          onUploadSuccess(formData);
          setOpen(false);
          enqueueSnackbar("Voided check has been uploaded and processed successfully!", {
            variant: "success",
          });
        } catch (error) {
          console.error("Upload failed:", error);
          let errorMessage = "Failed to upload voided check.";
          if (error?.response?.status === 400) {
            errorMessage = "Failed to grab check information from the uploaded document.";
          }
          if (error?.response?.status === 413) {
            errorMessage = "File size too large. Please choose a smaller file.";
          } else if (error?.response?.status === 415) {
            errorMessage = "Invalid file type. Please choose a different file.";
          } else if (error.message === "Invalid voided check document") {
            errorMessage = "The uploaded document is not a valid voided check.";
          }
          enqueueSnackbar(`${errorMessage} Would you like to try Branch Virtual Wallet instead?`, {
            variant: "warning",
            autoHideDuration: 10000,
            action: (
              <Button
                color="success"
                size="small"
                onClick={() => {
                  setOpen(false);
                  onSwitchToBranch();
                }}
              >
                Switch to Branch
              </Button>
            ),
          });
        } finally {
          setIsUploading(false);
        }
      }
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent sx={{ padding: "24px", maxWidth: "580px", width: "500px" }}>
          <FlexWrapper justifyContent="space-between" sx={{ flexWrap: "nowrap" }}>
            <MDTypography sx={{ flex: 1 }}>
              <AttachFile color="error" /> Upload Voided Check
            </MDTypography>
            <MDBox sx={{ flex: 0 }}>
              <Button
                variant="text"
                disabled={isUploading}
                startIcon={<CancelIcon />}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </MDBox>
          </FlexWrapper>
          <Grid item lg={8}>
            <FormField disabled variant="standard" label="Attachment Type" value="Voided Check" />
          </Grid>
          <MDBox mt={2}>
            <MDTypography variant="h5" color="info">
              Upload Voided Check or Form from your bank with routing & account number
            </MDTypography>
            <FileDropzone
              disabled={isUploading}
              multiple={false}
              message="Drop Voided Check or Form from your bank with routing & account number here to upload"
              message2={`Allowed types: ${allowedFileTypes}`}
              className={classes.dropzone}
              onDrop={onDropHandler}
              maxSize={MAX_FILE_SIZE}
              accept={{
                "application/pdf": [".pdf"],
                "text/plain": [".txt"],
                "image/png": [".png"],
                "image/bmp": [".bmp"],
                "image/jpeg": [".jpeg", ".jpg"],
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
                  ".docx",
                ],
              }}
            />
          </MDBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VoidedCheckUploadModal;
