import React, { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import { useAppContextController } from "context/AppContext";
import { useOutsideCompanyCache } from "hooks/useCompanyCache";
import {
  getCommonBaseImageUrl,
  getCompanyBaseImageUrl,
  getCompanyImageUrl,
} from "utils/helpers/upload";
import MDTypography from "components/MDTypography";
import ContainerWrapper from "components/Wrapper/ContainerWrapper";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import MDBox from "components/MDBox";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
  Alert,
  Box,
  useMediaQuery,
  Stack,
  Paper,
  LinearProgress,
  Grid,
  OutlinedInput,
  TextareaAutosize,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
  Divider,
  Avatar,
} from "@mui/material";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/GetApp";
import LockIcon from "@mui/icons-material/Lock";
import EmailIcon from "@mui/icons-material/Email";
// import ExcelDropzone from "@/components/ui/ExcelDropzone";
import { isNaN } from "lodash";
import { useParams } from "react-router-dom";
import { UploadFile } from "@mui/icons-material";
import FileDropzone from "components/Dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import HomeHeader from "../web/components/HomeHeader";
import { getAssessmentForApplicant } from "./actions/getAssessmentForApplicant";
import { sendAssessmentOTP, verifyAssessmentOTP } from "./actions/assessmentOTP";
import { startAssessment } from "./actions/startAssessment";
import { evaluateExcelSubmission } from "./actions/evaluateExcelSubmission";
import { answerTimeUpdate } from "./actions/answerTimeUpdate";
import { evaluateAssessment } from "./actions/evaluateAssessment";
import AssessmentTimer from "./components/AssessmentTimer";
import TimeOutModal from "./components/TimeOutModal";
import JobDescriptionModal from "./components/JobDescriptionModal";

const useStyles = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
    width: "85%",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",
    border: "1px dashed #C9C9C9",
    padding: "20px 20px",
    cursor: "pointer",
    borderRadius: "4px",
  },
  section_image_container: {
    display: "inline-block",
    position: "relative",
    margin: "4px 8px",
    cursor: "pointer",
  },
});

const StyledImg = styled("img")(() => ({
  borderRadius: "15px",
}));

const RenderAssessmentPage = () => {
  const classes = useStyles();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { company, setCompany, setCompanyType, isMobile } = useAppContextController();
  const { isLoadingCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: "public",
  });

  const IMAGE_SERVER = getCompanyImageUrl(company);

  //   const router = useRouter();

  const { id: assessmentId, applicantId } = useParams();

  // Assessment states
  const [assessment, setAssessment] = useState(null);
  const [applicant, setApplicant] = useState(null);
  const [job, setJob] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [randomizedAnswers, setRandomizedAnswers] = useState({});
  const [isStarted, setIsStarted] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentStatus, setAssessmentStatus] = useState(null);
  const [completedDate, setCompletedDate] = useState(null);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(Date.now());
  const [timeoutDialogOpen, setTimeoutDialogOpen] = useState(false);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [uploadStatus, setUploadStatus] = useState({
    loading: false,
    error: null,
    success: false,
  });
  const [answerFile, setAnswerFile] = useState(null);

  // OTP related states
  const [requiresOtp, setRequiresOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpExpiresAt, setOtpExpiresAt] = useState(null);

  useEffect(() => {
    const fetchAssessment = async () => {
      if (!assessmentId || !applicantId) return;

      try {
        setIsLoading(true);
        const response = await getAssessmentForApplicant(assessmentId, applicantId);

        if (response.success) {
          setAssessment(response.data.assessment);
          setApplicant(response.data.applicant);
          setJob(response.data.job);
          setAssessmentStatus(response.data.assessmentStatus);

          // Set OTP related states
          setRequiresOtp(response.data.requiresOtp || false);
          setOtpVerified(response.data.otpVerified || false);
          setOtpExpired(response.data.otpExpired || false);

          // If OTP is required but not verified, trigger sending OTP
          if (response.data.requiresOtp && !response.data.otpVerified && !otpSent) {
            // Don't auto-send to avoid overwhelming the user
            // Just set a flag that they should request it
            setOtpSent(false);
          }

          if (
            response.data.assessment.assessmentStatus === "Started" ||
            assessmentStatus === "Started"
          ) {
            // Handle stored answers
            const storedAnswers = localStorage.getItem(
              `assessmentAnswers-${assessmentId}-${applicantId}`
            );
            if (storedAnswers) {
              setAnswers(JSON.parse(storedAnswers));
            }

            const storedQuestionIndex = localStorage.getItem(
              `currentQuestionIndex-${assessmentId}-${applicantId}`
            );
            if (storedQuestionIndex) {
              setCurrentQuestionIndex(parseInt(storedQuestionIndex, 10));
            }

            // Handle stored randomized answers
            const storedRandomizedAnswers = localStorage.getItem(
              `randomizedAnswers-${assessmentId}-${applicantId}`
            );

            if (storedRandomizedAnswers) {
              // Use stored randomized answers if they exist
              setRandomizedAnswers(JSON.parse(storedRandomizedAnswers));
            } else {
              // Create new randomized answers if none exist
              const randomized = {};
              response.data.assessment.questions?.forEach((question) => {
                if (question.type === "Multiple Choice") {
                  randomized[question._id] = [...question.answers].sort(() => Math.random() - 0.5);
                }
              });
              setRandomizedAnswers(randomized);

              // Store the new randomized answers
              localStorage.setItem(
                `randomizedAnswers-${assessmentId}-${applicantId}`,
                JSON.stringify(randomized)
              );
            }

            // Handle start time
            const storedStartTime = localStorage.getItem(
              `assessmentStartTime-${assessmentId}-${applicantId}`
            );
            if (storedStartTime) {
              const parsedStartTime = parseInt(storedStartTime, 10);
              if (!isNaN(parsedStartTime)) {
                setStartTime(parsedStartTime);
                setIsStarted(true);
              }
            }
          }
        } else if (response.data?.score) {
          setCompletedDate(response.data.completedAt);
          setAssessmentStatus("Completed");
        } else {
          setError("Assessment or applicant not found");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching the assessment");
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssessment();
  }, [assessmentId, applicantId, otpSent, assessmentStatus]);

  const cleanupStoredData = useCallback(() => {
    localStorage.removeItem(`assessmentAnswers-${assessmentId}-${applicantId}`);
    localStorage.removeItem(`currentQuestionIndex-${assessmentId}-${applicantId}`);
    localStorage.removeItem(`assessmentStartTime-${assessmentId}-${applicantId}`);
    localStorage.removeItem(`randomizedAnswers-${assessmentId}-${applicantId}`);
  }, [applicantId, assessmentId]);

  const handleSendOTP = async () => {
    if (!assessmentId || !applicantId) return;

    try {
      setIsSendingOtp(true);
      setOtpError(null);
      cleanupStoredData();

      const response = await sendAssessmentOTP(assessmentId, applicantId);

      if (response.success) {
        setOtpSent(true);
        setOtpExpiresAt(new Date(response.data.expiresAt));
      } else {
        console.log("response: ", response);
        setOtpError(response.error || "Failed to send verification code");
      }
    } catch (err) {
      console.log("errr: ", err);
      setOtpError(err.message || "An error occurred while sending verification code");
    } finally {
      setIsSendingOtp(false);
    }
  };

  const handleVerifyOTP = async () => {
    if (!assessmentId || !applicantId || !otp) return;

    try {
      setIsVerifyingOtp(true);
      setOtpError(null);
      cleanupStoredData();

      const response = await verifyAssessmentOTP(assessmentId, applicantId, otp);

      if (response.success) {
        setOtpVerified(true);
        setAssessmentStatus("Not Started");

        // Update local storage with start time
        const currentTime = Date.now();
        setStartTime(currentTime);
        localStorage.setItem(
          `assessmentStartTime-${assessmentId}-${applicantId}`,
          currentTime.toString()
        );

        // No need to call startAssessment() again as the verify endpoint already started it
      } else if (response.expired) {
        setOtpExpired(true);
        setOtpError("Verification code has expired. Please request a new one.");
      } else {
        setOtpError(response.error || "Invalid authentication code");
      }
    } catch (err) {
      console.error("OTP verification error:", err);

      // Use the exact API error message when available
      if (err.apiError) {
        setOtpError(err.message);
      } else if (err.response?.data?.error) {
        setOtpError(err.response.data.error);
      } else if (err.message && err.message.includes("status code 400")) {
        // From the network log, show exactly what the API returned
        setOtpError("Invalid authentication code");
      } else {
        setOtpError(err.message || "An error occurred while verifying code");
      }
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  const initAssessment = async () => {
    if (!assessmentId || !applicantId) return;

    // // If assessment hasn't started, always show OTP screen first
    // if (assessmentStatus === "Not Started" && ) {
    //   // Send OTP automatically when user clicks "Begin Assessment"
    //   handleSendOTP();
    //   return;
    // }

    try {
      // Continue with normal assessment start (this will only run if OTP is already verified)
      const response = await startAssessment(assessmentId, applicantId);
      if (response.success) {
        setAssessmentStatus("Started");
        const currentTime = Date.now();
        setStartTime(currentTime);
        setIsStarted(true);

        // Store start time in localStorage
        localStorage.setItem(
          `assessmentStartTime-${assessmentId}-${applicantId}`,
          currentTime.toString()
        );
      } else {
        setError(response.error || "Failed to start assessment");
      }
    } catch (err) {
      setError("Failed to start assessment");
    }
  };

  const getExistingFileUrl = (type, filename) => {
    if (!assessmentId || !filename || !IMAGE_SERVER) return null;
    const baseUrl = `${IMAGE_SERVER}/assessments`;
    const encodedFilename = encodeURIComponent(filename);
    return `${baseUrl}/${assessmentId}/${type}/${encodedFilename}`;
  };

  const size = isMobile ? 70 : 100;

  const handleDownload = (url, filename) => {
    if (!url) return;
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAnswerUpload = (img) => {
    const file = img[0];

    setAnswerFile(file);
    setUploadStatus({
      loading: false,
      error: null,
      success: true,
    });
  };

  const handleExcelSubmit = async () => {
    if (!answerFile) return;

    try {
      setIsSubmitting(true);
      setIsLoading(true);
      setUploadStatus((prev) => ({ ...prev, loading: true, error: null }));

      const result = await evaluateExcelSubmission(assessmentId, applicantId, answerFile);

      if (result.success) {
        setIsComplete(true);
      } else {
        setUploadStatus((prev) => ({
          ...prev,
          error: result.message || "Failed to submit assessment",
          loading: false,
        }));
      }
    } catch (err) {
      setUploadStatus((prev) => ({
        ...prev,
        error: err.message || "An error occurred while submitting",
        loading: false,
      }));
    } finally {
      setIsSubmitting(false);
      setIsLoading(false);
      localStorage.removeItem(`assessmentStartTime-${assessmentId}-${applicantId}`); // Clear stored time on timeout
    }
  };

  const handleSubmit = async () => {
    // Get the current question from assessment questions using the current index
    const currentQuestion = assessment?.questions[currentQuestionIndex];

    const elapsedTime = Math.floor((Date.now() - questionStartTime) / 1000);

    const payload = {
      questionId: currentQuestion?._id.toString(),
      elapsedTime,
    };

    await answerTimeUpdate(assessmentId, applicantId, payload);

    if (currentQuestionIndex === assessment?.questions.length - 1) {
      try {
        setIsSubmitting(true);
        setIsLoading(true);
        const response = await evaluateAssessment(assessmentId, applicantId, answers, job);
        if (response.success) {
          setIsComplete(true);

          cleanupStoredData();
        } else {
          setError("Failed to submit assessment");
        }
      } catch (err) {
        setError(err.message || "An error occurred while submitting the assessment");
      } finally {
        setIsSubmitting(false);
        setIsLoading(false);
      }
    } else {
      const nextIndex = currentQuestionIndex + 1;
      setQuestionStartTime(Date.now()); // Reset timer for next question
      setCurrentQuestionIndex(nextIndex);
      localStorage.setItem(
        `currentQuestionIndex-${assessmentId}-${applicantId}`,
        nextIndex.toString()
      );
    }
  };

  const handleAnswerChange = (questionId, value) => {
    const updatedAnswers = {
      ...answers,
      [questionId]: value,
    };

    setAnswers(updatedAnswers);

    // Store answers in local storage
    localStorage.setItem(
      `assessmentAnswers-${assessmentId}-${applicantId}`,
      JSON.stringify(updatedAnswers)
    );
  };

  const handleTimeoutClose = async () => {
    setTimeoutDialogOpen(false);
    try {
      setIsSubmitting(true);
      const response = await evaluateAssessment(assessmentId, applicantId, answers, job);
      if (response.success) {
        setIsComplete(true);
      } else {
        setError("Failed to submit assessment");
      }
    } catch (err) {
      setError(err.message || "An error occurred while submitting the assessment");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePreviousQuestion = () => {
    const prevIndex = currentQuestionIndex - 1;
    setCurrentQuestionIndex(prevIndex);
    localStorage.setItem(
      `currentQuestionIndex-${assessmentId}-${applicantId}`,
      prevIndex.toString()
    );
  };

  const handleTimeUp = useCallback(() => {
    if (!isTimeUp) {
      setIsTimeUp(true);
      setTimeoutDialogOpen(true);
      cleanupStoredData();
    }
  }, [isTimeUp, cleanupStoredData]);

  const renderExcelAssessment = () => {
    if (!assessment?.excelAssessment) return null;

    const { startingWorkbook } = assessment.excelAssessment;

    return (
      <Container maxWidth="lg" sx={{ py: 4, px: { xs: 2, sm: 3 } }}>
        {assessment && (
          <AssessmentTimer
            timeLimit={assessment?.timeLimit}
            startTime={startTime}
            onTimeUp={handleTimeUp}
          />
        )}

        <Stack spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h4" component="h1" sx={{ fontWeight: "bold" }}>
              {assessment?.name}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Excel-based Assessment
            </Typography>
          </Stack>

          <Paper
            elevation={3}
            sx={{
              overflow: "visible",
              position: "relative",
              borderRadius: 4,
              mt: 8,
              mb: 4,
              pt: 8,
              pb: 6,
              px: { xs: 3, sm: 6 },
              background: "linear-gradient(to bottom, #ffffff, #f9fafc)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Instructions
            </Typography>
            <Typography variant="body1" paragraph>
              1. Download the Excel template provided below
            </Typography>
            <Typography variant="body1" paragraph>
              2. Complete all required tasks within the Excel file
            </Typography>
            <Typography variant="body1" paragraph>
              3. Upload your completed file using the submission section
            </Typography>
            <Typography variant="body1">4. Submit your work before the timer expires</Typography>
          </Paper>

          <Paper
            elevation={3}
            sx={{
              overflow: "visible",
              position: "relative",
              borderRadius: 4,
              mt: 8,
              mb: 4,
              pt: 8,
              pb: 6,
              px: { xs: 3, sm: 6 },
              background: "linear-gradient(to bottom, #ffffff, #f9fafc)",
            }}
          >
            <Stack spacing={3}>
              {/* Template Download Section */}
              <Box>
                <Typography variant="h6" gutterBottom>
                  Starting Template
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <FileIcon sx={{ fontSize: 32, color: "success.main" }} />
                  <Box sx={{ flex: 1 }}>
                    <Typography sx={{ fontWeight: 500 }}>
                      {startingWorkbook?.filename || "Excel Template"}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Download and complete this template
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={() => {
                      const url = getExistingFileUrl(
                        "starting-template",
                        startingWorkbook?.filename
                      );

                      handleDownload(url, startingWorkbook?.filename);
                    }}
                  >
                    Download Template
                  </Button>
                </Box>
              </Box>

              {/* Answer Upload Section */}
              <Box>
                <Typography variant="h6" gutterBottom>
                  Submit Your Answer
                </Typography>

                <FileDropzone
                  message="Drag & Drop or Choose file to upload"
                  onDrop={handleAnswerUpload}
                  maxSize={1048576}
                  size="8rem"
                  icon={<UploadFile />}
                  customStyles={classes}
                  accept={{
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
                    "application/vnd.ms-excel": [".xls"],
                  }}
                />

                {answerFile && (
                  <MDBox className={classes.section_image_container}>
                    <Stack
                      container
                      justifyContent="space-evenly"
                      alignItems="center"
                      mb={3}
                      pt={1}
                    >
                      <MDBox
                        style={{
                          position: "absolute",
                          right: "-10px",
                          top: "-10px",
                          padding: 1,
                          borderRadius: "50%",
                          zIndex: "1000",
                        }}
                      >
                        <IconButton
                          color="error"
                          sx={{ padding: "0px", background: "#fff", zIndex: "1000" }}
                          onClick={() => setAnswerFile(null)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                      </MDBox>

                      <img
                        src={`${getCommonBaseImageUrl(company)}/static/excel_icon.png`}
                        alt="preview"
                        width={size}
                        height={size}
                      />
                      <MDBox>
                        <MDTypography sx={{ fontSize: "12px" }}>{answerFile?.name}</MDTypography>
                      </MDBox>
                    </Stack>
                  </MDBox>
                )}
              </Box>

              {/* Submit Button */}
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleExcelSubmit}
                  disabled={!answerFile || isSubmitting}
                  fullWidth
                  size="large"
                  sx={{
                    py: 1.5,
                    backgroundColor: "#00A3FF",
                    "&:hover": {
                      backgroundColor: "#0091EA",
                    },
                  }}
                >
                  Submit Assessment
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Stack>

        <TimeOutModal
          timeoutDialogOpen={timeoutDialogOpen}
          handleTimeoutClose={handleTimeoutClose}
        />
      </Container>
    );
  };

  // Render OTP verification screen
  const renderOTPVerification = () => {
    return (
      <Container maxWidth="sm" sx={{ py: 4, px: { xs: 2, sm: 3 } }}>
        <Paper
          elevation={3}
          sx={{
            overflow: "visible",
            position: "relative",
            borderRadius: 4,
            mt: 8,
            mb: 4,
            pt: 8,
            pb: 6,
            px: { xs: 3, sm: 6 },
            background: "linear-gradient(to bottom, #ffffff, #f9fafc)",
          }}
        >
          {/* Avatar at top of card */}
          <Avatar
            sx={{
              position: "absolute",
              top: -36,
              left: "50%",
              transform: "translateX(-50%)",
              width: 72,
              height: 72,
              bgcolor: (theme) => theme.palette.primary.main,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <LockIcon sx={{ fontSize: 36 }} />
          </Avatar>

          <Stack spacing={4} alignItems="center">
            <Stack spacing={2} textAlign="center">
              <Typography variant="h4" sx={{ fontWeight: 700, color: "#1a237e" }}>
                Verify Your Identity
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  maxWidth: "90%",
                  mx: "auto",
                  fontSize: "1rem",
                  lineHeight: 1.6,
                }}
              >
                For security reasons, we need to verify your identity before you begin the
                assessment.
              </Typography>
            </Stack>

            <Divider sx={{ width: "100%", my: 1 }} />

            <Box sx={{ width: "100%" }}>
              <Stack spacing={4} width="100%">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2.5,
                    width: "100%",
                    p: 2.5,
                    bgcolor: "rgba(33, 150, 243, 0.08)",
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "primary.light",
                  }}
                >
                  <Avatar sx={{ bgcolor: "primary.main", width: 40, height: 40 }}>
                    <EmailIcon sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                      Verification code will be sent to:
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }}>
                      {applicant?.email || "adannanthony@gmail.com"}
                    </Typography>
                  </Box>
                </Box>

                {otpSent ? (
                  <Stack spacing={3} sx={{ width: "100%" }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="medium"
                      sx={{ textAlign: "center" }}
                    >
                      Enter the 6-digit code sent to your email
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value.replace(/\D/g, ""))}
                      placeholder="000000"
                      error={!!otpError}
                      helperText={otpError}
                      inputProps={{
                        maxLength: 6,
                        style: {
                          fontSize: "1.75rem",
                          letterSpacing: "0.75em",
                          fontWeight: "bold",
                          textAlign: "center",
                          padding: "16px 8px",
                        },
                      }}
                      sx={{ mb: 1 }}
                    />

                    {otpExpiresAt && (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ textAlign: "center", fontWeight: 500 }}
                      >
                        Code expires at {new Date(otpExpiresAt).toLocaleTimeString()}
                      </Typography>
                    )}

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleVerifyOTP}
                      disabled={!otp || isVerifyingOtp || otp.length !== 6}
                      sx={{
                        py: 1.5,
                        mt: 1,
                        borderRadius: 2,
                        textTransform: "none",
                        fontSize: "1rem",
                        fontWeight: 600,
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        background: "linear-gradient(45deg, #1976d2, #2196f3)",
                        "&:hover": {
                          background: "linear-gradient(45deg, #1565c0, #1976d2)",
                        },
                      }}
                    >
                      {isVerifyingOtp ? (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      ) : (
                        "Verify & Begin Assessment"
                      )}
                    </Button>

                    <Button
                      variant="text"
                      disabled={isSendingOtp}
                      onClick={handleSendOTP}
                      sx={{
                        color: "primary.main",
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                    >
                      {isSendingOtp ? (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <CircularProgress size={16} color="inherit" /> Sending...
                        </Box>
                      ) : (
                        "Resend Code"
                      )}
                    </Button>
                  </Stack>
                ) : (
                  <Stack spacing={3}>
                    <Typography variant="subtitle1" sx={{ textAlign: "center", fontWeight: 500 }}>
                      Click the button below to receive your verification code
                    </Typography>

                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSendOTP}
                      disabled={isSendingOtp}
                      sx={{
                        py: 1.5,
                        borderRadius: 2,
                        textTransform: "none",
                        fontSize: "1rem",
                        fontWeight: 600,
                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        background: "linear-gradient(45deg, #1976d2, #2196f3)",
                        "&:hover": {
                          background: "linear-gradient(45deg, #1565c0, #1976d2)",
                        },
                      }}
                    >
                      {isSendingOtp ? (
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <CircularProgress size={20} color="inherit" /> Sending Code...
                        </Box>
                      ) : (
                        "Send Verification Code"
                      )}
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Stack>
        </Paper>
      </Container>
    );
  };

  const renderAssessmentContent = () => {
    // Show loading state first
    if (isLoading) {
      return (
        <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
          <CircularProgress size={60} />
        </Box>
      );
    }

    // Error state
    if (error) {
      return (
        <Alert severity="error" sx={{ my: 3 }}>
          {error}
        </Alert>
      );
    }

    // Check for valid assessment content first
    const hasValidQuestions = assessment?.questions?.length > 0;
    const isValidExcelAssessment = assessment?.type === "Excel" && assessment?.excelAssessment;

    // No questions state - check this before OTP verification
    if (!hasValidQuestions && !isValidExcelAssessment) {
      return (
        <Container maxWidth="sm" sx={{ py: 4, px: { xs: 2, sm: 3 } }}>
          <Paper
            elevation={3}
            sx={{
              overflow: "visible",
              position: "relative",
              borderRadius: 4,
              mt: 8,
              mb: 4,
              pt: 8,
              pb: 6,
              px: { xs: 3, sm: 6 },
              background: "linear-gradient(to bottom, #ffffff, #f9fafc)",
              textAlign: "center",
            }}
          >
            <Typography variant="h4" sx={{ mb: 2, color: "#1e293b", fontWeight: 700 }}>
              Assessment Not Available
            </Typography>
            <Typography variant="body1" color="text.secondary">
              This assessment is not properly configured. Please contact the administrator.
            </Typography>
          </Paper>
        </Container>
      );
    }

    // Completed state
    if (assessmentStatus === "Completed" || completedDate) {
      return (
        <Alert severity="info" sx={{ my: 3 }}>
          You have already completed this assessment on{" "}
          {new Date(completedDate).toLocaleDateString()}
        </Alert>
      );
    }

    // Success state
    if (isComplete) {
      return (
        <Box sx={{ textAlign: "center", py: 4 }}>
          <Alert severity="success" sx={{ my: 3, fontSize: "1.1rem", py: 2 }}>
            Thank you for completing this assessment. Someone will be in touch with you shortly.
          </Alert>
        </Box>
      );
    }

    // Now we can safely show OTP verification since we know we have valid content
    if (assessmentStatus === "Not Started" && !otpVerified) {
      return renderOTPVerification();
    }

    if (!isStarted && assessmentStatus === "Not Started") {
      const minutes = Math.floor(assessment?.timeLimit / 60);
      const seconds = assessment?.timeLimit % 60;
      const timeString = `${minutes}:${seconds.toString().padStart(2, "0")}`;

      return (
        <Container maxWidth="sm" sx={{ py: 4, px: { xs: 2, sm: 3 } }}>
          <Paper
            elevation={3}
            sx={{
              overflow: "visible",
              position: "relative",
              borderRadius: 4,
              mt: 8,
              mb: 4,
              pt: 8,
              pb: 6,
              px: { xs: 3, sm: 6 },
              background: "linear-gradient(to bottom, #ffffff, #f9fafc)",
            }}
          >
            <Grid container spacing={4}>
              {/* Left Side */}
              <Grid item xs={12} md={5}>
                <Stack spacing={4} sx={{ height: "100%" }}>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: { xs: "2rem", md: "2.5rem" },
                        fontWeight: 800,
                        color: "#1e293b",
                        mb: 1,
                      }}
                    >
                      Assessment Portal
                    </Typography>
                    <Typography variant="h5" color="primary.main" sx={{ fontWeight: 600 }}>
                      {company?.name || "Claim Return"}
                    </Typography>
                  </Box>

                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="h4" sx={{ color: "#334155", mb: 3, fontWeight: 700 }}>
                      {assessment?.name}
                    </Typography>

                    <Stack spacing={4}>
                      <Box>
                        <Typography variant="h6" color="primary" sx={{ mb: 2, fontWeight: 600 }}>
                          Time Allocation
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            py: 3,
                            borderRadius: 2,
                            bgcolor: "primary.lighter",
                          }}
                        >
                          <Box component="span" sx={{ fontSize: "2rem" }}>
                            ⏱️
                          </Box>
                          <Typography variant="h4" sx={{ fontWeight: 700 }}>
                            {timeString}{" "}
                            <Typography component="span" variant="h6">
                              Minutes
                            </Typography>
                          </Typography>
                        </Box>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Grid>

              {/* Right Side */}
              <Grid
                item
                xs={12}
                md={7}
                sx={{
                  borderLeft: { md: "1px solid #e5e7eb" },
                  pl: { md: 6 },
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    height: "100%",
                    background: "linear-gradient(to bottom, #f8fafc, #fff)",
                    borderRadius: "16px",
                  }}
                >
                  <Stack spacing={4}>
                    <Typography variant="h5" sx={{ fontWeight: 700, color: "#334155" }}>
                      Candidate Information
                    </Typography>

                    <Stack spacing={3}>
                      <Box>
                        <Typography variant="subtitle1" color="text.secondary">
                          Full Name
                        </Typography>
                        <Typography variant="h6">
                          {applicant?.firstName} {applicant?.lastName}
                        </Typography>
                      </Box>

                      <Box>
                        <Typography variant="subtitle1" color="text.secondary">
                          Email
                        </Typography>
                        <Typography variant="h6">{applicant?.email}</Typography>
                      </Box>

                      <Button
                        variant="contained"
                        onClick={() => setIsJobModalOpen(true)}
                        startIcon={<WorkOutlineIcon />}
                        disabled={!job}
                        sx={{
                          width: "fit-content",
                          borderRadius: 2,
                          textTransform: "none",
                          py: 1.5,
                        }}
                      >
                        View Job Details
                      </Button>
                    </Stack>

                    <Box sx={{ mt: "auto" }}>
                      <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={initAssessment}
                        sx={{
                          mt: 4,
                          py: 2,
                          borderRadius: 3,
                          textTransform: "none",
                          fontSize: "1.1rem",
                          fontWeight: 600,
                          background: "linear-gradient(45deg, #0ea5e9, #2563eb)",
                          "&:hover": {
                            background: "linear-gradient(45deg, #0284c7, #1d4ed8)",
                          },
                        }}
                      >
                        Begin Assessment
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Paper>

          <JobDescriptionModal
            open={isJobModalOpen}
            onClose={() => setIsJobModalOpen(false)}
            job={job}
          />
        </Container>
      );
    }

    if (
      (isStarted && assessment?.type === "Excel") ||
      (assessmentStatus === "Started" && assessment?.type === "Excel")
    ) {
      return renderExcelAssessment();
    }

    const currentQuestion = assessment?.questions[currentQuestionIndex];
    const progress = ((currentQuestionIndex + 1) / assessment?.questions.length) * 100;

    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {assessment && (
          <AssessmentTimer
            timeLimit={assessment?.timeLimit}
            startTime={startTime}
            onTimeUp={handleTimeUp}
          />
        )}

        <Stack spacing={4}>
          <Stack spacing={2} sx={{ mb: 4 }}>
            <Box>
              <Typography
                variant="h4"
                component="h1"
                sx={{
                  fontWeight: 700,
                  color: "#1e293b",
                  letterSpacing: "-0.01em",
                  mb: 0.5,
                }}
              >
                {assessment?.name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "text.secondary",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>for</span>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    color: "primary.main",
                    ml: 1,
                  }}
                >
                  {applicant?.firstName} {applicant?.lastName}
                </Typography>
              </Typography>
            </Box>
          </Stack>

          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
              <Typography variant="h6">
                Question {currentQuestionIndex + 1} of {assessment?.questions.length}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {Math.round(progress)}% Complete
              </Typography>
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: 10,
                bgcolor: "rgba(0, 163, 255, 0.15)",
                borderRadius: 5,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  height: "100%",
                  width: `${progress}%`,
                  bgcolor: "#00A3FF",
                  borderRadius: 5,
                  transition: "width 0.4s ease-in-out",
                }}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                mb: 3,
                whiteSpace: "pre-line",
                width: "100%",
                maxWidth: "800px",
                wordBreak: "break-word",
              }}
            >
              {currentQuestion?.text}
            </Typography>

            {currentQuestion?.type === "Multiple Choice" && (
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={answers[currentQuestion?._id] || ""}
                  onChange={(e) => handleAnswerChange(currentQuestion?._id, e.target.value)}
                >
                  {randomizedAnswers[currentQuestion?._id]?.map((answer, index) => (
                    <FormControlLabel
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      value={answer.answerText}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "#00A3FF",
                            },
                            alignSelf: "center", // Add this
                            margin: "0 4px 0 0", // Add spacing between radio and text
                          }}
                        />
                      }
                      label={<Typography variant="body1">{answer.answerText}</Typography>}
                      sx={{
                        width: "100%",
                        margin: "8px 0",
                        padding: "12px 16px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "8px",
                        transition: "all 0.2s ease",
                        display: "flex", // Add this
                        alignItems: "center", // Add this
                        "&:hover": {
                          backgroundColor: "#F5F5F5",
                          borderColor: "#BDBDBD",
                        },
                        ...(answers[currentQuestion?._id] === answer.answerText && {
                          borderColor: "#00A3FF",
                          backgroundColor: "rgba(0, 163, 255, 0.05)",
                          boxShadow: "0 0 0 1px #00A3FF",
                        }),
                        "& .MuiFormControlLabel-label": {
                          // Add this to style the label
                          flex: 1,
                          padding: "0 0 0 4px", // Add left padding to text
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}

            {currentQuestion?.type === "True/False" && (
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  value={answers[currentQuestion?._id] || ""}
                  onChange={(e) => handleAnswerChange(currentQuestion?._id, e.target.value)}
                >
                  {["True", "False"].map((option) => (
                    <FormControlLabel
                      key={option}
                      value={option}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": { color: "#00A3FF" },
                            alignSelf: "center", // Add this
                            margin: "0 4px 0 0", // Add spacing between radio and text
                          }}
                        />
                      }
                      label={<Typography variant="body1">{option}</Typography>}
                      sx={{
                        width: "100%",
                        margin: "8px 0",
                        padding: "12px 16px",
                        border: "1px solid #E0E0E0",
                        borderRadius: "8px",
                        display: "flex", // Add this
                        alignItems: "center", // Add this
                        "&:hover": { backgroundColor: "#F5F5F5" },
                        ...(answers[currentQuestion?._id] === option && {
                          borderColor: "#00A3FF",
                          backgroundColor: "rgba(0, 163, 255, 0.05)",
                          boxShadow: "0 0 0 1px #00A3FF",
                        }),
                        "& .MuiFormControlLabel-label": {
                          // Add this to style the label
                          flex: 1,
                          padding: "0 0 0 4px", // Add left padding to text
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}

            {currentQuestion?.type === "Fill-In" && (
              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  multiline
                  inputComponent={TextareaAutosize}
                  placeholder="Type your answer here..."
                  inputProps={{
                    minRows: 5,
                    style: {
                      resize: "vertical",
                      overflowY: "auto",
                    },
                    onCopy: (e) => {
                      e.preventDefault();
                      return false;
                    },
                    onPaste: (e) => {
                      e.preventDefault();
                      return false;
                    },
                    onCut: (e) => {
                      e.preventDefault();
                      return false;
                    },
                  }}
                  value={answers[currentQuestion?._id] || ""}
                  onChange={(e) => handleAnswerChange(currentQuestion?._id, e.target.value)}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "background.paper",
                    },
                  }}
                />
              </FormControl>
            )}

            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
              <Button
                variant="outlined"
                onClick={handlePreviousQuestion}
                disabled={currentQuestionIndex === 0}
                sx={{
                  borderColor: "#00A3FF",
                  color: "#00A3FF",
                  "&:hover": {
                    borderColor: "#0091EA",
                    backgroundColor: "rgba(0, 163, 255, 0.04)",
                  },
                  "&.Mui-disabled": {
                    borderColor: "#E0E0E0",
                  },
                }}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={!answers[currentQuestion?._id] || isSubmitting}
                sx={{
                  backgroundColor: "#00A3FF",
                  "&:hover": {
                    backgroundColor: "#0091EA",
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#E0E0E0",
                  },
                }}
              >
                {currentQuestionIndex === assessment?.questions.length - 1 ? "Submit" : "Next"}
              </Button>
            </Box>
          </Box>
        </Stack>

        <TimeOutModal
          timeoutDialogOpen={timeoutDialogOpen}
          handleTimeoutClose={handleTimeoutClose}
        />
      </Container>
    );
  };

  return (
    <>
      <ContainerWrapper sx={{ px: "20px", pt: "15px" }} position="relative">
        <Box position="relative">
          <a href="/">
            <StyledImg
              src={`${getCompanyImageUrl(company)}/company/${company?.slug}/banner/${
                company?.bannerUrl
              }`}
              alt=""
              width="100%"
            />
          </a>
        </Box>

        {renderAssessmentContent()}
        <MDBox
          sx={{ py: "15px", px: "20px" }}
          bgColor="#222"
          display="flex"
          justifyContent="center"
        >
          <img
            src="/images/png/logos/powered-by-gignology.png"
            alt="powered by gig-nology"
            width="205.8px"
            height="82.8px"
          />
        </MDBox>
      </ContainerWrapper>
    </>
  );
};

export default RenderAssessmentPage;
