import { useState, useEffect } from "react";
import { Typography, Paper } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { isNaN } from "lodash";

const AssessmentTimer = ({ timeLimit, startTime, onTimeUp }) => {
  const [remainingTime, setRemainingTime] = useState(timeLimit);
  const [localStartTime, setLocalStartTime] = useState(null);

  useEffect(() => {
    // Set initial start time
    if (startTime && !localStartTime) {
      setLocalStartTime(startTime);
    }
  }, [startTime, localStartTime]);

  useEffect(() => {
    if (!localStartTime) return;

    const updateTimer = () => {
      const now = Date.now();
      const elapsed = Math.floor((now - localStartTime) / 1000);
      const updatedTimeLeft = Math.max(timeLimit - elapsed, 0);

      setRemainingTime(updatedTimeLeft);

      if (updatedTimeLeft <= 0) {
        onTimeUp();
      }
    };

    // Initial update
    updateTimer();

    // Set up interval
    const interval = setInterval(updateTimer, 1000);

    // Cleanup
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [localStartTime, timeLimit, onTimeUp]);

  // Format time
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  // Check if times are valid numbers
  if (isNaN(minutes) || isNaN(seconds)) {
    // eslint-disable-next-line no-console
    console.error("Invalid time values:", {
      minutes,
      seconds,
      remainingTime,
      timeLimit,
      startTime,
    });
    return null;
  }

  return (
    <Paper
      elevation={3}
      sx={{
        position: "fixed",
        top: 16,
        right: 16,
        p: 2,
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        gap: 1,
        bgcolor: remainingTime <= 300 ? "#fff3f3" : "white",
        zIndex: 1000,
      }}
    >
      <AccessTimeIcon color={remainingTime <= 300 ? "error" : "primary"} />
      <Typography
        variant="h6"
        sx={{
          fontWeight: "bold",
          color: remainingTime <= 300 ? "error.main" : "text.primary",
        }}
      >
        {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
      </Typography>
    </Paper>
  );
};

export default AssessmentTimer;
