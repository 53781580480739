import axios from "axios";

import { baseAxios } from "config";

const generateAssessmentRubric = async ({ description, skills, type, existingQuestions = [] }) => {
  // try {
  const res = await baseAxios.post(`/assessments/generate-rubric`, {
    description,
    skills,
    type,
    existingQuestions,
  });
  return res.data;
  // } catch (err) {
  //   if (err.message === "Request failed with status code 404") return [];
  //   throw new Error(err.toString());
  // }
};

export default generateAssessmentRubric;
