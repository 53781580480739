import { Alert, Card, Fade, Modal, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDTypography from "components/MDTypography";
import AddANote from "components/AddANote";
import useDropdowns from "hooks/useDropdowns";
import TaskDetailsModal from "layouts/pages/tasks/components/TaskDetailsModal";
import { useState } from "react";
import { useAppContextController } from "context/AppContext";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  wideContainer: {
    width: "50%",
  },
  dropzone: {
    height: 170,
  },
});
const AddANoteModal = ({
  open,
  setOpen,
  toastAlert,
  setToastAlert,
  saveNote,
  saveChanges,
  showType = true,
  allowEmpty,
  defaultValue,
  onCloseAction,
  types = "noteTypes",
  onExtraAction,
  extraActionLabel = "",
  showCurrentNotes,
  currentApplicant,
  isCreateTaskAllowed = false,
}) => {
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const { currentLoggedUser } = useAppContextController();

  const classes = useStyle();
  const handleClose = () => {
    setOpen(false);
    if (onCloseAction) onCloseAction();
  };

  const { dropdowns: noteTypes } = useDropdowns({ entity: types });
  return (
    <>
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
          style={{
            backgroundColor: toastAlert.status === "success" ? "#4caf4f" : "#f44336",
          }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Card
            className={[classes.container, showCurrentNotes ? classes.wideContainer : ""].join(" ")}
          >
            <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }} key="text_addNote">
              Add a Note
            </MDTypography>
            <AddANote
              showType={showType}
              noteTypes={noteTypes}
              saveNote={saveNote}
              saveChanges={saveChanges}
              setOpen={setOpen}
              allowEmpty={allowEmpty}
              defaultValue={defaultValue}
              onCloseAction={onCloseAction}
              onExtraAction={onExtraAction}
              extraActionLabel={extraActionLabel}
              showCurrentNotes={showCurrentNotes}
              applicant={currentApplicant}
              isCreateTaskAllowed={isCreateTaskAllowed}
              setTaskModalOpen={setTaskModalOpen}
            />
          </Card>
        </Fade>
      </Modal>
      {isCreateTaskAllowed && taskModalOpen && (
        <TaskDetailsModal
          mode="create"
          setTaskModalOpen={setTaskModalOpen}
          taskModalOpen={taskModalOpen}
          currentTask={null}
          setToastAlert={setToastAlert}
          currentApplicant={currentApplicant}
          parent="Notes"
        />
      )}
    </>
  );
};

export default AddANoteModal;
