import { baseAxios } from "config";
import createApiParams from "utils/createApiParamsTemp";
import { calculateInterviewOverallFeedback } from "utils/helpers/events";

const fetchInterviewApplicantsForJobStatus = async ({
  page,
  limit,
  order,
  orderBy,
  interviewJobSlug,
  orderByOverride,
  jobApplicantStatus,
  aiInterviewedOnly,
  assessmentOnly,
}) => {
  // If isForJobStatus is true, return interviews only for applicants that have an employmentStage of "Interview"
  // Also, if an applicant with employmentStage of "Interview" doesn't have an interview, return a fake item
  try {
    const { paginationQuery, sortQuery, filterQuery, queryString } = createApiParams({
      page,
      limit,
      order,
      orderBy,
      orderByOverride,
    });

    const interviews = await baseAxios.get(
      `/jobs/slug/${interviewJobSlug}/interviews${queryString}&isForJobStatus=true${
        jobApplicantStatus && jobApplicantStatus !== "all"
          ? `&applicantStatus=${jobApplicantStatus}`
          : ""
      }${aiInterviewedOnly ? "&aiInterviewedOnly=true" : ""}${
        assessmentOnly ? "&assessmentOnly=true" : ""
      }`
    );

    const modifiedData = [];
    interviews.data?.data?.forEach((interview) => {
      if (interview.interview) {
        // This is a real interview
        // Calculate the feedback value
        const interviewFeedback = (interview.feedback ?? []).filter(
          (applFeed) => applFeed.eventUrl === interview.interview.eventUrl
        );
        const { overallRating, agentRating, tooltip } =
          calculateInterviewOverallFeedback(interviewFeedback);

        const interviewFeedbackStatus =
          interviewFeedback && ["None", "Hired", "Pending", "Declined"].includes(overallRating)
            ? overallRating
            : "None";

        modifiedData.push({
          ...interview,
          interview: {
            ...interview.interview,
            status: interviewFeedbackStatus,
            eventDate: interview.event.eventDate,
            tooltip,
            agentRating,
          },
        });
      } else {
        // This is a fake item without interview
        modifiedData.push({ ...interview, interview: null });
      }
    });

    return { ...interviews.data, data: modifiedData };
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchInterviewApplicantsForJobStatus;
