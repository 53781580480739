import { Box, styled, useMediaQuery } from "@mui/material";
import Icon from "@mui/material/Icon";
import { useCallback, useMemo, useState } from "react";
import {
  Assessment,
  AttachFile,
  Badge,
  CalendarMonth,
  ContentPasteSearch,
  Description,
  FolderCopy,
  Info,
  Settings,
  Timeline,
  Try,
} from "@mui/icons-material";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import CustomCard from "components/CustomCard";
import MDBox from "components/MDBox";
import { useAppContextController } from "context/AppContext";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import CopyJobURLModal from "layouts/pages/jobs/components/CopyJobURLModal";
import CompanyJobForm from "layouts/pages/companyjobs/components/CompanyJobInfoPanel/CompanyJobForm";

export const COMPANY_JOBS_PANEL_OPTIONS = [
  {
    title: "Activities",
    icon: <Timeline />,
    color: "error",
    ariaLabel: "timeLine",
    name: "activities",
  },
  {
    title: "Additional Information",
    icon: <Info />,
    color: "violet",
    ariaLabel: "information",
    name: "additionalInfo",
  },
  {
    title: "Job Content",
    icon: <FolderCopy />,
    color: "primary",
    ariaLabel: "job content",
    name: "content",
  },
  {
    title: "Job Interviews",
    icon: <CalendarMonth />,
    color: "success",
    ariaLabel: "job interviews",
    name: "interviews",
  },
  {
    title: "Job Attachments",
    icon: <AttachFile />,
    color: "info",
    ariaLabel: "job attachments",
    name: "attachments",
  },
  {
    title: "Job Status",
    icon: <Badge />,
    color: "success",
    ariaLabel: "job status",
    name: "status",
  },
  {
    title: "Job Settings",
    icon: <Settings />,
    color: "info",
    ariaLabel: "job settings",
    name: "settings",
  },
  {
    title: "Job Notes",
    icon: <Description />,
    color: "warning",
    ariaLabel: "job notes",
    name: "notes",
  },
  {
    title: "Job Assessments",
    icon: <ContentPasteSearch />,
    color: "info",
    ariaLabel: "job assessments",
    name: "assessments",
  },
  {
    title: "AI Settings",
    icon: <Try />,
    color: "violet",
    ariaLabel: "ai settings",
    name: "aiSettings",
  },
  {
    title: "Job Report Data",
    icon: <Assessment />,
    color: "violet",
    ariaLabel: "job report data",
    name: "reportdata",
  },
];

function CompanyJobInfoPanel({
  setRefetchJobs,
  setPreviewContent,
  previewContent,
  selectedPanel = "",
  onPanelSelect = () => null,
}) {
  const { currentJob, company, isCurrentFormDirty, setPanelChangesOpen, setChangePanel } =
    useAppContextController();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [CopyJobURLModalOpen, setCopyJobURLModalOpen] = useState(false);

  const isEdit = !!currentJob?._id;

  const copyUrlHandler = () => setCopyJobURLModalOpen(!CopyJobURLModalOpen);

  const handlePanelChange = useCallback(
    (panel) => {
      if (isCurrentFormDirty) {
        setPanelChangesOpen(true);
        setChangePanel(() => onPanelSelect(panel));
      } else {
        onPanelSelect(panel);
      }
    },
    [isCurrentFormDirty, onPanelSelect, setChangePanel, setPanelChangesOpen]
  );

  const panelOptions = useMemo(() => {
    let result = [...COMPANY_JOBS_PANEL_OPTIONS];
    if (company?.aiInterviewsAvailable !== "Yes") {
      result = result.filter((op) => op.name !== "aiSettings");
    }
    return result.map((op) => ({
      ...op,
      hasShadow: selectedPanel === op.name ? "emd" : "",
      fn: () => handlePanelChange(op.name),
    }));
  }, [company?.aiInterviewsAvailable, handlePanelChange, selectedPanel]);

  return (
    // currentJob && (
    <CustomCard
      icon={
        isEdit ? (
          <CompanyLogoSquare company={company} />
        ) : (
          <StyledImageContainer>
            <Icon fontSize="large">work</Icon>
          </StyledImageContainer>
        )
      }
      iconContainerSx={{ backgroundColor: "transparent", mx: "1.5rem", mt: "-1.5rem" }}
      cardSx={{ width: "100%", height: 850, overflow: "visible !important", p: 0 }}
    >
      <MDBox mt={!isSmallScreen && -9}>
        <CompanyJobForm
          setRefetchJobs={setRefetchJobs}
          copyUrlHandler={copyUrlHandler}
          setPreviewContent={setPreviewContent}
          previewContent={previewContent}
          panelOptions={panelOptions}
          selectedPanel={selectedPanel}
        />
      </MDBox>

      {CopyJobURLModalOpen && (
        <CopyJobURLModal
          id={company?.companyType === "Company" && currentJob?._id ? currentJob?._id : ""}
          jobSlug={
            company?.companyType !== "Company" && currentJob?.jobSlug ? currentJob?.jobSlug : ""
          }
          open={CopyJobURLModalOpen}
          setOpen={setCopyJobURLModalOpen}
        />
      )}
      {isEdit && (
        <ReminderToSaveChangesModal
          open={reminderToSaveChangesModalOpen}
          setOpen={setReminderToSaveChangesModalOpen}
        />
      )}
    </CustomCard>
  );
  // );
}

export default CompanyJobInfoPanel;

const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "4rem",
  height: "4rem",
  background: theme.palette.warning.main,
  color: "white",
  borderRadius: "0.8rem",
}));
