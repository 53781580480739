import React from "react";
import {
  Button,
  TextField,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Stack,
  Radio,
  RadioGroup,
  FormLabel,
  Box,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  TextareaAutosize,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { INITIAL_QUESTION_MULTI_CHOICE_ANSWER, QUESTION_TYPES } from "utils/constants/assessments";
import AssessmentQuestionsMultipleChoiceAnswers from "./AssessmentQuestionsMultipleChoiceAnswers";

const AssessmentQuestionsModal = ({ open, onClose, onSave, question, skills, assessmentType }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Initialize state with question data or initial state
  const [formData, setFormData] = React.useState(() => ({
    text: "",
    type: "Multiple Choice",
    required: false,
    idealAnswer: "",
    answers: [{ ...INITIAL_QUESTION_MULTI_CHOICE_ANSWER }],
    skillsMeasured: [],
    _id: question?._id || uuidv4(),
  }));

  React.useEffect(() => {
    if (open) {
      setFormData(
        question
          ? {
              ...question,
              idealAnswer: question.idealAnswer || "",
              skillsMeasured: question.skillsMeasured || [],
              _id: question._id || uuidv4(),
            }
          : {
              text: "",
              type: QUESTION_TYPES.includes(assessmentType) ? assessmentType : "Multiple Choice",
              required: false,
              answers: [{ ...INITIAL_QUESTION_MULTI_CHOICE_ANSWER }],
              idealAnswer: "",
              skillsMeasured: [],
              _id: uuidv4(),
            }
      );
    }
  }, [open, question, assessmentType]);

  const handleSave = () => {
    const saveData = {
      ...formData,
      _id: formData._id || uuidv4(),
    };
    onSave(saveData);
  };

  const handleAnswersChange = (answers) => {
    setFormData((prev) => ({ ...prev, answers }));
  };

  // Reset answers when question type changes
  const handleTypeChange = (newType) => {
    let initialAnswers;
    let initialIdealAnswer = "";

    switch (newType) {
      case "Multiple Choice":
        initialAnswers = [{ ...INITIAL_QUESTION_MULTI_CHOICE_ANSWER }];
        break;
      case "Fill-In":
        initialAnswers = [];
        break;
      case "True/False":
        initialAnswers = [];
        initialIdealAnswer = "True";
        break;
      default:
        initialAnswers = [];
    }

    setFormData((prev) => ({
      ...prev,
      type: newType,
      answers: initialAnswers,
      idealAnswer: initialIdealAnswer,
      skillsMeasured: [],
    }));
  };

  const renderSkillsField = () => {
    if (formData.type !== "Multiple Choice") {
      return (
        <FormControl
          sx={{
            minWidth: 200,
            "& .Mui-focused": {
              color: "unset !important",
            },
          }}
        >
          <InputLabel
            shrink={false}
            sx={{ position: "static", transform: "none", marginBottom: 1 }}
          >
            Skills Measured
          </InputLabel>
          <Select
            multiple
            value={formData.skillsMeasured}
            onChange={(e) => setFormData({ ...formData, skillsMeasured: e.target.value })}
            displayEmpty
            sx={{
              minWidth: 200,
              "& .MuiSelect-outlined.MuiSelect-select": {
                padding: "0.75rem !important",
              },
              "& .MuiSelect-icon": {
                display: "initial",
              },
            }}
          >
            {skills.map((skill) => (
              <MenuItem key={skill} value={skill}>
                {skill}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return null;
  };

  const renderIdealAnswerField = () => {
    if (formData.type === "Fill-In") {
      return (
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            mt: 0,
            "& .Mui-focused": {
              color: "unset !important",
            },
          }}
        >
          <InputLabel
            shrink={false}
            sx={{ position: "static", transform: "none", marginBottom: 1 }}
          >
            Ideal Answer
          </InputLabel>

          <OutlinedInput
            multiline
            inputComponent={TextareaAutosize}
            inputProps={{
              minRows: 5,
              style: {
                resize: "vertical",
                overflowY: "auto",
              },
            }}
            value={formData.idealAnswer}
            onChange={(e) => setFormData({ ...formData, idealAnswer: e.target.value })}
            size="small"
          />
        </FormControl>
      );
    }

    if (formData.type === "True/False") {
      return (
        <FormControl
          variant="standard"
          sx={{
            minWidth: 200,
            mt: 0,
            "& .Mui-focused": {
              color: "unset !important",
            },
          }}
        >
          <InputLabel
            shrink={false}
            sx={{ position: "static", transform: "none", marginBottom: 1 }}
          >
            Ideal Answer
          </InputLabel>
          <Select
            size="small"
            value={formData.idealAnswer}
            onChange={(e) => setFormData({ ...formData, idealAnswer: e.target.value })}
            displayEmpty
            sx={{
              bgcolor: "background.paper",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "grey.300",
              },
            }}
          >
            <MenuItem value="True">True</MenuItem>
            <MenuItem value="False">False</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return null;
  };

  const getQuestionTypes = () => {
    if (!assessmentType || assessmentType === "Hybrid") {
      return QUESTION_TYPES;
    }
    return [assessmentType];
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{question ? "Edit Question" : "Add Question"}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 2, p: 1 }}>
          <FormControl
            fullWidth
            variant="outlined"
            sx={{
              "& .Mui-focused": {
                color: "unset !important",
              },
            }}
          >
            <InputLabel
              shrink={false}
              sx={{ position: "static", transform: "none", marginBottom: 1 }}
            >
              Question Text
            </InputLabel>

            <OutlinedInput
              multiline
              inputComponent={TextareaAutosize}
              inputProps={{
                minRows: 5,
                style: {
                  resize: "vertical",
                  overflowY: "auto",
                },
              }}
              value={formData.text}
              onChange={(e) => setFormData({ ...formData, text: e.target.value })}
              required
              size="small"
            />
          </FormControl>

          <FormControl
            component="fieldset"
            sx={{
              "& .Mui-focused": {
                color: "unset !important",
              },
            }}
          >
            <FormLabel component="legend">Question Type</FormLabel>
            <RadioGroup
              row={!isMobile}
              value={formData.type}
              onChange={(e) => handleTypeChange(e.target.value)}
              sx={{
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              {getQuestionTypes().map((type) => (
                <FormControlLabel key={type} value={type} control={<Radio />} label={type} />
              ))}
            </RadioGroup>
          </FormControl>

          {formData.type && (
            <>
              {formData.type === "Multiple Choice" && (
                <Box>
                  <AssessmentQuestionsMultipleChoiceAnswers
                    answers={formData.answers}
                    setAnswers={handleAnswersChange}
                    skills={skills}
                  />
                </Box>
              )}

              {renderIdealAnswerField()}
              {renderSkillsField()}
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ flexDirection: isMobile ? "column" : "row", gap: 1, p: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={
            !formData.text ||
            (formData.type === "Fill-In" && !formData.idealAnswer) ||
            (formData.type !== "Multiple Choice" && formData.skillsMeasured.length === 0)
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssessmentQuestionsModal;
