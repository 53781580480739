import { Autocomplete, Grid, Stack, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { getCombinedDate } from "utils/helpers/events";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  AttachFile,
  Description,
  FmdGood,
  FolderCopy,
  Info,
  Settings,
  Timeline,
} from "@mui/icons-material";
import WorkIcon from "@mui/icons-material/Work";
import PollIcon from "@mui/icons-material/Poll";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import SplitPanelSelector from "components/SplitPanelSelector";
import { useAppContextController } from "context/AppContext";
import useDropdowns from "hooks/useDropdowns";
import FormField from "layouts/applications/wizard/components/FormField";
import createEvent from "layouts/pages/events/actions/createEvent";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchSingleEvent from "layouts/pages/events/actions/fetchSingleEvent";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { EVENT_ENTITIES } from "utils/constants/entityOptions";
import generateSlug from "utils/generateSlug";
import { getDirtyFields } from "utils/helpers/formHelpers";
import { eventInfoSchema } from "data/event";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import EventActions from "./EventActions";

const useStyle = makeStyles({
  error: {
    fontSize: "0.75rem",
  },
});

const { EVENT_STATUS, EVENT_TYPES, STATES } = EVENT_ENTITIES;

const EventForm = ({
  setPreviewContent,
  setEventPreview,
  setOpen,
  getVenueField,
  setEventLogo,
  setCopyEventName,
  selectedPanel,
  onPanelSelect,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { dropdowns: eventStatusOptions } = useDropdowns({ entity: EVENT_STATUS });
  const { dropdowns: eventTypeOptions } = useDropdowns({ entity: EVENT_TYPES });
  const { dropdowns: stateCodeOptions } = useDropdowns({ entity: STATES });

  const [searchParams] = useSearchParams();
  const copyEventId = searchParams.get("copyEventId");
  const { enqueueSnackbar } = useSnackbar();
  const { createVenueSlug } = useParams();
  const {
    currentEvent,
    setCurrentEvent,
    venues,
    currentLoggedUser,
    company,
    setCompany,
    setCompanyType,
    userType,
  } = useAppContextController();

  const [eventToCopy, setEventToCopy] = useState(null);

  const secondaryLocationListRef = useRef([]);
  const venueNamesListRef = useRef([]);

  const isEdit = !!currentEvent?._id;
  const resolver = useYupValidationResolver(eventInfoSchema);
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    control,
    watch,
    getValues,
    trigger,
    setError,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm({ mode: "onBlur", defaultValues: currentEvent, resolver });

  const defaultValues = {
    eventName: "",
    eventType: "Event",
    status: "Active",
    venueName: createVenueSlug ? getVenueField(createVenueSlug, "name") : null,
    secondaryLocation: "",
    address: createVenueSlug ? getVenueField(createVenueSlug, "address") : "",
    venueCity: createVenueSlug ? getVenueField(createVenueSlug, "city") : "",
    venueState: createVenueSlug ? getVenueField(createVenueSlug, "state") : "",
    zip: createVenueSlug ? getVenueField(createVenueSlug, "zip") : "",
    eventId: "",
    eventUrl: "",
    makePublicAndSendNotification: "Yes",
    sendConfirmationToSignUps: "No",
    waitListPercentage: "25",
    eventDate: null,
    eventEndTime: null,
    reportTimeTBD: "",
    positionsRequested: "",
    billRate: 0,
    payRate: 0,
    eventLogo: "No",
    jobLogo: "No",
    venueBanner: "No",
    googleMap: "No",
    geoFence: "Yes",
    allowPartners: "No",
    allowEarlyClockin: "No",
    enableClockInReminders: "No",
    allowWaitList: "No",
    allowNonHired: "No",
    reminder48Hour: "No",
    reminder24Hour: "No",
    notifyCallOff: "No",
    description: "",
    venueSlug: createVenueSlug || "",
    positions: [],
    attachments: [],
    applicants: [],
    tags: [],
  };

  const handlePanelChange = (panel) => {
    onPanelSelect(panel);
  };

  const panelOptions = [
    {
      title: "Activities",
      icon: <Timeline />,
      hasShadow: selectedPanel === "activities" ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handlePanelChange("activities"),
      name: "activities",
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: selectedPanel === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handlePanelChange("additionalInfo"),
      name: "additionalInfo",
    },
    {
      title: "Content",
      icon: <FolderCopy />,
      hasShadow: selectedPanel === "content" ? "emd" : "",
      color: "primary",
      ariaLabel: "content",
      fn: () => handlePanelChange("content"),
      name: "content",
    },
    {
      title: "Attachments",
      icon: <AttachFile />,
      hasShadow: selectedPanel === "attachments" ? "emd" : "",
      color: "info",
      ariaLabel: "attachments",
      fn: () => handlePanelChange("attachments"),
      name: "attachments",
    },
    {
      title: "Additional Positions",
      icon: <WorkIcon />,
      hasShadow: selectedPanel === "additionalpositions" ? "emd" : "",
      color: "warning",
      ariaLabel: "additional positions",
      fn: () => handlePanelChange("additionalpositions"),
      name: "additionalpositions",
    },
    {
      title: "Settings",
      icon: <Settings />,
      hasShadow: selectedPanel === "settings" ? "emd" : "",
      color: "info",
      ariaLabel: "settings",
      fn: () => handlePanelChange("settings"),
      name: "settings",
    },
    {
      title: "Notes",
      icon: <Description />,
      hasShadow: selectedPanel === "notes" ? "emd" : "",
      color: "warning",
      ariaLabel: "notes",
      fn: () => handlePanelChange("notes"),
      name: "notes",
    },
    {
      title: "Geofencing",
      icon: <FmdGood />,
      hasShadow: selectedPanel === "geofencing" ? "emd" : "",
      color: "error",
      ariaLabel: "geofencing",
      fn: () => handlePanelChange("geofencing"),
      name: "geofencing",
    },
    {
      title: "Event Report Data",
      icon: <PollIcon />,
      hasShadow: selectedPanel === "reportdata" ? "emd" : "",
      color: "violet",
      ariaLabel: "report data",
      fn: () => handlePanelChange("reportdata"),
      name: "reportdata",
    },
  ];

  const dateToSlugString = (date) =>
    getValues()?.eventDate?.toString()?.split(" ")?.splice(0, 4)?.join("-")?.toLowerCase();

  const onVenueNameChange = () => {
    Object.keys(venues).forEach((key) => {
      const { name, logoUrl, slug, address, city, state, zip, locations } = venues[key];
      if (isEqual(name, getValues("venueName"))) {
        setValue("logoUrl", logoUrl, { shouldDirty: true });
        setValue("venueSlug", slug, { shouldDirty: true });
        setValue("address", address, { shouldDirty: true });
        setValue("venueName", name, { shouldDirty: true });
        setValue("venueCity", city, { shouldDirty: true });
        setValue("venueState", state, { shouldDirty: true });
        setValue("zip", zip, { shouldDirty: true });
        secondaryLocationListRef.current = locations?.map((location) => ({
          ...location,
          label: location.locationName,
        }));
      }
    });
  };

  const createEventMutation = useMutation(createEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, data) => {
      enqueueSnackbar("Event has been created!", { variant: "success" });
      setCurrentEvent({
        ...currentEvent,
        ...data,
        [_.id.name]: _.id?.[_.id.name],
        _id: _.result.insertedId,
      });
      await queryClient.invalidateQueries("events");
      setTimeout(
        () =>
          navigate(
            `/${userType === "Client" ? "clientevents" : "events"}/${
              data.eventUrl
            }/action/eventsinfo`
          ),
        200
      );
    },
  });

  const createEventFormHandler = async (values) => {
    const updatedValues = { ...values };
    if (copyEventId) updatedValues.copyEventId = copyEventId;
    updatedValues.createAgent = currentLoggedUser._id;
    updatedValues.billRate = parseFloat(values.billRate);
    updatedValues.payRate = parseFloat(values.payRate);
    updatedValues.eventEndTime = new Date(updatedValues.eventEndTime).toISOString();
    updatedValues.eventDate = new Date(updatedValues.eventDate).toISOString();

    await createEventMutation.mutateAsync(updatedValues);
  };

  const updateEventMutation = useMutation(updateEvent, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {
      setCurrentEvent({
        ...currentEvent,
        ...data,
        eventId: currentEvent.eventId,
        eventUrl: currentEvent.eventUrl,
      });
      enqueueSnackbar("Event has been updated!", { variant: "success" });
      await queryClient.invalidateQueries("events");
    },
  });

  const editEventFormHandler = async (values) => {
    if (copyEventId) return;
    if (!currentEvent.venueSlug || !currentEvent.eventId) {
      enqueueSnackbar("Cannot Update Event -- No Event ID!", { variant: "error" });
      return;
    }
    const modifiedFields = getDirtyFields(values, dirtyFields);
    if (modifiedFields?.eventEndTime) {
      modifiedFields.eventEndTime = new Date(modifiedFields.eventEndTime).toISOString();
    }
    if (modifiedFields?.eventDate) {
      modifiedFields.eventDate = new Date(modifiedFields.eventDate).toISOString();
    }
    await updateEventMutation.mutateAsync({
      venueSlug: currentEvent.venueSlug,
      eventId: currentEvent._id,
      data: {
        ...modifiedFields,
        billRate: parseFloat(values.billRate),
        payRate: parseFloat(values.payRate),
      },
    });
  };

  // additional positions functions.

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setEventPreview(currentEvent);
    setOpen(true);
  };

  const handlePositionChanges = () => {
    const eventDate = getValues("eventDate");
    const eventEndTime = getValues("eventEndTime");
    if (new Date(eventDate)?.getTime() && new Date(eventEndTime)?.getTime()) {
      const allPositions = getValues("positions");
      const tempPositions = allPositions?.map((pos) => {
        const reportTime = getCombinedDate(eventDate, pos?.reportTime || eventDate);
        const endTime = getCombinedDate(eventEndTime, pos?.endTime || eventEndTime);
        return { ...pos, reportTime, endTime };
      });
      setValue("positions", tempPositions, { shouldDirty: true });
    }
  };

  // initialize rendering first value for edit and create upon load.
  useEffect(() => {
    clearErrors();
    if (!isEdit) {
      const data = copyEventId && eventToCopy ? eventToCopy : defaultValues;
      setCurrentEvent(data);
      reset(data);
    } else if (isEdit && currentEvent) {
      reset(currentEvent);
    }

    if (currentEvent) setEventLogo(getValues("logoUrl"));
  }, [isEdit, copyEventId, eventToCopy]);
  // initialize venues on dropdown field secondary venue and venue name list
  useEffect(() => {
    if (venues) {
      let currentVenuesList = venues;
      if (userType === "Client") {
        // Clients can only select their own venues
        const allowedSlugsIndexed = {};
        currentLoggedUser?.clientOrgs?.forEach((clVen) => {
          if (clVen.slug) allowedSlugsIndexed[clVen.slug] = clVen;
        });
        currentVenuesList = {};
        Object.entries(venues).forEach(([slug, ven]) => {
          if (allowedSlugsIndexed[slug]) currentVenuesList[slug] = ven;
        });
      }
      Object.keys(currentVenuesList).forEach((key) => {
        if (isEqual(currentVenuesList[key].name, getValues("venueName"))) {
          secondaryLocationListRef.current = currentVenuesList[key].locations?.map((location) => ({
            ...location,
            label: location.locationName,
          }));
        }
      });
      venueNamesListRef.current = Object.keys(currentVenuesList)
        .map((key) => currentVenuesList[key].name)
        .sort((venueA, venueB) => (venueA > venueB ? 1 : -1));
    }
  }, [venues, isEdit, currentEvent]);
  // validate and generate event url.
  const eventNameValue = useEffect(() => {
    if (isEdit) return;
    const hasEventUrl =
      getValues("eventName") !== "" &&
      getValues("venueSlug") !== "" &&
      getValues("eventDate") !== "";
    const variables = {
      venueSlug: getValues("venueSlug"),
      title: getValues("eventName"),
      suffix: dateToSlugString(getValues("eventDate")),
      slugField: "eventUrl",
      fetchFunction: fetchEvents,
    };

    if (hasEventUrl) {
      (async () => {
        const { slug } = await generateSlug(variables);
        setValue("eventUrl", slug);
      })();
    } else setValue("eventUrl", "");
  }, [getValues("eventName"), getValues("venueSlug"), getValues("eventDate")]);
  // copy event initialize values
  useEffect(() => {
    const fetchEventToCopy = async (eid) => {
      const event = await fetchSingleEvent({ eventId: eid });
      if (event) {
        setCopyEventName(event.eventName);
        event.eventName = "";
        event.reportTime = "";
        event.eventDate = "";
        event.eventEndTime = "";
        event.eventUrl = "";
        event.eventId = "";
        event.applicants = [];
        setValue("description", event.description);
        delete event._id;
        if (!event.positions) event.positions = [];
        if (!event.attachments) event.attachments = [];
        // Clear secondary venue
        event.secondaryLocation = "";
        event.address = event.venueSlug ? getVenueField(event.venueSlug, "address") : "";
        event.venueCity = event.venueSlug ? getVenueField(event.venueSlug, "city") : "";
        event.venueState = event.venueSlug ? getVenueField(event.venueSlug, "state") : "";
        event.zip = event.venueSlug ? getVenueField(event.venueSlug, "zip") : "";
        setEventToCopy(event);
      }
    };

    if (copyEventId) {
      if (currentEvent) {
        // Copy ID changed, clear the ID of the current event
        const newEvent = {
          ...currentEvent,
          _id: undefined,
        };
        setCurrentEvent(newEvent);
      }
      fetchEventToCopy(copyEventId);
    }
  }, [copyEventId]);

  // reset form value if current event id has change.
  useEffect(() => {
    reset(currentEvent);
    setEventLogo(getValues("logoUrl"));
  }, [currentEvent]);

  return (
    <>
      <form
        onSubmit={
          isEdit ? handleSubmit(editEventFormHandler) : handleSubmit(createEventFormHandler)
        }
      >
        <FlexWrapper justifyContent="end" mb={3}>
          <EventActions
            submitted={isSubmitting}
            hasChanges={isDirty}
            resetChanges={() => reset(currentEvent)}
            copyEventId={copyEventId}
            updateEventMutation={updateEventMutation}
            onPreview={() => openPreviewModal("all")}
          />
        </FlexWrapper>
        <MDBox p={3}>
          <Grid container spacing={3}>
            <MDBox mt={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} desktop={6}>
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="info">
                    Event Information
                  </MDTypography>
                </Grid>
                <Grid item xs={12} desktop={6}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                    <SplitPanelSelector
                      panelOptions={panelOptions}
                      selectedPanel={selectedPanel}
                      isEdit={isEdit}
                    />
                  </Stack>
                </Grid>

                <Grid item sm={12}>
                  <Controller
                    name="eventName"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        disabled={isEmpty(getValues("venueName"))}
                        inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                        type="text"
                        label="Event Name"
                        InputLabelProps={{ shrink: !isEmpty(getValues("eventName")) }}
                        {...field}
                      />
                    )}
                  />
                  {errors?.eventName && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventName.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item sm={6}>
                  <MDBox>
                    <Controller
                      name="status"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={eventStatusOptions || []}
                          autoSelect
                          freeSolo
                          name="status"
                          value={field.value || null}
                          onChange={(e, v) =>
                            field.onChange(v.charAt(0).toUpperCase() + v.slice(1))
                          }
                          onBlur={() => trigger("status")}
                          renderInput={(params) => (
                            <FormField {...params} type="text" label="Event Status" {...field} />
                          )}
                        />
                      )}
                    />
                    {errors?.status && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.status.message}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item sm={6}>
                  <Controller
                    name="eventType"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={eventTypeOptions || []}
                        autoSelect
                        freeSolo
                        name="eventType"
                        value={field.value || null}
                        onChange={(e, v) => field.onChange(v.charAt(0).toUpperCase() + v.slice(1))}
                        onBlur={() => trigger("eventTypes")}
                        renderInput={(params) => (
                          <FormField {...params} type="text" label="Event Type" {...field} />
                        )}
                      />
                    )}
                  />
                  {errors?.eventType && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventType.message}
                    </MDTypography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="eventDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          disabled={isEmpty(getValues("venueName"))}
                          inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                          label="Report Time"
                          value={!field.value ? null : field.value}
                          onChange={(date) => {
                            setValue("eventDate", new Date(date), { shouldDirty: true });
                            setValue(
                              "eventEndTime",
                              new Date(
                                new Date(getValues().eventDate).getTime() + 5 * 60 * 60 * 1000
                              ),
                              { shouldDirty: true }
                            );
                            handlePositionChanges();
                            clearErrors("eventDate");
                          }}
                          renderInput={(params) => <FormField {...params} />}
                        />
                      </LocalizationProvider>
                    )}
                  />
                  {errors?.eventDate && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventDate.message}
                    </MDTypography>
                  )}
                </Grid>

                {currentEvent?.eventDate && currentEvent?.timeZone && (
                  <Grid item xs={12} sm={12} lg={9} xl={6}>
                    <MDTypography fontSize="0.725rem" padding={0} color="secondary">
                      Time at Venue
                    </MDTypography>
                    <MDTypography fontSize="0.675rem" padding={0} fontStyle="italic">
                      {moment(getValues("eventDate") || currentEvent?.eventDate)
                        .tz(currentEvent?.timeZone)
                        .format("hh:mm A") || "-"}
                    </MDTypography>
                    <MDTypography fontSize="0.675rem" padding={0} fontStyle="italic">
                      {currentEvent?.timeZone}
                    </MDTypography>
                  </Grid>
                )}

                <Grid item xs={12} sm={12} lg={9} xl={6}>
                  <Controller
                    name="eventEndTime"
                    control={control}
                    render={({ field }) => {
                      return (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DateTimePicker
                            disabled={isEmpty(getValues("venueName"))}
                            inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                            label="Est. End Time"
                            value={!field.value ? null : field.value}
                            onChange={(date) => {
                              setValue("eventEndTime", new Date(date), { shouldDirty: true });
                              handlePositionChanges();
                            }}
                            renderInput={(params) => <FormField {...params} />}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                  {errors?.eventEndTime && (
                    <MDTypography className={classes.error} color="error">
                      {errors?.eventEndTime.message}
                    </MDTypography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Controller
                    name="reportTimeTBD"
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="TBD/Time Text"
                        InputLabelProps={{ shrink: !isEmpty(getValues("reportTimeTBD")) }}
                        onChange={(e) => {
                          const reportTimeTBDTemp = e.target.value.toUpperCase();
                          setValue("reportTimeTBD", reportTimeTBDTemp, { shouldDirty: true });
                        }}
                        type="text"
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item sm={12}>
                  <MDBox mt={1}>
                    <Controller
                      name="venueName"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          options={venueNamesListRef.current || []}
                          disabled={isEdit}
                          name="venueName"
                          value={field.value || null}
                          onChange={(e) => {
                            field.onChange(e.target.textContent);
                            onVenueNameChange();
                            trigger("address");
                          }}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="Venue Name"
                              //  inputProps={isEdit ? { tabIndex: -1 } : {}}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.venueName && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.venueName.message}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item sm={12}>
                  <MDBox mt={2}>
                    <Controller
                      control={control}
                      name="secondaryLocation"
                      render={({ field }) => (
                        <>
                          <Autocomplete
                            options={
                              !isEmpty(secondaryLocationListRef.current)
                                ? [
                                    { label: "<Original Venue Location>" },
                                    ...secondaryLocationListRef.current,
                                  ]
                                : []
                            }
                            disabled={isEmpty(getValues("venueName"))}
                            name="secondaryLocation"
                            value={field.value ? field.value.locationName || null : null}
                            onChange={(e, v) => {
                              if (v.label === "<Original Venue Location>") {
                                onVenueNameChange();
                                field.onChange(v);
                              } else {
                                setValue("address", v.address, { shouldDirty: true });
                                setValue("venueCity", v.city, { shouldDirty: true });
                                setValue("venueState", v.state, { shouldDirty: true });
                                setValue("zip", v.zip, { shouldDirty: true });
                                field.onChange(v);
                              }
                            }}
                            renderInput={(params) => (
                              <FormField
                                {...params}
                                type="text"
                                label="Secondary Location"
                                //  inputProps={isEmpty(getValues("venueName")) ? { tabIndex: -1 } : {}}
                              />
                            )}
                          />
                        </>
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item sm={12}>
                  <MDBox mt={2}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled
                          inputProps={{ tabIndex: -1 }}
                          type="text"
                          label="Address"
                          InputLabelProps={{ shrink: !isEmpty(getValues("address")) }}
                          {...field}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDBox mt={2}>
                    <Controller
                      name="venueCity"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled
                          inputProps={{ tabIndex: -1 }}
                          type="text"
                          label="City"
                          InputLabelProps={{ shrink: !isEmpty(getValues("venueCity")) }}
                          {...field}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDBox mb={3} mt={2}>
                    <Controller
                      name="venueState"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          disabled
                          freeSolo
                          options={stateCodeOptions || []}
                          defaultValue={null}
                          name="venueState"
                          value={field.value || (stateCodeOptions ? stateCodeOptions?.[0] : "")}
                          onChange={(e, v) => {
                            field.onChange(v.toUpperCase());
                          }}
                          onBlur={() => trigger("state")}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              type="text"
                              label="State"
                              inputProps={{ ...params.inputProps, tabIndex: -1 }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors?.state && (
                      <MDTypography className={classes.error} color="error">
                        {errors?.state.message}
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDBox mt={2}>
                    <Controller
                      name="zip"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled
                          inputProps={{ tabIndex: -1 }}
                          type="text"
                          label="Zip Code"
                          InputLabelProps={{ shrink: !isEmpty(getValues("zip")) }}
                          {...field}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDBox mt={2}>
                    <Controller
                      name="eventId"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled
                          inputProps={{ tabIndex: -1 }}
                          type="text"
                          label="Event ID"
                          InputLabelProps={{ shrink: getValues("eventId") !== "" }}
                          {...field}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MDBox mt={2}>
                    <Controller
                      name="eventUrl"
                      control={control}
                      render={({ field }) => (
                        <FormField
                          disabled={
                            isEdit ||
                            isEmpty(getValues("venueSlug")) ||
                            isEmpty(getValues("eventDate")) ||
                            isEmpty(getValues("eventName"))
                          }
                          inputProps={
                            isEdit ||
                            isEmpty(getValues("venueSlug")) ||
                            isEmpty(getValues("eventDate")) ||
                            isEmpty(getValues("eventName"))
                              ? { tabIndex: -1 }
                              : {}
                          }
                          type="text"
                          label="Event URL"
                          InputLabelProps={{ shrink: !isEmpty(getValues("eventUrl")) }}
                          {...field}
                        />
                      )}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </MDBox>
      </form>
    </>
  );
};

export default EventForm;
