import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Radio,
  Select,
  TextareaAutosize,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export const AssessmentQuestionsMultipleChoiceAnswers = ({ answers, setAnswers, skills = [] }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAddAnswer = () => {
    setAnswers([...answers, { isRecommended: false, answerText: "", skillMeasured: "" }]);
  };

  const handleRemoveAnswer = (index) => {
    setAnswers(answers.filter((_, i) => i !== index));
  };

  const handleRecommendedChange = (index) => {
    setAnswers(
      answers.map((answer, i) => ({
        ...answer,
        isRecommended: i === index,
      }))
    );
  };

  const handleAnswerChange = (index, field, value) => {
    setAnswers(answers.map((answer, i) => (i === index ? { ...answer, [field]: value } : answer)));
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          alignItems: isMobile ? "flex-start" : "center",
          gap: 2,
          mb: 3,
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          Multiple Choice Answers
        </Typography>
        <Button startIcon={<AddIcon />} onClick={handleAddAnswer} variant="contained">
          ADD ANSWER
        </Button>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {answers.map((answer, index) => (
          <Paper
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            variant="outlined"
            sx={{
              p: 2.5,
              bgcolor: "background.paper",
              borderColor: "grey.300",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "stretch" : "center",
                  gap: 2,
                  width: isMobile ? "100%" : "auto",
                  justifyContent: isMobile ? "flex-start" : "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Radio
                    checked={answer.isRecommended}
                    onChange={() => handleRecommendedChange(index)}
                  />
                  <Typography variant="body2" color="text.secondary">
                    Recommended Answer
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <Select
                    value={answer.skillMeasured}
                    onChange={(e) => handleAnswerChange(index, "skillMeasured", e.target.value)}
                    displayEmpty
                    sx={{
                      minWidth: 200,
                      "& .MuiSelect-outlined.MuiSelect-select": {
                        padding: "0.75rem !important",
                      },
                      "& .MuiSelect-icon": {
                        display: "initial",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>Select Skill</em>
                    </MenuItem>
                    {skills.map((skill) => (
                      <MenuItem key={skill} value={skill}>
                        {skill}
                      </MenuItem>
                    ))}
                  </Select>

                  <IconButton
                    onClick={() => handleRemoveAnswer(index)}
                    size="small"
                    sx={{
                      color: "error.main",
                      p: 1,
                      "&:hover": {
                        bgcolor: "error.50",
                      },
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>

              <FormControl fullWidth variant="outlined">
                <OutlinedInput
                  placeholder="Answer Text"
                  multiline
                  inputComponent={TextareaAutosize}
                  inputProps={{
                    minRows: 2,
                    maxRows: 4,
                    style: {
                      resize: "vertical",
                      overflowY: "auto",
                      padding: "8px 14px",
                    },
                  }}
                  value={answer.answerText}
                  onChange={(e) => handleAnswerChange(index, "answerText", e.target.value)}
                  sx={{
                    bgcolor: "background.paper",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "grey.300",
                    },
                  }}
                />
              </FormControl>
            </Box>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default AssessmentQuestionsMultipleChoiceAnswers;
