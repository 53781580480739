import { baseAxios } from "config";

const uploadResume = async (formData) => {
  try {
    return baseAxios.post("/applicants/resume/import", formData);
  } catch (err) {
    console.error(`Error in uploading resumes`, err);
    throw new Error(err.toString());
  }
};

export default uploadResume;
