// import {useState} from "react";
import { PersonAdd } from "@mui/icons-material";
import TableAction from "components/TableAction";
import { useAppContextController } from "context/AppContext";
import updateUser from "layouts/pages/users/actions/updateUser";
import { QueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import GenericMoreButton from "components/GenericMoreButton";
// import BadgeIcon from "@mui/icons-material/Badge";
// import ChatIcon from '@mui/icons-material/Chat';

let favoriteCustomers = [];
const ACTION_NAMES = ["favorite"];
const ACTION_COLORS = {
  info: "info",
  warning: "warning",
  success: "success",
  error: "error",
  secondary: "secondary",
  dark: "dark",
  light: "light",
};

const CustomerTableActions = ({
  slug,
  row,
  setCurrentCompany,
  setGenericModalOpen,
  setModalInfo,
  setNavigateUrl,
}) => {
  const navigate = useNavigate();
  const { currentLoggedUser, setCurrentLoggedUser, userType } = useAppContextController();

  const navigateLocal = setNavigateUrl ?? navigate;

  const queryClient = new QueryClient();

  const updateUserMutation = useMutation(updateUser, {
    onSuccess: async (_, { data }) => {
      await queryClient.invalidateQueries("myVenues");
      localStorage.setItem("myVenues", data.venues);
      setCurrentLoggedUser(data);
    },
  });

  const isFavorite = (companySlug) =>
    currentLoggedUser?.companies &&
    currentLoggedUser?.companies?.length &&
    currentLoggedUser.companies.includes(companySlug);

  const setFavoritesAndRequery = (customerSlug, e) => {
    e.stopPropagation();
    if (favoriteCustomers.includes(customerSlug)) {
      favoriteCustomers = favoriteCustomers.filter((favorite) => favorite !== customerSlug);
    } else favoriteCustomers.push(customerSlug);

    const loggedUser = { ...currentLoggedUser };
    loggedUser.companies = favoriteCustomers;

    updateUserMutation.mutate({ userId: loggedUser.userId, data: loggedUser });
  };

  const actionColorHandler = (actionName, color) => {
    if (actionName === ACTION_NAMES[0]) {
      return isFavorite(slug) ? ACTION_COLORS.warning : ACTION_COLORS.light;
    }

    return color;
  };

  const onClickHandler = (action, e) => {
    e.stopPropagation();
    setCurrentCompany(row);
    navigateLocal(`/customers/${row.slug}/action/${action}`);
  };

  const [actions] = useState([
    {
      color: ACTION_COLORS.info,
      icon: "info",
      clickAction: (e) => onClickHandler("customerinfo", e),
      tooltip: "Customer Info",
    },
    {
      name: "favorite",
      color: ACTION_COLORS.light,
      icon: "staroutline",
      clickAction: (e) => setFavoritesAndRequery(slug, e),
      tooltip: "Add/Remove from My Customers",
    },
  ]);

  useEffect(() => {
    if (currentLoggedUser?.companies) {
      favoriteCustomers = currentLoggedUser?.companies;
    }
  }, [currentLoggedUser?.companies]);

  return (
    <div style={{ textAlign: "end" }}>
      <span key={`table_actions_span_${slug}`}>
        {actions.map((prop) => (
          <TableAction {...prop} color={actionColorHandler(prop.name, prop.color)} />
        ))}
        <GenericMoreButton
          buttons={[
            {
              color: ACTION_COLORS.warning,
              icon: "work",
              clickAction: (e) => onClickHandler("customerjobs", e),
              tooltip: "Jobs",
            },
            {
              color: ACTION_COLORS.warning,
              icon: "person-add",
              clickAction: (e) => onClickHandler("customerapplicants", e),
              tooltip: "Applicants",
            },
            {
              color: ACTION_COLORS.error,
              icon: "people",
              clickAction: (e) => onClickHandler("customeremployees", e),
              tooltip: "Employee Pool",
            },
            {
              color: ACTION_COLORS.success,
              icon: "event-seat",
              clickAction: (e) => onClickHandler("customerinterviews", e),
              tooltip: "Interviews",
            },
            {
              color: ACTION_COLORS.secondary,
              icon: "movie_filter",
              clickAction: (e) => onClickHandler("customermedia", e),
              tooltip: "Customer Media",
            },
          ]}
        />
      </span>
    </div>
  );
};

export default CustomerTableActions;
