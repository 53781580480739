import { baseAxios } from "config";

export const updatePunches = async ({ jobId, shiftSlug, data }) => {
  try {
    const res = await baseAxios.put(`/jobs/${jobId}/shift/${shiftSlug}/updatePunches`, {
      punches: data,
    });
    return res.data;
  } catch (err) {
    console.error(`Error updating`, err);
    throw new Error(err.toString());
  }
};
