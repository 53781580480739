import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Autocomplete, Button, Card, Fade, Grid, Modal, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";

import states from "assets/stateCodes.json";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";

const useStyle = makeStyles({
  container: {
    position: "absolute",
    width: "25%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    paddingTop: "1rem",
  },
  dropzone: {
    height: 170,
  },
  error: {
    fontSize: "0.75rem",
  },
});

const AddCompanyLocationModal = ({
  setValue,
  getValues,
  saveChanges,
  addCompanyLocationModalOpen,
  setAddCompanyLocationModalOpen,
  locations,
  currentCompany,
  currentLocation,
  setCurrentLocation,
  locationIndex,
  setLocationIndex,
  isEdit,
}) => {
  const classes = useStyle();

  const handleClose = () => setAddCompanyLocationModalOpen(false);
  const [locationName, setLocationName] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [zip, setZip] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [radius, setRadius] = useState(null);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [validationError, setValidationError] = useState([]);

  const reset = async () => {
    setCurrentLocation(null);
    setLocationName(null);
    setAddress(currentCompany?.address);
    setCity(currentCompany?.city);
    setState(currentCompany?.state);
    setZip(currentCompany?.zip);
    setLongitude(currentCompany?.longitude);
    setLatitude(currentCompany?.latitude);
    setRadius(currentCompany?.radius);
    setValidationError([]);
    setLocationIndex(null);
  };
  const onCancel = () => {
    reset();
    setAddCompanyLocationModalOpen(false);
  };

  const validation = () => {
    const errorArr = [];
    let newError;
    const zipRegex = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");
    if (locationName === null || locationName === "") {
      newError = "location";
      errorArr.push(newError);
    }
    if (address === null || address === "") {
      newError = "address";
      errorArr.push(newError);
    }
    if (city === null || city === "") {
      newError = "city";
      errorArr.push(newError);
    }
    if (state === null || state === "") {
      newError = "state";
      errorArr.push(newError);
    }
    if (zip === null || zip === "") {
      newError = "zip";
      errorArr.push(newError);
    }
    if (!states.includes(state)) {
      newError = "wrongState";
      errorArr.push(newError);
    }
    if (!zipRegex.test(zip)) {
      newError = "wrongZip";
      errorArr.push(newError);
    }
    setValidationError(errorArr);
    return errorArr;
  };

  const handleLocationSubmit = async (e) => {
    e.preventDefault();
    validation();
    if (validation().length === 0) {
      if (!isEdit) {
        const newLocation = {
          locationName,
          address,
          city,
          state,
          zip,
          longitude,
          latitude,
          radius,
          modifiedDate: new Date(),
        };
        if (locationIndex !== null) {
          /* eslint-disable no-param-reassign */
          locations[locationIndex] = newLocation;
          setValue("locations", locations);
        } else {
          const newLocationArr = locations ? [...locations] : [];
          newLocationArr.push(newLocation);

          setValue("locations", newLocationArr);
        }
        reset();
        setAddCompanyLocationModalOpen(false);
      } else {
        const newLocation = {
          locationName,
          address,
          city,
          state,
          zip,
          longitude,
          latitude,
          radius,
          modifiedDate: new Date(),
        };
        if (locationIndex !== null) {
          /* eslint-disable no-param-reassign */
          locations[locationIndex] = newLocation;
          setValue("locations", locations);
          saveChanges(getValues());
        } else {
          const newLocationArr = locations ? [...locations] : [];
          newLocationArr.push(newLocation);
          setValue("locations", newLocationArr);
          saveChanges(getValues());
        }
        reset();
        setAddCompanyLocationModalOpen(false);
      }
    }
  };

  useEffect(() => {
    if (currentLocation) {
      setLocationName(currentLocation?.locationName);
      setAddress(currentLocation?.address);
      setCity(currentLocation?.city);
      setState(currentLocation?.state);
      setZip(currentLocation?.zip);
      setLongitude(currentLocation?.longitude);
      setLatitude(currentLocation?.latitude);
      setRadius(currentLocation?.radius);
    } else {
      setAddress(currentCompany?.address);
      setCity(currentCompany?.city);
      setState(currentCompany?.state);
      setZip(currentCompany?.zip);
      setLongitude(currentCompany?.longitude);
      setLatitude(currentCompany?.latitude);
      setRadius(currentCompany?.radius);
    }
  }, [currentLocation, currentCompany]);

  useEffect(() => {
    if (validationError.length > 0) {
      validation();
    }
  }, [locationName, address, city, state, zip]);

  return (
    <>
      <Snackbar
        additionalPositionModalOpen={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Modal
        open={addCompanyLocationModalOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={addCompanyLocationModalOpen}>
          <Card className={classes.container}>
            <form onSubmit={handleLocationSubmit}>
              <MDBox display="flex" justifyContent="space-between" py={1} px={3}>
                <MDTypography>Add Locations</MDTypography>
                <MDBox display="flex" alignItems="center">
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="text"
                        startIcon={<CancelIcon />}
                        onClick={onCancel}
                        sx={{ padding: "0.5rem 1rem" }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        endIcon={<SaveIcon />}
                        style={{ color: "white" }}
                        sx={{ padding: "0.5rem 1rem" }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox p={3} mb={3}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <FormField
                      defaultValue={locationName}
                      onChange={(e) => setLocationName(e.target.value)}
                      label="Location Name"
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("location") && "You must set a location name"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={12}>
                    <FormField
                      defaultValue={address}
                      onChange={(e) => setAddress(e.target.value)}
                      label="Address"
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("address") && "You must enter an addres"}
                    </MDTypography>
                  </Grid>

                  <Grid item sm={4}>
                    <FormField
                      defaultValue={city}
                      onChange={(e) => setCity(e.target.value)}
                      label="City"
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("city") && "You must enter a city"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4}>
                    <Autocomplete
                      options={states}
                      autoSelect
                      freeSolo
                      name="state"
                      defaultValue={state}
                      value={state}
                      onChange={(e, v) => {
                        setState(v.toUpperCase());
                      }}
                      renderInput={(params) => <FormField {...params} type="text" label="State" />}
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("state") && "You must insert a state"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("wrongState") && "Insert a valid state"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4}>
                    <FormField
                      defaultValue={zip}
                      onChange={(e) => setZip(e.target.value)}
                      label="Zip"
                    />
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("zip") && "You must insert a zip"}
                    </MDTypography>
                    <MDTypography className={classes.error} color="error">
                      {validationError.includes("wrongZip") && "Insert a valid zip"}
                    </MDTypography>
                  </Grid>
                  <Grid item sm={4}>
                    <FormField
                      defaultValue={longitude}
                      onChange={(e) => setLongitude(e.target.value)}
                      label="Longitude"
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormField
                      defaultValue={latitude}
                      onChange={(e) => setLatitude(e.target.value)}
                      label="Latitude"
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <FormField
                      defaultValue={radius}
                      onChange={(e) => setRadius(e.target.value)}
                      label="Radius"
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </form>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};

export default AddCompanyLocationModal;
