import findIndex from "lodash/findIndex";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PropTypes from "prop-types";

import useCompanyCache from "hooks/useCompanyCache";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Alert, Button, Snackbar } from "@mui/material";
import useGetBackButton from "hooks/useGetBackButton";
import useHandleBackButtonMainPage from "hooks/useHandleBackButtonMainPage";
import useSessionAuth from "hooks/useSessionAuth";
import { isEmpty } from "lodash";
import generateFiltersFromString from "utils/generateFiltersFromString";

import fetchAssessments from "./actions/fetchAssessments";
import AssessmentsContent from "./components/AssessmentsContent";
import AssessmentsFilterCards, { initialCards } from "./components/AssessmentsFilterCards";
import AssessmentsInformation from "./components/AssessmentsInformation";
import AssessmentsQuestions from "./components/AssessmentsQuestions";
import AssessmentsSettings from "./components/AssessmentsSettings";
import AssessmentsTable from "./components/AssessmentsTable";

function Assessments({ mode = "edit" }) {
  const navigate = useNavigate();

  const defaultFilterCardIndex = findIndex(initialCards, (card) => card.default);

  const {
    currentAssessment,
    setCurrentAssessment,
    currentLoggedUser,
    venues,
    setVenues,
    userType,
    isMobile,
    currentApplicant,
    company,
    setCompany,
    setCompanyType,
  } = useAppContextController();

  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });

  const isCompany = company?.companyType === "Company";

  const { assessmentId, action } = useParams();
  const [open, setOpen] = useState(false);
  const [assessmentPreview, setAssessmentPreview] = useState(null);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [searchParams] = useSearchParams();
  const panel = searchParams.get("panel");
  const [filters, setFilters] = useState({});
  const [selectedPanel, setSelectedPanel] = useState(panel ?? "content");
  const [refetchAssessments, setRefetchAssessments] = useState(false);
  const [page, setPage] = useState(1);
  const [notesModalOpen, setNoteModalOpen] = useState(false);

  useEffect(() => {
    if (panel) setSelectedPanel(panel);
  }, [panel]);

  // Handle back button for this screen
  const {
    backListUrl,
    filterParam,
    pageParam,
    subFilterParam,
    subPageParam,
    pageFromUrl,
    filtersFromUrl,
    subPageFromUrl,
    subFiltersFromUrl,
    navigateToListWithBack,
    navigateToScreenWithBack,
  } = useHandleBackButtonMainPage({
    baseUrl: "/assessments",
    screenName: "Assessments",
  });

  // Get back button generated by other screens
  const { backParam, backToTitle, backToUrl, backButton } = useGetBackButton();

  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });

  const renderPanel = (selection) => {
    switch (selection) {
      case "content":
        return <AssessmentsContent />;
      case "questions":
        return <AssessmentsQuestions />;
      case "settings":
        return <AssessmentsSettings />;
      default:
        return null;
    }
  };

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    if (defaultFilterCardIndex >= 0 && isEmpty(filters)) {
      const { filterField, filterValue } = initialCards[defaultFilterCardIndex];
      setFilters({ [filterField]: filterValue });
    }
  }, [filters, assessmentId]);

  useEffect(() => {
    if (filterParam != null) {
      if (filterParam) {
        const newFilters = generateFiltersFromString(filterParam);
        setFilters(newFilters);
      } else {
        setFilters({});
      }
    }
  }, [filterParam]);

  useEffect(() => {
    const getAssessment = async () => {
      const assessments = await fetchAssessments({ filters: { _id: assessmentId } });
      let assessment;
      if (assessments && assessments?.data?.length) {
        assessment = assessments?.data[0];
        setCurrentAssessment(assessment);
        if (action === "assessmentpreview" && assessment) {
          setAssessmentPreview();
          setOpen(true);
        }
      }
    };

    if (assessmentId && !filters._id && action) {
      setFilters({ _id: assessmentId });
    }

    if (action !== actionSelected) {
      setActionSelected(action || "");
    }

    if (assessmentId && (!currentAssessment || currentAssessment._id !== assessmentId)) {
      try {
        getAssessment();
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    } else if (action === "assessmentpreview" && currentAssessment) {
      setAssessmentPreview(currentAssessment);
      setOpen(true);
    }
  }, [assessmentId, action, actionSelected, venues]);

  const setNavigateUrl = (url) => navigate(url);

  const eventsTableFilters = useMemo(
    () => (currentAssessment?._id ? { assessments: currentAssessment._id } : {}),
    [currentAssessment?._id]
  );

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Snackbar
        open={toastAlert.isShow}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
        key="top-center"
      >
        <Alert
          onClose={() => setToastAlert({ isShow: false, message: "", status: "" })}
          // severity={toastAlert.status}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {toastAlert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          {backToUrl ? (
            <Grid item xs={12} sm={12} md={12} pb={4} pt={3}>
              {backButton}
            </Grid>
          ) : (
            <AssessmentsFilterCards
              setFilters={setFilters}
              setPage={setPage}
              initialIndex={defaultFilterCardIndex}
            />
          )}
          <AssessmentsTable
            assessmentId={assessmentId}
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected}
            setNavigateUrl={setNavigateUrl}
            showFiltersList
            title="Assessments"
            page={page}
            setPage={setPage}
            refetchAssessments={refetchAssessments}
            setOpen={setOpen}
            setAssessmentPreview={setAssessmentPreview}
            parent="assessments"
            setToastAlert={setToastAlert}
            parentPage={pageFromUrl}
            parentNavigateWithBack={navigateToListWithBack}
          />
        </Grid>
        {backListUrl && (
          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => {
                navigate(backListUrl);
              }}
            >
              Back to Assessment List
            </Button>
          </Grid>
        )}
        {["assessmentinfo", "assessmentall", ""].includes(actionSelected) &&
          (assessmentId || mode === "create") && (
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <AssessmentsInformation
                    mode={mode}
                    setRefetchAssessments={setRefetchAssessments}
                    setOpen={setOpen}
                    setAssessmentPreview={setAssessmentPreview}
                    setToastAlert={setToastAlert}
                    selectedPanel={selectedPanel}
                    onPanelSelect={setSelectedPanel}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {mode === "edit" && renderPanel(selectedPanel)}
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
    </DashboardLayout>
  );
}

// Setting default values for the props
Assessments.defaultProps = {
  mode: "edit",
};

// Typechecking props
Assessments.propTypes = {
  mode: PropTypes.string,
};
export default Assessments;
