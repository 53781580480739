import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import ContactsPanel from "components/ContactsPanel";
import ConfirmModal from "components/ConfirmDialog/ConfirmModal";
import usePartnerLeaderChecker from "hooks/usePartnerLeaderChecker";
import createPartnerContactUser from "../../actions/createContactUser";
import deletePartnerContactUser from "../../actions/deleteContactUser";

const emptyValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  address: "",
  title: "",
  city: "",
  state: "",
  zip: "",
};

const PartnerContacts = ({ setSelectedPanel, setSelectedContact, refreshPartner }) => {
  const { currentPartner, setCurrentPartner, currentLoggedUser, company } =
    useAppContextController();

  const [contacts, setContacts] = useState([]);
  const [tempContacts, setTempContacts] = useState(contacts);
  const [currentContact, setCurrentContact] = useState(emptyValues);
  const [contactToDeleteId, setContactToDeleteId] = useState(null);

  const { mutateAsync: updatePartnerMutation } = useMutation(updatePartner);
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const handleUpdatePartner = async (values, method = "update", onSuccess) => {
    const createAgent = currentLoggedUser?._id;
    const data = values?.contact
      ? { ...values, ...values?.contact, createAgent }
      : { ...values, createAgent };
    if (!currentPartner?._id)
      enqueueSnackbar("Something went wrong!", { variant: "error", autoHideDuration: 3000 });
    else {
      updatePartnerMutation(
        { id: currentPartner?._id, data },
        {
          onSuccess: async (res) => {
            refreshPartner();
            onSuccess(method, data?.contacts);
            enqueueSnackbar("Partner contacts has been updated.", { variant: "success" });
            await queryClient.invalidateQueries("partners");
          },
        }
      );
    }
  };

  const createContactUserAccountMutation = useMutation(createPartnerContactUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      refreshPartner();
      enqueueSnackbar("Login invite has been sent!", { variant: "success" });
    },
  });

  const deleteContactUserAccountMutation = useMutation(deletePartnerContactUser, {
    onError: (error) =>
      enqueueSnackbar(`Something went wrong! ${error.toString()}`, { variant: "error" }),
    onSuccess: async (_, { data }) => {
      refreshPartner();
      enqueueSnackbar("Contact user deleted!", { variant: "success" });
    },
  });

  const handleClickCreateUser = (contact) => {
    createContactUserAccountMutation.mutateAsync({
      id: currentPartner?._id,
      contactId: contact.id,
    });
  };

  const handleDeleteUser = () => {
    deleteContactUserAccountMutation.mutateAsync({
      id: currentPartner?._id,
      contactId: contactToDeleteId,
    });
  };

  const handleClickDeleteUser = (contact) => {
    setContactToDeleteId(contact.id);
  };

  const handleCloseModal = () => {
    setContactToDeleteId(null);
  };

  // on company change or current company update
  useEffect(() => {
    if (currentPartner?.contacts) {
      setContacts(currentPartner?.contacts);
      setTempContacts(currentPartner?.contacts);
    } else {
      setContacts([]);
      setTempContacts([]);
    }
  }, [currentPartner]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await fetchSingleVenue({
  //       venueId: currentVenue?._id,
  //     });

  //     if (!isEqual(currentVenue?.contacts, res?.contacts)) {
  //       const newData = imageUrlFixer(res, currentVenue, getCompanyImageUrl(company));
  //       setCurrentVenue(newData);
  //     }
  //   };

  //   fetchData();
  // }, [currentVenue?.contacts]);

  const { currentPartnerLeader } = usePartnerLeaderChecker({ currentPartner, setCurrentPartner });

  const setSelectedContactLocal = (index) => {
    if (currentPartnerLeader) {
      // If we have a leader, then increase the index by 1 to account for the added item
      setSelectedContact(index + 1);
    } else {
      setSelectedContact(index);
    }
  };

  return (
    <>
      <ContactsPanel
        name={currentPartner?.name}
        sourceComponent="Partner"
        onUpdate={handleUpdatePartner}
        setSelectedPanel={setSelectedPanel}
        setSelectedContact={setSelectedContactLocal}
        contacts={contacts}
        tempContacts={tempContacts}
        setTempContacts={setTempContacts}
        primaryInfo={null}
        setPrimaryInfo={() => {}}
        currentContact={currentContact}
        setCurrentContact={setCurrentContact}
        currentData={currentPartner}
        allowPrimary={false}
        onClickCreateUser={handleClickCreateUser}
        onClickDeleteUser={handleClickDeleteUser}
      />
      <ConfirmModal
        title="Delete Contact User"
        description="Are you sure you want to delete the user of this contact?"
        negativeBtn={{
          label: "No",
          fn: handleCloseModal,
          isShown: true,
          isOpen: true,
        }}
        positiveBtn={{
          label: "Yes",
          fn: async () => {
            handleDeleteUser();
            handleCloseModal();
          },
          isShown: true,
        }}
        isOpen={contactToDeleteId != null}
        closeFn={handleCloseModal}
      />
    </>
  );
};

export default PartnerContacts;
