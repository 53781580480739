import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useAppContextController } from "context/AppContext";
import { searchGoogleMaps } from "utils/helpers/googleMaps";
import { getCommonBaseImageUrl, getCompanyImageUrl } from "utils/helpers/upload";

const VenueMapsAndPolicies = ({ address, city, state, zip, slug, otherUrls }) => {
  // const searchWaze = () => {
  //   const search = `${state || ""}%${city || ""}%${address || ""}%${zip || ""}`;
  //   window.open(`http://waze.com`)
  // }
  // https://waze.com/ul?q=some%20address
  const { company } = useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);

  return (
    <MDBox>
      <MDBox p={3}>
        <h3 style={{ lineHeight: 1, marginBottom: 5, textAlign: "center" }}>
          Venue Maps & Policies
        </h3>
      </MDBox>
      <Grid container spacing={3} justifyContent="space-around">
        <Grid item sm={12}>
          <MDBox
            component="img"
            sx={{
              height: "7rem",
              display: "block",
              overflow: "hidden",
              width: "100%",
              borderRadius: 5,
              padding: 1,
              objectFit: "contain",
            }}
            src="/images/png/logos/google_maps_icon_square.png"
            onClick={() => searchGoogleMaps({ address, city, state, zip })}
          />
        </Grid>

        <MDBox
          px={2}
          sx={{
            height: 180,
            display: "block",
            overflow: "auto",
          }}
        >
          {otherUrls?.map((file, i) => {
            const index = i;
            return (
              <span key={`div_${file}_${index}`}>
                <Grid
                  item
                  sm={12}
                  key={`grid_${file}_${index}`}
                  onClick={() => window.open(`${IMAGE_SERVER}/${slug}/venues/other/${file}`)}
                >
                  <MDBox display="flex" direction="row" key={`mdbox${file}_${index}`}>
                    {!!getCompanyImageUrl(company) && !!company?.uploadUrl && (
                      <MDBox
                        component="img"
                        sx={{
                          height: 90,
                          display: "block",
                          overflow: "hidden",
                          padding: 1,
                        }}
                        src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                      />
                    )}
                    <MDButton display="flex">{file}</MDButton>
                  </MDBox>
                </Grid>
              </span>
            );
          })}
        </MDBox>
      </Grid>
    </MDBox>
  );
};

export default VenueMapsAndPolicies;
