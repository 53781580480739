import axios from "axios";

import { baseAxios } from "config";

const generateAssessmentSkills = async ({ description, hasName }) => {
  try {
    const res = await baseAxios.post(`/assessments/generate-skills`, { description, hasName });
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default generateAssessmentSkills;
