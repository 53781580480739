import { baseAxios } from "config";

export const evaluateAssessment = async (assessmentId, applicantId, answers, job) => {
  try {
    const res = await baseAxios.put(
      `/outside-public/assessments/${assessmentId}/applicants/${applicantId}/evaluate`,
      {
        jobSlug: job?.jobSlug,
        answers,
        jobName: job?.title,
      }
    );
    return res.data;
  } catch (err) {
    // Extract and preserve the actual error message from the API
    console.log("API Error Response:", err.response?.data);
    if (err.response?.data?.error) {
      // eslint-disable-next-line no-throw-literal
      throw {
        apiError: true,
        message: err.response.data.error,
        originalError: err,
      };
    }
    throw new Error(err?.response?.data?.message?.toString() ?? err.toString());
  }
};
