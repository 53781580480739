import {
  Check,
  Error,
  HourglassBottom,
  Info,
  InfoOutlined,
  Phone,
  WhereToVote,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

const commonColumnAttributes = {
  flex: 1,
  align: "center",
  headerAlign: "center",
};

export const POSITION_COLUMNS = [
  { field: "position", headerName: "Position", flex: 1 },
  { field: "requested", headerName: "Requested", flex: 1 },
  { field: "onPremise", headerName: "On-Premise", flex: 1 },
];

export const LIST_COLUMNS = [
  {
    field: "employee",
    headerName: "Employee",
    flex: 1,
  },
  {
    field: "dateTimeAdded",
    headerName: "Date/Time Added",
    flex: 1,
    valueGetter: ({ value }) =>
      new Date(value)?.toLocaleString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
  },
  {
    field: "rank",
    headerName: "Rank",
    flex: 0.5,
    align: "center",
    headerAlign: "center",
  },
];

export const EVENT_STAFF_DATA_ICONS = {
  "On Roster": <Check color="success" fontSize="medium" />,
  "On Premise": <WhereToVote fontSize="medium" sx={{ color: "#FB8C00" }} />,
  Late: <HourglassBottom color="error" fontSize="medium" />,
  "Mobile User": <Phone fontSize="medium" sx={{ color: "#8935F4" }} />,
};
