import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Icon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import useWindowSize from "hooks/useWindowSize";

const useStyle = makeStyles({
  tableStriped: {
    "& tr": {
      "&:nth-of-type(odd)": { border: 0, background: "rgba(0, 0, 0, 0.04)" },
      "&:last-child td, &:last-child th": { border: 0 },
    },
  },
  tableRow: {
    "& td, & th": { border: 0 },
    cursor: "pointer",
  },
  tableDivider: {
    borderBottom: "3px solid #00000070 !important",
  },
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible!important",
  },
  scroll: {
    overflowY: "hidden!important",
    height: "100%",
  },
  header: {
    width: "60%",
    textAlign: "end",
  },
  tableContainer: {
    boxShadow: "none",
    height: "100% !important",
    overflowY: "scroll !important",
    padding: "0 24px 24px 24px",
    borderRadius: 0,
  },
  tableContainerTopPadding: {
    paddingTop: "24px",
  },
  tableContainerScroll: {
    overflowY: "auto !important",
    height: "100% !important",
    borderRadius: 0,
  },
  pageLoadingStyle: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  },
  autoHeight: {
    height: "auto !important",
  },
});

const DataList = ({
  data = [],
  renderRow,
  onRowClick,
  dateSelected,
  icon,
  iconColor,
  title,
  limit,
  isLoading,
  greybar,
  divider,
  header,
  topButtonBar = null,
  tableHeight,
  height,
  width,
  img,
  keyField,
  scrollY,
  boxPadding,
  cardVariant,
  renderDataView,
  dataViewComponent,
  computedHeight = false,
  headerStyle = () => ({}),
  noLoading = false,
  noIcon = false,
  selected,
  tableHead,
  flexWrap = "nowrap",
  loadMore,
  infiniteData,
  boxShadow,
  additionalRender,
  isFetching,
  ListWrapper,
  listWrapperProps,
  autoHeight,
}) => {
  const classes = useStyle();
  const { width: widthHook, height: heightHook } = useWindowSize();
  const [headerRef, setHeaderRef] = useState(null);
  const [limitedData, setLimitedData] = useState(data);
  const [pageLoading, setPageLoading] = useState(false);
  const tableEl = useRef();

  const isFetchingRef = useRef(isFetching);
  isFetchingRef.current = isFetching;

  const scrollListener = useCallback(() => {
    const bottom = tableEl.current.scrollHeight - tableEl.current.clientHeight;

    if (
      tableEl.current.scrollTop > bottom - 10 &&
      infiniteData?.pagination?.current.page !== infiniteData?.count &&
      !isFetchingRef.current
    ) {
      if (loadMore) loadMore();
    }
  }, [infiniteData?.count, infiniteData?.pagination, loadMore]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef?.addEventListener("scroll", scrollListener);
    if (infiniteData?.pagination?.current.page !== infiniteData?.count) {
      setPageLoading(true);
    }

    return () => {
      tableRef?.removeEventListener("scroll", scrollListener);
      if (infiniteData?.pagination?.current.page !== infiniteData?.count) {
        setPageLoading(false);
      }
    };
  }, [scrollListener]);

  useLayoutEffect(() => {
    const tableRef = tableEl.current;
    tableRef?.addEventListener("scroll", scrollListener);
    return () => {
      tableRef?.removeEventListener("scroll", scrollListener);
    };
  }, [scrollListener]);

  useEffect(() => {
    if (data) {
      if (limit && limit < data.length) {
        setLimitedData(data?.slice(0, limit));
      } else {
        setLimitedData(data);
      }
    }
  }, [data, limit]);

  const [computedTableHeight, setComputedTableHeight] = useState(tableHeight);

  useEffect(() => {
    const OFFSET = 30;
    if (headerRef?.clientHeight && computedHeight) {
      setComputedTableHeight(`calc(100% - ${headerRef?.clientHeight + OFFSET}px)`);
    }
  }, [headerRef, computedHeight, widthHook, heightHook]);

  const Wrapper = ListWrapper ?? Fragment;

  return (
    <Card
      className={`${classes.box} ${autoHeight ? classes.autoHeight : ""}`}
      style={{ height, width, boxShadow: boxShadow ? "none" : "" }}
      variant={cardVariant}
    >
      <FlexWrapper
        ref={(ref) => setHeaderRef(ref)}
        flexWrap={flexWrap}
        alignItems={{ xs: "flex-start", lTablet: "center" }}
        justifyContent={{ xs: "center", lTablet: "space-between" }}
        flexDirection={{ xs: "column", lTablet: "row" }}
        sx={(theme) => ({ ...headerStyle(theme) })}
      >
        <Box alignSelf="start">
          <Grid container>
            {header && !noIcon && (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="4rem"
                height="4rem"
                variant="gradient"
                bgColor={img ? "white" : iconColor}
                color="white"
                borderRadius="xl"
                ml={3}
                mt={icon ? -3 : -2}
              >
                {dateSelected ? (
                  <Stack alignItems="center">
                    <MDTypography variant="h4" color="white">
                      {moment(dateSelected).format("DD")}
                    </MDTypography>
                    <MDTypography variant="h5" color="white">
                      {moment(dateSelected).format("MMM")}
                    </MDTypography>
                  </Stack>
                ) : (
                  <Icon fontSize="large">{icon}</Icon>
                )}
              </MDBox>
            )}
            {dateSelected ? (
              <Stack>
                <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {title || ""}
                </MDTypography>
                <MDTypography variant="body2" sx={{ mb: 1, ml: 2 }}>
                  {moment(dateSelected).format("dddd, MMM Do, YYYY")}
                </MDTypography>
              </Stack>
            ) : (
              <MDTypography variant="h5" color="dark" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {title || ""}
              </MDTypography>
            )}
          </Grid>
        </Box>
        <MDBox
          sx={(theme) => ({
            width: "60%",
            textAlign: "end",
            marginLeft: "10px",
            [theme.breakpoints.down("lTablet")]: {
              width: "100%",
            },
            flexGrow: 1,
          })}
        >
          {header || " "}
        </MDBox>
      </FlexWrapper>

      <MDBox className={`${classes.scroll} ${autoHeight ? classes.autoHeight : ""}`}>
        {/* {new Date().getTime() - startTime.getTime()} */}
        {additionalRender || null}
        {renderDataView ? (
          dataViewComponent
        ) : (
          <TableContainer
            p={boxPadding}
            sx={{ maxHeight: computedTableHeight }}
            className={`${classes.tableContainer} ${scrollY && classes.tableContainerScroll} ${
              !topButtonBar && classes.tableContainerTopPadding
            }`}
            ref={tableEl}
          >
            <Table stickyHeader>
              {topButtonBar && topButtonBar}
              {limitedData?.length ? tableHead : null}
              {isLoading ? (
                <Box display="flex" justifyContent="center" mt={3}>
                  <CircularProgress color="info" />
                </Box>
              ) : (
                <Wrapper {...listWrapperProps}>
                  <TableBody className={greybar ? classes.tableStriped : ""}>
                    {limitedData?.length ? (
                      limitedData.map((row, idx) => (
                        <TableRow
                          key={
                            keyField && row?.[keyField]
                              ? `${row[keyField]}_${idx}`
                              : `${row?.name}_${idx}`
                          }
                          // className={divider && idx < limitedData.length - 1 && classes.tableDivider}
                        >
                          <TableCell
                            sx={{ cursor: "pointer", bgcolor: selected === idx ? "#D1E3FA" : "" }}
                          >
                            {renderRow(row, idx)}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center">No Data</TableCell>
                      </TableRow>
                    )}
                    {pageLoading && limitedData?.length ? (
                      <TableRow className={classes.pageLoadingStyle}>
                        <TableCell align="center">
                          <CircularProgress color="info" />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Wrapper>
              )}
            </Table>
          </TableContainer>
        )}
      </MDBox>
    </Card>
  );
};

DataList.defaultProps = {
  data: [],
  limit: 0,
  dateSelected: null,
  // icon: "description",
  iconColor: "dark",
  greybar: false,
  divider: true,
  renderRow: () => {},
  onRowClick: () => {},
  height: null,
  tableHeight: null,
  width: null,
  img: null,
  scrollY: false,
  boxPadding: 3,
  cardVariant: "outlined",
  ListWrapper: null,
  listWrapperProps: {},
  autoHeight: false,
};

DataList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  renderRow: PropTypes.func,
  limit: PropTypes.number,
  dateSelected: PropTypes.string,
  // icon: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.func, // or PropTypes.func for older versions of prop-types
  // ]),
  iconColor: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  divider: PropTypes.bool,
  greybar: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  img: PropTypes.string,
  scrollY: PropTypes.bool,
  boxPadding: PropTypes.number,
  cardVariant: PropTypes.string,
  ListWrapper: PropTypes.elementType,
  listWrapperProps: PropTypes.object,
  autoHeight: PropTypes.bool,
};

export default DataList;
