import { useLayoutEffect, useState, useEffect } from "react";
// import axios from "axios";
import SignaturePad from "signature_pad";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { baseAxios } from "config";
import { Box, Button, Card } from "@mui/material";
import GenericModal from "components/GenericModal";
import { useAppContextController } from "context/AppContext";
import MDTypography from "components/MDTypography";
import { getCompanyImageUrl } from "utils/helpers/upload";

const SignatureModal = ({
  applicant,
  setSignatureModalOpen,
  signatureModalOpen,
  setValue,
  externalSubmit,
}) => {
  const [signaturePad, setSignaturePad] = useState(null);
  const [emptyCanvas, setEmptyCanvas] = useState(false);
  const [domRendered, setDomRendered] = useState(false);
  const [editMode, setEditMode] = useState(!applicant?.i9Form?.signature);
  const { company, isMobile } = useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (signatureModalOpen) setDomRendered(true);
    else setDomRendered(false);
  }, [signatureModalOpen]);

  useLayoutEffect(() => {
    if (domRendered && signatureModalOpen) {
      const canvasElement = document.getElementById("signatureCanvas");
      if (canvasElement) {
        setSignaturePad(new SignaturePad(canvasElement));
      }
    }
    return () => {
      if (signaturePad) {
        signaturePad.clear();
        signaturePad.off();
      }
    };
  }, [domRendered]);

  useEffect(() => {
    setEditMode(!applicant?.i9Form?.signature);
  }, [signatureModalOpen]);

  const uploadSignatureImgMutation = useMutation(
    async (imgForm) => {
      await baseAxios.post(
        `/outside-protected/upload/applicants/${applicant._id}/${imgForm.type}`,
        imgForm.form
      );
    },
    {
      onSuccess: async (_, data) => {
        setValue("signature", "signatureFile.png", { shouldDirty: true });
        setValue("createdDate", new Date().toISOString(), { shouldDirty: true });
        enqueueSnackbar("Signature has been updated!", { variant: "success" });
        externalSubmit();
        setEditMode(false);
        setSignatureModalOpen(false);
      },
    }
  );

  const handleClear = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
    if (applicant?.i9Form?.signature) {
      setEditMode(true);
    }
  };

  const handleSave = async () => {
    if (applicant?.i9Form?.signature && !editMode) {
      externalSubmit();
      setEditMode(false);
      setSignatureModalOpen(false);
    } else if (signaturePad.isEmpty() && editMode) {
      setEmptyCanvas(true);
    } else if (!applicant?.i9Form?.signature && signaturePad.isEmpty()) {
      setEmptyCanvas(true);
    } else if (!applicant?.i9Form?.signature && !signaturePad.isEmpty()) {
      setEmptyCanvas(false);
      const base64 = signaturePad.toDataURL();

      const dataURL = await (await fetch(base64)).blob();
      const form = new FormData();
      form.append("file", dataURL, "signatureFile.png");

      uploadSignatureImgMutation.mutate({
        form,
        data: { ...applicant, signature: "signatureFile.png" },
        type: "signature",
      });
    } else {
      setEmptyCanvas(false);
      const base64 = signaturePad.toDataURL();

      const dataURL = await (await fetch(base64)).blob();
      const form = new FormData();
      form.append("file", dataURL, "signatureFile.png");

      uploadSignatureImgMutation.mutate({
        form,
        data: { ...applicant, signature: "signatureFile.png" },
        type: "signature",
      });
    }
  };

  const header = (
    <MDTypography
      variant="h5"
      color="dark"
      sx={{ mt: 2, mb: 1, ml: 2 }}
      // key={`note_title_${modalInfo.data.id}`}
    >
      Sign for {applicant?.firstName} {applicant?.lastName}
    </MDTypography>
  );

  const modalBody = (
    <>
      {!editMode && applicant?.i9Form?.signature && (
        <Box mb={2}>
          <MDTypography fontWeight="bold" fontSize="0.875rem">
            E-Signature
          </MDTypography>
          <Card>
            <img
              src={`${IMAGE_SERVER}/applicants/${applicant?._id}/signature/${
                applicant?.i9Form?.signature
              }?${Date.now()}`}
              alt="signature"
              style={{ width: "100%" }}
            />
          </Card>
        </Box>
      )}

      <Box mb={2} display={editMode ? "block" : "none"}>
        <MDTypography fontWeight="bold" fontSize="0.875rem">
          E-Signature
        </MDTypography>
        <Card>
          <canvas
            id="signatureCanvas"
            width={450}
            height={250}
            style={{ border: "1px solid #ccc" }}
          />
          {emptyCanvas && (
            <MDTypography color="error">You can&apos;t save a blank signature</MDTypography>
          )}
        </Card>
      </Box>
    </>
  );

  const buttons = (
    <div style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
      <Button
        variant="contained"
        color="error"
        onClick={() => {
          setSignatureModalOpen(false);
          setEditMode(false);
          setEmptyCanvas(false);
        }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleClear}
        style={{ marginLeft: "8px" }}
      >
        Clear
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginLeft: "8px" }}
      >
        Save
      </Button>
    </div>
  );

  return (
    <>
      <GenericModal
        open={signatureModalOpen}
        setOpen={setSignatureModalOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
        width={isMobile ? "90%" : "40%"}
      />
    </>
  );
};

export default SignatureModal;
