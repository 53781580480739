import { baseAxios } from "config";

export const sentAssessmentInvitation = async ({ assessmentId, applicantId }) => {
  //   try {
  const res = await baseAxios.post(`/assessments/${assessmentId}/applicants/${applicantId}/invite`);
  return res.data;
  //   } catch (err) {
  //     console.error(`Error fetching assessment:`, err);
  //     throw new Error(err.toString());
  //   }
};
