import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const TimeOutModal = ({ timeoutDialogOpen, handleTimeoutClose }) => {
  return (
    <Dialog open={timeoutDialogOpen}>
      <DialogTitle>Time&apos;s Up!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your assessment time has expired. Your answers have been automatically submitted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleTimeoutClose} autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeOutModal;
