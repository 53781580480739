import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import ApplicantMoreButton from "components/ApplicantMoreButton";

const CompanyJobsApplicantsTableActions = ({
  id,
  row,
  type,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setModalInfo,
  onRefresh,
}) => {
  const { company } = useAppContextController();
  const navigate = useNavigate();
  const navigateLocal = setNavigateUrl ?? navigate;
  const route = type === "Staffing" ? "employees" : "applicants";
  const actionroute = type === "Staffing" ? "employee" : "applicant";
  const onClickHandler = (action, e) => {
    if (action === "applicantmail") {
      setCurrentApplicant(row);
      setModalInfo({ type: action, data: row });
      setGenericModalOpen(true);
    } else {
      e.stopPropagation();
      navigateLocal(`/${route}/${id}/action/${action}`);
    }
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler(`${actionroute}info`, e)}
        tooltip={type === "Staffing" ? "Employee Info" : "Applicant Info"}
      />
      <ApplicantMoreButton
        applicant={row}
        onSuccess={onRefresh}
        extraButtons={[
          ...(company?.companyType === "Venue"
            ? [
                {
                  color: "info",
                  icon: "stadium",
                  clickAction: (e) => onClickHandler("applicantvenues", e),
                  tooltip: "Applicant Venues",
                },
              ]
            : [
                {
                  color: "info",
                  icon: "business",
                  clickAction: (e) => onClickHandler("applicantcustomers", e),
                  tooltip: "Applicant Customers",
                },
              ]),
          {
            color: "warning",
            icon: "work",
            clickAction: (e) => onClickHandler("applicantjobs", e),
            tooltip: "Applicant Jobs",
          },
          {
            color: "success",
            icon: "event-seat",
            clickAction: (e) => onClickHandler("applicantevents", e),
            tooltip: "Applicant Event",
          },
          {
            color: "error",
            icon: "mail",
            clickAction: (e) => onClickHandler("applicantmail", e),
            tooltip: "Applicant Mail",
          },
        ]}
      />
    </span>
  );
};

export default CompanyJobsApplicantsTableActions;

CompanyJobsApplicantsTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
CompanyJobsApplicantsTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
