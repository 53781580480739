import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import GenericModal from "components/GenericModal";
import { Block, Cancel, ChevronLeft, ChevronRight, Save as SaveIcon } from "@mui/icons-material";
import { useMutation, useQuery } from "react-query";
import fetchShiftJobPunches from "api/jobs/fetchShiftJobPunches";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import CustomTextField from "components/Form/CustomTextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import { updatePunches } from "api/jobs/updatePunches";
import { useSnackbar } from "notistack";
import { useAppContextController } from "context/AppContext";

const TimeEntryPair = ({ control, punchIndex, fieldArrayName, onRemove }) => (
  <Box sx={{ display: "flex", gap: 1, mb: 1, alignItems: "center" }}>
    <Controller
      name={`${fieldArrayName}.${punchIndex}.timeIn`}
      control={control}
      rules={{
        required: "Time In is required",
        validate: {
          isValid: (value) => moment(value).isValid() || "Invalid time",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label="Time In"
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { py: 1, px: 1 },
                }}
                autoComplete="off"
                showError
                errorMessage={error?.message}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
    <Controller
      name={`${fieldArrayName}.${punchIndex}.timeOut`}
      control={control}
      rules={{
        required: "Time Out is required",
        validate: {
          isValid: (value) => value === null || moment(value).isValid() || "Invalid time",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TimePicker
            label="Time Out"
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
            renderInput={(params) => (
              <CustomTextField
                {...params}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-input": { py: 1, px: 1 },
                }}
                autoComplete="off"
                showError
                errorMessage={error?.message}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
    {onRemove && (
      <IconButton size="small" onClick={onRemove} sx={{ color: "error.main" }}>
        <Cancel fontSize="small" />
      </IconButton>
    )}
  </Box>
);

const PunchesCell = ({ control, applicantId, date, defaultPunches = [] }) => {
  const fieldArrayName = `punches.${applicantId}.${date}`;

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldArrayName,
  });

  return (
    <Box sx={{ width: "100%", p: 1 }} textAlign="center">
      {fields.map((field, index) => (
        <TimeEntryPair
          key={field.id}
          control={control}
          punchIndex={index}
          fieldArrayName={fieldArrayName}
          onRemove={fields.length > 1 ? () => remove(index) : undefined}
        />
      ))}
      <Button
        startIcon={<AddIcon />}
        size="small"
        onClick={() =>
          append({
            timeIn: moment(date).startOf("day"), // Preserve date and set time to 00:00
            timeOut: moment(date).startOf("day"), // Preserve date and set time to 00:00
            status: "Pending",
            _id: null,
          })
        }
        sx={{ mt: 1 }}
      >
        Add Punch
      </Button>
    </Box>
  );
};

const ShiftPreviewAndEditPunchesModal = ({
  shift,
  isShiftEditPunchesModalOpen,
  setShiftEditPunchesModalOpen,
}) => {
  const { currentLoggedUser, currentJob } = useAppContextController();

  const { enqueueSnackbar } = useSnackbar();
  const [defaultValues, setDefaultValues] = useState({
    punches: {},
    formattedPunches: {},
  });

  const {
    data: shiftPunches = [],
    isLoading: isLoadingShiftPunches,
    isFetching: isFetchingPunches,
  } = useQuery(
    ["shiftJobPunches", { jobId: currentJob._id, shiftSlug: shift.slug }],
    () => fetchShiftJobPunches({ jobId: currentJob._id, shiftSlug: shift.slug }),
    { enabled: !!shift?.slug, refetchOnWindowFocus: false }
  );

  const updateShiftPunches = useMutation(updatePunches, {
    onError: () => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    onSuccess: async (_, { data }) => {},
  });

  const [currentStartDate, setCurrentStartDate] = useState(moment(shift.shiftStartDate));
  const defaultSchedule = shift.defaultSchedule || {};
  const shiftRoster = shift.shiftRoster || [];

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (shiftPunches.length > 0) {
      const newDefaultValues = {
        punches: shiftPunches.reduce((acc, punch) => {
          const dateStr = moment(punch.timeIn).format("YYYY-MM-DD");
          if (!acc[punch.applicantId]) {
            acc[punch.applicantId] = {};
          }
          if (!acc[punch.applicantId][dateStr]) {
            acc[punch.applicantId][dateStr] = [];
          }
          acc[punch.applicantId][dateStr].push({
            _id: punch._id,
            timeIn: moment(punch.timeIn),
            timeOut: punch.timeOut ? moment(punch.timeOut) : null,
            status: punch.status,
          });
          return acc;
        }, {}),
        formattedPunches: shiftPunches.reduce((acc, punch) => {
          acc[punch._id] = punch;
          return acc;
        }, {}),
      };
      reset(newDefaultValues);
      setDefaultValues(newDefaultValues);
    }
  }, [shiftPunches]); // Re-run this effect when shiftPunches changes

  // Get scheduled days
  const getScheduledDays = () => {
    return Object.entries(defaultSchedule)
      .filter(([, schedule]) => schedule.start && schedule.end)
      .reduce((acc, [day, schedule]) => {
        acc[day.toLowerCase()] = schedule;
        return acc;
      }, {});
  };

  // Generate dates for the current view (7 days from current start date)
  const getDatesForView = () => {
    const dates = [];
    const viewStart = moment(currentStartDate);
    const shiftEnd = moment(shift.shiftEndDate);
    const scheduledDays = getScheduledDays();

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 7; i++) {
      const currentDate = moment(viewStart).add(i, "days");

      // Break if we've gone past the shift end date
      if (currentDate.isAfter(shiftEnd)) break;

      const dayName = currentDate.format("dddd").toLowerCase();

      if (scheduledDays[dayName]) {
        dates.push({
          date: currentDate,
          schedule: scheduledDays[dayName],
        });
      }
    }
    return dates;
  };

  // Update navigation function
  const navigateDates = (direction) => {
    const newStartDate = moment(currentStartDate).add(direction === "next" ? 7 : -7, "days");

    // Don't go before shift start date
    if (newStartDate.isBefore(shift.shiftStartDate)) {
      setCurrentStartDate(moment(shift.shiftStartDate));
      return;
    }

    // Don't go after shift end date
    if (newStartDate.isAfter(shift.shiftEndDate)) {
      return;
    }

    setCurrentStartDate(newStartDate);
  };

  // State for rows
  const [rows, setRows] = useState([]);

  // Initialize rows
  useEffect(() => {
    const newRows = shiftRoster.map((row) => {
      const rowData = { id: row._id, fullName: row.fullName, applicantId: row._id };
      getDatesForView().forEach(({ date }) => {
        const dateStr = date.format("YYYY-MM-DD");
        const dayName = date.format("dddd").toLowerCase();
        const defaultRoster = defaultSchedule[dayName]?.roster || [];
        rowData[dateStr] = defaultRoster.includes(row._id);
      });
      return rowData;
    });
    setRows(newRows);
  }, [shiftRoster, currentStartDate, defaultSchedule, shiftPunches]);

  // Columns for DataGrid
  const columns = [
    {
      field: "fullName",
      headerName: "Applicants",
      flex: 1,
      renderHeader: () => <Typography variant="h6">Applicants</Typography>,
    },
    ...getDatesForView().map(({ date, schedule }) => ({
      field: date.format("YYYY-MM-DD"),
      headerName: date.format("ddd"),
      flex: 1,
      renderHeader: () => (
        <Box textAlign="center">
          <Typography variant="h6">{date.format("ddd")}</Typography>
          <Typography variant="body2">{date.format("MMM D, yyyy")}</Typography>
          <Typography variant="body2">
            {moment(schedule.start).format("h:mm A")} - {moment(schedule.end).format("h:mm A")}
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        if (!params.value)
          return (
            <Box sx={{ width: "100%", p: 1 }} textAlign="center">
              <Block color="error" />
            </Box>
          );

        const dateStr = date.format("YYYY-MM-DD");
        const { applicantId } = params.row;

        return (
          <PunchesCell
            control={control}
            applicantId={applicantId}
            date={dateStr}
            defaultPunches={defaultValues.punches[applicantId]?.[dateStr] || []}
          />
        );
      },
    })),
  ];

  const onSubmit = async (data) => {
    try {
      // Get all punches in flat array
      const allPunches = Object.entries(data.punches).flatMap(([applicantId, dates]) =>
        Object.entries(dates).flatMap(([date, punches]) =>
          punches.map((punch) => ({
            _id: punch._id,
            applicantId,
            timeIn: punch.timeIn?.toISOString(),
            timeOut: punch.timeOut?.toISOString(),
            status: punch.status || "Pending",
            originalPunch: data.formattedPunches[punch._id],
          }))
        )
      );

      // Filter only modified punches by comparing with original values
      const modifiedPunches = allPunches.filter((punch) => {
        if (!punch._id) return true; // New punch
        const original = punch.originalPunch;
        if (!original) return true;

        // Compare the ISO strings directly since original values are already in ISO format
        return (
          punch.timeIn !== original.timeIn ||
          punch.timeOut !== original.timeOut ||
          punch.status !== original.status
        );
      });

      // Add modifiedBy field and remove temporary fields
      const transformedPunches = modifiedPunches.map(({ originalPunch, ...punch }) => ({
        ...punch,
        modifiedBy: currentLoggedUser?._id, // Add modifiedBy field
      }));

      if (transformedPunches.length > 0) {
        await updateShiftPunches.mutateAsync({
          jobId: currentJob._id,
          shiftSlug: shift.slug,
          data: transformedPunches,
        });
        enqueueSnackbar("Punches updated successfully", { variant: "success" });
        setShiftEditPunchesModalOpen(false);
      } else {
        enqueueSnackbar("No changes to save", { variant: "info" });
      }
    } catch (error) {
      console.error("Error updating punches:", error);
      enqueueSnackbar("Error updating punches", { variant: "error" });
    }
  };

  const header = (
    <Box display="flex" sx={{ mt: 2, mb: 1, ml: 2 }} justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h5" color="dark">
          Timesheet for {shift.shiftName}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            onClick={() => navigateDates("prev")}
            disabled={currentStartDate.isSameOrBefore(moment(shift.shiftStartDate))}
          >
            <ChevronLeft />
          </IconButton>
          <Typography>
            {currentStartDate.format("MMM D")} -{" "}
            {moment
              .min(moment(currentStartDate).add(6, "days"), moment(shift.shiftEndDate))
              .format("MMM D, YYYY")}
          </Typography>
          <IconButton
            onClick={() => navigateDates("next")}
            disabled={moment(currentStartDate).add(7, "days").isAfter(shift.shiftEndDate)}
          >
            <ChevronRight />
          </IconButton>
        </Stack>
      </Stack>
      <IconButton onClick={() => setShiftEditPunchesModalOpen(false)}>
        <Cancel />
      </IconButton>
    </Box>
  );

  const modalBody = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ height: 600, width: "100%" }}>
            {isFetchingPunches ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
                headerHeight={80}
                getRowHeight={() => "auto"}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableSort
                sx={{
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#f5f5f5",
                  },
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    display: "none",
                  },
                  "& .MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon": {
                    display: "none",
                  },
                }}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={9} />
        <Grid item sm={3}>
          <Grid container spacing={3} display="flex">
            <Grid item sm={6}>
              <Button
                type="button"
                variant="contained"
                color="error"
                style={{ color: "error" }}
                fullWidth
                onClick={() => setShiftEditPunchesModalOpen(false)}
                disabled={updateShiftPunches.isLoading}
              >
                Close
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                type="button"
                variant="contained"
                endIcon={<SaveIcon />}
                style={{ color: "white" }}
                sx={{ padding: "0.5rem 1rem" }}
                fullWidth
                disabled={updateShiftPunches.isLoading || isFetchingPunches}
                onClick={async (e) => {
                  e.stopPropagation();
                  await handleSubmit(onSubmit)();
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <GenericModal
      open={isShiftEditPunchesModalOpen}
      setOpen={setShiftEditPunchesModalOpen}
      header={header}
      body={modalBody}
      width="85%"
      overflow="auto"
    />
  );
};

export default ShiftPreviewAndEditPunchesModal;
