/* eslint-disable react-hooks/exhaustive-deps */
import { useAppContextController } from "context/AppContext";
import { useSnackbar } from "notistack";
import { useEffect, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { getDirtyFields } from "utils/helpers/formHelpers";

import { AddCircleRounded, Settings } from "@mui/icons-material";
import { Checkbox, Grid, Icon, IconButton, Stack, Switch, Box } from "@mui/material";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import CustomCard from "components/CustomCard";
import DataList from "components/DataList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PanelActions from "components/PanelActions";
import { customPanelCard, cardStyles as styles } from "layouts/pages/customers/styles";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import NotificationRecipientsModal from "layouts/pages/jobs/components/NotificationRecipientsModal";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import { isEmpty } from "lodash";
import updateAssessment from "../../actions/updateAssessment";

const AssessmentsSettings = () => {
  const { currentAssessment, setCurrentAssessment, isMobile, setCurrentFormDirty, changePanel } =
    useAppContextController();
  const [copyAssessmentURLModalOpen, setCopyAssessmentURLModalOpen] = useState(false);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [notificationRecipientsModalOpen, setNotificationRecipientsModalOpen] = useState(false);
  const [usersNames, setUsersNames] = useState();
  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((user) => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
          }))
      );
    }
  }, [users]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    watch,
    formState: { dirtyFields },
  } = useForm();

  const isDirty = !isEmpty(dirtyFields);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
  }, []);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateAssessmentMutation } = useMutation(updateAssessment);

  const handleUpdateAssessment = (updatedValues, silent = false) => {
    updateAssessmentMutation(
      { _id: currentAssessment?._id, data: updatedValues },
      {
        onSuccess: async (res) => {
          queryClient.invalidateQueries("assessments_table");
          queryClient.invalidateQueries("assessments");
          queryClient.invalidateQueries("useBadgeValues");
          setCurrentAssessment({ ...currentAssessment, ...updatedValues });
          if (!silent) {
            enqueueSnackbar("Assessment has been updated.", { variant: "success" });
          }
        },
        onError: (err) => {
          if (!silent) {
            console.error(err);
            enqueueSnackbar("Failed to save changes", { variant: "error" });
          }
        },
      }
    );
  };

  const onSubmit = (data) => {
    const temp = getDirtyFields(data, dirtyFields);
    handleUpdateAssessment({ ...temp });
  };

  const handleCancelChanges = () => reset(currentAssessment);

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  const removeRecipient = (idx) => {
    const temporaryRecipients = getValues()?.notificationRecipients;
    temporaryRecipients.splice(idx, 1);
    setValue("notificationRecipients", temporaryRecipients);
    setReminderToSaveChangesModalOpen(true);
  };

  const addNotificationRecipients = () => setNotificationRecipientsModalOpen(true);

  const notificationRecipientsRenderRow = (row, idx) => {
    return (
      <>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          onClick={() => {
            setNotificationRecipientsModalOpen(true);
          }}
        >
          <Grid container>
            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>{row.fullName}</MDTypography>
            </Grid>

            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>E-Mail: {row.email}</MDTypography>
            </Grid>

            <Grid item sm={1}>
              <MDBox textAlign="right">
                <Icon
                  color="error"
                  fontSize="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeRecipient(idx);
                  }}
                >
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    reset(currentAssessment);
  }, [currentAssessment]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    return () => {
      setCurrentFormDirty(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleOption = (array, fieldName, option) => {
    if (array == null) {
      setValue(fieldName, [option], { shouldDirty: true });
    } else if ((array ?? []).includes(option)) {
      setValue(
        fieldName,
        array.filter((e) => {
          return e !== option;
        }),
        { shouldDirty: true }
      );
    } else {
      setValue(fieldName, [...array, option], { shouldDirty: true });
    }
  };

  // Add debounced autosave
  useEffect(() => {
    if (!isDirty || !currentAssessment?._id) return;

    const timer = setTimeout(() => {
      const data = getValues();
      const temp = getDirtyFields(data, dirtyFields);
      handleUpdateAssessment({ ...temp }, true); // Silent - no feedback
    }, 3000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [
    currentAssessment?._id,
    watch("audience"),
    watch("notifyJobRecipients"),
    watch("notifyCandidate"),
    watch("showResultsToCandidate"),
    watch("notificationRecipients"),
    isDirty,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Settings color="white" />}
        cardTitle={
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <span>Settings: {currentAssessment?.name}</span>
          </Box>
        }
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        {...customPanelCard(isMobile, "info")}
      >
        <MDBox sx={{ ...styles.container, height: 614 }}>
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography component="label" variant="body2">
                  Audience
                </MDTypography>
              </MDBox>
              <Controller
                name="audience"
                control={control}
                render={({ field }) => {
                  const checkboxMapped = {};
                  (field.value ?? []).forEach((element) => {
                    checkboxMapped[element] = true;
                  });
                  return (
                    <Grid container direction="column">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                          checked={!!checkboxMapped.New}
                          onChange={(e) => toggleOption(field.value, "audience", "New")}
                        />
                        <MDTypography variant="text1">New</MDTypography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                          checked={!!checkboxMapped.ATC}
                          onChange={(e) => toggleOption(field.value, "audience", "ATC")}
                        />
                        <MDTypography variant="text1">ATC</MDTypography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                          checked={!!checkboxMapped.Screened}
                          onChange={(e) => toggleOption(field.value, "audience", "Screened")}
                        />
                        <MDTypography variant="text1">Screened</MDTypography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                          checked={!!checkboxMapped["Pre-Hire"]}
                          onChange={(e) => toggleOption(field.value, "audience", "Pre-Hire")}
                        />
                        <MDTypography variant="text1">Pre-Hire</MDTypography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                          checked={!!checkboxMapped.Declined}
                          onChange={(e) => toggleOption(field.value, "audience", "Declined")}
                        />
                        <MDTypography variant="text1">Declined</MDTypography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Checkbox
                          checked={!!checkboxMapped.Employee}
                          onChange={(e) => toggleOption(field.value, "audience", "Employee")}
                        />
                        <MDTypography variant="text1">Employee</MDTypography>
                      </Stack>
                    </Grid>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography variant="body2">Notify Job Recipients</MDTypography>
                    <Controller
                      name="notifyJobRecipients"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value === "Yes"}
                          disabled={!watch("jobSlug")}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography variant="body2">Notify Candiate</MDTypography>
                    <Controller
                      name="notifyCandidate"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value === "Yes"}
                          onChange={(e) => {
                            if (!e.target.checked) {
                              setValue("showResultsToCandidate", "No", { shouldDirty: true });
                              field.onChange("No");
                              const data = getValues();
                              const temp = getDirtyFields(data, dirtyFields);
                              handleUpdateAssessment({ ...temp }, true);
                            } else {
                              field.onChange("Yes");
                            }
                          }}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <MDTypography variant="body2">Show Results To Candidate</MDTypography>
                    <Controller
                      name="showResultsToCandidate"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value === "Yes"}
                          disabled={watch("notifyCandidate") !== "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={1} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography component="label" variant="body2">
                  Extra Notification Recipients
                </MDTypography>
                <IconButton color="info" onClick={addNotificationRecipients}>
                  <AddCircleRounded />
                </IconButton>
              </MDBox>
              <Controller
                name="notificationRecipients"
                control={control}
                render={({ field }) => (
                  <DataList
                    renderRow={notificationRecipientsRenderRow}
                    data={getValues().notificationRecipients === undefined ? [] : field.value}
                    greybar
                    divider
                    scrollY
                    tableHeight={170}
                    height={200}
                    isLoading={false}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      </CustomCard>
      <NotificationRecipientsModal
        open={notificationRecipientsModalOpen}
        setOpen={setNotificationRecipientsModalOpen}
        NotificationRecipientsArray={getValues().notificationRecipients || []}
        setValue={setValue}
        setReminderToSaveChangesModalOpen={setReminderToSaveChangesModalOpen}
      />
      <ReminderToSaveChangesModal
        open={reminderToSaveChangesModalOpen}
        setOpen={setReminderToSaveChangesModalOpen}
      />
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default AssessmentsSettings;
