/**
 * Utility functions for formatting data
 */

/**
 * Formats bytes into a human-readable string with appropriate unit (KB, MB, GB, etc.)
 * @param {number} bytes - The number of bytes to format
 * @param {number} decimals - The number of decimal places to include (default: 2)
 * @returns {string} Formatted string with unit (e.g., "5.25 MB")
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Processes file rejection errors and returns appropriate error messages
 * @param {Array} rejectedFiles - Array of rejected files from react-dropzone
 * @param {string} allowedFileTypes - String of allowed file types to display in error message
 * @param {number} maxFileSize - Maximum file size in bytes
 * @param {string} [fileTypeName=""] - Optional name of the file type for more specific error messages
 * @returns {string} Formatted error message with all relevant errors
 */
export const getFileRejectionErrors = (
  rejectedFiles,
  allowedFileTypes,
  maxFileSize,
  fileTypeName = ""
) => {
  if (!rejectedFiles || rejectedFiles.length === 0) {
    return "";
  }

  const rejectionErrors = rejectedFiles[0].errors || [];
  const errorMessages = [];

  // Check for specific error types
  rejectionErrors.forEach((err) => {
    if (err.code === "file-too-large") {
      const maxSizeFormatted = formatBytes(maxFileSize);
      errorMessages.push(
        `File is too large. Maximum size allowed for ${
          fileTypeName || "this type"
        } is ${maxSizeFormatted}.`
      );
    } else if (err.code === "file-invalid-type") {
      errorMessages.push(`Invalid file type. Please upload only: ${allowedFileTypes}.`);
    } else if (err.code === "too-many-files") {
      errorMessages.push("Only one file can be uploaded at a time.");
    }
  });

  // If no specific errors were identified, use default message
  if (errorMessages.length === 0) {
    errorMessages.push(
      `Unknown error occurred. Please try again with a valid file type: ${allowedFileTypes}`
    );
  }

  // Join all error messages with line breaks
  return errorMessages.join("\n");
};
