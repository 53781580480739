import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { Icon, IconButton, TextField, Tooltip } from "@mui/material";
import useStyles from "layouts/pages/events/components/InterviewEditor/Attachments/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { baseAxios } from "config";
import ImagePreviewModal from "layouts/pages/events/components/InterviewEditor/ImagePreviewModal";
import updateEvent from "layouts/pages/events/actions/updateEvent";
import { useAppContextController } from "context/AppContext";
import { getCommonBaseImageUrl, getCompanyImageUrl } from "utils/helpers/upload";

const Attachments = ({
  currentInterview,
  setCurrentInterview,
  setAttachmentOpen,
  fileLocation = "attachments",
}) => {
  const { company } = useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);
  const styles = useStyles();
  const [files, setFiles] = useState([]);
  const [deletePolicyErr, setDeletePolicyErr] = useState(false);
  const [toastAlert, setToastAlert] = useState({
    isShow: false,
    message: "",
  });
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const queryClient = useQueryClient();

  const renameFileMutation = useMutation(
    async ({ rename, original }) => {
      const url = new URL(
        `/upload/rename/${currentInterview?.companySlug}/events/${currentInterview?.eventUrl}`
      );
      url.searchParams.append("rename", rename);
      url.searchParams.append("original", original);
      await baseAxios.patch(url.toString());
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: () => {
        setToastAlert({ isShow: true, message: "Successfully renamed", status: "success" });
      },
    }
  );
  const selectLogoMutation = useMutation(
    async ({ logo }) => {
      await updateEvent({
        eventId: currentInterview._id,
        data: { eventLogo: logo },
      });
    },
    {
      onError: (error) =>
        setToastAlert({ isShow: true, message: error.toString(), status: "error" }),
      onSuccess: (_, data) => {
        setCurrentInterview({
          ...currentInterview,
          eventLogo: data.logo,
        });
        setToastAlert({
          isShow: true,
          message: "Successfully selected a new logo",
          status: "success",
        });
      },
    }
  );
  const selectLogo = async (e, idx) => {
    await selectLogoMutation.mutateAsync({
      logo: files[idx].name,
    });
  };

  const addNew = (e) => {
    setAttachmentOpen(true);
  };

  const deleteFileHandler = async (e, idx) => {
    const attachments = [...files];
    let delFile;

    if (idx !== -1) {
      [delFile] = attachments.splice(idx, 1);
    }

    if (!deletePolicyErr) {
      setFiles(attachments);
      if (fileLocation === "attachments") {
        await updateEvent({
          eventId: currentInterview._id,
          data: { attachments: attachments.map((img) => img.name) },
        });
      } else {
        await updateEvent({
          eventId: currentInterview._id,
          data: { eventLogos: attachments.map((img) => img.name) },
        });
      }
    }
  };

  const editFileNameHandler = (e, idx) => {
    const newFiles = [...files];

    // get the image extension
    const imgExt = newFiles[idx].name?.substring(newFiles[idx].name.lastIndexOf("."));

    // check if edited
    const removeExt = newFiles[idx].name?.substring(0, newFiles[idx].name.lastIndexOf("."));
    newFiles[idx].isEdited = removeExt !== e.target.value;

    // set the edited name value
    newFiles[idx].editedName = newFiles[idx].isEdited
      ? e.target.value + imgExt
      : newFiles[idx].name;

    // set the nAttachmenttate
    setFiles(newFiles);
  };

  const confirmFileNameChanges = async (e, idx) => {
    await renameFileMutation.mutateAsync({
      original: files[idx].name,
      rename: files[idx].editedName,
    });

    const newFiles = [...files];
    newFiles[idx] = { ...newFiles[idx], name: newFiles[idx].editedName, isEdited: false };
    setFiles(newFiles);
    if (fileLocation === "attachnments") {
      await updateEvent.mutateAsync({
        eventId: currentInterview._id,
        data: { attachments: newFiles.map((img) => img.name) },
      });
    } else {
      await updateEvent.mutateAsync({
        eventId: currentInterview._id,
        data: { eventLogos: newFiles.map((img) => img.name) },
      });
    }
  };

  const cancelFileNameChanges = useCallback(
    (e, idx) => {
      const newFiles = [...files];
      const originalName = newFiles[idx].name;
      newFiles[idx] = { ...newFiles[idx], editedName: originalName, isEdited: false };
      setFiles(newFiles);
    },
    [files]
  );
  const getLastNode = (path) => {
    const pathArr = path.split("/");
    if (pathArr?.length) return pathArr[pathArr.length - 1];
    return path;
  };

  useEffect(() => {
    if (fileLocation === "logos" && currentInterview?.eventLogos?.length) {
      setFiles(
        currentInterview?.eventLogos.map((img) => ({
          name: img?.startsWith("http") ? getLastNode(img) : img,
          editedName: img?.startsWith("http") ? getLastNode(img) : img,
          isEdited: false,
        }))
      );
    } else if (fileLocation === "attachments" && currentInterview?.attachments?.length) {
      setFiles(
        currentInterview?.attachments?.map((img) => ({
          name: img?.startsWith("http") ? getLastNode(img) : img,
          editedName: img?.startsWith("http") ? getLastNode(img) : img,
          isEdited: false,
        }))
      );
    }
  }, [
    currentInterview?.attachments,
    currentInterview?.eventLogo,
    currentInterview?.eventLogos,
    fileLocation,
  ]);

  return (
    <MDBox className={styles.otherSection}>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={10.5}>
          <MDBox className={styles.section_images_wapper}>
            {fileLocation === "logos" &&
              files.map((image, idx) => (
                <MDBox className={styles.section_image_container} key={image.name}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid>
                      <MDBox
                        onClick={(e) => {
                          selectLogo(e, idx);
                        }}
                        style={{
                          border: image?.name === currentInterview.eventLogo && "solid 4px #1A73E8",
                          padding: 4,
                        }}
                      >
                        <IconButton
                          color="info"
                          className={styles.maps_section_image_delete}
                          onClick={(e) => deleteFileHandler(e, idx)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>

                        {IMAGE_SERVER && (
                          <img
                            src={
                              image?.name?.startsWith("http")
                                ? image.name
                                : `${IMAGE_SERVER}/company/${currentInterview?.companySlug}/events/${currentInterview?.eventUrl}/${image?.name}`
                            }
                            alt="previews"
                            height={100}
                          />
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid>
                    <TextField
                      // defaultValue={image?.name?.substring(0, image.name.lastIndexOf("."))}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      value={files[idx].editedName?.substring(0, image.editedName.lastIndexOf("."))}
                      onInput={(e) => editFileNameHandler(e, idx)}
                    />

                    {image.isEdited && (
                      <>
                        <Tooltip title="Confirm" placement="bottom">
                          <CheckIcon
                            color="success"
                            onClick={(e) => confirmFileNameChanges(e, idx)}
                            fontSize="large"
                            sx={{
                              "&": { transition: "0.1s" },
                              "&:hover": { background: "#D3D3D3" },
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Cancel" placement="bottom">
                          <CloseIcon
                            color="error"
                            onClick={(e) => cancelFileNameChanges(e, idx)}
                            fontSize="large"
                            sx={{
                              "&": { transition: "0.1s" },
                              "&:hover": { background: "#D3D3D3" },
                            }}
                          />
                        </Tooltip>
                      </>
                    )}
                  </Grid>
                </MDBox>
              ))}
            {fileLocation === "attachments" &&
              files.map((file, idx) => {
                const fileType = file?.name?.split(".").pop();
                return (
                  <MDBox className={styles.section_image_container} key={file.name}>
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Grid>
                        <MDBox
                          onClick={() => {
                            if (IMAGE_SERVER && file?.name)
                              window.open(
                                `${IMAGE_SERVER}/company/${currentInterview?.companySlug}/events/${currentInterview?.eventUrl}/${file?.name}`
                              );
                          }}
                        >
                          <IconButton
                            color="info"
                            className={styles.maps_section_image_delete}
                            onClick={(e) => deleteFileHandler(e, idx)}
                          >
                            <Icon fontSize="small">cancel</Icon>
                          </IconButton>

                          {IMAGE_SERVER &&
                            ["jpeg", "jpg", "png", "gif", "heic", "jfif", "svg"].includes(
                              fileType?.toLowerCase()
                            ) && (
                              <img
                                src={`${IMAGE_SERVER}/company/${currentInterview?.companySlug}/events/${currentInterview?.eventUrl}/${file?.name}`}
                                alt="previews"
                                // className={styles.section_image}
                                height={100}
                              />
                            )}
                          {!!getCompanyImageUrl(company) &&
                            !!company?.uploadUrl &&
                            fileType?.toLowerCase() === "pdf" && (
                              <img
                                src={`${getCommonBaseImageUrl(company)}/static/pdf-icon.png`}
                                alt="PDF Preview"
                                width={100}
                                height={100}
                              />
                            )}
                          {!!getCompanyImageUrl(company) &&
                            !!company?.uploadUrl &&
                            fileType?.toLowerCase() === "docx" && (
                              <img
                                src={`${getCommonBaseImageUrl(company)}/static/word-icon.png`}
                                alt="preview"
                                width={100}
                                height={100}
                              />
                            )}
                        </MDBox>
                      </Grid>

                      <Grid>
                        <TextField
                          // defaultValue={file?.name?.substring(0, file.name.lastIndexOf("."))}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                          value={files[idx].editedName?.substring(
                            0,
                            file.editedName.lastIndexOf(".")
                          )}
                          onInput={(e) => editFileNameHandler(e, idx)}
                        />

                        {file.isEdited && (
                          <>
                            <Tooltip title="Confirm" placement="bottom">
                              <CheckIcon
                                color="success"
                                onClick={(e) => confirmFileNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Cancel" placement="bottom">
                              <CloseIcon
                                color="error"
                                onClick={(e) => cancelFileNameChanges(e, idx)}
                                fontSize="large"
                                sx={{
                                  "&": { transition: "0.1s" },
                                  "&:hover": { background: "#D3D3D3" },
                                }}
                              />
                            </Tooltip>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </MDBox>
                );
              })}
          </MDBox>
        </Grid>
      </Grid>
      <ImagePreviewModal
        imagePreviewOpen={imagePreviewOpen}
        setImagePreviewOpen={setImagePreviewOpen}
        currentInterview={currentInterview}
        previewFile={previewFile}
      />
    </MDBox>
  );
};

export default Attachments;
