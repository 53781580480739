import { AutoAwesome } from "@mui/icons-material";
import { Button, Chip, FormHelperText, Grid } from "@mui/material";
import CircularLoader from "components/CircularLoader";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/applications/wizard/components/FormField";
import { useState } from "react";

const FreeTagList = ({
  setValue,
  value = [],
  setError,
  clearErrors,
  currentComponent,
  outsideMode = "",
  label = "Tags",
  errorMessage,
  isLoadingGenerateSkills,
  onGenerateSkills,
  placeholder,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleAddTag = (v) => {
    clearErrors("invalidTag");
    if (!value?.includes(v)) {
      setValue(props?.name, [...value, v], { shouldDirty: true });
      setInputValue("");
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setValue(
      props?.name,
      value?.filter((chip) => chip !== chipToDelete),
      { shouldDirty: true }
    );
  };

  const onKeyPressHandler = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddTag(e.target.value);
    }
  };

  return (
    <div>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <FormField
            label={label}
            type="text"
            inputProps={{ value: inputValue }}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyPress={onKeyPressHandler}
            placeholder={placeholder}
          />
        </Grid>
        {onGenerateSkills != null && (
          <Grid container item flexBasis="auto" flexGrow={0} alignItems="center" width="auto">
            <Button disableRipple disabled={isLoadingGenerateSkills} onClick={onGenerateSkills}>
              {isLoadingGenerateSkills && (
                <CircularLoader size="2rem" color="info" hasBackdrop={false} isLoading />
              )}
              <Grid container columnGap={1} alignItems="center">
                <AutoAwesome sx={{ color: "#8935f4" }} />
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="medium"
                  color="violet"
                  sx={{ fontSize: "0.8rem", color: "#8935f4", cursor: "inherit" }}
                >
                  Generate Skills
                </MDTypography>
              </Grid>
            </Button>
          </Grid>
        )}
      </Grid>
      <MDBox sx={{ border: "1px solid grey" }}>
        {value?.length
          ? value?.map((tag, index) => (
              <Chip
                label={tag}
                onDelete={handleDelete(tag)}
                key={tag.replace(" ", "_")}
                style={{ margin: "0.5em" }}
              />
            ))
          : null}
      </MDBox>
      {errorMessage && (
        <FormHelperText error sx={{ fontSize: "0.75rem" }}>
          {errorMessage}
        </FormHelperText>
      )}
    </div>
  );
};

export default FreeTagList;
