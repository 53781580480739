import axios from "axios";

import { baseAxios } from "config";
import createApiParams from "utils/createApiParams";

const fetchJobs = async ({
  page,
  limit,
  order,
  orderBy,
  fetchAll,
  filters,
  venues,
  useOr = false,
  status,
  companyType = "Venue",
  outsideMode = "",
  startDate = "",
  endDate = "",
  shiftJobStartDate = "",
  shiftJobEndDate = "",
  projectedFields,
}) => {
  if (
    filters &&
    Object.prototype.hasOwnProperty.call(filters, "venueSlug") &&
    companyType === "Venue" &&
    filters.venueSlug === undefined
  )
    return null;

  try {
    const { paginationQuery, sortQuery, filterQuery } = createApiParams({
      page,
      limit,
      order,
      orderBy,
      fetchAll,
      filters,
    });

    const outsideModeQuery = !outsideMode ? "" : `outside-${outsideMode}/`;
    const projectedFieldsFinal =
      projectedFields ??
      "title,hideThisJob,companySlug,venueSlug,showCustomer,venueName,venueCity,venueState,companyCity,companyState,description,jobLocation,status";

    const res = await baseAxios.get(
      `/${outsideModeQuery}jobs${paginationQuery}${sortQuery}${filterQuery}${
        useOr ? "&useOr=true" : ""
      }${status ? `&status=${status}` : ""}${
        outsideModeQuery || projectedFields ? `&projectedFields=${projectedFieldsFinal}` : ""
      }${startDate ? `&startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}${
        shiftJobStartDate ? `&shiftJobStartDate=${shiftJobStartDate}` : ""
      }${shiftJobEndDate ? `&shiftJobEndDate=${shiftJobEndDate}` : ""}`
    );
    if (res?.data?.data?.length) {
      const retData = res.data.data.map((job) => {
        return {
          ...job,
          ...(companyType === "Venue"
            ? {
                logoUrl: venues?.[job?.venueSlug]?.logoUrl,
                venueName: venues?.[job?.venueSlug]?.name,
              }
            : {}),
        };
      });
      res.data = { ...res.data, data: [...retData] };
    }

    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default fetchJobs;
