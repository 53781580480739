/* eslint-disable react/no-array-index-key */
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Close as CloseIcon,
  GetApp as DownloadIcon,
  InsertDriveFile as FileIcon,
  Star as StarIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import MDProgress from "components/MDProgress";
import { useAppContextController } from "context/AppContext";
import React, { useEffect } from "react";
import { getCompanyImageUrl } from "utils/helpers/upload";

const AssessmentPreviewModal = ({ open, onClose, questions = [], name, assessment }) => {
  const { company } = useAppContextController();
  const [activeStep, setActiveStep] = React.useState(0);
  const [answers, setAnswers] = React.useState({});
  const [showSkills, setShowSkills] = React.useState(false);
  const [randomizedAnswers, setRandomizedAnswers] = React.useState({});
  const [isExcelView, setIsExcelView] = React.useState(false);

  const excelAssessment = assessment?.excelAssessment;
  const IMAGE_SERVER = getCompanyImageUrl(company);

  const initializeRandomizedAnswers = () => {
    const randomized = {};
    questions.forEach((question, index) => {
      if (question?.type === "Multiple Choice") {
        const shuffledAnswers = [...question?.answers].sort(() => Math.random() - 0.5);
        randomized[index] = shuffledAnswers;
      }
    });
    setRandomizedAnswers(randomized);
  };

  useEffect(() => {
    if (open) {
      setActiveStep(0);
      setShowSkills(false);
      initializeRandomizedAnswers();

      if (assessment?.type === "Excel") {
        setIsExcelView(assessment?.type === "Excel");
      }
    } else {
      setIsExcelView(false);
    }
  }, [open, questions, assessment]);

  const progress = ((activeStep + 1) / questions.length) * 100;

  const handleNext = () => {
    setActiveStep((prev) => Math.min(prev + 1, questions.length - 1));
  };

  const handleBack = () => {
    setActiveStep((prev) => Math.max(prev - 1, 0));
  };

  const handleAnswerChange = (value) => {
    setAnswers((prev) => ({
      ...prev,
      [activeStep]: value,
    }));
  };

  const toggleSkills = () => {
    setShowSkills((prev) => !prev);
  };

  const getExistingFileUrl = (type, filename) => {
    if (!assessment?._id || !filename) return null;
    const baseUrl = `${IMAGE_SERVER}/assessments`;
    const encodedFilename = encodeURIComponent(filename);
    return `${baseUrl}/${assessment._id}/${type}/${encodedFilename}`;
  };

  const handleDownload = (url, filename) => {
    if (!url) return;

    // Create an anchor element and trigger download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename; // Set suggested filename for download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderExcelView = () => {
    if (!excelAssessment) return null;

    const { startingWorkbook, expectedAnswer } = excelAssessment;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Alert severity="info" sx={{ backgroundColor: "primary.lighter" }}>
          This is an Excel-based assessment. You&apos;ll need to download and work with Excel files.
        </Alert>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Starting Template
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FileIcon sx={{ fontSize: 32, color: "success.main" }} />
              <Box sx={{ flex: 1 }}>
                <Typography sx={{ fontWeight: 500 }}>
                  {startingWorkbook?.filename || "Excel Template"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Use this file as your starting point
                </Typography>
              </Box>
              <Button
                startIcon={<DownloadIcon />}
                onClick={() => {
                  const url = getExistingFileUrl("starting-template", startingWorkbook?.filename);
                  if (url) {
                    handleDownload(url, startingWorkbook?.filename || "Excel_Template.xlsx");
                  }
                }}
              >
                Download
              </Button>
            </Box>
          </Paper>

          {showSkills && expectedAnswer && (
            <Paper elevation={1} sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Expected Answer Template
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <FileIcon sx={{ fontSize: 32, color: "success.main" }} />
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    {expectedAnswer?.filename || "Answer Template"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Reference file with expected outcomes
                  </Typography>
                </Box>
                <Button
                  startIcon={<DownloadIcon />}
                  onClick={() => {
                    const url = getExistingFileUrl("expected-answer", expectedAnswer?.filename);
                    if (url) {
                      handleDownload(url, expectedAnswer?.filename || "Answer_Template.xlsx");
                    }
                  }}
                >
                  Download
                </Button>
              </Box>
            </Paper>
          )}
        </Box>
      </Box>
    );
  };

  const renderSkillsAndIdealAnswer = (question) => {
    if (!showSkills || question?.type === "Multiple Choice") return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Paper
          elevation={0}
          sx={{
            p: 3,
            bgcolor: "grey.50",
            border: "1px solid",
            borderColor: "grey.200",
            borderRadius: 2,
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              color="info"
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 2,
              }}
            >
              <AssignmentTurnedInIcon fontSize="small" />
              Model Answer
            </Typography>

            <Paper
              elevation={0}
              sx={{
                p: 2,
                mb: 3,
                bgcolor: "background.paper",
                border: "1px solid",
                borderColor: "grey.100",
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body1"
                color="text.primary"
                sx={{
                  fontWeight: 500,
                  lineHeight: 1.6,
                  whiteSpace: "pre-line",
                  width: "100%", // Full width of container
                  maxWidth: "800px", // Maximum width
                  wordBreak: "break-word", // Ensures long words don't overflow
                }}
              >
                {question?.idealAnswer}
              </Typography>
            </Paper>
          </Box>

          <Box>
            <Typography
              variant="subtitle1"
              color="info"
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 2,
              }}
            >
              <StarIcon fontSize="small" />
              Skills Evaluated
            </Typography>

            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {question?.skillsMeasured.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill}
                  variant="outlined"
                  color="info"
                  size="medium"
                  sx={{
                    borderRadius: 1.5,
                    "& .MuiChip-label": {
                      px: 1.5,
                      fontWeight: 500,
                    },
                  }}
                />
              ))}
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderQuestion = (question) => {
    let currentAnswers;
    switch (question?.type) {
      case "Multiple Choice":
        currentAnswers = randomizedAnswers[activeStep] || question?.answers;
        return (
          <FormControl component="fieldset" fullWidth>
            <RadioGroup
              value={answers[activeStep] || ""}
              onChange={(e) => handleAnswerChange(e.target.value)}
            >
              {currentAnswers.map((answer, index) => (
                <Box key={index}>
                  <FormControlLabel
                    value={answer.answerText}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#00A3FF",
                          },
                        }}
                      />
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            flex: 1,
                          }}
                        >
                          <Typography sx={{ fontSize: "1rem" }}>{answer.answerText}</Typography>
                          {answer.isRecommended && (
                            <Box
                              sx={{
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "error.main",
                                fontSize: "32px",
                                fontWeight: "bold",
                                lineHeight: 1,
                              }}
                            >
                              *
                            </Box>
                          )}
                        </Box>
                        {showSkills && (
                          <Chip
                            label={answer.skillMeasured}
                            size="small"
                            color="info"
                            sx={{
                              //   bgcolor: "info.main",
                              color: "white",
                              height: 24,
                              flexShrink: 0,
                            }}
                          />
                        )}
                      </Box>
                    }
                    sx={{
                      width: "100%",
                      margin: "8px 0",
                      padding: "12px 16px",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#F5F5F5",
                      },
                      "&.Mui-checked": {
                        borderColor: "#00A3FF",
                        backgroundColor: "#F0F9FF",
                      },
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                  />
                </Box>
              ))}
            </RadioGroup>
          </FormControl>
        );
      case "Fill-In":
        return (
          <>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                multiline
                inputComponent={TextareaAutosize}
                placeholder="Type your answer here..."
                inputProps={{
                  minRows: 5,
                  style: {
                    resize: "vertical",
                    overflowY: "auto",
                  },
                }}
                value={answers[activeStep] || ""}
                onChange={(e) => handleAnswerChange(e.target.value)}
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    bgcolor: "background.paper",
                  },
                }}
              />
            </FormControl>

            {renderSkillsAndIdealAnswer(question)}
          </>
        );
      case "True/False":
        return (
          <>
            <FormControl component="fieldset" fullWidth>
              <RadioGroup
                value={answers[activeStep] || ""}
                onChange={(e) => handleAnswerChange(e.target.value)}
              >
                {["True", "False"].map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option.toLowerCase()}
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "#00A3FF",
                          },
                        }}
                      />
                    }
                    label={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ fontSize: "1rem", py: 1 }}>{option}</Typography>
                        {question?.idealAnswer === option && (
                          <Typography
                            component="span"
                            sx={{
                              color: "error.main",
                              ml: 1,
                              fontSize: "1.25rem",
                            }}
                          >
                            *
                          </Typography>
                        )}
                      </Box>
                    }
                    sx={{
                      width: "100%",
                      margin: "8px 0",
                      padding: "12px 16px",
                      border: "1px solid #E0E0E0",
                      borderRadius: "8px",
                      "&:hover": {
                        backgroundColor: "#F5F5F5",
                      },
                      "&.Mui-checked": {
                        borderColor: "#00A3FF",
                        backgroundColor: "#F0F9FF",
                      },
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {renderSkillsAndIdealAnswer(question)}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "80vh",
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {/* Rest of the component remains the same */}
      <Box
        sx={{
          px: 3,
          py: 2,
          borderBottom: "1px solid",
          borderColor: "grey.200",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h6" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Preview Mode
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Tooltip title={showSkills ? "Hide Answer Details" : "Show Answer Details"}>
            <IconButton onClick={toggleSkills} size="small">
              {showSkills ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      {!isExcelView ? (
        <>
          {/* <MDProgress variant="determinate" value={progress} sx={{ height: 1 }} /> */}

          <DialogContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
              pt: 4,
            }}
          >
            <Box
              sx={{
                mb: 3,
                display: "flex",
                alignItems: "center",
                gap: 3,
                p: 1.5,
                borderLeft: "4px solid",
                borderLeftColor: "info.main",
                bgcolor: "grey.50",
                borderRadius: "4px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: "text.primary",
                  fontWeight: 600,
                }}
              >
                Legend
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <Box
                    sx={{
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "error.main",
                      fontSize: "16px",
                      fontWeight: "bold",
                      lineHeight: 1,
                    }}
                  >
                    *
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontWeight: 500,
                    }}
                  >
                    Recommended Answer
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <Chip
                    label="Skill"
                    size="small"
                    color="info"
                    sx={{
                      bgcolor: "info.main",
                      color: "white",
                      fontWeight: 500,
                      minWidth: 60,
                      height: 22,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontWeight: 500,
                    }}
                  >
                    Skill Measured
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <Checkbox
                    checked
                    size="small"
                    sx={{
                      p: 0,
                      color: "info.main",
                      "&.Mui-checked": {
                        color: "info.main",
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontWeight: 500,
                    }}
                  >
                    Model Answer
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                  <StarIcon
                    color="info"
                    sx={{
                      fontSize: 20,
                      color: "info.main",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.secondary",
                      fontWeight: 500,
                    }}
                  >
                    Skills Evaluated
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mb: 1,
                }}
              >
                <Typography variant="h6">
                  Question {activeStep + 1} of {questions.length}
                </Typography>
                <Chip
                  label={questions[activeStep]?.type || "Multiple Choice"}
                  size="small"
                  sx={{
                    height: 24,
                    backgroundColor: "transparent",
                    border: "1px solid",
                    borderColor: "divider",
                  }}
                />
              </Box>
              <MDProgress
                variant="determinate"
                value={progress}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: "#E0E0E0",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#00A3FF",
                    borderRadius: 4,
                  },
                }}
              />
            </Box>

            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                whiteSpace: "pre-line",
                width: "100%", // Full width of container
                maxWidth: "800px", // Maximum width
                wordBreak: "break-word", // Ensures long words don't overflow
              }}
            >
              {questions[activeStep]?.text}
            </Typography>

            <Box sx={{ flex: 1 }}>
              {questions.length > 0 && renderQuestion(questions[activeStep])}
            </Box>
          </DialogContent>
        </>
      ) : (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            pt: 4,
          }}
        >
          <>{renderExcelView()}</>
        </DialogContent>
      )}

      <Box
        sx={{
          p: 2,
          borderTop: "1px solid",
          borderColor: "grey.200",
          display: "flex",
          justifyContent: "space-between",
          bgcolor: "grey.50",
        }}
      >
        <Button onClick={handleBack} disabled={activeStep === 0} startIcon={<ArrowBackIcon />}>
          Previous
        </Button>
        <Button
          onClick={activeStep < questions.length - 1 ? handleNext : onClose}
          variant="contained"
          endIcon={activeStep < questions.length - 1 ? <ArrowForwardIcon /> : null}
        >
          {activeStep < questions.length - 1 ? "Next" : "Finish Preview"}
        </Button>
      </Box>
    </Dialog>
  );
};

export default AssessmentPreviewModal;
