import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const SortableFormField = ({
  field,
  handleDeleteField,
  handleEditField,
  handleToggleVisibility,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: field.id,
  });

  const style = React.useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition: transform ? transition : undefined,
    }),
    [transform, transition]
  );
  
  // Calculate opacity to avoid nested ternary
  const getOpacity = () => {
    if (isDragging) return 0.9;
    if (field.hidden) return 0.6;
    return 1;
  };

  const renderFieldPreview = () => {
    const { type, name, placeholder, options, defaultValue } = field;

    switch (type) {
      case "input":
      case "email":
      case "phone":
      case "number":
      case "currency":
        return (
          <TextField
            fullWidth
            label={name}
            placeholder={placeholder || ""}
            type={type === "currency" ? "number" : type}
            value={defaultValue || ""}
            disabled
          />
        );

      case "textarea":
        return (
          <TextareaAutosize
            style={{ width: "100%", minHeight: "80px", padding: "8px" }}
            placeholder={placeholder || ""}
            disabled
          />
        );

      case "select":
      case "dropdown":
        return (
          <TextField
            select
            fullWidth
            label={name}
            value={defaultValue || ""}
            disabled
          >
            {options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );

      case "checkbox":
        return (
          <FormControlLabel
            control={<Checkbox checked={defaultValue || false} disabled />}
            label={name}
          />
        );

      case "radio":
        return (
          <RadioGroup value={defaultValue || ""}>
            {options?.map((option) => (
              <FormControlLabel key={option} value={option} control={<Radio disabled />} label={option} />
            ))}
          </RadioGroup>
        );

      case "date":
        return (
          <TextField
            fullWidth
            label={name}
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled
          />
        );

      case "paragraph":
        return (
          <Typography variant="body1" sx={{ opacity: 0.7 }}>
            {field.content}
          </Typography>
        );

      case "heading":
        return (
          <Typography variant={`h${field.level || 6}`} sx={{ fontWeight: "bold", opacity: 0.7 }}>
            {field.content}
          </Typography>
        );

      case "signature":
        return (
          <Box 
            sx={{ 
              border: "1px dashed #ccc", 
              height: "80px", 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center" 
            }}
          >
            <Typography variant="body2" color="text.secondary">Signature Field</Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} md={6} ref={setNodeRef} style={style}>
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        border="1px solid #ddd"
        borderRadius="8px"
        bgcolor={field.hidden ? "#f5f5f5" : "#fff"}
        sx={{
          position: "relative",
          transform: isDragging ? "scale(1.02)" : "scale(1)",
          zIndex: isDragging ? 999 : 1,
          opacity: getOpacity(),
          boxShadow: isDragging ? "0 8px 16px rgba(0, 0, 0, 0.1)" : "none",
          transition: "transform 150ms ease, box-shadow 150ms ease",
          willChange: "transform",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: isDragging ? "#1976d2" : "inherit",
            }}
          >
            {field.name || field.content || "Field"}
            {field.required && field.type !== "checkbox" && (
              <span style={{ color: "red" }}> *</span>
            )}
          </Typography>
          <Box display="flex" gap={1} sx={{ opacity: isDragging ? 0.7 : 1 }}>
            <IconButton
              {...listeners}
              {...attributes}
              sx={{
                cursor: isDragging ? "grabbing" : "grab",
                p: 0.5,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <DragIndicatorIcon />
            </IconButton>
            <IconButton
              onClick={() => handleEditField(field)}
              sx={{ p: 0.5 }}
              disabled={isDragging}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleToggleVisibility(field)}
              sx={{ p: 0.5 }}
              disabled={isDragging}
            >
              {!field.hidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
            <IconButton
              onClick={() => handleDeleteField(field.id)}
              sx={{ p: 0.5 }}
              disabled={isDragging}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        
        {renderFieldPreview()}
      </Box>
    </Grid>
  );
};

SortableFormField.propTypes = {
  field: PropTypes.object.isRequired,
  handleDeleteField: PropTypes.func.isRequired,
  handleEditField: PropTypes.func.isRequired,
  handleToggleVisibility: PropTypes.func.isRequired,
};

export default SortableFormField; 