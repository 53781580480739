import { useCallback, useEffect, useState } from "react";
import { Cancel, Save } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
  Stack,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import FormErrors from "components/Form/FormErrors";
import moment from "moment";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import postEmailTemplates from "context/actions/postEmailTemplates";
import updateEmailTemplate from "context/actions/updateEmailTemplate";
import { useAppContextController } from "context/AppContext";
import FormField from "layouts/applications/wizard/components/FormField";
import { parseMessage, parseMessageFromDB } from "utils/helpers/emailTemplate";
import { removeStylingTags } from "utils/string";
import EditorPreviewModal from "components/SendMessage/EditorPreviewModal";
import fetchVerifiedUsers from "layouts/pages/profile/actions/fetchVerifiedUsers";
import useSessionAuth from "hooks/useSessionAuth";
import EmailVariableTable from "./EmailVariableTable";

const EmailTemplateModal = ({ isOpen, template, onClose, context, setContext }) => {
  const { company } = useAppContextController();

  const { enqueueSnackbar } = useSnackbar();
  const [sender, setSender] = useState(null);
  const [email, setEmail] = useState(null);
  const [verifiedUserList, setVerifiedUserList] = useState([]);
  const queryClient = useQueryClient();
  const { control, handleSubmit, formState, reset, watch, setValue } = useForm();
  const { errors } = formState;
  const isCompany = company?.companyType === "Company";
  const [manager, setManager] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const { logoutAuth0User } = useSessionAuth();

  const discardModal = useCallback(() => {
    reset(
      { message: "", name: "", subject: "", suppressEmailFooter: "No" },
      {
        keepValues: false,
      }
    );
    onClose();
  }, [onClose]);

  const onSubmit = async ({ message, subject, ...data }) => {
    const parsed = parseMessage(message);
    const parsedSubject = parseMessage(subject);
    const body = {
      context,
      Message: removeStylingTags(parsed),
      subject: removeStylingTags(parsedSubject),
      sendFrom: sender === "other" ? { type: sender, email } : { type: sender },
      ...data,
    };

    try {
      let res;
      if (template?._id) {
        res = await updateEmailTemplate(template._id, body);
      } else {
        res = await postEmailTemplates(body);
      }
      if (res.status === 201 || res.acknowledged) {
        discardModal();
        await queryClient.invalidateQueries("messagetemplates");
        enqueueSnackbar("Email template created.", { variant: "success" });
      }
    } catch (err) {
      enqueueSnackbar("Sorry! There is an error.", { variant: "error" });
      if (String(err).includes("401") || err?.response?.status === 401) {
        logoutAuth0User();
      }
      throw new Error(err.toString());
    }
  };

  const handleSuppressEmailFooter = (value) => {
    setValue("suppressEmailFooter", value ? "Yes" : "No", { shouldDirty: true });
  };

  const handleCheckContextChange = (e) => {
    if (context.includes(e.target.id)) {
      context.splice(context.indexOf(e.target.id), 1);
      setContext(context.filter((element) => element !== e.target.id));
    } else if (e.target.id === "Event") {
      setContext([...context, "Event", "Venue"]);
    } else if (e.target.id === "Prospect" && isCompany) {
      setContext([...context, "Prospect", "Customer"]);
    } else if (e.target.id === "Prospect" && !isCompany) {
      setContext([...context, "Prospect", "Venue"]);
    } else if (e.target.id === "Interview") {
      setContext([...context, "Interview", "Customer"]);
    } else {
      setContext([...context, e.target.id]);
    }
  };

  useEffect(() => {
    if (isCompany) {
      setManager(!context.includes("Customer"));
    } else {
      setManager(!context.includes("Venue"));
    }
  }, [context, isCompany]);

  useEffect(() => {
    if (template && isOpen) {
      reset({
        name: template?.name || "",
        subject: template?.subject ? parseMessageFromDB(template?.subject) : "",
        suppressEmailFooter: template?.suppressEmailFooter,
        message: template?.Message ? parseMessageFromDB(template?.Message) : "",
      });
      setSender(template?.sendFrom?.type);
      setEmail(template?.sendFrom?.email || null);
    }
  }, [template, isOpen]);

  useEffect(async () => {
    try {
      const tempVerifiedUserList = await fetchVerifiedUsers();
      setVerifiedUserList(tempVerifiedUserList);
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  }, []);

  return (
    <Modal open={isOpen} onClose={discardModal}>
      <Card
        sx={{
          position: "absolute",
          width: "70%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 50,
        }}
      >
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" pt={3} px={3}>
              <Grid container>
                <Grid item sm={12}>
                  <Grid container>
                    <Grid item>
                      <Typography variant="h5" color="dark" sx={{ width: "100%", mr: 2 }}>
                        Message Template
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MDTypography variant="body2">
                        Last Update:{" "}
                        {moment(template?.dateModified).format("ddd MM/DD/YYYY hh:mm a")}
                      </MDTypography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={6} mt={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item sm={2}>
                      <MDTypography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                        Template Type:
                      </MDTypography>
                    </Grid>
                    <Grid item sm={10}>
                      <Grid container>
                        <Grid item sm={4}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id="Applicant"
                              checked={context.includes("Applicant")}
                              onChange={(e) => handleCheckContextChange(e)}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              {" "}
                              Applicant
                            </MDTypography>
                          </Stack>
                        </Grid>
                        <Grid item sm={4}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id={isCompany ? "Customer" : "Venue"}
                              disabled={
                                isCompany
                                  ? context.includes("Interview") || context.includes("Prospect")
                                  : context.includes("Event") || context.includes("Prospect")
                              }
                              checked={context.includes(isCompany ? "Customer" : "Venue")}
                              onChange={(e) => handleCheckContextChange(e)}
                              sx={{
                                disabled: {
                                  cursor: "not-allowed",
                                  color: "grey",
                                  backgroundColor: "grey",
                                },
                              }}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              {isCompany ? "Customer" : "Venue"}
                            </MDTypography>
                          </Stack>
                        </Grid>
                        <Grid item sm={4}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id={isCompany ? "Interview" : "Event"}
                              checked={context.includes(isCompany ? "Interview" : "Event")}
                              onChange={(e) => handleCheckContextChange(e)}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              {isCompany ? "Interview" : "Event"}
                            </MDTypography>
                          </Stack>
                        </Grid>

                        <Grid item sm={4}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id="Issue"
                              checked={context.includes("Issue")}
                              onChange={(e) => handleCheckContextChange(e)}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              Issue
                            </MDTypography>
                          </Stack>
                        </Grid>
                        <Grid item sm={4}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id="Prospect"
                              checked={context.includes("Prospect")}
                              onChange={(e) => handleCheckContextChange(e)}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              Prospect
                            </MDTypography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <Grid container alignItems="center">
                    <Grid item sm={2}>
                      <MDTypography variant="body2" sx={{ whiteSpace: "nowrap" }}>
                        Sender:
                      </MDTypography>
                    </Grid>
                    <Grid item sm={10}>
                      <Grid container>
                        <Grid item sm={6}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id="company"
                              checked={sender === "company"}
                              onChange={(e) => {
                                setSender(e.target.id);
                                setEmail(null);
                              }}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              {" "}
                              {company?.companyEmail}
                            </MDTypography>
                          </Stack>
                        </Grid>
                        <Grid item sm={6}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id={isCompany ? "customerManager" : "venueManager"}
                              disabled={manager}
                              checked={sender === "customerManager" || sender === "venueManager"}
                              onChange={(e) => {
                                setSender(e.target.id);
                                setEmail(null);
                              }}
                            />
                            <MDTypography
                              fontWeight="bold"
                              variant="body2"
                              color={manager && "secondary"}
                            >
                              {" "}
                              {isCompany ? "Customer Manager" : "Venue Manager"}
                            </MDTypography>
                          </Stack>
                        </Grid>
                        <Grid item sm={6}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Checkbox
                              id="other"
                              checked={sender === "other"}
                              onChange={(e) => setSender(e.target.id)}
                            />
                            <MDTypography fontWeight="bold" variant="body2">
                              {" "}
                              Other
                            </MDTypography>
                          </Stack>
                        </Grid>
                        <Grid item sm={6}>
                          <Autocomplete
                            options={verifiedUserList || []}
                            disabled={sender !== "other"}
                            defaultValue={null}
                            value={email}
                            onChange={(e, v) => {
                              setEmail(v);
                            }}
                            sx={{ maxHeight: "44px" }}
                            renderInput={(params) => <FormField {...params} type="text" />}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>

            <FlexWrapper position="relative" p={3} justifyContent="flex-start" gap="10px">
              <Grid container spacing={3}>
                <Grid item sx={12} sm={4}>
                  <Controller
                    name="name"
                    rules={{ required: "Message title is required" }}
                    control={control}
                    render={({ field }) => (
                      <FormField
                        label="Message Title"
                        sx={{ maxWidth: 500, input: { fontSize: "1.15rem" } }}
                        {...field}
                        disabled={template?.isSystem === "Yes"}
                      />
                    )}
                  />
                </Grid>
                <Grid item sx={12} sm={4}>
                  <Controller
                    name="subject"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormField
                        label="Subject Line"
                        sx={{ maxWidth: 500, input: { fontSize: "1.15rem" } }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item sx={12} sm={4}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Checkbox
                      id="suppressEmailFooter"
                      checked={watch("suppressEmailFooter") === "Yes"}
                      onChange={(e, v) => {
                        handleSuppressEmailFooter(v);
                      }}
                    />
                    <MDTypography fontWeight="bold" variant="body2">
                      Suppress Email Footer
                    </MDTypography>
                  </Stack>
                </Grid>
              </Grid>

              <EmailVariableTable />

              <MDBox
                mb={8}
                width="100%"
                sx={{
                  "& .quill": {
                    height: "200px",
                  },
                }}
              >
                <MDTypography variant="body2" color="dark">
                  MESSAGE
                </MDTypography>
                <Controller
                  name="message"
                  control={control}
                  defaultValue="<p></p>"
                  render={({ field }) => (
                    <MDEditor
                      {...field}
                      onChange={(e) => {
                        setValue("message", removeStylingTags(e, "{{"));
                      }}
                    />
                  )}
                />
              </MDBox>
              <FormErrors errors={errors} />
              <FlexWrapper mt="15px" justifyContent="flex-start">
                {/* <MDTypography variant="body2" color="info">
                  Upload Image
                </MDTypography> */}
              </FlexWrapper>
              <FlexWrapper justifyContent="flex-end">
                <MDButton color="info" variant="text" onClick={() => setOpenPreview(true)}>
                  Preview
                </MDButton>
                <MDButton onClick={discardModal} color="error" variant="text" endIcon={<Cancel />}>
                  Cancel
                </MDButton>

                <MDButton color="info" variant="contained" endIcon={<Save />} type="submit">
                  {template ? "Update" : "Save"}
                </MDButton>
              </FlexWrapper>
            </FlexWrapper>
          </form>
          {openPreview && (
            <EditorPreviewModal
              toggleOpen={() => setOpenPreview(!openPreview)}
              isOpen={openPreview}
              message={watch("message")}
            />
          )}
        </CardContent>
      </Card>
    </Modal>
  );
};

export default EmailTemplateModal;
