import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import SplitPanelSelector from "components/SplitPanelSelector";
import {
  AccountCircle,
  AttachFile,
  Business,
  DateRange,
  Description,
  Email,
  FolderShared as FolderSharedIcon,
  Info,
  Stadium,
  Timeline as TimelineIcon,
} from "@mui/icons-material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import WorkIcon from "@mui/icons-material/Work";
import { useAppContextController } from "context/AppContext";

const userPanelAllowedStatus = ["Employee"];

const ApplicantHeader = ({
  parent,
  panelSelect,
  setPanelSelect,
  boarding,
  setBoarding,
  defaultPanel,
}) => {
  const { currentApplicant, company } = useAppContextController();
  const isCompany = company?.companyType === "Company";
  const isEdit = !!currentApplicant?._id;
  const isUserPanelVisible = userPanelAllowedStatus.includes(currentApplicant?.status);

  useEffect(() => {
    if (!isEdit) {
      setPanelSelect("");
    } else {
      setPanelSelect(defaultPanel ?? "activities");
    }
  }, [isEdit, setPanelSelect]);

  const handleClick = (selection) => {
    setPanelSelect(selection);
    setBoarding(false);
  };

  const handleBoarding = () => {
    setBoarding(true);
    setPanelSelect("activities");
    setTimeout(() => {
      const bottomElement = document.querySelector("#onboardingPanel");
      if (bottomElement) bottomElement.scrollIntoView({ behavior: "smooth" });
    }, 10);
  };

  const applicantTabs = [
    {
      title: "Activities",
      icon: <TimelineIcon />,
      hasShadow: panelSelect === "activities" && !boarding ? "emd" : "",
      color: "error",
      ariaLabel: "timeLine",
      fn: () => handleClick("activities"),
      hidden: false,
      name: "activities",
    },
    {
      title: "Additional Information",
      icon: <Info />,
      hasShadow: panelSelect === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "information",
      fn: () => handleClick("additionalInfo"),
      hidden: isCompany,
      name: "additionalInfo",
    },
    {
      title: "Venues",
      icon: <Stadium />,
      hasShadow: panelSelect === "venues" ? "emd" : "",
      color: "info",
      ariaLabel: "stadium",
      fn: () => handleClick("venues"),
      hidden: isCompany,
      name: "venues",
    },
    {
      title: "Companies",
      icon: <Business />,
      hasShadow: panelSelect === "companies" ? "emd" : "",
      color: "info",
      ariaLabel: "business",
      fn: () => handleClick("companies"),
      hidden: !isCompany,
      name: "companies",
    },
    {
      title: "Jobs",
      icon: <WorkIcon />,
      hasShadow: panelSelect === "jobs" ? "emd" : "",
      color: "warning",
      ariaLabel: "work",
      fn: () => handleClick("jobs"),
      name: "jobs",
    },
    {
      title: isCompany ? "Interview" : "Events",
      icon: <DateRange />,
      hasShadow: panelSelect === "orientation" ? "emd" : "",
      color: "success",
      ariaLabel: "date",
      fn: () => handleClick("orientation"),
      name: "orientation",
    },
    {
      title: "Onboarding",
      icon: <FolderSharedIcon />,
      hasShadow: boarding ? "emd" : "",
      color: "error",
      ariaLabel: "folderShared",
      fn: handleBoarding,
      name: "onboarding",
    },
    {
      title: "Notes",
      icon: <Description />,
      hasShadow: panelSelect === "note" ? "emd" : "",
      color: "",
      ariaLabel: "description",
      fn: () => handleClick("note"),
      name: "note",
    },
    {
      title: "User Account",
      icon: <AccountCircle color="info" />,
      hasShadow: panelSelect === "user" ? "emd" : "",
      color: "",
      ariaLabel: "userPanel",
      fn: () => handleClick("user"),
      hidden: !isUserPanelVisible,
      name: "user",
    },
    {
      title: "Messages",
      icon: <Email />,
      hasShadow: panelSelect === "message" ? "emd" : "",
      color: "warning",
      ariaLabel: "Email",
      fn: () => handleClick("message"),
      name: "message",
    },
    {
      title: "Attachments",
      icon: <AttachFile />,
      hasShadow: panelSelect === "attachments" ? "emd" : "",
      color: "dark",
      ariaLabel: "attachments",
      fn: () => handleClick("attachments"),
      name: "attachments",
    },
    {
      title: "Additional Info",
      icon: <NoteAddIcon />,
      hasShadow: panelSelect === "additionalInfo" ? "emd" : "",
      color: "violet",
      ariaLabel: "additionalInfo",
      fn: () => handleClick("additionalInfo"),
      hidden: !isCompany,
      name: "additionalInfo",
    },
  ];

  return (
    <FlexWrapper justifyContent="space-between">
      <Grid container sx={{ px: "1.5rem" }} alignItems="center">
        <Grid item xs={12} sm={7}>
          <FlexWrapper
            flex="1 0 50%"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
          >
            <MDTypography variant="h5" color="dark" display="block" sx={{ whiteSpace: "wrap" }}>
              {isEdit ? (
                `${currentApplicant?.firstName} ${currentApplicant?.lastName}`
              ) : (
                <>Create {parent}</>
              )}
            </MDTypography>
            {isEdit && (
              <MDTypography
                fontSize="14px"
                variant="p"
                color="dark"
                display="block"
                sx={{ whiteSpace: "nowrap" }}
              >
                Last Update:
                {moment(currentApplicant?.modifiedDate).format("ddd MM/DD/YYYY hh:mm a")}
              </MDTypography>
            )}
          </FlexWrapper>
        </Grid>
        {isEdit && (
          <Grid item xs={12} sm={5}>
            <MDBox display="flex" justifyContent="flex-end">
              <Stack direction="row" alignItems="center" flexWrap="wrap">
                <SplitPanelSelector
                  panelOptions={applicantTabs}
                  selectedPanel={panelSelect}
                  isEdit={isEdit}
                />
              </Stack>
            </MDBox>
          </Grid>
        )}
      </Grid>
    </FlexWrapper>
  );
};

export default ApplicantHeader;
