import {
  Grid,
  Icon,
  IconButton,
  Button,
  Stack,
  Tooltip,
  Typography as MDTypography,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useState, useEffect } from "react";
import { getCommonBaseImageUrl } from "utils/helpers/upload";
import moment from "moment";

const IMAGE_EXTENSIONS = ["jpeg", "jpg", "png", "webp"];

const FileViewer = ({
  file,
  currentApplicant,
  IMAGE_SERVER,
  company,
  onDelete,
  index,
  size = 100,
}) => {
  const [usePresigned, setUsePresigned] = useState(true);

  // Generate direct URL for the file
  const getDirectUrl = () => {
    return `${IMAGE_SERVER}/applicants/${currentApplicant?._id}/${file.type}/${
      file.filename || file.name
    }`;
  };

  // Determine file preview source
  const getFilePreviewSrc = () => {
    if (IMAGE_EXTENSIONS.includes(file.docType.toLowerCase())) {
      return usePresigned && file.presignedUrl ? file.presignedUrl : getDirectUrl();
    }

    // Show icon for PDFs and Word docs
    const baseUrl = getCommonBaseImageUrl(company);
    if (file.docType.toLowerCase() === "pdf") {
      return `${baseUrl}/static/pdf-icon.png`;
    }
    if (file.docType.toLowerCase() === "docx") {
      return `${baseUrl}/static/word-icon.png`;
    }
    if (file.docType.toLowerCase() === "xlsx") {
      return `${baseUrl}/static/excel_icon.png`;
    }

    return null;
  };

  // Handle file opening
  const handleFileOpen = () => {
    if (usePresigned && file.presignedUrl) {
      fetch(file.presignedUrl, { method: "HEAD" })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Presigned URL failed");
          }
          window.open(file.presignedUrl);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("Presigned URL failed, falling back to direct URL");
          setUsePresigned(false);
          window.open(getDirectUrl());
        });
    } else {
      window.open(getDirectUrl());
    }
  };

  // Check presigned URL validity on mount
  useEffect(() => {
    if (!file.presignedUrl) {
      setUsePresigned(false);
    }
  }, [file.presignedUrl]);

  return (
    <Grid container alignItems="center">
      {/* File preview area */}
      <Grid item xs={12} sm={2} overflow="auto">
        <MDBox
          component="img"
          sx={{
            height: size,
            display: "block",
            overflow: "hidden",
            borderRadius: 5,
            padding: 1,
            cursor: "pointer",
          }}
          src={getFilePreviewSrc()}
          alt={`${file.type} preview`}
          onError={() => setUsePresigned(false)}
          onClick={handleFileOpen}
        />
      </Grid>

      {/* File details area */}
      <Grid item xs={12} sm={9}>
        <Grid container>
          <Grid item xs={12}>
            <MDTypography variant="h6">
              {file.type}
              <Button onClick={handleFileOpen}>View</Button>
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDTypography variant="body2">
              {moment(file.uploadDate).format("dddd MMM Do HH:mm")}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <MDTypography variant="body2">{file.filename || file.name}</MDTypography>
              <MDTypography variant="body2">{file.title}</MDTypography>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      {/* Delete button area */}
      <Grid item xs={12} sm={1}>
        <MDBox>
          <Tooltip title="Delete File">
            <IconButton color="info" onClick={() => onDelete(index)}>
              <Icon fontSize="large">cancel</Icon>
            </IconButton>
          </Tooltip>
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default FileViewer;
