// import {useState} from "react";
import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import BadgeIcon from "@mui/icons-material/Badge";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppContextController } from "context/AppContext";
import GenericMoreButton from "components/GenericMoreButton";
// import ChatIcon from '@mui/icons-material/Chat';

const UsersTableActions = ({
  id,
  row,
  setCurrentUser,
  setGenericModalOpen,
  setModalInfo,
  deleteModalHandler,
}) => {
  const { userType, currentLoggedUser } = useAppContextController();
  const navigate = useNavigate();

  const onClickHandler = (action, e) => {
    setCurrentUser(row);
    switch (action) {
      case "userinfo":
        navigate(`/users/${id}/action/${action}`);
        break;
      case "userall":
        navigate(`/users/${id}/action/${action}`);
        break;
      case "usermail":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      case "usercomments":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      case "userdelete":
        deleteModalHandler();
        break;
      case "useremployee":
        navigate(`/employees/${row?.applicantId}/action/employeeinfo?ref=users`);
        break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <div style={{ textAlign: "end" }}>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("userinfo", e)}
        tooltip="User Info"
      />
      <GenericMoreButton
        buttons={[
          {
            color: "error",
            icon: "mail",
            clickAction: (e) => onClickHandler("usermail", e),
            tooltip: "User Mail",
          },
          {
            color: "dark",
            icon: "description",
            clickAction: (e) => onClickHandler("usercomments", e),
            tooltip: "User Notes",
          },
          {
            color: "error",
            icon: "badge",
            clickAction: (e) => onClickHandler("useremployee", e),
            tooltip: "Employee Info",
          },
          ...(userType === "Master" && currentLoggedUser?._id !== id
            ? [
                {
                  color: "error",
                  icon: "delete",
                  clickAction: (e) => onClickHandler("userdelete", e),
                  tooltip: "Delete User",
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default UsersTableActions;

UsersTableActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
UsersTableActions.propTypes = {
  id: PropTypes.string,
};
