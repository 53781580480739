import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import axios from "axios";
import moment from "moment";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FlagIcon from "@mui/icons-material/Flag";
import { Lock, LockOpen } from "@mui/icons-material";
import { Box } from "@mui/material";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import OnboardingStatus from "components/OnboardingStatus";
import PictureAvatar from "components/PictureAvatar";
import SmartPhoneColumn from "components/SmartPhoneColumn";
import { useAppContextController } from "context/AppContext";
import useSort from "utils/useSort";
// import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import EmployeeStatus from "components/EmployeeStatus";
import EventsTimeActions from "layouts/pages/events/components/EventsTimeActions";
import RosterPosition from "layouts/pages/events/components/RosterPosition";
import EventStatusButton from "components/EventStatusButton";
import VenueStatusButton from "components/VenueStatusButton";
import { baseAxios } from "config";
import { useEventContext } from "context/EventContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";
import rosterClockIn from "layouts/pages/events/actions/rosterClockIn";
import EventActionModal from "layouts/pages/events/components/EventActionModal";
import { attended, getApplicantVenueStatus } from "utils/helpers/applicant";
import useSessionAuth from "hooks/useSessionAuth";
import ApplicantStatus from "components/ApplicantStatus";
import InterviewAttended from "../InterviewAttended";
import TimeInOut from "../TimeInOut";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const EventsTimeRoster = ({
  filters,
  rosterFilters,
  setFilters,
  fetchAll,
  showFiltersList,
  name,
  setApplicantPoolSize,
  setApplicantsData,
  eventDay,
  defaultSort = "lastName",
  showEventStatus = false,
  readRoster,
  setReadRoster,
  readApps,
  setReadApps,
  toggleReadApps,
  toggleReadRoster,
  toggleRefreshBadges,
  applicants,
  appLoading,
  rosterApplicants,
  rosterLoading,
  rosterIds,
  setSelectedCardNumber,
  requeryApplicants,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setEventRoster } = useEventContext();
  //  const queryClient = useQueryClient();
  const { closeSnackbar } = useSnackbar();

  const { venues, setCurrentEvent, currentEvent, userType } = useAppContextController();
  const [venueSlug, setVenueSlug] = useState(currentEvent ? currentEvent.venueSlug : null);
  const [mongoId, setMongoId] = useState(currentEvent ? currentEvent._id : null);
  const { eventUrl } = useParams();
  const [modalInfo, setModalInfo] = useState({ data: currentEvent });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [showTimeColumns, setShowTimeColumns] = useState(true);

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50); // Default pageSize higher than 10. Don't use preferred pageSize
  const [apps, setApps] = useState({});
  // const [applicants, setApplicants] = useState(null);
  // const [appLoading, setAppLoading] = useState(false);

  const { order, orderBy, toggleSort } = useSort();
  const [toastAlert, setToastAlert] = useState(false);

  const { logoutAuth0User } = useSessionAuth();

  const isOnRoster = (applId, i) => {
    if (rosterApplicants?.result) {
      const returnValue = rosterApplicants.result?.findIndex(
        (item) => item.id === applId && item.status === "Roster"
      );
      return returnValue > -1;
    }
    return false;
  };

  setApplicantPoolSize(applicants?.data?.length);

  const eventVenue = venues?.[currentEvent?.venueSlug] || {};

  const rosterStatus = (id, status) => {
    return (
      rosterApplicants?.result.findIndex((item) => item.id === id && item.status === status) > -1 ||
      false
    );
  };

  useEffect(() => {
    const getData = async () => {
      const getFilteredEmp = async () => {
        if (!appLoading && applicants?.data) {
          // we need to "break" the integrity here and populate data with Employees
          const empOptions = {
            limit: 100,
            order: "asc",
            orderBy: "lastName",
            fetchAll: true,
            filters: {
              "venues.venueSlug": eventVenue?.slug,
            },
          };
          const emps = await fetchEmployees(empOptions);
          const data = emps.data?.filter((app) => {
            const appId = app._id || app.id;
            return rosterIds.includes(appId);
          });
          return {
            data,
          };
        }
        return { data: [] };
      };
      const getFilteredPart = async () => {
        if (rosterFilters?.status) {
          if (rosterFilters.status === "Partner") {
            // we need to "break" the integrity here and populate data with Employees
            const empOptions = {
              limit: 100,
              order: "asc",
              orderBy: "lastName",
              fetchAll: true,
              filters: {
                "venues.venueSlug": eventVenue?.slug,
                status: "Partner",
              },
            };
            const emps = await fetchApplicants(empOptions);
            return {
              data: emps.data?.filter((app) => rosterIds.includes(app._id)),
            };
          }
        }
        return { data: [] };
      };

      const getFilteredApp = () => {
        if (rosterFilters.status === "" || rosterFilters.filt === "All")
          return { data: applicants.data };
        if ((rosterFilters?.filt || rosterFilters.status) && !appLoading && applicants?.data) {
          if (rosterFilters.filt === "Attended")
            return {
              data: applicants.data?.filter(
                (app) => rosterIds.includes(app._id) && attended(app._id, currentEvent)
              ),
            };
          if (rosterFilters.filt === "No Show")
            return {
              data: applicants.data?.filter(
                (app) =>
                  rosterIds.includes(app._id) &&
                  new Date(currentEvent?.eventDate).getTime() < new Date().getTime() &&
                  !attended(app._id, currentEvent)
              ),
            };
          if (rosterFilters.status === "Roster" || rosterFilters.filt === "Roster") {
            return {
              data: applicants.data?.filter(
                (app) =>
                  rosterIds.includes(app._id) &&
                  rosterStatus(app._id, rosterFilters.status || rosterFilters.filt)
              ),
            };
          }
          if (rosterFilters.status === "Request") {
            return {
              data: applicants.data?.filter(
                (app) => rosterIds.includes(app._id) && rosterStatus(app._id, rosterFilters.status)
              ),
            };
          }
          if (rosterFilters.status === "Waitlist") {
            return {
              data: applicants.data?.filter(
                (app) => rosterIds.includes(app._id) && rosterStatus(app._id, rosterFilters.status)
              ),
            };
          }
          if (rosterFilters.status === "Not Roster" || rosterFilters.filt === "Not Roster")
            return { data: applicants.data?.filter((app) => !rosterIds.includes(app._id)) };
        }
        return { data: [] };
      };

      if (!appLoading && !rosterLoading && applicants?.data?.length) {
        setApplicantsData(applicants.data);
        setShowTimeColumns(["All", "Roster"].includes(rosterFilters.status));
        //      setApps(applicants.data);
        let data;
        if (
          (!rosterFilters?.status && !rosterFilters.filt) ||
          !["Employee", "Partner"].includes(rosterFilters.status || rosterFilters.filt)
        ) {
          data = getFilteredApp();
        } else if (rosterFilters.filt === "Employee") {
          data = await getFilteredEmp();
        } else if (rosterFilters.status === "Partner") {
          data = await getFilteredPart();
        } else data = [];

        setApps(data);
        setEventRoster(data.data);
      }
    };

    try {
      getData();
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appLoading, rosterFilters, applicants, readApps, rosterLoading]);

  useEffect(() => {
    setPage(1);
  }, [rosterFilters]);

  useEffect(() => {
    if (apps?.data?.length !== undefined) setSelectedCardNumber(apps.data.length);
  }, [apps?.data?.length]);

  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);

  useEffect(() => {
    if (currentEvent?._id && currentEvent?.eventDate) {
      setVenueSlug(currentEvent.venueSlug);
      setMongoId(currentEvent._id);
      const gmtDate = new Date(currentEvent.eventDate);
      const offset = gmtDate.getTimezoneOffset();
      const MS_PER_MINUTE = 60000;
      const localDate = new Date(gmtDate - offset * MS_PER_MINUTE);

      if (localDate) setEventDate(localDate?.toISOString().split("T")[0]);
      if (gmtDate) setEventTime(gmtDate?.toISOString().split("T")[1]);
    }
  }, [currentEvent]);

  const deleteRosterMutation = useMutation(
    async (data) => {
      // eslint-disable-next-line no-unused-expressions
      await baseAxios.delete(`/events/url/${eventUrl}/roster?id=${data.id}`, {
        headers: { Authorization: "***" },
      });
    },
    {
      // eslint-disable-next-line no-return-await
      // onSuccess: async () => await queryClient.invalidateQueries(["rosterApplicants", eventUrl]),
      onSuccess: () => toggleReadRoster(),
      onError: (error) => alert(error.toString()),
    }
  );

  const insertRosterMutation = useMutation(updateEventRoster, {
    // eslint-disable-next-line
    onSuccess: async (_, { data }) => {
      // await queryClient.invalidateQueries("rosterApplicants")
      const newApps = currentEvent?.applicants;
      if (newApps?.length) {
        const ndx = newApps.findIndex((item) => item.id === data.id);
        if (ndx > -1) {
          newApps[ndx] = data;
          setCurrentEvent({ ...currentEvent, applicants: newApps });
        }
      }
      toggleReadRoster();
    },
    onError: (error) => alert(error.toString()),
  });

  //
  const clockInRosterMutation = useMutation(rosterClockIn, {
    // eslint-disable-next-line
    onSuccess: async (_, { data }) => {
      // await queryClient.invalidateQueries("rosterApplicants")
      const newApps = currentEvent?.applicants;
      if (newApps?.length) {
        const ndx = newApps.findIndex((item) => item.id === data.id);
        if (ndx > -1) {
          newApps[ndx] = data;
          setCurrentEvent({ ...currentEvent, applicants: newApps });
        }
      }
      toggleReadRoster();
    },
    onError: (error) => alert(error.toString()),
  });

  const clockInOut = async (e, id, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    } else newRoster[field] = newRoster[field] || new Date();
    await insertRosterMutation.mutateAsync({ id: currentEvent?._id, data: newRoster });
  };

  //
  const clockInOutAtEventTime = async (e, id, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    } else newRoster[field] = newRoster[field] || new Date();
    await clockInRosterMutation.mutateAsync({
      id: currentEvent?._id,
      applicantId: rosterObj.id,
      data: newRoster,
    });
  };

  const getSignupDate = (id) => {
    if (rosterIds.includes(id)) {
      const rosterRec = rosterApplicants?.result?.find((item) => item.id === id);
      if (rosterRec) return { signupDate: rosterRec.dateModified, agent: rosterRec?.agent };
    }
    return null;
  };

  const getEmployeeFlag = (appId) => {
    if (!rosterApplicants?.result?.length) return "";
    const ndx = rosterApplicants?.result?.findIndex((app) => app.id === appId);
    if (ndx > -1 && "flag" in rosterApplicants.result[ndx]) {
      return rosterApplicants.result[ndx];
    }
    return "";
  };

  const handleRowClick = (row, e) => {
    e.stopPropagation();
    // const route =
    //   currentEvent?.eventType === "Event" || row?.status === "Employee"
    //     ? "employees"
    //     : "applicants";
    // const path = row?.status === "Employee" ? "employeeinfo" : "applicantinfo";

    // navigate(`/${route}/${row._id}/action/${path}?backTo=events`);
  };

  const columns = useMemo(
    () => [
      {
        title: "Roster",
        field: "venues",
        customCell: (check, field, row) => {
          const eventApplicant =
            rosterApplicants?.result?.find((item) => item.id === row?._id) || null;
          const eventStatus = eventApplicant?.status || "Not Roster";
          const applicantVenueStatus = getApplicantVenueStatus(row, currentEvent?.venueSlug);
          const isInvalid = applicantVenueStatus === "Locked" || row?.recordLocked === "Yes";
          return (
            <>
              <EventStatusButton
                status={eventStatus}
                isInvalid={isInvalid}
                applicant={row}
                event={{ _id: currentEvent?._id, eventUrl, applicants: rosterApplicants?.result }}
                onUpdate={() => {
                  toggleReadRoster();
                  toggleRefreshBadges?.();
                }}
                iconType="distinct"
              />
            </>
          );
        },
      },
      ...(["Master", "Admin"].includes(userType)
        ? [
            {
              title: "Locked",
              field: "recordLocked",
              width: 5,
              customCell: (recordLocked) => (
                <Box pl={0.75}>
                  {recordLocked === "Yes" ? (
                    <Lock fontSize="large" color="error" />
                  ) : (
                    <LockOpen fontSize="large" color="success" />
                  )}
                </Box>
              ),
            },
          ]
        : []),
      {
        title: "Avatar",
        field: "profileImg",
        customCell: (image, field, row) => (
          <PictureAvatar
            image={row?.profileImg}
            firstName={row?.firstName}
            lastName={row?.lastName}
            userId={row?.userRecordId ? row.userRecordId : null}
            applicantId={row?.userRecordId ? null : row._id}
          />
        ),
      },
      {
        title: "Status",
        field: "profileImg",
        customCell: (image, field, row) => <EmployeeStatus applicant={row} size="md" />,
      },
      {
        title: "Mobile",
        field: "platform",
        customCell: (platform) => {
          return <SmartPhoneColumn platform={platform} />;
        },
      },
      // ...(showEventStatus
      //   ? [
      //       {
      //         title: "Venue",
      //         field: "venueSlug",
      //         customCell: (vslug, field, row) => (
      //           <>
      //             <VenueStatusButton
      //               status={getApplicantVenueStatus(row, currentEvent?.venueSlug)}
      //               venue={eventVenue}
      //               onUpdate={() => requeryApplicants()}
      //               applicant={row}
      //             />
      //           </>
      //         ),
      //       },
      //     ]
      //   : []),
      {
        title: "Last Name",
        field: "lastName",
        customCell: (last, field, row) => (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            {["Master", "Admin"].includes(userType) ? (
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
              <div
                onClick={() => {
                  const route =
                    currentEvent?.eventType === "Event" || row?.status === "Employee"
                      ? "employees"
                      : "applicants";
                  const path = row?.status === "Employee" ? "employeeinfo" : "applicantinfo";
                  navigate(`/${route}/${row._id}/action/${path}?backTo=events`);
                }}
              >
                {last}
              </div>
            ) : (
              <div>{last}</div>
            )}
          </CustomWidthTooltip>
        ),
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (first, field, row) => (
          <CustomWidthTooltip
            title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
            sx={{ color: "red" }}
          >
            {["Master", "Admin"].includes(userType) ? (
              /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
              <div
                onClick={() => {
                  const route =
                    currentEvent?.eventType === "Event" || row?.status === "Employee"
                      ? "employees"
                      : "applicants";
                  const path = row?.status === "Employee" ? "employeeinfo" : "applicantinfo";
                  navigate(`/${route}/${row._id}/action/${path}?backTo=events`);
                }}
              >
                {first}
              </div>
            ) : (
              <div>{first}</div>
            )}
          </CustomWidthTooltip>
        ),
      },
      { title: "Login", field: "loginVerified" },
      { title: "Phone", field: "phone" },
      // {
      //   title: "Birthdate",
      //   field: "birthDate",
      //   customCell: (date) => (date ? moment(date).format("MM/DD/YYYY") : ""),
      // },
      // { title: "City", field: "city" },
      // { title: "State", field: "state" },
      ...(currentEvent?.eventType === "Interview"
        ? [
            { title: "Type", field: "status" },
            {
              title: "Applicant Status",
              field: "applicantStatus",
              customCell: (image, field, row) => <ApplicantStatus applicant={row} size="lg" />,
            },
            {
              title: "Status",
              field: "timeIn",
              customCell: (timeIn, field, row) => (
                <MDBox width="7rem">
                  <InterviewAttended
                    id={row?._id}
                    field="timeIn"
                    currentEvent={currentEvent}
                    eventDate={eventDate}
                    eventTime={eventTime}
                    rosterObj={
                      // !rosterLoading &&
                      rosterApplicants?.result?.find((item) => item.id === row._id)
                    }
                    disabled={!isOnRoster(row._id)}
                    timeZone={currentEvent?.timeZone}
                    clockInOut={(e, rosterObj) => clockInOut(e, row._id, field, rosterObj)}
                  />
                </MDBox>
              ),
            },
            {
              title: "Onboard",
              field: "status",
              customCell: (id, field, row) => <OnboardingStatus id={row._id} applicant={row} />,
            },
          ]
        : [
            // { title: "Rank", field: "rank" },
            {
              title: "Signup Date",
              field: "hireDate",
              customCell: (date, field, row) => {
                const obj = getSignupDate(row?._id);
                const dt = obj?.signupDate;
                const agent = obj?.agent;
                if (dt)
                  return (
                    <Tooltip title={`Agent: ${agent}`}>
                      <span>{moment(dt).format("MM/DD/YYYY hh:mm a")}</span>
                    </Tooltip>
                  );
                return "";
              },
            },
            {
              title: "Position",
              field: "primaryPosition",
              customCell: (position, field, row) => (
                <>
                  <RosterPosition
                    id={row?._id}
                    eventApplicants={rosterApplicants?.result}
                    row={field}
                    currentEvent={currentEvent}
                    toggleReadRoster={toggleReadRoster}
                  />
                </>
              ),
            },
          ]),

      ...(currentEvent?.eventType === "Event" && eventDay && showTimeColumns
        ? [
            {
              title: "Time in",
              field: "timeIn",
              customCell: (date, field, row) => (
                <>
                  <MDBox width="7rem">
                    <TimeInOut
                      id={row?._id}
                      field="timeIn"
                      currentEvent={currentEvent}
                      eventDate={eventDate}
                      eventTime={eventTime}
                      rosterObj={
                        // !rosterLoading &&
                        rosterApplicants?.result?.find((item) => item.id === row._id)
                      }
                      disabled={!isOnRoster(row._id)}
                      timeZone={currentEvent?.timeZone}
                      clockInOut={(e, rosterObj) => clockInOut(e, row._id, field, rosterObj)}
                      clockInOutAtEventTime={(e, rosterObj) =>
                        clockInOutAtEventTime(e, row._id, field, rosterObj)
                      }
                    />
                  </MDBox>
                </>
              ),
            },
            {
              title: "Time out",
              field: "timeOut",
              customCell: (date, field, row) => (
                <>
                  <MDBox width="7rem">
                    {/* <Tooltip title="Clock Out"> */}
                    <TimeInOut
                      id={row?._id}
                      field="timeOut"
                      currentEvent={currentEvent}
                      eventDate={eventDate}
                      eventTime={eventTime}
                      rosterObj={
                        // !rosterLoading &&
                        rosterApplicants?.result?.find((item) => item.id === row._id)
                      }
                      // checks if there is a time in value before enabling
                      disabled={
                        !(
                          isOnRoster(row._id) &&
                          rosterApplicants?.result?.find((item) => item.id === row._id)?.timeIn
                        )
                      }
                      timeZone={currentEvent?.timeZone}
                      eventEndTime={currentEvent?.eventEndTime}
                      clockInOut={(e, rosterObj) => clockInOut(e, row._id, field, rosterObj)}
                    />
                    {/* </Tooltip> */}
                  </MDBox>
                </>
              ),
            },
            {
              title: "Flag",
              field: "_id",
              customCell: (id) => {
                const rosterRec = getEmployeeFlag(id);
                return (
                  <span>
                    {rosterRec?.flag === "Yes" ? (
                      <Tooltip title={rosterRec?.flagTooltip ? rosterRec.flagTooltip : "Flag"}>
                        <FlagIcon
                          fontSize="large"
                          color={rosterRec?.flagColor ? rosterRec?.flagColor : "warning"}
                        />
                      </Tooltip>
                    ) : null}
                  </span>
                );
              },
            },
          ]
        : []),
      {
        title: "Actions",
        field: "action",
        customCell: (id, field, row) => (
          <EventsTimeActions
            id={id}
            row={row}
            currentEvent={currentEvent}
            setCurrentEvent={setCurrentEvent}
            setModalInfo={setModalInfo}
            // setNavigateUrl={setNavigateUrl}
            setGenericModalOpen={setGenericModalOpen}
          />
        ),
      },
    ],
    [eventUrl, currentEvent, appLoading, rosterApplicants?.result, rosterIds, showTimeColumns]
  );

  return (
    <>
      {" "}
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={{ data: apps?.data, count: apps?.data?.length }}
            // onRowClick={(event) => setCurrentEvent(event)}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            fetchAll
            defaultSort={defaultSort}
            isLoading={appLoading} // Extract isLoading from useQuery then pass here (required)
            greybar
            divider
            idField="email"
          />
          {/* {JSON.stringify(rosterApplicants?.result?.map(item =>
            ({ id: item.id, timeIn: item.timeOut })))} */}
        </MDBox>
        <EventActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setModalInfo={setModalInfo}
          setToastAlert={setToastAlert}
          toggleReadApps={toggleReadApps}
        />
      </SnackbarProvider>
    </>
  );
};
EventsTimeRoster.defaultProps = {
  name: "partners",
  showFiltersList: false,
};

// Typechecking props
EventsTimeRoster.propTypes = {
  name: PropTypes.string,
  showFiltersList: PropTypes.bool,
};

export default EventsTimeRoster;
