import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
  Chip,
  IconButton,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

const defaultFieldValues = {
  id: "",
  name: "",
  type: "input",
  placeholder: "",
  defaultValue: "",
  required: false,
  hidden: false,
  options: [],
  validation: {},
  tooltip: "",
  readOnly: false,
  conditionalDisplay: null,
};

const FormFieldModal = ({ open, onClose, field, onSave }) => {
  const [formValues, setFormValues] = useState(defaultFieldValues);
  const [newOption, setNewOption] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (field) {
      setFormValues({
        ...defaultFieldValues,
        ...field,
      });
    } else {
      setFormValues(defaultFieldValues);
    }
  }, [field, open]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    
    if (name === "type" && ["input", "textarea", "date", "paragraph", "heading", "signature"].includes(value)) {
      // Reset options when changing to a type that doesn't use them
      setFormValues({
        ...formValues,
        [name]: value,
        options: [],
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: e.target.type === "checkbox" ? checked : value,
      });
    }
  };

  const handleAddOption = () => {
    if (!newOption.trim()) {
      setErrors({ ...errors, options: "Option cannot be empty" });
      return;
    }

    if (formValues.options.includes(newOption)) {
      setErrors({ ...errors, options: "Option already exists" });
      return;
    }

    setFormValues({
      ...formValues,
      options: [...formValues.options, newOption],
    });
    setNewOption("");
    setErrors({ ...errors, options: null });
  };

  const handleRemoveOption = (index) => {
    setFormValues({
      ...formValues,
      options: formValues.options.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = () => {
    // Validate form
    const newErrors = {};
    
    if (!formValues.name && !["paragraph", "heading", "divider"].includes(formValues.type)) {
      newErrors.name = "Name is required";
    }
    
    if (["select", "dropdown", "radio", "checkbox"].includes(formValues.type) && 
        (!formValues.options || formValues.options.length === 0)) {
      newErrors.options = "At least one option is required";
    }
    
    if (["paragraph", "heading"].includes(formValues.type) && !formValues.content) {
      newErrors.content = "Content is required";
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    // Save the form
    onSave(formValues);
    onClose();
  };

  const renderTypeSpecificFields = () => {
    switch (formValues.type) {
      case "paragraph":
      case "heading":
        return (
          <>
            <TextField
              fullWidth
              label="Content"
              name="content"
              value={formValues.content || ""}
              onChange={handleChange}
              multiline={formValues.type === "paragraph"}
              rows={formValues.type === "paragraph" ? 4 : 1}
              margin="normal"
              error={!!errors.content}
              helperText={errors.content}
            />
            
            {formValues.type === "heading" && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Heading Level</InputLabel>
                <Select
                  name="level"
                  value={formValues.level || 6}
                  onChange={handleChange}
                  label="Heading Level"
                >
                  <MenuItem value={1}>H1</MenuItem>
                  <MenuItem value={2}>H2</MenuItem>
                  <MenuItem value={3}>H3</MenuItem>
                  <MenuItem value={4}>H4</MenuItem>
                  <MenuItem value={5}>H5</MenuItem>
                  <MenuItem value={6}>H6</MenuItem>
                </Select>
              </FormControl>
            )}
          </>
        );
        
      case "select":
      case "dropdown":
      case "radio":
      case "checkbox":
        return (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Options
            </Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                fullWidth
                label="Add Option"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                error={!!errors.options}
                helperText={errors.options}
              />
              <IconButton color="primary" onClick={handleAddOption}>
                <AddIcon />
              </IconButton>
            </Box>
            <Box display="flex" flexWrap="wrap" gap={1}>
              {formValues.options.map((option) => (
                <Chip
                  key={`option-${option}`}
                  label={option}
                  onDelete={() => handleRemoveOption(formValues.options.indexOf(option))}
                  color="primary"
                  variant="outlined"
                />
              ))}
            </Box>
          </Box>
        );
        
      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {field ? "Edit Field" : "Add New Field"}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Field Type</InputLabel>
              <Select
                name="type"
                value={formValues.type}
                onChange={handleChange}
                label="Field Type"
              >
                <MenuItem value="input">Text Input</MenuItem>
                <MenuItem value="textarea">Text Area</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="select">Dropdown</MenuItem>
                <MenuItem value="checkbox">Checkbox</MenuItem>
                <MenuItem value="radio">Radio Group</MenuItem>
                <MenuItem value="currency">Currency</MenuItem>
                <MenuItem value="paragraph">Paragraph</MenuItem>
                <MenuItem value="heading">Heading</MenuItem>
                <MenuItem value="divider">Divider</MenuItem>
                <MenuItem value="signature">Signature</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          {!["paragraph", "heading", "divider"].includes(formValues.type) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Field Name"
                name="name"
                value={formValues.name || ""}
                onChange={handleChange}
                margin="normal"
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
          )}
          
          {!["paragraph", "heading", "divider", "checkbox", "radio"].includes(formValues.type) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Placeholder"
                name="placeholder"
                value={formValues.placeholder || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
          )}
          
          {!["paragraph", "heading", "divider"].includes(formValues.type) && (
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Default Value"
                name="defaultValue"
                value={formValues.defaultValue || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
          )}
          
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.required || false}
                    onChange={handleChange}
                    name="required"
                  />
                }
                label="Required"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.hidden || false}
                    onChange={handleChange}
                    name="hidden"
                  />
                }
                label="Hidden"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.readOnly || false}
                    onChange={handleChange}
                    name="readOnly"
                  />
                }
                label="Read Only"
              />
            </FormGroup>
          </Grid>
          
          <Grid item xs={12}>
            {renderTypeSpecificFields()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormFieldModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  field: PropTypes.object,
  onSave: PropTypes.func.isRequired,
};

FormFieldModal.defaultProps = {
  field: null,
};

export default FormFieldModal; 