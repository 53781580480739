import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  titleBar: { marginBottom: "15px" },

  titleBar_title: { my: 1, ml: 2 },

  section_images_wrapper: {
    overflowX: "auto",
    paddingTop: 10,
    whiteSpace: "nowrap",
  },

  positioner: {
    position: "absolute",
  },

  section_image_container: {
    display: "inline-block",
    position: "relative",
    cursor: "pointer",
  },
  section_image_container2: {
    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
  },

  training_section_image_container: {
    display: "inline-block",
    position: "relative",
    margin: "0px 8px",
    cursor: "pointer",
  },

  section_image: {
    height: "100px",
  },

  training_section_image_delete: {
    position: "relative",
    left: "1000%",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  maps_section_image_delete: {
    position: "absolute",
    left: "150px",
    top: "-15px",
    backgroundColor: "white",
    padding: 5,
  },

  section_image_delete: {
    position: "absolute",
    right: "-9px",
    top: "-9px",
    backgroundColor: "#fff",
    width: "15px",
    height: "15px",
  },

  otherSection: {
    marginBottom: "24px",
  },
  section_images_wapper: {
    overflowX: "hidden",
    whiteSpace: "nowrap",
    padding: "24px 0px 24px 0px",
    maxWidth: "100%",
  },
  error: {
    fontSize: "0.75rem",
  },
  card: {
    height: 350,
  },
  stack: {
    padding: 10,
  },
  editor: {
    height: 100,
  },
  box: {
    overflow: "visible",
  },
  addButton: {
    fontSize: 14,
    marginRight: 15,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",
    border: "1px dashed #C9C9C9",
    padding: "20px 20px",
    cursor: "pointer",
    borderRadius: "4px",
  },
});

export default useStyles;
