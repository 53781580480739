import { baseAxios } from "config";

const getAllForms = async () => {
  try {
    const res = await baseAxios.get(`/llm/dynamicForms`);
    return res.data;
  } catch (err) {
    if (err.message === "Request failed with status code 404") return [];
    throw new Error(err.toString());
  }
};

export default getAllForms;
