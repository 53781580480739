import { IMG_SERVER } from "config";

export const getCompanyImageUrl = (company) => {
  if (IMG_SERVER && company?.uploadPath) {
    return `${IMG_SERVER}/${company?.uploadPath}`;
  }
  return company?.imageUrl;
};

export const getCompanyBaseImageUrl = (company, defaultCompany) => {
  if (getCompanyImageUrl(company) && company?.slug) {
    return `${getCompanyImageUrl(company)}/company/${company?.slug}`;
  }
  if (getCompanyImageUrl(defaultCompany) && defaultCompany?.slug) {
    return `${getCompanyImageUrl(defaultCompany)}/company/${defaultCompany?.slug}`;
  }
  return null;
};

export const getCommonBaseImageUrl = (company, defaultCompany) => {
  if (getCompanyImageUrl(company) && company?.uploadUrl) {
    return getCompanyImageUrl(company).replace(`/${company.uploadUrl}`, "/common");
  }
  if (getCompanyImageUrl(defaultCompany) && defaultCompany?.uploadUrl) {
    return getCompanyImageUrl(defaultCompany).replace(`/${defaultCompany.uploadUrl}`, "/common");
  }
  return null;
};
