import { Close } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Chip,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import { useQuery } from "react-query";
import { getAssessmentById } from "api/assessments/getAssessmentById";
import CircularLoader from "components/CircularLoader";
import { useSnackbar } from "notistack";
import fetchSingleJob from "layouts/pages/jobs/actions/fetchSingleJob";
import { getAssessmentResultsUrl } from "api/assessments/getAssessmentResultsUrl";

const ExamDetailsModal = ({
  open,
  onClose,
  applicantAssessment,
  assessment,
  applicantId,
  applicant,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  // If assessment is not passed, fetch it
  const {
    data: queryAssessment,
    isLoading,
    refetch,
  } = useQuery(
    ["assessmentDetails", applicantAssessment?.assessmentId],
    () => {
      return getAssessmentById(applicantAssessment?.assessmentId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !assessment && !!applicantAssessment?.assessmentId,
      onError: (e) => {
        if (e.response?.data?.error.includes("not found")) {
          enqueueSnackbar(`Error: Assessment not found`, { variant: "error" });
        } else {
          enqueueSnackbar(
            `Something went wrong! ${
              e.response?.data?.error ?? e.response?.data?.message ?? e.message ?? e.toString()
            }`,
            { variant: "error" }
          );
        }
      },
    }
  );

  const examDetails = assessment
    ? {
        ...assessment,
        ...applicantAssessment,
      }
    : {
        ...(queryAssessment?.data ?? {}),
        ...applicantAssessment,
      };

  const {
    data: queryAssessmentUrl,
    isLoading: isLoadingUrl,
    refetch: refetchUrl,
  } = useQuery(
    ["assessmentUrl", applicantId, examDetails?.score?.attachment?.filename],
    () => {
      return getAssessmentResultsUrl(applicantId, examDetails?.score?.attachment?.filename);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!applicantId && !!examDetails?.score?.attachment?.filename,
    }
  );

  const {
    data: queryJob,
    isLoading: isLoadingJob,
    refetch: refetchJob,
  } = useQuery(
    ["jobDetails", examDetails?.jobSlug],
    () => {
      return fetchSingleJob(examDetails?.jobSlug);
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!examDetails?.jobSlug,
    }
  );

  const formatTime = (dateString) => {
    if (!dateString) return "";
    return moment(dateString).format("hh:mm a");
  };

  const formatDuration = (totalSeconds, startDate, endDate, isExcelAssessment) => {
    if (isExcelAssessment && startDate && endDate) {
      // Convert start and end time to Date objects
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Calculate the difference in seconds
      const diffInSeconds = Math.floor((end - start) / 1000);
      const minutes = Math.floor(diffInSeconds / 60);
      const seconds = diffInSeconds % 60;

      // Format output like "MM:SS minutes"
      return `${minutes}:${String(seconds).padStart(2, "0")} minutes`;
    }

    if (!totalSeconds) return "0:00 minutes"; // Adjusted to match the format

    // Convert totalSeconds to minutes and seconds
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}:${String(seconds).padStart(2, "0")} minutes`;
  };

  const formatDate = (date) => {
    if (!date) return "";

    return moment(date).format("MMMM d, yyyy");
  };

  const processQuestionDetails = (question, result) => {
    if (!result) {
      return {
        text: question.text,
        type: question.type,
        skillsMeasured: question.skillsMeasured || [],
        candidateAnswer: "Not answered",
        isCorrect: false,
        correctAnswer:
          question.type === "Multiple Choice"
            ? question.answers.find((a) => a.isRecommended)?.answerText
            : question.idealAnswer,
        recommendedSkills: [],
        elapsedTime: 0,
      };
    }

    return {
      text: question.text || result.questionText,
      type: result.type,
      skillsMeasured: question.skillsMeasured || [],
      candidateAnswer: result.userAnswer,
      isCorrect: result.isCorrect,
      correctAnswer: result.recommendedAnswer,
      similarity: result.similarity,
      explanation: result.explanation,
      elapsedTime:
        examDetails.timeIntervals.find((t) => t.questionId.toString() === question?._id)
          ?.elapsedTime || 0,
      recommendedSkills:
        question.type === "Multiple Choice"
          ? question.answers
              .find((a) => a.isRecommended)
              ?.skillMeasured?.split(",")
              .map((s) => s.trim()) || []
          : question.skillsMeasured || [],
    };
  };

  const isExcelAssessment = examDetails?.questions?.length === 0;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 900,
          bgcolor: "background.paper",
          boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
          borderRadius: 3,
          p: 4,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <Stack spacing={4}>
          {isLoading || isLoadingJob || isLoadingUrl ? (
            <Box
              sx={{
                alignItems: "center",
                justifyContent: "center",
                padding: 4,
              }}
            >
              <CircularLoader
                hasBackdrop={false}
                isLoading={isLoading || isLoadingJob || isLoadingUrl}
                color="info"
              />
            </Box>
          ) : (
            <>
              {/* Header with Score */}
              <Box sx={{ textAlign: "center", position: "relative" }}>
                <IconButton onClick={onClose} sx={{ position: "absolute", right: 0, top: 0 }}>
                  <Close />
                </IconButton>

                <Typography variant="h4" fontWeight={700} color="info.dark" mb={3}>
                  Assessment Results
                </Typography>
                {examDetails?.score?.attachment && (
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      component="a"
                      href={queryAssessmentUrl?.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: "info.main",
                        textDecoration: "none",
                        display: "inline-flex",
                        alignItems: "center",
                        gap: 0.5,
                        fontSize: "0.875rem",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      <PictureAsPdfIcon /> View PDF Report
                    </Typography>
                  </Box>
                )}
                <Typography
                  variant="h2"
                  color={
                    examDetails?.score?.details?.totalScore >= 70 ? "success.main" : "error.main"
                  }
                  fontWeight={800}
                >
                  {examDetails?.score?.details?.totalScore}%
                </Typography>
              </Box>

              <Paper spacing={3} elevation={0}>
                {/* Info Cards */}
                <Grid container spacing={3}>
                  {/* Assessment Info */}
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: "100%",
                        bgcolor: "grey.50",
                        border: "1px solid",
                        borderColor: "grey.200",
                      }}
                    >
                      <Typography variant="h6" fontWeight={600} color="info.dark" mb={2}>
                        Assessment Details
                      </Typography>
                      <Stack spacing={2}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Assessment Name
                          </Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {examDetails?.assessmentName}
                          </Typography>
                        </Box>

                        {applicant?.firstName && applicant?.lastName && (
                          <Box>
                            <Typography variant="body2" color="text.secondary">
                              Candidate Name
                            </Typography>
                            <Typography variant="body1" fontWeight={500}>
                              {[applicant?.firstName, applicant?.lastName].join(" ")}
                            </Typography>
                          </Box>
                        )}

                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Position
                          </Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {queryJob?.data?.job?.title || "--"}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Date
                          </Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {formatDate(examDetails?.attemptDate)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Duration
                          </Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {formatTime(examDetails?.startDate)} -{" "}
                            {formatTime(examDetails?.endDate)}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Total Time (Minutes)
                          </Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {formatDuration(
                              examDetails?.totalElapsedTime,
                              examDetails?.startDate,
                              examDetails?.endDate,
                              isExcelAssessment
                            )}
                          </Typography>
                        </Box>
                      </Stack>
                    </Paper>
                  </Grid>

                  {/* Summary Stats */}
                  <Grid item xs={12} md={6}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: 3,
                        height: "100%",
                        bgcolor: "grey.50",
                        border: "1px solid",
                        borderColor: "grey.200",
                      }}
                    >
                      <Typography variant="h6" fontWeight={600} color="info.dark" mb={2}>
                        Summary
                      </Typography>
                      <Grid container spacing={2}>
                        {isExcelAssessment ? (
                          // Excel Assessment Summary
                          <>
                            <Grid item xs={6}>
                              <Paper sx={{ p: 2, textAlign: "center" }}>
                                <Typography variant="h4" fontWeight={700} color="info.main">
                                  4
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Total Categories
                                </Typography>
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              <Paper sx={{ p: 2, textAlign: "center" }}>
                                <Typography
                                  variant="h4"
                                  fontWeight={700}
                                  color={
                                    examDetails?.score?.details?.totalScore >= 70
                                      ? "success.main"
                                      : "error.main"
                                  }
                                >
                                  {examDetails?.score?.details?.totalScore}%
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Score
                                </Typography>
                              </Paper>
                            </Grid>
                          </>
                        ) : (
                          // Regular Assessment Summary
                          <>
                            <Grid item xs={6}>
                              <Paper sx={{ p: 2, textAlign: "center" }}>
                                <Typography variant="h4" fontWeight={700} color="info.main">
                                  {examDetails?.score?.details?.totalQuestions}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Total Questions
                                </Typography>
                              </Paper>
                            </Grid>
                            <Grid item xs={6}>
                              <Paper sx={{ p: 2, textAlign: "center" }}>
                                <Typography variant="h4" fontWeight={700} color="info.main">
                                  {examDetails?.score?.details?.answeredQuestions}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  Answered
                                </Typography>
                              </Paper>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>

              {/* Skills Table */}
              {!isExcelAssessment && (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    border: "1px solid",
                    borderColor: "grey.200",
                  }}
                >
                  <Typography variant="h6" fontWeight={600} color="info.dark" mb={3}>
                    Skills Assessment
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600, fontSize: "1rem" }}>Skill</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Selected
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Recommended
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Total
                          </TableCell>
                          <TableCell align="center" sx={{ fontWeight: 600, fontSize: "1rem" }}>
                            Performance
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(examDetails?.score?.skillsTally || {}).map(
                          ([skill, data]) => {
                            const performance =
                              data.total === 0 ? 0 : (data.selected / data.total) * 100;
                            return (
                              <TableRow key={skill} hover>
                                <TableCell sx={{ fontWeight: 500 }}>{skill}</TableCell>
                                <TableCell align="center">{data.selected}</TableCell>
                                <TableCell align="center">{data.recommended}</TableCell>
                                <TableCell align="center">{data.total}</TableCell>
                                <TableCell align="center">
                                  <Chip
                                    label={`${performance.toFixed(1)}%`}
                                    color={performance >= 70 ? "success" : "error"}
                                    variant="outlined"
                                    sx={{ minWidth: 80 }}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}

              {isExcelAssessment && (
                <>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      border: "1px solid",
                      borderColor: "grey.200",
                    }}
                  >
                    <Typography variant="h6" fontWeight={600} color="info.dark" mb={3}>
                      Score Breakdown
                    </Typography>
                    <Grid container spacing={3}>
                      {Object.entries(examDetails?.score?.details?.scores || {}).map(
                        ([category, data]) => (
                          <Grid item xs={12} md={6} key={category}>
                            <Box
                              sx={{
                                background: "#f8f9fa",
                                p: 2.5,
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  mb: 2,
                                  color: "info.dark",
                                  textTransform: "capitalize",
                                }}
                              >
                                {category}
                              </Typography>
                              <Typography
                                variant="h4"
                                fontWeight={700}
                                color={data.score >= 0.7 ? "success.main" : "error.main"}
                                sx={{ mb: 1.5 }}
                              >
                                {(data.score * 100).toFixed(1)}%
                              </Typography>
                              <Stack spacing={0.5}>
                                {data?.details?.map((detail, idx) => (
                                  <Typography
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={idx}
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <Box component="span" sx={{ color: "text.secondary" }}>
                                      •
                                    </Box>
                                    {detail}
                                  </Typography>
                                ))}
                              </Stack>
                            </Box>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Paper>

                  {/* Skills Assessment */}
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      border: "1px solid",
                      borderColor: "grey.200",
                    }}
                  >
                    <Typography variant="h6" color="info.dark" sx={{ mb: 3 }}>
                      Skills Assessment
                    </Typography>
                    <Grid container spacing={2}>
                      {Object.entries(examDetails?.score?.details?.skills || {}).map(
                        ([skill, score]) => (
                          <Grid item xs={12} sm={6} key={skill}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                p: 2,
                                bgcolor: "grey.50",
                                borderRadius: 1,
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "info.main",
                                  fontWeight: 500,
                                }}
                              >
                                {skill
                                  .split("_")
                                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                  .join(" ")}
                              </Typography>
                              <Box
                                sx={{
                                  px: 2,
                                  py: 0.5,
                                  borderRadius: "pill",
                                  bgcolor: score >= 0.7 ? "success.lighter" : "error.lighter",
                                  color: score >= 0.7 ? "success.main" : "error.main",
                                }}
                              >
                                {(score * 100).toFixed(1)}%
                              </Box>
                            </Box>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Paper>

                  {/* Feedback */}
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      border: "1px solid",
                      borderColor: "grey.200",
                    }}
                  >
                    <Typography variant="h6" color="info.main" sx={{ mb: 3 }}>
                      Feedback
                    </Typography>

                    {/* Strengths */}
                    <Box sx={{ mb: 3 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "success.main",
                          mb: 2,
                        }}
                      >
                        Strengths
                      </Typography>
                      {examDetails?.score?.details?.feedback?.strengths.map((strength, idx) => (
                        <Box
                          // eslint-disable-next-line react/no-array-index-key
                          key={idx}
                          sx={{
                            backgroundColor: "#4caf50",
                            color: "white",
                            p: 2,
                            mb: 1,
                            borderRadius: 1,
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Typography>•</Typography>
                          <Typography>{strength}</Typography>
                        </Box>
                      ))}
                    </Box>

                    {/* Areas for Improvement */}
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "error.main",
                          mb: 2,
                        }}
                      >
                        Areas for Improvement
                      </Typography>
                      {examDetails?.score?.details?.feedback?.improvements.map(
                        (improvement, idx) => (
                          <Box
                            // eslint-disable-next-line react/no-array-index-key
                            key={idx}
                            sx={{
                              backgroundColor: "#f44336",
                              color: "white",
                              p: 2,
                              mb: 1,
                              borderRadius: 1,
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Typography>•</Typography>
                            <Typography>{improvement}</Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  </Paper>
                </>
              )}

              {!isExcelAssessment && (
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    border: "1px solid",
                    borderColor: "grey.200",
                  }}
                >
                  <Typography variant="h6" fontWeight={600} color="info.dark" mb={3}>
                    Question Details
                  </Typography>
                  <Stack spacing={3}>
                    {examDetails?.questions
                      ?.filter((question) => {
                        const questionId =
                          question._id?.$oid || question._id || question._id?.toString();
                        const result = examDetails?.score?.details.questionResults[questionId];
                        return result && result.userAnswer && result.userAnswer !== "Not answered";
                      })
                      .map((question, index) => {
                        const questionId =
                          question._id?.$oid || question._id || question._id?.toString();
                        const rawResult = examDetails?.score?.details.questionResults[questionId];
                        const processedQuestion = processQuestionDetails(question, rawResult);

                        return (
                          <Paper
                            key={questionId}
                            elevation={0}
                            sx={{
                              p: 3,
                              bgcolor: "grey.50",
                              borderLeft: 4,
                              borderColor: processedQuestion.isCorrect
                                ? "success.main"
                                : "error.main",
                            }}
                          >
                            <Stack spacing={2}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography variant="h6" fontWeight={600}>
                                  Question {index + 1}
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  {processedQuestion.type === "Fill-In" &&
                                    processedQuestion.similarity && (
                                      <Typography variant="body2" color="text.secondary">
                                        Similarity: {processedQuestion.similarity}
                                        /10
                                      </Typography>
                                    )}
                                  <Chip
                                    label={processedQuestion.isCorrect ? "Correct" : "Incorrect"}
                                    color={processedQuestion.isCorrect ? "success" : "error"}
                                    size="small"
                                  />
                                </Box>
                              </Box>
                              <Typography variant="body1">{processedQuestion.text}</Typography>
                              <Box
                                sx={{
                                  bgcolor: "background.paper",
                                  p: 2,
                                  borderRadius: 1,
                                }}
                              >
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                  Your Answer:
                                </Typography>
                                <Typography
                                  fontWeight={500}
                                  color={
                                    processedQuestion.isCorrect ? "success.main" : "error.main"
                                  }
                                >
                                  {processedQuestion.candidateAnswer}
                                </Typography>
                                {(!processedQuestion.isCorrect ||
                                  processedQuestion.type === "Fill-In") && (
                                  <Box
                                    sx={{
                                      mt: 2,
                                      pt: 2,
                                      borderTop: 1,
                                      borderColor: "grey.200",
                                    }}
                                  >
                                    <Typography variant="body2" color="text.secondary" gutterBottom>
                                      {processedQuestion.type === "Fill-In"
                                        ? "Expected Answer:"
                                        : "Recommended Answer:"}
                                    </Typography>
                                    <Typography fontWeight={500} color="success.main">
                                      {processedQuestion.correctAnswer}
                                    </Typography>
                                    {processedQuestion.type === "Fill-In" &&
                                      processedQuestion.explanation && (
                                        <Box sx={{ mt: 1 }}>
                                          <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            gutterBottom
                                          >
                                            Explanation:
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              bgcolor: "grey.100",
                                              p: 1,
                                              borderRadius: 1,
                                              fontStyle: "italic",
                                            }}
                                          >
                                            {processedQuestion.explanation}
                                          </Typography>
                                        </Box>
                                      )}
                                  </Box>
                                )}
                              </Box>
                            </Stack>
                          </Paper>
                        );
                      })}
                  </Stack>
                </Paper>
              )}
            </>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ExamDetailsModal;
