import axios from "axios";
import { baseAxios } from "config";

const exportUserActivity = async ({
  userId,
  period = "week",
  startDate,
  endDate,
  fileType: type,
}) => {
  try {
    let baseUrl = `/users/activity-report-export/${userId}/${period}`;

    // If custom date range is provided, add as query parameters
    if (period === "custom" && startDate && endDate) {
      baseUrl += `?startDate=${startDate}&endDate=${endDate}`;
    }

    const res = await baseAxios.post(
      baseUrl,
      { fileType: type },
      {
        responseType: "blob",
      }
    );
    const blob = new Blob([res.data], { type: res.headers["content-type"] });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    const fileType = type === "csv" ? "csv" : "xlsx";
    link.setAttribute("download", `report.${fileType}`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);
  } catch (err) {
    throw new Error(err.toString());
  }
};

export default exportUserActivity;
