import TableAction from "components/TableAction";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const AssessmentAssignApplicantActions = ({
  row,
  applicant,
  onOpenAssessmentResultsModal,
  onSendInvitation,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const navigateLocal = navigate;

  const onClickInfo = (e) => {
    e.stopPropagation();
    navigateLocal(`/assessments/${row?._id}/action/assessmentinfo`);
  };

  const onClickCopyURL = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(
      `${window.location.protocol}//${window.location.host}/render-assessment/${row._id}/applicants/${applicant?._id}`
    );
    enqueueSnackbar("Assessment link copied.", { variant: "success" });
  };

  const applicantAssessment = useMemo(() => {
    if (applicant?.assessments?.length) {
      const currentAs = applicant.assessments.find(
        (apAs) => apAs.endDate && apAs.assessmentId === row?._id
      );
      return currentAs;
    }
    return null;
  }, [applicant?.assessments, row?._id]);

  const onClickResults = (e) => {
    e.stopPropagation();
    onOpenAssessmentResultsModal(applicant, applicantAssessment);
  };

  const onClickSendInvitation = (e) => {
    e.stopPropagation();
    onSendInvitation(row, applicant);
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickInfo(e)}
        tooltip="Assessment Info"
        iconStyle={{ margin: 0 }}
      />
      <TableAction
        color="info"
        icon="copy"
        clickAction={(e) => onClickCopyURL(e)}
        tooltip="Copy assessment link"
        iconStyle={{ margin: 0 }}
      />
      <TableAction
        color="info"
        icon="content_paste_search"
        clickAction={(e) => onClickResults(e)}
        disabled={!applicantAssessment}
        tooltip={!applicantAssessment ? "No results available" : "View results"}
        iconStyle={{ margin: 0 }}
      />
      <TableAction
        color="info"
        icon="mail"
        clickAction={(e) => onClickSendInvitation(e)}
        tooltip="Send invitation"
        iconStyle={{ margin: 0 }}
      />
    </span>
  );
};

AssessmentAssignApplicantActions.defaultProps = {};

// Typechecking props for the TableAction
AssessmentAssignApplicantActions.propTypes = {};

export default AssessmentAssignApplicantActions;
