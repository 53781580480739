import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Grid,
  Autocomplete,
  Checkbox,
  Card,
  Typography,
} from "@mui/material";
import LandscapeIcon from "@mui/icons-material/Landscape";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useCallback, useEffect, useRef, useState } from "react";
import useDropdowns from "hooks/useDropdowns";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useAppContextController } from "context/AppContext";
import CustomTextField from "components/Form/CustomTextField";
import FormErrors from "components/Form/FormErrors";
import MDTypography from "components/MDTypography";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { useNewApplicantContext } from "context/NewApplicantContext";
import { i9EmployerSchema } from "data/i9Employer";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useCompanyCache, { useOutsideCompanyCache } from "hooks/useCompanyCache";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import ConfirmationModal from "components/ConfirmationModal";
import { getCompanyImageUrl } from "utils/helpers/upload";

const EmployerI9Form = () => {
  const {
    applicant,
    updateApplicantAction,
    updateButtons,
    buttonState,
    updateCurrentFormState,
    currentFormState,
  } = useNewApplicantContext();
  const resolver = useYupValidationResolver(i9EmployerSchema);
  const { user, company, setCompany, setCompanyType, currentLoggedUser } =
    useAppContextController();
  const IMAGE_SERVER = getCompanyImageUrl(company);
  const [isConfirmationOpen, toggleConfirmationModal] = useState(false);
  const { isLoadingCompany, refetchCompany } = useOutsideCompanyCache({
    company,
    setCompany,
    setCompanyType,
    outsideMode: user ? "" : "protected",
  });
  const [usersNames, setUsersNames] = useState();
  const [currentRepresentativeApplicant, setCurrentRepresentativeApplicant] = useState(null);
  const listBFiltered = useRef([]);

  const options = { fetchAll: true };
  const allOptionsUsers = {
    ...options,
    filters: { userType: "Master;Admin" },
    outsideMode: user ? "" : "protected",
  };

  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );

  const defaultValues = {
    listADocumentTitle: applicant?.employerI9Form?.listADocument?.title || null,
    listAIssuingAuthority: applicant?.employerI9Form?.listADocument?.issuingAuthority || null,
    listAIssuingAuthorityState:
      applicant?.employerI9Form?.listADocument?.issuingAuthorityState || null,
    listADocumentNumber: applicant?.employerI9Form?.listADocument?.number || null,
    listAExpirationDate: applicant?.employerI9Form?.listADocument?.expirationDate || null,
    listBDocumentTitle: applicant?.employerI9Form?.listBDocument?.title || null,
    listBIssuingAuthority: applicant?.employerI9Form?.listBDocument?.issuingAuthority || null,
    listBIssuingAuthorityState:
      applicant?.employerI9Form?.listBDocument?.issuingAuthorityState || null,
    listBDocumentNumber: applicant?.employerI9Form?.listBDocument?.number || null,
    listBExpirationDate: applicant?.employerI9Form?.listBDocument?.expirationDate || null,
    listCDocumentTitle: applicant?.employerI9Form?.listCDocument?.title || null,
    listCIssuingAuthority: applicant?.employerI9Form?.listCDocument?.issuingAuthority || null,
    listCIssuingAuthorityState:
      applicant?.employerI9Form?.listCDocument?.issuingAuthorityState || null,
    listCDocumentNumber: applicant?.employerI9Form?.listCDocument?.number || null,
    listCExpirationDate: applicant?.employerI9Form?.listCDocument?.expirationDate || null,
    firstDayOfEmployment: applicant?.employerI9Form?.firstDayOfEmployment || null,
    reverificationOrRehire: applicant?.employerI9Form?.reverificationOrRehire || false,
    authorizedRepresentative: {
      title:
        applicant?.employerI9Form?.authorizedRepresentative?.title || currentLoggedUser?.jobTitle,
      lastName:
        applicant?.employerI9Form?.authorizedRepresentative?.lastName ||
        currentLoggedUser?.lastName,
      firstName:
        applicant?.employerI9Form?.authorizedRepresentative?.firstName ||
        currentLoggedUser?.firstName,
      signature:
        applicant?.employerI9Form?.authorizedRepresentative?.signature ||
        currentLoggedUser?.signature,
      applicantId:
        applicant?.employerI9Form?.authorizedRepresentative?.applicantId ||
        currentLoggedUser?.applicantId,
      organizationName:
        applicant?.employerI9Form?.authorizedRepresentative?.organizationName ||
        currentLoggedUser?.organization ||
        company?.name,
      organizationAddress:
        applicant?.employerI9Form?.authorizedRepresentative?.organizationAddress ||
        company?.address,
      city: applicant?.employerI9Form?.authorizedRepresentative?.city || company?.city,
      state: applicant?.employerI9Form?.authorizedRepresentative?.state || company?.state,
      zip: applicant?.employerI9Form?.authorizedRepresentative?.zip || company?.zip,
    },
  };

  const [representativeFullName, setRepresentativeFullName] = useState({
    ...defaultValues?.authorizedRepresentative,
    label: `${defaultValues?.authorizedRepresentative?.firstName} ${defaultValues?.authorizedRepresentative?.lastName}`,
  });
  const [signature, setSignature] = useState(
    representativeFullName?.signature && currentRepresentativeApplicant?._id ? (
      <Card>
        <img
          src={`${IMAGE_SERVER}/applicants/${currentRepresentativeApplicant?._id}/signature/${representativeFullName?.signature}`}
          alt="signature"
          height={40}
          width={120}
          style={{ width: "100%" }}
        />
      </Card>
    ) : (
      <Typography
        sx={{ fontStyle: "italic" }}
      >{`${representativeFullName?.firstName} ${representativeFullName?.lastName} Signature`}</Typography>
    )
  );

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    trigger,
    setError,
    formState: { errors, isDirty, isValid, isSubmitSuccessful },
  } = useForm({ resolver });

  const { dropdowns: listADocumentOptions } = useDropdowns({
    entity: "listADocumentOptions",
    outsideMode: user ? "" : "protected",
  });
  const { dropdowns: listBDocumentOptions } = useDropdowns({
    entity: "listBDocumentOptions",
    outsideMode: user ? "" : "protected",
  });
  const { dropdowns: listBDocumentOptionsUnder18 } = useDropdowns({
    entity: "listBDocumentOptionsUnder18",
    outsideMode: user ? "" : "protected",
  });
  const { dropdowns: listCDocumentOptions } = useDropdowns({
    entity: "listCDocumentOptions",
    outsideMode: user ? "" : "protected",
  });
  const { dropdowns: authoritiesOptions } = useDropdowns({
    entity: "authoritiesOptions",
    outsideMode: user ? "" : "protected",
  });
  const { dropdowns: stateCodeOptions } = useDropdowns({
    entity: "stateCodes",
    outsideMode: user ? "" : "protected",
  });
  const [under18, setUnder18] = useState(false);

  const confirmationModalBody = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mb={3}>
          <Typography variant="h5"> Employer or Authorized Representative: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> Title: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.title")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> Last Name: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.lastName")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> First Name: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.firstName")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} my={3}>
          <Typography variant="h5"> Employer&apos;s Business or Organization Name </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> Organization Name: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.organizationName")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> Company: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.organizationAddress")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> City: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.city")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> State: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.state")}{" "}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2"> Zip: </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" fontWeight="bold">
            {" "}
            {watch("authorizedRepresentative.zip")}{" "}
          </Typography>
        </Grid>
      </Grid>
    </>
  );

  // useEffect(() => {
  //   const fetchApplicant = async (applicantId) => {
  //     const app = await fetchSingleApplicant({ applicantId });
  //     if (app?.data) { setCurrentRepresentativeApplicant(app.data) }
  //     else setCurrentRepresentativeApplicant(null)
  //   }
  //   if (currentLoggedUser?.applicantId) {

  //     fetchApplicant(currentLoggedUser?.applicantId)

  //   }

  // }, [currentLoggedUser])

  useEffect(() => {
    setSignature(
      representativeFullName?.signature && representativeFullName?.applicantId ? (
        <Card>
          <img
            src={`${IMAGE_SERVER}/applicants/${representativeFullName?.applicantId}/signature/${representativeFullName?.signature}`}
            alt="signature"
            style={{ height: 40, width: 120 }}
          />
        </Card>
      ) : (
        <Typography
          sx={{ fontStyle: "italic" }}
        >{`${representativeFullName?.firstName} ${representativeFullName?.lastName} Signature`}</Typography>
      )
    );
  }, [representativeFullName, currentRepresentativeApplicant]);

  const onSubmit = async (data) => {
    let parsedData;
    if (data.reverificationOrRehire) {
      parsedData = {
        employerI9Form: {
          lastName: data.newLastName,
          firstName: data.firstName,
          middleInitial: data.middleInitial,
          processedDate: data.processedDateRehired,
          dateOfRehire: data.dateOfRehire ? new Date(data.dateOfRehire).toISOString() : null,
          reverificationOrRehire: data.reverificationOrRehire,
          ...(data.listADocumentTitle
            ? {
                listADocument: {
                  title: data.listADocumentTitle,
                  issuingAuthority: data.listAIssuingAuthority,
                  ...(data.listAIssuingAuthorityState && {
                    issuingAuthorityState: data.listAIssuingAuthorityState,
                  }),
                  number: data.listADocumentNumber,
                  expirationDate: data.listAExpirationDate
                    ? new Date(data.listAExpirationDate).toISOString()
                    : null,
                },
              }
            : {
                listBDocument: {
                  title: data.listBDocumentTitle,
                  issuingAuthority: data.listBIssuingAuthority,
                  ...(data.listBIssuingAuthorityState && {
                    issuingAuthorityState: data.listBIssuingAuthorityState,
                  }),
                  number: data.listBDocumentNumber,
                  expirationDate: data.listBExpirationDate
                    ? new Date(data.listBExpirationDate).toISOString()
                    : null,
                },
                listCDocument: {
                  title: data.listCDocumentTitle,
                  issuingAuthority: data.listCIssuingAuthority,
                  ...(data.listCIssuingAuthorityState && {
                    issuingAuthorityState: data.listCIssuingAuthorityState,
                  }),
                  number: data.listCDocumentNumber,
                  expirationDate: data.listCExpirationDate
                    ? new Date(data.listCExpirationDate).toISOString()
                    : null,
                },
              }),
          firstDayOfEmployment: data.firstDayOfEmployment
            ? new Date(data.firstDayOfEmployment).toISOString()
            : null,
        },
      };
    } else {
      parsedData = {
        employerI9Form: {
          authorizedRepresentative: data.authorizedRepresentative,
          processedDate: data.processedDate,
          ...(data.listADocumentTitle
            ? {
                listADocument: {
                  title: data.listADocumentTitle,
                  issuingAuthority: data.listAIssuingAuthority,
                  ...(data.listAIssuingAuthorityState && {
                    issuingAuthorityState: data.listAIssuingAuthorityState,
                  }),
                  number: data.listADocumentNumber,
                  expirationDate: data.listAExpirationDate
                    ? new Date(data.listAExpirationDate).toISOString()
                    : null,
                },
              }
            : {
                listBDocument: {
                  title: data.listBDocumentTitle,
                  issuingAuthority: data.listBIssuingAuthority,
                  ...(data.listBIssuingAuthorityState && {
                    issuingAuthorityState: data.listBIssuingAuthorityState,
                  }),
                  number: data.listBDocumentNumber,
                  expirationDate: data.listBExpirationDate
                    ? new Date(data.listBExpirationDate).toISOString()
                    : null,
                },
                listCDocument: {
                  title: data.listCDocumentTitle,
                  issuingAuthority: data.listCIssuingAuthority,
                  ...(data.listCIssuingAuthorityState && {
                    issuingAuthorityState: data.listCIssuingAuthorityState,
                  }),
                  number: data.listCDocumentNumber,
                  expirationDate: data.listCExpirationDate
                    ? new Date(data.listCExpirationDate).toISOString()
                    : null,
                },
              }),
          firstDayOfEmployment: data.firstDayOfEmployment
            ? new Date(data.firstDayOfEmployment).toISOString()
            : null,
          reverificationOrRehire: data.reverificationOrRehire,
        },
      };
    }

    if (
      data.listADocumentTitle === null &&
      data.listBDocumentTitle === null &&
      data.listCDocumentTitle === null
    ) {
      setError("noDocumentation", {
        type: "documentation missing",
        message: "You Must Fill the List A or B + C Documentation ",
      });
    } else {
      updateApplicantAction(applicant._id, parsedData);
      setTimeout(
        () =>
          document.getElementById("next-btn").scrollIntoView({ block: "center", inline: "center" }),
        1000
      );
    }
  };

  const sortUsers = (a, b) => {
    if (a.firstName > b.firstName) return 1;
    return -1;
  };

  const confirmFirstTime = (e) => {
    e.preventDefault();
    if (!applicant?.employerI9Form?.authorizedRepresentative) {
      toggleConfirmationModal(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };
  const submitForm = () => {
    handleSubmit(onSubmit)();
  };

  function applicantUnder18(dateOfBirth) {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age -= 1;
    }

    if (age < 18) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((usr) => ({
            ...usr,
            label: `${usr.firstName} ${usr.lastName}`,
          }))
      );
    }
  }, [users]);

  const listABDocumentTitleChange = (list, value) => {
    if (list === "b") {
      setValue("listBDocumentTitle", value);
      setValue("listADocumentTitle", null);
      setValue("listAIssuingAuthority", null);
      setValue("listAIssuingAuthorityState", null);
      setValue("listADocumentNumber", "");
      setValue("listAExpirationDate", null);
    } else if (list === "c") {
      setValue("listCDocumentTitle", value);
      setValue("listADocumentTitle", null);
      setValue("listAIssuingAuthority", null);
      setValue("listAIssuingAuthorityState", null);
      setValue("listADocumentNumber", "");
      setValue("listAExpirationDate", null);
    } else {
      setValue("listADocumentTitle", value);
      setValue("listBDocumentTitle", null);
      setValue("listBIssuingAuthority", null);
      setValue("listBIssuingAuthorityState", null);
      setValue("listBDocumentNumber", "");
      setValue("listBExpirationDate", null);
      setValue("listCDocumentTitle", null);
      setValue("listCIssuingAuthority", null);
      setValue("listCIssuingAuthorityState", null);
      setValue("listCDocumentNumber", "");
      setValue("listCExpirationDate", null);
    }
  };

  useEffect(() => {
    updateCurrentFormState({
      ...currentFormState,
      isDirty,
    });

    updateButtons({
      ...buttonState,
      submit: {
        show: true,
        disabled: !isDirty,
      },
    });
  }, [isDirty]);

  useEffect(() => {
    updateButtons({
      previous: {
        show: true,
        disabled: false,
      },
      next: {
        show: true,
        disabled: true,
      },
      submit: {
        show: true,
        disabled: false,
      },
    });
  }, []);

  useEffect(() => {
    if (isValid) {
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isValid]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
      updateButtons({
        ...buttonState,
        next: {
          show: true,
          disabled: false,
        },
        submit: {
          show: true,
          disabled: false,
        },
      });
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    setUnder18(applicantUnder18(applicant?.birthDate));
    if (applicant?.employerI9Form) {
      reset(defaultValues, { keepErrors: true });
    }
  }, [applicant]);

  useEffect(() => {
    reset(defaultValues, { keepErrors: true });
  }, [currentLoggedUser]);

  useEffect(() => {
    if (under18 && listBDocumentOptions != null && listBDocumentOptionsUnder18 != null) {
      listBFiltered.current = [...listBDocumentOptions, ...listBDocumentOptionsUnder18];
      listBFiltered.current.concat(listBDocumentOptionsUnder18);
    } else if (!under18 && listBDocumentOptions != null) {
      listBFiltered.current = listBDocumentOptions && listBDocumentOptions;
    }
  }, [under18, listBDocumentOptions, listBDocumentOptionsUnder18]);

  return (
    <form onSubmit={confirmFirstTime} id="current-form">
      <FlexWrapper sx={{ flexDirection: "column", rowGap: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <MDTypography variant="h5">Employee info from I-9 Section 1:</MDTypography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              value={applicant?.lastName}
              variant="outlined"
              label="Last Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3} xl={3}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              value={applicant?.firstName}
              variant="outlined"
              label="First Name"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={1} xl={1}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              value={applicant?.middleInitial}
              variant="outlined"
              label="Middle Initial"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={2} xl={2}>
            <CustomTextField
              disabled
              inputProps={{ tabIndex: -1 }}
              value={applicant?.i9Form?.citizenshipStatus}
              variant="outlined"
              label="Citizenship/Immigration Status"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={4}>
            <Grid container>
              <Grid item xs={12}>
                <MDTypography variant="h6">
                  List A: Identity & Employment Authorization
                </MDTypography>
              </Grid>
              <Grid item xs={12} my={1}>
                <Controller
                  name="listADocumentTitle"
                  freeSolo
                  Autocomplete
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={listADocumentOptions || []}
                      defaultValue={null}
                      value={field.value || null}
                      onChange={(e, v) => {
                        listABDocumentTitleChange("a", v);
                      }}
                      sx={{ maxHeight: "44px" }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          type="text"
                          label="Document Title"
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f0f0f0",
                              py: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listAIssuingAuthority"
                  freeSolo
                  Autocomplete
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={authoritiesOptions || []}
                      defaultValue={null}
                      disabled={
                        watch("listADocumentTitle") === null ||
                        watch("listADocumentTitle") === undefined
                      }
                      value={field.value || null}
                      onChange={(e, v) => {
                        setValue("listAIssuingAuthority", v);
                      }}
                      sx={{ maxHeight: "44px" }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          type="text"
                          label="Issuing Authority"
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f0f0f0",
                              py: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {watch("listAIssuingAuthority") === "List of 50 States Below" && (
                <Grid item xs={12} mb={1}>
                  <Controller
                    name="listAIssuingAuthorityState"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={stateCodeOptions}
                        freeSolo
                        defaultValue={null}
                        value={field.value || (stateCodeOptions ? stateCodeOptions?.[0] : "")}
                        onChange={(e, v) => {
                          field.onChange(v?.toUpperCase());
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="outlined"
                            type="text"
                            label="Issuing Authority State"
                            sx={{
                              "& .MuiInputBase-root": {
                                background: "#f0f0f0",
                                py: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listADocumentNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      label="Document Number"
                      disabled={
                        watch("listADocumentTitle") === null ||
                        watch("listADocumentTitle") === undefined
                      }
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listAExpirationDate"
                  control={control}
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Expiration Date (if any)"
                        disabled={
                          watch("listADocumentTitle") === null ||
                          watch("listADocumentTitle") === undefined
                        }
                        renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={4}>
            <Grid container>
              <Grid item xs={12}>
                <MDTypography variant="h6">List B: Identity</MDTypography>
              </Grid>
              <Grid item xs={12} my={1}>
                <Controller
                  name="listBDocumentTitle"
                  freeSolo
                  Autocomplete
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={listBFiltered.current || []}
                      defaultValue={null}
                      value={field.value || null}
                      onChange={(e, v) => {
                        listABDocumentTitleChange("b", v);
                      }}
                      sx={{ maxHeight: "44px" }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          type="text"
                          label="Document Title"
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f0f0f0",
                              py: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listBIssuingAuthority"
                  freeSolo
                  Autocomplete
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={authoritiesOptions || []}
                      defaultValue={null}
                      disabled={
                        watch("listBDocumentTitle") === null ||
                        watch("listBDocumentTitle") === undefined
                      }
                      value={field.value || null}
                      onChange={(e, v) => {
                        setValue("listBIssuingAuthority", v);
                      }}
                      sx={{ maxHeight: "44px" }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          type="text"
                          label="Issuing Authority"
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f0f0f0",
                              py: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {watch("listBIssuingAuthority") === "List of 50 States Below" && (
                <Grid item xs={12} mb={1}>
                  <Controller
                    name="listBIssuingAuthorityState"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={stateCodeOptions}
                        freeSolo
                        defaultValue={null}
                        value={field.value || (stateCodeOptions ? stateCodeOptions?.[0] : "")}
                        onChange={(e, v) => {
                          field.onChange(v.toUpperCase());
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="outlined"
                            type="text"
                            label="Issuing Authority State"
                            sx={{
                              "& .MuiInputBase-root": {
                                background: "#f0f0f0",
                                py: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listBDocumentNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      label="Document Number"
                      disabled={
                        watch("listBDocumentTitle") === null ||
                        watch("listBDocumentTitle") === undefined
                      }
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listBExpirationDate"
                  control={control}
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Expiration Date (if any)"
                        disabled={
                          watch("listBDocumentTitle") === null ||
                          watch("listBDocumentTitle") === undefined
                        }
                        renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={4}>
            <Grid container>
              <Grid item xs={12}>
                <MDTypography variant="h6">List C: Employment Authorization</MDTypography>
              </Grid>
              <Grid item xs={12} my={1}>
                <Controller
                  name="listCDocumentTitle"
                  freeSolo
                  Autocomplete
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={listCDocumentOptions || []}
                      defaultValue={null}
                      value={field.value || null}
                      onChange={(e, v) => {
                        listABDocumentTitleChange("c", v);
                      }}
                      sx={{ maxHeight: "44px" }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          type="text"
                          label="Document Title"
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f0f0f0",
                              py: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listCIssuingAuthority"
                  freeSolo
                  Autocomplete
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      options={authoritiesOptions || []}
                      defaultValue={null}
                      disabled={
                        watch("listCDocumentTitle") === null ||
                        watch("listCDocumentTitle") === undefined
                      }
                      value={field.value || null}
                      onChange={(e, v) => {
                        setValue("listCIssuingAuthority", v);
                      }}
                      sx={{ maxHeight: "44px" }}
                      renderInput={(params) => (
                        <CustomTextField
                          {...params}
                          variant="outlined"
                          type="text"
                          label="Issuing Authority"
                          sx={{
                            "& .MuiInputBase-root": {
                              background: "#f0f0f0",
                              py: "5px",
                            },
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {watch("listCIssuingAuthority") === "List of 50 States Below" && (
                <Grid item xs={12} mb={1}>
                  <Controller
                    name="listCIssuingAuthorityState"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        options={stateCodeOptions}
                        freeSolo
                        defaultValue={null}
                        value={field.value || (stateCodeOptions ? stateCodeOptions?.[0] : "")}
                        onChange={(e, v) => {
                          field.onChange(v.toUpperCase());
                        }}
                        renderInput={(params) => (
                          <CustomTextField
                            {...params}
                            variant="outlined"
                            type="text"
                            label="Issuing Authority State"
                            sx={{
                              "& .MuiInputBase-root": {
                                background: "#f0f0f0",
                                py: "5px",
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listCDocumentNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <CustomTextField
                      label="Document Number"
                      disabled={
                        watch("listCDocumentTitle") === null ||
                        watch("listCDocumentTitle") === undefined
                      }
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} mb={1}>
                <Controller
                  name="listCExpirationDate"
                  control={control}
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Expiration Date (if any)"
                        disabled={
                          watch("listCDocumentTitle") === null ||
                          watch("listCDocumentTitle") === undefined
                        }
                        renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="firstDayOfEmployment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    label="Employee's first day of employment"
                    renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                    {...field}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="h6">
              Certification: I attest, under penalty of perjury, that (1) I have examined the
              document(s) presented by the above-named employee, (2) the above-listed document(s)
              appear to be genuine and to relate to the employee named, and (3) to the best of my
              knowledge the employee is authorized to work in the United States.
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {signature}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="processedDate"
              control={control}
              defaultValue={new Date()}
              render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    disabled={applicant?.employerI9Form?.processedDate}
                    label="Today's Date"
                    renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                    {...field}
                    value={
                      applicant?.employerI9Form?.processedDate
                        ? applicant?.employerI9Form?.processedDate
                        : field.value
                    }
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="authorizedRepresentative.title"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={{ tabIndex: -1 }}
                  {...field}
                  value={field.value}
                  variant="outlined"
                  label="title of Employer or Authorized Representative"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              options={usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []}
              autoSelect
              name="authorizedRepresentativeName"
              disabled={applicant?.employerI9Form?.authorizedRepresentative}
              value={representativeFullName?.label}
              defaultValue={`${defaultValues.authorizedRepresentative.firstName} ${defaultValues.authorizedRepresentative.lastName}`}
              onChange={(e, v) => {
                setValue("authorizedRepresentative.firstName", v.firstName, { shouldDirty: true });
                setValue("authorizedRepresentative.lastName", v.lastName, { shouldDirty: true });
                setValue("authorizedRepresentative.signature", v.signature, { shouldDirty: true });
                setValue("authorizedRepresentative.applicantId", v.applicantId, {
                  shouldDirty: true,
                });
                setRepresentativeFullName(v);
              }}
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    type="text"
                    variant="outlined"
                    label="Name of Employer or Authorized Representative"
                    sx={{
                      "& .MuiInputBase-root": {
                        py: "5px",
                      },
                    }}
                    // InputLabelProps={{ shrink: !isEmpty(getValues("recruiter")) }}
                  />
                </>
              )}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Controller
              name="authorizedRepresentative.lastName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={{ tabIndex: -1 }}
                  {...field}
                  variant="outlined"
                  label="Last Name of Employer or Authorized Representative"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="authorizedRepresentative.firstName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={
                    applicant?.employerI9Form?.authorizedRepresentative && { tabIndex: -1 }
                  }
                  {...field}
                  value={field.value}
                  variant="outlined"
                  label="First Name of Employer or Authorized Representative"
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Controller
              name="authorizedRepresentative.organizationName"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={
                    applicant?.employerI9Form?.authorizedRepresentative && { tabIndex: -1 }
                  }
                  {...field}
                  value={field.value}
                  variant="outlined"
                  label="Employer's Business or Organization Name"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="authorizedRepresentative.organizationAddress"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={
                    applicant?.employerI9Form?.authorizedRepresentative && { tabIndex: -1 }
                  }
                  {...field}
                  value={field.value}
                  variant="outlined"
                  label="Employer's Business or Organization Address"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="authorizedRepresentative.city"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={
                    applicant?.employerI9Form?.authorizedRepresentative && { tabIndex: -1 }
                  }
                  value={field.value}
                  {...field}
                  variant="outlined"
                  label="City"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <Controller
              name="authorizedRepresentative.state"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={
                    applicant?.employerI9Form?.authorizedRepresentative && { tabIndex: -1 }
                  }
                  options={stateCodeOptions || []}
                  autoSelect
                  freeSolo
                  name="state"
                  value={field.value || ""}
                  onChange={(e) => {
                    field.onChange(e.target.value.toUpperCase());
                  }}
                  onBlur={() => trigger("state")}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      type="text"
                      variant="outlined"
                      label="State"
                      sx={{
                        "& .MuiInputBase-root": {
                          py: "5px",
                        },
                      }}
                      InputLabelProps={{ shrink: getValues("authorizedRepresentative.state") }}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="authorizedRepresentative.zip"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Zip Code"
                  disabled={applicant?.employerI9Form?.authorizedRepresentative}
                  inputProps={
                    applicant?.employerI9Form?.authorizedRepresentative && { tabIndex: -1 }
                  }
                  value={field.value}
                  variant="outlined"
                  type="text"
                  InputLabelProps={{ shrink: getValues("zip") }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Controller
                name="reverificationOrRehire"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    id="Applicant"
                    checked={field.value}
                    onChange={(e) => setValue("reverificationOrRehire", !field.value)}
                  />
                )}
              />
              <MDTypography variant="h5">Reverification and Rehires</MDTypography>
            </Stack>
          </Grid>

          {watch("reverificationOrRehire") && (
            <>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="newLastName"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label="New Last Name (Family Name)"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label="First Name (Given Name)"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Controller
                  name="middleInitial"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField label="MI" variant="outlined" {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="dateOfRehire"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Date of Rehire"
                        renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h6">
                  If the employee&apos;s previous grant of employment authorization has expired,
                  provide the information for the document of receipt that establishes continuing
                  employment authorization in the space provided below.
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="documentTitle"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField label="Document Title" variant="outlined" {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller
                  name="documentNumber"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField label="Document Number" variant="outlined" {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="expirationDate"
                  control={control}
                  defaultValue={null}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Expiration Date (if any)"
                        renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h6">
                  I attest, under penalty of perjury, that to the best of my knowledge this employee
                  is authorized to work in the United States, and if the employee presented
                  document(s), the document(s) I have examined appear to be genuine and to relate to
                  the individual.
                </MDTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="signatureEmployer"
                  control={control}
                  render={({ field }) => (
                    <CustomTextField
                      label="Signature of Employer or Authorized Representative"
                      variant="outlined"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Controller
                  name="processedDateRehired"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Today's Date"
                        renderInput={(params) => <CustomTextField variant="outlined" {...params} />}
                        {...field}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTextField
                  disabled
                  inputProps={{ tabIndex: -1 }}
                  value={`${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`}
                  variant="outlined"
                  label="Name of Employer or Authorized Representative"
                />
              </Grid>
            </>
          )}
        </Grid>
      </FlexWrapper>

      <FormErrors errors={errors} />
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => toggleConfirmationModal(false)}
        title="You cannot update this information once it is saved.  Are you sure you want to save?"
        body={confirmationModalBody}
        onConfirm={(e) => {
          e.stopPropagation();
          submitForm();
          toggleConfirmationModal(false);
        }}
      />
    </form>
  );
};

export default EmployerI9Form;
