import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import JobAssessmentRowActions from "./JobAssessmentRowActions";

const JobAssessmentRow = ({ row }) => {
  return (
    <>
      <Grid container spacing={3} alignItems="center" flexWrap="nowrap">
        <Grid item flexGrow={2} flexBasis={0} flexShrink={1} overflow="hidden">
          <MDTypography variant="body2">{row.name}</MDTypography>
        </Grid>
        <Grid item flexGrow={1} flexBasis={0} flexShrink={1} overflow="hidden">
          <MDTypography variant="body2">{row.type}</MDTypography>
        </Grid>
        <Grid item flexBasis="auto">
          <JobAssessmentRowActions row={row} />
        </Grid>
      </Grid>
    </>
  );
};

export default JobAssessmentRow;
