import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { Box, Stack } from "@mui/material";

import { makeStyles } from "@mui/styles";

import MDTypography from "components/MDTypography";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100px",

    border: "2px solid #d2d6da",
    padding: "20px 20px",
    cursor: "pointer",
  },
  text: { color: "#7b809a!important", fontSize: "0.875rem!important" },
});

const FileDropzone = ({
  onDrop,
  className,
  customStyles,
  icon,
  disabled,
  message = "Drop files here to upload",
  message2 = "",
  restrict = {},
  maxSize = 4000000,
  size,
  avatar,
  accept,
  multiple = true,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled,
    restrict,
    maxSize,
    accept,
    multiple,
  });

  const styles = useStyles();
  return (
    <section>
      <Box
        {...getRootProps()}
        className={customStyles ? customStyles.container : styles.container}
        sx={{ background: disabled ? "#c1c1c1" : "white", height: `${size}` }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        {icon}
        {avatar || (
          <Stack direction="column" alignItems="center" justifyContent="center">
            <MDTypography variant="p" className={customStyles ? customStyles.text : styles.text}>
              {message}
            </MDTypography>
            {message2 && (
              <MDTypography
                variant="p"
                textAlign="center"
                className={customStyles ? customStyles.text : styles.text}
              >
                {message2}
              </MDTypography>
            )}
          </Stack>
        )}
      </Box>
    </section>
  );
};

FileDropzone.defaultProps = {
  onDrop: (acceptedFiles) => console.log("UPLOADED FILES HERE", acceptedFiles),
  className: "",
};
FileDropzone.prototype = {
  onDrop: PropTypes.func,
  className: PropTypes.string,
};

export default FileDropzone;
