import React, { useEffect, useState } from "react";
import { Box, Button, Card, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

// API functions
import getDynamicFormData from "api/llm/getDynamicFormData";
import getAllForms from "api/llm/getAllForms";
import updateDynamicForm from "api/llm/updateDynamicForm";
import generateFilledPdf from "api/llm/generateFilledPdf";

// Components
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FormRenderer from "./components/FormRenderer";
import FormsList from "./components/FormsList";
import FormUploadModal from "./components/FormUploadModal";
import FormEditor from "./components/FormEditor";

const DynamicForms = () => {
  // State management
  const [formData, setFormData] = useState(null);
  const [currentFormId, setCurrentFormId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formsList, setFormsList] = useState([]);
  const [loadingForms, setLoadingForms] = useState(false);
  const [formName, setFormName] = useState("");
  const [uploadedForm, setUploadedForm] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // Fetch all forms with complete data on component mount
  const fetchForms = async () => {
    try {
      setLoadingForms(true);
      const forms = await getAllForms();
      setFormsList(forms);
    } catch (error) {
      enqueueSnackbar(error.message || "Error fetching forms", {
        variant: "error",
      });
    } finally {
      setLoadingForms(false);
    }
  };

  useEffect(() => {
    fetchForms();
  }, []);

  // Event handlers
  const handleFileUpload = async (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      enqueueSnackbar(rejectedFiles[0].errors[0].message, {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }
    if (acceptedFiles.length <= 0) return;

    // Create form data
    const formUpload = new FormData();
    formUpload.append("file", acceptedFiles[0]);

    // Store the form data for later use
    setUploadedForm(formUpload);
  };

  const handleProcessForm = async () => {
    if (!uploadedForm) return;

    try {
      setLoading(true);

      const response = await getDynamicFormData({
        form: uploadedForm,
        formName,
      });

      setFormData(response.data.form);
      setCurrentFormId(response.data.formId);
      setFormValues({});

      // Refresh forms list
      fetchForms();

      enqueueSnackbar("Form processed and saved successfully", {
        variant: "success",
      });

      // Switch to form view tab
      setActiveTab(1);
    } catch (error) {
      enqueueSnackbar(error.message || "Error processing form", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setUploadedForm(null);
      setFormName("");
    }
  };

  const handleCloseUploadModal = async (shouldProcess) => {
    setShowUploadModal(false);

    if (shouldProcess && uploadedForm) {
      await handleProcessForm();
    }
  };

  const handleLoadForm = (formId) => {
    try {
      // Find the form in our already loaded forms list
      const selectedForm = formsList.find((form) => form.id === formId);

      if (selectedForm && selectedForm.form) {
        setFormData(selectedForm.form);
        setCurrentFormId(selectedForm.id);
        setFormValues({});
        setActiveTab(1); // Switch to form view tab
        setIsEditMode(false); // Reset edit mode
      } else {
        enqueueSnackbar("Form data not found", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error.message || "Error loading form", {
        variant: "error",
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Reset edit mode when changing tabs
    if (newValue !== 2) {
      setIsEditMode(false);
    }
  };

  const handleInputChange = (id, value) => {
    setFormValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form values:", formValues);
    enqueueSnackbar("Form submitted successfully", {
      variant: "success",
    });
    // TODO: Handle form submission
  };

  const handleEditForm = () => {
    setIsEditMode(true);
    setActiveTab(2); // Switch to edit tab
  };

  const handleFormDataChange = async (updatedFormData) => {
    try {
      setLoading(true);

      if (!currentFormId) {
        throw new Error("Form ID not found");
      }

      // Update the form data
      await updateDynamicForm({
        formId: currentFormId,
        formData: updatedFormData,
      });

      // Update local state
      setFormData(updatedFormData);

      // Refresh forms list
      await fetchForms();

      enqueueSnackbar("Form updated successfully", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(error.message || "Error updating form", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGeneratePdf = async (values) => {
    if (!currentFormId) {
      enqueueSnackbar("Form ID not found", { variant: "error" });
      return;
    }

    try {
      setGeneratingPdf(true);

      const response = await generateFilledPdf({
        formId: currentFormId,
        formValues: values,
      });

      // Create a temporary link and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${formData.title || "filled-form"}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      enqueueSnackbar("PDF generated successfully", { variant: "success" });
    } catch (error) {
      console.error("Error generating PDF:", error);
      enqueueSnackbar(error.message || "Error generating PDF", { variant: "error" });
    } finally {
      setGeneratingPdf(false);
    }
  };

  return (
    <Card sx={{ width: "100%", p: 3, position: "relative" }}>
      {/* Header with title and add button */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
        <Typography variant="h4">Dynamic Forms</Typography>
        <Button
          startIcon={<AddCircleRoundedIcon />}
          variant="text"
          onClick={() => {
            setFormName(`Form ${new Date().toLocaleDateString()}`);
            setShowUploadModal(true);
          }}
          aria-label="add form"
        >
          Add New Form
        </Button>
      </Box>

      {/* Tabs */}
      <Tabs value={activeTab} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Forms Library" />
        {formData && <Tab label="Form View" />}
        {formData && isEditMode && <Tab label="Form Editor" />}
      </Tabs>

      {/* Loading indicator */}
      {(loading || generatingPdf) && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Forms Library Tab */}
      {!loading && !generatingPdf && activeTab === 0 && (
        <FormsList forms={formsList} loading={loadingForms} onViewForm={handleLoadForm} />
      )}

      {/* Form View Tab */}
      {!loading && !generatingPdf && activeTab === 1 && formData && (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, gap: 2 }}>
            <Button 
              variant="contained" 
              color="secondary" 
              startIcon={<PictureAsPdfIcon />}
              onClick={() => handleGeneratePdf(formValues)}
              disabled={generatingPdf}
            >
              Generate PDF
            </Button>
            <Button variant="contained" color="primary" onClick={handleEditForm}>
              Edit Form
            </Button>
          </Box>
          <FormRenderer
            formData={formData}
            formValues={formValues}
            onInputChange={handleInputChange}
            onSubmit={handleSubmit}
          />
        </>
      )}

      {/* Form Editor Tab */}
      {activeTab === 2 && formData && isEditMode && (
        <FormEditor formData={formData} onFormDataChange={handleFormDataChange} loading={loading} />
      )}

      {/* Modals */}
      <FormUploadModal
        open={showUploadModal}
        onClose={handleCloseUploadModal}
        onUpload={handleFileUpload}
        loading={loading}
        formName={formName}
        setFormName={setFormName}
      />
    </Card>
  );
};

export default DynamicForms;
