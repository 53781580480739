/*
Missing these for now:
  Event Report Time (from position on Roster)
  Event Map URL
*/
export const MESSAGE_VARIABLES = [
  {
    name: "Applicant First Name",
    key: "{{ applicantFirstName }}",
    variable: "this.applicant.firstName",
  },
  {
    name: "Applicant Last Name",
    key: "{{ applicantLastName }}",
    variable: "this.applicant.lastName",
  },
  {
    name: "Applicant Email",
    key: "{{ applicantEmail }}",
    variable: "this.applicant.email",
  },
  {
    name: "Applicant Phone",
    key: "{{ applicantPhone }}",
    variable: "this.applicant.phone",
  },
  {
    name: "Applicant Code",
    key: "{{ applicantCode }}",
    variable: "this.applicant.applicantCode",
  },
  {
    name: "Applicant ID",
    key: "{{ applicantId }}",
    variable: "this.applicant._id",
  },
  {
    name: "Event Venue Name",
    key: "{{ eventVenue }}",
    variable: "this.event.venueName",
  },
  {
    name: "Event Name",
    key: "{{ eventName }}",
    variable: "this.event.eventName",
  },
  {
    name: "Event Venue Logo",
    key: "{{ eventVenueLogo }}",
    variable: "this.event.eventImage",
  },
  {
    name: "Event Preview URL",
    key: "{{ eventUrl }}",
    variable: "this.event.eventUrl",
  },
  {
    name: "Event Description",
    key: "{{ eventDescription }}",
    variable: "this.event.description",
  },
  {
    name: "Event Date",
    key: "{{ eventDate }}",
    variable: "this.event.eventDate",
  },
  {
    name: "Event ReportTime",
    key: "{{ eventReportTime }}",
    variable: "this.event.reportTime",
  },
  {
    name: "Event Position",
    key: "{{ eventPosition }}",
    variable: "this.event.position",
  },
  {
    name: "Event Address",
    key: "{{ eventAddress }}",
    variable: "this.event.address",
  },
  {
    name: "Event City",
    key: "{{ eventCity }}",
    variable: "this.event.venueCity",
  },
  {
    name: "Event State",
    key: "{{ eventState }}",
    variable: "this.event.venueState",
  },
  {
    name: "Event Zip Code",
    key: "{{ eventZipCode }}",
    variable: "this.event.zip",
  },
  {
    name: "Sender First Name",
    key: "{{ senderFirstName }}",
    variable: "this.user.firstName",
  },
  {
    name: "Sender Last Name",
    key: "{{ senderLastName }}",
    variable: "this.user.lastName",
  },
  {
    name: "Sender Email",
    key: "{{ senderEmail }}",
    variable: "this.user.emailAddress",
  },
  {
    name: "Sender Phone",
    key: "{{ phone }}",
    variable: "this.user.phone",
  },
  {
    name: "Venue Name",
    key: "{{ venueName }}",
    variable: "this.venue.name",
  },
  {
    name: "Venue ID",
    key: "{{ venueId }}",
    variable: "this.venue.slug",
  },
  {
    name: "Web URL",
    key: "{{ webUrl }}",
    variable: "this.webUrl",
  },

  {
    name: "Job Name",
    key: "{{ jobName }}",
    variable: "this.job.title",
  },
  {
    name: "Job Company Name",
    key: "{{ jobCompany }}",
    variable: "this.job.companyName",
  },
  {
    name: "Job Description",
    key: "{{ jobDescription }}",
    variable: "this.job.description",
  },
  {
    name: "Job Address",
    key: "{{ jobAddress }}",
    variable: "this.job.address",
  },
  {
    name: "Job City",
    key: "{{ jobCity }}",
    variable: "this.job.companyCity",
  },
  {
    name: "Job State",
    key: "{{ jobState }}",
    variable: "this.job.companyState",
  },
  {
    name: "Job Zip Code",
    key: "{{ jobZipCode }}",
    variable: "this.job.zip",
  },

  {
    name: "Shift Name",
    key: "{{ shiftName }}",
    variable: "this.shift.shiftName",
  },
  {
    name: "Shift Start Date",
    key: "{{ startDate }}",
    variable: "this.shift.startDate",
  },
  {
    name: "Shift End Date",
    key: "{{ endDate }}",
    variable: "this.shift.endDate",
  },
  {
    name: "Shift Schedule",
    key: "{{ schedule }}",
    variable: "this.shift.schedule",
  },

  {
    name: "Task Name",
    key: "{{ taskName }}",
    variable: "this.task.taskName",
  },
  {
    name: "Task Type",
    key: "{{ taskType }}",
    variable: "this.task.taskType",
  },
  {
    name: "Task Due Date",
    key: "{{ taskDueDate }}",
    variable: "this.task.dueDate",
  },
  {
    name: "Task Applicant First Name",
    key: "{{ taskApplicantFirstName }}",
    variable: 'this.task?.applicant?.firstName ?? ""',
  },
  {
    name: "Task Applicant Last Name",
    key: "{{ taskApplicantLastName }}",
    variable: 'this.task?.applicant?.lastName ?? ""',
  },
  {
    name: "Task Description",
    key: "{{ taskDescription }}",
    variable: "this.task?.taskDescription",
  },

  {
    name: "AI Screening Applicant First Name",
    key: "{{ aiScreeningApplicantFirstName }}",
    variable: "this.aiInterview?.firstName",
  },
  {
    name: "AI Screening Applicant Last Name",
    key: "{{ aiScreeningApplicantLastName }}",
    variable: "this.aiInterview?.lastName",
  },
  {
    name: "AI Screening Applicant Job Name",
    key: "{{ aiScreeningJobName }}",
    variable: "this.aiInterview?.jobName",
  },
  {
    name: "AI Screening Applicant Job Company",
    key: "{{ aiScreeningJobCompany }}",
    variable: "this.aiInterview?.jobCompany",
  },
  {
    name: "AI Screening Applicant Score",
    key: "{{ aiScreeningScore }}",
    variable: "this.aiInterview?.score",
  },
  {
    name: "AI Screening Applicants With Finished Interview",
    key: "{{ aiInterviewApplicants }}",
    variable: "this.aiInterview?.aiInterviewApplicants",
  },
  {
    name: "AI Screening Applicants Waiting For Call",
    key: "{{ waitingForCallApplicants }}",
    variable: "this.aiInterview?.waitingForCallApplicants",
  },
  {
    name: "AI Screening Applicant Without Interview",
    key: "{{ idleApplicants }}",
    variable: "this.aiInterview?.idleApplicants",
  },
  {
    name: "Assessment Type",
    key: "{{ assessmentType }}",
    variable: "this.assessment.type",
  },
  {
    name: "Assessment Link",
    key: "{{ assessmentLink }}",
    variable: "this.assessment.applicantLink",
  },
  {
    name: "Assessment Name",
    key: "{{ assessmentName }}",
    variable: "this.assessment?.name",
  },
  {
    name: "Assessment Applicant First Name",
    key: "{{ assessmentApplicantFirstName }}",
    variable: "this.assessment?.applicant?.firstName",
  },
  {
    name: "Assessment Applicant Last Name",
    key: "{{ assessmentApplicantLastName }}",
    variable: "this.assessment?.applicant?.lastName",
  },
  {
    name: "Assessment Applicant Email",
    key: "{{ assessmentApplicantEmail }}",
    variable: "this.assessment?.applicant?.email",
  },
  {
    name: "Assessment Applicant Result Link",
    key: "{{ assessmentApplicantResultLink }}",
    variable: "this.assessment?.applicant?.resultLink",
  },
  {
    name: "Primary Company Name",
    key: "{{ companyName }}",
    variable: "this.primaryCompany.name",
  },
  {
    name: "Primary Company Email",
    key: "{{ companyEmail }}",
    variable: "this.primaryCompany.companyEmail",
  },
  {
    name: "Primary Company Address",
    key: "{{ companyAddress }}",
    variable: "this.primaryCompany.address",
  },
  {
    name: "Primary Company City",
    key: "{{ companyCity }}",
    variable: "this.primaryCompany.city",
  },
  {
    name: "Primary Company State",
    key: "{{ companyState }}",
    variable: "this.primaryCompany.state",
  },
  {
    name: "Primary Company Zip Code",
    key: "{{ companyZip }}",
    variable: "this.primaryCompany.zip",
  },
  {
    name: "Primary Company Phone Number",
    key: "{{ companyPhone }}",
    variable: "this.primaryCompany.phone",
  },
  {
    name: "Primary Company Url",
    key: "{{ companyUrl }}",
    variable: "this.primaryCompany.companyUrl",
  },
];
